import i18n from "../i18n.js";
import { f7, theme } from "framework7-vue";
import { utils } from "framework7";
import _ from "lodash";
export const scanCard = {
  methods: {
    scanCard() {
      const self = this;

      f7.dialog.confirm("Scan card QR code?", () => {
        setTimeout(() => {
          cordova.plugins.barcodeScanner.scan(
            function (result) {
              self.$store
                .dispatch("card/decodeCardQRCode", result.text.code)
                //   self.$store
                // .dispatch(
                //   "cqrd/decodeCardQRCode",
                //   "SgfenU3Nb57EPlKNXEz4adRV2wDbWdvYWhqCMMz/DBSLtYphoyWhqNnkwWNzNLaHHdcAJ/89t+X8+2sdWSy29/fRVh60c3qGVfoIR1v2VxX/cv7KDj1mVeSZ5Wrhgr/m"
                // )
                .then(() => {
                  setTimeout(() => {
                    self.$store
                      .dispatch("card/checkCard", {
                        cardNumber: this.QRtext.cardNumber,
                        cardPIN: this.QRtext.cardPIN,
                      })
                      .then(() => {
                        this.selectCurrentPage();
                        // this.f7router.navigate("/send-cash", {
                        //     ignoreCache: true,
                        //     reloadCurrent: true
                        // });
                      })
                      .catch((e) => {
                        console.log(e.message);
                      });
                  }, 1000);
                });
            },
            (error) => {
              // alert("Scanning failed: " + error);
              f7.dialog.alert(`Scanning failed: ${error}`);
            },
            {
              preferFrontCamera: false, // iOS and Android
              showFlipCameraButton: true, // iOS and Android
              showTorchButton: true, // iOS and Android
              torchOn: true, // Android, launch with the torch switched on (if available)
              // saveHistory: true, // Android, save scan history (default false)
              prompt: "Place a barcode inside the scan area", // Android
              resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
              formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
              orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
              disableAnimations: true, // iOS
              disableSuccessBeep: false, // iOS and Android
            }
          );
        }, 500);
      });
    },
    async selectCurrentPage() {
      const self = this;

      if (
        this.f7router.currentRoute.url ===
          `/pickup/with-recipient/${this.recipient.id}/pick-up` ||
        this.f7router.currentRoute.url ===
          `/topupwithrecipient/${this.recipient.id}/top-up`
      ) {
        f7.tab.show("#withnewcards");
      } else if (this.f7router.currentRoute.url === "/send-cash") {
        await this.f7router.navigate("/send-cash", {
          ignoreCache: true,
          reloadCurrent: true,
        });
      } else if (this.f7router.currentRoute.url === "/topup/my-account") {
        await this.f7router.navigate("/topup/my-account", {
          ignoreCache: true,
          reloadCurrent: true,
        });
      }
    },
  },
};

export const checkCard = {
  mounted() {
    const self = this;

    if (this.$keycloak.authenticated) {
      self.$store.dispatch("card/loadCarditemlist");
    }
    self.$store.state.card.queryerror = "";
  },
  methods: {
    async checkCard() {
      const self = this;

      // console.log("card PIn in check card " + this.cardPINKeypad.value);
      (await self.$store.state.card.queryerror) == "";
      if (this.cardNumber !== "" && this.cardPIN !== "") {
        await self.$store.dispatch("card/checkCard", {
          cardNumber: this.cardNumber,
          cardPIN: this.cardPIN, //this.cardPIN
        });
        this.cardNumber = "0000 0000 0000 0000";
        this.cardPIN = "°°°°";
        this.cardPINKeypad.setValue("°°°°");
        this.cardNumberKeypad.setValue("0000 0000 0000 0000");
        if (this.carditem && !this.queryerror) {
          await self.$store.dispatch("card/loadCarditemlist");
          if (this.f7router.currentRoute.path === "/send-cash") {
            await this.redirectAfterCardCheck();
          }
          if (this.recipient) {
            await this.selectWithNewCardsTab();
          }
        } else {
          return;
        }
        await this.f7router.refreshPage();
        return;
      }
      (await this.nocardNumberOrPINError) ===
        "Please enter corrrect avo¢ash Code and PIN!";
    },
    async transferCardBasketAmount() {
      await self.$store.dispatch(
        "card/getCardBasketAmount",
        this.totalCardbasket
      );
    },
    async selectWithNewCardsTab() {
      const self = this;

      if (
        this.f7router.currentRoute.url ===
          `/pickup/with-recipient/${this.recipient.id}/pick-up` ||
        this.f7router.currentRoute.url ===
          `/topupwithrecipient/${this.recipient.id}/top-up`
      ) {
        f7.tab.show("#withnewcards");
      }
    },

    async redirectAfterCardCheck() {
      const self = this;

      if (
        this.f7router.currentRoute.path === "/send-cash" &&
        !this.queryerror
      ) {
        f7.dialog
          .create({
            text: `Do you want to send ${self.totalCardbasket}`,
            backdrop: true,
            buttons: [
              {
                text: "Top-up my account",
                color: "auto",
                bold: true,
                onClick() {
                  self.selectAccountToTopup();
                },
              },
              {
                text: "Send cash",
                color: "red",
                bold: true,
                onClick() {
                  this.f7router.navigate("/recipients");
                },
              },
              {
                text: "Contribute to tontine",
                onClick() {
                  this.f7router.navigate("/tontines");
                },
              },
              {
                text: "Contribute to association",
                onClick() {
                  this.f7router.navigate("/associations");
                },
              },
              {
                text: "Contribute to campaign",
                onClick() {
                  this.f7router.navigate("/wallets/public");
                },
              },
              {
                text: "Add more cards!",
                close: true,
              },
            ],
            verticalButtons: true,
          })
          .open();
      } else {
        return false;
      }
    },
    async removeCardItem(carditem) {
      const self = this;

      await self.$store.dispatch("card/removeCarditem", carditem.id);
      f7.preloader.show("multi");
      await this.f7router.refreshPage();
      f7.preloader.hide();
      await this.selectWithNewCardsTab();
      await self.$store.dispatch("card/loadCarditemlist");
      if (
        parseFloat(this.totalCardbasket) > 0 ||
        parseFloat(this.amountToSend) > 0
      ) {
        await this.$emit("getCardBAsketAmount", this.totalCardbasket);
        await this.calculateTransferOrPayment();
      }
    },

    async calculateTransferOrPayment() {
      const self = this;

      if (
        [
          "/send-cash",
          "/pickup/with-recipient",
          "/topup/with-recipient",
        ].includes(this.f7router.currentRoute.url)
      ) {
        this.amountToSend = parseFloat(this.totalCardbasket);
        await this.calculateTransfer();
      } else if (
        this.f7router.currentRoute.url.includes(
          "/tonine/contribute/" ||
            "/association/contribute/" ||
            "/wallet/contribute/"
        )
      ) {
        this.amountToPay = parseFloat(this.totalCardbasket);
        await this.calculatePayment();
      }
    },

    async updateCarditem(carditem) {
      const self = this;

      if (
        parseFloat(carditem.cardAmount) <=
        parseFloat(carditem.card.availableBalance)
      ) {
        await self.$store.dispatch("card/updateCarditem", {
          cardItemId: carditem.id,
          cardAmount: parseFloat(carditem.cardAmount),
        });
      } else if (carditem.cardAmount === "" || carditem.cardAmount === 0) {
        carditem.cardAmount = 0;
        this.cardAmount = 0;
        await self.$store.dispatch("card/updateCarditem", {
          cardItemId: carditem.id,
          cardAmount: parseFloat(carditem.cardAmount),
        });
      } else {
        carditem.cardAmount = parseFloat(carditem.card.availableBalance);
      }
      await this.$emit("getCardBAsketAmount", this.totalCardbasket);
      await this.calculateTransferOrPayment();
    },
    // async removeCardItemForPayment(carditem) {
    //     const self = this;
    //
    //
    //     await self.$store.dispatch("card/removeCarditem", carditem.id);
    //     await f7.preloader.show("multi");
    //     await this.f7router.refreshPage();
    //     await f7.preloader.hide();
    //     await this.selectWithNewCardsTab();
    //     await self.$store.dispatch("card/loadCarditemlist");
    //     if (
    //         parseFloat(this.totalCardbasket) > 0 ||
    //         parseFloat(this.amountToPay) > 0
    //     ) {
    //         await this.calculatePayment();
    //     }
    // },
    // async updateCarditemForPayment(carditem) {
    //     const self = this;
    //
    //     if (
    //         parseFloat(carditem.cardAmount) <=
    //         parseFloat(carditem.card.availableBalance)
    //     ) {
    //         await self.$store.dispatch("card/updateCarditem", {
    //             cardItemId: carditem.id,
    //             cardAmount: parseFloat(carditem.cardAmount)
    //         });
    //         this.amountToPay = parseFloat(this.totalCardbasket);
    //         console.log("total card: " + this.amountToPay);
    //         await this.calculatePayment();
    //         await this.amountToPayChange();
    //     } else if (carditem.cardAmount === "" || carditem.cardAmount === 0) {
    //         carditem.cardAmount = 0;
    //         this.cardAmount = 0;
    //         await self.$store.dispatch("card/updateCarditem", {
    //             cardItemId: carditem.id,
    //             cardAmount: parseFloat(carditem.cardAmount)
    //         });
    //         this.amountToPay = parseFloat(this.totalCardbasket);
    //         await this.calculatePayment();
    //         await this.amountToPayChange();

    //     } else {
    //         carditem.cardAmount = parseFloat(carditem.card.availableBalance);
    //         await self.$store.dispatch("card/updateCarditem", {
    //             cardItemId: carditem.id,
    //             cardAmount: parseFloat(carditem.card.availableBalance)
    //         });
    //         this.amountToPay = parseFloat(this.totalCardbasket);
    //         await this.calculatePayment();
    //         await this.amountToPayChange();
    //     }
    // },
    amountToPayChange() {
      const self = this;

      console.log(this.recipientCountry);
      if (this.recipientCountry && parseFloat(this.totalCardbasket) > 0) {
        self.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              // if (
              //     parseFloat(this.amountToPay) <=
              //     parseFloat(this.wallet.accountBalance)
              // ) {
              //     this.calculatePayment();
              // } else if (
              //     parseFloat(this.amountToPay) >
              //     parseFloat(this.wallet.accountBalance)
              // ) {
              this.amountToPay = parseFloat(this.totalCardbasket);
              this.calculatePayment();
              // } else {
              //     return false;
              // }
            }, 500);
          })
          .catch((err) => {});
      } else {
        return false;
      }
    },
    // async amountToSendChange() {
    //     const self = this;
    //

    //     if (this.recipientCountry) {
    //         await self.$store
    //             .dispatch("transaction/getCurrencyRate", {
    //                 fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
    //                 toCurrencyCode: this.recipientCountry.toCurrencyCode
    //             })

    //         await this.calculateTransfer(); // await self.$store.dispatch("transaction/calculateTransfer", {

    //     } else {
    //         return false;
    //     }
    // },

    async removeCarditemlist() {
      const self = this;

      await self.$store.dispatch("card/removeAllCardItems");
      await this.f7router.refreshPage();
    },

    // updateCardBasket() {
    //     const self = this;
    //
    //     if (parseFloat(this.amountToSend) >= parseFloat(this.totalCardbasket)) {
    //         f7.dialog.alert(
    //             this.amountToSend +
    //             " is higher than cardBaketAMount " +
    //             this.totalCardbasket
    //         );
    //         _.forEach(this.carditemlist, function(value, key, index) {
    //             console.log(index);
    //         });
    //         this.amountToSend = parseFloat(this.totalCardbasket);
    //         console.log(this.amountToSend);
    //     }
    // }
  },
  computed: {
    totalCardbasket() {
      return _.reduce(
        this.carditemlist,
        (amount, carditem) => amount + parseFloat(carditem.cardAmount || 0),
        0
      );
    },
    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    destinationCty() {
      return _(this.destinationCity)
        .groupBy("countryName")
        .pick([this.recipientCountry.toCountryName]);
    },
    recipientCountry() {
      return _.find(this.destinationCountry, [
        "toCountryId",
        this.destinationctryId,
      ]);
    },
  },
};
