<template>
  <f7-page class="bankAccount_page">
    <f7-navbar
      sliding
      no-shadow
      :back-link="$t('common.backButton')"
      :back-link-show-text="false"
    >
      <f7-nav-title sliding>{{ $t("dashboard.myBankCards") }}</f7-nav-title>
      <f7-nav-right v-if="this.isTablet == false">
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios strong-ios media-list>
          <f7-card outline header-divider raised v-if="this.baseTransfer.amount !== ''">
            <f7-card-header>{{ $t("common.paymentDetails") }}</f7-card-header>
            <f7-list media-list>
              <f7-list-item
                :header="$t('common.amount')"
                :title="
                  $filtersToCurrency.toCurrency(
                    this.baseTransfer.amount,
                    null,
                    this.baseTransfer.currency
                  )
                " />
              <f7-list-item :header="$t('common.tag')" :title="this.baseTransfer.tag" />
              <f7-list-item
                :header="$t('common.description')"
                :title="this.baseTransfer.description"
            /></f7-list>
          </f7-card>
          <div id="paymentForm" class="list-group">
            <!-- Pay with a registred Credit card panel -->
            <div id="walletPart" class="card rounded mb-5">
              <div id="walletHeader" class="card-header">
                <h5 class="mb-0 no-margin-vertical">
                  <button
                    id="walletToggle"
                    class="btn btn-link button button-large"
                    data-toggle="collapse"
                    style="white-space: normal"
                    data-target="#walletPanel"
                    aria-expanded="true"
                    aria-controls="walletPanel"
                  >
                    <span id="walletTitleLabel" class="block-title block-title-large">{{
                      $t("bankCard.paywithregisteredcard")
                    }}</span>
                  </button>
                </h5>
              </div>
              <div
                id="walletPanel"
                class="collapse show"
                aria-labelledby="walletHeader"
                data-parent="#body"
              >
                <div id="walletPanelBody" class="card-body">
                  <!-- paymendatuthorization error message -->
                  <div
                    id="walletAuthorizeInvalidFeedback"
                    class="invalid-feedback text-color-red block block-strong"
                  >
                    <!-- {{ $t("bankCard.paymentAuthfailed") }} -->
                  </div>

                  <!-- registered card list -->
                  <li class="item-content item-input">
                    <div class="form-row item-inner">
                      <div
                        class="item-title item-floating-label"
                        id="creditCardIdLabel"
                        for="creditCardId"
                      >
                        {{ $t("bankCard.selectCard") }}
                      </div>
                      <div
                        id="creditCardIdGroup"
                        class="mb-3 item-input-wrap input-dropdown-wrap"
                      >
                        <!-- <label id="creditCardIdLabel" for="creditCardId">Select a card</label
                  ><br /> -->
                        <select id="creditCardId" class="form-control">
                          <option value=""></option>
                        </select>
                      </div>
                    </div>
                  </li>
                  <li class="item-content item-input item-input-with-info">
                    <div class="form-row item-inner">
                      <!-- CVC (Card Verification Code) input -->
                      <div
                        class="item-title item-floating-label"
                        id="walletCvxLabel"
                        for="walletCvx"
                      >
                        {{ $t("bankCard.cvc") }}
                      </div>
                      <div id="walletCvxGroup" class="col-md-4 mb-3 item-input-wrap">
                        <!-- <label id="walletCvxLabel" for="walletCvx"
                    >Card Verification Code</label
                  > -->
                        <input
                          id="walletCvx"
                          class="form-control"
                          type="text"
                          placeholder="123"
                          required
                          validate
                          data-error-message="Invalid card verification code"
                        />
                        <span class="input-clear-button"></span>
                        <!-- CVC error message -->
                        <!-- Invalid card verification code -->
                        <div
                          class="item-input-info color-red text-color-red invalid-feedback"
                          id="walletCvxInvalidFeedback"
                        ></div>
                      </div>
                    </div>
                  </li>

                  <!-- Buttons -->
                </div>
              </div>
              <div class="form-row grid cols40_60">
                <a
                  id="walletCancelButton"
                  @click="walletCancelButton()"
                  class="btn button button-fill button-large color-primary btn-warning"
                  style="float: left; width: 100%; border-bottom-left-radius: 16px"
                  >{{ $t("common.cancel") }}</a
                >
                <button
                  id="walletValidationButton"
                  @click="walletValidationButton()"
                  class="btn btn-primary col button button-large button-fill color-red"
                  style="float: right; border-bottom-right-radius: 16px"
                  type="submit"
                >
                  {{ $t("common.validate") }}
                </button>
              </div>
            </div>

            <!-- Pay with a new Credit card panel -->
            <div id="creditCardPart" class="card rounded">
              <div id="creditCardHeader" class="card-header">
                <h5 class="mb-0 no-margin-bottom no-margin-top">
                  <button
                    id="creditCardToggle"
                    class="btn btn-link button button-large"
                    data-toggle="collapse"
                    style="white-space: normal"
                    data-target="#creditCardPanel"
                    aria-expanded="false"
                    aria-controls="creditCardPanel"
                  >
                    <span
                      id="creditCardTitleLabel"
                      class="block-title block-title-large"
                      >{{ $t("bankCard.paywithnewcard") }}</span
                    >
                  </button>
                </h5>
              </div>

              <div
                id="creditCardPanel"
                class="collapse show card-content card-content card-content-padding"
                aria-labelledby="creditCardHeader"
                data-parent="#body"
              >
                <!-- payment authorization error message -->
                <div
                  id="authorizeInvalidFeedback"
                  class="invalid-feedback text-color-red block block-strong"
                >
                  <!-- {{ $t("bankCard.paymentAuthfailed") }} -->
                </div>

                <div id="creditCardPanelBody" class="card-body margin-vertical">
                  <!-- Credit card brand input -->
                  <div
                    id="brandGroup"
                    class="mb-3 list media-list list-strong-ios list-outline-ios list-dividers-ios"
                  >
                    <ul>
                      <li>
                        <a
                          href="#"
                          class="item-link smart-select smart-select-init"
                          :data-open-in="this.isTablet ? 'popover' : 'sheet'"
                          data-close-on-select="“true”"
                          data-sheet-swipe-to-close="true"
                        >
                          <select name="brandGroup" v-model="selectedCardNetWork">
                            <option
                              v-for="brand in this.cardNetWork"
                              :id="brand.id"
                              :value="brand.value"
                              selected
                              :data-option-color="brand.color"
                              :data-option-image="brand.img"
                            >
                              {{ brand.name }}
                            </option>
                            <!-- <option id="brandCb" value="CB" selected data-option-color="teal"
                              data-option-image="/payment_icons/CB.png">
                              CB
                            </option>
                            <option value="VISA" id="brandVisa" data-option-color="primary"
                              data-option-image="/payment_icons/VISA.png">
                              VISA
                            </option>
                            <option value="MASTERCARD" id="brandMasterCard" data-option-color="orange"
                              data-option-image="/payment_icons/Mastercard.png">
                              MasterCard
                            </option>
                            <option value="MAESTRO" id="brandMasterCard" data-option-color="red"
                              data-option-image="/payment_icons/Maestro.png">
                              Maestro
                            </option> -->
                          </select>
                          <div class="item-content">
                            <!-- <div class="item-media">
                              <img src="/payment_icons/CB.png" style="width: 24px; margin: auto; height: 24px" />
                            </div> -->
                            <div class="item-inner">
                              <div class="item-title">
                                {{ $t("bankCard.selectCardBrand") }}
                              </div>
                            </div>
                          </div>
                        </a>
                      </li>

                      <!-- <div class="d-flex justify-content-around grid grid-cols-4">
                      <label for="brandCb" class="bgCb radio grid grid-rows-1">
                        <input
                          type="radio"
                          id="brandCb"
                          name="brand"
                          value="CB"
                          class="m-1"
                        /><i class="icon-radio"></i>

                        <img
                          src="/payment_icons/CB.png"
                          style="width: 48px; margin: auto"
                        />

                      </label>
                      <div class="d-flex align-items-center">
                        <label for="brandVisa" class="bgVisa radio grid grid-cols-2"
                          ><input
                            type="radio"
                            id="brandVisa"
                            name="brand"
                            value="VISA"
                            class="m-1" /><i class="icon-radio"></i
                          ><img
                            src="/payment_icons/VISA.png"
                            style="width: 48px; margin: auto"
                        /></label>
                      </div>
                      <div class="d-flex align-items-center">
                        <label for="brandMasterCard" class="bgMasterCard radio">
                          <input
                            type="radio"
                            id="brandMasterCard"
                            name="brand"
                            value="MASTERCARD"
                            class="m-1" /><i class="icon-radio"></i
                          ><img src="/payment_icons/Mastercard.png" width="48px"
                        /></label>
                      </div>
                      <div class="d-flex align-items-center">
                        <label for="brandMaestro" class="bgMaestro radio">
                          <input
                            type="radio"
                            id="brandMaestro"
                            name="brand"
                            value="MAESTRO"
                            class="m-1" />
                          <i class="icon-radio"></i
                          ><img src="/payment_icons/Maestro.png" width="48px"
                        /></label>
                      </div> -->

                      <!-- </div> -->
                      <div
                        id="brandInvalidFeedback"
                        class="invalid-feedback block-header tex-color-red"
                      >
                        <!-- {{ $t("bankCard.selectpaymentNetwork") }} -->
                      </div>
                    </ul>
                  </div>

                  <!-- Credit card number input -->
                  <div class="form-row">
                    <li class="mb-3 item-content item-input item-input-with-info">
                      <div class="item-inner">
                        <div
                          class="item-title item-floating-label"
                          id="creditCardNumberLabel"
                        >
                          {{ $t("bankCard.cardNumber") }}
                        </div>
                        <!-- <label id="creditCardNumberLabel">Credit Card number</label> -->
                        <div class="item-input-wrap">
                          <input
                            id="creditCardNumber"
                            class="form-control"
                            type="text"
                            required
                            validate
                            placeholder="0000 1111 2222 3333"
                            data-error-message="Invalid credit card number (16 digits)"
                          />
                          <!-- credit card number error message -->
                          <span class="input-clear-button"></span>
                          <!-- Invalid credit card number (16 digits) -->
                          <div
                            id="creditCardNumberInvalidFeedback"
                            class="invalid-feedback item-input-info color-red text-color-red"
                          ></div>
                        </div>
                      </div>
                    </li>
                  </div>

                  <div class="form-row grid grid-gap grid-cols-2">
                    <!-- Expiration date input -->
                    <li
                      id="expirationDateGroup"
                      class="item-input-with-info item-content item-input"
                    >
                      <div class="item-inner">
                        <div
                          class="item-title item-floating-label"
                          id="expirationDateLabel"
                          for="expirationDate"
                        >
                          {{ $t("bankCard.expirationDate") }}
                        </div>
                        <!-- <label id="expirationDateLabel" for="expirationDate"
                    >Expiration Date</label
                  > -->
                        <div class="item-input-wrap">
                          <input
                            id="expirationDate"
                            class="form-control"
                            placeholder="MM/YY"
                            type="text"
                            readonly="readonly"
                            required
                            validate
                            data-error-message="Invalid expiration date (MM/YY)"
                          />
                          <span class="input-clear-button"></span>
                          <!-- date format error message -->
                          <div
                            id="expirationDateInvalidFeedback"
                            class="invalid-feedback item-input-info color-red text-color-red"
                          >
                            <!-- Invalid expiration date (MM/YY) -->
                          </div>
                        </div>
                      </div>
                    </li>

                    <!-- CVC (Card Verification Code) input -->
                    <li
                      id="cvxGroup"
                      class="mb-3 item-content item-input item-input-with-info"
                    >
                      <div class="item-inner">
                        <div
                          class="item-title item-floating-label"
                          id="cvxLabel"
                          for="cvx"
                        >
                          {{ $t("bankCard.cvc") }}
                        </div>
                        <!-- <label id="cvxLabel" for="cvx">Card Verification Code</label> -->
                        <div class="item-input-wrap">
                          <input
                            id="cvx"
                            class="form-control"
                            type="text"
                            placeholder="123"
                            readonly="readonly"
                            required
                            validate
                            data-error-message="Invalid card verification code"
                          />
                          <span class="input-clear-button"></span>
                          <!-- CVC error message -->
                          <div
                            id="cvxInvalidFeedback"
                            class="invalid-feedback item-input-info color-red text-color-red"
                          >
                            Invalid card verification code
                          </div>
                        </div>
                      </div>
                    </li>
                  </div>

                  <!-- Card holder input -->
                  <li class="form-row item-content item-input item-input-with-info">
                    <div id="cardHolderGroup" class="item-inner">
                      <div
                        class="item-title item-label"
                        id="cardHolderLabel"
                        for="cardHolder"
                      >
                        {{ $t("bankCard.holderName") }}
                      </div>
                      <!-- <label id="cardHolderLabel" for="cardHolder">Holder name</label> -->
                      <div class="item-input-wrap">
                        <input
                          id="cardHolder"
                          class="form-control"
                          type="text"
                          required
                          validate
                          :value="holder_name"
                          @input="holder_name = $event.target.value"
                          data-error-message="Invalid holder name"
                        />
                        <span class="input-clear-button"></span>
                        <div
                          id="cardHolderInvalidFeedback"
                          class="invalid-feedback item-input-info color-red text-color-red"
                        ></div>
                      </div>
                    </div>
                  </li>

                  <!-- Card memorization input -->
                  <div id="memorizeCreditCardGroup" class="form-check">
                    <label
                      for="memorizeCreditCard"
                      class="form-check-label item-checkbox item-content"
                    >
                      <input
                        id="memorizeCreditCard"
                        type="checkbox"
                        class="form-check-input"
                      />
                      <i class="icon icon-checkbox"></i>
                      <div class="item-inner">
                        <div class="item-title" id="memorizeCreditCardLabel">
                          {{ $t("bankCard.saveCardforNext") }}
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <!-- Buttons -->
              <div class="form-row grid cols40_60">
                <a
                  id="cancelButton"
                  @click="cancelButton()"
                  class="btn btn-warning button button-fill button-large color-primary"
                  style="float: left; width: 100%; border-bottom-left-radius: 16px"
                  >{{ $t("common.cancel") }}</a
                >

                <button
                  id="validationButton"
                  @click="validationButton()"
                  class="btn color-red button button-large button-fill"
                  style="border-bottom-right-radius: 16px"
                  type="submit"
                >
                  {{ $t("common.validate") }}
                </button>
              </div>
            </div>
          </div>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped>
#walletToggle,
#creditCardToggle {
  box-shadow: var(--f7-button-box-shadow);
}
</style>
<script>
import {
  f7List,
  f7ListGroup,
  f7BlockHeader,
  f7ListInput,
  theme,
  f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import Navbarright from "../components/navbarright.vue";
import card_network from "../../public/data/card_network.json";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    f7List,
    f7ListGroup,
    f7BlockHeader,
    f7ListInput,
    navbarright: Navbarright,
  },
  data() {
    return {
      theme,
      f7,
      number: "",
      bic: "",
      holder_name: "",
      tag: "",
      type: "bankAccount",
      forms: "",
      selectedCardNetWork: "CB",
      cardNetWork: card_network,
    };
  },
  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    const styleCSSCustom = {
      ".invalid-feedback": {
        color: "red",
        "font-weight": "bold",
      },
      "#GreenLabel": {
        color: "green",
        "font-weight": "bold",
      },
      body: {
        "background-color": "transparent",
      },
      "#walletPart": {
        "border-radius": "16px",
      },
      "#creditCardPart": {
        "border-radius": "16px",
      },
      "#walletHeader": {
        "background-color": "#ffff",
      },
      "#creditCardHeader": {
        "background-color": "#ffff",
      },
      "#walletCancelButton": {
        width: "48%",
      },
      "#walletValidationButton": {
        width: "48%",
      },
      "#cancelButton": {
        width: "48%",
      },
      "#validationButton": {
        width: "48%",
      },
    };
    if (this.cardInResponse && this.cardInResponse.payment_url !== "") {
      SecureCb.injectIframe(
        "paymentForm",
        this.cardInResponse.payment_url,
        this.cardInResponse.payment_token,
        styleCSSCustom
      );
    }
    const expirationDateCalendar = f7.calendar.create({
      inputEl: "#expirationDate",
      openIn: "auto",
      closeOnSelect: true,
      headerPlaceholder: this.$t("bankCard.expirationDate"),
      header: true,
      footer: true,
      dateFormat: "mm/yy",
      minDate: new Date(),
    });
    if (this.profile) {
      this.holder_name = this.profile.name;
    } else if (this.userAccount) {
      this.holder_name = this.userAccount.name;
    }
  },

  methods: {},
  computed: {
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "bankCard",
      "bankCardList",
      "accounts",
      "cardInResponse",
      "baseTransfer",
    ]),
    ...mapState("account", ["userAccount"]),

    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "documentsUrl", "document"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
</script>
