<template>
  <f7-page hide-bars-on-scroll @page:beforeremove="onPageBeforeRemove">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-if="!this.paymentTransaction && !!this.QRCode && !this.decodedQRCode">{{
        $t("paywithnewqrcode.issueNewQr") }}</f7-nav-title>
      <f7-nav-title sliding v-if="!!this.decodedQRCode">{{
        $t("paywithnewqrcode.transferAmount")
      }}</f7-nav-title>
      <f7-nav-title sliding v-if="this.paymentTransaction.isValidated === false &&
          this.decodedQRCode.type === 'avo¢ash pay'
          ">{{ $t("paywithnewqrcode.validateTransferpayment") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <span v-if="!this.paymentTransaction && this.QRCode">
          <f7-list dividers-ios strong-ios>
            <f7-block-title class="margin-left">{{
              $t("paywithnewqrcode.recipientCanScan")
            }}</f7-block-title>
            <h5 class="margin-horizontal">
              {{ $t("paywithnewqrcode.amountToPay") }}:
              <span v-html="$filtersToCurrency.toCurrency(
                this.paymentData.amount,
                this.primaryAccount.currencySymbolNative,
                this.paymentData.paymentCurrency
              )
                "></span>
            </h5>
            <img :src="this.QRCode" class="QRCode" alt="QRCode" style="display: block; margin: auto" />
            <f7-block-footer>{{ this.paymentData.description }}</f7-block-footer>
          </f7-list>

          <f7-button ripple outline raised large color="red" class="margin" @click="deleteQRCode()">{{
            $t("paywithnewqrcode.deleteQrCode") }}</f7-button>
        </span>
        <f7-list dividers-ios strong-ios v-if="!!this.decodedQRCode" media-list>
          <f7-list-item :title="$t('paywithnewqrcode.youPay')" :after="$filtersToCurrency.toCurrency(
            this.decodedQRCode.amount,
            '',
            this.decodedQRCode.paymentCurrency
          )
            " :footer="$t('common.to') + this.decodedQRCode.payee.name"></f7-list-item>

          <f7-button raised fill ripple large class="margin" color="red" strong @click="payNowWithQrCode()">{{
            $t("paywithnewqrcode.payNow") }}</f7-button>
        </f7-list>

        <f7-list v-if="this.paymentTransaction.isValidated === false &&
          this.decodedQRCode.type === 'avo¢ash pay'
          " media-list>
          <f7-list-group>
            <f7-block-title color="green" class="text-align-center">{{
              $t("paywithnewqrcode.transferTo", {
                thePayee: this.paymentTransaction.payeeName,
              })
            }}</f7-block-title>
            <f7-list-item :title="$t('paywithnewqrcode.youPaid')" :after="$filtersToCurrency.toCurrency(
              this.paymentTransaction.grossPaymentAmount,
              this.paymentTransaction.fromCurrencySymbol,
              this.paymentTransaction.fromCurrencyCode
            )
              "></f7-list-item>
            <f7-block-title>{{
              $t("paywithnewqrcode.finalStepvalidatePayment")
            }}</f7-block-title>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferCode") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferPin") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.paymentTransaction.transferCode }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ this.paymentTransaction.transferPIN }}
                  </div>
                </div>
              </div>
            </li>
          </f7-list-group>
        </f7-list>
        <f7-button v-if="this.paymentTransaction.isValidated === false" raised fill ripple large class="margin"
          color="red" strong @click="validateDebitTransaction(paymentTransaction)">{{ $t("common.validatePayment") }}
        </f7-button>
        <f7-button v-if="this.paymentTransaction.isValidated === false" raised outline ripple large small class="margin"
          color="primary" strong @click="cancelPayment(paymentTransaction)">{{ $t("common.cancelPayment") }}
        </f7-button>
      </div>
      <div class="right_layout"></div>
    </div>
    <template #fixed>
      <f7-fab v-if="!this.paymentTransaction && this.QRCode" position="right-bottom" class="shareButton" color="auto"
        @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.paymentCountry .smart-select .item-after {
  text-align: right !important;
}
</style>
<script>
import { f7, theme } from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import { paymentConnect } from "../js/websocket/paymentWebsocket.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";

import { mapGetters, mapState } from "vuex";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    paymentData: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [calculateTransferPayment, transferAgain, paymentConnect, socialShare],
  data() {
    return {
      theme,
      f7,
      amountToPay: "",
      recipientuser: "",
      paymentCountry: "",
      isssueQRPaymentErrorMessage: "",
      paymentCurrency: "",
      countryCode: "",
      description: "",
      fromAccount: "",
      toastText: "",
      description: "",
      connected: false,
      service: "",
      type: "",
      path: "",
      socket: "",
      stompClient: "",
      sharedContent: "",
    };
  },

  mounted: function () {
    const self = this;
    console.log("paymentData ", this.paymentData);
    if (this.profile) {
      if (
        (this.primaryAccount === "" || this.businessAccount === "") &&
        this.$keycloak.authenticated
      ) {
        self.$store.dispatch("account/loadPrimaryAccount");
        self.$store.dispatch("account/loadBusinessAccount");
      }
      if (this.destinationCountry === "" && this.$keycloak.authenticated) {
        self.$store.dispatch("transaction/loadDestinationCtry");
      }
      this.paymentCurrency = this.primaryAccount.currencyCode;

      this.type = "avo¢ash pay";
      this.path = import.meta.env.VITE_APP_URL;
    }
  },
  methods: {
    async payNowWithQrCode() {
      const self = this;

      await f7.preloader.show("multi");
      await self.$store.dispatch("payment/makePayment", {
        type: "avo¢ash pay",
        amount: this.decodedQRCode.amount,
        paymentCurrency: this.decodedQRCode.paymentCurrency,
        payerAccountNumber: this.decodedQRCode.payerAccountNumber,
        payerGeolocation: this.latitude + "-" + this.longitude,
        payerIpAddress: this.ipAddress,

        payeeAccountNumber: this.decodedQRCode.payeeAccountNumber,
        payeeGeolocation: this.decodedQRCode.payeeGeolocation,
        payeeIpAddress: this.decodedQRCode.payeeIpAddress,
        description: this.decodedQRCode.description,
      });

      if (this.fromAccount === "Primary") {
        await self.$store.dispatch("account/loadPrimaryAccount");
      } else {
        await self.$store.dispatch("account/loadBusinessAccount");
      }
      await f7.preloader.hide();
    },
    deleteQRCode() {
      const self = this;

      self.$store.state.payment.QRCode = "";
      self.$store.dispatch("payment/setDecodedQRCode", "");
      this.amountToPay = "";
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
      self.$store.dispatch("payment/deleteQRCode", this.qrCodeData.id);
      this.disconnectPaymentService();
    },
    onPageBeforeRemove() {
      const self = this;

      this.deleteQRCode();
    },
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
      "qrCodeData",
      // "paymentData",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("remit", ["recipients", "allrecipients", "userDetail"]),

    ...mapState("location", [
      "country",
      "destinationCity",
      "currentUserCountry",
      "latitude",
      "longitude",
      "ipAddress",
    ]),

    ...mapState("account", ["primaryAccount", "businessAccount"]),
    ...mapState("auth", ["profile"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    recipientCountry() {
      return _.find(this.destinationCountry, ["toCountryId", this.paymentCountry]);
    },
    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    payerAccount() {
      // if (this.fromAccount === "Primary") {
      return this.primaryAccount;
      // } else {
      //   return this.businessAccount;
      // }
    },
  },
};
</script>
