<template>
  <f7-page name="paycash" hide-bars-on-scroll @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">

      <f7-nav-title sliding v-html="this.title"></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar bottom no-shadow class="keypadToolbar avocash">
      <f7-link tab-link="#payWithQRCode" icon-aurora="f7:creditcard" icon-ios="f7:creditcard" icon-md="material:payment"
        style="text-transform: lowercase" :text="$t('paycash.pay')"></f7-link>

      <f7-link tab-link="#payWithavocashNumber" class="payWithavocashNumberTab" style="text-transform: lowercase"
        icon-ios="f7:square_arrow_up_on_square" icon-aurora="f7:square_arrow_up_on_square"
        icon-md="f7:square_arrow_up_on_square" :text="$t('paycash.cash')">
      </f7-link>
      <f7-link tab-link="#pickupTransfer" icon-ios="material:account_balance_wallet"
        icon-aurora="material:account_balance_wallet" icon-md="material:account_balance_wallet"
        style="text-transform: lowercase" :text="$t('paycash.pickup')"></f7-link>
    </f7-toolbar>
    <f7-tabs swipeable>
      <f7-tab id="payWithQRCode" tab-active @tab:show="showPayTab()">
        <form>
          <div class="block passcode-input">
            <input type="text" :value="this.amountToPay" id="demo-numpad-inline" />
          </div>
          <!-- TODO Manage to remove double keypad in case of page refresh -->
          <div class="block block-strong no-padding no-margin passcode-numpad">
            <div id="numpad-inline-container"></div>
            <div class="margin-bottom-half payButtons grid grid-cols-3">
              <!-- You pay give cash to someone -->
              <!-- <f7-col tag="span"> -->
              <f7-button color="gray" class="roundButtons" outline text-color="red" round-md round-ios
                @click="goToPay()">{{ $t("paycash.pay") }}</f7-button>
              <!-- </f7-col> -->
              <!-- You issue a QR code with an amount to either pay someone or claim payment
              from them -->
              <!-- <f7-col tag="span"> -->
              <f7-button outline round class="roundButtons" color="gray" @click="payWithQRCode()">{{
                $t("paycash.qrCodePay") }}</f7-button>
              <!-- </f7-col> -->

              <!-- You request a payment from someone by sending a payment  request which they can validate
              Risk of spam/ random users sending request to unknown users!! Maybe limit to contact list of get end-user/payer
              to validate request attempt before request is issued-->

              <!-- <f7-col tag="span"> -->
              <f7-button outline round class="roundButtons" color="gray" style="color: #0009ff"
                @click="goToRequestPayment()">{{ $t("paycash.request") }}</f7-button>
              <!-- </f7-col> -->
            </div>
          </div>
        </form>
      </f7-tab>
      <f7-tab id="payWithavocashNumber" @tab:show="showATMTab()">
        <form>
          <div class="block passcode-input">
            <input type="text" :value="this.amountToCashOut" id="demo-numpad-inline2" />
          </div>
          <div class="block block-strong no-padding no-margin passcode-numpad">
            <div id="numpad-inline-container2"></div>
            <div tag="p" no-gap class="margin-bottom-half payButtons grid grid-cols-3">
              <!-- You withdraw cash and transfer amount to the person giving the cash to you -->

              <!-- <f7-col tag="span"> -->
              <f7-button color="gray" outline text-color="red" round class="roundButtons" @click="goToWithdrawCash()">
                {{ $t("paycash.withdraw") }}</f7-button>
              <!-- </f7-col> -->
              <!-- You issue a QR code with an amount to either get transferred the amount and give cash or get the code scan and transfer the amount and receive cash -->
              <!-- <f7-col tag="span"> -->
              <f7-button outline round color="gray" class="roundButtons" @click="cashOutWithQRCode()">{{
                $t("paycash.qrCodeOut") }}</f7-button>
              <!-- </f7-col> -->
              <!-- You receive a transfer and give cash to the person transferring the cash to you -->
              <!-- <f7-col tag="span"> -->
              <f7-button outline round class="roundButtons" style="color: #0009ff" color="gray"
                @click="goToATMOutCash()">{{ $t("paycash.atmOut") }}</f7-button>
              <!-- </f7-col> -->
            </div>
          </div>
        </form>
      </f7-tab>
      <f7-tab id="pickupTransfer" @tab:show="pickupTransfer()">
        <avocashPickup-page />
      </f7-tab>
    </f7-tabs>

    <f7-popup class="selectAvocashUser" :swipe-to-close="false" :tablet-fullscreen="true" :backdrop="false"
      :close-by-backdrop-click="false" :close-on-escape="false" @popup:close="resetPaymentDetail()"
      style="min-height: 70%">
      <f7-page>
        <f7-navbar :mdCenterTitle="true">
          <div class="left margin-left"></div>
          <div class="title popupTitle">
            <span v-if="this.paymentCurrency !== null" v-html="$filtersToCurrency.toCurrency(this.amount, null, this.paymentCurrency)
              "></span>
          </div>
          <div class="right">
            <f7-link popup-close icon-f7="xmark_circle"></f7-link>
          </div>
        </f7-navbar>
        <f7-list dividers-ios strong-ios media-list>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-label">
                {{ $t("paycash.findUser") }}
              </div>
              <div class="item-input-wrap">
                <input type="text" @blur="clearSearch()" @keydown="updateList()"
                  :placeholder="$t('paycash.searchAvoashUser')" id="autocomplete-dropdown-ajax-typeahead" />
                <span class="input-clear-button" @click="clearSearch()"></span>
              </div>
            </div>
          </li>
          <f7-block-footer style="font-size: 12px" class="no-margin" v-if="this.amountToPay != '' &&
            paymentDetail !== null &&
            parseFloat(paymentDetail.receivedAmount) >= 0 &&
            (this.transactionType === 'avo¢ash pay' ||
              this.transactionType === 'avo¢ash out')
            ">
            <span v-if="this.transactionType === 'avo¢ash pay'">{{
              $t("paycash.beneficiaryGets")
            }}</span>
            <span v-if="this.transactionType === 'avo¢ash out'">{{
              $t("paycash.youGetCash")
            }}</span>
            <span v-if="this.paymentDetail !== null && this.paymentDetail.toCurrencyCode !== null
                " v-html="$filtersToCurrency.toCurrency(
      paymentDetail.receivedAmount,
      null,
      paymentDetail.toCurrencyCode
    )
      "></span>
          </f7-block-footer>
          <f7-list-input :label="$t('common.description')" floating-label type="text" name="Description"
            :placeholder="$t('common.description')" :value="description" @input="description = $event.target.value"
            :error-message="$t('paycash.enterCardHolderName')" clear-button></f7-list-input>
        </f7-list>
        <div id="autocompleteList"></div>
        <f7-list dividers-ios strong-ios media-list id="userRecipientlist" v-if="!selectedUser">
          <f7-block-title medium class="margin-left">{{
            $t("paycash.suggestions")
          }}</f7-block-title>
          <li v-for="recipient in userRecipients" :key="recipient.id">
            <label class="item-radio item-content" :data-value="recipient.userRecipientId" @click="selectUser(recipient)">
              <div class="item-media">
                <f7-badge color="auto" style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">{{
                  $filtersGetInitial.getInitial(recipient.name) }}</f7-badge>
              </div>
              <div class="item-inner">
                <div class="item-title">{{ recipient.name }}</div>
                <div class="item-footer">
                  {{ recipient.phone }}
                </div>
              </div>
            </label>
          </li>
        </f7-list>
        <span v-if="!!selectedUser" style="bottom: 0">
          <f7-toolbar tabbar bottom style="position: absolute; bottom: 0">
            <f7-link v-if="transactionType === 'avo¢ash claim'" raised fill class="link buttons" large bg-color="red"
              text-color="white" strong @click="requestPament()">{{ $t("paycash.requestPayment") }}</f7-link>
            <f7-link v-if="transactionType === 'avo¢ash pay'" raised fill large class="link buttons" bg-color="red"
              text-color="white" strong @click="payNow()">{{ $t("paycash.payNow") }}</f7-link>
            <f7-link v-if="transactionType === 'avo¢ash ATM'" raised fill large class="link buttons" bg-color="red"
              text-color="white" strong @click="requestWithdrawal()">{{ $t("paycash.requestWithdrawalAmount") }}
            </f7-link>
            <f7-link v-if="transactionType == 'avo¢ash out'" raised fill large class="link buttons" bg-color="red"
              text-color="white" strong @click="transferWithdrawl()">{{ $t("paycash.transferWithdrawalAmount") }}
            </f7-link>
          </f7-toolbar>
        </span>
      </f7-page>
    </f7-popup>

    <f7-popup class="issueQRCode" :swipe-to-close="false" :tablet-fullscreen="true" :backdrop="false"
      :close-by-backdrop-click="false" :close-on-escape="false" style="min-height: 70%">
      <f7-page>
        <f7-navbar :mdCenterTitle="true">
          <div class="left"></div>
          <div class="title popupTitle">
            <span v-if="this.paymentCurrency !== null" v-html="$filtersToCurrency.toCurrency(this.amount, null, this.paymentCurrency)
              "></span>
          </div>
          <div class="right">
            <f7-link popup-close icon-f7="xmark_circle"></f7-link>
          </div>
        </f7-navbar>

        <f7-list dividers-ios strong-ios media-list>
          <f7-list-group>
            <f7-list-input :label="$t('common.description')" floating-label type="text" name="Description"
              :placeholder="$t('common.description')" :value="description" @input="description = $event.target.value"
              :error-message="$t('paycash.enterCardHolderName')" clear-button></f7-list-input>
            <f7-block class="grid grid-cols-2" v-if="operationType === 'Pay'">
              <f7-list-item radio :title="$t('paycash.request')" name="transactionType" value="avo¢ash claim"
                :checked="transactionType === 'avo¢ash claim'" @change="(e) => {
                  if (e.target.checked) transactionType = 'avo¢ash claim';
                }
                  "></f7-list-item>

              <f7-list-item radio :title="$t('paycash.pay')" name="transactionType" value="avo¢ash pay"
                :checked="transactionType === 'avo¢ash pay'" @change="(e) => {
                  if (e.target.checked) transactionType = 'avo¢ash pay';
                }
                  "></f7-list-item>
            </f7-block>
            <f7-block class="grid grid-cols-2" v-if="operationType === 'ATM'">
              <f7-list-item radio :title="$t('paycash.atm')" name="transactionType" value="avo¢ash ATM"
                :checked="transactionType === 'avo¢ash ATM'" @change="(e) => {
                  if (e.target.checked) transactionType = 'avo¢ash ATM';
                }
                  "></f7-list-item>

              <f7-list-item radio :title="$t('paycash.withdraw')" name="transactionType" value="avo¢ash out"
                :checked="transactionType === 'avo¢ash out'" @change="(e) => {
                  if (e.target.checked) transactionType = 'avo¢ash out';
                }
                  "></f7-list-item>
            </f7-block>
          </f7-list-group>
        </f7-list>
        <f7-toolbar tabbar bottom style="position: absolute; bottom: 0">
          <f7-link v-if="transactionType === 'avo¢ash claim'" raised fill large class="link buttons" bg-color="red"
            text-color="white" strong @click="issueQRCodeToClaim()">{{ $t("paycash.createQRCodeTorequestPament") }}
          </f7-link>
          <f7-link v-if="transactionType === 'avo¢ash pay'" raised fill large class="link buttons" bg-color="red"
            text-color="white" strong @click="issueQRCodeToPay()">{{ $t("paycash.createQRCodeTorequestToPay") }}
          </f7-link>
          <f7-link v-if="transactionType === 'avo¢ash ATM'" raised fill class="link buttons" bg-color="red"
            text-color="white" large strong @click="createQRCodeToRequestWithdrawal()">{{
              $t("paycash.createQRCodeToRequestWithdrawal") }}
          </f7-link>
          <f7-link v-if="transactionType == 'avo¢ash out'" raised fill class="link buttons" bg-color="red"
            text-color="white" large strong @click="createQRCodeToGiveCash()">{{ $t("paycash.createQRCodeToGiveCash")
            }}</f7-link>
        </f7-toolbar>
        <!--         <f7-list dividers-ios strong-ios  media-list> </f7-list>
        <span v-if="searchedUser || selectedUser" style="bttom: 0">
          <f7-button
            v-if="transactionType === 'avo¢ash claim'"
            raised
            fill
            large
            color="red"
            text-color="white"
            class="margin-horizontal"
            strong
            @click="requestPament()"
          >{{ $t('questPayment') }}</f7-button>
          <f7-button
            v-if="transactionType === 'avo¢ash pay'"
            raised
            fill
            large
            class="margin-horizontal"
            color="red"
            text-color="white"
            strong
            @click="payNow()"
          >{{ $t('yNow') }}</f7-button>
          <f7-button
            v-if="transactionType === 'avo¢ash ATM'"
            raised
            fill
            color="red"
            class="margin-horizontal"
            large
            text-color="white"
            strong
            @click="requestWithdrawal()"
          >{{ $t('questWithdrawalAmount') }}</f7-button>
          <f7-button
            v-if="transactionType == 'avo¢ash out'"
            raised
            fill
            color="red"
            class="margin-horizontal"
            large
            text-color="white"
            strong
            @click="transferWithdrawl()"
          >{{ $t('ansferWithdrawalAmount') }}</f7-button>
        </span> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<style scoped>
.tab-link i.icon .f7- {
  transform: rotate(180deg);
}

/* .keypadToolbar {
  background-color: #fcfcfd;
} */
.popupTitle {
  font-weight: bold;
  font-family: inherit;
  font-size: xxx-large;
}

.navbar-bg {
  background-color: #fff !important;
}

.tab-link-highlight {
  display: none !important;
  /* background: none !important;
  height: 0px;
  width: 0px; */
}

.toolbar.tabbar.toolbar-bottom.keypadToolbar.avocash span.tab-link-highlight {
  display: none !important;
  width: 0%;
}

.row.no-gap {
  --f7-grid-gap: 8px;
  --f7-grid-row-gap: 0px;
}

/* .keypad-button {
  background: none !important;
}
span .keypad-button:after {
  display: none !important;
}
span .keypad-button:before {
  display: none !important;
}
.keypad-button.keypad-button-dark {
  background: none !important;
} */
.payButtons .button-outline {
  box-shadow: var(--f7-elevation-0) !important;
}

.payButtons .roundButtons {
  border-radius: 25px !important;
}
</style>
<script>
import { f7, theme } from "framework7-vue";
import ScanQRToPay from "./payWithQRCodeScan.vue";
import AccountNumberToPay from "./payWithAccountNumber.vue";
import AvocashPickUp from "./avocashATMPickUp.vue";
import Navbarright from "../components/navbarright.vue";
import { mapState, mapGetters } from "vuex";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";
import { payment } from "../js/mixins/payment.js";
import { messageSocket } from "../js/websocket/websocketConnect.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    "scanQRToPay-page": ScanQRToPay,
    "accountNumberToPay-page": AccountNumberToPay,
    "avocashPickup-page": AvocashPickUp,
    navbarright: Navbarright,
  },
  mixins: [calculateTransferPayment, transferAgain, payment, messageSocket],
  data() {
    return {
      theme,
      f7,
      title: "",
      amountToPay: "",
      amountToCashOut: "",
      description: "",
      transactionType: "",
      selectedUser: "",
      dropdownValue: "",
      paymentCurrency: "",
      numpadInlinePay: "",
      numpadInlineATM: "",
      operationType: "Pay",
      amount: "",
    };
  },
  mounted() {
    const self = this;
    if (!this.msgCltConnected) {
      this.connectMessageSocket();
    }
    this.title = this.$t("paycash.pay");
    window.onbeforeunload = (e) => {
      // Turning off the event
      e.preventDefault();
    };
    if (this.numpadInlinePay) {
      this.numpadInlinePay.destroy();
    }
    if (this.numpadInlineATM) {
      this.numpadInlineATM.destroy();
    }
    if (f7.height >= 400) {
      const height1 = `${f7.height / 2}px`;
      const root = document.documentElement;
      root.style.setProperty("--f7-keypad-inline-height", height1);
    }
    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.profile) {
      this.paymentCurrency = this.profile.currencyCode;
    } else if (this.userAccount !== null) {
      this.paymentCurrency = this.userAccount.currencyCode;
    }

    console.log("this.paymentCurrency", this.paymentCurrency);
    this.numpadInlinePay = f7.keypad.create({
      inputEl: "#demo-numpad-inline",
      containerEl: "#numpad-inline-container",
      toolbar: false,
      on: {
        change(keypad, value) {
          if (value.length > 0) {
            console.log(keypad, parseFloat(value));
            value = value.toString();
            f7.$("#demo-numpad-inline").value = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
              ? self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
                .currencySymbolNative
              : self.paymentCurrency
              }${value}`;
            self.amountToPay = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
              ? self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
                .currencySymbolNative
              : self.paymentCurrency
              }${value}`;
            console.log(keypad);
            console.log(parseFloat(self.amountToPay));
            return;
          }
          f7.$("#demo-numpad-inline").value = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
            ? self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
              .currencySymbolNative
            : self.paymentCurrency
            }${0}`;
          console.log(f7.$("#demo-numpad-inline").val());

          self.amountToPay = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
            ? self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
              .currencySymbolNative
            : self.paymentCurrency
            }${0}`;
          // if (value.length === 4) {
          //   f7.di0alog.alert('Thank you! Your passcode is<br><b>' + value + '</b>', function () {
          //     f7.views.main.f7router.back();
          //   });
          // }
        },
      },
    });
    this.numpadInlineATM = f7.keypad.create({
      inputEl: "#demo-numpad-inline2",
      containerEl: "#numpad-inline-container2",
      toolbar: false,

      on: {
        change(keypad, value) {
          if (value.length > 0) {
            console.log(keypad, value);
            value = value.toString();
            f7.$("#demo-numpad-inline2").value = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
              .currencySymbolNative
              }${value}`;
            self.amountToCashOut = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
              .currencySymbolNative
              }${value}`;
            console.log(keypad);
            console.log(self.amountToCashOut);
            return;
          }
          f7.$("#demo-numpad-inline2").value = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
            .currencySymbolNative
            }${0}`;
          console.log(f7.$("#demo-numpad-inline2").val());

          self.amountToCashOut = `${self.$filtersGetCurrency.getCurrency(self.paymentCurrency)
            .currencySymbolNative
            }${0}`;
          // if (value.length === 4) {
          //   f7.di0alog.alert('Thank you! Your passcode is<br><b>' + value + '</b>', function () {
          //     f7.views.main.f7router.back();
          //   });
          // }
        },
      },
    });
    if (this.isTablet == false) {
      // f7.$("div.page").addClass("no-toolbar");
    }
    if (this.$keycloak.authenticated && this.allrecipients === "") {
      self.$store.dispatch("remit/loadRecipients");
    }
    if (this.$keycloak.authenticated && !this.users) {
      self.$store.dispatch("account/getAllUsers");
    }

    if (f7.$("#autocomplete-dropdown-ajax-typeahead").val() === "") {
      self.$store.dispatch("account/setUser", "");
    }
    this.getUserList();
    if (!self.toastTop) {
      self.toastTop = f7.toast.create({
        text: this.$t("paycash.enterAmount"),
        cssClass: "checkCardToast",
        position: "top",
        closeTimeout: 3000,
      });
    }

    if (this.userAccount?.currencyCode) {
      this.amountToPay = this.$filtersGetCurrency.getCurrency(
        this.userAccount.currencyCode
      )
        ? this.$filtersGetCurrency.getCurrency(this.userAccount.currencyCode)
          .currencySymbolNative
        : this.userAccount.currencyCode + "" + 0;
      this.amountToCashOut = this.$filtersGetCurrency.getCurrency(
        this.userAccount.currencyCode
      )
        ? this.$filtersGetCurrency.getCurrency(this.userAccount.currencyCode)
          .currencySymbolNative
        : this.userAccount.currencyCode + "" + 0;
    }
  },

  methods: {
    goToRequestPayment() {
      this.transactionType = "avo¢ash claim";
      this.clearSearch();
      this.autocompleteDropdownAjaxTypeahead.inputEl.value = "";
      if (this.numpadInlinePay.value > 0) {
        this.amount = this.numpadInlinePay.value;
        f7.popup.open(".selectAvocashUser", true);
      } else {
        this.toastTop.open();
        return;
      }
    },
    goToPay() {
      this.transactionType = "avo¢ash pay";
      this.clearSearch();
      this.autocompleteDropdownAjaxTypeahead.inputEl.value = "";
      if (this.numpadInlinePay.value > 0) {
        this.amount = this.numpadInlinePay.value;
        f7.popup.open(".selectAvocashUser", true);
      } else {
        this.toastTop.open();
        return;
      }
    },
    goToATMOutCash() {
      this.transactionType = "avo¢ash ATM";
      this.clearSearch();
      this.autocompleteDropdownAjaxTypeahead.inputEl.value = "";
      if (this.numpadInlineATM.value > 0) {
        this.amount = this.numpadInlineATM.value;
        f7.popup.open(".selectAvocashUser", true);
      } else {
        this.toastTop.open();
        return;
      }
    },
    goToWithdrawCash() {
      this.transactionType = "avo¢ash out";
      this.clearSearch();
      this.autocompleteDropdownAjaxTypeahead.inputEl.value = "";
      if (this.numpadInlineATM.value > 0) {
        this.amount = this.numpadInlineATM.value;
        f7.popup.open(".selectAvocashUser", true);
      } else {
        this.toastTop.open();
        return;
      }
    },

    showPayTab() {
      this.numpadInlinePay.setValue("");
      this.amountToPay = "";
      this.operationType = "Pay";
      this.title = this.$t("paycash.pay");
      self.$store.state.account.searchedUser = "";
    },
    showATMTab() {
      this.numpadInlineATM.setValue("");
      this.amountToCashOut = "";
      this.$store.state.account.searchedUser = "";
      this.operationType = "ATM";
      this.title = this.$t("paycash.withdraw");
    },
    pickupTransfer() {
      this.title = this.$t("homepage.remitPickup");
    },
    payWithQRCode() {
      if (this.numpadInlinePay.value > 0) {
        this.amount = this.numpadInlinePay.value;
        this.transactionType = "avo¢ash pay";
        f7.popup.open(".issueQRCode", true);
        return;
      }
      f7.toastTop.open();
      return;
    },
    cashOutWithQRCode() {
      if (this.numpadInlineATM.value > 0) {
        this.amount = this.numpadInlineATM.value;
        this.transactionType = "avo¢ash out";
        f7.popup.open(".issueQRCode", true);
        return;
      }
      f7.toastTop.open();
      return;
    },
    resetPaymentDetail() {
      this.amount = "";
      this.$store.dispatch("transaction/resetPaymentDetail");
    },

    updateList() {
      if (this.$keycloak.authenticated && !this.users) {
        this.$store.dispatch("account/getAllUsers");
      }
      f7.$("#userRecipientlist").hide();
    },
    getUserList() {
      const self = this;
      self.autocompleteDropdownAjaxTypeahead = f7.autocomplete.create({
        inputEl: "#autocomplete-dropdown-ajax-typeahead",
        openIn: "dropdown",
        preloader: true, // enable preloader
        preloaderColor: "multi",
        valueProperty: "name",
        textProperty: "name",
        limit: 20, // limit to 20 results
        typeahead: true,
        // dropdownPlaceholderText:
        //   "Search user, type in name, avocash tag '¢kalebe', phone number, username",
        notFoundText: this.$t("common.nothingFound"),
        dropdownContainerEl: "#autocompleteList",
        renderItem(item) {
          if (item.placeholder) {
            return ` <li class=" item-content">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">${item.text}</div>
                          </div>
                        </div>
                    </li>`;
          }
          for (const user of self.users) {
            if (user["name"] === item["value"]) {
              f7.utils.extend(item, user);
              break;
            }
          }
          const initials = item.name ? self.$filtersGetInitial.getInitial(item.name) : "";
          if (item.logo === null) {
            var pict = `<span v-else color="auto" class="badge" slot="media" style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px; background-color:var(--f7-theme-color) ">${initials}</span>`;
          } else {
            var pict = `<img src="data:image/png;base64,${item.logo}" class="userImage" v-if="${item.logo}" width="40"    style="height: 48px; width: 48px; border-radius: 25px" slot="media"/>`;
          }

          return `<div class="list media-list  ">
                          <ul class="padding-left no-margin-left">
                            <li class="no-margin-left no-padding-left">
                            <label class="item-radio item-content  no-margin-left no-padding-left" data-value="${item.value
            }"   @click="selectUser(${item})">
                              <div class="item-media">${pict}</div>
                              <div class="item-inner">
                                   <di v class="item-title">${item.text}</div>
                                  <div class="item-subtitle">${item.username}</div>
                                   <div class="item-footer avocash">${item.tag || " "
            }</div>
                                </div>
                              </label>
                          </li>
                        </ul>
                      </div>`;
        },
        source(query, render) {
          const autocomplete = this;
          const results = [];
          if (query.length === 0) {
            render(results);
            return;
          }
          autocomplete.preloaderShow();
          for (const user of self.users) {
            if (
              user.name.toLowerCase().toString().includes(query.toLowerCase()) ||
                user.phoneNumber.toLowerCase().toString().includes(query.toLowerCase()) ||
                user.email !== null
                ? user.email
                  .substring(0, user.email.toString().indexOf("@"))
                  .toLowerCase()
                  .includes(query.toLowerCase())
                : null ||
                  user.username.toLowerCase().toString().includes(query.toLowerCase()) ||
                  _.findIndex(user.tag.toLowerCase(), query.toLowerCase()) >= 0 ||
                  user.walletId !== null
                  ? user.walletId.toLowerCase().toString().includes(query.toLowerCase())
                  : null
            )
              results.push(user);
          }
          console.log(results);
          // Hide Preoloader
          autocomplete.preloaderHide();
          // Render items by passing array with result items
          render(results);
        },
        on: {
          change(value) {
            self.selectedUser = value[0];
            console.log(self.selectedUser);
            self.$store.dispatch("account/setUser", value[0]);
            f7.$("#userRecipientlist").hide();
            setTimeout(() => {
              self.changeAmountToPay();
            }, 3000);
          },
        },
      });
    },

    selectUser(recipient) {
      this.selectedUser = this.users.find(
        (x) => x.userId === recipient.userRecipientId && x.accountType === "Primary"
      );
      this.autocompleteDropdownAjaxTypeahead.inputEl.value = this.selectedUser.name;
      if (this.autocompleteDropdownAjaxTypeahead.inputEl.value !== null) {
        f7.$("#userRecipientlist").hide();
      }
      setTimeout(() => {
        this.changeAmountToPay();
      }, 3000);
    },
    clearSearch() {
      if (f7.$("#autocomplete-dropdown-ajax-typeahead").val() === "") {
        this.$store.dispatch("account/setUser", "");
      }
      this.selectedUser === "";
      this.$store.state.account.searchedUser = "";
      this.$store.dispatch("account/setUser", "");
      f7.$("#userRecipientlist").show();
      // setTimeout(() => {
      //   this.changeAmountToPay();
      // }, 3000);
    },

    changeAmountToPay() {
      console.log(this.recipientCountry);
      if (
        this.recipientCountry &&
        parseFloat(this.amount) > 0 &&
        (this.transactionType === "avo¢ash pay" || this.transactionType === "avo¢ash out")
      ) {
        this.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              if (
                this.paymentCurrency === this.userAccount.currencyCode &&
                this.paymentCurrency !== null
              ) {
                this.amountToPay = parseFloat(this.amount);

                this.calculatePayment();
              }
            }, 500);
          })
          .catch((err) => { });
      } else {
        return false;
      }
    },
    beforeOut() {
      f7.$(".mainToolbar").css("display", "block");
    },
  },
  computed: {
    ...mapState("auth", ["isDeskTop", "profile", "isTablet"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),

    ...mapState("account", ["userAccount", "searchedUser", "users"]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    ...mapState("location", ["latitude", "longitude", "ipAddress"]),
    ...mapState("remit", ["recipients", "allrecipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
    ...mapState("message", ["messageSocketClient", "msgCltConnected"]),
    userRecipients() {
      return _.chain(this.allrecipients)
        .reject(["userRecipientId", null])
        .orderBy(["name"], ["asc"])
        .value();
    },
    recipientCountry() {
      if (
        (this.transactionType === "avo¢ash pay" ||
          this.transactionType === "avo¢ash out") &&
        (this.searchedUser !== "" || this.selectedUser !== "")
      ) {
        return _.find(this.destinationCountry, [
          "toCountryCode2",
          this.searchedUser.countryCode || this.selectedUser.countryCode,
        ]);
      }
    },
  },
};
</script>
