<template>
  <f7-link tab-link href="/cart/" transition="f7-fade"
    v-if="this.cart && this.cart.length > 0 && !this.isTablet && this.onboardingFinished" class="toolbarDeskTop">
    <f7-icon ios="f7:cart" aurora="f7:cart" md="material:shopping_cart" color="none">
      <f7-badge bg-color="none" class="text-align-center" :style="{
        ' font-size': '50%',
        'margin-left': this.totalItemCount > 9 ? '-23px' : '-20px',
        top: '-5px',
        'font-weight': bold,
      }" text-color="red">{{ this.totalItemCount }}</f7-badge>
    </f7-icon>
  </f7-link>
  <f7-link v-if="!this.isTablet && this.onboardingFinished" @click="scanQRCode()" icon-aurora="f7:qrcode_viewfinder"
    text="" icon-ios="f7:qrcode_vie
    wfinder" icon-md="material:qr_code_scanner">
  </f7-link>
  <f7-link v-if="this.$keycloak.authenticated || this.authenticated" raised @click="openLoginPanel()">
    <img :src="`data:image/png;base64,${this.userAccount.avatar}`"
      v-if="this.userAccount && this.userAccount.avatar !== null" :style="{
        height: theme.md ? '32px' : '28px',
        width: theme.md ? '32px' : '28px',
        'min-width': theme.md ? '32px' : '28px',
        'background-size': 'cover',
        display: 'block',
        'border-radius': '50%',
      }" alt="usera_vatar" />
    <f7-badge :color="this.onboardingFinished ? 'primary' : 'white'" v-else-if="this.profile" class="elevation-1"
      slot="media" :style="{
        height: theme.md ? '32px' : '28px',
        width: theme.md ? '32px' : '28px',
        'min-width': theme.md ? '32px' : '28px',
        'border-radius': '50%',
      }">{{ $filtersGetInitial.getInitial(profile.name) }}</f7-badge>
  </f7-link>

  <f7-link v-if="!(this.$keycloak.authenticated || this.authenticated) && !this.isTablet" icon-ios="f7:square_arrow_right"
    icon-md="material:login" :color="this.onboardingFinished ? 'red' : 'white'" class="Login_or_Register" link="#"
    @click="openLogingPrompt()"></f7-link>

  <f7-sheet class="Login_or_Register_Sheet" style="height: auto; --f7-sheet-bg-color: #fff" swipe-to-close backdrop top>
    <f7-page-content>
      <f7-block>
        <f7-list dividers-ios strong-ios inset>
          <f7-list-item :title="$t('common.login')" link="#" sheet-close @click="loginFn()" text-color="red"
            class="margin-bottom" style="font-weight: bold"><template #media>
              <f7-icon ios="f7:square_arrow_right" aurora="f7:square_arrow_right" md="material:login"></f7-icon>
            </template>
          </f7-list-item>
          <f7-list-item :title="$t('common.register')" link="#" text-color="primary" sheet-close @click="registerFn()"
            class="margin-bottom" style="font-weight: bold"><template #media>
              <f7-icon ios="f7:person_badge_plus_fill" aurora="f7:person_badge_plus_fill" md="material:person_add_alt">
              </f7-icon>
            </template>
          </f7-list-item>
          <f7-list-item :title="$t('common.close')" text-color="gray" link="#" sheet-close><template #media>
              <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
            </template>
          </f7-list-item>
        </f7-list>
      </f7-block>
    </f7-page-content>
  </f7-sheet>

  <panelright></panelright>
</template>
<script>
import { f7, theme } from "framework7-vue";
import { loginFn } from "../js/mixins/loginFn.js";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import panelright from "../pages/panel-right.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [loginFn, scanQRCodeGeneral],
  components: { panelright },

  data() {
    return { theme, f7 };
  },
  mounted() { },

  methods: {
    openLogingPrompt() {
      if (this.theme.md) {
        f7.sheet.open(".Login_or_Register_Sheet");
      } else {
        this.openLoginActions();
      }
    },

    openLoginPanel() {
      f7.panel.toggle(".loginPanel", true);
    },
    openLoginActions() {
      const self = this;

      if (!self.actionsToLogin) {
        self.actionsToLogin = f7.actions.create({
          backdrop: false,
          animate: true,
          closeByOutsideClick: true,
          buttons: [
            [
              {
                text: self.$t("common.login"),
                color: "red",
                bold: true,
                onClick() {
                  self.loginFn();
                },
              },
            ],
            [
              {
                text: self.$t("common.register"),
                color: "auto",
                bold: true,
                onClick() {
                  self.registerFn();
                },
              },
            ],
            [
              {
                text: self.$t("common.close"),
                bold: true,
                color: "red",
              },
            ],
          ],
          targetEl: f7.$(".Login_or_Register"),
        });
      }
      self.actionsToLogin.open();
    },
  },

  computed: {
    ...mapState("account", ["userAccount"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("auth", [
      "profile",
      "isDeskTop",
      "isTablet",
      "authenticated",
      "locale",
      "onboardingFinished",
    ]),
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
  },
};
</script>
