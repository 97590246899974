import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "airtime/info";
import _ from "lodash";
// const airtime = {
export default (app) => {
  const airtimeCountries =
    app.config.globalProperties.$lcStorage.get("7be2b77a-27e6");
  const airtimeCurrencies =
    app.config.globalProperties.$lcStorage.get("4577-8e9b");
  const errorDescriptions =
    app.config.globalProperties.$lcStorage.get("24cf124f8821");
  const airtimeProducts =
    app.config.globalProperties.$lcStorage.get("0953936f");
  const airtimeProductDesc =
    app.config.globalProperties.$lcStorage.get("54d8-47d7");
  const airtimePromotions =
    app.config.globalProperties.$lcStorage.get("b27fc1bd1f36");
  const airtimePromoDesc =
    app.config.globalProperties.$lcStorage.get("f98c5de3");
  const airtimeProviders =
    app.config.globalProperties.$lcStorage.get("09f1-40a3");
  return {
    namespaced: true,
    state: {
      accountLookUp: [],
      airtimeCountries: airtimeCountries ? JSON.parse(airtimeCountries) : [],
      airtimeCurrencies: airtimeCurrencies ? JSON.parse(airtimeCurrencies) : [],
      airtimeProducts: airtimeProducts ? JSON.parse(airtimeProducts) : [],
      airtimeProductDesc: airtimeProductDesc
        ? JSON.parse(airtimeProductDesc)
        : [],
      airtimePromotions: airtimePromotions ? JSON.parse(airtimePromotions) : [],
      airtimePromoDesc: airtimePromoDesc ? JSON.parse(airtimePromoDesc) : [],
      airtimeProviders: airtimeProviders ? JSON.parse(airtimeProviders) : [],
      airtimeProviderStatus: "",
      airtimeRegions: "",
      airtimeBalance: "",
      airtimeEstimatedPrice: "",
      airtimeTransactionList: "",
      errorDescriptions: errorDescriptions ? JSON.parse(errorDescriptions) : [],
      queryError: "",
    },
    getters: {},
    mutations: {
      GET_ACCOUNTLOOKUP(state, accountLookUp) {
        state.accountLookUp = accountLookUp;
      },
      GET_COUNTRIES(state, airtimeCountries) {
        state.airtimeCountries = airtimeCountries;
        app.config.globalProperties.$lcStorage.set(
          "7be2b77a-27e6",
          JSON.stringify(state.airtimeCountries)
        );
      },
      GET_CURRENCIES(state, airtimeCurrencies) {
        state.airtimeCurrencies = airtimeCurrencies;
        app.config.globalProperties.$lcStorage.set(
          "4577-8e9b",
          JSON.stringify(state.airtimeCurrencies)
        );
      },
      GET_PRODUCTS(state, airtimeProducts) {
        state.airtimeProducts = airtimeProducts;
        app.config.globalProperties.$lcStorage.set(
          "0953936f",
          JSON.stringify(state.airtimeProducts)
        );
      },
      GET_PRODUCT_DESC(state, airtimeProductDesc) {
        state.airtimeProductDesc = airtimeProductDesc;
        app.config.globalProperties.$lcStorage.set(
          "54d8-47d7",
          JSON.stringify(state.airtimeProductDesc)
        );
      },
      GET_PROMOTIONS(state, airtimePromotions) {
        state.airtimePromotions = airtimePromotions;
        app.config.globalProperties.$lcStorage.set(
          "b27fc1bd1f36",
          JSON.stringify(state.airtimePromotions)
        );
      },
      GET_PROMO_DESC(state, airtimePromoDesc) {
        state.airtimePromoDesc = airtimePromoDesc;
        app.config.globalProperties.$lcStorage.set(
          "f98c5de3",
          JSON.stringify(state.airtimePromoDesc)
        );
      },
      GET_PROVIDERS(state, airtimeProviders) {
        state.airtimeProviders = airtimeProviders;
        app.config.globalProperties.$lcStorage.set(
          "09f1-40a3",
          JSON.stringify(state.airtimeProviders)
        );
      },
      GET_PROVIDER_STATUS(state, airtimeProviderStatus) {
        state.airtimeProviderStatus = airtimeProviderStatus;
      },
      GET_REGIONS(state, airtimeRegions) {
        state.airtimeRegions = airtimeRegions;
      },
      QUERY_ERROR(state, queryError) {
        state.queryError = queryError;
      },
      GET_ERRORS_DESC(state, errorDescriptions) {
        state.errorDescriptions = errorDescriptions;
        app.config.globalProperties.$lcStorage.set(
          "24cf124f8821",
          JSON.stringify(state.errorDescriptions)
        );
      },
      GET_BALANCE(state, airtimeBalance) {
        state.airtimeBalance = airtimeBalance;
      },
      GET_ESTIMATE(state, airtimeEstimatedPrice) {
        state.airtimeEstimatedPrice = airtimeEstimatedPrice;
      },
      GET_AIRTIMETRANSACTIONLIST(state, airtimeTransactionList) {
        state.airtimeTransactionList = airtimeTransactionList;
      },
    },
    actions: {
      getAccountLookUp({ commit, dispatch }, payload) {
        axios({
          url: `${path}/account/lookup`,
          method: "GET",
          params: {
            accountNumber: payload.accountNumber,
          },
        })
          .then((r) => r.data)
          .then((accountLookUp) => {
            commit("GET_ACCOUNTLOOKUP", accountLookUp.Items);
            console.log(accountLookUp.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            commit("GET_ACCOUNTLOOKUP", "");
            console.log(err);
          });
      },
      getCountries({ commit }, payload) {
        axios({
          url: `${path}/countries`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((airtimeCountries) => {
            commit("GET_COUNTRIES", airtimeCountries.Items);
            console.log(airtimeCountries.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getCurrencies({ commit }) {
        axios({
          url: `${path}/currencies`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((airtimeCurrencies) => {
            commit("GET_CURRENCIES", airtimeCurrencies.Items);
            console.log(airtimeCurrencies.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getProducts({ commit, dispatch }, payload) {
        console.log("get Products payload", payload);
        axios({
          url: `${path}/products`,
          method: "GET",
          params: payload,
        })
          .then((r) => r.data)
          .then((airtimeProducts) => {
            commit("GET_PRODUCTS", airtimeProducts.Items);
            console.log(airtimeProducts.Items);
            const skuCodesList = [];
            airtimeProducts.Items.forEach((item) => {
              skuCodesList.push(item.SkuCode);
            });
            console.log("this.skuCodesList", skuCodesList);
            dispatch("getProductDescriptions", skuCodesList);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getProductDescriptions({ commit, rootState }, payload) {
        axios({
          url: `${path}/product/descriptions`,
          method: "GET",
          params: {
            skuCodes: payload.toString(),
            languageCodes:
              rootState.auth.locale === ""
                ? navigator.language.split("-")[0]
                : rootState.auth.locale,
          },
        })
          .then((r) => r.data)
          .then((airtimeProductDesc) => {
            commit("GET_PRODUCT_DESC", airtimeProductDesc.Items);
            console.log(airtimeProductDesc.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getPromotions({ commit, dispatch }, payload) {
        console.log("get Promos payload", payload);
        axios({
          url: `${path}/promotions`,
          method: "GET",
          params: payload,
        })
          .then((r) => r.data)
          .then((airtimePromotions) => {
            commit("GET_PROMOTIONS", airtimePromotions.Items);
            console.log(airtimePromotions.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },

      getPromotionDescriptions({ commit }, payload) {
        axios({
          url: `${path}/promotions/descriptions`,
          method: "GET",
          params: {
            languageCodes: payload.languageCodes,
          },
        })
          .then((r) => r.data)
          .then((airtimePromoDesc) => {
            commit("GET_PROMO_DESC", airtimePromoDesc.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },

      getProviders({ commit, dispatch }, payload) {
        console.log("provider payload ", payload);
        axios({
          url: `${path}/providers`,
          method: "GET",
          params: payload,
        })
          .then((r) => r.data)
          .then((airtimeProviders) => {
            commit("GET_PROVIDERS", airtimeProviders.Items);
            console.log("airtimeProviders.Items ", airtimeProviders.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getProviderStatus({ commit }, payload) {
        axios({
          url: `${path}/providerStatus`,
          method: "GET",
          params: { providerCodes: payload },
        })
          .then((r) => r.data)
          .then((airtimeProviderStatus) => {
            commit("GET_PROVIDER_STATUS", airtimeProviderStatus.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getRegions({ commit }, payload) {
        axios({
          url: `${path}/regions`,
          method: "GET",
          params: { countryIsos: payload },
        })
          .then((r) => r.data)
          .then((airtimeRegions) => {
            commit("GET_REGIONS", airtimeRegions.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getErrorCodeDescriptions({ commit }, payload) {
        axios({
          url: `${path}/errors`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((errorDescriptions) => {
            commit("GET_ERRORS_DESC", errorDescriptions.Items);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getBalance({ commit }) {
        axios({
          url: `${path}/balance`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((airtimeBalance) => {
            commit("GET_BALANCE", airtimeBalance);
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },
      getEstimate({ commit }, payload) {
        console.log("gest estimate payload", payload);
        axios({
          url: `${path}/estimate`,
          method: "POST",
          data: payload,
        })
          .then((r) => r.data)
          .then((airtimeEstimatedPrice) => {
            commit("GET_ESTIMATE", airtimeEstimatedPrice.Items);
            console.log(
              "gest estimate padatayload",
              airtimeEstimatedPrice.Items
            );
          })
          .catch((err) => {
            commit("QUERY_ERROR", err);
            console.log(err);
          });
      },

      getAirTimeTransactions({ commit }) {
        if (app.config.globalProperties.$keycloak.token) {
          axios({
            url: `${path}/transactions`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((airtimeTransactionList) => {
              commit("GET_AIRTIMETRANSACTIONLIST", airtimeTransactionList);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
    },
  };
};
// export default airtime;
