<template>
  <f7-page class="bankAccount_page">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title>{{ $t("bankAccount.mybankaccounts") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios strong-ios>
          <f7-list-group media-list v-if="this.userAccount.userBankAccounts &&
            (this.userAccount.userBankAccounts.STANDARD.length > 0 ||
              this.userAccount.userBankAccounts.BUSINESS.length > 0)
            ">
            <f7-block-title style="white-space: normal; word-wrap: break-word">{{
              $t("bankAccount.mybankaccounts")
            }}</f7-block-title>

            <!-- <f7-list-item v-if="this.isEditable">
                <span>{{ getAccountStatus(accountStatus) }}</span>
                <f7-toggle
                  v-model:checked="accountIsActive"
                  color="primary"
                  @toggle:change="setStatus()"
                ></f7-toggle>
              </f7-list-item> -->
            <!-- @accordion:open="openItem(account)"  -->
            <span v-for="(accountsList, account_type, index) in this.userAccount
              .userBankAccounts" :key="index">
              <f7-list-item group-title :title="getAccountType(account_type)" v-if="accountsList.length > 0" />
              <f7-list-item link="#" v-for="(account, index) in accountsList" @click="openItem(account)" :key="index"
                :title="account.tag" :subtitle="$t('bankAccount.number') + ': ' + account.number" :text="account.bic !== null ? $t('bankAccount.bic') + ': ' + account.bic : ''
                  " :after="getAccountStatus(account.status)" :checkbox="account.main" :checked="account.main"
                :class="account.main ? 'border-color-primary highlighted_horiz' : ''" :id="`account${account.id}`">
              </f7-list-item>
            </span>

            <f7-list-item link="#" ripple color="red" class="text-color-red" @click="addNewBankAccount()">{{
              $t("bankAccount.addbankaccounts") }}</f7-list-item>
          </f7-list-group>

          <f7-list-group v-if="this.isEditable == true || this.addNew == true">
            <f7-block-title style="white-space: normal; word-wrap: break-word">{{
              $t("bankAccount.addbankaccounts")
            }}</f7-block-title>
            <f7-list-item :title="$t('common.selectAccount')" smart-select :smart-select-params="{
              openIn: this.isTablet ? 'popover' : 'sheet',
              closeOnSelect: true,
            }">
              <select name="account_id" v-model="account_id">
                <option v-if="this.userAccount.standard !== null" :value="this.userAccount.standard.id" :selected="true">
                  {{ getAccountType(userAccount.standard.type) }}
                </option>
                <option v-if="this.userAccount.business !== null" :value="this.userAccount.business.id">
                  {{ getAccountType(userAccount.business.type) }}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input type="text" :readonly="this.isEditable" placeholder="FI1370001540000072"
              :label="$t('bankAccount.number')" floating-label required validate v-model:value="this.number"
              :error-message="$t('bankAccount.acctNumberRequired')" :clear-button="!this.isEditable">
            </f7-list-input>
            <f7-list-input type="text" :readonly="this.isEditable" placeholder="PSPBFIHH" :label="$t('bankAccount.bic')"
              floating-label v-model:value="this.bic" :clear-button="!this.isEditable"></f7-list-input>
            <f7-list-input type="text" floating-label :label="$t('common.tag')" v-model:value="this.tag" clear-button>
            </f7-list-input>
            <f7-list-input type="text" :readonly="this.isEditable" :label="$t('bankAccount.holder_name')"
              v-model:value="this.holder_name" :clear-button="!this.isEditable"></f7-list-input>

            <myDocs :type="this.type" v-if="!this.isEditable" />
            <f7-button v-if="!this.isEditable" raised fill large ripple color="red" class="margin buttons"
              @click="addBankAccount()">
              {{ $t("bankAccount.savebankaccounts") }}</f7-button>
            <f7-button v-else raised fill large ripple color="red" class="margin buttons" @click="editBankAccount()">{{
              $t("bankAccount.savebankaccounts") }}</f7-button>
            <!-- </span> -->
          </f7-list-group>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7List,
  f7ListGroup,
  f7BlockTitle,
  f7Accordion,
  f7BlockHeader,
  f7ListItem,
  f7ListInput,
  f7Button,
  theme,
  f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import attachDocs from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import Navbarright from "../components/navbarright.vue";
import { account } from "../js/mixins/account.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [account],
  components: {
    f7List,
    f7ListGroup,
    f7BlockTitle,
    f7Accordion,
    f7BlockHeader,
    f7ListItem,
    f7ListInput,
    f7Button,

    navbarright: Navbarright,
    attachDocs,
    myDocs,
  },
  data() {
    return {
      theme,
      f7,
      number: "",
      bic: "",
      holder_name: "",
      account_id: "",
      tag: "",
      type: "bankAccount",
      forms: "",
      isEditable: false,
      addNew: false,
      selectedAccount: "",
      accountStatus: "",
      accountIsActive: false,
    };
  },
  mounted() {
    if (this.profile) {
      this.holder_name = this.profile.name;
    }

    if (this.userAccount === null && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.userAccount && this.userAccount.standard !== null) {
      this.account_id = this.userAccount.standard.id;
      this.$store.dispatch("apimoney/getAllBankAccounts", this.account_id);
    }
  },

  methods: {},
  computed: {
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "standard",
      "business",
      "accountList",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "documentsUrl", "document"]),
    ...mapState("account", ["userAccount"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
</script>
