<template>
  <f7-page @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove">
    <f7-navbar no-shadow v-if="topuptransaction.transactionType === 'avo¢ash top-up'">
      <f7-nav-title>{{
        $t("sendcash.topupTo", {
          myrecipient: topuptransaction.mainRecipient.name,
        })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios v-if="topuptransaction.transactionType === 'avo¢ash top-up'" media-list form
          class="margin-vertical-half" style="padding-bottom: 80px">
          <f7-list-group>
            <f7-list-item :title="$t('sendcash.transferCodeAndPin')" divider></f7-list-item>
            <li class="item-content" v-if="topuptransaction !== null">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferCode") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferPin") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ topuptransaction.transferCode }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ topuptransaction.transferPIN }}
                  </div>
                </div>
              </div>
            </li>
            <f7-button raised v-if="topuptransaction.isValidated === false" fill ripple class="margin buttons" color="red"
              strong @click="validateDebitTransaction(topuptransaction)">{{ $t("common.validateTransfer") }}</f7-button>
            <f7-list-item :title="$t('sendcash.recipientDetail')" divider></f7-list-item>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.recipientName") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.recipientContact") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ topuptransaction.mainRecipient.name }}
                    <div class="item-footer">
                      {{ topuptransaction.mainRecipient.countryName }}
                    </div>
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    +{{ topuptransaction.mainRecipient.phoneCode
                    }}{{ topuptransaction.mainRecipient.phone }}
                    <div class="item-footer">
                      {{ topuptransaction.mainRecipient.email }}
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.destinationCountry") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.pickupMode") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ topuptransaction.toCountryName }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ topuptransaction.transactionType }}
                  </div>
                </div>
              </div>
            </li>
            <f7-list-item :title="$t('sendcash.transferDetail')" divider></f7-list-item>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.youSend") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.recipientGets") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      topuptransaction.grossTransferAmount,
                      null,
                      topuptransaction.fromCurrencyCode
                    )
                      "></span>
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      topuptransaction.receivedAmount,
                      null,
                      topuptransaction.toCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.fee") }}</div>
                  <div class="item-title">
                    <span v-html="$filtersToCurrency.toCurrency(
                      topuptransaction.transferFeeAmount,
                      null,
                      topuptransaction.fromCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.xrate") }}</div>
                  <div class="item-title float-left">
                    1{{ topuptransaction.fromCurrencyCode }}=<span v-html="$filtersToCurrency.toCurrency(
                      topuptransaction.netExchangeRate,
                      null,
                      topuptransaction.toCurrencyCode
                    )
                      "></span><i>{{ topuptransaction.toCurrencyName }}</i>
                  </div>
                </div>
              </div>
            </li>
            <f7-block v-if="topuptransaction.qrCode !== null">
              <img :src="`data:image/png;base64,${topuptransaction.qrCode}`" style="display: block; margin: auto"
                alt="transfer QR code" />
              <f7-link @click="saveFile(fileToSave)" icon-aurora="f7:square_arrow_down" icon-ios="f7:square_arrow_down"
                icon-md="material:save"></f7-link>
            </f7-block>
          </f7-list-group>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
    <!--f7-button href="/transfer/validate" v-if="transferDetail.pickupMethod==='top-up'" raised big fill color="red">{{ $t('transfervalidated.topupNow') }}</f7-button>
        <f7-button href="/transfer/validate" v-else raised big fill color="red">{{ $t('transfervalidated.transferNow') }}</f7-button-->
    <f7-navbar no-shadow v-if="pickuptransaction.transactionType === 'avo¢ash pick-up' && pickuptransaction">
      <f7-nav-title v-if="pickuptransaction.transactionType">{{
        $t("sendcash.transferTo", {
          myrecipient: pickuptransaction.mainRecipient.name,
        })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios v-if="pickuptransaction.transactionType === 'avo¢ash pick-up'" media-list form
          style="padding-bottom: 80px" class="margin-vertical-half">
          <f7-list-group>
            <f7-list-item :title="$t('sendcash.transferCodeAndPin')" divider></f7-list-item>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferCode") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferPin") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ pickuptransaction.transferCode }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ pickuptransaction.transferPIN }}
                  </div>
                </div>
              </div>
            </li>
            <f7-list-item :title="$t('sendcash.recipientDetail')" divider></f7-list-item>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.recipientName") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.recipientContact") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ pickuptransaction.mainRecipient.name }}
                    <div class="item-footer">
                      {{ pickuptransaction.mainRecipient.cityName }} in
                      {{ pickuptransaction.mainRecipient.countryName }}
                    </div>
                  </div>
                  <div class="item-title">
                    +{{ pickuptransaction.mainRecipient.phoneCode
                    }}{{ pickuptransaction.mainRecipient.phone }}
                    <div class="item-footer">
                      {{ pickuptransaction.mainRecipient.email }}
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.destinationCountry") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.pickupMode") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ pickuptransaction.toCountryName }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ pickuptransaction.transactionType }}
                  </div>
                </div>
              </div>
            </li>
            <f7-list-item :title="$t('sendcash.transferDetail')" divider></f7-list-item>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.youSend") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.recipientGets") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      pickuptransaction.grossTransferAmount,
                      null,
                      pickuptransaction.fromCurrencyCode
                    )
                      "></span>
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      pickuptransaction.receivedAmount,
                      null,
                      pickuptransaction.toCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.fee") }}</div>
                  <div class="item-title">
                    <span v-html="$filtersToCurrency.toCurrency(
                      pickuptransaction.transferFeeAmount,
                      null,
                      pickuptransaction.fromCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.xrate") }}</div>
                  <div class="item-title float-left">
                    1{{ pickuptransaction.fromCurrencyCode }}=<span v-html="$filtersToCurrency.toCurrency(
                      pickuptransaction.netExchangeRate,
                      null,
                      pickuptransaction.toCurrencyCode
                    )
                      "></span><i>{{ pickuptransaction.toCurrencyName }}</i>
                  </div>
                </div>
              </div>
            </li>
            <f7-block v-if="pickuptransaction.qrCode !== null">
              <img :src="`data:image/png;base64,${pickuptransaction.qrCode}`" style="display: block; margin: auto"
                alt="transfer QR code" />
              <f7-link @click="saveFile(fileToSave)" icon-aurora="f7:square_arrow_down" icon-ios="f7:square_arrow_down"
                icon-md="material:save"></f7-link>
            </f7-block>
          </f7-list-group>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>

    <!-- <f7-toolbar sendcash.willReceivess=" fab-morph-target" v-if="isDeskTop ==false">
      <f7-link
        href="/home/"
        :text="$t('transfervalidated.home')"
        id="home"
        icon-ios="f7:house_fill"
        icon-aurora="f7:house_fill"
        icon-md="material:home"
      >      <f7-view url="/" links-view=".tab-active"></f7-view>
</f7-link>
      <f7-link href="/dashboard/" class="avocash-icon">
        <span
          class="icon"
          style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)"
        >
          <img src="/static/icon-new.ico" width="85%" height="85%" class="elevation-6"
        /></span>
        <span class="tabbar-label avocash">{{ $t('transfervalidated.avoash') }}</span></f7-link
      >
      <f7-link
        href="/topup/my-account"
        id="topupmyaccount"
        :text="$t('transfervalidated.topMeUp')"
        icon-aurora="f7:arrow_up_round_fill"
        icon-ios="f7:arrow_up_round_fill"
        icon-md="material:arrow_upward"
      ></f7-link>
    </f7-toolbar>


    <f7-fab position="right-bottom" slot="fixed" morph-to=".toolbar.fab-morph-target">
      <f7-icon ios="f7:arrowshape_turn_up_left" aurora="f7:arrowshape_turn_up_left" md="material:reply_all"></f7-icon>
    </f7-fab> -->

    <span :class="'msg' + transaction.id" style="display: none" v-if="transaction">{{ transaction.description }}
      <br />
      {{ $t("recipienttransactiondetail.transactionDate")
      }}{{ $filtersToDate.toDateTime(transaction.transactionDate) }} <br />{{
  $t("recipienttransactiondetail.topupAmount")
}}
      <b>
        <span v-html="$filtersToCurrency.toCurrency(
              transaction.receivedAmount,
              null,
              transaction.toCurrencyCode
            )
            "></span>
      </b>
      <br />{{ $t("sendcash.recipientName") }}
      {{ transaction.mainRecipient.name }}
      <br />{{ $t("common.transferCode") }} <b>{{ transaction.transferCode }}</b></span>
    <span :class="'subject' + transaction.id" style="display: none" v-if="transaction">
      {{ transaction.transactionType }} <br />
      {{ $t("common.amount") }}
      <b>
        <span v-html="$filtersToCurrency.toCurrency(
          transaction.receivedAmount,
          null,
          transaction.toCurrencyCode
        )
          "></span>
      </b>
      {{ $t("sendcash.status") }}
      {{ transaction.status }}</span>

    <f7-popup class="transferSuccess" swipe-to-close :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }">
      <f7-navbar title="" no-shadow>
        <f7-nav-right>
          <f7-link popup-close icon-f7="xmark_circle"></f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-page-content class="text-align-center">
        <f7-icon ios="f7:checkmark_circle" aurora="f7:checkmark_circle" md="material:check_circle_outline" size="300px"
          color="green" class="text-align-center"></f7-icon>
        <!-- <f7-card> -->
        <div v-if="pickuptransaction && pickuptransaction.transactionType === 'avo¢ash pick-up'
          ">
          <h1 color="green" class="text-align-center">
            {{ $t("sendcash.transferSuccessful") }}
          </h1>
          <br />
          <h4 text-color="green" class="text-align-center margin-horizontal">
            {{ $t("sendcash.youSend") }}
            <span v-html="$filtersToCurrency.toCurrency(
              pickuptransaction.grossTransferAmount,
              null,
              pickuptransaction.fromCurrencyCode
            )
              "></span><br />{{
    $t("sendcash.recipient", {
      myrecipient: pickuptransaction.mainRecipient.name,
    })
  }}<br />{{ $t("sendcash.willReceive") }}
            <span v-html="$filtersToCurrency.toCurrency(
              pickuptransaction.receivedAmount,
              null,
              pickuptransaction.toCurrencyCode
            )
              "></span>
          </h4>
        </div>
        <div v-if="topuptransaction && topupsendcash.willReceive == 'avo¢ash top-up'">
          <h1 color="green" class="text-align-center">
            {{ $t("sendcash.transferSuccessful") }}
          </h1>
          <br />
          <h4 text-color="green" class="text-align-center margin-horizontal">
            {{ $t("sendcash.youSend") }}
            <span v-html="$filtersToCurrency.toCurrency(
              topuptransaction.grossTransferAmount,
              null,
              topuptransaction.fromCurrencyCode
            )
              "></span><br />{{
    $t("sendcash.recipient", {
      myrecipient: topuptransaction.mainRecipient.name,
    })
  }}<br />{{ $t("sendcash.willReceive") }}
            <span v-html="$filtersToCurrency.toCurrency(
              topuptransaction.receivedAmount,
              null,
              topuptransaction.toCurrencyCode
            )
              "></span>
          </h4>
        </div>

        <!-- </f7-card> -->
      </f7-page-content>
    </f7-popup>
    <template #fixed>
      <f7-fab position="left-bottom">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
        <f7-icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></f7-icon>
        <f7-fab-buttons position="top">
          <f7-fab-button :label="$t('app.home')" @click="selectHomeView()">
            <f7-icon ios="f7:house_fill" aurora="f7:house_fill" md="material:home"></f7-icon>
          </f7-fab-button>
          <f7-fab-button :label="$t('app.dashboard')" @click="selectDashboardView()">
            <span class="icon" color="white" style="
                height: var(--f7-tabbar-icon-size);
                width: var(--f7-tabbar-icon-size);
              ">
              <img src="/icon-new.ico" width="24" height="24" class="elevation-6" alt="avocash icon" /></span>
          </f7-fab-button>

          <f7-fab-button :label="this.recipient.name" @click="toUrlDetail(recipient)">
            <f7-icon ios="f7:person" aurora="f7:person" md="material:person"></f7-icon>
            <!-- <span>
            <f7-badge
              slot="media"
              color="red"
              style="height: 32px; width: 32px; font-size: 16px"
              :text="
                (recipient.name.split(' ')[0] || ' ').charAt(0) +
                '' +
                (recipient.name.split(' ')[1] || ' ').charAt(0)
              "
            ></f7-badge
          ></span> -->
          </f7-fab-button>
        </f7-fab-buttons>
      </f7-fab>

      <f7-fab position="right-bottom" color="auto" class="shareButton" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.avocash-icon .md {
  font-size: 14px;
}

.ios .avocash-icon {
  font-size: 12px;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Toggle,
  f7ListInput,
  f7Button,
  f7Block,
  f7Link,
  f7Popup,
  f7PageContent,
  f7Icon,
  f7Fab,
  f7FabButtons,
  f7FabButton,
  theme,
  f7,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import { transferAgain } from "../js/mixins/transaction.js";
import { selectedRecipient, recipient } from "../js/mixins/recipient.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7Toggle,
    f7ListInput,
    f7Button,
    f7Block,
    f7Link,
    f7Popup,
    f7PageContent,
    f7Icon,
    f7Fab,
    f7FabButtons,
    f7FabButton,
  },
  mixins: [selectedRecipient, recipient, transferAgain, socialShare],
  data() {
    return {
      theme,
      f7,
      pickupcity: "",
      pickupMethod: "",
      totransferid: "",
      tocurrencycode: "",
      query: "",
      grossXrate: "",
      recipientId: "",
      transferPIN: "",
      transacttion: "",
      fileToSave: [],
    };
  },
  mounted() {
    const self = this;

    this.recipientId =
      this.pickuptransaction.recipientId ||
      this.topuptransaction.recipientId ||
      this.extraTransferDetail.recipientId;
    this.share(this.pickuptransaction || this.topuptransaction);
    // f7.$(".mainToolbar").css("display", "block");
    this.transacttion = this.pickuptransaction || this.topuptransaction;
  },

  methods: {
    selectDashboardView() {
      const self = this;

      f7.views.main.router.navigate("/home/dashboard/");
      f7.tab.show("#view-dashboard");
    },
    selectHomeView() {
      const self = this;

      f7.view.get(".view-main").router.navigate("/");
      f7.tab.show("#view-home");
    },
    async share(myTransaction) {
      const self = this;

      const files = [];
      const base64Files = [];
      if (myTransaction?.qrCode && myTransaction.qrCode !== null) {
        const blob = await (
          await fetch(`data:image/png;base64,${myTransaction.qrCode}`)
        ).blob();
        const termination = blob.type.split("/")[1];
        const file = new File([blob], `${myTransaction.date}.${termination}`, {
          type: blob.type,
        });
        this.fileToSave.push(file);
        files.push(file);
        base64Files.push(`data:image/png;base64,${myTransaction.qrCode}`);
      }

      this.sharedContent = {
        message: f7.$(`.msg${myTransaction.id}`).text(),
        subject: f7.$(`.subject${myTransaction.id}`).text(),
        files,
        base64Files,
        // url:
        //   "https://www.avocash.com/#/recipient/transaction/detail/" +
        //   myTransaction.id,
        link: `${this.f7router.currentRoute.path}`,
        short: false,
        linkBase: "group",
        imgUrl: "https://avocash.com/34x34.png",
      };

      self.$store.dispatch("auth/createDynamicLink", this.sharedContent);
      console.log(this.sharedContent);
    },
    onPageInit() {
      const self = this;

      // f7.popup.open(".transferSuccess");
      // setTimeout(() => {
      //   f7.popup.close(".transferSuccess");
      //   // this.f7router.navigate("/dashboard/", {
      //   //   reloadCurrent: true,
      //   //   ignoreCache: true
      //   // });
      // }, 10000);
    },
    // async validateTopUp(topuptransaction) {
    //   const self = this;
    //
    //
    //   await f7.preloader.show("multi");
    //   if (topuptransaction !== null) {
    //     console.log(topuptransaction);
    //     await self.$store.dispatch("remit/validateTopUp", {
    //       transferCode: topuptransaction.transferCode,
    //       transferPIN: topuptransaction.transferPIN,
    //     });
    //   }
    //   await f7.preloader.hide();
    //   await this.validatedDialog();
    //   await this.f7router.refreshPage();
    // },

    // validatedDialog() {
    //   const self = this;
    //
    //   if (!self.toastIcon) {
    //     self.toastIcon = f7.toast.create({
    //       icon: theme.ios
    //         ? '<i class="f7-  text-color-green border-color-gray color-green size-100">check</i>'
    //         : '<i class="material-  text-color-green border-color-gray color-green size-100">check</i>',
    //       text: "Transaction validated!",
    //       cssClass: "toast_validate",
    //       position: "center",
    //       closeTimeout: 1500,
    //     });
    //   }
    //   self.toastIcon.open();
    // },
    onPageBeforeRemove() {
      const self = this;

      f7.$(".mainToolbar").css("display", "block");
      if (self.popup) self.popup.destroy();
    },
  },

  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userrecipientList",
      "recipient",
      "topup",
      "pickup",
      "topuptransaction",
      "pickuptransaction",
    ]),

    ...mapState("account", ["userDetail", "userAccount"]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),

    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "extraTransferDetail",
      "primaryTransactionList",
      "primaryTransactionList",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "extraTransferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
    ]),

    ...mapGetters("transaction", ["grossexchangerate2"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
</script>
