<template>
  <f7-popover class="groupDetailActionsPopover" :backdrop="false">
    <!-- v-model:opened="isOpenedPopover" -->
    <!-- v-if="this.selectedGp" -->
    <f7-list dividers-ios strong-ios media-list no-chevron>
      <f7-list-group v-if="this.selectedGp.groupType === 'PERSONAL'">
        <f7-list-item link="#" popover-close text-color="teal" :title="$t('tamtam.markAsUnread')"
          @click="markUnread(selectedGp)"></f7-list-item>
        <f7-list-item link="#" popover-close text-color="red" :title="$t('tamtam.deleteChat')"
          @click="deleteChat(selectedGp)"></f7-list-item>
      </f7-list-group>
      <f7-list-group v-if="this.selectedGp.groupType === 'GROUP'">
        <f7-list-item v-if="selectedGp.groupAdminList.find((x) => x.userId !== profile.sub) !== null" link="#"
          popover-close :title="$t('tamtam.addSubgroup')" @click="addSubgroup(selectedGp)"></f7-list-item>
        <f7-list-item link="#" popover-close text-color="teal" :title="$t('tamtam.markAsUnread')"
          @click="markUnread(selectedGp)"></f7-list-item>
        <f7-list-item link="#" popover-close text-color="orange" :title="$t('tamtam.exitGroup')"
          @click="leaveGroup(selectedGp)"></f7-list-item>
        <f7-list-item v-if="selectedGp.groupAdminList.find((x) => x.userId !== profile.sub) !== null" link="#"
          text-color="red" popover-close :title="$t('tamtam.deleteGroup')"
          @click="deleteGroup(selectedGp)"></f7-list-item>
      </f7-list-group>
      <f7-list-group v-if="this.selectedGp.groupType === 'SUBGROUP'">
        <f7-list-item link="#" popover-close :title="$t('tamtam.markAsUnread')" @click="markUnread(selectedGp)"
          text-color="teal"></f7-list-item>
        <f7-list-item link="#" popover-close :title="$t('tamtam.exitGroup')" text-color="orange"
          @click="leaveGroup(selectedGp)"></f7-list-item>
        <f7-list-item v-if="selectedGp.subGroupAdminList.find((x) => x.userId !== profile.sub) !== null
          " link="#" popover-close text-color="red" :title="$t('tamtam.deleteSubgroup')"
          @click="deleteGroup(selectedGp)"></f7-list-item>
      </f7-list-group>
    </f7-list>
  </f7-popover>
  <!-- v-if="this.selectedGp" -->
  <!-- v-model:opened="isOpenedActions" -->
  <f7-actions class="groupDetailActionsSheet" convert-to-popover close-by-outside-click>
    <f7-actions-group v-if="this.selectedGp.groupType === 'PERSONAL'">
      <f7-actions-button color="teal" @click="markUnread(selectedGp)">{{
        $t("tamtam.markAsUnread")
      }}</f7-actions-button>
      <f7-actions-button color="red" @click="deleteChat(selectedGp)">{{
        $t("tamtam.deleteChat")
      }}</f7-actions-button>
    </f7-actions-group>
    <f7-actions-group v-if="this.selectedGp.groupType === 'GROUP'">
      <f7-actions-button v-if="selectedGp.groupAdminList.find((x) => x.userId !== profile.sub) !== null"
        @click="addSubgroup(selectedGp)">{{ $t("tamtam.addSubgroup") }}</f7-actions-button>
      <f7-actions-button color="teal" @click="markUnread(selectedGp)">{{
        $t("tamtam.markAsUnread")
      }}</f7-actions-button>
      <f7-actions-button color="orange" @click="leaveGroup(selectedGp)">{{
        $t("tamtam.exitGroup")
      }}</f7-actions-button>
      <f7-actions-button color="red" v-if="selectedGp.groupAdminList.find((x) => x.userId !== profile.sub) !== null"
        @click="deleteGroup(selectedGp)">{{ $t("tamtam.deleteGroup") }}</f7-actions-button>
    </f7-actions-group>
    <f7-actions-group v-if="this.selectedGp.groupType === 'SUBGROUP'">
      <f7-actions-button color="teal" @click="markUnread(selectedGp)">{{
        $t("tamtam.markAsUnread")
      }}</f7-actions-button>
      <f7-actions-button color="orange" @click="leaveGroup(selectedGp)">{{
        $t("tamtam.exitGroup")
      }}</f7-actions-button>
      <f7-actions-button color="red" v-if="selectedGp.subGroupAdminList.find((x) => x.userId !== profile.sub) !== null"
        @click="deleteGroup(selectedGp)">{{ $t("tamtam.deleteSubgroup") }}</f7-actions-button>
    </f7-actions-group>
  </f7-actions>
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { messageGroupMixin } from "../../js/mixins/messageMixins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    selectedGp: Object,
  },
  mixins: [messageGroupMixin],

  data() {
    return {
      theme,
      f7,
      // selectedGroup: "",
    };
  },

  computed: {
    ...mapState("auth", ["isDeskTop", "isTablet", "profile"]),
  },
};
</script>
