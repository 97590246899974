<template>
  <f7-page hide-bars-on-scroll no-toolbar @page:beforeremove="onPageBeforeRemove">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-html="this.title"></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <f7-block-title medium style="white-space: normal; word-wrap: break-word">{{
      $t("avocashoutwithaccountnumber.enterCashier")
    }}<span class="avocash">{{
  $t("avocashoutwithaccountnumber.avoash")
}}</span>{{ $t("avocashoutwithaccountnumber.accountAndAmount") }}</f7-block-title>

    <f7-list dividers-ios strong-ios id="payWithAcctNbr" v-if="this.paymentTransaction === '' ||
      this.paymentTransaction.isValidated === true
      ">
      <f7-list-group>
        <h5 class="margin-bottom-half margin-left" v-if="this.userByAccountNumber">
          {{ $t("avocashoutwithaccountnumber.cashier")
          }}<b>{{ this.userByAccountNumber.firstName }}
            {{ this.userByAccountNumber.lastName }}</b>
        </h5>
        <!-- <h5 v-else class="margin-bottom-half margin-left">{{ $t('avocashoutwithaccountnumber.enterCashierAccount') }}</h5> -->

        <f7-list-input :label="$t('avocashoutwithaccountnumber.cashierAccount')" floating-label clear-button type="number"
          required validate error-message="Please enter cashier account number!" class="inputNumber"
          :value="userAccountNumber" @input="userAccountNumber = $event.target.value" name="userAccountNumber"
          @blur="getUserByAccountNber()"></f7-list-input>
        <f7-block v-if="this.userAccountNumber && (this.toastText || this.getusererror)" class="no-margin">
          <p style="font-size: 10px; font-style: italic; color: red" v-html="this.getusererror || this.toastText"></p>
        </f7-block>

        <f7-row no-gap>
          <f7-col width="25">
            <li class="item-content item-input no-padding-right">
              <div class="item-inner no-padding-right">
                <div class="item-title item-label">
                  {{ $t("avocashoutwithaccountnumber.currency") }}
                </div>
                <div class="item-input-wrap input-dropdown-wrap">
                  <select v-model="paymentCurrency" @change="changePaymentCurrency()" :disabled="!this.recipientCountry">
                    <option v-if="this.profile.sub != null" :value="this.primaryAccount.currencyCode ||
                      this.businesAccount.currencyCode
                      " selected>
                      {{
                        this.primaryAccount.currencyCode ||
                        this.businesAccount.currencyCode
                      }}
                    </option>
                    <option v-if="this.userByAccountNumber !== ''" :value="this.userByAccountNumber.primaryAccount.currencyCode ||
                      thi.userByAccountNumber.businessAccount.currencyCode
                      ">
                      {{
                        this.userByAccountNumber.primaryAccount.currencyCode ||
                        thi.userByAccountNumber.businessAccount.currencyCode
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </li>
          </f7-col>
          <f7-col width="75">
            <li class="item-content item-input padding-left-half">
              <div class="item-inner padding-left-half">
                <div class="item-title item-floating-label">
                  {{ $t("avocashoutwithaccountnumber.amountToCashOut") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" name="amountToPay" class="inputNumber" min="0" required validate
                    data-error-message="Please enter amount to cash out!" :disabled="!this.recipientCountry"
                    id="amountToPay" :value="amountToPay" @input="amountToPay = $event.target.value"
                    @change="changeAmountToPay()" @keyup="changeAmountToPay()" @blur="changeAmountToPay()"
                    @keyup.enter="cashOutWithAccountNumber()" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
          </f7-col>
        </f7-row>

        <f7-block style="font-size: 14px" class="no-margin"
          v-if="this.amountToPay != '' && this.userByAccountNumber !== null">
          <p v-if="this.paymentDetail">
            {{ $t("avocashoutwithaccountnumber.iCashout")
            }}<b>{{
  $filtersToCurrency.toCurrency(
    this.paymentDetail.receivedAmount,
    this.paymentDetail.toCurrencySymbol,
    this.paymentDetail.toCurrencyCode
  )
}}</b>
          </p>
          <p v-if="this.paymentReverseDetail">
            {{
              $t("avocashoutwithaccountnumber.equivalentIn", {
                "1": this.paymentReverseDetail.fromCurrencyCode,
              })
            }}<b>{{
  $filtersToCurrency.toCurrency(
    this.paymentReverseDetail.grossTransferAmount,
    this.paymentReverseDetail.fromCurrencySymbol,
    this.paymentReverseDetail.fromCurrencyCode
  )
}}</b>
          </p>
        </f7-block>

        <f7-block class="no-margin" v-if="this.noAccountError !== null">
          <p style="font-size: 10px; font-style: italic; color: red">
            {{ this.noAccountError }}
          </p>
        </f7-block>
        <f7-list-input :label="$t('avocashoutwithaccountnumber.paymentDescription')" floating-label type="textarea"
          resizable name="description" :placeholder="$t('avocashoutwithaccountnumber.paymentDescription')"
          :value="description" @input="description = $event.target.value"></f7-list-input>
      </f7-list-group>
    </f7-list>
    <span v-if="this.paymentTransaction === '' ||
      this.paymentTransaction.isValidated === true
      ">
      <f7-button raised fill large color="red" class="margin margin-vertical" @click="cashOutWithAccountNumber()">{{
        $t("avocashoutwithaccountnumber.cashOut") }}</f7-button>
      <f7-button v-if="this.amountToPay != '' && this.userByAccountNumber !== null" class="margin" ripple outline raised
        color="red" @click="cancelCashOut()">{{ $t("avocashoutwithaccountnumber.cancelCashOut") }}</f7-button>
    </span>
    <f7-list dividers-ios strong-ios v-if="this.paymentTransaction.isValidated === false" media-list form
      class="margin-vertical-half">
      <f7-list-group>
        <f7-block-title color="green" class="text-align-center">{{
          $t("avocashoutwithaccountnumber.transferTo", {
            "1": this.paymentTransaction.payeeName,
          })
        }}</f7-block-title>
        <li class="item-content">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title" style="color: gray; font-size: 75%">
                {{ $t("avocashoutwithaccountnumber.youTransferred") }}
              </div>
              <div class="item-title" style="color: gray; font-size: 75%" v-if="this.paymentTransaction.fromCurrencyCode !==
                this.paymentTransaction.toCurrencyCode
                ">
                {{ $t("avocashoutwithaccountnumber.youGet") }}
              </div>
            </div>
            <div class="item-title-row">
              <div class="item-title" style="font-weight: bold">
                {{
                  $filtersToCurrency.toCurrency(
                    this.paymentTransaction.grossPaymentAmount,
                    this.paymentTransaction.fromCurrencySymbol,
                    this.paymentTransaction.fromCurrencyCode
                  )
                }}
              </div>
              <div class="item-title" style="font-weight: bold" v-if="this.paymentTransaction.fromCurrencyCode !==
                  this.paymentTransaction.toCurrencyCode
                  ">
                {{
                  $filtersToCurrency.toCurrency(
                    this.paymentTransaction.receivedPaymentAmount,
                    this.paymentTransaction.toCurrencySymbol,
                    this.paymentTransaction.toCurrencyCode
                  )
                }}
              </div>
            </div>
          </div>
        </li>
        <!-- </f7-list-group>
          <f7-list-group > -->
        <f7-block-title>{{
          $t("avocashoutwithaccountnumber.validateCashOut")
        }}</f7-block-title>
        <li class="item-content">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title" style="color: gray; font-size: 75%">
                {{ $t("avocashoutwithaccountnumber.transferCode") }}
              </div>
              <div class="item-title" style="color: gray; font-size: 75%">
                {{ $t("avocashoutwithaccountnumber.transferPin") }}
              </div>
            </div>
            <div class="item-title-row">
              <div class="item-title" style="font-weight: bold">
                {{ this.paymentTransaction.transferCode }}
              </div>
              <div class="item-title" style="font-weight: bold">
                {{ this.paymentTransaction.transferPIN }}
              </div>
            </div>
          </div>
        </li>
      </f7-list-group></f7-list>
    <f7-button v-if="this.paymentTransaction.isValidated === false" raised fill ripple large class="margin" color="red"
      strong @click="validateDebitTransaction(paymentTransaction)">{{ $t("avocashoutwithaccountnumber.validateTransfer")
      }}</f7-button>
    <!-- </f7-col>
      <f7-col width="0" medium="25"></f7-col>
    </f7-row> -->
  </f7-page>
</template>
<script>
import userByAccountNumber from "../js/mixins/userByAccountNumber.js";
import {
  calculateTransferPayment,
  transferAgain,
} from "../js/mixins/transaction.js";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [userByAccountNumber, calculateTransferPayment, transferAgain],
  data() {
    return {
      theme,
      f7,
      title: "cash out with account number",
      noAccountError: "",
      time: 5,
      isRunning: false,
      interval: null,
      recipientuser: "",
      userAccountNumber: "",
      amountToPay: "",
      paymentCurrency: "",
      toastText: "",
      description: "",
    };
  },

  mounted: function () {
    const self = this;

    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }
    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
    if (this.profile) {
      this.paymentCurrency = this.primaryAccount.currencyCode;
      this.fromAccount = this.primaryAccount.accountNumber;
    }
    this.paymentCurrency = this.payerAccount.currencyCode;
    this.fromAccount = "Primary";
    if (!this.recipientCountry && this.userByAccountNumber) {
      this.toastText =
        "You cannot withdraw in" +
        " " +
        this.userByAccountNumber.country.countryName;
    }
  },
  methods: {
    changeAmountToPay() {
      const self = this;

      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        self.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              if (this.paymentCurrency === this.payerAccount.currencyCode) {
                if (
                  parseFloat(this.amountToPay) >
                  parseFloat(this.payerAccount.accountBalance)
                ) {
                  this.amountToPay = parseFloat(
                    this.payerAccount.accountBalance
                  );
                }
                this.calculatePayment();
              } else if (
                this.paymentCurrency !== this.payerAccount.currencyCode &&
                this.paymentCurrency !== null
              ) {
                if (
                  parseFloat(this.amountToPay) >
                  parseFloat(this.payerAccount.accountBalance) *
                  parseFloat(this.grossexchangerate2)
                ) {
                  this.amountToPay =
                    this.payerAccount.accountBalance *
                    parseFloat(this.grossexchangerate2) *
                    0.9;
                }
                this.calculatePaymentReverse();
              }
              this.noAccountError = "";
            }, 500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },
    changePaymentCurrency() {
      const self = this;

      if (this.paymentDetail || this.paymentReverseDetail) {
        self.$store.dispatch("transaction/resetPaymentDetail");
        self.$store.dispatch("transaction/resetPaymentReverseDetail");
      }
      this.changeAmountToPay();
    },

    cancelCashOut() {
      const self = this;

      this.amountToPay = "";
      this.userAccountNumber = "";
      this.paymentCurrency = "";
      this.fromAccount = "";

      self.$store.dispatch("account/resetUserByAccountNumber");
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
    },

    cashOutWithAccountNumber() {
      const self = this;

      if (f7.input.validateInputs("#cashOutWithAcctNber")) {
        f7.preloader.show("multi");
        self.$store
          .dispatch("payment/makePayment", {
            type: "avo¢ash out",
            amount: this.amountToPay,
            paymentCurrency: this.paymentCurrency,
            payerAccountNumber: this.payerAccount.accountNumber,
            payerGeolocation: this.latitude + "-" + this.longitude,
            payerIpAddress: this.ipAddress,

            payeeAccountNumber: this.userByAccountNumber.accountNumber,
            payeeGeolocation: "",
            payeeIpAddress: "",
            description: this.description,
          })
          .then((result) => {
            console.log(result);
            setTimeout(() => {
              if (this.fromAccount === "Primary") {
                self.$store.dispatch("account/loadPrimaryAccount");
              } else {
                self.$store.dispatch("account/loadBusinessAccount");
              }
            }, 500);
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
        f7router.refreshPage();
      } else {
        var valid = f7.$("#cashOutWithAcctNber .input-invalid").length === 0;
      }
    },
    cancelCashOut() {
      const self = this;

      this.amountToPay = "";
      this.userAccountNumber = "";
      this.paymentCurrency = "";
      this.fromAccount = "";

      self.$store.dispatch("account/resetUserByAccountNumber");
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
    },
    onPageBeforeRemove() {
      const self = this;

      this.cancelCashOut();
    },
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "qrcodeDecoded",
      "QRtext",
      "QRCode",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userDetail",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userByAccountNumber",
      "getusererror",
    ]),
    ...mapState("location", ["latitude", "longitude", "ipAddress"]),

    ...mapState("auth", ["profile"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    recipientCountry() {
      if (!this.userByAccountNumber) {
        return false;
      } else {
        return _.find(this.destinationCountry, [
          "toCountryId",
          this.userByAccountNumber.country.countryId,
        ]);
      }
    },
    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    payerAccount() {
      return this.primaryAccount;
    },
  },
};
</script>
