import { f7 } from "framework7-vue";
import _ from "lodash";
export const saving = {
  mounted() {
    if (this.savings) {
      this.share();
    }
  },
  methods: {
    //Tontine List
    async share() {
      const files = [];
      const base64Files = [];
      if (this.savings && this.savings.qrCode.length > 0) {
        const blob = await (
          await fetch(`data:image/png;base64,${this.savings.qrCode}`)
        ).blob();
        const termination = blob.type.split("/")[1];
        const file = new File([blob], `qrCode.${termination}`, {
          type: blob.type,
        });
        files.push(file);
        base64Files.push(`data:image/png;base64,${this.savings.qrCode}`);
        // }
      }

      this.sharedContent = {
        message: `${this.savings.tag}\n\n${this.savings.description}`,
        subject: this.savings.name,
        base64Files,
        files,
        link: `${f7.views.main.router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        imgUrl: "https://avocash.com/34x34.png",
      };
    },

    createNewGroup() {
      this.f7router.navigate("/saving/new");
    },

    createNewBattle() {
      const self = this;

      if (!self.actionsBattle) {
        self.actionsBattle = f7.actions.create({
          buttons: [
            {
              text: self.$t("common.selectAction"),
              label: true,
            },
            {
              text: self.$t("homepage.groupFriendFamily"),
              icon: `<i class="material-icons color-teal md-only">groups</i><i class="f7-icons color-teal if-not-md">person_3_fill</i>`,
              color: "teal",
              onClick() {
                self.f7router.navigate("/savings/new/battle");
              },
            },
            {
              text: self.$t("homepage.associations"),
              icon: `<i class="material-icons color-green">group_work</i>`,
              color: "green",
              onClick() {
                self.f7router.navigate("/savings/new/battle");
              },
            },
          ],
          // Need to specify popover target
          targetEl: self.$el.querySelector(".createBatlle"),
        });
      }

      // Open
      self.actionsBattle.open();
    },
    async selectBattle(battle) {
      f7.tab.show(`#tab-${battle.battleNumber}`, true);
      this.currentBattle = battle;
    },
    async removeParticipant(participant) {
      await this.$store.dispatch("savings/removeParticipant", {
        userId: participant.userId,
        savingsGroupId: this.savings.id,
      });
    },
    grantAdminRole(participant) {
      this.$store.dispatch("savings/grantAdminRole", {
        savingsGroupId: this.savings.id,
        userId: participant.userId,
      });
    },
    withdrawAdminRole(participant) {
      this.$store.dispatch("savings/withdrawAdminRole", {
        savingsGroupId: this.savings.id,
        userId: participant.userId,
      });
    },
    battleContribution(battle) {
      return _.reduce(
        battle.participantList,
        (amount, participant) => {
          return amount + parseFloat(participant.contribAmount || 0);
        },
        0
      );
    },
  },
  computed: {
    myBattles() {
      return _.chain(this.savings.battleList)
        .orderBy(["battleNumber"], ["desc"])
        .value();
    },
  },
};
