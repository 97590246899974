<template>
  <f7-page @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("deliveryAccount.createNew") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block strong>{{ $t("tontinecreatenew.createATontine") }} <br />{{
          $t("tontinecreatenew.youCanAdd")
        }}</f7-block>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped>
.calendar-day.calendar-day-prev {
  pointer-events: none;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Block,
  f7Card,
  f7CardContent,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7ListInput,
  f7Link,
  f7BlockTitle,
  f7TextEditor,
  f7Toolbar,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDocs from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDocs,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Block,
    f7Card,
    f7CardContent,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7ListInput,

    f7Link,
    f7BlockTitle,
    f7TextEditor,
    f7Toolbar,
  },
  mixins: [],
  data() {
    return {
      theme,
      f7,
      companyName: "",
      ownerName: "",
      email: "",
      phoneNumber: "",
      selectedCountry: "",
      deliverAccountInfo: "",
      forms: "",
      deliveryCities: [],
      type: "delivery",
    };
  },
  mounted() {
    this.selectedCountry = this.$keycloak.authenticated
      ? this.profile.countryCode
      : this.currentUserCountry.countryCode2;
    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    this.paymentCurrency = this.profile.currencyCode;
  },
  methods: {
    createDeliveryAccount() {
      f7.preloader.show("multi");
      if (f7.input.validateInputs("#createDeliveryAccount")) {
        const deliveryAccount = {
          companyName: this.companyName,
          ownerName: this.profile.name,
          accountAddress: this.accountAddress,
          deliverAccountInfo: this.deliverAccountInfo,
          email: this.email,
          phoneNumber: this.phoneNumber,
          phoneCode: this.profile.phoneCode,
          primaryAccount: this.primaryAccount.accountNumber,
          businessAccount: this.businessAccount.accountNumber,
          deliveryCities: this.deliveryCities,
          currencyCode: this.profile.currencyCode,
          countryCode: this.profile.countryCode,
          countryName: this.profile.countryName,
        };
        this.forms = new FormData();
        this.forms.append("tontine", JSON.stringify(deliveryAccount));

        for (const element of this.documentsUrl) {
          this.forms.append("files", element.file);
        }
        this.$store
          .dispatch("delivery/createDeliveryAccount", this.forms)
          .then((result) => {
            setTimeout(() => {
              this.f7router.navigate("/deliveryAccount/" + this.deliveryAccount.id);
              this.$store.dispatch("auth/setDocumentsUrl", "");
              f7.preloader.hide();
            }, 1000);
          })
          .then((err) => {
            console.log(err);
            f7.preloader.hide();
          });
      }
    },
    beforeOut() {
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("delivery", [
      "deliveryAccount",
      "delivery",
      "deliverer",
      "pickuPointAccount",
      "pickuPoint",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
