<template>
  <f7-page :page-content="false" no-shadow hide-bars-on-scroll>
    <f7-navbar
      no-shadow
      :back-link="$t('common.backButton')"
      :back-link-show-text="false"
      no-border
      :title="this.title"
    >
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar top no-shadow>
      <f7-link
        tab-link="#transfer-to-avocash"
        tab-link-active
        style="text-transform: lowercase"
        icon-ios="f7: dot_square"
        icon-aurora="f7: dot_square"
        icon-md="material:radio_button_checked"
      >
        <!-- <img src="/static/icon-new.ico" width="24" height="24" style="display: block; margin: auto;" /> -->
        <span><span class="avocash">avo¢ash</span> </span>
      </f7-link>
      <f7-link
        tab-link="#transfer-to-mobile"
        style="text-transform: lowercase"
        icon-ios="f7:device_phone_portrait"
        icon-aurora="f7:device_phone_portrait"
        icon-md="material:smartphone"
        :text="$t('topupbetweenaccounts.toMobile')"
      ></f7-link>
      <f7-link
        tab-link="#transfer-to-bank"
        style="text-transform: lowercase"
        icon-ios="f7:building_2_fill"
        icon-aurora="f7:building_2_fill"
        icon-md="material:account_balance"
        :text="$t('topupbetweenaccounts.toBank')"
      >
      </f7-link>
    </f7-toolbar>
    <f7-tabs swipeable animated>
      <f7-tab id="transfer-to-avocash" class="page-content" tab-active no-shadow>
        <f7-list dividers-ios strong-ios media-list class="margin-vertical-half">
          <f7-card style="background-size: cover; background-position: center">
            <f7-card-content>
              <span class="avocash">avo¢ash</span>{{ $t("topupbetweenaccounts.primary")
              }}<b>
                <span
                  v-html="
                    $filtersToCurrency.toCurrency(
                      primaryAccount.accountBalance,
                      primaryAccount.currencySymbol,
                      primaryAccount.currencyCode
                    )
                  "
                ></span>
              </b>
              <br />
              <span class="avocash">avo¢ash</span>{{ $t("topupbetweenaccounts.business")
              }}<b>
                <span
                  v-html="
                    $filtersToCurrency.toCurrency(
                      businessAccount.accountBalance,
                      businessAccount.currencySymbol,
                      businessAccount.currencyCode
                    )
                  "
                ></span>
              </b>
            </f7-card-content>
          </f7-card>
          <f7-card style="background-size: cover; background-position: center">
            <f7-card-header class="no-border" valign="bottom">{{
              $t("topupbetweenaccounts.selectSourceAnd")
            }}</f7-card-header>
            <f7-card-content :padding="false">
              <f7-list-group>
                <f7-list-item
                  smart-select
                  class="fromAccountNumber item-label"
                  :smart-select-params="{
                    closeOnSelect: true,
                    openIn: this.isTablet == true ? 'popover' : 'sheet',
                    sheetSwipeToClose: true,
                    sheetPush: true,
                  }"
                  :title="$t('topupbetweenaccounts.selectFromAccount')"
                >
                  <select
                    type="select"
                    name="account-type"
                    v-model="fromAccountNumber"
                    @change="selectFromAccount()"
                  >
                    <option :value="primaryAccount.accountNumber" selected>
                      {{ primaryAccount.accountType }}:
                      {{ primaryAccount.accountNumber }}
                    </option>
                    <option :value="businessAccount.accountNumber">
                      {{ businessAccount.accountType }}:
                      {{ businessAccount.accountNumber }}
                    </option>
                  </select>
                </f7-list-item>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title" style="font-size: 75%; color: gray">
                        {{
                          $t("topupbetweenaccounts.balance", {
                            accountType: this.fromAccountType,
                          })
                        }}
                      </div>
                    </div>
                    <div class="item-title-row" style="font-weight: bold">
                      <div class="item-title">
                        <span
                          v-html="
                            $filtersToCurrency.toCurrency(
                              this.fromAccountBalance,
                              primaryAccount.currencySymbol,
                              primaryAccount.currencyCode
                            )
                          "
                        ></span>
                      </div>
                    </div>
                  </div>
                </li>

                <f7-link
                  @click="changeAccount()"
                  icon-aurora="f7:arrow_right_arrow_left_circle_fill"
                  icon-ios="f7:arrow_right_arrow_left_circle_fill"
                  icon-md="material:swap_horizontal_circle"
                >
                </f7-link>
                <br />

                <f7-list-item
                  class="toAccountNumber item-label"
                  smart-select
                  backdrop
                  :smart-select-params="{
                    closeOnSelect: true,
                    openIn: this.isTablet == true ? 'popover' : 'sheet',
                    sheetSwipeToClose: true,
                    sheetPush: true,
                  }"
                  :title="$t('topupbetweenaccounts.selectToAccount')"
                >
                  <select
                    type="select"
                    name="account-type"
                    v-model="toAccountNumber"
                    @change="selectToAccount()"
                  >
                    <option :value="primaryAccount.accountNumber" selected>
                      {{ primaryAccount.accountType }}:
                      {{ primaryAccount.accountNumber }}
                    </option>
                    <option :value="businessAccount.accountNumber">
                      {{ businessAccount.accountType }}:
                      {{ businessAccount.accountNumber }}
                    </option>
                  </select>
                </f7-list-item>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title" style="font-size: 75%; color: gray">
                        {{
                          $t("topupbetweenaccounts.balance", {
                            accountType: this.toAccountType,
                          })
                        }}
                      </div>
                    </div>
                    <div class="item-title-row" style="font-weight: bold">
                      <div class="item-title">
                        {{
                          $filtersToCurrency.toCurrency(
                            this.toAccountBalance,
                            primaryAccount.currencySymbol,
                            primaryAccount.currencyCode
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </li>

                <li class="item-content item-input">
                  <div class="item-media">
                    {{ businessAccount.currencyCode }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-label">
                      {{ $t("topupbetweenaccounts.amountToTopup") }}
                    </div>
                    <div class="item-input-wrap">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        required
                        validate
                        inputmode="numeric"
                        data-error-message=""
                        name="totalCardbasket"
                        :value="transferAmount"
                        id="amountTosend"
                        @input="transferAmount = $event.target.value"
                        @change="amountToTopUp()"
                        @keyup="amountToTopUp()"
                        @blur="amountToTopUp()"
                      />
                      <span class="input-clear-link"></span>
                    </div>
                  </div>
                </li>
                <f7-block class="no-margin" v-if="this.noAccountError !== null">
                  <p style="font-size: 10px; font-style: italic; color: red">
                    {{ this.noAccountError }}
                  </p>
                </f7-block>
              </f7-list-group>
            </f7-card-content>

            <f7-button
              raised
              large
              fill
              color="red"
              strong
              class="margin-vertical buttons"
              text-color="white"
              style="border-radius: 0px"
              @click="topUpMyAccount()"
              >{{
                $t("topupbetweenaccounts.topUpMy", { accountType: this.toAccountType })
              }}</f7-button
            >
          </f7-card>
        </f7-list>
      </f7-tab>
      <!-- Transfer to mobile money account -->
      <f7-tab id="transfer-to-mobile" class="page-content">
        <f7-list dividers-ios strong-ios media-list class="margin-vertical-half">
          <f7-list-group>
            <f7-card style="background-size: cover; background-position: center">
              <f7-card-content :padding="false">
                <h5 class="margin">
                  {{ $t("topupbetweenaccounts.accountToTransferFrom") }}
                </h5>
                <f7-list-item
                  radio
                  :title="
                    this.primaryAccount.accountType +
                    ': ' +
                    this.primaryAccount.accountNumber
                  "
                  name="fromAccountNumberMobile"
                  :value="this.primaryAccount.accountNumber"
                  :after="
                    $filtersToCurrency.toCurrency(
                      this.primaryAccount.accountBalance,
                      this.primaryAccount.currencySymbol,
                      this.primaryAccount.currencyCode
                    )
                  "
                  @change="
                    (e) => {
                      if (e.target.checked)
                        fromAccountNumberMobile = this.primaryAccount.accountNumber;
                    }
                  "
                  checked
                ></f7-list-item>
                <f7-list-item
                  radio
                  :title="
                    businessAccount.accountType + ': ' + businessAccount.accountNumber
                  "
                  name="fromAccountNumberMobile"
                  :value="businessAccount.accountNumber"
                  :after="
                    $filtersToCurrency.toCurrency(
                      this.businessAccount.accountBalance,
                      this.businessAccount.currencySymbol,
                      this.businessAccount.currencyCode
                    )
                  "
                  @change="
                    (e) => {
                      if (e.target.checked)
                        fromAccountNumberMobile = this.businessAccount.accountNumber;
                    }
                  "
                ></f7-list-item>

                <f7-list-input
                  :label="$t('common.phoneNumber')"
                  floating-label
                  type="tel"
                  padding-right-half
                  name="userPhoneNumber"
                  disabled
                  :value="'+' + this.profile.phoneCode + ' ' + this.profile.phoneNumber"
                  @input="userPhoneNumber = $event.target.value"
                ></f7-list-input>

                <li class="item-content item-input">
                  <div class="item-media">
                    {{ primaryAccount.currencyCode }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-label">
                      {{ $t("topupbetweenaccounts.amountToTransfer") }}
                    </div>
                    <div class="item-input-wrap">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        required
                        validate
                        data-error-message=""
                        name="amountToTransferMobile"
                        min="0"
                        :placeholder="parseFloat(this.fromAccountBalanceMobile)"
                        :value="parseFloat(amountToTransferMobile)"
                        @input="amountToTransferMobile = $event.target.value"
                        @change="changeAmountToTransferMobile()"
                        @keyup="changeAmountToTransferMobile()"
                        @blur="changeAmountToTransferMobile()"
                      />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
              </f7-card-content>
              <f7-button
                raised
                large
                fill
                style="border-radius: 0px"
                color="red"
                strong
                class="margin-vertical buttons"
                text-color="white"
                @click="transferToMobile()"
                >{{ $t("topupbetweenaccounts.transferToMobile") }}
              </f7-button>
            </f7-card>
          </f7-list-group>
        </f7-list>
      </f7-tab>
      <!-- Transfer to bank account -->
      <f7-tab id="transfer-to-bank" class="page-content">
        <f7-list dividers-ios strong-ios media-list class="margin-vertical-half">
          <f7-list-group>
            <f7-card style="background-size: cover; background-position: center">
              <f7-card-content :padding="false">
                <h5 class="margin">
                  {{ $t("topupbetweenaccounts.accountToTransferFrom") }}
                </h5>
                <f7-list-item
                  radio
                  :title="
                    this.primaryAccount.accountType +
                    ': ' +
                    this.primaryAccount.accountNumber
                  "
                  name="fromAccountNumberBank"
                  :value="this.primaryAccount.accountNumber"
                  :after="
                    $filtersToCurrency.toCurrency(
                      this.primaryAccount.accountBalance,
                      this.primaryAccount.currencySymbol,
                      this.primaryAccount.currencyCode
                    )
                  "
                  @change="
                    (e) => {
                      if (e.target.checked)
                        fromAccountNumberBank = this.primaryAccount.accountNumber;
                    }
                  "
                  checked
                ></f7-list-item>
                <f7-list-item
                  radio
                  :title="
                    businessAccount.accountType + ': ' + businessAccount.accountNumber
                  "
                  name="fromAccountNumberBank"
                  :value="businessAccount.accountNumber"
                  :after="
                    $filtersToCurrency.toCurrency(
                      this.businessAccount.accountBalance,
                      this.businessAccount.currencySymbol,
                      this.businessAccount.currencyCode
                    )
                  "
                  @change="
                    (e) => {
                      if (e.target.checked)
                        fromAccountNumberBank = this.businessAccount.accountNumber;
                    }
                  "
                ></f7-list-item>

                <f7-list-input
                  :label="$t('topupbetweenaccounts.bankAccount')"
                  floating-label
                  type="text"
                  padding-right-half
                  name="userBankAccount"
                  disabled
                  :value="this.userBankAccount"
                  @input="userBankAccount = $event.target.value"
                ></f7-list-input>

                <li class="item-content item-input">
                  <div class="item-media">
                    {{ primaryAccount.currencyCode }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-label">
                      {{ $t("topupbetweenaccounts.amountToTransfer") }}
                    </div>
                    <div class="item-input-wrap">
                      <input
                        type="number"
                        pattern="[0-9]*"
                        required
                        validate
                        data-error-message=""
                        name="amountToTransferBank"
                        :placeholder="this.fromAccountBalanceBank"
                        min="0"
                        :value="parseFloat(amountToTransferBank)"
                        @input="amountToTransferBank = $event.target.value"
                        @change="changeAmountToTransferBank()"
                        @keyup="changeAmountToTransferBank()"
                        @blur="changeAmountToTransferBank()"
                      />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
              </f7-card-content>
              <f7-button
                raised
                large
                fill
                style="border-radius: 0px"
                color="red"
                strong
                class="margin-vertical buttons"
                text-color="white"
                @click="transferToBank()"
                >{{ $t("topupbetweenaccounts.transferToBank") }}</f7-button
              >
            </f7-card>
          </f7-list-group>
        </f7-list>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<style scoped>
.avocash-icon .md {
  font-size: 14px;
  padding-bottom: opx;
}

.ios .avocash-icon {
  font-size: 17px;
  padding-bottom: opx;
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Toolbar,
  f7Link,
  f7Tabs,
  f7Tab,
  f7BlockTitle,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Button,
  f7CardContent,
  f7Card,
  f7CardHeader,
  f7Block,
  theme,
  f7,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Toolbar,
    f7Link,
    f7Tabs,
    f7Tab,
    f7BlockTitle,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7Button,
    f7CardContent,
    f7Card,
    f7CardHeader,

    f7Block,
  },
  data() {
    return {
      theme,
      f7,
      fromAccountNumber: "",
      toAccountNumber: "",
      transferAmount: "",
      fromAccountBalance: "",
      toAccountBalance: "",
      title: $t("topupbetweenaccounts.transferBetweenAccount"),
      noAccountError: "",
      fromAccountType: "",
      toAccountType: "",
      userPhoneNumber: "",
      amountToTransferMobile: "",
      fromAccountNumberMobile: "",
      userBankAccount: "",
      amountToTransferBank: "",
      fromAccountNumberBank: "",
    };
  },
  mounted() {
    const self = this;

    this.fromAccountNumber = this.primaryAccount.accountNumber;
    this.fromAccountType = this.primaryAccount.accountType;
    this.fromAccountBalance = this.primaryAccount.accountBalance;
    f7.$(".fromAccountNumber .item-after").html(
      this.fromAccountType + ": " + this.fromAccountNumber
    );
    this.toAccountNumber = this.businessAccount.accountNumber;
    this.toAccountType = this.businessAccount.accountType;
    this.toAccountBalance = this.businessAccount.accountBalance;
    f7.$(".toAccountNumber .item-after").html(
      this.toAccountType + ": " + this.toAccountNumber
    );
    this.fromAccountNumberMobile = this.primaryAccount.accountNumber;
    this.fromAccountNumberBank = this.primaryAccount.accountNumber;
    var calculatorKeypad = f7.keypad.create({
      inputEl: ".currencyField",
      type: "calculator",
      toolbarCloseText: "OK",
      on: {
        change(keypad, value) {
          // f7$(".currencyField").val(value);
          self.transferAmount = value;
          console.log(value);
        },
      },
    });
    console.log(calculatorKeypad);
  },

  methods: {
    selectFromAccount() {
      const self = this;

      if (this.fromAccountNumber === this.primaryAccount.accountNumber) {
        this.fromAccountType = this.primaryAccount.accountType;
        this.fromAccountBalance = this.primaryAccount.accountBalance;

        this.toAccountNumber = this.businessAccount.accountNumber;
        this.toAccountType = this.businessAccount.accountType;
        this.toAccountBalance = this.businessAccount.accountBalance;
      } else {
        this.fromAccountNumber = this.businessAccount.accountNumber;
        this.fromAccountType = this.businessAccount.accountType;
        this.fromAccountBalance = this.businessAccount.accountBalance;

        this.toAccountNumber = this.primaryAccount.accountNumber;
        this.toAccountType = this.primaryAccount.accountType;
        this.toAccountBalance = this.primaryAccount.accountBalance;
      }
      f7.$(".toAccountNumber .item-after").html(
        this.toAccountType + ": " + this.toAccountNumber
      );
    },
    selectToAccount() {
      const self = this;

      if (this.toAccountNumber === this.businessAccount.accountNumber) {
        this.toAccountType = this.businessAccount.accountType;
        this.toAccountBalance = this.businessAccount.accountBalance;

        this.fromAccountNumber = this.primaryAccount.accountNumber;
        this.fromAccountType = this.primaryAccount.accountType;
        this.fromAccountBalance = this.primaryAccount.accountBalance;
      } else {
        this.toAccountNumber = this.primaryAccount.accountNumber;
        this.toAccountType = this.primaryAccount.accountType;
        this.toAccountBalance = this.primaryAccount.accountBalance;

        this.fromAccountNumber = this.businessAccount.accountNumber;
        this.fromAccountType = this.businessAccount.accountType;
        this.fromAccountBalance = this.businessAccount.accountBalance;
      }
      f7.$(".fromAccountNumber .item-after").html(
        this.fromAccountType + ": " + this.fromAccountNumber
      );
    },
    changeAccount() {
      const self = this;

      if (this.fromAccountNumber == this.primaryAccount.accountNumber) {
        this.toAccountNumber == this.primaryAccount.accountNumber;
        this.toAccountType === this.primaryAccount.accountType;
        this.toAccountBalance == this.primaryAccount.accountBalance;
        console.log("From " + this.fromAccountType);
        this.fromAccountType == this.businessAccount.accountNumber;
        this.fromAccountType === this.businessAccount.accountType;
        this.fromAccountBalance == this.businessAccount.accountBalance;
        f7.$(".toAccountNumber .item-after").html(
          this.toAccountType + ": " + this.toAccountNumber
        );
        f7.$(".fromAccountNumber .item-after").html(
          this.fromAccountType + ": " + this.fromAccountNumber
        );
      } else {
        this.fromAccountNumber == this.businessAccount.accountNumber;
        this.fromAccountType === this.businessAccount.accountType;
        this.fromAccountBalance == this.businessAccount.accountBalance;
        console.log(this.fromAccountType);

        this.toAccountNumber == this.primaryAccount.accountNumber;
        this.toAccountType === this.primaryAccount.accountType;
        this.toAccountBalance == this.primaryAccount.accountBalance;
        f7.$(".toAccountNumber .item-after").html(
          this.toAccountType + ": " + this.toAccountNumber
        );
        f7.$(".fromAccountNumber .item-after").html(
          this.fromAccountType + ": " + this.fromAccountNumber
        );
      }
    },

    amountToTopUp() {
      const self = this;

      // var amountoTPayKP = f7.$(".amountTosend")[0].f7Keypad;
      // var amountoTPayKP = f7.keypad.get(".amountTosend");
      // this.transferAmount = .value;
      console.log(this.transferAmount);
      if (
        parseFloat(this.transferAmount) > parseFloat(this.fromAccountBalance) &&
        this.transferAmount !== null &&
        this.fromAccountBalance !== null
      ) {
        this.noAccountError = "";
        this.transferAmount = parseFloat(this.fromAccountBalance);
      } else if (
        parseFloat(this.transferAmount) <= parseFloat(this.fromAccountBalance) &&
        this.fromAccountBalance !== null
      ) {
        this.noAccountError = "";
      } else {
        this.noAccountError = "Select From and To accounts!";
        this.transferAmount = null;
      }
    },
    async topUpMyAccount() {
      const self = this;

      if (
        this.fromAccountNumber !== null &&
        this.toAccountNumber !== null &&
        parseFloat(this.transferAmount) > 0
      ) {
        await self.$store.dispatch("transaction/topupBetweenAccounts", {
          fromAccountNumber: this.fromAccountNumber,
          fromAccountType: this.fromAccountType,
          toAccountNumber: this.toAccountNumber,
          toAccountType: this.toAccountType,
          transferAmount: parseFloat(this.transferAmount),
        });
        await self.$store.dispatch("account/loadPrimaryAccount");
        await self.$store.dispatch("account/loadBusinessAccount");
        // .then(response => {
        if (!self.notificationFull) {
          self.notificationFull = await f7.notification.create({
            icon:
              '<img src="/32x32.png" width="32" height="32" style="display: block; margin: auto;" />',
            title: "avo¢ash",
            titleRightText: "Top up successful",
            subtitle:
              "You have topped up your avo¢ash account with " +
              this.businessAccount.currencySymbolNative +
              "" +
              this.transferAmount,
            text:
              this.businessAccount.currencySymbolNative +
              "" +
              this.transferAmount +
              " top up from " +
              this.fromAccountNumber +
              " to " +
              this.toAccountNumber,
            closeTimeout: 5000,
          });
        }
        await self.notificationFull.open();
        await this.f7router.navigate("/dashboard/", {
          ignoreCache: true,
          reloadCurrent: true,
        });
        // });
      } else {
        const self = this;
        if (!self.toastTop) {
          self.toastTop = await f7.toast.create({
            text: "Please select From and To accounts and input amount to transfer!",
            cssClass: "checkCardToast",
            position: "top",
            closeTimeout: 2000,
          });
        }
        await self.toastTop.open();
        return false;
      }
    },

    changeAmountToTransferMobile() {},
    transferToMobile() {},
    changeAmountToTransferBank() {},
    transferToBank() {},
  },

  computed: {
    ...mapState("account", ["primaryAccount", "businessAccount"]),
    ...mapState("auth", ["queryerror", "profile", "isDeskTop", "isTablet"]),

    ...mapGetters("auth", [
      "queryerror",
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
    ]),
    fromAccountBalanceMobile() {
      if (this.fromAccountNumberMobile === this.primaryAccount.accountNumber) {
        return this.primaryAccount.accountBalance;
      } else {
        return this.businessAccount.accountBalance;
      }
    },
    fromAccountBalanceBank() {
      if (this.fromAccountNumberBank === this.primaryAccount.accountNumber) {
        return this.primaryAccount.accountBalance;
      } else {
        return this.businessAccount.accountBalance;
      }
    },
  },
};
</script>
