<template>
  <f7-page hide-bars-on-scroll no-toolbar>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-html="$t('common.validatePayment')"></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <span v-if="!this.paymentTransaction">
          <f7-block-title outline class="skeleton-text skeleton-effect-blink">
            {{ $t("paywithaccountnumber.validateOrSharePin") }}
          </f7-block-title>
          <f7-list media-list class="skeleton-text skeleton-effect-blink margin-vertical-half">
            <f7-list-group>
              <f7-block-title>{{
                $t("paywithaccountnumber.paymentTo", {
                  payeeName: "FirstName Lastname",
                })
              }}</f7-block-title>
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title" style="color: gray; font-size: 75%">
                      {{ $t("paywithaccountnumber.youPaid") }}
                    </div>
                    <div class="item-title" style="color: gray; font-size: 75%">
                      {{ $t("paywithaccountnumber.recipientGets") }}
                    </div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title" style="font-weight: bold">25 EUR</div>
                    <div class="item-title" style="font-weight: bold">45800 CFA</div>
                  </div>
                </div>
              </li>
              <f7-list-item :header="$t('common.description')" title="Particpation voyage">
              </f7-list-item>
              <f7-block-title>{{ $t("common.validatePayment") }}</f7-block-title>
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title" style="color: gray; font-size: 75%">
                      {{ $t("common.transferCode") }}
                    </div>
                    <div class="item-title" style="color: gray; font-size: 75%">
                      {{ $t("common.transferPin") }}
                    </div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title" style="font-weight: bold">
                      4582 8622 8852 8922
                    </div>
                    <div class="item-title" style="font-weight: bold">4585</div>
                  </div>
                </div>
              </li>
            </f7-list-group>
          </f7-list>
          <f7-button raised fill ripple large color="gray" strong class="skeleton-text skeleton-effect-blink margin">{{
}}</f7-button>
        </span>
        <f7-block-title v-if="this.paymentTransaction && this.paymentTransaction.isValidated === false">
          {{ $t("paywithaccountnumber.validateOrSharePin") }}
        </f7-block-title>
        <f7-list v-if="this.paymentTransaction.isValidated === false" media-list form class="margin-vertical-half">
          <f7-list-group>
            <f7-block-title>{{
              $t("paywithaccountnumber.paymentTo", {
                payeeName: this.paymentTransaction.payeeName,
              })
            }}</f7-block-title>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("paywithaccountnumber.youPaid") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%" v-if="this.paymentTransaction.fromCurrencyCode !==
                    this.paymentTransaction.toCurrencyCode
                    ">
                    {{ $t("paywithaccountnumber.recipientGets") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentTransaction.grossPaymentAmount,
                      null,
                      this.paymentTransaction.fromCurrencyCode
                    )
                      "></span>
                  </div>
                  <div class="item-title" style="font-weight: bold" v-if="this.paymentTransaction.fromCurrencyCode !==
                    this.paymentTransaction.toCurrencyCode
                    ">
                    <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentTransaction.receivedPaymentAmount,
                      null,
                      this.paymentTransaction.toCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
            <f7-list-item :header="$t('common.description')" :title="this.paymentTransaction.description"></f7-list-item>
            <f7-block-title>{{ $t("common.validatePayment") }}</f7-block-title>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferCode") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferPin") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.paymentTransaction.transferCode }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ this.paymentTransaction.transferPIN }}
                  </div>
                </div>
              </div>
            </li>
          </f7-list-group>
        </f7-list>
        <f7-button v-if="this.paymentTransaction.isValidated === false" raised fill ripple large class="margin"
          color="red" strong @click="validateDebitTransaction(paymentTransaction)">{{ $t("common.validatePayment") }}
        </f7-button>
        <f7-button v-if="this.paymentTransaction.isValidated === false" raised outline ripple large class="margin"
          color="primary" strong @click="cancelPayment(paymentTransaction)">{{ $t("common.cancelPayment") }}</f7-button>
      </div>
      <div class="right_layout"></div>
    </div>
    <f7-fab v-if="this.paymentTransaction &&
      this.paymentTransaction.isValidated === false &&
      this.sharedContent !== ''
      " position="right-bottom" class="shareButton" slot="fixed" color="auto" @click="socialShare(sharedContent)">
      <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
import { theme, f7 } from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { payment } from "../js/mixins/payment.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [calculateTransferPayment, transferAgain, socialShare, payment],
  data() {
    return {
      theme,
      f7,
      amountToPay: "",
      description: "",
      fromAccount: "",
      sharedContent: "",
      fileToSave: [],
    };
  },
  mounted: function () {
    const self = this;

    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    setInterval(() => {
      if (!this.paymentTransaction && this.sharedContent === "") {
        console.log(this.paymentTransaction);
        this.share();
      }
    }, 300);
  },
  methods: {
    share() {
      const self = this;

      const files = "";
      const base64Files = "";
      if (!!this.paymentTransaction) {
        this.sharedContent = {
          message: `${self.$t("paywithaccountnumber.transferPin")}: ${this.paymentTransaction.transferPIN
            }`,
          subject: `${this.paymentTransaction.description}: ${this.paymentTransaction.receivedPaymentAmount}' '${this.paymentTransaction.toCurrencyCode}`,
          files,
          base64Files,
          url: `https://avocash.com/#/my-transactions/${this.paymentTransaction.payeeAccountNumber
            }/${this.paymentTransaction.payeeAccountType.toLowerCase()}TransactionList`,
        };
        console.log(this.sharedContent);
      }
    },
    // cancelpayment() {
    //   const self = this;
    //
    //   this.amountToPay = "";
    //   self.$store.dispatch("account/resetUserByAccountNumber");
    //   self.$store.dispatch("transaction/resetPaymentDetail");
    //   self.$store.dispatch("transaction/resetPaymentReverseDetail");
    // },
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("account", ["userAccount", "getusererror"]),
    ...mapState("location", ["latitude", "longitude", "ipAddress"]),
    ...mapState("auth", ["profile"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
  },
};
</script>
