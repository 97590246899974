<template>
  <f7-page class="bankWire_page">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title>{{ $t("bankWire.bankwire") }}</f7-nav-title>
      <f7-nav-right v-if="this.isTablet == false">
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <!-- <f7-list dividers-ios strong-ios  media-list v-if="!this.transaction">
          <f7-list-item v-if="
            this.userAccount.standard !== null &&
            this.userAccount.standard.status === 'ACTIVE' &&
            this.userAccount.business !== null &&
            this.userAccount.business.status === 'ACTIVE'
          " :header="$t('common.selectAccount')" smart-select :smart-select-params="{
  openIn: this.isTablet ? 'popover' : 'sheet',
  closeOnSelect: true,
}">
            <select name="account_id" v-model="account_id">
              <option v-if="
                this.userAccount.standard !== null &&
                this.userAccount.standard.status === 'ACTIVE'
              " :value="this.userAccount.standard.id" selected>
                {{ getAccountType(userAccount.standard.type) }}
              </option>
              <option v-if="
                this.userAccount.business !== null &&
                this.userAccount.business.status === 'ACTIVE'
              " :value="userAccount.business.id" selected>
                {{ getAccountType(userAccount.business.type) }}
              </option>
            </select>
          </f7-list-item>
          <f7-list-item v-if="
            this.userAccount.standard !== null &&
            this.userAccount.standard.status === 'ACTIVE' &&
            (this.userAccount.business === null ||
              this.userAccount.business.status !== 'ACTIVE')
          " :title="getAccountType(userAccount.standard.type)" :subtitle="this.userAccount.standard.id">
          </f7-list-item>
          <f7-button raised fill large ripple color="red" class="margin" @click="transferFromBankAccount()">
            {{ $t("common.transferNow") }}</f7-button>
        </f7-list> -->
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card outline header-divider raised v-if="this.baseTransfer.amount !== ''">
          <f7-card-header>{{ $t("common.paymentDetails") }}</f7-card-header>
          <f7-list media-list>
            <f7-list-item :header="$t('common.amount')" :title="$filtersToCurrency.toCurrency(
              this.baseTransfer.amount,
              null,
              this.baseTransfer.currency
            )
              " />
            <f7-list-item :header="$t('common.tag')" :title="this.baseTransfer.tag" />
            <f7-list-item :header="$t('common.description')" :title="this.baseTransfer.description" /></f7-list>
        </f7-card>
        <f7-list dividers-ios strong-ios media-list v-if="this.bankWire">
          <f7-block-title medium style="white-space: normal; word-wrap: break-word; margin-left: 16px">{{
            $t("bankAccount.bankwireInstruction") }}</f7-block-title>
          <f7-list-item :title="$t('bankAccount.transactionId')" :subtitle="bankWire.id" link="#" id="transactionId"
            :footer="$t('bankAccount.descriptionorlabel')"><template #after><f7-icon ios="f7:doc_on_clipboard"
                md="material:content_copy" @click="copyTransactionId()" /></template>
          </f7-list-item>
          <f7-list-item :title="$t('bankAccount.number')" :subtitle="bankWire.iban" link="#" id="iban"><template
              #after><f7-icon ios="f7:doc_on_clipboard" md="material:content_copy" @click="copyIBAN()" /></template>
          </f7-list-item>
          <f7-list-item :title="$t('bankAccount.bic')" :subtitle="bankWire.bic" link="#" id="bic"><template
              #after><f7-icon ios="f7:doc_on_clipboard" md="material:content_copy" @click="copyBIC()" /></template>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7List,
  f7ListGroup,
  f7BlockTitle,
  f7Accordion,
  f7BlockHeader,
  f7ListItem,
  f7ListInput,
  f7Button,
  theme,
  f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { account } from "../js/mixins/account.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    transferDetail: Object,
  },
  mixins: [account],

  components: {
    f7List,
    f7ListGroup,
    f7BlockTitle,
    f7Accordion,
    f7BlockHeader,
    f7ListItem,
    f7ListInput,
    f7Button,

    navbarright: Navbarright,
  },
  data() {
    return {
      theme,
      f7,
      account_id: "",
      tag: "",
      type: "bankAccount",
      toastIcon: "",
    };
  },
  mounted() {
    const self = this;

    // if (this.profile) {
    //   this.holder_name = this.profile.name;
    // }
    // if (!this.userAccount && this.$keycloak.authenticated) {
    //   self.$store.dispatch("account/loadAccount", this.profile.sub);
    // }
    // if (this.$keycloak.authenticated) {
    //   self.$store.dispatch("apimoney/getAccountList");
    // }
  },

  methods: {
    transferFromBankAccount() {
      const self = this;
      self.$store.dispatch("apimoney/initCashInBankWire", {
        partner_ref: "",
        payer_account_id: this.account_id,
        tag: this.transferDetail.description,
        amount: this.transferDetail.amount,
        receiver_wallet_id: this.transferDetail.receiver_wallet_id,
        fees_wallet_id: this.transferDetail.fees_wallet_id,
        fees: this.transferDetail.fees ? this.transferDetail.fees : 0,
      });
    },
    copyText(copyText) {
      const self = this;
      console.log("copied text", copyText);
      navigator.clipboard.writeText(copyText).then(() => {
        if (!self.toastIcon) {
          self.toastIcon = f7.toast.create({
            icon: theme.ios
              ? '<i class="f7-icons color-primary">checkmark_alt_circle_fill</i>'
              : '<i class="material-icons color-primary">check_circle_outline</i>',
            text: self.$t("common.copied"),
            position: "center",
            closeTimeout: 2000,
          });
        }
        // Open it
        self.toastIcon.open();
      });
    },
    copyTransactionId() {
      const id = f7.$("#transactionId div.item-subtitle");
      this.copyText(id[0].innerText);
    },
    copyIBAN() {
      const id = f7.$("#iban div.item-subtitle");
      this.copyText(id[0].innerText);
    },
    copyBIC() {
      const id = f7.$("#bic div.item-subtitle");
      this.copyText(id[0].innerText);
    },
    copyAll() { },
  },
  computed: {
    ...mapState("apimoney", [
      "bankAccount",
      "accountList",
      "bankAccountList",
      "standardAccount",
      "businessAccount",
      "transaction",
      "bankWire",
      "baseTransfer",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "documentsUrl", "document"]),
    ...mapState("account", ["userAccount"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
</script>
