<template>
  <div>
    <f7-card
      class="text-align-center elevation-3 padding-vertical-half"
      style="font-weight: bold"
      href="/userprofile/"
    >
      <f7-card-content :padding="false" v-if="this.profile">
        {{ this.profile.name }}
      </f7-card-content>
      <f7-card-content :padding="false" v-else-if="this.userAccount">
        {{ this.userAccount.name }}
      </f7-card-content>
    </f7-card>
    <f7-card outline v-if="this.userAccount && this.userAccount.standard !== null">
      <a href="#" @click="goToPrimaryAccount()">
        <h4>
          {{ this.userAccount.standard.type }} :
          {{ this.userAccount.standard.id }}
        </h4>
        <!-- <h3>
              <span  v-html="
                $filtersToCurrency.toCurrency(
                  this.primaryAccount.accountBalance,
                  this.primaryAccount.currencySymbol,
                  this.primaryAccount.currencyCode
                )
              "></span>
            </h3> -->
        <f7-link
          href="#"
          icon-size="44"
          icon-ios="f7:tray_2"
          icon-aurora="f7:tray_2"
          icon-md="material:account_balance_wallet"
        ></f7-link>
        <p class="text-align-center" href="#">
          {{ $t("dashboard.viewMyTransactions") }}
        </p>
      </a>
    </f7-card>
    <f7-card outline v-if="this.userAccount && this.userAccount.business !== null">
      <a
        href="#"
        @click="goToBusinessAccount()"
        :class="this.businessColor"
        v-if="this.userAccount.business"
      >
        <h4>
          {{ this.userAccount.business.type }} :
          {{ this.userAccount.business.id }}
        </h4>
        <!-- <h3>
              <span  v-html="
                $filtersToCurrency.toCurrency(
                  this.businessAccount.accountBalance,
                  this.businessAccount.currencySymbol,
                  this.businessAccount.currencyCode
                )
             "></span>
            </h3> -->
        <f7-link
          class="text-align-center"
          href="#"
          icon-size="44"
          icon-ios="f7:square_stack_3d_up"
          icon-aurora="f7:square_stack_3d_up"
          icon-md="material:account_balance"
        ></f7-link>
        <p class="text-align-center" href="#">
          <span v-if="this.userAccount.business.status === 'ACTIVE'">{{
            $t("dashboard.viewMyTransactions")
          }}</span>
          <span v-else>
            <span>{{ this.userAccount.business.status }}</span>
            <a>{{ $t("dashboard.activateAccount") }}</a>
          </span>
        </p>
      </a>
      <a v-else
        ><f7-card class="padding-vertical-half">
          <f7-link
            class="text-align-center"
            href="#"
            icon-size="44"
            icon-ios="f7:square_stack_3d_up"
            icon-aurora="f7:square_stack_3d_up"
            icon-md="material:account_balance"
          ></f7-link>
          <p class="text-align-center" href="#">
            <span>{{ $t("dashboard.activateAccount") }}</span>
          </p>
        </f7-card></a
      >
    </f7-card>
    <f7-list dividers-ios strong-ios class="padding-vertical">
      <f7-list-item link="/topup/between-accounts" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="primary"
            aurora="f7:arrow_right_arrow_left_square"
            ios="f7:arrow_right_arrow_left_square"
            md="material:swap_horiz"
          ></f7-icon>
        </template>
        {{ $t("dashboard.transferBtwAcounts") }}
      </f7-list-item>

      <f7-list-item link="/topup/my-account" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="red"
            aurora="f7:square_arrow_up_on_square"
            ios="f7:square_arrow_up_on_square"
            md="material:add_circle_outline"
          ></f7-icon>
        </template>
        {{ $t("dashboard.topupMyAcounts") }}
      </f7-list-item>

      <f7-list-item link="/user/profile" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="primary"
            aurora="f7:person_round"
            ios="f7:person_round"
            md="material:person_outline"
          ></f7-icon>
        </template>
        {{ $t("dashboard.goToMyProfile") }}
      </f7-list-item>

      <f7-list-item link="/recipients" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="red"
            aurora="f7:person_2_square_stack"
            ios="f7:person_2_square_stack"
            md="material:people_outline"
          ></f7-icon>
        </template>
        {{ $t("dashboard.goToMyRecipients") }}
      </f7-list-item>
      <f7-list-item link="/card/sold" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="primary"
            aurora="f7:creditcard_fill"
            ios="f7:creditcard_fill"
            md="material:credit_card"
          ></f7-icon>
        </template>
        {{ $t("dashboard.soldCards") }}
      </f7-list-item>
      <f7-list-item link="/card/new" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="red"
            aurora="f7:creditcard_fill"
            ios="f7:creditcard_fill"
            md="material:credit_card"
          >
            <f7-badge color="primary">+</f7-badge>
          </f7-icon>
        </template>
        {{ $t("dashboard.issueNewCard") }}
      </f7-list-item>

      <f7-list-item link="#" @click="getTontineList()" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="primary"
            aurora="f7:person_3"
            ios="f7:person_3"
            md="material:group_work"
          >
          </f7-icon>
        </template>
        {{ $t("dashboard.myTontines") }}
      </f7-list-item>
      <f7-list-item link="/tontine/new" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="red"
            aurora="f7:person_3"
            ios="f7:person_3"
            md="material:group_work"
          >
            <f7-badge color="primary">+</f7-badge>
          </f7-icon>
        </template>
        {{ $t("dashboard.createATontine") }}
      </f7-list-item>
      <f7-list-item link="/associations" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="primary"
            ios="f7:heart"
            aurora="f7:heart"
            md="material:favorite_border"
          ></f7-icon>
        </template>
        {{ $t("dashboard.myAssociationscircles") }}
      </f7-list-item>
      <f7-list-item link="/association/new/association" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="red"
            ios="f7:heart"
            aurora="f7:heart"
            md="material:favorite_border"
          >
            <f7-badge color="primary">+</f7-badge>
          </f7-icon>
        </template>
        {{ $t("dashboard.newAssociationcircle") }}
      </f7-list-item>

      <f7-list-item link="#" @click="getUserWalletList()" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="primary"
            aurora="f7:circle_grid_hex"
            ios="f7:circle_grid_hex"
            md="material:grain"
          >
          </f7-icon>
        </template>
        {{ $t("dashboard.myCrowdfunding") }}
      </f7-list-item>
      <f7-list-item link="/wallet/new" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="red"
            aurora="f7:circle_grid_hex"
            ios="f7:circle_grid_hex"
            md="material:grain"
          >
            <f7-badge color="primary">+</f7-badge>
          </f7-icon>
        </template>
        {{ $t("dashboard.newCrowdfunding") }}
      </f7-list-item>
      <!-- <f7-row no-gap class="text-align-center">
        <f7-col class="dashboardColBorderRight">
           <f7-list-item link="/wallet/new/private">
            <f7-icon
              size="44px"

              aurora="f7:archivebox_fill"
              ios="f7:archivebox_fill"
              md="f7:archivebox_fill"
            >
              <f7-badge color="red">+</f7-badge>
            </f7-icon>
            <br />{{ $t("dashboard.newMoneypot") }}</a
          >
        </f7-col>
        <f7-col>
           <f7-list-item link="/wallet/private">
            <f7-icon
              size="44px"

              color="red"
              aurora="f7:archivebox_fill"
              ios="f7:archivebox_fill"
              md="f7:archivebox_fill"
            ></f7-icon>
            <br />{{ $t("dashboard.myMoneypots") }}</a
          >
        </f7-col>
      </f7-row>
      <br /> -->
      <f7-list-item link="/shops" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="primary"
            aurora="f7:cart_fill"
            ios="f7:cart_fill"
            md="material:store"
          ></f7-icon>
        </template>
        <div>{{ $t("dashboard.myShops") }}</div>
      </f7-list-item>
      <f7-list-item link="/shop/new" panel-close>
        <template #media>
          <f7-icon
            size="44px"
            color="red"
            aurora="f7:cart_fill"
            ios="f7:cart_fill"
            md="material:store"
          >
            <f7-badge color="primary">+</f7-badge>
          </f7-icon>
        </template>
        {{ $t("dashboard.newShop") }}
      </f7-list-item>
    </f7-list>
  </div>
</template>
<style scoped>
@media (min-width: 768px) {
  .dashboardColBorderRight {
    border-right: 1px solid var(--f7-theme-color);
  }
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Card,
  f7CardContent,
  f7CardHeader,
  f7Icon,
  f7Badge,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Card,
    f7CardContent,
    f7CardHeader,

    f7Icon,
    f7Badge,
  },
  data() {
    return { theme, f7, theme };
  },
  mounted() {
    const self = this;

    if (this.userAccount === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.isTablet == false) {
      f7.$("div.dashboard_page.page-content").addClass("hide-bars-on-scroll");
    }
  },
  methods: {
    async goToPrimaryAccount() {
      const self = this;

      f7.preloader.show("multi");
      await self.$store.dispatch("account/loadAccount", this.profile.sub);
      setTimeout(() => {
        f7.preloader.hide();
        f7.views.main.router.navigate(
          "/my-transactions/" + this.userAccount.standard.id + "/primaryTransactionList"
        );
      }, 300);
    },

    async goToBusinessAccount() {
      const self = this;

      f7.preloader.show("multi");
      self.$store.dispatch("account/loadAccount", this.profile.sub);
      setTimeout(() => {
        f7.preloader.hide();
        f7.views.main.router.navigate(this.businessaccountUrl);
      }, 300);
    },

    async getTontineList() {
      const self = this;

      f7.preloader.show("multi");
      await self.$store.dispatch("tontine/getUserTontinesByuserId", 6);
      setTimeout(() => {
        f7.preloader.hide();
        f7.views.main.router.navigate("/tontines");
      }, 300);
    },
    async getUserWalletList() {
      const self = this;

      f7.preloader.show("multi");
      await self.$store.dispatch("wallet/getPrivateWalletList", 6);
      setTimeout(() => {
        f7.preloader.hide();
        f7.views.main.router.navigate("/wallets/private");
      }, 3000);
    },
  },
  computed: {
    ...mapState("wallet", [
      "wallet",
      "walletList",
      "publicWalletList",
      "privateWalletList",
    ]),
    ...mapState("tontine", ["tontineList", "tontine"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("account", ["userAccount"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    primaryaccountUrl() {
      return (
        "/my-transactions/" + this.userAccount.standard.id + "/primaryTransactionList"
      );
    },
    businessaccountUrl() {
      if (this.userAccount.business.status !== "ACTIVE") {
        return "/account/business/activate";
      } else {
        return (
          "/my-transactions/" + this.userAccount.business.id + "/businessTransactionList"
        );
      }
    },
    businessColor() {
      if (this.userAccount.business.status !== "ACTIVE") {
        return "color-pink text-color-pink";
      } else {
        return;
      }
    },
  },
};
</script>
