<template>
  <f7-card :padding="false" v-for="(cartItem, index) in this.cart" :key="index">
    <f7-card-header>
      {{ cartItem.item.Title }}
      <span class="text-align-left">{{
        $filtersToCurrency.toCurrency(
          cartItem.item["Variant Price"] * cartItem.item.quantity,
          "€",
          "EUR"
        )
      }}</span>
    </f7-card-header>
    <f7-card-content>
      <f7-list dividers-ios strong-ios media-list>
        <f7-list-item
          :link="itemUrl(cartItem)"
          popup-close
          :title="
            cartItem.item['Option1 Name'] +
            ': ' +
            cartItem.item['Option1 Value'] +
            ' ' +
            cartItem.item['Option2 Name'] +
            ': ' +
            cartItem.item['Option2 Value']
          "
          :after="
            $filtersToCurrency.toCurrency(cartItem.item['Variant Price'], '€', 'EUR')
          "
        >
          <img
            slot="media"
            :src="cartItem.item.ImageSrc || cartItem.item['Variant Image']"
            width="80"
            alt="product image"
          />
          <div accordion-list class="margin-bottom">
            <f7-accordion-item>
              <f7-accordion-toggle>
                <a>
                  <i>{{ $t("shoppingcartcomponent.more") }}</i>
                </a>
              </f7-accordion-toggle>
              <f7-accordion-content>
                <p v-html="cartItem.item['Body (HTML)']"></p>
              </f7-accordion-content>
            </f7-accordion-item>
          </div>
        </f7-list-item>
      </f7-list>
    </f7-card-content>
    <f7-card-footer>
      <f7-link
        @click="deleteFromCart(cartItem)"
        icon-color="red"
        icon-ios="f7:trash"
        icon-aurora="f7:trash"
        icon-md="material:delete"
      ></f7-link>
      <f7-stepper
        fill
        small
        raised
        readonly
        color="auto"
        :value="cartItem.item.quantity"
        :min="0"
        :max="cartItem.item['Variant Inventory Qty']"
        @stepper:plusclick="incrementCartItem(cartItem)"
        @stepper:minusclick="decrementCartItem(cartItem)"
        :step="1"
      ></f7-stepper>
    </f7-card-footer>
  </f7-card>
  <f7-list dividers-ios strong-ios media-list>
    <li v-if="this.cart && this.cart.length > 0">
      <div class="item-content">
        <div class="item-inner">
          <div class="item-title-row">
            <div class="item-title">
              {{ $t("shoppingcartcomponent.totalBasket") }}
            </div>
            <div class="item-title" style="font-weight: bold">
              {{ $filtersToCurrency.toCurrency(this.totalCartAmount, "€", "EUR") }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </f7-list>
</template>
<style scoped>
div.item-media > img {
  width: 50px;
  height: 50px;
  border: none;
}
</style>
<script>
import {
  f7Page,
  f7Card,
  f7CardHeader,
  f7CardContent,
  f7List,
  f7ListItem,
  f7AccordionItem,
  f7AccordionToggle,
  f7CardFooter,
  f7Link,
  f7Stepper,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { shopping } from "../js/mixins/shopping.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [shopping],
  components: {
    f7Page,
    f7Card,
    f7CardHeader,
    f7CardContent,
    f7List,
    f7ListItem,
    f7AccordionItem,
    f7AccordionToggle,
    f7CardFooter,
    f7Link,
    f7Stepper,
  },
  data() {
    return { theme, f7, path: "" };
  },

  mounted() {
    const self = this;
    console.log(f7.views.main.router);
    this.path = f7.views.main.router.url;
    // self.$store.dispatch("card/loadSoldCard");
    // console.log(this.cardList);
  },
  methods: {
    itemUrl(cartItem) {
      const self = this;

      return `/itemDetail/${cartItem.item.storeId}/${cartItem.item.Handle}`;
    },
    async incrementCartItem(cartItem) {
      const self = this;

      await this.addToCart(cartItem.item, 1);
      f7.views.main.router.refreshPage();
    },
    async decrementCartItem(cartItem) {
      const self = this;

      if (f7.stepper.getValue() >= 1) {
        // await f7.dialog.alert(
        //   " stepper value if >1: " + f7.stepper.getValue()
        // );
        await this.removeFromCart(cartItem);
      } else {
        // await f7.dialog.alert(
        //   " stepper value if =1: " + f7.stepper.getValue()
        // );

        await this.deleteItemFromCart(cartItem);
      }
      f7.views.main.router.refreshPage();
    },
    async deleteFromCart(cartItem) {
      const self = this;

      await this.deleteItemFromCart(cartItem);
      f7.views.main.router.refreshPage();
    },
  },

  computed: {
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
  },
};
</script>
