<template>
  <f7-page :hide-bars-on-scroll="true" name="associationDetail" :page-content="false"
    v-if="this.association && this.currentMember">
    <!-- @page:afterout="afterOut()" -->
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ this.association.name }}</f7-nav-title>
      <f7-nav-right v-if="this.$keycloak.authenticated && this.currentMemberIsAdmin">
        <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus" icon-ios="f7:person_crop_circle_fill_badge_plus"
          icon-md="material:person_add" color="red" href="#" popup-open=".addNewMember"></f7-link>
        <f7-link icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"
          :href="'/association/edit/' + this.association.id"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar labels top :scrollable="this.association.associationCampaignList &&
        this.association.associationCampaignList.length > 6
        ? true
        : false
      " no-shadow class="detailToolbar">
      <f7-link tab-link="#mainPage" tab-link-active :text="isTablet ? this.association.name : ''">
        <img v-if="this.association.logo !== null" :src="`data:image/png;base64,${this.association.logo}`" width="40"
          height="40" slot="media" style="border-radius: 50%" class="margin-left-half" />
        <f7-badge v-else color="auto" slot="media" class="margin-left-half" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ $filtersGetInitial.getInitial(association.name) }}</f7-badge>
      </f7-link>
      <f7-link v-for="(campaign, index) in this.myCampaignsList" :key="index + 1" :text="isTablet ? campaign.name : ''"
        :tab-link="`#tab-${campaign.campaignNumber}`" @tab:show="selectCampaign(campaign)">
        <f7-badge slot="media" color="auto" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ $filtersGetInitial.getInitial(campaign.name) }}</f7-badge>
      </f7-link>
    </f7-toolbar>

    <f7-tabs swipeable class="detailTabs">
      <f7-tab id="mainPage" tab-active class="page-content">
        <div class="layout_content">
          <div class="left_layout">
            <div class="sticky with_content">
              <f7-card outline header-divider v-if="this.association &&
                this.$keycloak.authenticated &&
                this.currentMember &&
                association.associationAdminList !== null
                ">
                <f7-card-header>
                  {{ $t("common.members") }}

                  <f7-badge v-if="this.currentMemberIsAdmin" bg-color="red" class="addParticipantToolTip">
                    <f7-link icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                      href="#" popup-open=".addNewMember"></f7-link>
                  </f7-badge>
                </f7-card-header>
                <membersComponent :entityId="this.association.id" :memberList="this.association.associationMemberList"
                  :adminList="this.association.associationAdminList" :entityType="'asso'"
                  :isAdmin="this.currentMemberIsAdmin" :mainUserId="this.association.mainAdminUserId"></membersComponent>
              </f7-card>
            </div>
          </div>
          <div class="main_layout">
            <f7-card v-if="!this.association" class="skeleton-text skeleton-effect-blink">
              <f7-card-header class="no-border">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" color="gray"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">GT</f7-badge>
                </div>
                <div class="tontineTitle demo-facebook-name">Asso name</div>
                <div class="demo-facebook-date tontineTitle">Asso tag</div>
              </f7-card-header>
              <f7-card-content style="width: auto; height: 400px">
                <div class="timeline">
                  <div class="timeline-item" v-for="x in 6" :key="x">
                    <div class="timeline-item-date">2021-11>2022-12</div>
                    <div class="timeline-item-divider"></div>
                    <div class="timeline-item-content">
                      <div class="timeline-item-title">Association name</div>
                      <div class="timeline-item-inner">
                        Collected
                        <span style="font-weight: bold; color: green"> 250 EUR</span><br />Balance
                        <span style="font-weight: bold"> 200 EUR </span>
                      </div>
                    </div>
                  </div>
                </div>
              </f7-card-content>
              <f7-list dividers-ios strong-ios accordion-list media-list>
                <f7-list-item accordion-item :title="$t('common.description')">
                  <f7-accordion-content>
                    <f7-block> </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
              <f7-button fill large style="border-radius: 0px" color="gray" class="buttons">{{ $t("common.seeDetail")
              }}</f7-button>
            </f7-card>
            <f7-card class="demo-facebook-card" v-else>
              <f7-card-header :padding="false" class="no-margin">
                <div class="demo-facebook-avatar padding-right">
                  <img v-if="this.association.logo !== null" :src="`data:image/png;base64,${this.association.logo}`"
                    width="40" height="40" style="border-radius: 50%" />
                  <f7-badge v-else slot="media" color="auto" style="
                      height: 40px;
                      width: 40px;
                      min-width: 40px;
                      font-size: 16px;
                      border-radius: 50%;
                    ">{{ $filtersGetInitial.getInitial(association.name) }}</f7-badge>
                </div>
                <div class="demo-facebook-name">
                  {{ this.association.name }}
                </div>
                <div class="demo-facebook-date" v-html="this.association.tag"></div>
              </f7-card-header>
              <f7-card-content :padding="false" v-if="this.association">
                <f7-photo-browser :photos="this.assoPhotos" ref="popup"></f7-photo-browser>
                <swiper-container v-if="this.assoPhotos" slidesPerView="1" :spaceBetween="0" :disableOnInteraction="true">
                  <swiper-slide class="align-content-center" :style="{
                    background:
                      'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',

                    height: '400px',
                    'overflow-y': 'auto',
                  }">
                    <div class="timeline">
                      <div class="timeline-item">
                        <div class="timeline-item-date">
                          {{ $t("common.total") }}
                        </div>
                        <div class="timeline-item-divider"></div>
                        <div class="timeline-item-content">
                          <div class="timeline-item-title">
                            {{ this.association.name }}
                          </div>
                          <div class="timeline-item-inner">
                            {{ $t("common.collected")
                            }}<span style="font-weight: bold; color: green" v-html="$filtersToCurrency.toCurrency(
    (this.association.collectedAmount || 0).toFixed(2),
    null,
    this.association.currencyCode
  )
  "></span><br />{{ $t("common.balance")
  }}<span style="font-weight: bold" v-html="$filtersToCurrency.toCurrency(
    (this.association.accountBalance || 0).toFixed(2),
    null,
    this.association.currencyCode
  )
  ">
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-for="campaign in this.myCampaignsList" href="#" :key="campaign.id" class="timeline-item"
                        @click="selectCampaign(campaign)">
                        <div class="timeline-item-date" v-if="!!campaign.startDate">
                          <small>{{ campaign.startDate.substr(0, 7) }} >
                            {{ campaign.endDate.substr(0, 7) }}</small>
                        </div>
                        <div class="timeline-item-divider"></div>
                        <div class="timeline-item-content">
                          <div class="timeline-item-text">
                            <f7-badge slot="media" color="auto" width="24" height="24px"
                              style="height: 20px; width: 20px; font-size: 12px">{{ campaign.campaignNumber }}</f7-badge>
                            {{ campaign.name }}
                          </div>
                          <div class="timeline-item-inner">
                            {{ $t("common.collected") }}:
                            <span style="font-weight: bold; color: green" v-html="$filtersToCurrency.toCurrency(
                              (campaign.collectedAmount || 0).toFixed(2),
                              null,
                              campaign.currencyCode
                            )
                              "></span><br />{{ $t("common.balance")
  }}<span style="font-weight: bold" v-html="$filtersToCurrency.toCurrency(
    (campaign.accountBalance || 0).toFixed(2),
    null,
    campaign.currencyCode
  )
  ">
                            </span>
                            <span v-if="!!campaign.campaignBeneficiary" style="font-style: italic"><br />{{
                              $t("common.beneficiary") }}:
                              {{
                                association.associationMemberList.find(
                                  (x) => x.userId === campaign.campaignBeneficiary
                                ).name
                              }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide v-if="this.assoPhotos && this.assoPhotos.length > 0"
                    v-for="(imageUrl, index) in this.assoPhotos" :key="index" style="height: 400px; width: 100%">
                    <a link="#" @click="$refs.popup.open()"
                      style="display: flex; align-items: center; justify-content: center">
                      <img :src="imageUrl.url" style="
                          display: block;
                          margin: auto;
                          position: absolute;
                          top: 0;
                          bottom: 0;
                        " />
                    </a>
                  </swiper-slide>
                </swiper-container>
              </f7-card-content>
              <f7-button v-if="this.association.associationCampaignList &&
                this.association.associationCampaignList.length > 0
                " raised fill ripple large @click="goToContributeNocampaign()" class="contributeButton buttons"
                color="red">{{ $t("common.contribute") }}
              </f7-button>
              <f7-button v-if="this.currentMemberIsAdmin &&
                this.association.associationCampaignList &&
                this.association.associationCampaignList.length == 0
                " raised fill ripple large sheet-open=".addNewCampaign" class="contributeButton buttons" color="red">{{
    $t("associationdetail.addNewProjectcampaign") }}</f7-button>
            </f7-card>

            <f7-list dividers-ios strong-ios accordion-list media-list class="no-margin-top">
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title" style="color: gray">
                      {{ $t("common.collected") }}
                    </div>
                    <div class="item-title" style="color: gray">
                      {{ $t("common.balance") }}
                    </div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title" style="font-weight: bold; color: green">
                      <span v-html="$filtersToCurrency.toCurrency(
                        (this.association.collectedAmount || 0).toFixed(2),
                        null,
                        this.association.currencyCode
                      )
                        "></span>
                    </div>
                    <div class="item-title" style="font-weight: bold; color: auto">
                      <span v-html="$filtersToCurrency.toCurrency(
                        (this.association.accountBalance || 0).toFixed(2),
                        null,
                        this.association.currencyCode
                      )
                        "></span>
                    </div>
                  </div>
                </div>
              </li>

              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block>
                    <p v-html="this.association.description"></p>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <div class="right not_sticky_with_content">
              <campaignRounds :entity="this.association" :entityType="'asso'"></campaignRounds>
            </div>
            <f7-card outline header-divider class="left not_sticky_with_content" v-if="this.association &&
              this.$keycloak.authenticated &&
              this.currentMember &&
              association.associationAdminList !== null
              ">
              <f7-card-header>
                {{ $t("common.members") }}

                <f7-badge v-if="currentMemberIsAdmin" bg-color="red" class="addParticipantToolTip">
                  <f7-link icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                    href="#" popup-open=".addNewMember"></f7-link>
                </f7-badge>
              </f7-card-header>
              <membersComponent :entityId="this.association.id" :memberList="this.association.associationMemberList"
                :adminList="this.association.associationAdminList" :entityType="'asso'"
                :isAdmin="this.currentMemberIsAdmin" :mainUserId="this.association.mainAdminUserId"></membersComponent>
            </f7-card>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <campaignRounds :entity="this.association" :entityType="'asso'"></campaignRounds>
            </div>
          </div>
        </div>
      </f7-tab>

      <f7-tab v-for="(campaign, index) in this.myCampaignsList" :key="index + 1" :id="`tab-${campaign.campaignNumber}`"
        @tab:show="selectCampaign(campaign)" class="page-content">
        <div class="layout_content">
          <div class="left_layout">
            <f7-card outline class="sticky with_content">
              <f7-list outline v-if="association && isAuthenticated && currentMember">
                <f7-list-item :title="$t('common.beneficiary')" smart-select :smart-select-params="{
                  openIn: isTablet ? 'popover' : 'sheet',
                  sheetSwipeToClose: true,
                  closeOnSelect: true,
                }" @smartselect:close="changeCampaignBeneficiary(campaign)">
                  <select name="campainBeneficiary" v-model="campaignBeneficiary">
                    <option v-for="(member, index) in association.associationMemberList" :key="index"
                      :value="member.userId" :selected="campaignBeneficiary === member.userId">
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>

                <f7-list-item :title="$t('associationdetail.manageVisibility')" smart-select :smart-select-params="{
                  openIn: isTablet ? 'popover' : 'sheet',
                  sheetSwipeToClose: true,
                }" @smartselect:close="changeHiddenMember(campaign)">
                  <select name="hideCampaign" multiple v-model="hiddenFrom">
                    <option v-for="(member, index) in association.associationMemberList" :key="index"
                      :value="member.userId" :selected="hiddenFrom.includes(member.userId)">
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>

                <f7-list-item :title="$t('associationdetail.manager', {
                  campaignType: getCampaignType,
                })
                  " smart-select :smart-select-params="{
    openIn: isTablet ? 'popover' : 'sheet',
    sheetSwipeToClose: true,
    closeOnSelect: true,
  }" @smartselect:close="changeCampaignManager(campaign)">
                  <select name="campaignManager" v-model="campaignManager">
                    <option v-for="(member, index) in association.associationMemberList" :key="index"
                      :value="member.userId" :selected="campaignManager === member.userId">
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>
              </f7-list>
            </f7-card>
          </div>
          <div class="main_layout">
            <f7-card class="demo-facebook-card" header-divider :id="`campaingView-${campaign.campaignNumber}`">
              <f7-card-header text-color="auto">
                <div class="demo-facebook-avatar padding-right"
                  style="white-space: normal; word-wrap: break-word; font-weight: 90%">
                  <f7-badge width="50px" height="50px" style="
                      height: 32px;
                      width: 32px;
                      font-size: 16px;
                      background-color: #0009ff;
                      color: white;
                    ">{{ campaign.campaignNumber }}</f7-badge>
                </div>
                <div style="white-space: normal; word-wrap: break-word; font-weight: 90%" class="demo-facebook-name">
                  {{ campaign.name }}
                </div>

                <div class="demo-facebook-date item-label" style="font-size: small">
                  {{
                    $t("common.fromto", {
                      startDate: campaign.startDate.substr(0, 10),
                      endDate: campaign.endDate.substr(0, 10),
                    })
                  }}
                </div>
              </f7-card-header>

              <f7-card-content>
                <div class="grid grid-cols-1 medium-grid-cols-2">
                  <span>
                    <div v-if="campaign.campaignBeneficiary">
                      <small :style="{ opacity: 0.7 }">
                        {{ $t("common.beneficiary") }}:</small>
                      <b><span v-html="association.associationMemberList.find(
                        (x) => x.userId === campaign.campaignBeneficiary
                      ).name
                        "></span></b>
                    </div>
                    <!-- <br /> -->
                    <small :style="{ opacity: 0.7 }">{{ $t("common.amount") }}: </small>
                    <b>
                      <span v-html="$filtersToCurrency.toCurrency(
                        (campaign.campaignAmount || 0).toFixed(2),
                        null,
                        campaign.currencyCode
                      )
                        "></span>
                    </b>
                    <br />

                    <small :style="{ opacity: 0.7 }">{{ $t("common.balance") }}</small>
                    <b>
                      <span v-html="$filtersToCurrency.toCurrency(
                        (campaign.accountBalance || 0).toFixed(2),
                        null,
                        campaign.currencyCode
                      )
                        "></span>
                    </b>
                  </span>
                  <span>
                    <f7-gauge type="circle" :value="parseFloat(campaign.collectedAmount) /
                      parseFloat(campaign.fundingTarget || campaign.collectedAmount)
                      " :valueText="$filtersToCurrency.toCurrency(
    (campaign.collectedAmount || 0).toFixed(2),
    null,
    campaign.currencyCode
  )
    " valueTextColor="auto" border-color="green" labelText="collected" labelTextColor="#4caf50"
                      :labelFontWeight="600" :labelFontSize="12" :borderWidth="30" bg-color="white" />
                  </span>
                </div>
              </f7-card-content>
              <transactionCharts v-if="campaign.cpTransactionList.length > 0"></transactionCharts>
              <div class="grid grid-cols-2" v-if="campaign.collectedAmount >= campaign.campaignAmount &&
                currentMemberIsAdmin
                ">
                <f7-button raised fill large ripple
                  class="contributeButton accordion-item-contentmargin-horiztontal-half margin-top buttons"
                  @click="goToContribute(campaign)" color="red">{{ $t("common.contribute") }}</f7-button>

                <f7-button raised fill large ripple class="contributeButton margin-horiztontal-half margin-top buttons"
                  color="red" @click="redeemCampaign(campaign)">{{ $t("associationdetail.redeem") }}</f7-button>
              </div>
              <f7-button v-else raised fill ripple large class="contributeButton buttons"
                @click="goToContribute(campaign)" color="red">{{ $t("common.contribute") }}</f7-button>
            </f7-card>
            <f7-list accordion-list>
              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block>
                    <p v-html="campaign.description"></p>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <f7-card outline class="left not_sticky_with_content">
              <f7-list outline v-if="association && isAuthenticated && currentMember">
                <f7-list-item :title="$t('common.beneficiary')" smart-select :smart-select-params="{
                  openIn: isTablet ? 'popover' : 'sheet',
                  sheetSwipeToClose: true,
                  closeOnSelect: true,
                }" @smartselect:close="changeCampaignBeneficiary(campaign)">
                  <select name="campainBeneficiary" v-model="campaignBeneficiary">
                    <option v-for="(member, index) in association.associationMemberList" :key="index"
                      :value="member.userId" :selected="member.userId === campaign.campaignBeneficiary">
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>

                <f7-list-item :title="$t('associationdetail.manageVisibility')" smart-select :smart-select-params="{
                  openIn: isTablet ? 'popover' : 'sheet',
                  sheetSwipeToClose: true,
                }" @smartselect:close="changeHiddenMember(campaign)">
                  <select name="hideCampaign" multiple v-model="hiddenFrom">
                    <option v-for="(member, index) in association.associationMemberList" :key="index"
                      :value="member.userId" :selected="hiddenFrom.includes(member.userId)">
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>

                <f7-list-item :title="$t('associationdetail.manager', {
                  campaignType: getCampaignType,
                })
                  " smart-select :smart-select-params="{
    openIn: isTablet ? 'popover' : 'sheet',
    sheetSwipeToClose: true,
    closeOnSelect: true,
  }" @smartselect:close="changeCampaignManager(campaign)">
                  <select name="campaignManager" v-model="campaignManager">
                    <option v-for="(member, index) in association.associationMemberList" :key="index"
                      :value="member.userId" :selected="member.userId === campaign.campaignManager">
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>
              </f7-list>
            </f7-card>
            <div class="left not_sticky_with_content">
              <getTransactions :transactionList="campaign.cpTransactionList" v-if="campaign.cpTransactionList.length > 0">
              </getTransactions>
            </div>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <getTransactions :transactionList="campaign.cpTransactionList" v-if="campaign.cpTransactionList.length > 0">
              </getTransactions>
            </div>
          </div>
        </div>
      </f7-tab>
    </f7-tabs>

    <addNewCampaign></addNewCampaign>

    <template #fixed>
      <f7-fab v-if="this.association && this.currentMemberIsAdmin" position="center-bottom" :text="$t('associationdetail.addNewCampaignproject', {
        campaignType: this.getCampaignType,
      })
        " color="red" @click="addNewCampaignPopup()">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
      <f7-fab position="left-bottom" color="auto" @click="goToMessageGroup()">
        <f7-icon ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="material:chat"></f7-icon>
      </f7-fab>
      <f7-fab position="right-bottom" color="auto" class="shareButton" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>

<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7List,
  f7Link,
  f7Tabs,
  f7Tab,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7PhotoBrowser,
  f7Button,
  f7AccordionContent,
  f7AccordionItem,
  f7Block,
  f7Gauge,
  f7Input,
  f7ListInput,
  f7Popup,
  f7ListGroup,
  f7BlockTitle,
  f7PageContent,
  f7Toolbar,
  f7Chip,
  f7TextEditor,
  f7Fab,
  f7Icon,
  f7ListItem,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import addNewCampaign from "../components/associationAddCampaign.vue";
import membersComponent from "../components/modules/members.vue";
import campaignRounds from "../components/modules/campaignRounds.vue";
import getTransactions from "../components/modules/transactions.vue";
import transactionCharts from "../components/modules/charts.vue";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import UsersPage from "./users.vue";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { association } from "../js/mixins/association.js";
import { contributeRedeem } from "../js/mixins/contributeRedeem.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    userslist: UsersPage,
    addNewCampaign,
    getTransactions,
    transactionCharts,
    membersComponent,
    campaignRounds,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7List,
    f7Link,
    f7Tabs,
    f7Tab,
    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,

    f7PhotoBrowser,

    f7Button,
    f7AccordionContent,
    f7AccordionItem,
    f7Block,

    f7Gauge,
    f7Input,
    f7ListInput,
    f7Popup,
    f7ListGroup,
    f7BlockTitle,
    f7PageContent,
    f7Toolbar,
    f7Chip,
    f7TextEditor,
    f7Fab,
    f7Icon,
    f7ListItem,
  },
  mixins: [calculateTransferPayment, association, socialShare, contributeRedeem],
  data() {
    return {
      theme,
      f7,
      title: "",
      description: "",
      fundingTarget: "",
      campaignAmount: "",
      campaignPeriod: [],
      campaignManager: "",
      cardNumber: "",
      transferCode: "",
      transferPIN: "",
      nocardNumberOrPINError: "",
      currentCampaign: "",
      sharedContent: "",
      hideOrShowcampaign: "",
      isVisible: false,
      campaignBeneficiary: "",
      hiddenFrom: [],
      sharedContent: "",
      fileToSave: [],
      type: "association",
      amount: "",
      setAmount: null,
      paymentCurrency: "",
      walletCampaignBeneficiary: "",
      project_type: "",
      isAdmin: "",
      isMember: "",
      trxType: "",
      referenceId: "",
      receiver_wallet_id: "",
      sender_wallet_id: "",
      tag: "",
      return_url: "",
      return_url_fail: "",
      countryCode: "",
      project: "",
      entity: "",
    };
  },

  mounted() {
    this.hideOrShowcampaign = this.$t("associationdetail.hideCampaignFromBeneficiary");
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === null) {
      this.$store.dispatch("location/getCountry");
    }
    if (!this.association) {
      this.$store.dispatch("asso/getAssociation", this.f7route.params.entityId);
    }
  },
  methods: {
    goToContributeNocampaign() {
      const self = this;

      if (
        this.association.associationCampaignList &&
        this.association.associationCampaignList.length == 1
      ) {
        this.goToContribute(this.association.associationCampaignList[0]);
        return;
      }
      const butt = [];
      const label = {
        text: self.$t("associationdetail.selectCampaignToContribute", {
          campaignType: this.getCampaignType.toLowerCase(),
        }),
        color: "auto",
        bold: true,
      };

      butt.push(label);
      for (const campaign of this.association.associationCampaignList) {
        butt.push({
          text:
            ' <span class="badge color-auto">' +
            campaign.campaignNumber +
            "</span>" +
            " " +
            `${campaign.name}`,
          onClick() {
            self.goToContribute(campaign);
          },
        });
      }
      if (!self.contribToPopover) {
        self.contribToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            butt,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(".contributeButton"),
        });
      }
      self.contribToPopover.open();
    },
    goToContribute(campaign) {
      this.project_type = "contribute";
      this.preparePayment(campaign);
      setTimeout(() => {
        this.contribute();
      }, 500);
    },
    redeemCampaign(campaign) {
      this.project_type = "redeem";
      this.preparePayment(campaign);
      setTimeout(() => {
        this.redeem();
      }, 500);
    },
    async goToMessageGroup() {
      f7.preloader.show("multi");
      const chatId =
        this.association.chatGroupId === ""
          ? this.association.id
          : this.association.chatGroupId;
      setTimeout(() => {
        this.f7router.navigate(`/message/${chatId}/GROUP`, {
          reloadAll: true,
        });
        f7.preloader.hide();
      }, 2000);
    },
    async topUpPopupOpen() {
      await this.$store.dispatch("transaction/resetPaymentDetail");
      await this.$store.dispatch("transaction/resetPaymentReverseDetail");
      await this.$store.dispatch("card/loadCarditemlist");
      await this.amountToPayChange();
    },

    updateAssociation() {
      this.$store.dispatch("asso/getAssociationById", this.association.id);
    },

    addNewCampaignPopup() {
      f7.sheet.open(".addNewCampaign");
      this.camapaignNumber =
        parseFloat(this.association.associationCampaignList.length) + 1;
      this.campaignAmount = "";
    },

    openMemberList(campaign) {
      const self = this;
      this.currentCampaign = campaign;
      const members = [];
      const label = {
        text: self.$t("associationdetail.selectCampaignManager"),
        color: "auto",
        bold: true,
      };

      members.push(label);
      for (const member of this.association.associationMemberList) {
        members.push({
          text: `${member.name}<br>${member.phoneNumber}<br>${member.email}`,
          bold: true,
          onClick() {
            self.changeCampaignManager(member);
          },
        });
      }
      if (!self.changeCampaignManagerToPopover) {
        self.changeCampaignManagerToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            members,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(`.selectChangeCampainManager${campaign.id}`),
        });
      }
      self.changeCampaignManagerToPopover.open();
    },

    async changeCampaignManager(campaign) {
      if (this.campaignManager !== campaign.campaignManager) {
        await this.$store.dispatch("asso/addCampaignManager", {
          id: campaign.id,
          campaignManager: this.campaignManager,
        });
      }
    },
    async changeCampaignBeneficiary(campaign) {
      console.log("this.campaignBeneficiary ", this.campaignBeneficiary);
      if (this.campaignBeneficiary !== campaign.campaignBeneficiary) {
        await this.$store.dispatch("asso/changeCampaignBeneficiary", {
          id: campaign.id,
          campaignBeneficiary: this.campaignBeneficiary,
        });
      }
    },
    changeHiddenMember(campaign) {
      console.log(this.hiddenFrom);
      if (!_.isEqual(campaign.hiddenFrom, this.hiddenFrom)) {
        this.$store.dispatch("asso/changeHiddenMember", {
          id: campaign.id,
          hiddenFrom: this.hiddenFrom,
        });
      }
    },

    getCampaignManager(value) {
      const member = _.find(this.association.associationMemberList, ["userId", value]);
      if (member) {
        return member.name;
      } else {
        return;
      }
    },
  },

  computed: {
    ...mapState("asso", [
      "association",
      "campaign",
      "member",
      "associationList",
      "memberList",
      "associationTransaction",
      "associationTransactionList",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("card", ["carditemlist", "cardbasket", "country", "queryerror"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "dynamicLink",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
    ]),
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "standard",
      "business",
      "accountList",
      "transactionList",
      "apiWallet",
      "structuredTransactionList",
    ]),

    ...mapGetters("account", ["userByAccountNumber"]),
  },
};
</script>
