<template>
  <f7-page>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{
        $t("sendcash.validateTransferTo", {
          myrecipient: this.extraTransferDetail.name,
        })
      }}</f7-nav-title>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios media-list form>
          <f7-list-group>
            <f7-list-item :title="$t('sendcash.recipientDetail')" divider></f7-list-item>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.extraTransferDetail.name }}
                  </div>
                  <div v-if="this.extraTransferDetail.pickupMethod === 'top-up'" class="item-title"
                    style="font-weight: bold">
                    {{ this.extraTransferDetail.recipientAccountType }}:
                    {{ this.extraTransferDetail.recipientAccountNumber }}
                  </div>
                  <div v-else class="item-title" style="font-weight: bold">
                    +{{ this.extraTransferDetail.phoneCode
                    }}{{ this.extraTransferDetail.phone }}
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 75%; color: gray">
                  <div class="item-title">
                    {{ extraTransferDetail.countryName }}
                  </div>
                  <div class="item-title">
                    {{ extraTransferDetail.cityName }}
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.destinationCountry") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.pickupMode") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ extraTransferDetail.destinationctry }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    <span class="avocash">avo¢ash</span>
                    {{ transferDetail.pickupMethod }}
                  </div>
                </div>
              </div>
            </li>
            <f7-list-item :title="$t('sendcash.transferDetail')" divider></f7-list-item>
            <li class="item-content" v-if="transferDetail.receivedAmount !== null">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.youSend") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.recipientGets") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.grossTransferAmount,
                      transferDetail.fromCurrencySymbol,
                      transferDetail.fromCurrencyCode
                    )
                      "></span>
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.receivedAmount,
                      transferDetail.toCurrencySymbol,
                      transferDetail.toCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content" v-if="transferDetail.receivedAmount !== null">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.fee") }}</div>
                  <div class="item-title">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.transferFeeAmount,
                      transferDetail.fromCurrencySymbol,
                      transferDetail.fromCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.xrate") }}</div>
                  <div class="item-title float-left">
                    1{{ transferDetail.fromCurrencyCode }}={{
                      $filtersToCurrency.toCurrency(
                        transferDetail.netExchangeRate,
                        transferDetail.toCurrencySymbol,
                        transferDetail.toCurrencyCode
                      )
                    }}<i>{{ transferDetail.toCurrencyName }}</i>
                  </div>
                </div>
              </div>
            </li>
          </f7-list-group>
          <f7-list-item v-if="this.transferDetail.pickupMethod === 'top-up'">
            <f7-toggle class="forPurpose" checked color="auto" @change="forPurposeChange()"></f7-toggle>
            <span>{{ this.withPurpose }}</span>
          </f7-list-item>
          <f7-list-group v-if="this.transferDetail.pickupMethod === 'top-up' && this.addPurpose == true
            ">
            <f7-list-item smart-select :smart-select-params="{
              closeOnSelect: true,
              openIn: 'popup',
              popupSwipeToClose: true,
              popupPush: true,
              searchbar: true,
              searchbarPlaceholder: $t('forpurposetransfer.findApprover'),
            }" class="selectPaymentApprover" :title="$t('forpurposetransfer.selectPaymentApprover')">
              <select name="recipient" v-model="validatorRecipientId">
                <optgroup v-for="(allRecipient, countryName, index) in this.recipientsList" :key="index"
                  :label="countryName">
                  <option v-for="(recipient, index) in allRecipient" :value="recipient.id" :key="index">
                    {{ recipient.name }}
                    <br />
                    +{{ recipient.phoneCode + "" + recipient.phone }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>
            <f7-block-title divider class="margin-bottom-half">{{
              $t("forpurposetransfer.fillInBelow")
            }}</f7-block-title>

            <f7-list-input :label="$t('forpurposetransfer.paymentReference')" class="no-padding-left"
              :placeholder="$t('forpurposetransfer.paymentReference')" floating-label type="text" :value="this.paymentRef"
              @input="paymentRef = $event.target.value" clear-button></f7-list-input>
            <f7-block-title class="margin-top-half margin-left item-footer">{{
              $t("validatetransfer.otherPaymentDetails")
            }}</f7-block-title>
            <f7-list-input :label="$t('common.description')" floating-label :value="senderComment"
              @texteditor:change="(v) => (senderComment = v)" resizable required validate type="texteditor" :style="f7.device.cordova || !this.isTablet
                ? '--f7-text-editor-height: 150px'
                : ''
                " />

            <myDocs :type="this.type"> </myDocs>
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom bg-color="red">
          <f7-link @click="gettopupfromnewcards()" v-if="this.transferDetail.pickupMethod === 'top-up' &&
            !this.extraTransferDetail.senderAccountNumber
            " raised large ripple fill class="link" strong text-color="white">{{ $t("sendcash.topupNow") }}</f7-link>
          <f7-link @click="gettopupfrombalance()" v-else-if="this.transferDetail.pickupMethod === 'top-up' &&
            this.extraTransferDetail.senderAccountNumber !== null
            " raised large ripple fill class="link" strong text-color="white">{{ $t("sendcash.topupNow") }}</f7-link>
          <f7-link @click="getpickupfromnewcards()" v-else-if="this.transferDetail.pickupMethod === 'pick-up' &&
            !this.extraTransferDetail.senderAccountNumber
            " raised large ripple fill class="link" strong text-color="white">{{ $t("sendcash.transferNow")
  }}</f7-link>
          <f7-link @click="getpickupfrombalance()" v-else raised large class="link" fill strong text-color="white">{{
            $t("sendcash.transferNow") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7NavRight,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Toggle,
  f7BlockTitle,
  f7ListInput,
  f7Toolbar,
  f7Link,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import attachDocs from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import { selectedRecipient, recipient } from "../js/mixins/recipient.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [selectedRecipient, recipient],
  components: {
    attachDocs,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7NavRight,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7Toggle,
    f7BlockTitle,
    f7ListInput,
    f7Toolbar,
    f7Link,
  },
  data() {
    return {
      theme,
      f7,
      pickupcity: "",
      totransferid: "",
      tocurrencycode: "",
      query: "",
      grossXrate: "",
      recipientId: "",
      validatorRecipientId: "",
      countryCode: "",
      senderComment: "",
      invoiceFile: "",
      accountNumber: "",
      paymentRef: "",
      forms: "",
      docIndex: "",
      type: "transfer",
      recipientAccountNumber: "",
      withPurpose: this.$t("forpurposetransfer.withPurpose"),
      addPurpose: true,
    };
  },
  mounted() {
    const self = this;

    self.$store.dispatch("location/getCurrentUserCountry", this.profile.countryCode);
    this.recipientId = this.f7route.params.id || this.extraTransferDetail.recipientId;
    f7.$(".selectPaymentApprover .smart-select .item-after").html(
      this.$t("forpurposetransfer.findApprover")
    );
  },
  methods: {
    forPurposeChange() {
      const self = this;

      const toggle = f7.toggle.get(".forPurpose");
      if (toggle.checked) {
        this.withPurpose = this.$t("forpurposetransfer.withPurpose");
        this.addPurpose = true;
      } else {
        this.withPurpose = this.$t("forpurposetransfer.notForPurpose");
        this.addPurpose = false;
      }
    },

    async gettopupfromnewcards() {
      const self = this;

      f7.preloader.show("multi");
      this.forms = new FormData();
      for (const element of this.documentsUrl) {
        this.forms.append("files", element.file);
      }
      await self.$store.dispatch("remit/getTopupFromNewCards", {
        recipientId: this.extraTransferDetail.recipientId,
        id: this.transferDetail.id,
        recipientAccountNumber: this.extraTransferDetail.recipientAccountNumber,
        recipientAccountType: this.extraTransferDetail.recipientAccountType,
        amount: this.transferDetail.grossTransferAmount,
        paymentCurrency: this.transferDetail.fromCurrencyCode,
        validatorRecipientId: this.validatorRecipientId,
        senderComment: this.senderComment,
        paymentRef: this.paymentRef,
        forms: this.forms,
      });
      await self.$store.dispatch("account/loadUser", {
        userId: this.recipient.userRecipientId,
        type: "userId",
      });
      setTimeout(() => {
        this.f7router.navigate("/transfer/validated", {
          ignoreCache: true,
          reloadCurrent: true,
        });
        self.$store.dispatch("auth/setDocumentsUrl", "");
        f7.preloader.hide();
      }, 300);
    },
    async gettopupfrombalance() {
      const self = this;

      f7.preloader.show("multi");
      this.forms = new FormData();

      for (const element of this.documentsUrl) {
        this.forms.append("files", element.file);
      }

      await self.$store.dispatch("remit/getTopupFromBalance", {
        recipientId: this.extraTransferDetail.recipientId,
        senderAccountNumber: this.extraTransferDetail.senderAccountNumber,
        senderAccountType: this.extraTransferDetail.senderAccountType,
        recipientAccountNumber: this.extraTransferDetail.recipientAccountNumber,
        recipientAccountType: this.extraTransferDetail.recipientAccountType,
        amount: this.transferDetail.grossTransferAmount,
        paymentCurrency: this.transferDetail.fromCurrencyCode,
        validatorRecipientId: this.validatorRecipientId,
        senderComment: this.senderComment,
        paymentRef: this.paymentRef,
        forms: this.forms,
      });
      await self.$store.dispatch("account/loadUser", {
        userId: this.recipient.userRecipientId,
        type: "userId",
      });
      setTimeout(() => {
        this.f7router.navigate("/transfer/validated", {
          ignoreCache: true,
          reloadCurrent: true,
        });
        f7.preloader.hide();
      }, 300);
    },
    async getpickupfromnewcards() {
      const self = this;

      f7.preloader.show("multi");
      await self.$store.dispatch("remit/getPickupFromNewCards", {
        recipientId: this.extraTransferDetail.recipientId,
        id: this.transferDetail.id,
      });
      setTimeout(() => {
        this.f7router.navigate("/transfer/validated", {
          ignoreCache: true,
          reloadCurrent: true,
        });
        f7.preloader.hide();
      }, 3000);
    },
    async getpickupfrombalance() {
      const self = this;

      f7.preloader.show("multi");
      await self.$store.dispatch("remit/getPickupFromBalance", {
        recipientId: this.extraTransferDetail.recipientId,
        amount: this.transferDetail.amount || this.transferDetail.grossTransferAmount,
        senderAccountNumber: this.extraTransferDetail.senderAccountNumber,
        senderAccountType: this.extraTransferDetail.senderAccountType,
      });
      setTimeout(() => {
        this.f7router.navigate("/transfer/validated", {
          ignoreCache: true,
          reloadCurrent: true,
        });
        f7.preloader.hide();
      }, 300);
    },
  },

  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userrecipientList",
      "recipient",
    ]),

    ...mapState("account", ["userDetail", "userAccount"]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),

    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "documentsUrl", "document"]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "extraTransferDetail",
      "grossexchangerate",
      "transferDetail",
      "extraTransferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
    ]),

    ...mapGetters("remit", ["recipients", "recipientsList"]),
  },
};
</script>
