<template>
  <f7-popup
    :opened="!this.onboardingFinished"
    class="onboardingPage"
    @popup:close="navigateHome()"
    :close-by-backdrop-click="false"
    :close-by-outside-click="false"
    tablet-fullscreen
    :backdrop="false"
    ><f7-view>
      <f7-page>
        <f7-navbar
          no-shadow
          no-border
          no-outline
          transparent
          style="background: transparent"
        >
          <f7-nav-left class="margin-left-half"
            ><img
              src="/32x32.png"
              alt="avocash logo"
              width="32"
              height="32"
              style="vertical-align: middle"
            />
            <span
              class="avocash text-color-white margin-left-half"
              style="
                letter-spacing: var(--f7-tabbar-label-letter-spacing);
                font-weight: bold;
                font-size: large;
              "
              >avo¢ash</span
            ></f7-nav-left
          >
          <f7-nav-right>
            <span
              class="tab-link margin-right-half"
              data-route-tab-id="#"
              style="justify-content: center"
              v-if="!this.authenticated && this.isTablet"
            >
              <f7-button
                small
                round-md
                outline
                round
                raised
                class="registerButton signUpSignIn"
                color="auto"
                @click="registerFn()"
                >{{ $t("common.register") }}</f7-button
              >
            </span>
            <span
              class="tab-link"
              data-route-tab-id="#"
              style="justify-content: center"
              v-if="!this.authenticated && this.isTablet"
            >
              <f7-button
                small
                outline
                round
                round-md
                raised
                color="red"
                class="loginButton signUpSignIn"
                @click="loginFn()"
                >{{ $t("common.login") }}</f7-button
              ></span
            >
            <span v-if="!this.authenticated && !this.isTablet">
              <navbarright></navbarright>
            </span>
            <f7-link transition="f7-cover" popup-open=".onbordindDetail">
              <f7-icon ios="f7:bars" md="material:menu" color="white"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <swiper-container
          class="onboardingSlides"
          :pagination="{ enabled: true, clickable: true }"
          direction="vertical"
          :slidesPerView="1"
          :mousewheel="true"
          :keyboard="true"
          :centeredSlides="true"
          :speed="2000"
          :scrollbar="{ draggable: true, snapOnRelease: true }"
          effect="slide"
          @slidechange="onSlideChange"
        >
          <swiper-slide
            ref="targetRef0"
            class="firstSlide innerSwiper scrollView"
            :class="this.isTablet ? 'bg-color-primary' : 'bg-color-' + this.sliderColor"
          >
            <div
              class="onboarding-content1 grid grid-gap xsmall-grid-cols-1 small-grid-cols-1 medium-grid-cols-1 grid-cols-1 large-cols65_30"
            >
              <f7-block
                class="onboarding_title fullScreen margin-top onboarding_content"
                style="display: grid; place-items: center"
                v-if="this.isTablet"
              >
                <h1
                  class="text-color-white headline no-margin-bottom margin-horizontal-half"
                >
                  <span
                    class="headline0 no-margin-bottom"
                    v-html="$t('landingPage.mainMessage1')"
                  ></span>

                  <span
                    class="headline1 no-margin-top"
                    v-html="$t('landingPage.mainMessage2')"
                  ></span>
                  <span
                    class="headline3 no-margin-top"
                    v-html="$t('landingPage.mainMessage3')"
                  ></span>
                </h1>
              </f7-block>
              <span>
                <swiper-container
                  :autoplay="{
                    delay: 5000,
                    disableOnInteraction: false,
                  }"
                  :loop="true"
                  :effect="this.isTablet ? 'slide' : 'slide'"
                  crossFade="true"
                  class="firstSwiper"
                  :speed="5000"
                  direction="horizontal"
                  :slides-per-view="1"
                  :pagination="
                    this.isTablet
                      ? false
                      : {
                          type: 'progressbar',
                        }
                  "
                  @slidechange="onInnerSlideChange"
                >
                  <swiper-slide
                    class="bg-color-primary main-innerSwiper"
                    v-if="f7.width <= 768"
                    data-color="#0009ff"
                  >
                    <div class="innerSwiper padding-horizontal">
                      <h1
                        class="text-color-white headline no-margin-bottom margin-top padding-horizontal"
                        style="top: 30%; position: absolute"
                      >
                        <span
                          class="margin-left-half"
                          v-html="$t('landingPage.mainMessage1')"
                        ></span>

                        <span v-html="$t('landingPage.mainMessage2')"></span>
                      </h1>
                      <div></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide
                    v-for="(product, index) in product_first"
                    :key="index"
                    class="innerSwiper"
                    :class="!this.isTablet ? 'bg-color-' + product.color : 'primary'"
                    :data-color="!this.isTablet ? product.color_hex : '#0009ff'"
                  >
                    <div
                      class="innerSwiper align-content-center content-grid"
                      style="top: 20%"
                    >
                      <h1
                        class="text-color-white no-margin-bottom align-content-center padding-horizontal"
                        :style="{
                          'font-size': 'x-large',

                          'padding-top': '42px',
                        }"
                        v-html="product.title"
                      ></h1>
                      <div
                        class="onboarding-content-image margin-top padding-top"
                        :style="{ bottom: this.isTablet ? '16px' : '0px' }"
                      >
                        <div class="wt-hero-phone">
                          <div class="wt-phone">
                            <div class="wt-phone-case" style=""></div>
                            <div class="wt-phone-screen">
                              <div class="wt-phone-screen-img">
                                <img :src="product.img" alt="product image" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper-container>
              </span>
            </div>
          </swiper-slide>
          <swiper-slide
            v-for="(product, index) in products"
            :key="index"
            :class="'scrollView bg-color-' + product.color"
            :id="'videoSlide' + index"
            :data-color="product.color_hex"
          >
            <div
              class="innerSwiper onboarding-content swiper-content grid grid-gap medium-grid-cols-1 grid-cols-1 large-cols65_30"
            >
              <div class="title_content text-align-center align-content-center">
                <span class="text-color-white animation onboarding_title">
                  <h1
                    :class="'slideTitle slideTitleaos_' + index"
                    v-html="product.title"
                  ></h1>
                </span>
                <span
                  class="display-flex margin-vertical-half"
                  style="justify-content: space-evenly"
                >
                  <f7-link
                    large
                    style="
                      box-shadow: none;
                      font-style: italic;
                      width: fit-content;
                      justify-content: right;
                    "
                    color="white"
                    @click="learnMore(product)"
                  >
                    {{ $t("landingPage.getLearnMore") }}
                  </f7-link>
                  <f7-button
                    large
                    outline
                    button-ios
                    round
                    border-color="white"
                    color="white"
                    :style="{
                      'border-radius': theme.md ? '25px' : '',
                      'border-width': theme.md ? '4px' : '',
                      width: 'fit-content',
                      'justify-content': 'left',
                    }"
                    @click="closePopup()"
                  >
                    {{ $t("landingPage.getStarted") }}
                  </f7-button>
                </span>
              </div>
              <div class="wt-hero-phone">
                <div class="wt-phone">
                  <div class="wt-phone-case" style=""></div>
                  <div class="wt-phone-screen">
                    <div class="wt-phone-screen-video">
                      <video
                        preload="auto"
                        loop=""
                        muted=""
                        playsinline=""
                        :class="`video${index + 1}`"
                      >
                        <source :src="product.video" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>

        <template #fixed>
          <f7-fab
            v-if="this.lastSwiperIsActive"
            :position="this.isTablet ? 'right-bottom' : 'right-bottom'"
            color="red"
            class="goToNextButton"
            @click="closePopup()"
            :text="
              this.isTablet ? $t('landingPage.getStarted') : $t('landingPage.getStarted')
            "
          >
            <f7-icon ios="f7:arrow_right" md="material:arrow_forward"></f7-icon>
          </f7-fab>
          <f7-fab
            position="right-bottom"
            :color="!this.isTablet ? 'primary' : 'red'"
            class="goToNextButton"
            v-else
            @click="goToNextSlide()"
            :text="
              this.isTablet && this.swiperIndex < 1 ? $t('landingPage.getLearnMore') : ''
            "
          >
            <f7-icon
              :ios="this.isTablet ? 'f7:arrow_down' : 'f7:arrow_down'"
              :md="this.isTablet ? 'material:arrow_downward' : 'material:arrow_downward'"
            ></f7-icon>
          </f7-fab>
        </template>
      </f7-page>
    </f7-view>
  </f7-popup>
  <f7-popup
    :close-by-backdrop-click="false"
    :close-by-outside-click="false"
    tablet-fullscreen
    class="prodDetails"
    :backdrop="false"
  >
    <f7-page style="background-color: white" hide-bars-on-scroll>
      <f7-navbar no-shadow no-border no-outline>
        <f7-nav-left> </f7-nav-left
        ><f7-nav-title> {{ this.selectedProduct.title }}</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close=".prodDetails">
            <f7-icon ios="f7:multiply" md="material:close" color="primary"></f7-icon>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout margin">
          <h1>
            {{ this.selectedProduct.title }}
          </h1>
          <h2>
            {{ this.selectedProduct.subtitle }}
          </h2>
          <h3>
            <p>{{ this.selectedProduct.detail }}</p>
          </h3>
          <div class="card-content-padding onboarding-content-image">
            <div class="wt-hero-phone">
              <div class="wt-phone">
                <div class="wt-phone-case" style=""></div>
                <div class="wt-phone-screen">
                  <div class="wt-phone-screen-img">
                    <img :src="this.selectedProduct.img" alt="product image" />
                  </div>
                </div>
              </div>
            </div>
            <div class="wt-hero-phone margin-top">
              <div class="wt-phone">
                <div class="wt-phone-case" style=""></div>
                <div class="wt-phone-screen">
                  <div class="wt-phone-screen-video">
                    <video
                      preload="auto"
                      loop=""
                      muted=""
                      playsinline=""
                      style=""
                      :class="`video${this.selectedProduct.id}`"
                    >
                      <source :src="this.selectedProduct.video" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_layout">
          <div class="sticky with_content" v-if="this.isTablet"></div>
        </div>
      </div>
    </f7-page>
  </f7-popup>
  <f7-popup
    :close-by-backdrop-click="false"
    :close-by-outside-click="false"
    tablet-fullscreen
    class="onbordindDetail"
  >
    <onbordindDetail />
  </f7-popup>
</template>

<style scoped>
swiper-slide,
swiper-container {
  width: 100%;
  height: 100vh !important;
}

.signUpSignIn {
  border-radius: 32px;
  border-width: medium;
  background-color: white;
}

.swiper-pagination.swiper-pagination-progressbar.swiper-pagination-horizontal {
  height: 6px;
  background: lightgrey;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: white;
  height: 6px;
}

@media screen and (max-width: 568px) {
  .onboarding-content-image {
    /* width: 70%; */
    height: -webkit-fill-available;
    /* display: flex; */
    margin: auto;
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    text-align: center;
  }

  .onboarding-content {
    margin: auto;
    justify-content: center;
    text-align: center;
  }
}

@media screen and (min-width: 569px) {
  .onboarding-content-image {
    height: -webkit-fill-available;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.onboarding-content1 {
  height: 100%;
}

.onboarding-content {
  /* justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto; */
  height: 100%;
}

.onboarding-content-swiper {
  width: 70%;
  margin: auto;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
}

.firstSlide {
  /* background-image: url(/static/images/avocash_bg2.png); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  text-align: center;
}

.onboarding_title,
.slideTitle,
.slideSubtitle {
  text-align: center;
  /* padding-top: 40px; */
}

.headline,
.slideTitle {
  font-stretch: 150%;
  letter-spacing: -0.02em;
  font-size: clamp(1.5875rem, 1.2329545455rem + 1.4727272727vw, 1.6875rem);
  letter-spacing: -0.04;
  font-weight: 600;
  margin-bottom: 0.4em;
}

@media screen and (min-width: 1200px) {
  .headline,
  .slideTitle {
    font-weight: 520;
    font-stretch: 150%;
    letter-spacing: -0.02em;
    font-size: clamp(4.1875rem, 0.1875rem + 6.25vw, 6.75rem);
    line-height: 1.05;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-innerSwiper {
    display: none;
  }

  .onboarding_content {
    padding-top: calc(
      var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) +
        var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) +
        var(--f7-page-content-extra-padding-top, 0px)
    );
  }
}

@media screen and (min-width: 1024px) {
  .headline,
  .slideTitle {
    font-weight: 520;
    font-stretch: 150%;
    letter-spacing: -0.02em;
    font-size: clamp(4.1875rem, 0.1875rem + 6.25vw, 6.75rem);
    line-height: 1.05;
  }

  .large-cols65_30 {
    display: grid;
    grid-template-columns: 65% 30%;
  }
}

@media screen and (min-width: 768px) {
  .headline,
  .slideTitle {
    font-weight: 520;
    font-stretch: 150%;
    letter-spacing: -0.02em;
    font-size: clamp(2.1875rem, 0.1875rem + 4.25vw, 4.75rem);
    line-height: 1.25;
  }

  .onboarding_title {
    margin-left: 16px;
  }

  .headline0 {
    display: block;
  }
}

@media screen and (min-width: 568px) {
  .onboarding-content-image .slideSubtitle {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .headline0,
  .headline1 {
    display: none;
  }

  /* .onboarding-content, */
  /* .onboarding_content {
    margin-top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));
  } */
}

@media screen and (min-width: 481px) {
  .headline0,
  .headline1 {
    display: block;
  }

  .headline3 {
    display: none;
  }
}

.wt-phone {
  position: relative;
  display: inline-block;
  contain: layout style;
}

.wt-phone-shadow {
  position: absolute;
  top: -8.577%;
  left: -41.289%;
  right: -14.68%;
  bottom: -11.483%;
  opacity: 0.8;
  background-image: url(/phone-shadow.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  /* background-repeat:no-repeat; */
}

.wt-phone-case {
  position: relative;
  width: 212.55px;
  height: 447.2px;
  background-image: url(/phone.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

.onboarding-content-image {
  height: 500px;
  bottom: 16px;
}

@media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
  (min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),
  (min-width: 768px) and (min-resolution: 192dpi) {
  .wt-phone-case {
    background-image: url(/phone@2x.png);
  }
}

.wt-phone-screen {
  position: absolute;
  top: 8.45px;
  left: 6.5px;
  width: 199.55px;
  height: 430.3px;
  overflow: hidden;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  border-radius: 19px;
  background: #fff;
}

@media (min-width: 768px) {
  .wt-phone-screen {
    top: 11.05px;
    left: 8.5px;
    width: 260.95px;
    height: 562.7px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
  }

  .wt-phone-case {
    width: 277.95px;
    height: 584.8px;
  }

  .onboarding-content-image {
    height: 600px;
    bottom: 16px;
  }
}

@media (min-width: 1600px) {
  .wt-phone-screen {
    top: 13px;
    left: 10px;
    width: 307px;
    height: 662px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .wt-phone-case {
    width: 327px;
    height: 688px;
  }

  .onboarding-content-image {
    height: 700px;
    bottom: 16px;
  }
}

.wt-phone-screen-video {
  height: 100%;
  width: 100%;
  line-height: 0;
  contain: content;
}

.wt-phone-screen-video video {
  height: 100%;
  width: 100%;
}

.ie .wt-phone-screen-video video {
  -webkit-transform: scale(1.011);
  -moz-transform: scale(1.011);
  -ms-transform: scale(1.011);
  -o-transform: scale(1.011);
  transform: scale(1.011);
}

.wt-phone-screen-img {
  height: 100%;
  width: 100%;
  line-height: 0;
  contain: content;
}

.wt-phone-screen-img img {
  height: 100%;
  width: 100%;
}

.wt-phone-screen-video + .wt-phone-screen-img {
  display: none;
}

.wt-hero-phone {
  display: grid;
  place-items: center;
}

.swiper-slide,
/* .onboardingSlides, */
.innerSwiper {
  height: -webkit-fill-available;
  /* min-height: 100vh !important; */
  min-height: calc(100vh - var(--f7-navbar-height)) !important;
  width: 100%;
}

.content-grid {
  display: grid;
  grid-template-rows: 30% 70%;
  gap: 1rem;
}
</style>

<style>
.swiper-content {
  padding-top: calc(
    var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) +
      var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) +
      var(--f7-page-content-extra-padding-top, 0px)
  );
}

div.popup.onboardingPage .page-content {
  padding-top: 0px !important;
}
</style>

<script>
import {
  f7,
  theme,
  f7Popup,
  f7Block,
  f7Button,
  f7Link,
  f7PageContent,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
// import onboarding_products from "../../public/data/onboarding_products.js";
import group_money from "/images/group-money.webp";
import asso_money from "/images/asso-money.webp";
import group_savings from "/images/group-savings.webp";
import charity_image2 from "/images/charity-image2.webp";
import charity_image from "/images/charity-image.webp";
import store_image from "/images/store-image.webp";
import chat_image from "/images/chat-image.webp";
import group_money_mp4 from "/group_money.mp4";
import onbordindDetail from "../pages/onboardingDetailsPage.vue";
import navbarright from "../components/navbarright.vue";
import { loginFn } from "../js/mixins/loginFn.js";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [loginFn],
  components: {
    f7Popup,
    f7Block,
    f7Button,
    f7Link,
    f7PageContent,
    onbordindDetail,
    navbarright,
  },
  data() {
    return {
      theme,
      f7,
      slide1_autoplay: true,
      main_swiper: "",
      innerSwiper: "",
      sliderColor: "primary",
      appWidth: "",
      onBoarded: true,
      swiperIndex: 0,
      selectedProduct: "",
      videos: [],
      i8n_title: "common.landing_createGrup",
      product_first: [
        {
          id: "create_group",
          prod:
            "Create Group for your Roscas, associations, groups, and more in one place",
          img: group_money,
          video: group_money_mp4,
          i8n_title: "common.landing_createGrup",
          title: this.$t("common.landing_createGrup"),
          color: "red",
          color_hex: "#ff3b30",
        },
        {
          id: "add_invite_friends",
          prod: "Add Invite friends and family",
          img: asso_money,
          video: group_money_mp4,
          title: this.$t("common.landind_invite"),
          color: "deeppurple",
          color_hex: "#673ab7",
        },
        {
          id: "add_campaigns",
          prod: "Add new campaigns and projects to your groups",
          img: asso_money,
          video: group_money_mp4,
          title: this.$t("common.landind_add_new_campaigns"),
          color: "lightblue",
          color_hex: "#5ac8fa",
        },
        {
          id: "share_group_and_payment_links",
          prod: "Share group and paymant links",
          img: group_savings,
          video: group_money_mp4,
          title: this.$t("common.landing_sharelinks"),
          color: "deeporange",
          color_hex: "#ff6b22",
        },
        {
          id: "collect",
          prod: "Collect",
          img: group_savings,
          video: group_money_mp4,
          title: this.$t("common.landing_collect"),
          color: "green",
          color_hex: "#4cd964",
        },
        {
          id: "allow_transparency",
          prod: "Allow members to see how much is collected nd who has contributed",
          img: chat_image,
          video: group_money_mp4,
          title: this.$t("common.landing_transparency"),
          color: "lime",
          color_hex: "#cddc39",
        },
        {
          id: "keep_informed",
          prod: "Get and keep members informed, share documents, and more",
          img: charity_image2,
          video: group_money_mp4,
          title: this.$t("common.landing_getInformed"),
          color: "pink",
          color_hex: "#ff2d55",
        },
        {
          id: "communicate",
          prod: "Communicate about group",
          img: charity_image2,
          video: group_money_mp4,
          title: this.$t("common.landing_communicate"),
          color: "orange",
          color_hex: "#ff9500",
        },
        {
          id: "redeem_spend",
          prod: "Redeem, Spend and Share",
          img: charity_image,
          video: group_money_mp4,
          title: this.$t("common.landing_redeem"),
          color: "teal",
          color_hex: "#009688",
        },
        {
          id: "community_commerce",
          prod: "Sell to your community",
          img: store_image,
          video: group_money_mp4,
          title: this.$t("common.landing_sell_to_community"),
          color: "purple",
          color_hex: "#9c27b0",
        },
      ],
      products: [
        {
          id: "group_money",
          prod: "Group Money",
          img: group_money,
          video: group_money_mp4,
          title: this.$t("common.groupMoneyTitle"),
          subtitle: this.$t("common.groupMoneySubTitle"),
          detail: this.$t("common.groupMoneyDetail"),
          color: "red",
          color_hex: "#ff3b30",
        },
        {
          id: "asso_money",
          prod: "Association Money",
          img: asso_money,
          video: group_money_mp4,
          title: this.$t("common.assoMoneyTitle"),
          subtitle: this.$t("common.assoMoneySubTitle"),
          detail: this.$t("common.assoMoneyDetail"),
          color: "deeppurple",
          color_hex: "#673ab7",
        },
        {
          id: "group_savings_challenges",
          prod: "Group Saving Challenges",
          img: group_savings,
          video: group_money_mp4,
          title: this.$t("common.savingsTitle"),
          subtitle: this.$t("common.savingsSubTitle"),
          detail: this.$t("common.savingsDetail"),
          color: "deeporange",
          color_hex: "#ff6b22",
        },
        {
          id: "group_buying",
          prod: "Group Saving for Buying",
          img: group_savings, //group_buying,
          video: group_money_mp4, // "title": this.$t("common.groupBuyTitle"),
          title: this.$t("common.groupBuySubTitle"),
          subtitle: this.$t("common.groupBuySubTitle"),
          detail: this.$t("common.groupBuyDetail"),
          color: "green",
          color_hex: "#4cd964",
        },
        {
          id: "charity_money",
          prod: "Charities Collecting Money",
          img: charity_image2,
          video: group_money_mp4,
          title: this.$t("common.charityTitle"),
          subtitle: this.$t("common.charitySubTitle"),
          detail: this.$t("common.charityDetail"),
          color: "pink",
          color_hex: "#ff2d55",
        },
        {
          id: "money_pot_crowdfunding",
          prod: "Money Pot & crowdfunding",
          img: charity_image2,
          video: group_money_mp4,
          title: this.$t("common.moneyPotTitle"),
          subtitle: this.$t("common.moneyPotSubTitle"),
          detail: this.$t("common.moneyPotDetail"),
          color: "orange",
          color_hex: "#ff9500",
        },
        {
          id: "tontines_saving_club",
          prod: "Tontines, Saving clubs, ROSCAs",
          img: charity_image,
          video: group_money_mp4,
          title: this.$t("common.tontineTitle"),
          subtitle: this.$t("common.tontineSubTitle"),
          detail: this.$t("common.tontineDetail"),
          color: "teal",
          color_hex: "#009688",
        },
        {
          id: "community_commerce",
          prod: "Sell to your community",
          img: store_image,
          video: group_money_mp4,
          title: this.$t("common.shopTitle"),
          subtitle: this.$t("common.shopSubTitle"),
          detail: this.$t("common.shopDetail"),
          color: "purple",
          color_hex: "#9c27b0",
        },
        {
          id: "communicate_share",
          prod: "Communicate, Share, Be transparent",
          img: chat_image,
          video: group_money_mp4,
          title: this.$t("common.commTitle"),
          subtitle: this.$t("common.commSubTitle"),
          detail: this.$t("common.commDetail"),
          color: "lime",
          color_hex: "#cddc39",
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("deviceready", this.changeStatusBar(), false);

    if (!this.$lcStorage.get("ql82uFo")) {
      this.$lcStorage.set("ql82uFo", false);
    }
    if (!this.onboardingFinished && f7.device.cordova) {
      f7.statusbar.hide();
      f7.statusbar.overlaysWebView(true);
    }
    this.main_swiper = f7.swiper.get("swiper-container.onboardingSlides");
    this.innerSwiper = f7.swiper.get("swiper-container.firstSwiper");
    this.$store.dispatch("auth/setLandingPage", this.products);
  },

  methods: {
    changeStatusBar() {
      if (!this.onboardingFinished && f7.device.cordova) {
        f7.statusbar.setTextColor("white");
        f7.statusbar.setBackgroundColor(this.sliderColor);
      }
    },
    onInnerSlideChange() {
      if (this.isTablet) {
        this.sliderColor = "primary";
        console.log("this.sliderColor", this.sliderColor);
      }
      const inner_swiper = f7.swiper.get("swiper-container.firstSwiper");
      this.innerSwiperIndex = inner_swiper.activeIndex;
      const currentSlide = inner_swiper.slides[this.innerSwiperIndex];
      this.sliderColor = currentSlide.dataset.color;
      console.log("this.sliderColor", this.sliderColor);
    },
    onSlideChange() {
      const currSwiper = f7.swiper.get("swiper-container.onboardingSlides");
      const inner_swiper = f7.swiper.get("swiper-container.firstSwiper");
      this.swiperIndex = currSwiper.activeIndex;
      this.main_swiper = currSwiper;
      const currentSlide = currSwiper.slides[this.swiperIndex];
      this.sliderColor = currentSlide.dataset.color;
      console.log("this.sliderColor", this.sliderColor);
      if (this.inner_swiper && this.swiperIndex >= 1) {
        inner_swiper.pause();
      } else if (this.inner_swiper && this.swiperIndex == 0) {
        inner_swiper.resume();
      }
      this.playVideo();
    },
    goToNextSlide() {
      const currSwiper = f7.swiper.get("swiper-container.onboardingSlides");
      this.swiperIndex = currSwiper.activeIndex;
      this.main_swiper = currSwiper;
      currSwiper.slideNext();
      this.playVideo();
    },
    playVideo() {
      const swipeLen = this.products.length;
      if (this.swiperIndex == swipeLen) {
        this.$store.dispatch("auth/setLastSwiperIsActive", true);
      } else {
        this.$store.dispatch("auth/setLastSwiperIsActive", false);
      }
      if (this.main_swiper && this.swiperIndex >= 1) {
        setTimeout(() => {
          if (this.swiperIndex > 0 && this.main_swiper.previousIndex >= 1) {
            f7.$(`.video${this.main_swiper.previousIndex}`)[0].pause();
          }
          if (this.swiperIndex >= 1 && 1 <= this.main_swiper.activeIndex <= swipeLen) {
            f7.$(`.video${this.main_swiper.activeIndex}`)[0].play();
          }
        }, 100);
      }
    },
    navigateHome() {
      if (this.isTablet) {
        // f7.views.main.router.navigate("/stores/");
        f7.views.main.router.navigate(f7.views.main.router.currentRoute.path, {
          reloadAll: true,
        });
      }
    },
    closePopup() {
      this.$store.dispatch("auth/setOnboardingFinished", true);
      f7.statusbar.show();
    },

    learnMore(product) {
      this.selectedProduct = product;
      if (this.selectedProduct !== "") {
        f7.popup.open(".prodDetails");
      }
    },
  },

  computed: {
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "lastSwiperIsActive",
      "onboardingFinished",
      "locale",
    ]),
  },
};
</script>
