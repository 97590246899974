<template>
  <!-- <div v-if="!this.installAppPromptShown">
    <f7-popover
      target=".qrCodeScannerIcon"
      :close-by-backdrop-click="false"
      :close-by-outside-click="false"
      :style="{
        'min-width': this.isTablet == true ? '418px' : '100%',
      }"
      v-model:opened="this.popoverIsOpened"
      class="installAppPrompt"
      v-if="this.isTablet"
    >
      <f7-block class="cols20_80">
        <div>
          <img
            src="/64x64.png"
            alt="logo"
            width="64"
            height="64"
            style="vertical-align: middle"
          />
          <span
            class="avocash text-color-primary deskTopSmallerScreen"
            style="
              letter-spacing: var(--f7-tabbar-label-letter-spacing);
              font-weight: bold;
              font-size: large;
            "
            >avo¢ash</span
          >
        </div>
        <div>
          <p class="text-align-center" v-html="$t('common.installInstructions')"></p>
          <f7-button raised fill ripple color="red" @click="install()">{{
            $t("common.install")
          }}</f7-button>
        </div>
      </f7-block>
      <f7-link
        @click="dismiss()"
        popover-close
        color="red"
        :style="{ position: 'absolute', right: '8px', top: '8px' }"
        icon-f7="xmark_circle_fill"
      ></f7-link>
    </f7-popover>

    <f7-sheet
      style="height: auto; --f7-sheet-bg-color: #fff"
      swipe-to-close
      backdrop
      bottom
      :close-by-backdrop-click="false"
      :close-by-outside-click="false"
      class="installAppPrompt"
      v-if="!this.isTablet"
      v-model:opened="this.sheetIsOpened"
    >
      >
      <f7-page-content>
        <f7-block class="cols20_80">
          <div>
            <img
              src="/64x64.png"
              alt="logo"
              width="64"
              height="64"
              style="vertical-align: middle"
            />
            <span
              class="avocash text-color-primary deskTopSmallerScreen"
              style="
                letter-spacing: var(--f7-tabbar-label-letter-spacing);
                font-weight: bold;
                font-size: large;
              "
              >avo¢ash</span
            >
          </div>
          <div>
            <p class="text-align-center" v-html="$t('common.installInstructions')"></p>
            <f7-button raised fill ripple large color="red" @click="install()">{{
              $t("common.install")
            }}</f7-button>
          </div>
        </f7-block>
        <f7-link
          sheet-close
          @click="dismiss()"
          color="red"
          :style="{ position: 'absolute', right: '8px', top: '8px' }"
          icon-f7="xmark_circle_fill"
        ></f7-link>
      </f7-page-content>
    </f7-sheet>

    <f7-popover
      target=".qrCodeScannerIcon"
      :close-by-backdrop-click="true"
      :close-by-outside-click="true"
      :style="{
        'min-width': this.isTablet == true ? '418px' : '100%',
      }"
      class="installAppPromptIos"
      v-if="this.isTablet"
      v-model:opened="this.popoverIosIsOpened"
    >
      <f7-block class="cols20_80">
        <div>
          <img
            src="/64x64.png"
            alt="logo"
            width="64"
            height="64"
            style="vertical-align: middle"
          />
          <span
            class="avocash text-color-primary deskTopSmallerScreen"
            style="
              letter-spacing: var(--f7-tabbar-label-letter-spacing);
              font-weight: bold;
              font-size: large;
            "
            >avo¢ash</span
          >
        </div>
        <div>
          <p class="text-align-left" v-html="$t('common.installInstructions')"></p>
          <p class="text-align-left" v-html="$t('common.iosInstallInstructions')"></p>
        </div>
      </f7-block>
      <f7-link
        popover-close
        color="red"
        :style="{ position: 'absolute', right: '8px', top: '8px' }"
        icon-f7="xmark_circle_fill"
      ></f7-link>
    </f7-popover>

    <f7-sheet
      style="height: auto; --f7-sheet-bg-color: #fff"
      swipe-to-close
      backdrop
      bottom
      :close-by-backdrop-click="true"
      :close-by-outside-click="true"
      class="installAppPromptIos"
      v-if="!this.isTablet"
      v-model:opened="this.sheetIosIsOpened"
    >
      <f7-page-content>
        <f7-block class="cols20_80">
          <div>
            <img
              src="/64x64.png"
              alt="logo"
              width="64"
              height="64"
              style="vertical-align: middle"
            />
            <span
              class="avocash text-color-primary deskTopSmallerScreen"
              style="
                letter-spacing: var(--f7-tabbar-label-letter-spacing);
                font-weight: bold;
                font-size: large;
              "
              >avo¢ash</span
            >
          </div>
          <div>
            <p class="text-align-left" v-html="$t('common.installInstructions')"></p>
            <p class="text-align-left" v-html="$t('common.iosInstallInstructions')"></p>
          </div>
        </f7-block>
        <f7-link
          sheet-close
          color="red"
          :style="{ position: 'absolute', right: '8px', top: '8px' }"
          icon-f7="xmark_circle_fill"
        ></f7-link>
      </f7-page-content>
    </f7-sheet>
  </div> -->
  <p
    class="installInstructions text-align-left"
    v-html="$t('common.installInstructions')"
  ></p>
  <p
    class="iosInstallInstructions text-align-left"
    v-html="$t('common.iosInstallInstructions')"
  ></p>
</template>
<style scoped>
.popover {
  right: 0px !important;
  left: auto !important;
}
</style>
<script>
import {
  f7,
  f7Block,
  f7Button,
  f7Link,
  f7PageContent,
  f7Popover,
  f7Sheet,
  theme,
} from "framework7-vue";
import { mapState } from "vuex";

export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    f7Popover,
    f7Block,

    f7Button,
    f7Link,
    f7Sheet,
    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
      deferredPrompt: "",
      installAppPromptShown: false,
      promptIns: false,
      popoverIsOpened: false,
      popoverIosIsOpened: false,
      sheetIsOpened: false,
      sheetIosIsOpened: false,
      toastWithCallback: "",
      installInstructions: f7.$(".installInstructions").html(),
      iosInstallInstructions: f7.$(".iosInstallInstructions").html(),
    };
  },
  mounted() {
    this.promptIns = this.$lcStorage.get("qkUJkct32OVdt");
    this.installInstructions = f7.$(".installInstructions").html();
    this.iosInstallInstructions = f7.$(".iosInstallInstructions").html();
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
    if (
      (navigator.standalone || isStandalone) &&
      document.querySelector(".first_preloader ") &&
      document.querySelector(".first_preloader ").length > 0
    ) {
      document.querySelector(".first_preloader ").style.display = "none";
    }
    console.log(
      "f7.$('.iosInstallInstructions').html()",
      `${this.installInstructions} ${this.iosInstallInstructions}`
    );
    this.installAppPromptShown = this.promptIns ? this.promptIns : false;
    console.log("promptIns ", this.promptIns);
    window.addEventListener("beforeinstallprompt", (e) => {
      // Stash the event so it can be triggered later.
      f7.dialog.alert("beforeinstallprompt", JSON.parse(e));
      e.preventDefault();
      this.deferredPrompt = e;
      // if (this.deferredPrompt !== "") {
      this.openPrompt();
      // }
    });
    window.addEventListener("appinstalled", (e) => {
      // hideInstallPromotion();
      e.preventDefault();
      f7.dialog.alert("appinstalled", JSON.parse(e));
      this.deferredPrompt = null;
    });
    setTimeout(() => {
      if (
        this.deferredPrompt === "" &&
        this.device &&
        !this.device.standalone &&
        (this.device.ios ||
          this.device.iphone ||
          this.device.ipad ||
          this.device.os === "ios" ||
          this.device.macos)
      ) {
        this.openPromptIos();
      }
    }, 5000);

    setTimeout(() => {
      this.closePromptIos();
      this.dismiss();
    }, 7000);
    // }
  },

  methods: {
    dismiss() {
      this.deferredPrompt = null;
      this.closePrompt();
    },
    async install() {
      this.deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      this.deferredPrompt = null;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt.");
        this.closePrompt();
      } else if (outcome === "dismissed") {
        this.dismiss();
        console.log("User dismissed the install prompt");
      }
    },
    getPWADisplayMode() {
      const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
      if (document.referrer.startsWith("android-app://")) {
        return "twa";
      } else if (navigator.standalone || isStandalone) {
        return "standalone";
      }
      return "browser";
    },
    openPrompt() {
      if (!this.installAppPromptShown) {
        this.showToastWithCallback();
      }
    },
    closePrompt() {
      this.$lcStorage.set("qkUJkct32OVdt", true);
    },

    openPromptIos() {
      if (!this.installAppPromptShown) {
        this.showToastWithCallback();
      }
    },
    closePromptIos() {
      this.$lcStorage.set("qkUJkct32OVdt", true);
    },

    showToastWithCallback() {
      const self = this;
      // Create toast
      if (!self.installPWAToast) {
        self.installPWAToast = f7.toast.create({
          horizontalPosition: self.isTablet ? "right" : "center",
          text: f7.device.ios ? self.iosInstallInstructions : self.installInstructions,
          closeButton: true,
          closeButtonText: theme.md
            ? self.$t("common.install")
            : self.$t("common.buttonOk"),
          closeButtonColor: theme.md ? "red" : "primary",
          position: self.isTablet ? "top" : "bottom",
          on: {
            close() {
              if (theme.md) {
                self.install();
              }
            },
          },
        });
      }
      // Open it
      self.installPWAToast.open();
    },
  },

  computed: {
    ...mapState("auth", ["video", "isDeskTop", "isTablet", "device"]),
  },
};
</script>
