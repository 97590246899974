import { utils } from "framework7";
import { f7, theme } from "framework7-vue";
import _, { get } from "lodash";
export const attachDocs = {
  data() {
    return {
      // recorder: new Recorder(audioContext,
      //     /*    {
      //        numChannels: 1
      //        }, */
      //     {
      //         onAnalysed: data => this.setVoiceMeter(data.lineTo)
      //     }),

      audioRecordConstraints: {
        echoCancellation: true,
      },

      imGattachments: [],
      attachment: "",
      content: "",
      msgType: "text",
      // document: "",
      forms: "",
      cameraIndex: 0,
      cameraId: "",
      inputFile: "",

      image: null,
      docsUrl: [],
      documentFile: "",
      actionGridOpened: false,
      // shouldStop: false,
      // stopped: false,
      // videoElement: "",
      isMediaRecording: false,
      // front: false,
      imageCapture: "",
      constraints: "",
      // mediaStream: "",
      // hasTorch: "",
      // torchSupported: false,
      // torchActive: false,
      // cameraSupport2Cameras: [],
      // recordType: "",
      cordovaApp: "",
      selectedDocument: "",
      docToView: "",
      videoUrl: "",
      videoName: "",
      isDragging: false,
      files: [],
    };
  },
  props: {
    onStart: {
      type: Function,
      default: () => {},
    },
    onStop: {
      type: Function,
      default: () => {},
    },
  },

  mounted() {
    this.constraints = {
      video: {
        width: {
          min: 640,
          ideal: 1920,
        },
        height: {
          min: 400,
          ideal: 1080,
        },
        facingMode: this.front ? "user" : "environment",
      },
      audio: this.recordType === "video" ? { echoCancellation: true } : false,
    };
  },
  methods: {
    async startRecording() {
      navigator.mediaDevices.getUserMedia =
        navigator.mediaDevices.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      const mimeType = "audio/webm";
      this.shouldStop = false;
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { echoCancellationType: "system", channelCount: 1 },
        video: false,
      });
      this.mediaStream = stream;
      if (stream) {
        this.isRecording = true;
      }
      this.handleRecord({ stream, mimeType });
    },
    setVoiceMeter(value) {
      if (!this.$refs.stopRecord) return;
      // this.$refs.voiceMeter.style.height = `calc(2rem + ${value / 4}px`;
      this.$refs.voiceMeter.style.width = `calc(8rem + ${value / 2}px`;
    },
    stopRecording() {
      this.shouldStop = true;
      this.isMediaRecording = false;
      this.stopBothVideoAndAudio();
      // f7.popup.close(".recordVideoTakePicutre", true);
    },
    stopBothVideoAndAudio() {
      if (!this.mediaStream) {
        return;
      }
      const tracks = this.mediaStream.getTracks();
      tracks().forEach((track) => {
        console.log(track);
        if (track.readyState == "live") {
          track.stop();
        }
      });
      if (this.isRecording) {
        this.isRecording = false;
      }
    },

    async stopMediaRecording() {
      this.shouldStop = true;
      this.isMediaRecording = false;
      this.stopBothVideoAndAudio();
      f7.popup.close(".recordVideoTakePicutre", true);
    },

    handleRecord({ stream, mimeType }) {
      this.isMediaRecording = true;
      let recordedChunks = [];
      this.stopped = false;
      const mediaRecorder = new MediaRecorder(stream);
      console.log(mediaRecorder);

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          recordedChunks.push(e.data);
        }

        if (
          this.shouldStop == true &&
          this.stopped == false &&
          mediaRecorder.state === "active"
        ) {
          mediaRecorder.stop();
          this.stopped = true;
        }
        console.log(recordedChunks);
      };
      mediaRecorder.onstop = () => {
        const blob = new Blob(recordedChunks, {
          type: mimeType,
        });
        console.log(blob);
        blob.name = `${blob.type.split("/")[0]}-${new Date().toISOString()}.${
          blob.type.split("/")[1]
        }`;
        this.dispatchAttachments({ blob });
        recordedChunks = [];
        this.isMediaRecording = false;
        this.videoElement.srcObject = null;
        this.stopBothVideoAndAudio();
      };
      console.log(mediaRecorder);
      mediaRecorder.start(200);
    },

    dispatchAttachments({ blob }) {
      if (f7.views.main.router.url.search("/message/") >= 0) {
        this.setAttachment({ blob });
      } else {
        this.setDocumentsUrl({ blob });
      }
    },
    setDocumentsUrl({ blob, file = blob }) {
      const docsToAttach = {
        id: utils.id("xxxx-xxxx-xxxx-xxxx"),
        name: file.name,
        url: this.blobTobase64(blob),
        msgtype: this.getMsgType(file.type),
        fileType: file.type.split("/")[0],
        mimetype: file.type,
        type: file.type,
        sizekb: `${file.size / 1000}kb`,
        link: URL.createObjectURL(file),
        base64: this.blobTobase64(blob),
      };
      this.$store.dispatch("auth/setDocumentsUrl", docsToAttach);
      console.log("docsUrl ", docsToAttach);
    },
    //  async recordAudio() {
    //                                                                                                                                                                      const self = this;

    //                                                                                                                                                                      window.AudioContext = window.AudioContext || window.webkitAudioContext;
    //                                                                                                                                                                      const context = new AudioContext();
    //                                                                                                                                                                      const mimeType = 'audio/webm';
    //                                                                                                                                                                      navigator.mediaDevices.getUserMedia =
    //                                                                                                                                                                          navigator.mediaDevices.getUserMedia ||
    //                                                                                                                                                                          navigator.webkitGetUserMedia ||
    //                                                                                                                                                                          navigator.mozGetUserMedia;
    //                                                                                                                                                                      this.shouldStop = false;
    //                                                                                                                                                                      const audioRecordConstraints = {
    //                                                                                                                                                                          echoCancellation: true,
    //                                                                                                                                                                          sampleSize: 16,
    //                                                                                                                                                                          channelCount: 2,
    //                                                                                                                                                                      };
    //                                                                                                                                                                      const stream = await navigator.mediaDevices.getUserMedia({ audio: audioRecordConstraints });
    //                                                                                                                                                                      self.mediaStream = stream;
    //                                                                                                                                                                      self.handleRecord({ stream, mimeType });
    //                                                                                                                                                                  },
    updateFlashAvailability() {
      const track = this.mediaStream.getVideoTracks()[0];

      this.videoElement.addEventListener("loadedmetadata", () => {
        onCapabilitiesReady(track.getCapabilities());
      });

      const onCapabilitiesReady = (capabilities) => {
        if (capabilities.torch || track.getSettings().torch) {
          this.torchSupported = true;
        } else if (
          "fillLightMode" in capabilities &&
          capabilities.fillLightMode.length != 0 &&
          capabilities.fillLightMode != "none"
        ) {
          this.torchSupported = true;
        }

        if (this.torchSupported) {
          this.switchButton.addEventListener("click", () => {
            try {
              track.applyConstraints({
                advanced: [
                  {
                    torch: (this.torchActive = !this.torchActive),
                  },
                ],
              });
            } catch (err) {
              console.error(err);
            }
          });
        } else {
          console.log("No torch found");
        }
      };
    },

    async switchCamera() {
      // toggle / flip camera
      this.cameraId = this.cameras[this.cameraIndex].deviceId;

      // increment camera index and loop back to 0 if at end
      this.cameraIndex =
        this.cameraIndex + 1 < this.cameras.length ? this.cameraIndex + 1 : 0;

      this.constraints = {
        video: {
          deviceId: { exact: this.cameraId },
        },
        audio: this.recordType === "video" ? { echoCancellation: true } : false,
      };

      if (!this.mediaStream) return;

      // stop existing tracks before switching
      this.mediaStream.getTracks().forEach((track) => track.stop());

      // wait for tracks to stop
      await new Promise((resolve) => setTimeout(resolve, 100));

      // get new stream with constraints
      await this.captureCurrentMedia(this.constraints);
    },

    async recordVideo() {
      const mimeType = "video/webm";
      this.shouldStop = false;
      console.log(this.mediaStream);
      if (this.mediaStream) {
        this.handleRecord({ stream: this.mediaStream, mimeType });
      }
    },

    async recordScreen() {
      this.getDevices();
      const mimeType = "video/webm";
      this.shouldStop = false;
      if (!(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia)) {
        f7.dialog.alert("Screen Record not supported!");
      }
      let stream = null;
      const displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: "motion" },
        audio: { echoCancellation: true },
      });
      f7.dialog.confirm(
        "Record audio with screen?",
        () => {
          const voiceStream = navigator.mediaDevices.getUserMedia({
            audio: { echoCancellation: true },
            video: false,
          });
          const tracks = [
            ...displayStream.getTracks(),
            ...voiceStream.getAudioTracks(),
          ];
          stream = new MediaStream(tracks);
          this.handleRecord({ stream, mimeType });
          this.mediaStream = stream;
        },
        () => {
          stream = displayStream;
          this.handleRecord({ stream, mimeType });
          this.mediaStream = displayStream;
        }
      );
      this.videoElement.srcObject = stream;
    },

    async capTurePhoto() {
      await this.getDevices();
      const canvas = document.createElement("canvas");
      canvas.width = this.videoElement.videoWidth;
      canvas.height = this.videoElement.videoHeight;
      canvas.getContext("2d").drawImage(this.videoElement, 0, 0);
      const img = canvas.toDataURL("image/webp");
      const blob = await (await fetch(img)).blob();
      blob.name = `img-${new Date().toISOString()}.${blob.type.split("/")[1]}`;
      this.dispatchAttachments({ blob });
      console.log(blob);
      f7.popup.close(".recordVideoTakePicutre", true);
    },
    async getDevices() {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.cameraSupport2Cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        console.log("videoDevices", this.cameraSupport2Cameras.length);
        // this.cameraSupport2Cameras = videoDevices;
        this.front = this.cameraSupport2Cameras.length == 1;
        console.log(
          "this.cameraSupport2Cameras",
          this.cameraSupport2Cameras.length
        );
      });
      const supports = navigator.mediaDevices.getSupportedConstraints();

      console.log(supports);
    },

    async captureMediaCordova() {
      const self = this;
      f7.actions
        .create({
          grid: true,
          buttons: [
            [
              {
                text: self.$t("attachdocs.takeAPhoto"),
                color: "auto",
                icon: theme.ios
                  ? '<i class="f7-icons">camera</i>'
                  : '<i class="material-icons ">photo_camera</i>',
                bold: true,
                close: true,
                onClick() {
                  self.captureMedia("video", "");
                },
              },
              {
                text: self.$t("attachdocs.recordAVideo"),
                color: "teal",
                bold: true,
                close: true,
                icon: theme.ios
                  ? '<i class="f7-icons ">videocam_circle_fill</i>'
                  : '<i class="material-icons ">videocam</i>',
                onClick() {
                  self.captureMedia("img", "");
                },
              },
            ],
          ],
        })
        .open();
    },

    async captureMediaDeskTop(record_type) {
      f7.popup.open(".recordVideoTakePicutre", true);
      f7.$("#recordMediaPopup .page-content").css("overflow", "hidden");
      this.getDevices();
      this.recordType = record_type;
      this.videoElement = document.getElementById("videoRecorder");
      console.log(
        "videoElement this.constraints:",
        document.getElementById("videoRecorder"),
        this.recordType,
        this.isMediaRecording
      );
      this.captureCurrentMedia();
    },
    async captureCurrentMedia() {
      console.log("videoElement :", document.querySelector("videoRecorder"));
      navigator.mediaDevices.getUserMedia =
        navigator.mediaDevices.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;
      await navigator.mediaDevices
        .getUserMedia(this.constraints)
        .then((stream) => {
          this.videoElement = document.getElementById("videoRecorder");
          this.videoElement.srcObject = stream;
          this.mediaStream = stream;
          this.videoElement.onloadedmetadata = () => {
            this.videoElement.play();
          };
          console.log("mediaStream :", this.mediaStream);
          this.updateFlashAvailability();
        })
        .catch((error) => {
          console.error("getUserMedia() error:", error);
        });
    },

    readFileBlob(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onerror = console.error;
        reader.onload = async (event) =>
          resolve(await (await fetch(event.target.result)).blob());
        reader.readAsDataURL(file);
      });
    },

    blobTobase64(blob) {
      const reader = new FileReader();
      reader.onerror = console.error;
      reader.onloadend = () => {
        reader.result.split(",")[1];
      };
      reader.readAsDataURL(blob);
    },
    setAttachment({ blob, file = blob }) {
      const docsToAttach = {
        msgtype: this.getMsgType(file.type),
        fileType: file.type.split("/")[0],
        mimetype: file.type,
        type: file.type,
        url: window.URL.createObjectURL(file),
        link: window.URL.createObjectURL(file),
        name: file.name,
        base64: this.blobTobase64(blob),
        blob,
        file,
      };
      this.content = {
        body: file.name,
        msgtype: this.attachment.msgtype,
        mimetype: this.attachment.mimetype,
        filename: file.name,
      };
      // const docsToAttach = [];
      // docsToAttach.unshift(this.attachment);
      console.log("attachment", docsToAttach);
      setTimeout(() => {
        this.$store.dispatch("auth/setDocumentsUrl", docsToAttach);
      }, 500);
    },
    getMsgType(fileType) {
      return (
        {
          image: "image",
          audio: "audio",
          video: "video",
        }[fileType.split("/", 1)[0]] || "file"
      );
    },

    resetAttachment() {
      if (!this.attachment) return this.attachment == undefined;
      window.URL.revokeObjectURL(this.attachment.file);
      this.content = {
        body: this.attachment
          ? this.content.body.replace(this.attachment.name, "")
          : "",
        msgtype: "text",
      };
      this.attachment = undefined;
      this.$store.dispatch("auth/setDocumentsUrl", []);
    },

    msgAttachmentUpload(event) {
      this.files =
        event.target.files ||
        event.dataTransfer.files ||
        this.documents ||
        this.file;
      if (!this.files.length) return;
      setTimeout(() => {
        for (const blob of this.files) {
          this.dispatchAttachments({ blob });
          // self.setAttachment({ blob });
        }
      }, 1000);
    },

    async onPaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      console.log(items);
      for (const index in items) {
        const item = items[index];
        if (item.kind === "file") {
          const file = item.getAsFile();
          console.log(file);
          const blob = await this.readFileBlob(file);
          return this.dispatchAttachments({ blob }); //self.setAttachment({ blob });
        }
      }
    },
    onChange() {
      const draggedFiles = [];
      draggedFiles.push(...this.$refs.file.files);
      this.createFile(draggedFiles);
      this.$refs.file.files = null;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    attachFileOpenActions() {
      const self = this;
      if (!self.actionsAttachFiles) {
        self.actionsAttachFiles = f7.actions.create({
          convertToPopover: true,
          closeByOutsideClick: true,
          closeOnEscape: true,
          grid: self.isTablet ? false : true,
          buttons: [
            {
              text: self.$t("attachdocs.selectAPhoto"),
              icon: `<i class="f7-icons size-50 if-not-md color-lightblue">photo_on_rectangle</i><i class="material-icons size-50 md-only color-lightblue">image</i>`,
              color: "lightblue",
              strong: true,
              onClick() {
                self.selectDocument("img");
              },
            },
            {
              text: self.$t("attachdocs.takeAPhoto"),
              icon: `<i class="f7-icons if-not-md color-pink">camera</i><i class="material-icons md-only color-pink">photo_camera</i>`,
              color: "pink",
              strong: true,
              onClick() {
                self.captureDeskTopMedia("img");
              },
            },

            {
              text: self.$t("attachdocs.selectAVideo"),
              icon: `<i class="f7-icons if-not-md color-lime">play_rectangle</i><i class="material-icons md-only color-lime">video_library</i>`,
              color: "lime",
              strong: true,
              onClick() {
                self.selectDocument("video");
              },
            },
            {
              text: self.$t("attachdocs.recordAVideo"),
              icon: `<i class="f7-icons if-not-md color-purple">videocam</i><i class="material-icons md-only color-purple">videocam</i>`,
              color: "purple",
              strong: true,
              onClick() {
                self.captureDeskTopMedia("video");
              },
            },
            {
              text: self.$t("attachdocs.documents"),
              icon: `<i class="f7-icons if-not-md color-deeporange">doc_text</i><i class="material-icons md-only color-deeporange">description</i>`,
              color: "deeporange",
              strong: true,
              onClick() {
                self.selectDocument("otherFiles");
              },
            },
            {
              text: self.$t("attachdocs.selectAnAudio"),
              icon: `<i class="f7-icons if-not-md color-teal">music_albums</i><i class="material-icons md-only color-teal">library_music</i>`,
              color: "teal",
              strong: true,
              onClick() {
                self.selectDocument("audio");
              },
            },
          ],
          // Need to specify popover target
          targetEl: f7.$(".attachFileButton"),
        });
      }

      // Open
      self.actionsAttachFiles.open();
    },
    viewOtherFiles(id) {
      const currentImage = this.documentsUrl.find((img) => img.id === id);
      this.$store.dispatch("auth/selectDocument", currentImage);
      f7.popup.open(".viewDocs");
    },

    selectDocument(docType) {
      if (docType === "img" && f7.device.cordova) {
        this.takePicture("PHOTOLIBRARY");
        return;
      }
      const docTypeMap = {
        img: "selectPhoto",
        audio: "selectAudio",
        video: "selectVideo",
        otherFiles: "selectOtherFile",
      };
      const inputFiles = document.getElementById(docTypeMap[docType]);
      console.log("inputFile", inputFiles);

      if (!inputFiles) {
        return;
      }
      inputFiles.value = null;
      inputFiles.files = null;
      inputFiles.click();

      // inputFiles.addEventListener("change", (e) => {
      //   // setTimeout(() => {
      //   const imageFiles = e.target.files || e.dataTransfer.files;
      //   console.log("imageFiles", inputFiles.files);
      //   if (imageFiles.length > 0) {
      //     this.createFile(imageFiles);
      //   }
      //   e.preventDefault();
      //   // }, 3000);
      //   inputFiles.value = null;
      //   inputFiles.files = null;
      //   e.target.value = null;
      // });
    },
    onFileChange(e) {
      console.log("onFileChange", e);
      const imageFiles = e.dataTransfer.files;
      console.log("imageFiles", this.inputFileVal);
      // setTimeout(() => {
      if (imageFiles.length > 0) {
        this.createFile(imageFiles);
      }
      // }, 3000);
    },

    captureDeskTopMedia(record_type) {
      this.recordType = record_type === "img" ? "img" : "video";
      if (f7.device.cordova) {
        if (record_type === "img") {
          this.takePicture("CAMERA");
        } else {
          this.captureMedia("video", "");
        }
      } else {
        this.captureMediaDeskTop(record_type);
      }
    },

    attachmentUpload(event) {
      console.log("attachmentUpload", this.selectedDocument);
      // this.files =
      //   event.target.files ||
      //   event.dataTransfer.files ||
      //   this.documents ||
      //   this.file;
      console.log(event.target.files);
      this.createFile(event.target.files);
    },
    async uploadedFile() {
      document.querySelector(`[id=${this.fileInput}]`).click();
    },

    // uploadDocumentFile() {
    //   this.attachFileOpenActions();
    //   // if (f7.width > 768) {
    //   //   this.attachFileOpenFab();
    //   // } else {
    //   //   f7.actions.open(".attachFileForMobile");
    //   // }
    // },
    // attachFileOpenFab() {
    //   const self = this;
    //   f7.fab.toggle(".attachFileForDeskTop");
    // },
    onFileChange(event) {
      this.fileUploading(event);
    },
    avatarUpload(event) {
      this.fileUploading(event);
    },
    logoUpload(event) {
      this.fileUploading(event);
    },
    uploadIdProof(event) {
      this.fileUploading(event);
    },

    fileUploading(event) {
      this.files =
        event.target.files ||
        event.dataTransfer.files ||
        this.documents ||
        this.file;
      if (this.files.length > 0) {
        this.createFile(this.files);
      }
    },
    createFile(files) {
      console.log("createFile", files);
      for (const element of files) {
        const reader = new FileReader();
        if (element) {
          reader.readAsDataURL(element);
          console.log(element.name);
        }
        reader.onload = (event) => {
          const docsToAttach = {
            id: f7.utils.id("xxxx-xxxx-xxxx-xxxx"),
            name: element.name,
            url: event.target.result,
            type: element.type,
            file: element,
            fileType: element.type.split("/", 1)[0],
            sizekb: `${element.size / 1000}kb`,
            link: URL.createObjectURL(element),
            base64: event.target.result,
          };
          console.log("docsToAttach ", docsToAttach);
          this.$store.dispatch("auth/setDocumentsUrl", docsToAttach);
        };
      }
    },

    removeDoc(index) {
      const doc = this.documentsUrl[index];
      this.$store.dispatch("auth/unSetDocumentsUrl", doc);
    },
    deleteImage(id, entity, type, name) {
      const entityType =
        type === "association" || type === "group" ? "asso" : type;
      this.$store.dispatch(`${this.type}/deleteImages`, {
        imageId: id,
        entityId: entity.id,
        type: entityType,
      });
    },

    editImage(id) {
      f7.preloader.show("multi");
      const currentImage = this.documentsUrl.find((img) => img.id === id);
      this.$store.dispatch("auth/selectDocument", currentImage);
      console.log("currentImage", currentImage);
      if (this.document !== null) {
        console.log("this.document", this.document);
        f7.popup.open(".editImage");
      }
      f7.preloader.hide();
    },

    flip(x, y) {
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },

    viewOtherFiles(currentImage) {
      console.log("currentImage", currentImage);
      this.$store.dispatch("auth/selectDocument", currentImage);
      f7.popup.open(".viewDocs");
    },
    photoList() {
      const photos = [];
      for (const photo of this.documentsUrl) {
        if (photo.fileType === "imgage" || photo.fileType === "video") {
          photos.push(photo.url);
        }
      }
      return photos;
      // }
    },

    addVideoUrls(serviceType, type) {
      console.log(type);
      this.$store.dispatch("auth/setVideoServiceType", {
        id: serviceType.id,
        type,
      });
      f7.popup.open(".addNewVideo");
    },
    addVideo() {
      if (this.videoUrl !== "") {
        this.videosUrl.push({
          url: this.videoUrl,
          name: this.videoName,
        });
        this.$store.dispatch("auth/setVideosUrl", this.videosUrl);
        this.videoUrl = "";
        this.videoName = "";
      }
      console.log(this.videosUrl);
    },
    removeVideo(index) {
      this.videosUrl.splice(index, 1);
    },
    async saveVideos() {
      if (!(this.videosUrl && this.videosUrl.length > 0)) {
        return;
      }
      await this.$store.dispatch(`${this.videoService.type}/uploadShopVideo`, {
        url: this.videosUrl,
        id: this.videoService.id,
      });
      await this.$store.dispatch("auth/setVideoServiceType", "");
      await this.$store.dispatch("auth/setVideosUrl", "");
      f7.popup.close(".addNewVideo");
    },
    deleteVideo(id, entity, type) {
      this.$store.dispatch(`${this.type}/deleteVideo`, {
        videoId: id,
        entityId: entity.id,
        type,
      });
    },
  },
};
