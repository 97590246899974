<template>
  <f7-page class="paymentCards_page">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title>{{ $t("common.mypayCards") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list media-list dividers-ios strong-ios>
          <f7-list-group media-list v-if="this.userAccount.userCreditCards &&
            (this.userAccount.userCreditCards.STANDARD.length > 0 ||
              this.userAccount.userCreditCards.BUSINESS.length > 0)
            ">
            <f7-block-title style="white-space: normal; word-wrap: break-word">{{
              $t("common.mypayCards")
            }}</f7-block-title>

            <span v-for="(creditCardsList, account_type, index) in this.userAccount
              .userCreditCards" :key="index">
              <f7-list-item group-title :title="getAccountType(account_type)" v-if="creditCardsList.length > 0" />
              <f7-list-item no-chevron link="#" v-if="creditCardsList.length > 0"
                v-for="(creditcard, index) in creditCardsList" :key="index" :disabled="creditcard.status !== 'ACTIVE'"
                :title="creditcard.number" :subtitle="creditcard.tag" :header="$t('common.cardNumber')" :after="`${isTablet ? $t('common.expiryDate') + ':' : ''} ${creditcard.expiry_date
                  }`" :class="`text-color-${cardNetWork.find((cd) => cd.value === creditcard.brand).color
    } ${creditcard.main ? 'border-color-primary highlighted_horiz' : ''}`" :checkbox="true"
                :checked="creditcard.main" @click="openCreditCardItem(creditcard)" :id="`creditcard_${creditcard.id}`">
                <template #media><img :src="cardNetWork.find((cd) => cd.value === creditcard.brand).img" /></template>
                <template #after><f7-icon ios="f7:pencil" md="material:edit" color="primary" /></template></f7-list-item>
            </span>
          </f7-list-group>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7List,
  f7ListGroup,
  f7BlockTitle,
  f7Accordion,
  f7BlockHeader,
  f7ListItem,
  f7ListInput,
  f7Button,
  theme,
  f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import attachDocs from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import Navbarright from "../components/navbarright.vue";
import { account } from "../js/mixins/account.js";
import card_network from "../../public/data/card_network.json";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [account],
  components: {
    f7List,
    f7ListGroup,
    f7BlockTitle,
    f7Accordion,
    f7BlockHeader,
    f7ListItem,
    f7ListInput,
    f7Button,

    navbarright: Navbarright,
    attachDocs,
    myDocs,
  },
  data() {
    return {
      theme,
      f7,
      cardNetWork: card_network,
      selectedCard: "",
    };
  },
  mounted() {
    if (this.userAccount === null && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
  },

  methods: {},
  computed: {
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "standard",
      "business",
      "accountList",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "documentsUrl", "document"]),
    ...mapState("account", ["userAccount"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
</script>
