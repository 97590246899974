<template>
  <f7-page hide-bars-on-scroll :page-content="false">
    <f7-navbar sliding no-shadow no-toolbar :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-html="$t('issuenewcardqr.instantToup')"></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top labels no-shadow class="detailToolbar">
      <f7-link tab-link="#issueNewCard" v-text="$t('issuenewcardqr.issueNewCardTitle')" tab-link-active></f7-link>
      <f7-link tab-link="#checkCardSendCash" v-text="$t('issuenewcardqr.checkCard')"></f7-link>
    </f7-toolbar>

    <f7-tabs swipeable>
      <f7-tab id="issueNewCard" class="page-content" tab-active>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-card outline v-if="this.newCard === '' || !this.newCard" :padding="false">
              <f7-card-content v-html="$t('issuenewcardqr.toastMsg')"></f7-card-content>
            </f7-card>

            <f7-list dividers-ios strong-ios v-if="this.newCard === ''">
              <f7-list-item>
                <span style="text-align: left">{{ this.topupType }}</span>
                <f7-toggle class="topupType" checked :disabled="this.newCard !== ''" color="auto"
                  @change="topupTypeChange()"></f7-toggle>
              </f7-list-item>
            </f7-list>
            <f7-list dividers-ios strong-ios media-list>
              <f7-list-group v-if="this.newCard === ''">
                <h5 class="margin">{{ $t("issuenewcardqr.enterTheTopuup") }}</h5>

                <li class="item-content item-input">
                  <div class="item-media">
                    {{ this.topUpCurrency }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-floating-label">
                      {{ $t("common.amount") }}
                    </div>
                    <div class="item-input-wrap">
                      <input type="number" name="newQRamountToTopUp" id="newQRamountToTopUp" min="0" required validate
                        :data-error-message="$t('common.amountInfo')" :value="amountToPay"
                        @input="amountToPay = $event.target.value" />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>

                <f7-button raised fill large ripple class="margin-horizontal margin-vertical buttonIssue buttons"
                  color="red" @click="issueNewCardQRCode()" v-if="!this.newCard">{{ $t("issuenewcardqr.issueNewCard") }}
                </f7-button>
              </f7-list-group>
              <f7-list-group>
                <h5 class="margin-horizontal" v-if="this.newCard !== '' && this.toBeScanned == true">
                  {{ $t("issuenewcardqr.customerCanScan") }}
                </h5>
                <h5 class="margin-horizontal" v-if="this.newCard !== '' && this.toBeScanned == false">
                  {{ $t("issuenewcardqr.customerCanCopy") }}
                </h5>
                <h4 class="margin-horizontal" v-if="this.newCard">
                  {{ $t("common.amount") }} :<span style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      this.newCard.initAmount,
                      this.newCard.currencySymbol,
                      this.newCard.currencyCode
                    )
                      "></span>
                  </span>
                </h4>
                <f7-photo-browser :photos="[`data:image/png;base64,${this.newCard.cardQrImage}`]" ref="popup">
                </f7-photo-browser>
                <a link="#" @click="$refs.popup.open()">
                  <img v-if="this.newCard.cardQrImage" :src="`data:image/png;base64,${this.newCard.cardQrImage}`"
                    class="cardQrImage" alt="cardQrImage" style="display: block; margin: auto; width: auto" /></a>
                <li class="item-content" v-if="this.newCard">
                  <div class="item-inner">
                    <div class="item-title-row" style="color: gray">
                      <div class="item-title">{{ $t("common.code") }}</div>
                      <div class="item-title">{{ $t("common.pin") }}</div>
                    </div>
                    <div class="item-title-row">
                      <div v-if="this.newCard" class="item-title" style="font-weight: bold">
                        {{ this.newCard.cardNumber }}
                      </div>
                      <div class="item-title float-left" style="font-weight: bold">
                        {{ this.newCard.cardPIN }}
                      </div>
                    </div>
                  </div>
                </li>
                <f7-list-item v-if="this.newCard.url && this.newCard.url.length > 0" :title="$t('common.link')" link="#"
                  @click="socialShare(sharedContent)"><a>{{ this.newCard.url }}</a></f7-list-item>
                <f7-card-footer class="no-border" v-if="this.newCard !== ''">
                  <f7-link @click="saveQrFile()" icon-aurora="f7:square_arrow_down" icon-ios="f7:square_arrow_down"
                    icon-md="material:save"></f7-link>
                  <f7-link @click="socialShare(sharedContent)" class="shareButton" popup-close
                    icon-aurora="f7:square_arrow_up" icon-ios="f7:square_arrow_up" icon-md="material:share"></f7-link>
                </f7-card-footer>
                <span v-if="this.newCard &&
                  this.scannedCard &&
                  (this.newCard.id === this.scannedCard.cardId) &
                  (this.toBeScanned == true)
                  ">
                  <f7-button raised fill large class="validateTopUp margin buttons" color="red"
                    @click="validateToBeScannedTopUp()">
                    {{ $t("issuenewcardqr.validateTopup") }}</f7-button>

                  <f7-button ripple outline large raised color="red" class="margin deleteQRCode buttons"
                    @click="deleteQRCode()">{{ $t("common.cancel") }}</f7-button>
                </span>
                <span v-if="this.newCard && !this.toBeScanned">
                  <f7-button raised fill large class="validateTopUp margin buttons" color="red"
                    @click="validateNotToBeScannedTopUp()">{{ $t("issuenewcardqr.validateTopup") }}</f7-button>
                  <f7-button ripple outline large raised color="red" class="margin deleteQRCode buttons"
                    @click="deleteQRCode()">{{ $t("common.cancel") }}</f7-button>
                </span>
                <f7-list-item style="
                    color: red;
                    font-style: italic;
                    white-space: normal;
                    word-wrap: break-word;
                  ">{{ $t("issuenewcardqr.makeSureYou") }}
                  <template #media>
                    <f7-icon ios="f7:exclamationmark_triangle" aurora="f7:exclamationmark_triangle"
                      md="material:warning_amber" size="36px" color="red"></f7-icon>
                  </template>
                </f7-list-item>
              </f7-list-group>
            </f7-list>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>
      <f7-tab id="checkCardSendCash" class="page-content">
        <sendcash></sendcash>
      </f7-tab>
    </f7-tabs>
    <template #fixed>
      <f7-fab v-if="this.newCard !== ''" position="right-bottom" class="shareButton" color="auto"
        @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.paymentCountry .smart-select .item-after {
  text-align: left !important;
  padding-top: 8px !important;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavTitle,
  f7NavRight,
  f7Toolbar,
  f7Link,
  f7Tabs,
  f7Tab,
  f7Card,
  f7CardContent,
  f7List,
  f7ListItem,
  f7Toggle,
  f7ListGroup,
  f7Block,
  f7Button,
  f7PhotoBrowser,
  f7CardFooter,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import sendcash from "../pages/sendcash.vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { messageSocket } from "../js/websocket/websocketConnect.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    sendcash,
    f7Page,
    f7Navbar,
    f7NavTitle,
    f7NavRight,
    f7Toolbar,
    f7Link,
    f7Tabs,
    f7Tab,
    f7Card,
    f7CardContent,
    f7List,
    f7ListItem,
    f7Toggle,
    f7ListGroup,

    f7Block,
    f7Button,
    f7PhotoBrowser,
    f7CardFooter,
    f7Icon,
  },
  mixins: [calculateTransferPayment, messageSocket, socialShare],
  data() {
    return {
      theme,
      f7,
      amountToTopUp: "",
      amountToPay: "",
      recipientuser: "",
      topUpCurrency: "",
      topUpCountry: "",
      countryCode: "",
      isssueQRPaymentErrorMessage: "",
      connected: false,
      service: "",
      path: "",
      socket: "",
      stompClient: "",
      topupType: "",
      toBeScanned: true,
      sharedContent: "",
      fileToSave: [],
      // scannedCard: ""
    };
  },

  mounted() {
    const self = this;
    if (!this.msgCltConnected) {
      this.connectMessageSocket();
    }
    this.topupType = this.$t("issuenewcardqr.scanCardType");
    if (this.userAccount === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount");
    }
    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
    // if (this.profile) {
    this.topUpCurrency = this.profile.currencyCode;
    this.topUpCountry = this.profile.countryCode;
    this.countryCode = this.profile.countryCode;
    // f7.$(".smart-select .item-after").html(
    //   this.primaryAccount.currencyCode +
    //   ": " +
    //   this.primaryAccount.currencyName +
    //   " " +
    //   this.profile.address.country
    // );
    // }
    this.path = import.meta.env.VITE_APP_URL;
    var cardIssueKeyPad = f7.keypad.create({
      inputEl: "#newQRamountToTopUp",
      type: "calculator",
      toolbarCloseText: "OK",
      on: {
        change(keypad, value) {
          f7.$("#newQRamountToTopUp").value = value;
          self.amountToPay = value;
        },
      },
    });
  },
  methods: {
    topupTypeChange() {
      const self = this;
      const toggle = f7.toggle.get(".topupType");
      if (toggle.checked) {
        this.topupType = self.$t("issuenewcardqr.scanCardType");
        this.toBeScanned = true;
      } else {
        this.topupType = self.$t("issuenewcardqr.topupLaterCardType");
        this.toBeScanned = false;
      }
    },

    changeTopUpCurrency() {
      const self = this;
      this.topUpCurrency = this.recipientCountry.toCurrencyCode;
      this.countryCode = this.recipientCountry.toCountryCode2;
      this.calculateAmountToTopUp();
    },

    //  Issuing QR Code for the counterpart to scan and claim payment

    issueNewCardQRCode() {
      const self = this;

      if (!f7.input.validateInputs("#issueNewCard")) {
        return;
      }
      f7.preloader.show("multi");
      if (
        this.amountToPay != "" ||
        !this.primaryAccount.accountNumber ||
        !this.primaryAccount.currencyCode
      ) {
        if (this.toBeScanned == true) {
          self.$store.dispatch("card/createNewCardLiveScan", this.amountToPay);
          if (this.newCard.id !== "") {
            this.validatedDialog();
          }
          this.connectCardService();
        } else {
          self.$store
            .dispatch("card/createNewCardLaterScan", this.amountToPay)
            .then((result) => {
              console.log(result);
              setTimeout(() => {
                this.validatedDialog();
                this.share();
              }, 2000);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      f7.preloader.hide();
    },

    deleteQRCode() {
      const self = this;

      this.amountToPay = "";
      console.log(this.newCard.id);
      self.$store.dispatch("card/cancelNewCard", this.newCard.id);
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
    },

    async validateToBeScannedTopUp() {
      const self = this;

      console.log(this.scannedCard);
      if (
        !(this.newCard && this.scannedCard && this.newCard.id === this.scannedCard.cardId)
      ) {
        return;
      }
      await self.$store.dispatch("card/validateNewCard", {
        cardId: this.newCard.id || this.scannedCard.cardId,
        recipientId: this.scannedCard.recipientUserId,
      });
      this.validatedDialog();
      this.amountToPay = "";
      await self.$store.dispatch("transaction/resetPaymentDetail");
      await self.$store.dispatch("transaction/resetPaymentReverseDetail");
    },
    async validateNotToBeScannedTopUp() {
      const self = this;
      await self.$store.dispatch("card/validateNewCard", {
        cardId: this.newCard.id || this.scannedCard.cardId,
        recipientId: null,
      });
      this.amountToPay = "";
    },

    async share() {
      const self = this;

      const files = [];
      const base64Files = [];
      if (this.newCard) {
        const blob = await (
          await fetch(`data:image/png;base64,${this.newCard.cardQrImage}`)
        ).blob();
        const termination = blob.type.split("/")[1];
        const file = new File([blob], `${this.newCard.cardUID}.${termination}`, {
          type: blob.type,
        });
        this.fileToSave.push(file);
        files.push(file);
        base64Files.push(`data:image/png;base64,${this.newCard.cardQrImage}`);
        console.log(file);
      }
      const msg = `<p>${self.$t(
        "issuenewcardqr.scanQrCodeOrLinkClikToTopup"
      )}</p><br> ${self.$t("issuenewcardqr.cardNumber")}: <b>${this.newCard.cardNumber
        }</b> </br> ${self.$t("issuenewcardqr.cardCode")}: <b>${this.newCard.cardPIN}</b>`;
      const subj = self.$t("issuenewcardqr.avocashTopup");
      this.sharedContent = this.newCard.url
        ? {
          message: msg,
          subject: subj,
          base64Files,
          files,
          link: this.newCard.url,
          short: "",
          linkBase: "",
          imgUrl: "",
        }
        : {
          message: msg,
          subject: subj,
          base64Files,
          files,
          link: `/topup/my-account/${this.newCard.cardUID}/${this.newCard.cardNumber}`,
          short: false,
          linkBase: "links",
          imgUrl: "https://avocash.com/34x34.png",
        };
      console.log(this.sharedContent);
    },
    saveQrFile() {
      const self = this;
      if (this.fileToSave.length == 0) {
        this.share();
      }
      this.saveFile(fileToSave);
    },

    onPageBeforeRemove() {
      const self = this;

      // this.disconnect();
      f7.$(".mainToolbar").css("display", "block");
      if (self.popup) self.popup.destroy();
      this.$store.state.card.newCard = "";
      this.$store.state.card.scannedCard = "";
      this.$store.state.card.cardTopuValidateStatus = "";
    },
    validatedDialog() {
      const self = this;

      if (!self.toastIcon) {
        self.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-  text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-  text-color-green border-color-gray color-green" style="font-size:150px">check_circle_outline</i>',
          text: "",
          // this.scannedCard.status || this.QRtext.status,
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 3000,
        });
      }
      self.toastIcon.open();
    },
  },
  computed: {
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "qrcodeDecoded",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("remit", ["recipients", "allrecipients", "userDetail"]),
    ...mapState("location", ["country", "destinationCity", "currentUserCountry"]),
    ...mapState("card", [
      "cardQRCode",
      "newCard",
      "cardTopuValidateStatus",
      "scannedCard",
      "QRtext",
    ]),
    ...mapState("account", ["userAccount"]),
    ...mapState("auth", ["profile", "dynamicLink"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    ...mapState("message", ["messageSocketClient", "msgCltConnected"]),

    recipientCountry() {
      return _.find(this.destinationCountry, ["toCountryCode2", this.topUpCountry]);
    },
    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
  },
};
</script>
