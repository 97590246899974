<template>
  <f7-page hide-bars-on-scroll @page:beforeremove="onPageBeforeRemove">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ this.currentStore.storeName }}</f7-nav-title>
      <f7-nav-right>
        <f7-link href="/cart/">
          <f7-icon ios="f7:cart" aurora="f7:cart" md="material:shopping_cart" color="none"
            v-if="this.cart && this.cart.length > 0">
            <f7-badge bg-color="none" class="text-align-center"
              style="font-size: 50%; margin-left: -23px; top: -5px; font-weight: bold" text-color="red"
              v-if="this.cart && this.cart.length > 0 && this.totalItemCount > 9">{{ this.totalItemCount }}</f7-badge>
            <f7-badge bg-color="none" class="text-align-center"
              style="font-size: 50%; margin-left: -20px; top: -5px; font-weight: bold" text-color="red"
              v-if="this.cart && this.cart.length > 0 && this.totalItemCount < 10">{{ this.totalItemCount }}</f7-badge>
          </f7-icon>
          <!-- <navbarright></navbarright> -->
        </f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-block-title class="margin" style="font-weight: bold" divider>
      {{ this.selectedItems[0].Title }}
    </f7-block-title>
    <!-- <f7-block> -->

    <div :width="this.itemPhotos && this.itemPhotos.length > 1 ? 90 : 100">
      <f7-photo-browser :photos="this.itemPhotos" ref="popup"></f7-photo-browser>

      <a link="#" @click="$refs.popup.open()" style="height: 500px">
        <img :src="selectedImg" style="
            max-height: 500px;
            display: block;
            margin: auto;
            object-fit: cover;
            width: 100%;
          " />
      </a>
      <swiper-container :pagination="true" class="demo-swiper-multiple" :space-between="2"
        :slides-per-view="this.itemPhotos.length" style="text-align: center, overflow-x: scroll;"
        v-if="this.itemPhotos && this.itemPhotos.length > 1">
        <swiper-slide v-for="(item, index) in this.itemPhotos" :key="index" height="50px">
          <a link="#" @click="selectImg(item)">
            <img :src="item" style="
                max-height: 50px;
                display: block;
                margin: auto;
                object-fit: contain;
                width: 100%;
              " />
          </a>
        </swiper-slide>
      </swiper-container>
    </div>
    <f7-list>
      <p class="margin-left">
        <b>{{ this.selectedItems[0].Type }}</b>
        :
        <i>{{ this.selectedItems[0].Tags }}</i>
      </p>
      <span>
        <div accordion-list class="margin-bottom margin-left">
          <f7-accordion-item>
            <f7-accordion-toggle>
              <a>
                <i>{{ $t("common.more") }}</i>
              </a>
            </f7-accordion-toggle>
            <f7-accordion-content>
              <p v-html="this.selectedItems[0]['Body (HTML)']"></p>
            </f7-accordion-content>
          </f7-accordion-item>
        </div>
      </span>
      <f7-list-item style="font-weight: bold" :after="'€' + this.selectedItems[0]['Variant Price'] + ' EUR'">
      </f7-list-item>
      <f7-list-group>
        <div class="grid grid-cols-2">
          <f7-list-item class="no-margin-right" sheet-open=".itemTypePopup" link="#" :header="this.selectedItems[0]['Option1 Name'] +
            ': ' +
            this.selectedItem['Option1 Value']
            " :after="this.selectedItems[0]['Option2 Name'] +
    ' ' +
    this.selectedItem['Option2 Value']
    ">
            <img v-if="!!this.selectedItem.ImageSrc" slot="media" :src="this.selectedItem.ImageSrc" width="80" style="
                max-height: 50px;
                display: block;
                margin: auto;
                object-fit: contain;
                width: 100%;
                border: none;
              " />
          </f7-list-item>

          <li class="item-content item-input no-padding-right" v-if="this.selectedItem['Variant Inventory Qty'] >= 2">
            <div class="item-inner no-padding-right">
              <div class="item-title item-label no-padding-right">
                {{ $t("itemdetail.qty") }}
              </div>
              <div class="item-input-wrap input-dropdown-wrap">
                <select v-model="quantity" defaultValue="1">
                  <option v-for="(quantity, index) in this.selectedItem[
                    'Variant Inventory Qty'
                  ]" :key="index" :value="quantity">
                    {{ quantity }}
                  </option>
                </select>
              </div>
            </div>
          </li>
          <f7-list-item :header="$t('itemdetail.stock')" :title="this.selectedItem['Variant Inventory Qty']"
            v-else-if="this.selectedItem['Variant Inventory Qty'] === 1"></f7-list-item>
          <f7-list-item v-else>
            <b slot="after" style="text-break: break; font-size: 80%; font-weight: bold-italic"
              class="text-color-red text-align-left">{{ $t("itemdetail.notAvailable") }}</b>
          </f7-list-item>
        </div>
      </f7-list-group>
      <f7-block-title divider :title="$t('storedetail.communityBuy')"></f7-block-title>
      <f7-list-group>
        <li v-for="(offer, value, index) in currentStore.socBuy" :key="index + 1">
          <div class="item-content">
            <div class="item-media">
              <f7-badge style="width: 24px; height: 24px" slot="media" color="primary" class="no-padding no-margin">
                {{ value }}+</f7-badge>
            </div>
            <div class="item-inner no-padding-vertical">
              <div :class="isTablet ? 'item-after' : 'item-title'">
                €{{
                  parseFloat(
                    (selectedItems[0]["Variant Price"] * (100 - offer)) / 100
                  ).toFixed(2)
                }}
                EUR
              </div>
              <div class="item-after">
                <f7-link v-if="!isTablet" class="margin-right" icon-ios="f7:cart_badge_plus"
                  icon-aurora="f7:cart_badge_plus" icon-md="material:add_shopping_cart" color="auto" :style="{
                    'pointer-events':
                      value > selectedItem['Variant Inventory Qty'] ? 'none' : '',
                  }" @click="addToCart(selectedItem, value)"></f7-link>
                <f7-button v-else href="#" strong raised large small fill
                  :disabled="value > selectedItem['Variant Inventory Qty'] ? true : false"
                  class="badge color-primary margin-right-half" @click="addToCart(selectedItem, value)">{{
                    $t("itemdetail.addToCart") }}</f7-button>
                <f7-button href="#" strong raised large small fill
                  :disabled="value > selectedItem['Variant Inventory Qty'] ? true : false" class="badge color-red"
                  @click="buyNow(selectedItem, value)">{{ $t("itemdetail.buyNow") }}
                </f7-button>
              </div>
            </div>
          </div>
        </li>
      </f7-list-group>
    </f7-list>

    <div class="grid grid-cols-2">
      <f7-button raised fill color="auto" class="buttons" @click="addToCart(selectedItem, quantity)">{{
        $t("itemdetail.addToCart") }}
      </f7-button>

      <f7-button raised fill color="red" class="buttons" @click="buyNow(selectedItem, quantity)">{{
        $t("itemdetail.buyNow") }}
      </f7-button>
    </div>
    <!-- <f7-list dividers-ios strong-ios accordion-list>
        <f7-list-item accordion-item :title="$t('itemdetail.itemDescription')">
          <f7-accordion-content>
            <f7-block>
              <b></b>
              <p v-html="this.selectedItems[0]['Body (HTML)']"></p>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
    </f7-list>-->

    <f7-block-title style="font-weight: bold" divider>{{
      $t("itemdetail.otherItemsInStore")
    }}</f7-block-title>
    <span style="padding-bottom: 200px">
      <swiper-container :speed="500" :slides-per-view="this.isTablet == true ? 6 : 3" :space-between="5" :loop="true"
        :navigation="this.isTablet == true ? true : false" :autoplay="true" :delay="1000">
        <swiper-slide v-for="(item, index) in this.selectedStore.slice(0, visibleItem)" :key="index"
          class="dealsContainer">
          <a class="text-color-black" @click="itemUrl(item)" style="height: 150px">
            <img :src="item.ImageSrc" v-if="!!item.ImageSrc" style="object-fit: cover; width: 100%; height: 150px" />
          </a>
          <div class="no-margin-vertical margin-horizontal-half" style="font-size: 75%; height: 50px">
            <p class="no-margin-vertical">
              <b>{{ item.Title }}</b>
            </p>
            <p class="no-margin-top">
              €{{ (item["Variant Price"] * 0.8).toFixed(2) }} EUR
            </p>
          </div>
          <div class="grid cols40_60 padding-bottom">
            <f7-link class="padding-left-half" icon-ios="f7:cart_badge_plus" icon-aurora="f7:cart_badge_plus"
              icon-md="material:add_shopping_cart" color="auto" @click="addToCart(selectedItem, quantity)"></f7-link>

            <f7-button small raised fill color="red" style="font-size: 75%" @click="buyNow(selectedItem, quantity)">{{
              $t("itemdetail.buyNow") }}</f7-button>
          </div>
        </swiper-slide>
      </swiper-container>
    </span>
    <f7-popup class="itemImage-popup" swipe-to-close backdrop tablet-fullscreen
      :style="{ '--f7-sheet-bg-color': '#fff', height: isTablet ? '100%' : 'auto' }">
      <f7-page>
        <f7-navbar :title="this.selectedItems[0].Title" no-shadow>
          <f7-nav-right>
            <f7-link popup-close icon-f7="xmark_circle"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block class="itemImagePopupContainer">
          <swiper-container pagination>
            <swiper-slide v-for="(item, index) in this.selectedItems" :key="index">
              <img :src="item.ImageSrc" v-if="!!item.ImageSrc" height="auto" />
            </swiper-slide>
          </swiper-container>
        </f7-block>
      </f7-page>
    </f7-popup>
    <f7-sheet class="itemTypePopup" swipe-to-close backdrop tablet-fullscreen
      :style="{ '--f7-sheet-bg-color': '#fff', height: isTablet ? '100%' : 'auto' }">
      <f7-page-content>
        <f7-navbar :title="this.selectedItems[0].Title" no-shadow>
          <f7-nav-right>
            <f7-link sheet-close icon-f7="xmark_circle"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <div class="grid grid-cols-2">
            <span class="itemImageContainerPopup" v-for="(item, index) in this.selectedItems" :key="index"
              @click="selectItemType(item)">
              <div class="margin-vertical" style="height: 50px" v-if="!!item['Option1 Value'] ||
                (!!item['Option2 Value'] && item['Variant Inventory Qty'] > 0)
                ">
                <p class="no-margin-vertical" v-if="!!item['Option1 Value']">
                  {{ item["Option1 Name"] }} {{ item["Option1 Value"] }}
                </p>
                <p class="no-margin-vertical" v-if="!!item['Option2 Value']">
                  {{ item["Option2 Name"] }} {{ item["Option2 Value"] }}
                </p>
                <p class="no-margin-vertical" v-if="!!item['Variant Price']">
                  {{ $t("itemdetail.price") }}€{{ item["Variant Price"] }} EUR
                </p>
                <p class="no-margin-vertical">
                  <i v-if="item['Variant Inventory Qty'] > 0">{{
                    $t("itemdetail.inventory", {
                      itemInv: item["Variant Inventory Qty"],
                    })
                  }}</i>
                  <i v-else class="no-margin-vertical text-color-red">{{
                    $t("itemdetail.notAvailable")
                  }}</i>
                </p>
              </div>
              <img :src="item.ImageSrc" width="90%" style="object-fit: cover; width: 100%" />
            </span>
          </div>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
    <f7-toolbar tabbar bottom labels text-color="white" class="no-padding-horizontal">
      <f7-link class="no-padding-left link buuton buttons" bg-color="primary" raised large small fill ripple strong
        text-color="white" @click="addToCart(selectedItem, quantity)">{{ $t("itemdetail.addToCart") }}</f7-link>

      <f7-link raised large small fill ripple strong class="no-padding-right link button buttons" text-color="white"
        bg-color="red" @click="buyNow(selectedItem, quantity)">{{ $t("itemdetail.buyNow") }}</f7-link>
    </f7-toolbar>
  </f7-page>
</template>
<style scoped>
/* .itemImageContainer { */
/* border-left: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4; */
/* } */
div.toolbar-inner {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* .itemImageContainer img {
  height: "fit-content";
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
} */
/* .itemImagePopupContainer img {
  height: "fit-content";
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.itemImagePopupContainer {
  height: 300px;
  width: 100%;
}
.itemImageContainerPopup img {
  height: "fit-content";
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
} */
.dealsContainer img {
  max-height: 150px;
  /* width: 150px; */
  margin: auto;
  display: block;
}
</style>
<script>
import Navbarright from "../components/navbarright.vue";
import apparel from "../js/data/apparel.json";
import apparel2 from "../js/data/apparel2.json";
import homeandgarden from "../js/data/homeandgarden.json";
import bicycles from "../js/data/bicycles.json";
import fashion from "../js/data/fashion.json";
import jewelry from "../js/data/jewelry.json";
// import jewelry2 from "../js/data/jewelry2.json";
import snowdevil from "../js/data/snowdevil.json";

import { mapGetters, mapState } from "vuex";
import { shopping } from "../js/mixins/shopping.js";
import { f7, theme } from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [shopping],
  data() {
    return {
      theme,
      f7,
      title: "<span class='avocash'>avo¢ash</span> stores",
      selectedItem: "",
      quantity: 1,
      visibleItem: 8,
      storeId: this.f7route.params.storeId,
      apparel: apparel,
      apparel2: apparel2,
      bicycles: bicycles,
      fashion: fashion,
      jewelry: jewelry,
      // jewelry2: jewelry2,
      homeandgarden: homeandgarden,
      snowdevil: snowdevil,
      currencySymbol: "€",
      avocashStores: [
        {
          storeId: 1,
          storeName: "HeShop",
          storeSource: apparel,
          description: "Men shop",
          socBuy: {
            5: 3,
            10: 5,
            15: 7.5,
            20: 10,
          },
        },
        {
          storeId: 2,
          storeName: "Weshop",
          storeSource: apparel2,
          description: "Men & Women shop",
          socBuy: {
            5: 5,
            10: 7,
            15: 10,
            20: 12.5,
          },
        },
        {
          storeId: 3,
          storeName: "Velo",
          storeSource: bicycles,
          description: "bicycles",
          socBuy: {
            5: 2.5,
            10: 4,
            15: 7,
            20: 10,
          },
        },
        {
          storeId: 4,
          storeName: "Fashionist",
          storeSource: fashion,
          description: "Men & Women shop",
          socBuy: {
            5: 5,
            10: 8,
            15: 10,
            20: 12.5,
          },
        },
        {
          storeId: 5,
          storeName: "BijouX",
          storeSource: jewelry,
          description: "Lux jewelry",
          socBuy: {
            5: 2.5,
            10: 4,
            15: 7,
            20: 10,
          },
        },
        // {
        //   storeId: 6,
        //   storeName: "Accessoires",
        //   storeSource: jewelry2,
        //   description: "Fashion accessoiries"
        // },
        {
          storeId: 7,
          storeName: "Home&Garden",
          storeSource: homeandgarden,
          description: "Home and garden",
          socBuy: {
            5: 5,
            10: 8,
            15: 10,
            20: 12.5,
          },
        },
        {
          storeId: 8,
          storeName: "skiHigh",
          storeSource: snowdevil,
          description: "Ski wear & accessories",
          socBuy: {
            5: 5,
            10: 7,
            15: 10,
            20: 12.5,
          },
        },
      ],
      itemPhotos: [],
      selectedImg: "",
    };
  },

  mounted() {
    const self = this;

    this.selectedItem = this.selectedItems[0];
    _.forEach(this.selectedItems, (element) => {
      if (!!element.ImageSrc) {
        this.itemPhotos.push(element.ImageSrc);
      }
    });
    if (this.itemPhotos && this.itemPhotos.length > 0) {
      this.selectedImg = this.itemPhotos[0];
    }
  },
  methods: {
    loadMore(done) {
      const self = this;
      setTimeout(() => {
        this.visibleItem = this.visibleItem + 8;
        done();
      }, 1000);
    },

    itemImagePopupOpen() {
      const self = this;

      f7.popup.open(".itemImage-popup");
    },
    itemUrl(item) {
      const self = this;

      console.log(item);
      f7.sheet.close(".itemTypePopup");
      f7.sheet.close(".itemImage-popup");
      this.f7router.navigate("/itemDetail/" + item.storeId + "/" + item.Handle, {
        reloadCurrent: true,
        ignoreCache: true,
      });
    },
    selectItemType(item) {
      const self = this;

      this.selectedItem = item;
      console.log(this.selectedItem);
      // _.forEach(this.selectedItems, (element) => {
      //   if (!!element.ImageSrc) {
      //     this.itemPhotos.push(element.ImageSrc);
      //   }
      // });
      // console.log(this.itemPhotos);
      f7.sheet.close(".itemTypePopup");
    },
    selectImg(item) {
      const self = this;

      this.selectedImg = item;
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy popup when page removed
      // if (self.popup) self.popup.destroy();
    },
  },

  computed: {
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    selectedItems() {
      if (this.currentStore) {
        return _.filter(
          this.currentStore.storeSource,
          (item) => item.Handle === this.f7route.params.itemId
        );
      }
    },

    // selectedStoreWithTitle() {
    //   if (this.currentStore) {
    //     return _.filter(this.currentStore.storeSource, o => o.Title !== "");
    //   }
    // },

    selectedStore() {
      if (this.currentStore) {
        return _.chain(this.currentStore.storeSource)
          .filter((item) => item.Title !== "")
          .shuffle()
          .value();
      }
    },
    currentStore() {
      const self = this;

      const id = this.f7route.params.storeId;
      for (var i = 0; i < this.avocashStores.length; i += 1) {
        if (this.avocashStores[i].storeId == id) {
          return this.avocashStores[i];
        }
      }
    },
  },
};
</script>
