<template>
  <f7-panel right cover resizable @panel:opened="reducePageWidth" @panel:close="resetPageWidth" :backdrop="false"
    class="contactDetailPanel" :style="{
      'min-width': f7.width >= 768 ? '418px' : '100%',
      'min-width': f7.width >= 768 ? '418px' : '100%',
      'border-left':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
    }">
    <f7-page page-with-subnavbar>
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link panel-close>
            <f7-icon ios="f7:multiply" aurora="f7:multiply" md="material:close"></f7-icon></f7-link>
        </f7-nav-left>
        <div class="title" v-if="this.groupType === 'PERSONAL'">
          {{
            this.counterPartUser.name ||
            this.counterPartUser.username ||
            this.counterPartUser.tag ||
            this.counterPartUser.phoneNumber
          }}
        </div>
        <div class="title" v-else>
          {{ this.group.name || this.groupName }}
        </div>
      </f7-navbar>
      <f7-list dividers-ios strong-ios accordion-list :no-chevron="!isGroupAdmin ? true : false"
        v-if="this.groupType === 'GROUP' || this.groupType === 'SUBGROUP'">
        <f7-list-group>
          <f7-link v-if="this.chatGroupAdminList &&
            this.chatGroupAdminList.length > 0 &&
            this.isGroupAdmin &&
            (this.group.groupType === 'GROUP' || this.group.groupType === 'SUBGROUP')
            " class="editInput" @click="selectDocument('img')" icon-aurora="f7:pencil" icon-ios="f7:pencil"
            icon-md="material:edit" style="position: absolute; right: 16px; top: 0px" href="#"></f7-link>

          <div style="height: 200px" class="padding-vertical" v-if="this.documentsUrl.length == 0 || this.documentsUrl[0].fileType !== 'image'
            ">
            <img v-if="this.group.avatar !== null" :src="`data:image/png;base64,${this.group.avatar}`"
              style="display: block; margin: 0 auto; max-height: 100%" />

            <f7-badge v-else-if="this.group.avatar === null && this.group.name !== null" color="auto" style="
                height: 100px;
                width: 100px;
                font-size: xxx-large;
                border-radius: 50%;
                display: block;
                margin: 0 auto;
              ">{{ $filtersGetInitial.getInitial(this.group.name) }}</f7-badge>
          </div>
          <div v-else v-for="(docItem, index) in this.documentsUrl" :key="index" style="height: 200px"
            class="padding-vertical">
            <span class="attachmentName" v-if="docItem.fileType === 'image' && docItem.url" :footer="docItem.name"></span>
            <img :src="docItem.url" v-if="docItem.fileType === 'image'"
              style="display: block; margin: 0 auto; max-height: 100%" alt="user avatar" />
            <f7-link v-if="docItem.fileType === 'image'" raised style="position: absolute; right: 16px; top: 0px"
              icon-f7=" pencil_circle" @click="editImage(docItem.id)"></f7-link>
            <f7-link @click="removeDoc(index)" style="position: absolute; left: 16px; top: 0px" raised color="red"
              icon-f7="multiply_circle_fill"></f7-link>
            <f7-link class="editInput" @click="selectDocument('img')" icon-aurora="f7:pencil" icon-ios="f7:pencil"
              icon-md="material:edit" href="#"></f7-link>
          </div>
        </f7-list-group>
        <f7-list-group>
          <li class="item-content item-input" :style="{
            'margin-top':
              this.documentsUrl &&
                this.documentsUrl.length > 0 &&
                this.documentsUrl[0].url
                ? '100px'
                : '0px',
          }">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input id="nameInput" :disabled="this.groupNameDisabled" type="text" style="
                    font-size: var(--f7-block-title-medium-font-size);
                    text-transform: var(--f7-block-title-medium-text-transform);
                    color: var(--f7-block-title-medium-text-color);
                    font-weight: var(--f7-block-title-medium-font-weight);
                    line-height: var(--f7-block-title-medium-line-height);
                  " :value="this.group.name || this.groupName" @input="groupName = $event.target.value" />
                <f7-link v-if="this.groupNameDisabled == true &&
                  this.chatGroupAdminList &&
                  this.chatGroupAdminList.length > 0 &&
                  this.isGroupAdmin &&
                  (this.group.associatedId === '' ||
                    this.group.groupType === 'GROUP' ||
                    this.group.groupType === 'SUBGROUP')
                  " @click="editGroupName()" class="editInput padding-left-half" icon-aurora="f7:pencil"
                  icon-ios="f7:pencil" icon-md="material:edit" style="position: absolute; right: 0px; top: 8px"
                  href="#"></f7-link>
                <f7-link v-if="this.groupNameDisabled == false &&
                  this.chatGroupAdminList &&
                  this.chatGroupAdminList.length > 0 &&
                  this.isGroupAdmin &&
                  (this.group.associatedId === '' ||
                    this.group.groupType === 'GROUP' ||
                    this.group.groupType === 'SUBGROUP')
                  " @click="saveGroupName()" class="editInput" icon-aurora="f7:checkmark_alt"
                  icon-ios="f7:checkmark_alt" icon-md="material:check" style="position: absolute; right: 0px; top: 8px"
                  href="#"></f7-link>
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-input-wrap">
                <input type="text" id="descInput" :disabled="this.descDisabled"
                  :value="this.group.description || this.group.about || this.description"
                  @input="description = $event.target.value" />
                <f7-link v-if="this.descDisabled == true &&
                  this.chatGroupAdminList &&
                  this.chatGroupAdminList.length > 0 &&
                  this.isGroupAdmin &&
                  (this.group.associatedId === '' ||
                    this.group.groupType === 'GROUP' ||
                    this.group.groupType === 'SUBGROUP')
                  " class="editInput padding-left-half" @click="editDescription()" icon-aurora="f7:pencil"
                  icon-ios="f7:pencil" icon-md="material:edit" style="position: absolute; right: 0px; top: 8px"
                  href="#"></f7-link>
                <f7-link v-if="this.descDisabled == false &&
                  this.chatGroupAdminList &&
                  this.chatGroupAdminList.length > 0 &&
                  this.isGroupAdmin &&
                  (this.group.associatedId === '' ||
                    this.group.groupType === 'GROUP' ||
                    this.group.groupType === 'SUBGROUP')
                  " @click="saveDesc()" class="editInput" icon-aurora="f7:checkmark_alt" icon-ios="f7:checkmark_alt"
                  icon-md="material:check" style="position: absolute; right: 0px; top: 8px" href="#"></f7-link>
              </div>
            </div>
          </li>
          <f7-block-footer v-if="this.chatGroupParticipantList && this.chatGroupParticipantList.length > 0
            "><span v-if="this.group.createdBy !== null">{{ $t("tamtammessage.createdBy") }}
              {{
                this.chatGroupParticipantList.find(
                  (x) => x.userId === this.group.createdBy
                ).name
              }}</span><span v-if="this.group.created !== null" class="padding-left-half">
              {{ this.group.created.substr(0, 10) }}</span></f7-block-footer>
        </f7-list-group>
        <f7-list-group>
          <f7-block-title small v-if="this.chatGroupParticipantList &&
            this.chatGroupParticipantList.length > 0 &&
            this.groupType !== 'PERSONAL'
            ">{{ this.group.groupParticipantList.length }}
            {{ $t("tamtammessage.participants") }}</f7-block-title>
          <f7-list-item v-if="this.isGroupAdmin &&
            (this.group.associatedId === '' ||
              this.group.groupType === 'GROUP' ||
              this.group.groupType === 'SUBGROUP')
            " link="#" :title="$t('tamtammessage.addNewParticipant')"
            @click="addParticipantGroupRightPanel()"><template #media>
              <f7-badge color="auto" style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">
                <f7-icon ios="f7:person_crop_circle_fill_badge_plus" aurora="f7:person_crop_circle_fill_badge_plus"
                  md="material:person_add"></f7-icon></f7-badge></template>
          </f7-list-item>
          <!-- <span class="cols95_05"> -->
          <f7-searchbar class="no-shadow searchbar-inline margin-horizontal" search-container=".chatParticipantsList"
            search-in=".item-title, .item-subtitle, .item-footer, .item-text" :disable-button="!theme.aurora"
            :placeholder="$t('tamtammessage.searchParticipant')" :backdrop="false"></f7-searchbar>
          <!-- <span></span> -->
          <!-- </span> -->
          <f7-list dividers-ios strong-ios class="searchbar-not-found">
            <f7-list-item :title="$t('common.nothingFound')"></f7-list-item>
          </f7-list>
        </f7-list-group>

        <f7-list-group class="chatParticipantsList searchbar-found" v-if="this.group.groupParticipantList && this.group.groupParticipantList.length > 0
          ">
          <f7-list-item accordion-item v-for="(participant, index) in this.group.groupParticipantList.slice(0, 20)"
            :key="index" :title="participant.name" :text="participant.tag"><template #after>
              <f7-badge v-if="chatGroupAdminList &&
                chatGroupAdminList.length > 0 &&
                chatGroupAdminList.find((x) => x.userId === participant.userId)
                " color="auto">{{ $t("common.admin") }}</f7-badge>
              <span v-if="!isGroupAdmin">{{ participant.tag }}</span></template>
            <template #footer>
              <span :style="participant.online == true
                  ? 'color: var(--f7-theme-color)'
                  : 'color:red'
                ">{{ participant.online == true ? "online" : "offline" }}</span></template>

            <f7-accordion-content v-if="chatGroupAdminList &&
              chatGroupAdminList.length > 0 &&
              isGroupAdmin &&
              participant.userId !== profile.sub &&
              (group.associatedId === '' || group.groupType === 'SUBGROUP')
              ">
              <f7-button raised fill small large ripple color="red" class="margin-horizontal margin-vertical-half"
                :text="$t('tamtammessage.removeAsParticipant')" @click="removeParticipant(participant)">
              </f7-button>
              <f7-button raised fill small large ripple color="green" class="margin-horizontal margin-vertical-half"
                @click="grantAdminRole(participant)" :text="$t('tamtammessage.grantAdminRight')"
                v-if="!chatGroupAdminList.find((x) => x.userId === participant.userId)">
              </f7-button>
              <f7-button v-if="chatGroupAdminList.find((x) => x.userId === participant.userId)" raised fill small large
                ripple color="orange" class="margin-horizontal margin-vertical-half"
                @click="withdrawAdminRole(participant)" :text="$t('tamtammessage.withdrawAdminRight')">
              </f7-button>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list-group>
        <f7-list-group>
          <f7-list-item v-if="this.group.associatedId === '' ||
            this.group.groupType === 'GROUP' ||
            this.group.groupType === 'SUBGROUP'
            " link="#" :title="$t('tamtammessage.exitGroup')" @click="leaveGroup(group)" color="red"><template #media>
              <f7-badge color="red" style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">
                <f7-icon aurora="f7:square_arrow_left" ios="f7:square_arrow_left"
                  md="f7:square_arrow_left"></f7-icon></f7-badge></template>
          </f7-list-item>
          <f7-list-item link="#" :title="$t('tamtammessage.reportGroup')">
            <template #media><f7-badge color="red"
                style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">
                <f7-icon aurora="f7:hand_thumbsdown" ios="f7:hand_thumbsdown"
                  md="material:thumb_down"></f7-icon></f7-badge></template>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <f7-list dividers-ios strong-ios v-else>
        <f7-list-group v-if="this.counterPartUser">
          <div style="height: 200px" class="padding-vertical">
            <!-- <f7-card-content :padding="false"> -->
            <img v-if="this.counterPartUser.avatar !== null" :src="`data:image/png;base64,${this.counterPartUser.avatar}`"
              style="display: block; margin: 0 auto; max-height: 100%" />

            <f7-badge v-else color="auto" style="
                height: 75px;
                width: 75px;
                font-size: xxx-large;
                border-radius: 50%;
                display: block;
                margin: 0 auto;
              ">{{ $filtersGetInitial.getInitial(this.counterPartUser.name) }}</f7-badge>
          </div>
          <f7-list-item :title="this.counterPartUser.name"> </f7-list-item>
          <f7-list-item :title="'+' + this.counterPartUser.phoneNumber" :after="this.counterPartUser.tag"><template
              #footer>
              <span :style="this.counterPartUser.online == true
                  ? 'color:var(--f7-theme-color)'
                  : 'color:red'
                ">{{ this.counterPartUser.online == true ? "online" : "offline" }}</span></template>
          </f7-list-item>
        </f7-list-group>
        <f7-list-group>
          <f7-list-item v-if="this.counterPartUser" link="#" :title="$t('tamtammessage.blockUser')" color="red"
            @click="blockUser(counterPartUser)"><template #media>
              <f7-icon aurora="f7:multiply_circle_fill" ios="f7:multiply_circle_fill" md="material:cancel"
                color="red"></f7-icon></template>
          </f7-list-item>
          <f7-list-item v-if="this.group.associatedId === '' || this.group.groupType === 'SUBGROUP'" link="#"
            :title="$t('tamtammessage.deleteChat')" @click="deleteChat(group)">
            <template #media>
              <f7-icon aurora="f7:trash_fill" ios="f7:trash_fill" md="material:delete" color="red"></f7-icon></template>
          </f7-list-item>

          <f7-list-item link="#" :title="$t('tamtammessage.reportUser')">
            <template #media>
              <f7-icon aurora="f7:hand_thumbsdown" ios="f7:hand_thumbsdown" md="material:thumb_down"
                color="red"></f7-icon></template>
          </f7-list-item>
        </f7-list-group>
        <f7-list-group v-if="this.commonGroups && commonGroups.length > 0">
          <f7-list-item divider :title="$t('tamtammessage.groupInCommon') + commonGroups.length"></f7-list-item>
          <f7-list-item v-for="(comGroup, index) in this.commonGroups" :key="index"
            :link="'/message/' + comGroup.id + '/' + comGroup.type" :title="commonGroups.name"></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-page>
  </f7-panel>
  <editDocs></editDocs>
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { cordovaCamera } from "../../js/mixins/cordovaCamera.js";
import { attachDocs } from "../../js/mixins/attachDocs.js";
import editDocs from "../../components/editDocs.vue";
import { messageOptionsMixin, messageGroupMixin } from "../../js/mixins/messageMixins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    groupType: String,
    chatGroupAdminList: Object,
    isGroupAdmin: Boolean,
    commonGroups: Object,
    chatGroupParticipantList: Object,
  },
  mixins: [messageOptionsMixin, messageGroupMixin, cordovaCamera, attachDocs],
  components: {
    editDocs,
  },
  data() {
    return {
      theme,
      f7,
      groupNameDisabled: true,
      description: "",
      descDisabled: true,
    };
  },
  mounted() {
    if (this.documentsUrl && this.documentsUrl.length > 0) {
      this.$store.dispatch("auth/clearDocumentsUrl");
    }
  },
  // methods: {

  // },

  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
      "documentsUrl",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
  },
};
</script>
