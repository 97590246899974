import { f7, theme } from "framework7-vue";
import _ from "lodash";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.min.css";
import "intl-tel-input/build/js/intlTelInput.js";
export const finalPayment = {
  mounted() {
    window.addEventListener("resize", () => {
      if (f7.width > 768 && this.network_main && this.network_main.length < 4) {
        const fisrtItem = this.network_sec[0];
        this.network_main.push(fisrtItem);
        this.network_sec.splice(0, 1);
      } else if (f7.width <= 768 && this.network_main.length > 3) {
        const lastItem = this.network_main[this.network_main.length - 1];
        this.network_main.splice(this.network_main.length - 1, 1);
        this.network_sec.unshift(lastItem);
      }
    });

    console.log(
      " transfer_detail query",
      this.$encodeData.decode(this.f7route.query.transfer_detail)
    );
    const transfer_detail = this.$encodeData.decode(
      this.f7route.query.transfer_detail
    );
    this.payment_detail = transfer_detail[0];
    this.baseTransfer = transfer_detail[1];
    this.transferDetail = transfer_detail[1];
    console.log("paymentdetail query", transfer_detail[0]);
    console.log("basetransfer query", transfer_detail[1]);

    // if (this.publishableKey === null) {
    this.$store.dispatch("stripe/getStripePublicKey");
    // }
    this.selectedCountry = this.senderCountry.countryCode2;
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
      // this.selectedCountry = this.profile.countryCode;
      this.telephone =
        this.selectedCountry === this.profile.countryCode
          ? this.profile.upn
          : "";
    } else {
      // this.selectedCountry = this.userAccount.countryCode;
      this.telephone =
        this.selectedCountry === this.userAccount.countryCode
          ? this.userAccount.username
          : "";
    }

    if (this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch(
        "apimoney/getAllCreditCards",
        this.userAccount.standard.id
      );
    }
    if (
      this.baseTransfer.receiver_wallet_id !== null &&
      this.payment_detail &&
      !this.payment_detail.wallet
    ) {
      this.$store.dispatch("apimoney/getApiWallet", {
        walletId: this.baseTransfer.receiver_wallet_id,
      });
    }
    if (
      this.baseTransfer &&
      this.userAccount &&
      this.baseTransfer.payer_account_id !== this.userAccount.standard.id &&
      this.baseTransfer.payer_account_id !== this.userAccount.business.id &&
      this.baseTransfer.receiver_wallet_id !== null &&
      this.userDetail === ""
    ) {
      this.$store.dispatch("account/loadUser", {
        walletId: this.baseTransfer.receiver_wallet_id,
        type: "walletId",
      });
    }

    this.countryCode = this.userDetail.countryCode;
    if (
      this.userDetail.standard &&
      this.userDetail.standard.id === this.baseTransfer.payer_account_id
    ) {
      this.telephone =
        this.selectedCountry === this.userDetail.countryCode
          ? this.userDetail.standard.standard_info.phone_number
          : "";
      this.firstName =
        this.userDetail.standard.standard_info.subscriber.firstname;
      this.lastName =
        this.userDetail.standard.standard_info.subscriber.lastname;
      this.address = `${this.userDetail.standard.address.label1} ${this.userDetail.standard.address.label2} ${this.userDetail.standard.address.label3} ${this.userDetail.standard.address.city} ${this.userDetail.standard.address.zip_code} ${this.userDetail.standard.address.country}`;
    } else if (
      this.userDetail.business &&
      this.userDetail.business.id === this.baseTransfer.payer_account_id
    ) {
      this.telephone =
        this.selectedCountry === this.userDetail.countryCode
          ? this.userDetail.business.business_info.phone_number
          : "";
      this.firstName = this.userDetail.business.business_info.name;
      this.lastName = this.userDetail.business.business_info.description;
      this.address = `${this.userDetail.business.address.label1} ${this.userDetail.business.address.label2} ${this.userDetail.business.address.label3} ${this.userDetail.business.address.city} ${this.userDetail.business.address.zip_code} ${this.userDetail.business.address.country}`;
    } else {
      this.telephone =
        this.selectedCountry === this.userDetail.countryCode
          ? this.userDetail.username
          : "";
    }

    // this.paymentCurrency = this.entity.currencyCode;
    console.log("this.userDetail", this.userDetail);
    this.tag = this.baseTransfer.tag;
    this.description = this.baseTransfer.description;
    if (this.payment_detail && this.payment_detail.paymentCurrency !== "") {
      this.paymentCurrency = this.payment_detail.paymentCurrency;
    } else if (this.userAccount && this.userAccount.currencyCode !== "") {
      this.paymentCurrency = this.userAccount.currencyCode;
    } else {
      this.paymentCurrency = this.profile.currencyCode;
    }

    // this.triggerStripePayment();

    const phoneNumberInput = document.querySelector("#phoneNumber");
    if (this.selectedCountry) {
      this.itiPhoneNumber = intlTelInput(phoneNumberInput, {
        separateDialCode: true,
        nationalMode: true,
        initialCountry: this.selectedCountry,
        localizedCountries: this.selectedCountry,
        dropdownContainer: document.body,
        autoInsertDialCode: true,
        preferredCountries: [],
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.6/js/utils.js",
      });
      console.log(this.itiPhoneNumber);
      window.intlTelInputGlobals.loadUtils(
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.6/js/utils.js"
      );

      phoneNumberInput.addEventListener("blur, change, keyup", function () {
        if (this.itiPhoneNumber.isValidNumber()) {
          this.telephone = this.itiPhoneNumber.getNumber(
            intlTelInputUtils.numberFormat.NATIONAL
          );
          console.log(
            "Intern telephone ",
            this.itiPhoneNumber.getNumber().replace("+", "")
          );
        } else {
          console.log("phone number not valid");
        }
      });
      if (this.itiPhoneNumber !== "") {
        this.itiPhoneNumber.setCountry(this.selectedCountry);
        this.changePhoneNumber();
      }
    }

    const smartSelect = f7.smartSelect.get(".selectCountry .smart-select");
    f7.$(".iti__flag-container").click(function () {
      smartSelect.open();
      if (self.telephone !== "") {
        self.telephone = "";
      }
      smartSelect.setValue(self.selectedCountry);
    });

    f7.$(".selectCountry .smart-select").on("smartselect:close", () => {
      self.selectedCountry = smartSelect.getValue();
      console.log("self.selectedCountry ", self.selectedCountry);
      // self.itiPhoneNumber.setCountry(self.selectedCountry);
    });

    self = this;
    if (f7.height >= 400) {
      const height1 = `${f7.height / 2}px`;
      const root = document.documentElement;
      root.style.setProperty("--f7-keypad-inline-height", height1);
    }
    const transactAuthNumpad = f7.keypad.create({
      inputEl: "#auth-numpad-inline",
      containerEl: "#auth-inline-container",
      toolbar: false,
      valueMaxLength: 4,
      dotButton: false,
      formatValue(value) {
        value = value.toString();
        return (
          "****".substring(0, value.length) +
          "____".substring(0, 4 - value.length)
        );
      },
      on: {
        change(keypad, value) {
          console.log(keypad, value, self, f7);
          value = value.toString();
          if (value.length === 4) {
            self.numberOfTril--;
            const PIN = self.profile.PIN;
            if (PIN && value === PIN) {
              f7.popup.close(".authLoginScreen");
              self.successCallback();
            } else {
              f7.dialog.alert(
                self.$t("common.incorrectPIN", {
                  leftTrials: self.numberOfTril,
                }),
                () => {
                  f7.dialog.close();
                  keypad.setValue("____");
                }
              );
            }
            if (self.numberOfTril === 0) {
              f7.dialog.alert(self.$t("common.noMoreTrials"), () => {
                f7.dialog.close();
                keypad.setValue("____");
                f7.popup.close(".authLoginScreen");
              });
            }
          }
        },
      },
    });
    const dotCharacter = f7.$(
      '<i class="icon" style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)"><img src="/34x34.png" width="85%" height="85%"/></i>'
    );
    if (transactAuthNumpad) {
      dotCharacter.appendTo(".keypad-dummy-button");
    }
  },
  methods: {
    selectMethod(value) {
      console.log(value);
      switch (value) {
        case "avocash":
          f7.smartSelect.open(".selectApiWalet");
          this.sender_wallet_id = this.baseTransfer.sender_wallet_id
            ? this.baseTransfer.sender_wallet_id
            : this.userAccount.standardWalletId;
          break;
        case "banktransfer":
          f7.smartSelect.open(".selectBank");
          break;
        case "mobilemoney":
          this.selectedCountry = this.senderCountry.countryCode2;
          break;
        case "creditcard":
          // if(this.userAccount.userCreditCards && (this.userAccount.userCreditCards.STANDARD.length > 0 || this.userAccount.userCreditCards.BUSINESS.length > 0){
          //   this.selected_CreditCardId=this.userAccount.standardCreditCard;
          // }else{
          //   this.f7router.navigate("/bankCard");
          // }
          break;
      }
    },
    changeChannel() {
      const useOTP = f7.toggle.get(".use-otp");
      this.channel = useOTP.checked ? "ussd" : "web";
    },
    resetMethod() {
      this.selected_method = "";
    },
    preConfirm() {
      f7.preloader.show("multi");
      if (
        f7.input.validateInputs("#noPaymentDetails") && // ((this.userDetail.standard && this.userDetail.standard.stripeAccountId !== "") ||
        //   (this.userDetail.business &&
        //     this.userDetail.business.stripeAccountId !== "")) &&
        ((this.baseTransfer.amount > 1 &&
          this.baseTransfer.description !== "" &&
          this.baseTransfer.tag !== "") ||
          (this.amount > 1 && this.description !== "" && this.tag !== ""))
      ) {
        if (
          this.publishableKey !== "" &&
          this.userPaymentProvider === "stripe"
        ) {
          this.payWithBizaoCreditCard();
          setTimeout(() => {
            this.$refs.stripeComponent.triggerStripePayment();
            this.payment_options = {
              mode: "payment",
              currency: this.transferDetail.currency,
              amount:
                this.baseTransfer.amount > 0
                  ? this.baseTransfer.amount
                  : this.amount,
            };
          }, 1000);
          this.detail_confirmed = true;
        } else if (this.userPaymentProvider === "bizao") {
          this.detail_confirmed = true;
          this.selectedCountry = this.senderCountry.countryCode2;
          this.splitNetWork();
        }
        this.transferDetail = {
          amount:
            this.baseTransfer.amount > 0
              ? this.baseTransfer.amount
              : this.amount,
          currency: this.transferDetail.currency,
          description: this.baseTransfer.description
            ? this.baseTransfer.description
            : this.description,
          tag: this.baseTransfer.tag ? this.baseTransfer.tag : this.tag,
        };
      }
      f7.preloader.hide();
    },
    splitNetWork() {
      const netLength = this.isTablet ? 4 : 3;
      this.network_main = this.mobileNetwork?.slice(
        0,
        Math.min(netLength, this.mobileNetwork.length)
      );

      this.network_sec =
        this.mobileNetwork && this.mobileNetwork.length > netLength
          ? this.mobileNetwork.slice(netLength)
          : [];
      if (this.mobileNetwork && this.mobileNetwork.length > 0) {
        this.selectedMthd = this.mobileNetwork[0];
        // this.selectPaymentMethod(this.selectedMthd, this.selectedMthd.mthd);
      }
      console.log("this.network_main", this.network_main);
    },
    payWithBizaoMobileMoney() {
      // if (this.userAccount.stdPaymentLimit >= this.baseTransfer.amount) {
      let usermsisdn;
      if (this.telephone) {
        usermsisdn = this.itiPhoneNumber
          .getNumber(intlTelInputUtils.numberFormat.E164)
          .replace("+", "");
      } else if (
        this.selectedCountry === this.senderCountry.countryCode2 &&
        this.userPhoneNumber
      ) {
        usermsisdn = this.userPhoneNumber.replace("+", "");
      } else {
        usermsisdn = "";
      }
      const base_Transfer = {
        fees_wallet_id: null,
        fees: 0,
        sender_wallet_id:
          this.selected_method === "avocash" && this.sender_wallet_id !== null
            ? this.sender_wallet_id
            : this.baseTransfer.sender_wallet_id,
        payment_method: "MOBILE_MONEY",
        amount:
          this.baseTransfer.amount > 0 ? this.baseTransfer.amount : this.amount,
        tag: this.baseTransfer.amount > 0 ? this.baseTransfer.tag : this.tag,
        description:
          this.baseTransfer.amount > 0
            ? this.baseTransfer.description
            : this.description,
        trxType: "TRANSFER",
        mnoName: this.mnoName,
        channel: this.channel,
        otp_code: this.otp_code,
        receiverPhoneNumber: this.telephone,
        user_msisdn: usermsisdn,
        partner: this.mnoName === "wave" ? "wave" : "bizao",
        category: this.mnoName === "wave" ? "wave" : "bizao",
        lang: this.locale,
        state: "avocash Test",
        type: "",
        countryCode: this.senderCountry.countryCode2,
      };
      f7.utils.extend(this.baseTransfer, base_Transfer);
      console.log("this.baseTransfer", this.baseTransfer);
      console.log("this.countries", this.senderCountry);
      this.checkAuth();

      //   return;
      // }
      // const base_Transfer = {
      //   fees_wallet_id: null,
      //   fees: 0,
      //   auth_timeout_delay: 604_800, //7days
      //   sender_wallet_id:
      //     this.selected_method === "avocash" && this.sender_wallet_id !== null
      //       ? this.sender_wallet_id
      //       : this.baseTransfer.sender_wallet_id,
      //   payment_method: "TRANSFER",
      //   amount: this.baseTransfer.amount > 0 ? this.baseTransfer.amount : this.amount,
      //   tag: this.baseTransfer.amount > 0 ? this.baseTransfer.tag : this.tag,
      //   description:
      //     this.baseTransfer.amount > 0 ? this.baseTransfer.description : this.description,
      // };
      // f7.utils.extend(this.baseTransfer, base_Transfer);

      // this.$store.dispatch("apimoney/createTransferAuthorization", this.baseTransfer);
    },

    payWithBizaoCreditCard() {
      let usermsisdn;
      if (this.telephone) {
        usermsisdn = this.itiPhoneNumber
          .getNumber(intlTelInputUtils.numberFormat.E164)
          .replace("+", "");
      } else if (
        this.selectedCountry === this.senderCountry.countryCode2 &&
        this.userPhoneNumber
      ) {
        usermsisdn = this.userPhoneNumber.replace("+", "");
      } else {
        usermsisdn = "";
      }
      const base_Transfer = {
        fees_wallet_id: null,
        fees: 0,
        sender_wallet_id:
          this.selected_method === "avocash" && this.sender_wallet_id !== null
            ? this.sender_wallet_id
            : this.baseTransfer.sender_wallet_id,
        payment_method: "CREDIT_CARD",
        amount:
          this.baseTransfer.amount > 0 ? this.baseTransfer.amount : this.amount,
        tag: this.baseTransfer.amount > 0 ? this.baseTransfer.tag : this.tag,
        description:
          this.baseTransfer.amount > 0
            ? this.baseTransfer.description
            : this.description,
        trxType: "TRANSFER",
        mnoName: this.mnoName,
        channel: "web",
        user_msisdn: usermsisdn,
        partner: this.userPaymentProvider,
        category: this.userPaymentProvider,
        lang: this.locale,
        state: "avocash Test",
        type: "",
        countryCode: this.senderCountry.countryCode2,
      };
      f7.utils.extend(this.baseTransfer, base_Transfer);
      if (this.userPaymentProvider === "stripe") {
        this.$store.dispatch(
          "apimoney/initiateCreditCardCashIn",
          this.baseTransfer
        );
      } else if (this.userPaymentProvider === "bizao") {
        this.checkAuth();
      }
    },

    payWithavocash() {
      if (this.userAccount.stdPaymentLimit >= this.baseTransfer.amount) {
        const base_Transfer = {
          fees_wallet_id: null,
          fees: 0,
          sender_wallet_id:
            this.selected_method === "avocash" && this.sender_wallet_id !== null
              ? this.sender_wallet_id
              : this.baseTransfer.sender_wallet_id,
          payment_method: "TRANSFER",
          amount:
            this.baseTransfer.amount > 0
              ? this.baseTransfer.amount
              : this.amount,
          tag: this.baseTransfer.amount > 0 ? this.baseTransfer.tag : this.tag,
          description:
            this.baseTransfer.amount > 0
              ? this.baseTransfer.description
              : this.description,
          countryCode: this.senderCountry.countryCode2,
        };
        f7.utils.extend(this.baseTransfer, base_Transfer);
        this.$store.dispatch(
          "apimoney/executeSingleTransfer",
          this.baseTransfer
        );
        return;
      }
      const base_Transfer = {
        fees_wallet_id: null,
        fees: 0,
        auth_timeout_delay: 604_800, //7days
        sender_wallet_id:
          this.selected_method === "avocash" && this.sender_wallet_id !== null
            ? this.sender_wallet_id
            : this.baseTransfer.sender_wallet_id,
        payment_method: "TRANSFER",
        amount:
          this.baseTransfer.amount > 0 ? this.baseTransfer.amount : this.amount,
        tag: this.baseTransfer.amount > 0 ? this.baseTransfer.tag : this.tag,
        description:
          this.baseTransfer.amount > 0
            ? this.baseTransfer.description
            : this.description,
        countryCode: this.senderCountry.countryCode2,
      };
      f7.utils.extend(this.baseTransfer, base_Transfer);
      this.checkAuth();
    },

    cashOutAndTransfer() {
      switch (this.selected_method) {
      }
    },
    makeContribution() {
      switch (this.selected_method) {
        case "banktransfer":
          this.payWithBankAccount();
          break;
        case "creditcard":
          if (this.userPaymentProvider === "stripe") {
            this.payWithNewCreditCard();
          } else {
            this.payWithBizaoCreditCard();
          }
          break;
        case "avocash":
          this.payWithavocash();
          break;
        case "mobilemoney":
          this.payWithBizaoMobileMoney();
          break;

        default:
          break;
      }
    },
    selectPaymentMethod(method, provider) {
      let itemId;
      f7.$(`.selected`).removeClass("selected");
      switch (provider) {
        case "banktransfer":
          this.selected_method = "banktransfer";
          itemId = "banktransfer";
          break;
        case "avocash":
          this.selected_method = "avocash";
          itemId = provider;
          this.selectedMthd = {
            id: itemId,
            img: method.img,
            name: method.name,
            method: provider,
          };
          break;
        case "creditcard":
          this.selected_method = "creditcard";
          itemId = method.id;
          this.selectedMthd = method;
          break;
        case "mobilemoney":
          this.selected_method = "mobilemoney";
          itemId = method.id;
          this.mnoName = method.id;
          this.selectedMthd = method;
          this.selectedCountry = this.senderCountry.countryCode2;
      }
      console.log("method", method);
      setTimeout(() => {
        f7.$(`#${method.id}`).addClass("selected");
      }, 100);
    },

    selectExtranetwork(network) {
      const lastItem = this.network_main.pop();
      const newIndex = this.network_sec.indexOf(network);
      this.network_sec.splice(newIndex, 1);
      this.network_main.push(network);
      this.network_sec.push(lastItem);
      this.selectPaymentMethod(network, network.mthd);
    },

    openExtraNetwork() {
      const buttonsList = [];
      this.network_sec.forEach((network) => {
        buttonsList.unshift({
          text:
            network.mthd === "mobilemoney"
              ? network.name
              : this.$t(network.name),
          icon:
            network.icon ||
            `<img src="${network.img}" class="icon" width="24"/>`,
          onClick: () => this.selectExtranetwork(network),
        });
      });
      this.selectExtraNetworkList = f7.actions.create({
        convertToPopover: true,
        closeByOutsideClick: true,
        closeOnEscape: true,
        buttons: buttonsList,
        targetEl: this.$el.querySelector(`.extraNetworkListSelect`),
      });

      // Open
      this.selectExtraNetworkList.open();
    },
    isWalletSameZone(method) {
      const userApicode = this.userAccount.apiCode;
      const walletApicode = this.userDetail.apiCode;
      return (
        walletApicode &&
        userApicode &&
        walletApicode === userApicode &&
        method.includes(walletApicode)
      );
    },
    async changePhoneNumber() {
      if (this.telephone && this.telephone.length > 6) {
        await this.$store.dispatch("location/checkMobilePhone", {
          phone: this.telephone,
          countryCode2: this.selectedCountry,
        });
      }
    },
  },
  computed: {
    mobileNetwork() {
      const networks = this.payment_network.filter(
        (m) =>
          m.mkt.includes(this.senderCountry?.countryCode2) ||
          m.provider.includes(this.userPaymentProvider)
      );
      console.log("networks", networks);
      return networks.length > 0 ? networks : null;
    },

    // userPaymentMethod() {
    //   return this.payment_method.filter(
    //     (method) =>
    //       (method.mkt.includes(this.baseTransfer.countryCode) ||
    //         method.mkt.includes("all") || (method.method==='avocash' && isWalletSameZone)) &&
    //       method.type[this.payment_detail.transaction_type]
    //   );
    // },
    // userPaymentMethod() {
    //   const trx_tpe =
    //     this.payment_detail && this.payment_detail.transaction_type !== null
    //       ? this.payment_detail.transaction_type
    //       : "cash_in";
    //   return this.payment_method.filter(
    //     (method) =>
    //       (method.mkt.includes(this.baseTransfer.countryCode) ||
    //         method.mkt.includes("all") ||
    //         (method.method === "avocash" && this.isWalletSameZone(method.mkt)) ||
    //         (method.method === "banktransfer" && this.isWalletSameZone(method.mkt)) ||
    //         method.method === "mobilemoney") &&
    //       method.type[trx_tpe]
    //   );
    // },

    // selectedPaymentMetod() {
    //   return this.payment_method.find((m) => m.method === this.selected_method);
    // },
    selected_CreditCard() {
      const creditcards = _.concat(
        this.userAccount.userCreditCards.STANDARD,
        this.userAccount.userCreditCards.BUSINESS
      );

      return _.find(creditcards, { id: this.selected_CreditCardId });
    },
    selected_bank_account() {
      const bankAccounts = _.concat(
        this.userAccount.userBankAccounts.STANDARD,
        this.userAccount.userBankAccounts.BUSINESS
      );

      return _.find(bankAccounts, { id: this.selected_bank_account_id });
    },
    sender_wallet() {
      const walletTypes = ["STANDARD", "BUSINESS"];
      for (const type of walletTypes) {
        const foundWallet = _.find(this.userAccount.userAccountWallets[type], [
          "id",
          this.sender_wallet_id,
        ]);
        if (foundWallet) return foundWallet;
      }
      return null;
    },
    senderAccountLimit() {
      const accountLimit =
        this.accountType === "STANDARD"
          ? this.userAccount.stdPaymentLimit
          : this.userAccount.bsnPaymentLimit;
      return (
        this.baseTransfer.amount > accountLimit || this.amount > accountLimit
      );
    },
    accountType() {
      const accountId = this.userAccount.standardAccountId
        ? "STANDARD"
        : "BUSINESS";

      if (this.sender_wallet_id !== "") {
        this.sender_wallet.account_id = accountId;
      } else if (this.selected_bank_account_id !== "") {
        this.selected_bank_account.account_id = accountId;
      } else if (this.selected_CreditCardId !== "") {
        this.selected_CreditCard.account_id = accountId;
      }

      return accountId;
    },

    senderCountry() {
      const countryCode =
        this.apiWallet?.countryCode ||
        this.baseTransfer?.countryCode ||
        this.payment_detail.project?.countryCode ||
        this.payment_detail.entity?.countryCode ||
        this.userDetail?.countryCode ||
        this.userAccount?.countryCode ||
        this.selectedCountry;

      return this.country.find((c) => c.countryCode2 === countryCode);
    },

    userPaymentProvider() {
      const provider = this.emoneyApiList.find(
        (c) => c.countryCode === this.senderCountry?.countryCode2
      );
      return provider?.apiCode;
    },

    stripeAccountId() {
      if (this.userAccount.standard?.id) {
        return this.userAccount.standard?.stripeAccountId;
      }

      if (this.userAccount.business?.id) {
        return this.userAccount.business?.stripeAccountId;
      }

      return null;
    },
  },
};
export const payment = {
  mounted() {
    setInterval(() => {
      if (this.qrCodeData && this.qrCodeData.expireAt !== "") {
        const date = new Date();
        if (date > parseInt(this.qrCodeData.expireAt)) {
          this.deleteQRCode();
          this.f7router.back();
        }
      }
    }, 30000);
  },
  methods: {
    async payNow() {
      if (this.amountToPay != "") {
        f7.preloader.show("multi");
        await this.$store
          .dispatch("payment/makePayment", {
            type: "avo¢ash pay",
            amount: this.amount,
            paymentCurrency: this.paymentCurrency,
            payerAccountNumber: this.primaryAccount.accountNumber,
            payerGeolocation: `${this.latitude}-${this.longitude}`,
            payerIpAddress: this.ipAddress,
            payeeAccountNumber: this.selectedUser.accountNumber,
            payeeGeolocation: "",
            payeeIpAddress: "",
            description: this.description,
          })
          .then((result) => {
            setTimeout(() => {
              f7.popup.close(".selectAvocashUser");
              this.amount = "";
              this.amountToPay = "";
              this.f7router.navigate("/pay/with-account-number");
            }, 500);
            f7.preloader.hide();
          })
          .catch((err) => {
            console.log(err);
            f7.preloader.hide();
          });
      }
    },
    async cancelPayment(transaction) {
      await this.$store.dispatch("payment/cancelPayment", transaction);
      await this.f7router.back();
    },

    async issueQRCodeToPay() {
      if (
        !(
          this.amountToPay != "" ||
          !this.primaryAccount.accountNumber ||
          !this.primaryAccount.currencyCode
        )
      ) {
        return;
      }
      f7.preloader.show("multi");
      const paymentData = {
        type: "avo¢ash pay",
        amount: this.amount,
        paymentCurrency: this.paymentCurrency,
        payerAccountNumber: this.primaryAccount.accountNumber,
        payerGeolocation: `${this.latitude}-${this.longitude}`,
        payerIpAddress: this.ipAddress,
        payeeAccountNumber: "",
        payeeGeolocation: "",
        payeeIpAddress: "",
        description: this.description,
      };
      await this.$store.dispatch("payment/createQRcode", paymentData);

      await this.connectPayService();
      setTimeout(() => {
        f7.popup.close(".issueQRCode");
        this.amount = "";
        this.amountToPay = "";
        this.f7router.navigate("/pay/with-new-QRCode", {
          props: {
            paymentData,
          },
        });
      }, 500);
      f7.preloader.hide();
    },

    async issueQRCodeToClaim() {
      if (this.amountToPay == "") {
        return;
      }
      f7.preloader.show("multi");
      const paymentData = {
        type: "avo¢ash claim",
        amount: this.amount,
        paymentCurrency: this.paymentCurrency,
        payerAccountNumber: "",
        payerGeolocation: "",
        payerIpAddress: "",
        payeeAccountNumber: this.primaryAccount.accountNumber,
        payeeGeolocation: `${this.latitude}-${this.longitude}`,
        payeeIpAddress: this.ipAddress,
        description: this.description,
      };
      await this.$store.dispatch("payment/createQRcode", paymentData);
      await this.connectPayService();
      setTimeout(() => {
        this.amount = "";
        this.amountToPay = "";
        f7.popup.close(".issueQRCode");
        f7.preloader.hide();
        this.f7router.navigate("/accept-cash/with-new-QRCode", {
          props: {
            paymentData,
          },
        });
      }, 500);
    },

    //   async share() {
    //     const self = this;
    //
    //     const files = [];
    //     const base64Files = [];
    //     if (this.tontine && this.tontine.tontineImageList.length > 0) {
    //         for (let i = 0; i < this.tontine.tontineImageList.length; i++) {
    //             const blob = await (await fetch(this.tontine.tontineImageList[i].url)).blob();
    //             // if (f7.device.cordova == true) {
    //             const termination = (blob.type).split('/')[1];
    //             const file = new File([blob], `${this.tontine.tontineImageList[i].name}.${termination}`, { type: blob.type });
    //             files.push(file);
    //             base64Files.push(this.tontine.tontineImageList[i].url);
    //             if (
    //                 this.fileToSave) {
    //                 this.fileToSave.push(file);
    //             }
    //         }
    //     }

    //     this.sharedContent = {
    //         message: this.tontine.tontineTag,
    //         subject: this.tontine.tontineTitle,
    //         files,
    //         base64Files,
    //         url: `https://avocash.com/#/tontine/${this.tontine.id}`,
    //     };
    //     console.log(this.sharedContent);
    // },
  },
  computed: {},
};
