<template>
  <f7-page hide-bars-on-scroll @page:beforeremove="onPageBeforeRemove" no-toolbar>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{ title }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <span v-if="!paymentTransaction && !!QRCodeToClaim">
      <f7-block-title class="margin-horizontal">
        {{ $t("acceptcashwithnewqrcode.payerCanScan") }}
      </f7-block-title>

      <h5 class="margin-horizontal">
        {{ $t("acceptcashwithnewqrcode.amountToClaim") }}:
        <span v-html="$filtersToCurrency.toCurrency(
          paymentData.amount,
          primaryAccount.currencySymbol,
          primaryAccount.currencyCode
        )
          "></span>
      </h5>
      <img :src="QRCodeToClaim" class="QRCodeToClaim" alt="QRCodeToClaim" style="display: block; margin: auto" />
      <f7-button ripple outline raised large color="red" class="margin" @click="deleteQRCode()">{{
        $t("acceptcashwithnewqrcode.deleteQrCode") }}</f7-button>
    </span>
    <f7-list dividers-ios strong-ios v-if="paymentTransaction.isValidated === false" media-list form
      class="margin-vertical-half">
      <f7-list-group>
        <f7-block-title color="green" class="text-align-center">{{
          $t("acceptcashwithnewqrcode.paymentFrom", {
            payerName: paymentTransaction.payerName,
          })
        }}</f7-block-title>
        <f7-list-item :title="$t('acceptcashwithnewqrcode.youReceived')" :after="$filtersToCurrency.toCurrency(
          paymentTransaction.receivedPaymentAmount,
          paymentTransaction.toCurrencySymbol,
          paymentTransaction.toCurrencyCode
        )
          "></f7-list-item>
        <f7-block-title>{{ $t("common.validatePayment") }}</f7-block-title>
        <f7-row>
          <f7-col width="65" medium="80">
            <f7-list-item header="Transfer code" :title="paymentTransaction.transferCode"></f7-list-item>
          </f7-col>
          <f7-col width="25" medium="15">
            <f7-list-input type="number" floating-label :label="$t('common.pin')" placeholder="****" inputmode="numeric"
              :value="transferPIN" @input="transferPIN = $event.target.value" @keyup.enter="
                validateCreditTransaction(
                  paymentTransaction.transactionType,
                  paymentTransaction.transferCode
                )
                " pattern="[0-9]*" maxlength="4" size="4" required></f7-list-input>
          </f7-col>
        </f7-row>
      </f7-list-group>
    </f7-list>
    <f7-button v-if="paymentTransaction.isValidated === false" raised fill ripple large class="margin" color="red" strong
      @click="
        validateCreditTransaction(
          paymentTransaction.transactionType,
          paymentTransaction.transferCode
        )
        ">{{ $t("common.validatePayment") }}</f7-button>
    <template #fixed>
      <f7-fab v-if="!paymentTransaction && QRCodeToClaim" position="right-bottom" class="shareButton" color="auto"
        @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<script>
import Navbarright from "../components/navbarright.vue";
import { paymentConnect } from "../js/websocket/paymentWebsocket.js";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";
import { mapGetters, mapState } from "vuex";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { f7, theme } from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    paymentData: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [calculateTransferPayment, transferAgain, paymentConnect, socialShare],
  data() {
    return {
      theme,
      f7,
      title: this.$t("acceptcashwithnewqrcode.title"),
      amountToPay: "",
      transferRateId: "",
      paymentCurrency: "",
      paymentCountry: "",
      isssueQRClaimErrorMessage: "",
      countryCode: "",
      description: "",
      toAccount: "",
      toastText: "",
      description: "",
      connected: false,
      service: "",
      type: "",
      path: "",
      socket: "",
      stompClient: "",
      transferPIN: "",
      sharedContent: "",
    };
  },
  mounted: function () {
    const self = this;

    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }

    this.paymentCurrency = this.profile.currencyCode;
  },
  methods: {
    deleteQRCode() {
      const self = this;

      this.description = "";
      self.$store.state.payment.QRCodeToClaim = "";
      this.amountToPay = "";
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
      self.$store.dispatch("payment/deleteQRCode", this.qrCodeData.id);
      this.disconnectPaymentService();
    },
    onPageBeforeRemove() {
      const self = this;

      this.deleteQRCode();
    },
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCodeToClaim",
      "paymentError",
      "qrCodeData",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("account", ["primaryAccount", "businessAccount", "userByAccountNumber"]),
    ...mapState("auth", ["profile"]),
    ...mapState("location", [
      "country",
      "destinationCity",
      "currentUserCountry",
      "latitude",
      "longitude",
      "ipAddress",
    ]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
  },
};
</script>
