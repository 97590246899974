// Import Vue
import { createApp } from "vue";

// Import Framework7
import Framework7 from "framework7/lite-bundle";

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from "framework7-vue/bundle";

// Import Framework7 Styles
import "framework7/css/bundle";

// Import Icons and App Custom Styles
import "../css/app.css";
import "../css/icons.css";

// Impor        t App Component
import axios from "axios";
import Framework7Keypad from "framework7-plugin-keypad";
import Keycloak0 from "keycloak-js";
import VueAxios from "vue-axios";
import dayjs from "./day.js";
import i18n from "./i18n.js";
import keycloakStore from "./oauth2.js";
import base_url from "./store/base_url.json";
import { createStore } from "./store/store.js";
import SecureLS from "secure-ls";
import LZString from "lz-string";
const storage_pwd = import.meta.env.VITE_APP_STR_PWD;
import _ from "lodash";
import VueApexCharts from "vue3-apexcharts";
import App from "../components/app.vue";
import "../css/framework7-keypad.min.css";
import { Base64 } from "js-base64";
// import routes from "./routes.js";
import { Cropper } from "vue-advanced-cropper";
// // import "vue-advanced-cropper/dist/theme.compact.css";
import "vue-advanced-cropper/dist/style.css";
// Init         Framework7-Vue Plugin
Framework7.use(Framework7Vue);
// install p        lugin
Framework7.use(Framework7Keypad);
// if (process.env.NODE_ENV === "production") {
//     console.log = () => {};
//     console.warn = () => {};
//     console.error = () => {};
//     console.info = () => {};
// }

const app = createApp(App);
const lcStorage = new SecureLS({
  encodingType: "aes",
  isCompression: true,
  encryptionSecret: `${storage_pwd}`,
});
// if (window.localStorage.getItem("country")) {
//   lcStorage.removeAll();
// }
app.config.globalProperties.$lcStorage = lcStorage;

app.config.globalProperties.$filtersGetInitial = {
  getInitial(name) {
    return name === null
      ? null
      : (name.split(" ")[0] || " ").charAt(0) +
          "" +
          (name.split(" ")[1] || " ").charAt(0);
  },
};
app.config.globalProperties.$encodeData = {
  encode(data) {
    return LZString.compressToEncodedURIComponent(JSON.stringify(data));
  },
  decode(data) {
    return JSON.parse(LZString.decompressFromEncodedURIComponent(data));
  },
};

function findCurrencySymbol(currencyCode) {
  const currencyList = lcStorage.get("f7XquQcXg9d7ME");
  const currencies = currencyList
    ? JSON.parse(currencyList)
    : store.state.location.currencyList;
  for (const currency of currencies) {
    if (currency.currencyCode === currencyCode) {
      return currency;
    }
  }
}

app.config.globalProperties.$filtersGetCountry = {
  getCountry(countryCode) {
    const countryList = lcStorage.get("L5ET4juMXyWmUgzY9");
    const country = countryList
      ? JSON.parse(countryList)
      : store.state.location.country;
    return country.find((cntry) => cntry.countryCode2 === countryCode);
  },
};
app.config.globalProperties.$filtersGetCurrency = {
  getCurrency(currencyCode) {
    return findCurrencySymbol(currencyCode);
  },
};

app.config.globalProperties.$filtersToCurrency = {
  toCurrency(value, currencySymbol, currencyCode) {
    value = parseFloat(value) || 0;
    return (
      `${
        findCurrencySymbol(currencyCode)
          ? findCurrencySymbol(currencyCode).currencySymbol
          : ""
      }` +
      value.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      " " +
      currencyCode
    );
  },
};
app.config.globalProperties.$filtersToCurrency0 = {
  toCurrency(value, currencySymbol, currencyCode) {
    value = parseFloat(value) || 0;
    return currencySymbol === undefined
      ? ""
      : currencySymbol +
          "" +
          value.toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) +
          " " +
          currencyCode;
  },
};

app.config.globalProperties.$filtersToDate = {
  toDateTime(value) {
    let date;
    if (value === "") return;
    if (typeof value === "string") {
      date = new Date(Date.parse(value));
    } else if (typeof parseInt(value) === "number") {
      date = new Date(value);
    }
    // return dayjs().calendar(dayjs(date))
    return dayjs(date).format("DD/MM/YYYY");
  },
};
app.config.globalProperties.$filtersToTime = {
  toMsgTime(value) {
    let date;
    if (value === "") return;
    if (typeof value === "string") {
      date = new Date(Date.parse(value));
    } else if (typeof parseInt(value) === "number") {
      date = new Date(value);
    }
    return dayjs(date).fromNow();
  },
};

console.log("keycloakStore ", keycloakStore);
console.log("app root", app);
console.log("app.config", app.config);
const store = createStore(app);
app.use(store);
// app.use(keycloakStore);
app.use(i18n);
app.use(dayjs);
app.use(_);
app.use(VueAxios, axios);
app.use(VueApexCharts);
app.use(Base64);
// app.use(lcStorage);
// app.use(adapter);
app.config.globalProperties.axios = axios;
app.config.globalProperties.$dayjs = dayjs;
app.provide("$dayjs", dayjs);
// app.config.globalProperties.idempotencyKey = self.crypto.randomUUID();
// Register Framework7 Vue components
// app.use(routes);
app.component("cropper", Cropper);
registerComponents(app);
// axios.defaults.headers.post["Idempotency-key"] = self.crypto.randomUUID();
// console.log("httpt axios config ", axios);

// function idemInterceptor() {
//     axios.interceptors.request.use(
//         (config) => {
//             if (config.url.includes("https://secure.avocash.com/api")) {
//                 axios.defaults.headers.common["Idempotency-key"] =
//                     self.crypto.randomUUID();
//             }
//             console.log("httpt axios config ", axios);

//             return config;
//         },
//         (error) => {
//             return Promise.reject(error);
//         }
//     );
// }
// idemInterceptor();
// axios.interceptors.request.use(async(config) => {
//     await app.config.globalProperties.$keycloak.updateToken();

//     config.headers.common.Authorization = `Bearer ${app.config.globalProperties.$keycloak.token}`;

//     return config;
// });
const keycloak = new Keycloak0(base_url.keycloakJson);
// const keycloak =
//   window.cordova || window.Cordova
//     ? new Keycloak()
//     : new Keycloak0(base_url.keycloakJson);
// app.use(keycloak);
app.config.globalProperties.$keycloak = keycloak;
// keycloak.onAuthSuccess = updateState();
// keycloak.onAuthRefreshSuccess = updateState();
keycloakStore.dispatch("setKeycloak", keycloak);

const token = lcStorage.get("fuWwMxznZdgLixqZHy9");
const refreshToken = lcStorage.get("yln0qkUJkctVdt4Yk");

if (window.cordova || window.Cordova) {
  document.addEventListener(
    "deviceready",
    () => {
      console.log("Keycloak started in Cordova");

      keycloak
        .init({
          // token,
          // refreshToken,
          onLoad: "check-sso",
          // onLoad: "login-required",
          adapter: "cordova-native",
          responseMode: "query",
          // responseMode: "fragment",
          // checkLoginIframe: false,
          // silentCheckSsoFallback: false,
          enableLogging: true,
          redirectUri: `${import.meta.env.VITE_AVOCASH_MOBILE_URL_SCHEME}`,
          locale: navigator.language.split("-")[0],
          // silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
          promiseType: "native",
          flow: "standard",
          pkceMethod: "S256",
          scope: "openid address phone email",
          pkceMethod: "S256",
          // adapter: "cordova",
          // cordovaOptions: {
          //   zoom: "no",
          //   hardwareback: "yes",
          //   location: "no",
          //   clearsessioncache: "yes",
          //   clearcache: "yes",
          // },
        })
        .then(updateState)
        .catch(error);
    },
    false
  );
} else {
  app.config.globalProperties.$isBrowser = true;
  console.log("token refreshtoken", `${token} ${refreshToken}`);
  keycloak
    .init({
      onLoad: "check-sso",
      token,
      refreshToken,
      // onLoad: "login-required",
      checkLoginIframe: true,
      silentCheckSsoFallback: true,
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
      redirectUri: `${window.location.origin}/`,
      promiseType: "native",
      responseMode: "fragment",
      flow: "standard",
      scope: "openid address phone email",
      pkceMethod: "S256",
      locale: navigator.language.split("-")[0],
      enableLogging: true,
    })
    .then(updateState)
    .catch(error);
}
app.mount("#app");

async function updateState() {
  app.config.globalProperties.$keycloak = keycloak;
  keycloakStore.dispatch("setKeycloak", keycloak);
  store.dispatch("auth/setKeycloak", keycloak);
  console.log("keycloak onReady", keycloak);
  // app.provide("$keycloak", keycloak);
  if (keycloak.authenticated) {
    // Check authenticated and update state
    console.log("keycloak authenticated checked token ", keycloak);
    store.dispatch("auth/isAuthenticated", keycloak.authenticated);
    store.dispatch("auth/setToken", keycloak.token);
    store.dispatch("auth/setRefreshToken", keycloak.refreshToken);
    keycloakStore.dispatch("setAuthenticated", keycloak.authenticated);

    lcStorage.set("fuWwMxznZdgLixqZHy9", keycloak.token);
    lcStorage.set("yln0qkUJkctVdt4Yk", keycloak.refreshToken);
    store.dispatch("location/getEmoneyApiList");
    if (!store.state.message.chatUser) {
      await store.dispatch("message/getChatUser");
    }
    if (store.state.message.chatUser && !store.state.message.groupList) {
      await store.dispatch("message/getUserGroups");
    }
    if (!store.state.auth.profile) {
      try {
        const profile = await keycloak.loadUserInfo();
        console.log("user profile", profile);
        store.dispatch("auth/setProfile", profile);
        if (store.state.auth.profile !== null) {
          await store.dispatch(
            "account/getUserAccount",
            store.state.auth.profile
          );
          if (store.state.account.userAccount === "") {
            await store.dispatch(
              "account/createNewUservocashAccounts",
              store.state.auth.profile
            );
          }
        }
      } catch (err) {
        store.dispatch("auth/setProfile", "");
        console.log(err.data);
      }
    }
    if (!store.state.auth.user) {
      try {
        const user = await keycloak.loadUserProfile();
        store.dispatch("auth/setUser", user);
        console.log(user);
      } catch (err) {
        store.dispatch("auth/setUser", "");
        console.log(err);
      }
    }
    getOrCreateAccount();
    console.log(
      `Profile authenticated with function, logged in`,
      store.state.auth.profile
    );
    return;
  }
  keyCloakError();
}

setInterval(() => {
  keycloak
    .updateToken(70)
    .then((refreshed) => {
      if (refreshed) {
        console.log(`Token refreshed${refreshed}`);
      } else {
        console.warn(
          "Token not refreshed, valid for " +
            Math.round(
              keycloak.tokenParsed.exp +
                keycloak.timeSkew -
                new Date().getTime() / 1000
            ) +
            " seconds"
        );
      }
      // updateState();
    })
    .catch(() => {
      console.error("Failed to refresh token");
      keyCloakError();
    });
}, 6000);

function getOrCreateAccount() {
  if (keycloak.authenticated) {
    // store.dispatch("stripe/getStripePublicKey");
    setTimeout(() => {
      const stdId =
        store.state.account.userAccount.standard &&
        store.state.account.userAccount.standard.id !== null
          ? store.state.account.userAccount.standard.id
          : null;
      // console.error("standard account id", stdId);
      if (
        // store.state.account.userAccount === null &&
        !stdId == undefined &&
        stdId !== null &&
        stdId.indexOf("AS-") != -1 &&
        keycloak.userInfo !== null
      ) {
        console.error("standard account id", stdId);
        store.dispatch(
          "account/createNewUservocashAccounts",
          keycloak.userInfo
        );
      }
    }, 1000);
  }
}

function keyCloakError() {
  if (keycloak.authenticated) {
    return;
  }
  store.dispatch("auth/setProfile", "");
  store.dispatch("auth/setUser", "");
  store.dispatch("auth/isAuthenticated", false);
  store.dispatch("auth/setToken", "");
  store.dispatch("auth/setRefreshToken", "");
  keycloakStore.dispatch("setAuthenticated", false);
  // window.localStorage.setItem("token", "");
  // window.localStorage.setItem("refreshToken","");
  console.log(`Profile: Not logged in`);
  store.state.account.userAccount = "";
}
function error() {
  console.log("Failed to initialize Keycloak adapter");
}
