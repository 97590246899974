<template>
  <f7-link v-if="this.isTablet" @click="showOnbordingPage()" style="min-width: 125px" href="#" id="avocashLogo">
    <span>
      <img src="/34x34.png" alt="avocash_logo" width="34" height="34"
        style="vertical-align: middle; padding-right: 8px; padding-left: 16px" />
      <span class="avocash text-color-primary deskTopSmallerScreen padding-right margin-right" style="
          letter-spacing: var(--f7-tabbar-label-letter-spacing);
          font-weight: bold;
          font-size: large;
          text-transform: lowercase;
        ">avo¢ash</span>
    </span>
  </f7-link>
  <span v-if="this.isTablet" class="toolBarSpace"></span>
  <f7-link tab-link href="/" icon-ios="f7:house_fill" icon-aurora="f7:house_fill" transition="f7-parallax"
    :class="this.selectedTab === 'home' ? 'tab-link-active' : ''" id="home" @click="selectTab('home')"
    icon-md="material:home" :text="$t('home.home')" :style="'font-size:' + !this.isTablet ? '85%' : '100%'"></f7-link>

  <f7-link v-if="$keycloak.authenticated && this.authenticated" tab-link icon-aurora="f7:doc_richtext"
    icon-ios="f7:doc_richtext" icon-md="material:feed" :text="$t('home.fairia')" href="/fairia/" transition="f7-parallax"
    id="fairia" :class="this.selectedTab === 'fairia' ? 'tab-link-active' : ''" @click="selectTab('fairia')"
    :style="'font-size:' + !this.isTablet ? '85%' : '100%'"></f7-link>
  <f7-link tab-link icon-aurora="material:store" icon-ios="material:store" icon-md="material:store" id="stores"
    :class="this.selectedTab === 'stores' ? 'tab-link-active' : ''" @click="selectTab('stores')" :text="$t('home.stores')"
    href="/stores/" transition="f7-parallax" :style="'font-size:' + !this.isTablet ? '85%' : '100%'"></f7-link>
  <f7-link v-if="$keycloak.authenticated && this.authenticated" tab-link href="/dashboard/" transition="f7-parallax"
    class="avocash-icon" id="dashboard" :class="this.selectedTab === 'dashboard' ? 'tab-link-active' : ''"
    @click="selectTab('dashboard')" :style="'font-size:' + !this.isTablet ? '85%' : '100%'">
    <i class="icon" style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)">
      <img src="/icon-new.ico" width="24" height="24" class="elevation-6" alt="avocash_logo" /></i>
    <span class="tabbar-label avocash deskTopSmallerScreen"
      :style="'font-size:' + !this.isTablet ? '85%' : '100%'">avo¢ash</span>
  </f7-link>
  <f7-link tab-link href="/wallets/" transition="f7-parallax" icon-ios="f7:circle_grid_hex_fill"
    :class="this.selectedTab === 'wallets' ? 'tab-link-active' : ''" @click="selectTab('wallets')"
    icon-aurora="f7:circle_grid_hex_fill" icon-md="material:group_work" :text="$t('home.wallets')" id="wallets"
    :style="'font-size:' + !this.isTablet ? '85%' : '100%'"></f7-link>
  <f7-link href="/message/" tab-link :text="$t('home.tamtam')" v-if="$keycloak.authenticated && this.authenticated"
    transition="f7-parallax" id="tamtam" :class="this.selectedTab === 'tamtam' ? 'tab-link-active' : ''"
    @click="selectTab('tamtam')" icon-aurora="f7:bubble_left_bubble_right_fill"
    icon-ios="f7:bubble_left_bubble_right_fill" icon-md="material:chat"
    :style="'font-size:' + !this.isTablet ? '85%' : '100%'">
  </f7-link>
  <f7-link tab-link href="/cart/" transition="f7-parallax" v-if="this.cart && this.cart.length > 0 && this.isTablet"
    id="cart" :class="this.selectedTab === 'cart' ? 'tab-link-active' : ''" @click="selectTab('cart')"
    class="toolbarDeskTop">
    <f7-icon ios="f7:cart" aurora="f7:cart" md="material:shopping_cart" color="none">
      <f7-badge bg-color="none" class="text-align-center" :style="{
        ' font-size': '50%',
        'margin-left': this.totalItemCount > 9 ? '-23px' : '-20px',
        top: '-5px',
        'font-weight': 'bold',
      }" text-color="red" v-if="this.cart && this.cart.length > 0">{{ this.totalItemCount }}</f7-badge>
    </f7-icon>
    <span class="tabbar-label">{{ $t("home.cart") }}</span>
  </f7-link>
  <span target="_blank" v-if="$keycloak.authenticated && this.authenticated && this.isTablet"
    class="qrCodeScannerIcon margin-right">
    <navbarleft></navbarleft>
  </span>
  <span target="_blank" class="margin-horizontal" v-if="$keycloak.authenticated && this.authenticated && this.isTablet">
    <navbarright></navbarright>
  </span>

  <span class="tab-link" style="justify-content: center"
    v-if="!$keycloak.authenticated && !this.authenticated && this.isTablet">
    <f7-button small round-md outline round raised class="registerButton signUpSignIn" color="auto"
      @click="registerFn()">{{ $t("common.register") }}</f7-button>
  </span>
  <span class="tab-link" style="justify-content: center"
    v-if="!$keycloak.authenticated && !this.authenticated && this.isTablet">
    <f7-button small outline round round-md raised color="red" class="loginButton signUpSignIn" @click="loginFn()">{{
      $t("common.login") }}</f7-button></span>
  <span target="_blank" v-if="!$keycloak.authenticated && !this.authenticated && this.isTablet"
    class="qrCodeScannerIcon margin-right">
    <navbarleft></navbarleft>
  </span>
  <span class="installPrompt" style="visibility: hidden" v-if="this.isTablet"></span>
  <!-- </f7-toolbar> -->
  <!-- <f7-tabs routable="true" v-if="f7.device.cordova">
    <f7-tab id="view-home" name="home" class="page-content"> </f7-tab>
    <f7-tab id="view-fairia" name="fairia" class="page-content"> </f7-tab>
    <f7-tab id="view-stores" name="stores" class="page-content"> </f7-tab>
    <f7-tab id="view-dashboard" name="dashboard" class="page-content"> </f7-tab>
    <f7-tab id="view-wallets" name="wallets" class="page-content"> </f7-tab>
    <f7-tab id="view-tamtam" name="tamtam" class="page-content layout_container">
    </f7-tab>
    <f7-tab id="view-cart" name="cart" class="page-content"> </f7-tab>
  </f7-tabs> -->
</template>
<style>
/* .toolbar.tabbar.mainToolbar.webToolbar .tab-link-highlight {
  display: none !important;
  width: 0%;
} */
</style>
<style scoped>
@media only screen and (max-width: 568px) {

  a.link.tab-link span,
  .link i+span,
  .link i+i,
  .link span+i,
  .link span+span {
    font-size: xx-small;
  }
}

.signUpSignIn {
  border-radius: 32px;
  border-width: medium;
}

.registerButton {
  border-color: var(--f7-theme-color);
}

.loginButton {
  border-color: var(--f7-color-red);
}
</style>
<script>
import {
  f7Page,
  f7View,
  f7Button,
  f7Toolbar,
  f7Icon,
  f7Badge,
  f7Tabs,
  f7Tab,
  theme,
  f7,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import Navbarleft from "../components/navbarleft.vue";
import homepage from "../pages/homePage.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import { loginFn } from "../js/mixins/loginFn.js";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [loginFn],

  components: {
    navbarright: Navbarright,
    navbarleft: Navbarleft,
    homepage,
    f7Page,
    f7View,
    f7Button,
    f7Toolbar,
    f7Icon,
    f7Badge,
    f7Tabs,
    f7Tab,
  },

  data() {
    return {
      theme,
      f7,
      selectedTab: "home",
      placeholder: "",
      search_container: "",
      search_item: "",
      search_in: "",
      search_group_title: "",
      searchPost: "",
      isSelectiveTab: false,
    };
  },
  mounted() {
    if (!this.isTablet) {
      // f7.$(".page").addClass("page-with-subnavbar");
      // f7.$(".page-content").addClass("hide-bars-on-scroll");
    }
  },

  methods: {
    showOnbordingPage() {
      this.$lcStorage.set("ql82uFo", false);
      this.$store.dispatch("auth/setOnboardingFinished", false);
      if (!this.onboardingFinished && f7.device.cordova) {
        f7.statusbar.show();
      }
    },
    selectHomeView() {
      f7.tab.show("#view-home");
    },
    selectTamTam() {
      f7.tab.show("#view-tamtam");
      f7.view.get(".view-main").router.navigate("/tamtam/");
    },
    selectTab(selected_tab) {
      this.selectedTab = selected_tab;
    },
  },
  computed: {
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "authenticated",
      "onboardingFinished",
      "locale",
    ]),
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
  },
};
</script>
