<template>
  <f7-page :page-content="false" @page:beforein="onPageBeforeInit">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title>{{
        $t("sendcash.transferTo", { myrecipient: this.recipient.name })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top no-shadow class="detailTabs">
      <f7-link icon-aurora="material:account_balance_wallet" icon-ios="material:account_balance_wallet"
        icon-md="material:account_balance_wallet" :text="$t('sendcash.useBalance')" tab-link="#frombalance"
        tab-link-active style="text-transform: lowercase"></f7-link>
      <f7-link icon-aurora="f7:creditcard" icon-ios="f7:creditcard" icon-md="material:credit_card"
        :text="$t('sendcash.useNewCards')" tab-link="#withnewcards" style="text-transform: lowercase"></f7-link>
      <f7-link icon-ios="f7:square_stack_3d_up" icon-aurora="f7:square_stack_3d_up" icon-md="material:account_balance"
        :text="$t('sendcash.useBankCard')" tab-link="#withdebitcard" style="text-transform: lowercase"></f7-link>
    </f7-toolbar>
    <f7-tabs swipeable>
      <f7-tab id="frombalance" tab-active>
        <frombalance-page :pickupmode="this.f7route.params.pickupmode" :recipientId="this.f7route.params.id">
        </frombalance-page>
      </f7-tab>
      <f7-tab id="withnewcards">
        <withnewcards-page :pickupmode="this.f7route.params.pickupmode" :recipientId="this.f7route.params.id" />
      </f7-tab>
      <f7-tab id="withdebitcard">
        <withdebitcard-page :pickupmode="this.f7route.params.pickupmode" :recipientId="this.f7route.params.id" />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<style scoped></style>

<script>
import FrombalancePage from "./sendCashFromBalance.vue";
import WithnewcardsPage from "./sendCashWithNewCards.vue";
import WithDebitCardPage from "./sendCashFromDebitCard.vue";
import { f7, theme } from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import { selectedRecipient, recipient } from "../js/mixins/recipient.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [selectedRecipient, recipient],
  components: {
    "frombalance-page": FrombalancePage,
    "withnewcards-page": WithnewcardsPage,
    "withdebitcard-page": WithDebitCardPage,
    navbarright: Navbarright,
  },
  data() {
    return { theme, f7, destinationcty: "" };
  },
  methods: {},
  mounted() {
    const self = this;
    if (this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
  },

  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userrecipientList",
      "recipients",
      "allrecipients",
      "userrecipientList",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),

    ...mapState("transaction", ["grossexchangerate", "transferDetail"]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients", "recipient"]),
    ...mapGetters("account", ["userByAccountNumber", "userDetail"]),
    recipient() {
      const self = this;

      const id = this.f7route.params.id || this.recipientId;
      for (var i = 0; i < this.allrecipients.length; i += 1) {
        if (this.allrecipients[i].id == id) {
          return this.allrecipients[i];
        }
      }
    },
  },
};
</script>
