import { f7, theme } from "framework7-vue";
import _ from "lodash";
export const walletMixin = {
  mounted() {
    const self = this;

    if (!this.publicWalletList || this.publicWalletList === "") {
      self.$store.dispatch("wallet/getPublicWalletList", this.visibleItem);
    }
    if (this.$keycloak.authenticated) {
      this.get3rdPartyWallet();
    }
    if (this.projectCategories === "") {
      self.$store.dispatch("wallet/getWalletCategory");
    }
    if (this.wallet) {
      this.share();
    }
  },
  methods: {
    createNew() {
      const self = this;
      if (!self.actionsCampaign) {
        self.actionsCampaign = f7.actions.create({
          buttons: [
            {
              text: self.$t("common.selectAction"),
              label: true,
            },
            {
              text: self.$t("homepage.charitiesFunding"),
              icon: `<i class="material-icons color-green">volunteer_activism</i>`,
              color: "green",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/charities");
              },
            },
            {
              text: self.$t("homepage.soloPubliccampaigns"),
              icon: `<i class="material-icons color-teal">reduce_capacity</i>`,
              color: "teal",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/moneypots");
              },
            },
            {
              text: self.$t("homepage.artistsWebArtits"),
              icon: `<i class="material-icons color-teal">history_edu</i>`,
              color: "teal",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/creatives");
              },
            },
            {
              text: self.$t("homepage.webEntrepreneurs"),
              icon: `<i class="f7-icons color-teal">sunrise</i>`,
              color: "teal",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/entrepreneurs");
              },
            },
          ],
          // Need to specify popover target
          targetEl: self.$el.querySelector(".createCampaign"),
        });
      }

      // Open
      self.actionsCampaign.open();
    },

    async viewDetail(wallet) {
      const self = this;
      f7.preloader.show("multi");
      if (wallet.id !== self.$store.state.wallet.wallet.id) {
        (await self.$store.state.wallet.wallet) == {};
        await self.$store.dispatch("wallet/getWallet", wallet.id);
      }
      f7.views.main.router.navigate(`/wallet/${wallet.id}`);
      f7.preloader.hide();
    },

    async addWalletOwner(searchedUser) {
      const self = this;

      console.log(searchedUser);
      await self.$store.dispatch("wallet/addNewWalletOwner", {
        userId: searchedUser.userId,
        walletId: this.wallet.id,
      });
      f7.sheet.close(".addNewMember");
    },
    async removeWalletOwner(owner) {
      const self = this;

      await self.$store.dispatch("wallet/removeWalletOwner", {
        walletId: this.wallet.id,
        userId: owner.userId,
      });
    },
    async grantAdminRole(owner) {
      const self = this;

      await self.$store.dispatch("wallet/grantAdminRole", {
        walletId: this.wallet.id,
        userId: owner.userId,
      });
    },
    async withdrawAdminRole(owner) {
      const self = this;

      await self.$store.dispatch("wallet/withdrawAdminRole", {
        walletId: this.wallet.id,
        userId: owner.userId,
      });
    },

    loadMore() {
      const self = this;

      if (
        this.profile &&
        f7.views.main.router.currentRoute.path === "/wallets/private"
      ) {
        if (!self.allowInfinite) return;
        self.allowInfinite = false;
        if (
          this.privateWalletList &&
          this.privateWalletList.length < this.visibleItem
        ) {
          self.showPreloader = false;
          return;
        }
        setTimeout(() => {
          this.visibleItem += 6;
          self.$store.dispatch("wallet/getPrivateWalletList", this.visibleItem);
          self.allowInfinite = true;
        }, 1000);
      } else if (
        f7.views.main.router.currentRoute.path === "/" ||
        f7.views.main.router.currentRoute.path === "/wallets/"
      ) {
        if (!self.allowInfinite) return;
        self.allowInfinite = false;
        if (
          self.publicWalletList &&
          self.publicWalletList.length < this.visibleItem
        ) {
          self.showPreloader = false;
          return;
        }
        setTimeout(() => {
          this.visibleItem += 6;
          self.$store.dispatch("wallet/getPublicWalletList", this.visibleItem);
          self.allowInfinite = true;
        }, 1000);
      }
      self.showPreloader = false;
    },

    async validateRedeem(walletTransaction) {
      const self = this;

      f7.preloader.show("multi");
      if (walletTransaction !== null) {
        await self.$store.dispatch("wallet/validateWalletRedeem", {
          transferCode: walletTransaction.transferCode,
          transferPIN: walletTransaction.transferPIN,
        });
      }
      this.validatedDialog();
      setTimeout(() => {
        f7.preloader.hide();
        if (
          f7.views.main.router.currentRoute.url.search(`/wallet/${wallet.id}`) <
          0
        ) {
          f7.views.main.router.back();
        }
        f7.views.main.router.refreshPage();
      }, 300);
    },

    validatedDialog() {
      const self = this;

      if (!self.toastIcon) {
        self.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-icons text-color-green border-color-gray color-green size-100">check</i>'
            : '<i class="material-icons text-color-green border-color-gray color-green size-100">check</i>',
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 1500,
        });
      }
      self.toastIcon.open();
    },
    async share() {
      const self = this;

      const files = [];
      const base64Files = [];
      if (
        this.wallet.walletImageList &&
        this.wallet.walletImageList.length > 0
      ) {
        for (const walletImageItem of this.wallet.walletImageList) {
          const blob = await (await fetch(walletImageItem.url)).blob();
          const termination = blob.type.split("/")[1];
          const file = new File(
            [blob],
            `${walletImageItem.name}.${termination}`,
            { type: blob.type }
          );
          //  : `data:${blob.type};base64,${this.wallet.walletImageList[i].url}`;
          if (this.fileToSave) {
            this.fileToSave.push(file);
          }
          files.push(file);
          base64Files.push(walletImageItem.url);
        }
      }

      this.sharedContent = {
        message: this.wallet.tag,
        subject: this.wallet.name,
        files,
        base64Files,
        link: `${f7.views.main.router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        imgUrl: "https://avocash.com/34x34.png",
      };

      // self.$store.dispatch("auth/createDynamicLink", this.sharedContent);
      console.log(this.sharedContent);
    },
    campaignDays(value) {
      const currentDate = new Date();
      const endDate = new Date(value.endDate);
      return endDate < currentDate
        ? self.$t("walletdetail.ended") +
            self.$t("walletdetail.daysago", {
              endedDays: ((currentDate - endDate) / (1000 * 3600 * 24)).toFixed(
                0
              ),
            })
        : self.$t("walletdetail.daysleft", {
            daysLeft: ((endDate - currentDate) / (1000 * 3600 * 24)).toFixed(0),
          });
    },
    getWalletPhotos(wallet) {
      const photos = [];
      for (const photo of wallet.walletImageList) {
        photos.push({
          url: `data:image/png;base64,${photo.image}`,
          name: photo.name,
        });
      }
      return photos;
    },

    get3rdPartyWallet() {
      const self = this;
      if (
        !this.thirdPartyAllowedWalletList ||
        this.thirdPartyAllowedWalletList === ""
      ) {
        this.selectedCountry = this.profile.countryCode;
        self.$store.dispatch(
          "wallet/getThirdPartyAllowedWallets",
          this.selectedCountry
        );
      }
    },
  },

  computed: {
    recipientCountry() {
      if (this.wallet) {
        return _.find(this.destinationCountry, [
          "toCountryCode2",
          this.wallet.countryCode,
        ]);
      }
    },
    walletPhotos() {
      const walletsPhotoImage = this.wallet.walletImageList;
      if (walletsPhotoImage) {
        return _.forEach(walletsPhotoImage.slice(), (item) => {
          _.assign(item, {
            url: `data:image/png;base64,${item.image}`,
          });
        });
      }
    },
    walletQRImage() {
      return _.find(this.walletPhotos, ["name", "QRImage"]);
    },
    currentWalletOwner() {
      if (this.$keycloak.authenticated) {
        return _.find(this.wallet.walletOwnerList, [
          "userId",
          this.profile.sub,
        ]);
      }
    },
    currentUserIsAdmin() {
      if (this.$keycloak.authenticated) {
        return _.find(this.wallet.walletAdminList, [
          "userId",
          this.profile.sub,
        ]);
      }
    },
  },
};
