<template>
  <div class="text-align-center">
    <f7-preloader
      :size="42"
      color="multi"
      style="
        top: 50%;
        left: 50%;
        margin: 0;
        position: absolute;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1000;
      "
    />
  </div>
</template>
