<template>
  <f7-card outline header-divider raised v-if="this.client_secret && this.payment_intent">
    <f7-card-header>{{ $t("common.status")
    }}<span>{{ this.payment_intent.status }}</span></f7-card-header>
    <f7-list media-list>
      <f7-list-item :header="$t('common.amount')" :title="$filtersToCurrency.toCurrency(
        this.payment_intent.amount / 100,
        null,
        this.payment_intent.currency
      )
        " />
      <f7-list-item :header="$t('common.tag')" :title="this.payment_intent.metadata.tag" />
      <f7-list-item :header="$t('common.description')" :title="this.payment_intent.description" />
      <f7-list-item :header="$t('common.description')" :title="this.payment_intent.description" />
    </f7-list>
  </f7-card>
  <div v-else>{{ f7.views.main.router.currentRoute.query.redirect_status }}</div>
  <div v-if="this.message !== ''">{{ this.message }}</div>
</template>
<style scoped></style>
<script>
import {
  f7Popup,
  f7Page,
  f7Link,
  f7Button,
  f7Toolbar,
  f7Icon,
  f7,
  theme,
} from "framework7-vue";
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: { f7Popup, f7Page, f7Link, f7Button, f7Toolbar, f7Icon },

  data() {
    return {
      theme,
      f7,
      stripe: "",
      elements: "",
      client_secret: "",
      stripeResult: "",
      payment_intent: "",
      intentStatus: "",
    };
  },
  mounted() {
    console.log("this.f7route.query", f7.views.main.router.currentRoute.query);
    this.intentStatus = f7.views.main.router.currentRoute.query.redirect_status;
    if (this.publishableKey === null && this.$keycloak.authenticated) {
      this.$store.dispatch("stripe/getStripePublicKey");
      setTimeout(() => {
        this.stripe = loadStripe(this.publishableKey);

        this.client_secret =
          f7.views.main.router.currentRoute.query.payment_intent_client_secret;
        if (this.stripe !== "" && this.client_secret !== null) {
          const curPaymentIntent = this.stripe.retrievePaymentIntent(this.client_secret);
          console.log("curPaymentIntent", curPaymentIntent);
          if (error) {
            this.stripeResult = error.message;
          }
          this.payment_intent = curPaymentIntent;
        }
      }, 1000);
    }
  },

  methods: {},

  computed: {
    ...mapState("apimoney", ["paymentIntent"]),
    ...mapState("stripe", ["publishableKey"]),
    ...mapState("auth", ["profile", "documentsUrl", "document", "isTablet"]),
  },
};
</script>
