<template>
  <f7-page hide-bars-on-scroll no-toolbar @page:beforeremove="onPageBeforeRemove" @page:afterin="onPageBeforeIn">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{ this.title }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <f7-list dividers-ios strong-ios media-list v-if="((this.paymentTransaction === '' ||
      this.paymentTransaction.isValidated === true) &&
      parseFloat(this.decodedQRCode.payerAccountNumber) === '') ||
      !this.decodedQRCode
      ">
      <div class="margin-vertical-half">
        <f7-block-title medium style="white-space: normal; word-wrap: break-word"
          class="text-align-center margin marging-vertical-half">{{ $t("avocashatmwithqrcodescan.scanQrCode")
          }}</f7-block-title>
        <f7-row class="justify-content-center margin">
          <f7-link icon-size="50" icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder"
            icon-md="f7:qrcode_viewfinder" class="text-align-left" @click="scanQRCode()"></f7-link>
        </f7-row>
      </div>
    </f7-list>

    <!--if QR code has no set amount to pay or mannually enter recipient number and amount to pay-->
    <span v-if="(this.paymentTransaction === '' ||
      this.paymentTransaction.isValidated === true) &&
      this.decodedQRCode
      ">
      <f7-list dividers-ios strong-ios id="payOutCashwithQRScan">
        <h5 class="margin" style="font-weight: normal" v-if="this.paymentDetail || this.paymentReverseDetail">
          <span v-if="this.decodedQRCode.paymentCurrency == this.primaryAccount.currencyCode">{{
            $t("avocashatmwithqrcodescan.payout")
          }}<b>
              {{
                $filtersToCurrency.toCurrency(
                  this.paymentDetail.receivedAmount || this.decodedQRCode.amountToPay,
                  this.paymentDetail.toCurrencySymbolNative,
                  this.paymentDetail.toCurrencyCode
                )
              }}
            </b>
            to
            <b>
              {{ this.userByAccountNumber.firstName }}
              {{ this.userByAccountNumber.lastName }}
            </b>
            on
            <b>{{ this.decodedQRCode.payerAccountNumber }}</b>
          </span>
          <span v-else>{{ $t("avocashatmwithqrcodescan.payout")
          }}<b>
              {{
                $filtersToCurrency.toCurrency(
                  this.paymentReverseDetail.receivedAmount,
                  this.paymentReverseDetail.toCurrencySymbolNative,
                  this.paymentReverseDetail.toCurrencyCode
                )
              }}
            </b>
            to
            <b>
              {{ this.userByAccountNumber.firstName }}
              {{ this.userByAccountNumber.lastName }}
            </b>
            on
            <b>{{ this.decodedQRCode.payerAccountNumber }}</b>
          </span>
        </h5>
        <h5 v-else class="margin" style="font-weight: normal">
          {{ $t("avocashatmwithqrcodescan.enterTheAmount")
          }}<b>
            {{ this.userByAccountNumber.firstName }}
            {{ this.userByAccountNumber.lastName }}
          </b>
          on
          <b>{{ this.decodedQRCode.payerAccountNumber }}</b>
        </h5>

        <f7-row no-gap class="margin-vertical-half">
          <f7-col width="100" medium="50">
            <f7-list-input :label="$t('avocashatmwithqrcodescan.avoashNumber')" padding-right-half type="number" min="0"
              pattern="[0-9]*" maxlength="10" class="inputNumber" readonly
              :value="this.decodedQRCode.payerAccountNumber || userAccountNumber"
              @input="userAccountNumber = $event.target.value" name="recipient"></f7-list-input>
          </f7-col>

          <f7-col width="100" medium="50" v-if="parseFloat(this.decodedQRCode.amountToPay) > 0">
            <li class="item-content item-input">
              <div class="item-media">
                {{
                  this.decodedQRCode.paymentCurrency ||
                  this.userByAccountNumber.primaryAccount.currencyCode
                }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("avocashatmwithqrcodescan.amountToPayout") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" min="0" pattern="[0-9]*" required validate :max="this.decodedQRCode.amountToPay"
                    data-error-message="Only numbers please!" name="amountToPay" id="amountToPay" readonly :value="this.decodedQRCode.amountToPay.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                      " @input="amountToPay = $event.target.value" />
                </div>
              </div>
            </li>
          </f7-col>

          <f7-col width="100" medium="50" v-else>
            <li class="item-content item-input">
              <div class="item-media">
                {{
                  this.decodedQRCode.paymentCurrency ||
                  this.userByAccountNumber.primaryAccount.currencyCode
                }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("avocashatmwithqrcodescan.amountToPayout") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" min="0" pattern="[0-9]*" required validate
                    data-error-message="Only numbers please!" name="amountToPay" id="amountToPay" :value="amountToPay"
                    @input="amountToPay = $event.target.value" @change="changeAmountToPay()" @keyup="changeAmountToPay()"
                    @blur="changeAmountToPay()" @keyup.enter="PayOutNow()" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
          </f7-col>
        </f7-row>

        <f7-block style="font-size: 12px" class="no-margin" v-if="parseFloat(this.amountToPay)">
          <p v-if="this.paymentDetail !== null">
            {{
              $t("avocashatmwithqrcodescan.youGetAs", {
                "1": $filtersToCurrency.toCurrency(
                  this.paymentDetail.transferFeeAmount,
                  this.paymentDetail.fromCurrencySymbol,
                  this.paymentDetail.fromCurrencyCode
                ),
              })
            }}
          </p>
        </f7-block>
        <f7-block class="no-margin" v-if="this.userByAccountNumber && this.userByAccountNumber.length > 0">
          <p style="font-size: 10px; font-style: italic; color: red">
            {{ this.getusererror }}
          </p>
        </f7-block>

        <!--Ask if PIN required to confirm and release payment for remote payment mainly-->
      </f7-list>
      <f7-button raised fill ripple large color="red" class="margin" @click="PayOutNow()">{{
        $t("avocashatmwithqrcodescan.payoutNow") }}</f7-button>

      <f7-button class="margin" ripple outline raised color="red" @click="cancelScan()">{{
        $t("avocashatmwithqrcodescan.cancelPayout")
      }}</f7-button>
    </span>
    <f7-list dividers-ios strong-ios v-if="this.paymentTransaction.isValidated === false" media-list form
      class="margin-vertical-half">
      <f7-list-group>
        <f7-block-title color="green" class="text-align-center">{{
          $t("avocashatmwithqrcodescan.transferFrom", {
            "1": this.paymentTransaction.payerName,
          })
        }}</f7-block-title>
        <f7-list-item :title="$t('avocashatmwithqrcodescan.youReceived')" :after="$filtersToCurrency.toCurrency(
          this.paymentTransaction.receivedPaymentAmount,
          this.paymentTransaction.toCurrencySymbol,
          this.paymentTransaction.toCurrencyCode
        )
          "></f7-list-item>
        <f7-block-title>{{
          $t("avocashatmwithqrcodescan.validateReceipt")
        }}</f7-block-title>
        <div class="cols75-25">
          <f7-list-item header="Transfer code" :title="this.paymentTransaction.transferCode"></f7-list-item>

          <f7-list-input type="password" floating-label :label="$t('avocashatmwithqrcodescan.pin')"
            :placeholder="$t('avocashatmwithqrcodescan.676')" inputmode="numeric" :value="transferPIN"
            @input="transferPIN = $event.target.value" @keyup.enter="
              validateCreditTransaction(
                paymentTransaction.transactionType,
                paymentTransaction.transferCode
              )
              " pattern="[0-9]*" maxlength="4" size="4" required></f7-list-input>
        </div>
      </f7-list-group></f7-list>
    <f7-button v-if="this.paymentTransaction.isValidated === false" raised fill ripple large class="margin" color="red"
      strong @click="
        validateCreditTransaction(
          paymentTransaction.transactionType,
          paymentTransaction.transferCode
        )
        ">{{ $t("avocashatmwithqrcodescan.validateReceipt") }}</f7-button>
  </f7-page>
</template>
<script>
import { f7, theme } from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import userByAccountNumber from "../js/mixins/userByAccountNumber.js";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";

import { mapState, mapGetters } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [
    userByAccountNumber,
    scanQRCodeGeneral,
    calculateTransferPayment,
    transferAgain,
  ],
  data() {
    return {
      theme,
      f7,
      title: "scan QR to cash-out",
      amountToPay: "",
      paymentCurrency: "",
      userAccountNumber: "",
      transferPIN: "",
      description: "",
      type: "avo¢ash ATM",
    };
  },
  mounted: function () {
    const self = this;

    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }
    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
    this.amountToPay = parseFloat(this.decodedQRCode.amount);
    this.paymentCurrency = this.payeeAccount.currencyCode;
    this.toAccount = "Primary";
  },
  methods: {
    scanQRCodeToPayOut() {
      const self = this;

      this.scanPaymentQRCode(this.type);
      setTimeout(() => {
        this.amountToPay = parseFloat(this.decodedQRCode.amount);
        this.changeAmountToPay();
      }, 500);
    },

    changeAmountToPay() {
      const self = this;

      if (this.recipientCountry) {
        self.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then(() => {
            setTimeout(() => {
              if (
                this.decodedQRCode.paymentCurrency === this.payeeAccount.currencyCode &&
                parseFloat(this.amountToPay) > 0
              ) {
                if (
                  parseFloat(this.amountToPay) >
                  parseFloat(this.payeeAccount.accountBalance)
                ) {
                  this.amountToPay = parseFloat(this.payeeAccount.accountBalance);
                  self.$store.dispatch("transaction/calculatePayment", {
                    grossXrate: parseFloat(this.grossexchangerate2),
                    fromCountryCode: this.recipientCountry.fromCountryCode2,
                    toCountryCode: this.recipientCountry.toCountryCode2,
                    pickupMethod: "top-up",
                    amount: parseFloat(this.amountToPay),
                  });
                } else {
                  self.$store.dispatch("transaction/calculatePayment", {
                    grossXrate: parseFloat(this.grossexchangerate2),
                    fromCountryCode: this.recipientCountry.fromCountryCode2,
                    toCountryCode: this.recipientCountry.toCountryCode2,
                    pickupMethod: "top-up",
                    amount: parseFloat(this.amountToPay),
                  });
                }
              } else if (
                this.decodedQRCode.paymentCurrency !== this.payeeAccount.currencyCode
              ) {
                if (
                  parseFloat(this.amountToPay) / parseFloat(this.grossexchangerate2) >
                  parseFloat(this.payeeAccount.accountBalance)
                ) {
                  this.amountToPay =
                    parseFloat(this.payeeAccount.accountBalance) *
                    parseFloat(this.grossexchangerate2);
                  self.$store.dispatch("transaction/calculatePaymentReverse", {
                    grossXrate: parseFloat(this.grossexchangerate2),
                    fromCountryCode: this.recipientCountry.fromCountryCode2,
                    toCountryCode: this.recipientCountry.toCountryCode2,
                    pickupMethod: "top-up",
                    amount: parseFloat(this.amountToPay),
                  });
                } else {
                  self.$store.dispatch("transaction/calculatePaymentReverse", {
                    grossXrate: parseFloat(this.grossexchangerate2),
                    fromCountryCode: this.recipientCountry.fromCountryCode2,
                    toCountryCode: this.recipientCountry.toCountryCode2,
                    pickupMethod: "top-up",
                    amount: parseFloat(this.amountToPay),
                  });
                }
              } else {
                return false;
              }
            }, 1000);
          });
      } else {
        return false;
      }
    },

    onPageBeforeIn() {
      const self = this;

      this.changeAmountToPay();
    },

    PayOutNow() {
      const self = this;

      if (f7.input.validateInputs("#payOutCashwithQRScan")) {
        f7.preloader.show("multi");
        self.$store
          .dispatch("payment/makePayment", {
            type: this.type,
            amount: this.amountToPay,
            paymentCurrency: this.paymentCurrency,
            payerAccountNumber: this.payerAccount.accountNumber,
            payerGeolocation: this.decodedQRCode.payerGeolocation,
            payerIpAddress: this.decodedQRCode.payeeIpAddress,

            payeeAccountNumber: this.payeeAccount.accountNumber,
            payeeGeolocation: this.latitude + "-" + this.longitude,
            payeeIpAddress: this.ipAddress,
            description: this.description,
          })
          .then((result) => {
            console.log(result);
            setTimeout(() => {
              if (this.toAccount === "Primary") {
                self.$store.dispatch("account/loadPrimaryAccount");
              } else {
                self.$store.dispatch("account/loadBusinessAccount");
              }
            }, 500);
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
      } else {
        var valid = f7.$("#payOutCashwithQRScan .input-invalid").length === 0;
      }
    },

    cancelScan() {
      const self = this;

      // f7.dialog.confirm("Do you want to cancel the payment?", () => {
      this.amountToPay = "";
      this.userAccountNumber = "";
      self.$store.dispatch("account/resetUserByAccountNumber");
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
      self.$store.dispatch("transaction/resetDecodedQRCode");
      // });
    },
    onPageBeforeRemove() {
      const self = this;

      this.cancelScan();
    },
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "primaryTransactionList",
      "businessTransactionList",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("remit", ["recipients", "allrecipients", "userDetail"]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userByAccountNumber",
      "getusererror",
    ]),
    ...mapState("auth", ["profile"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    recipientCountry() {
      if (!this.userByAccountNumber) {
        return false;
      } else {
        return _.find(this.originCountry, [
          "fromCountryId",
          this.userByAccountNumber.country.countryId,
        ]);
      }
    },
    payeeAccount() {
      return this.primaryAccount;
    },
    payerAccount() {
      return this.userByAccountNumber.primaryAccount;
    },

    toastText() {
      if (
        parseFloat(this.decodedQRCode.payerAccountNumber) !== 0 &&
        !this.recipientCountry
      ) {
        return (
          "You cannot pay-out to" + " " + this.userByAccountNumber.country.countryName
        );
      }
    },
  },
};
</script>
