import { utils } from "framework7";
import { f7, theme } from "framework7-vue";
// import store from "../store/store.js";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
export const cardConnect = {
  data() {
    return {
      received_messages: [],
      connected: false,
      service: "card",
      path: "",
      socket: "",
      // messageSocketClient: "",
    };
  },
  mounted() {
    const self = this;
  },
  methods: {
    connectCardService() {
      const self = this;

      window.localStorage.setItem("scannedCard", "");

      // const path = `${import.meta.env.VITE_APP_URL}wss`;
      // const socket = new SockJS(`${path}`);
      // socket.binaryType = "blob";
      // this.messageSocketClient = Stomp.over(socket);
      // const headers = {};
      // // setTimeout(() => {
      // headers[self.csrfToken.headerName] = self.csrfToken.token;
      // headers["Authorization"] = `Bearer ${this.$keycloak.token}`;
      // console.log(" headers", headers);
      // this.messageSocketClient.connect(
      //   headers,
      //   // {
      //   //   "X-Authorization": `Bearer ${this.$keycloak.token}`,
      //   // },
      //   (frame) => {
      //     this.connected = true;
      // console.log(frame);
      this.messageSocketClient.subscribe("/topic/card", (data) => {
        setTimeout(() => {
          if (data.body.id !== null) {
            f7.$(".buttonIssue").hide();
          }
          f7.dialog.alert(data.body);
        }, 2000);
      });

      this.messageSocketClient.subscribe(
        "/user/queue/card.isCreated",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
            text: `Card status: <b>${cardData.status}</b>. Please get the top-up amount from customer before validating.`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.notCreated",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${cardData.status}</b>`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.isScanned",
        (data) => {
          if (data.body.cardId === null) {
            return;
          }
          const cardData = JSON.parse(data.body);
          store.dispatch("card/setScannedCard", JSON.parse(data.body));
          if (self.profile.sub === cardData.cardIssuerId) {
            const notificationCardIsScanned2 = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b>. Please get the top-up amount from customer before validating.<br/>Click to validate top-up or cancel it!`,
              closeOnClick: true,
              on: {
                close() {
                  f7.dialog
                    .create({
                      text: "Validate to confirm top-up!",
                      buttons: [
                        {
                          text: "Validate top-up",
                          color: "auto",
                          bold: true,
                          onClick() {
                            self.validateTopUp();
                          },
                        },
                        {
                          text: "Cancel top-up",
                          color: "red",
                          onClick() {
                            self.deleteQRCode();
                          },
                        },
                        {
                          text: "Close",
                          close: true,
                        },
                      ],
                      verticalButtons: true,
                    })
                    .open();
                },
              },
            });
            notificationCardIsScanned2.open();
          } else if (self.profile.sub === cardData.recipientUserId) {
            const notificationCardIsScanned = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b>. Please wait validation for your account to be topped-up!`,
              closeButton: true,
            });
            notificationCardIsScanned.open();
          }
        }
      );
      this.messageSocketClient.subscribe(
        "/user/queue/card.isLocked",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${cardData.status}</b>`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.topUpValidated",
        (data) => {
          if (data.body.cardId === null) {
            return;
          }
          const cardData = JSON.parse(data.body);
          store.dispatch("card/setScannedCard", JSON.parse(data.body));
          if (self.profile.sub === cardData.userId) {
            const notificationCardIsScanned1 = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b>`,
              closeButton: true,
            });
            notificationCardIsScanned1.open();
          } else if (self.profile.sub === cardData.recipientId) {
            const notificationCardIsScanned = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b><br/> Go to my account!`,
              closeOnClick: true,
              on: {
                close() {
                  // this.f7router.navigate("/dashboard/");
                  self.goToPrimaryAccount();
                },
              },
            });
            notificationCardIsScanned.open();
          }
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.notActive",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${cardData.status}</b>`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );
      // },
      // (error) => {
      //   console.log(error);
      //   this.connected = false;
      // }
      // );
    },
    cardDisconnect() {
      // const self = this;

      // if (this.messageSocketClient) {
      //   this.messageSocketClient.disconnect();
      // }
      // this.connected = false;
      console.log("Disconnected!");
    },
    async goToPrimaryAccount() {
      const self = this;

      f7.preloader.show("multi");
      await self.$store.dispatch("account/loadPrimaryAccount");
      f7.preloader.hide();
      await this.f7router.navigate(
        "/my-transactions/" +
          this.primaryAccount.accountNumber +
          "/primaryTransactionList"
      );
    },
  },
  computed: {
    ...mapState("auth", [
      "profile",
      "token",
      "user",
      "authenticated",
      "topath",
      "dynamicLink",
      "csrfToken",
    ]),
    ...mapState("message", ["messageSocketClient", "msgCltConnected"]),
  },
};
