import { f7, theme } from "framework7-vue";
import _ from "lodash";
export default {
    props: {
        f7route: Object,
        f7router: Object,
    },

    methods: {
        getUserByAccountNber() {
            const self = this;

            console.log(this.userAccountNumber);
            if (
                parseFloat(this.userAccountNumber) !=
                this.businessAccount.accountNumber &&
                parseFloat(this.userAccountNumber) !=
                this.primaryAccount.accountNumber &&
                this.userAccountNumber.length >= 5
            ) {
                self.$store
                    .dispatch(
                        "account/getUserByAccountNumber",
                        parseFloat(this.userAccountNumber)
                    )
                    .then(() => {
                        setTimeout(() => {
                            if (!this.recipientCountry && this.userByAccountNumber) {
                                this.toastText =
                                    "You cannot send payment to" +
                                    " " +
                                    this.userByAccountNumber.country.countryName;
                                self.$store.dispatch("account/resetUserByAccountNumber");
                                self.$store.dispatch("transaction/resetPaymentDetail");
                                self.$store.dispatch("transaction/resetPaymentReverseDetail");
                                self.$store.dispatch("transaction/resetDecodedQRCode");
                                return false;
                            }
                        }, 1000);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.toastText = `You cannot send payment to${err}`;
                    });
            } else {
                if (this.userAccountNumber) {
                    this.toastText = `You can't use your own account :${this.userAccountNumber}!`;
                }
                return false;
            }
        },
    },
};