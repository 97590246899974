import axios from "axios";
import { f7 } from "framework7-vue";
import openUrl from "@/js/mixins/cordovaPlugins.js";
// axios.defaults.headers.common["Idempotency-Key"] = self.crypto.randomUUID();
const path = import.meta.env.VITE_APP_URL + "apimoney";

// const apimoney = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      paymentIntent: "",
      bankAccount: "",
      bankAccountList: [],
      standard: "",
      business: "",

      accountEvents: "",
      accountLimits: "",
      accountDocument: "",

      creditCard: "",
      creditCardList: [],
      transaction: "",
      transaction_status: "",
      transactionList: "",
      accounts: "",
      cardInResponse: "",
      bankWire: "",
      baseTransfer: "",

      walletActivityList: [],
      accountWalletList: [],
      groupedWalletList: [],
      userWalletList: [],
      walletActivity: "",
      walletBalance: "",
      apiWallet: "",
      structuredTransactionList: [],
    },
    getters: {},
    mutations: {
      GET_STRIPE_CLIENT_SECRET(state, paymentIntent) {
        state.paymentIntent = paymentIntent;
      },
      SET_BASE_TRANSFER(state, baseTransfer) {
        state.baseTransfer = baseTransfer;
      },
      GET_BANKACCOUNT(state, bankAccount) {
        state.bankAccount = bankAccount;
      },
      GET_BANKACCOUNTLIST(state, bankAccountList) {
        state.bankAccountList = bankAccountList;
      },
      GET_TRANSACTION(state, transaction) {
        state.transaction = transaction;
      },
      GET_TRANSACTION_STATUS(state, transaction_status) {
        state.transaction_status = transaction_status;
      },

      GET_CARD_RESPONSE(state, cardInResponse) {
        state.cardInResponse = cardInResponse;
      },
      GET_BANKWIRE(state, bankWire) {
        state.bankWire = bankWire;
      },
      GET_CREDITCARDLIST(state, creditCardList) {
        state.creditCardList = creditCardList;
      },
      GET_CREDITCARD(state, creditCard) {
        state.creditCard = creditCard;
      },
      GET_ACCOUNT_EVENTS(state, accountEvents) {
        state.accountEvents = accountEvents;
      },
      GET_ACCOUNT_LIMITS(state, accountLimits) {
        state.accountLimits = accountLimits;
      },
      GET_ACCOUNT_DOC(state, accountDocument) {
        state.accountDocument = accountDocument;
      },
      GET_ACCOUNT_DOCLIST(state, accountDocumentList) {
        state.accountDocumentList = accountDocumentList;
      },
      GET_ACCOUNT_FILE(state, accountFile) {
        state.accountFile = accountFile;
      },

      GET_TRANSACTION_LIST(state, transactionList) {
        state.transactionList = transactionList;
      },
      GET_APIWALLET(state, apiWallet) {
        state.apiWallet = apiWallet;
      },
      GET_APIWALLET_ACTIVITYLIST(state, walletActivityList) {
        state.walletActivityList = walletActivityList;
      },
      GET_ACCT_APIWALLET_LIST(state, accountWalletList) {
        state.accountWalletList = accountWalletList;
      },
      GET_APIWALLET_ACTIVITY(state, walletActivity) {
        state.walletActivity = walletActivity;
      },
      GET_APIWALLET_BALANCE(state, walletBalance) {
        state.walletBalance = walletBalance;
      },
      GET_GP_APIWALLET_LIST(state, groupedWalletList) {
        state.groupedWalletList = groupedWalletList;
      },
      GET_API_USER_WALLET_LIST(state, userWalletList) {
        state.userWalletList = userWalletList;
      },
      SET_STR_TRX_LIST(state, structuredTransactionList) {
        state.structuredTransactionList = structuredTransactionList;
      },
    },
    actions: {
      setBaseTransfer({ commit }, payload) {
        commit("SET_BASE_TRANSFER", payload);
      },
      setTransactionList({ commit }, payload) {
        console.log("setTransactionList", payload);
        commit("SET_STR_TRX_LIST", payload);
      },

      addBankAccount({ commit }, payload) {
        axios({
          url: `${path}/bankAccount`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((bankAccount) => {
            console.log(payload);
            console.log(bankAccount);
            commit("GET_BANKACCOUNT", bankAccount);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getBankAccount({ commit }, payload) {
        axios({
          url: `${path}/bankAccount`,
          method: "GET",
          params: {
            bankAccountId: payload.bankAccountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((bankAccount) => {
            console.log(payload);
            console.log(bankAccount);
            commit("GET_BANKACCOUNT", bankAccount);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      editBankAccount({ commit }, payload) {
        axios({
          url: `${path}/bankAccount`,
          method: "PUT",
          data: payload.bankAccount,
          params: {
            bankAccountId: payload.bankAccountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((bankAccount) => {
            console.log(bankAccount);
            commit("GET_BANKACCOUNT", bankAccount);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteBankAccount({ commit }, payload) {
        axios({
          url: `${path}/bankAccount`,
          method: "DELETE",
          params: {
            bankAccountId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((bankAccountList) => {
            console.log(bankAccountList);
            commit("GET_BANKACCOUNTLIST", bankAccountList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getAllBankAccounts({ commit }, payload) {
        axios({
          url: `${path}/bankAccount/list`,
          method: "GET",
          params: { accountId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((bankAccountList) => {
            console.log(bankAccountList);
            commit("GET_BANKACCOUNTLIST", bankAccountList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      //Credit cards

      getCreditCard({ commit }, payload) {
        axios({
          url: `${path}/creditCard`,
          method: "GET",
          params: { creditCardId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((creditCard) => {
            console.log(payload);
            console.log(creditCard);
            commit("GET_CREDITCARD", creditCard);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAllCreditCards({ commit }, payload) {
        axios({
          url: `${path}/creditCard/list`,
          method: "GET",
          params: { accountId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((creditCardList) => {
            console.log(creditCardList);
            commit("GET_CREDITCARDLIST", creditCardList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      editCreditCard({ commit }, payload) {
        axios({
          url: `${path}/creditCard`,
          method: "PUT",
          data: payload.creditCard,
          params: {
            creditCardId: payload.creditCardId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((creditCard) => {
            console.log(creditCard);
            commit("GET_CREDITCARD", creditCard);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteCreditCard({ commit }, payload) {
        axios({
          url: `${path}/creditCard`,
          method: "DELETE",
          params: {
            creditCardId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((creditCardList) => {
            console.log(creditCardList);
            commit("GET_CREDITCARDLIST", creditCardList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      // ApiMoneyWallets

      createApiWallet({ commit }, payload) {
        axios({
          url: `${path}/wallet`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((apiWallet) => {
            console.log(apiWallet);
            commit("GET_APIWALLET", apiWallet);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getApiWallet({ commit }, payload) {
        axios({
          url: `${path}/wallet`,
          method: "GET",
          params: {
            walletId: payload.walletId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((apiWallet) => {
            console.log(apiWallet);
            commit("GET_APIWALLET", apiWallet);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      editApiWallet({ commit }, payload) {
        axios({
          url: `${path}/wallet`,
          method: "PUT",
          data: payload.apiWallet,
          params: {
            walletId: payload.walletId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((apiWallet) => {
            console.log(apiWallet);
            commit("GET_APIWALLET", apiWallet);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteApiWallet({ commit }, payload) {
        axios({
          url: `${path}/wallet`,
          method: "DELETE",
          params: {
            walletId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupedApiWalletList) => {
            console.log(groupedApiWalletList);
            commit("GET_APIWALLET_LIST", apiWalletList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getAllGroupedWallets({ commit }, payload) {
        axios({
          url: `${path}/wallet/user/grouped`,
          method: "GET",
          params: { userId: app.config.globalProperties.$keycloak.subject },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupedWalletList) => {
            console.log(groupedWalletList);
            commit("GET_GP_APIWALLET_LIST", groupedWalletList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAllWallets({ commit }) {
        axios({
          url: `${path}/wallet/user`,
          method: "GET",
          params: { userId: app.config.globalProperties.$keycloak.subject },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userWalletList) => {
            console.log(userWalletList);
            commit("GET_API_USER_WALLET_LIST", userWalletList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAccountWallets({ commit }, payload) {
        axios({
          url: `${path}/wallet/account`,
          method: "GET",
          params: { accountId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountWalletList) => {
            console.log(accountWalletList);
            commit("GET_ACCT_APIWALLET_LIST", accountWalletList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getApiWalletActivity({ commit }, payload) {
        axios({
          url: `${path}/wallet/activity`,
          method: "GET",
          params: {
            walletId: payload.walletId,
            walletActivityId: payload.walletActivityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((walletActivity) => {
            console.log(walletActivity);
            commit("GET_APIWALLET_ACTIVITY", walletActivity);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getApiWalletActivities({ commit }, payload) {
        axios({
          url: `${path}/wallet/activities`,
          method: "GET",
          params: {
            walletId: payload.walletId,
            walletActivityType: payload.walletActivityType,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((walletActivityList) => {
            console.log(walletActivityList);
            commit("GET_APIWALLET_ACTIVITYLIST", walletActivityList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getApiWalletTransactions({ commit }, payload) {
        axios({
          url: `${path}/wallet/transactions`,
          method: "GET",
          params: {
            walletId: payload.walletId,
            start_date: payload.start_date,
            end_date: payload.end_date,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transactionList) => {
            console.log(transactionList);
            commit("GET_TRANSACTION_LIST", transactionList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getApiWalletBalance({ commit }, payload) {
        axios({
          url: `${path}/wallet/balance`,
          method: "GET",
          params: {
            walletId: payload.walletId,
            timeStamp: payload.timeStamp,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((walletBalance) => {
            console.log(walletBalance);
            commit("GET_APIWALLET_BALANCE", walletBalance);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      //Api accounts

      createStandardAccount({ commit }) {
        axios({
          url: `${path}/account/standard`,
          method: "POST",
          data: app.config.globalProperties.$keycloak.userInfo,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((standard) => {
            console.log(standard);
            commit("GET_STANDARD", standard);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      editStandardAccount({ commit }, payload) {
        axios({
          url: `${path}/account/standard`,
          method: "PUT",
          data: payload.standard,
          params: { accountId: payload.accountId },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((standard) => {
            console.log(standard);
            commit("GET_STANDARD", standard);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      createBusinessAccount({ commit }, payload) {
        axios({
          url: `${path}/account/business`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((business) => {
            console.log(business);
            commit("GET_BUSINESS", business);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      editBusinessAccount({ commit }, payload) {
        axios({
          url: `${path}/account/business`,
          method: "PUT",
          data: payload.business,
          params: { accountId: payload.accountId },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((business) => {
            console.log(business);
            commit("GET_BUSINESS", business);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAccount({ commit }, payload) {
        axios({
          url: `${path}/account`,
          method: "GET",
          params: {
            accountId: payload.accountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((account) => {
            console.log(account);
            commit("GET_ACCOUNT", account);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAccountEvents({ commit }, payload) {
        axios({
          url: `${path}/account/events`,
          method: "GET",
          params: {
            accountId: payload.accountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountEvents) => {
            console.log(accountEvents);
            commit("GET_ACCOUNT_EVENTS", accountEvents);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAccountLimits({ commit }, payload) {
        axios({
          url: `${path}/account/limits`,
          method: "GET",
          params: {
            accountId: payload.accountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountLimits) => {
            console.log(accountLimits);
            commit("GET_ACCOUNT_LIMITS", accountLimits);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      createAccountDocument({ commit }, payload) {
        axios({
          url: `${path}/account/document`,
          method: "POST",
          data: payload.formData,
          params: { accountId: payload.accountId },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountDocument) => {
            console.log(accountDocument);
            commit("GET_ACCOUNT_DOC", accountDocument);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      editAccountDocument({ commit }, payload) {
        axios({
          url: `${path}/account/document`,
          method: "PUT",
          data: payload.formData,
          params: { accountId: payload.accountId },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountDocument) => {
            console.log(accountDocument);
            commit("GET_ACCOUNT_DOC", accountDocument);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteAccountDocument({ commit }, payload) {
        axios({
          url: `${path}/account/document`,
          method: "DELETE",
          params: {
            accountId: payload.accountId,
            documentId: payload.documentId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountDocumentList) => {
            console.log(accountDocumentList);
            commit("GET_ACCOUNT_DOCLIST", accountDocumentList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAccountDocument({ commit }, payload) {
        axios({
          url: `${path}/account/document`,
          method: "GET",
          params: {
            accountId: payload.accountId,
            documentId: payload.documentId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountDocument) => {
            console.log(accountDocument);
            commit("GET_ACCOUNT_DOC", accountDocument);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAccountDocumentList({ commit }, payload) {
        axios({
          url: `${path}/account/documents`,
          method: "GET",
          params: {
            accountId: payload.accountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountDocumentList) => {
            console.log(accountDocumentList);
            commit("GET_ACCOUNT_DOCLIST", accountDocumentList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createAccountFile({ commit }, payload) {
        axios({
          url: `${path}/account/file`,
          method: "POST",
          data: payload.formData,
          params: {
            accountId: payload.accountId,
            documentId: payload.documentId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountFile) => {
            console.log(accountFile);
            commit("GET_ACCOUNT_FILE", accountFile);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      deleteAccountFile({ commit }, payload) {
        axios({
          url: `${path}/account/file`,
          method: "DELETE",
          params: {
            accountId: payload.accountId,
            fileId: payload.fileId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountDocumentList) => {
            console.log(accountDocumentList);
            commit("GET_ACCOUNT_DOCLIST", accountDocumentList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAccountFile({ commit }, payload) {
        axios({
          url: `${path}/account/file`,
          method: "GET",
          params: {
            accountId: payload.accountId,
            fileId: payload.fileId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((accountDocumentList) => {
            console.log(accountDocumentList);
            commit("GET_ACCOUNT_DOCLIST", accountDocumentList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      //Cash-In transaction

      authorizeCashInBankwire({ commit }, payload) {
        axios({
          url: `${path}/cash-in/bankwire/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": `${payload.idempotency_key}`,
          },
        })
          .then((r) => r.data)
          .then((bankWire) => {
            commit("GET_BANKWIRE", bankWire);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      authorizeCashInMobileMoney({ commit }, payload) {
        axios({
          url: `${path}/cash-in/mobile-money/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((cardInResponse) => {
            commit("GET_CARD_RESPONSE", cardInResponse);
            console.log("cardInResponse", cardInResponse);
            if (
              cardInResponse &&
              cardInResponse.payment_url !== null &&
              cardInResponse.payment_token !== null &&
              cardInResponse.order_id !== null
            ) {
              // window.location = cardInResponse.payment_url;
              openUrl.openLink(cardInResponse.payment_url);
            } else if (
              cardInResponse &&
              cardInResponse.user_msisdn !== null &&
              cardInResponse.otp_code !== null
            ) {
              commit("GET_TRANSACTION_STATUS", cardInResponse);
              f7.views.main.router.navigate("/transaction/sucess", {
                params: {
                  bizao_mobile_order_id: cardInResponse["order-id"],
                  props: { transaction_status: cardInResponse },
                },
              });
              console.log("cardInResponse", cardInResponse);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      initiateCreditCardCashIn({ commit }, payload) {
        axios({
          url: `${path}/cash-in/creditcards/init`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": `${payload.idempotency_key}`,
          },
        })
          .then((r) => r.data)
          .then((cardInResponse) => {
            console.log("cardInResponse", cardInResponse);
            commit("GET_CARD_RESPONSE", cardInResponse);
            if (
              cardInResponse &&
              cardInResponse.payment_url !== null &&
              cardInResponse.payment_token !== null &&
              cardInResponse.order_id !== null
            ) {
              // window.location = cardInResponse.payment_url;
              openUrl.openLink(cardInResponse.payment_url);
            }
            if (
              cardInResponse?.body &&
              cardInResponse.body.client_secret !== null &&
              cardInResponse.body.id !== null
            ) {
              commit("GET_STRIPE_CLIENT_SECRET", cardInResponse.body);
              console.log("cardInResponse", cardInResponse);
            }
          })
          .catch((err) => {
            console.log(err);
            // f7.dialog.alert(err.response.data.message);
          });
      },
      executeSingleStepCreditCardCashIn({ commit }, payload) {
        axios({
          url: `${path}/cash-in/creditcards/single-step`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
            if (transaction && transaction.client_secret !== null) {
              commit("GET_STRIPE_CLIENT_SECRET", transaction);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      createRegisteredCreditCardCashInAuth({ commit }, payload) {
        axios({
          url: `${path}/cash-in/creditcards/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
            if (transaction && transaction.client_secret !== null) {
              commit("GET_STRIPE_CLIENT_SECRET", transaction);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      confirmAuthorizedCashIn({ commit }, payload) {
        axios({
          url: `${path}/cash-in/confirm`,
          method: "PUT",
          params: {
            transactionId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      cancelAuthorizedCashIn({ commit }, payload) {
        axios({
          url: `${path}/cash-in/cancel`,
          method: "DELETE",
          params: {
            transactionId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      //Transactions

      getTransaction({ commit }, payload) {
        axios({
          url: `${path}/transaction`,
          method: "GET",
          params: {
            transactionId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getPartnerRefTransaction({ commit }, payload) {
        axios({
          url: `${path}/transaction/partner_ref`,
          method: "GET",
          params: {
            partner_ref: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getTransactionStatus({ commit }, payload) {
        axios({
          url: `${path}/transaction/status`,
          method: "GET",
          params: payload,
          // {
          //   partner_ref: payload.partner_ref,
          //   transactionId: payload.transactionId,
          //   bizao_mobile_order_id: payload.bizao_mobile_order_id,
          //   bizao_card_order_id: payload.bizao_card_order_id,
          //   bizao_batchNumber: payload.bizao_batchNumber,
          //   wave_check_out_id: payload.wave_check_out_id,
          //   wave_pay_out_id: payload.wave_pay_out_id,
          // },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction_status) => {
            commit("GET_TRANSACTION_STATUS", transaction_status);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getWalletTransactionList({ commit }, payload) {
        axios({
          url: `${path}/transaction/wallet`,
          method: "GET",
          params: {
            walletId: payload.walletId,
            start_date: payload.start_date,
            end_date: payload.end_date,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transactionList) => {
            commit("GET_TRANSACTION_LIST", transactionList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      refundTransaction({ commit }, payload) {
        axios({
          url: `${path}/transaction/refund`,
          method: "PUT",
          data: payload,
          params: {
            transactionId: payload.id,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getTransactionReceipt({ commit }, payload) {
        axios({
          url: `${path}/transaction/receipt`,
          method: "GET",
          params: {
            transactionId: payload.transactionId,
            lang: payload.lang,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      //Cash-Out transaction

      authorizeCashOutBankwire({ commit }, payload) {
        axios({
          url: `${path}/cash-ou/bankwire/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      executeSingleStepCashOut({ commit }, payload) {
        axios({
          url: `${path}/cash-ou/bankwire/single-step`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      authorizeCashOutMobileMoney({ commit }, payload) {
        axios({
          url: `${path}/cash-out/mobile-money/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      executeCashOutSingleStepMobileMoney({ commit }, payload) {
        axios({
          url: `${path}/cash-out/mobile-money/single-step`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      initiateCashOutCreditCardAuthorization({ commit }, payload) {
        axios({
          url: `${path}/cash-out/creditcards/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createCashOutCashAuthorization({ commit }, payload) {
        axios({
          url: `${path}/cash-out/cash/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createCashOutCashAuthorization({ commit }, payload) {
        axios({
          url: `${path}/cash-out/cash/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createCashOutATMAuthorization({ commit }, payload) {
        axios({
          url: `${path}/cash-out/atm/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createCashOutVirtualCardAuthorization({ commit }, payload) {
        axios({
          url: `${path}/cash-out/virtualcard/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      confirmAuthorizedCashOut({ commit }, payload) {
        axios({
          url: `${path}/cash-out/confirm`,
          method: "PUT",
          params: {
            transactionId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      cancelAuthorizedCashOut({ commit }, payload) {
        axios({
          url: `${path}/cash-out/cancel`,
          method: "DELETE",
          params: {
            transactionId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      //Transfer

      createTransferAuthorization({ commit }, payload) {
        axios({
          url: `${path}/transfer/authorize`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
            f7.dialog.alert(err.response.data.message);
          });
      },

      executeSingleTransfer({ commit }, payload) {
        axios({
          url: `${path}/transfer/single-step`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            "Idempotency-Key": payload.idempotency_key,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
            f7.dialog.alert(err.response.data.message);
          });
      },

      confirmTransferAuthorization({ commit }, payload) {
        axios({
          url: `${path}/transfer/confirm`,
          method: "PUT",
          params: {
            transactionId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      cancelTransferAuthorization({ commit }, payload) {
        axios({
          url: `${path}/transfer/cancel`,
          method: "DELETE",
          params: {
            transactionId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((transaction) => {
            commit("GET_TRANSACTION", transaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
    },
  };
};
// export default apimoney;
