<template>
  <f7-page infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore" keep-navbar-on-scoll
    no-toolbar>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false" navbar-transparent
      v-if="this.isTablet == true">
      <f7-nav-title sliding>
        {{ this.transactionType }}:
        <span v-if="this.f7route.params.accounttype === 'primaryTransactionList'">
          <span v-html="$filtersToCurrency.toCurrency(
            this.primaryAccount.accountBalance,
            this.primaryAccount.currencySymbol,
            ''
          )
            "></span>
        </span>
        <span v-else>
          <span v-html="$filtersToCurrency.toCurrency(
            this.businessAccount.accountBalance,
            this.businessAccount.currencySymbol,
            ''
          )
            "></span>
        </span>
      </f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-transactions" icon-aurora="f7:search_strong"
          icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
        <navbarright></navbarright>
      </f7-nav-right>
      <f7-searchbar class="searchbar-transactions" expandable search-container=".transactions-list"
        :placeholder="$t('mytransactions.searchATransaction')" search-in=".item-title"></f7-searchbar>
    </f7-navbar>
    <f7-navbar sliding large no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false"
      navbar-transparent v-else>
      <f7-nav-title sliding>
        {{ this.transactionType }}:
        <span v-if="this.f7route.params.accounttype === 'primaryTransactionList'">
          <span v-html="$filtersToCurrency.toCurrency(
            this.primaryAccount.accountBalance,
            this.primaryAccount.currencySymbol,
            ''
          )
            "></span>
        </span>
        <span v-else>
          <span v-html="$filtersToCurrency.toCurrency(
            this.businessAccount.accountBalance,
            this.businessAccount.currencySymbol,
            ''
          )
            "></span>
        </span>
      </f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-transactions" icon-aurora="f7:search_strong"
          icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
        <navbarright></navbarright>
      </f7-nav-right>
      <f7-searchbar class="searchbar-transactions" expandable search-container=".transactions-list"
        :placeholder="$t('recipientdetail.searchATransaction')" search-in=".item-title"></f7-searchbar>
      <f7-nav-title-large sliding style="font-size: 50%; white-space: normal; word-wrap: break-word">
        {{ this.transactionType }}:
        <span v-if="this.f7route.params.accounttype === 'primaryTransactionList'">
          <span v-html="$filtersToCurrency.toCurrency(
            this.primaryAccount.accountBalance || 0,
            this.primaryAccount.currencySymbol,
            this.primaryAccount.currencyCode
          )
            "></span>
        </span>
        <span v-else>
          <span v-html="$filtersToCurrency.toCurrency(
            this.businessAccount.accountBalance,
            this.businessAccount.currencySymbol,
            this.businessAccount.currencyCode
          )
            "></span>
        </span>
      </f7-nav-title-large>
    </f7-navbar>
    <!-- <f7-row no-gap>
      <f7-col width="0" medium="25"></f7-col>
      <f7-col
        width="100"
        medium="50"
        v-if="this.transactionList && this.transactionList.length < 1"
      >
        <h4 style="font-style: italic" class="margin-left">{{ $t('mytransactions.noTransaction') }}</h4>
      </f7-col>

      <f7-col width="100" medium="50" v-else> -->
    <!-- <div v-if="this.transactionList && this.transactionList.length < 1">
      <span class="padding-left">{{ $t("mytransactions.noTransaction") }}</span>
    </div> -->

    <f7-list dividers-ios strong-ios v-if="(this.f7route.params.accounttype === 'primaryTransactionList' &&
        !this.primaryTransactionList) ||
      (this.f7route.params.accounttype === 'businessTransactionList' &&
        !this.businessTransactionList)
      " media-list>
      <f7-list-item v-for="n in 10" :key="n" class="skeleton-text skeleton-effect-blink" title="Full Name"
        subtitle="2,00 EUR" footer="avocash pay wed">
        <div slot="after-title">2,00 EUR</div>
        <span slot="after-start">After Start</span>
        <span slot="after">After End</span>
        <f7-skeleton-block style="width: 40px; height: 40px; border-radius: 50%" slot="media"></f7-skeleton-block>
      </f7-list-item>
    </f7-list>
    <div v-else-if="!!this.transactionList">
      <f7-list dividers-ios strong-ios transaction-list class="searchbar-not-found" media-list
        v-if="!!this.transactionList && this.transactionList.length < 1">
        <f7-list-item :title="$t('recipientdetail.noSuchTransaction')" style="font-size: 18px; color: red">
        </f7-list-item>
      </f7-list>
      <f7-list dividers-ios strong-ios v-if="!!this.transactionList && this.transactionList.length >= 1"
        class="transactions-list searchbar-found" media-list accordion-list
        v-for="(transactions, transactionYearMonth, index) in this.transactionList" :key="index">
        <!-- <f7-list-group

        > -->
        <f7-list-item group-title text-color="primary"
          style="font-size: 18px; white-space: normal; word-wrap: break-word">
          {{ transactions.transactionYearMonth }}
          <span v-if="profile && primaryAccount" style="font-size: 75%; font-style: italic">
            <f7-icon ios="f7:arrow_up_circle" aurora="f7:arrow_up_circle" md="material:arrow_circle_up" color="primary"
              size="22px"></f7-icon><span v-html="$filtersToCurrency.toCurrency(
                transactions.subTotalReceived,
                '',
                primaryAccount.currencyCode
              )
                "></span>
          </span>
          <!-- Intl.NumberFormat(
                  profile.countryCode + "-" + profile.countryCode.toLowerCase(),
                  { style: "currency", currency: primaryAccount.currencyCode }
                ).format(transactions.subTotalReceived) -->
          <span v-if="profile && primaryAccount" style="font-size: 75%; font-style: italic; color: red">
            <f7-icon ios="f7:arrow_down_circle" aurora="f7:arrow_down_circle" md="material:arrow_circle_down" color="red"
              size="22px"></f7-icon><span v-html="$filtersToCurrency.toCurrency(
                transactions.subTotalPaid,
                '',
                primaryAccount.currencyCode
              )
                "></span>
          </span>
          <!-- Intl.NumberFormat(
                  profile.countryCode + "-" + profile.countryCode.toLowerCase(),
                  { style: "currency", currency: primaryAccount.currencyCode }
                ).format(transactions.subTotalPaid) -->
          <span v-if="profile && primaryAccount" style="font-size: 75%; font-style: italic; font-weight: bold">
            <f7-icon ios="f7:arrow_right_circle" aurora="f7:arrow_right_circle" md="f7:arrow_right_circle" color="gray"
              size="22px"></f7-icon>
            <span v-if="transactions.subTotalMonth < 0" style="color: red"><span v-html="$filtersToCurrency.toCurrency(
              transactions.subTotalMonth,
              '',
              primaryAccount.currencyCode
            )
              "></span>
            </span>
            <span v-else>
              <span v-html="$filtersToCurrency.toCurrency(
                transactions.subTotalMonth,
                '',
                primaryAccount.currencyCode
              )
                "></span>
              <!-- {{

                  Intl.NumberFormat(
                    profile.countryCode +
                      "-" +
                      profile.countryCode.toLowerCase(),
                    {
                      style: "currency",
                      currency: primaryAccount.currencyCode,
                    }
                  ).format(transactions.subTotalMonth),
                }} -->
            </span>
          </span>
        </f7-list-item>
        <!-- <f7-card class="no-padding-left"> -->
        <span v-for="transaction in transactions.items" :key="transaction.id">
          <li :padding="false" class="accordion-item"
            v-if="transaction.isValidated === false || transaction.validated === false" style="border: 1px orange solid">
            <a href="#" class="item-content item-link">
              <div class="item-media">
                <f7-badge v-if="transaction.counterPartName !== null" color="auto"
                  style="height: 32px; width: 32px; font-size: 16px">
                  {{
                    $filtersGetInitial.getInitial(transaction.counterPartName)
                  }}</f7-badge>
                <f7-badge v-else-if="transaction.transactionType === 'account top-up'" color="auto"
                  style="height: 32px; width: 32px; font-size: 16px">
                  {{
                    $filtersGetInitial.getInitial(transaction.primaryAccount.name)
                  }}</f7-badge>
                <img v-else slot="media" :src="'https://flagcdn.com/' +
                    transaction.countryCode.toLowerCase() +
                    '.svg'
                    " width="24" />
              </div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">
                    {{ transaction.counterPartName }}
                  </div>
                  <div class="item-title" style="color: red" v-if="transaction.debitCreditType === 'debit'">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transaction.amount,
                      transaction.currencySymbol,
                      transaction.currencyCode
                    )
                      "></span>
                  </div>
                  <div class="item-after" v-else>
                    <span v-html="$filtersToCurrency.toCurrency(
                      transaction.amount,
                      transaction.currencySymbol,
                      transaction.currencyCode
                    )
                      ">
                    </span>
                  </div>
                </div>
                <div class="item-title-row no-chevron">
                  <div class="item-footer">
                    {{ transaction.transactionType }}
                  </div>
                  <div class="item-after">
                    <span class="badge color-green" v-if="transaction.status === 'Paid' || transaction.status === 'Complete'
                      ">{{ transactionStatus(transaction) }}</span>
                    <span class="badge color-orange" v-else-if="transaction.status === 'Pending'">{{
                      transactionStatus(transaction) }}</span>
                    <span class="badge color-red" v-else-if="transaction.status === 'Cancelled'">{{
                      transactionStatus(transaction) }}</span>
                  </div>
                </div>
                <div class="item-footer">
                  {{ $filtersToDate.toDateTime(transaction.date) }}
                </div>
                <div class="item-text" v-html="transaction.description"></div>
              </div>
            </a>
            <f7-accordion-content>
              <f7-list dividers-ios strong-ios media-list>
                <f7-button v-if="transaction.transactionType === 'avo¢ash top-up' &&
                  parseFloat(transaction.amount) < 0 &&
                  transaction.debitCreditType === 'debit'
                  " :text="$t('recipientdetail.topupAgain')"
                  @click="topupAgainFromAccount(transaction)" color="red" outline text-color="red" small raised
                  class="margin-horizontal"></f7-button>
                <f7-button v-if="transaction.transactionType === 'avo¢ash pick-up' &&
                  parseFloat(transaction.amount) < 0 &&
                  transaction.debitCreditType === 'debit'
                  " :text="$t('recipientdetail.sendAgain')" @click="sendAgainFromAccount(transaction)"
                  color="green" outline text-color="green" small raised
                  class="margin-horizontal margin-top-half"></f7-button>
                <f7-button v-if="transaction" :text="$t('recipientdetail.seeDetail')"
                  @click="toTransactionDetail(transaction)" color="auto" outline small raised
                  class="margin-horizontal margin-top-half"></f7-button>
                <f7-row class="margin-horizontal-left no-margin-bottom">
                  <f7-col width="65" medium="80">
                    <f7-list-input type="text" :label="$t('common.transferCode')" disabled readonly
                      @input="transferCode = $event.target.value" :value="transaction.transferCode" />
                  </f7-col>
                  <f7-col width="10" medium="5" class="no-margin-bottom"></f7-col>
                  <f7-col width="25" medium="15">
                    <f7-list-input v-if="(transaction.debitCreditType === 'debit' &&
                        transaction.transactionType !== 'avo¢ash pick-up') ||
                      transaction.transactionType === 'account top-up'
                      " type="text" :label="$t('common.pin')" inputmode="numeric"
                      :value="transaction.transferPIN" @input="transferPIN = $event.target.value"
                      @keyup.enter="validateTransaction(transaction)" pattern="[0-9]*" maxlength="4" size="4"
                      required></f7-list-input>
                    <f7-list-input v-else type="text" floating-label :label="$t('common.pin')"
                      :placeholder="$t('common.pin')" inputmode="numeric" input-id="transferPIN" value="°°°°"
                      @input="transferPIN = $event.target.value" @keyup.enter="validateTransaction(transaction)"
                      pattern="[0-9]*" maxlength="4" size="4" required>
                    </f7-list-input>
                  </f7-col>
                </f7-row>
                <f7-row no-gap v-if="(transaction.debitCreditType === 'debit' &&
                    transaction.transactionType !== 'avo¢ash pick-up') ||
                  transaction.transactionType === 'account top-up'
                  ">
                  <f7-col width="100" medium="50">
                    <f7-button raised fill ripple class="margin-horizontal margin-bottom-half" color="red" strong
                      @click="validateDebitTransaction(transaction)">{{ $t("mytransactions.validateOutTransfer") }}
                    </f7-button>
                  </f7-col>
                  <f7-col width="100" medium="50">
                    <f7-button outline raised ripple class="margin-horizontal margin-bottom-half" color="red" strong
                      @click="cancelTransaction(transaction)">{{ $t("mytransactions.cancelTransfer") }}</f7-button>
                  </f7-col>
                </f7-row>
                <f7-button v-else raised fill ripple class="margin-horizontal margin-bottom-half" color="red" strong
                  @click="
                    validateCreditTransaction(
                      transaction.transactionType,
                      transaction.transferCode
                    )
                    ">{{ $t("mytransactions.validateInTransfer") }}</f7-button>
              </f7-list>
            </f7-accordion-content>
          </li>

          <li :padding="false" class="accordion-item"
            v-if="transaction.isValidated === true || transaction.validated === true">
            <a href="#" class="item-content item-link">
              <div class="item-media">
                <f7-badge v-if="transaction.counterPartName !== null" color="auto"
                  style="height: 32px; width: 32px; font-size: 16px">
                  {{
                    $filtersGetInitial.getInitial(transaction.counterPartName)
                  }}</f7-badge>
                <!-- <f7-badge
                      v-else-if="transaction.transactionType ==='account top-up'"
                      color="auto"
                      size="44px"
                      style="height: 32px; width: 32px; font-size: 16px;"
                    >{{((transaction.primaryAccount.name.split(" ")[0]) || ' ').charAt(0)}}{{((transaction.primaryAccount.name.split(" ")[1]) || ' ').charAt(0)}}</f7-badge>-->
                <img v-else slot="media" :src="'https://flagcdn.com/' +
                    transaction.countryCode.toLowerCase() +
                    '.svg'
                    " width="24" />
              </div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">
                    {{ transaction.counterPartName }}
                  </div>
                  <div class="item-title" style="color: red" v-if="transaction.debitCreditType === 'debit'">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transaction.amount,
                      transaction.currencySymbol,
                      transaction.currencyCode
                    )
                      "></span>
                  </div>
                  <div class="item-after" v-else>
                    <span v-html="$filtersToCurrency.toCurrency(
                      transaction.amount,
                      transaction.currencySymbol,
                      transaction.currencyCode
                    )
                      "></span>
                  </div>
                </div>
                <div class="item-title-row no-chevron">
                  <div class="item-footer">
                    {{ transaction.transactionType }}
                  </div>
                  <div class="item-after">
                    <span class="badge color-green" v-if="transaction.status === 'Paid' || transaction.status === 'Complete'
                      ">{{ transactionStatus(transaction) }}</span>
                    <span class="badge color-orange" v-else-if="transaction.status === 'Pending'">{{
                      transactionStatus(transaction) }}</span>
                    <span class="badge color-red" v-else-if="transaction.status === 'Cancelled'">{{
                      transactionStatus(transaction) }}</span>
                  </div>
                </div>
                <div class="item-footer">
                  {{ $filtersToDate.toDateTime(transaction.date) }}
                </div>
                <div class="item-text" v-html="transaction.description"></div>
              </div>
            </a>
            <f7-accordion-content>
              <f7-list dividers-ios strong-ios media-list>
                <f7-button v-if="transaction" :text="$t('recipientdetail.seeDetail')"
                  @click="toTransactionDetail(transaction)" color="auto" outline small raised class="margin-horizontal">
                </f7-button>
                <f7-button v-if="transaction.transactionType === 'avo¢ash top-up' &&
                  parseFloat(transaction.amount) < 0 &&
                  transaction.debitCreditType === 'debit'
                  " :text="$t('recipientdetail.topupAgain')"
                  @click="topupAgainFromAccount(transaction)" color="red" outline text-color="red" small raised
                  class="margin-horizontal margin-top-half"></f7-button>
                <f7-button v-if="transaction.transactionType === 'avo¢ash pick-up' &&
                  parseFloat(transaction.amount) < 0 &&
                  transaction.debitCreditType === 'debit'
                  " :text="$t('recipientdetail.sendAgain')" @click="sendAgainFromAccount(transaction)"
                  color="green" outline text-color="green" small raised
                  class="margin-horizontal margin-top-half"></f7-button>

                <!-- <a @click="toTransactionDetail(transaction)" class="color-orange">{{ $t('mytransactions.seeDetail') }}</a> -->
                <f7-row class="margin-horizontal-left no-margin-bottom" v-if="profile.sub !== transaction.counterPartId">
                  <f7-col width="65" medium="80">
                    <f7-list-item header="Transfer code" :subtitle="transaction.transferCode" />
                  </f7-col>
                  <f7-col width="10" medium="5" class="no-margin-bottom"></f7-col>
                  <f7-col width="25" medium="15">
                    <f7-list-item v-if="transaction.debitCreditType === 'debit' ||
                      transaction.transactionType === 'account top-up'
                      " header="PIN" :subtitle="transaction.transferPIN"></f7-list-item>
                    <f7-list-item v-else></f7-list-item>
                  </f7-col>
                </f7-row>
              </f7-list>
            </f7-accordion-content>
          </li>
        </span>
        <!-- </f7-card> -->
        <!-- </f7-list-group> -->
      </f7-list>
    </div>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7NavTitleLarge,
  f7List,
  f7Link,
  f7Searchbar,
  f7Badge,
  f7AccordionContent,
  f7Button,
  f7ListGroup,
  f7ListInput,
  f7ListItem,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { transferAgain } from "../js/mixins/transaction.js";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7NavTitleLarge,
    f7List,
    f7Link,
    f7Searchbar,
    f7Badge,
    f7AccordionContent,
    f7Button,
    f7ListGroup,
    f7ListInput,
    f7ListItem,

    f7Icon,
  },
  mixins: [transferAgain, keyPadMixin],
  data() {
    return {
      theme,
      f7,
      accountTypeList: "",
      accountnumber: "",
      visibleItem: 25,
      transferCode: "",
      transferPIN: "",
      allowInfinite: true,
      showPreloader: false,
    };
  },

  mounted() {
    const self = this;

    // if (this.f7route.params.accounttype === "primaryTransactionList") {
    //   self.$store.dispatch("transaction/loadPrimaryTransactionList", this.visibleItem);
    // } else {
    //   self.$store.dispatch("transaction/loadBusinessTransactionList", this.visibleItem);
    // }

    this.accountTypeList = this.f7route.params.accounttype;
    this.accountnumber = this.f7route.params.accountNumber;
    // if (this.isDeskTop == true) {
    //   f7.$("div.page-content").attr("style", "--f7-page-navbar-offset:0px");
    // }
  },
  methods: {
    // transactionActions(transaction) {
    //   const self = this;
    //
    //   const transactData = "";

    //   if (transaction.type === "pick-up") {
    //     this.transactData = {
    //       text: "Send Again",
    //       color: "auto",
    //       bold: true,
    //       onClick: function() {
    //         self.sendAgain(transaction);
    //       }
    //     };
    //   } else if (transaction.type === "top-up") {
    //     this.transactData = {
    //       text: "Top-up Again",
    //       color: "red",
    //       bold: true,
    //       onClick: function() {
    //         self.topupAgain(transaction);
    //       }
    //     };
    //   } else if (
    //     transaction.type !== "top-up" ||
    //     transaction.type !== "pick-up"
    //   ) {
    //     this.transactData = {
    //       text: "Transaction details",
    //       label: true
    //     };
    //   }
    //   console.log(this.transactData);
    //   console.log(transaction);
    //   if (!self.actionsToPopover) {
    //     self.actionsToPopover = f7.actions.create({
    //       buttons: [
    //         [self.transactData],

    //         // {
    //         //   text:
    //         //     JSON.stringify(transaction.pickupTransaction) !== null
    //         //       ? "Send Again"
    //         //       : "",
    //         //   color: "auto",
    //         //   onClick: function() {
    //         //     self.sendAgain(transaction);
    //         //   }
    //         // },
    //         // {
    //         //   text:
    //         //     JSON.stringify(transaction.topupTransaction) !== null
    //         //       ? "Top-up Again"
    //         //       : "",
    //         //   color: "red",
    //         //   onClick: function() {
    //         //     self.topupAgain(transaction);
    //         //   }
    //         // }
    //         [
    //           {
    //             text: "See transaction detail",
    //             onClick: function() {
    //               self.toTransactionDetail(transaction);
    //             }
    //           },
    //           {
    //             text: "Cancel",
    //             color: "red"
    //           }
    //         ]
    //       ],
    //       targetEl: self.$el.querySelector(".button-to-popover")
    //     });
    //   }

    //   self.actionsToPopover.open();
    // },
    // onPageBeforeRemove() {
    //   const self = this;
    //   if (self.actionsToPopover) {
    //     self.actionsToPopover.destroy();
    //   }
    // },
    openPopUp() {

      f7.popup.open(".list-icon-actions");
    },
    toTransactionDetail(transaction) {

      this.f7router.navigate(
        `/my-transactions/detail/${this.accountTypeLink}/${transaction.id}`
      );
    },
    async deleteTransaction(transaction) {
      const self = this;

      f7.actions.close();
      f7.dialog.confirm(
        this.$t("mytransactions.doYouWantToDelete", { transactionId: transaction.id }),
        () => {
          self.$store.dispatch("transactionDelete", transaction.id).then(() => {
            f7.dialog.alert(self.$t("mytransactions.transactionDeletedConfirm"));
          });
        },
        () => {
          return false;
        }
      );
    },

    loadMore() {
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      if (this.transactionListType.length < this.visibleItem) {
        this.showPreloader = false;
        return;
      }

      setTimeout(() => {
        this.visibleItem += 25;
        console.log(this.visibleItem);
        if (this.f7route.params.accounttype === "primaryTransactionList") {
          this.$store.dispatch(
            "transaction/loadPrimaryTransactionList",
            this.visibleItem
          );
        } else {
          this.$store.dispatch(
            "transaction/loadBusinessTransactionList",
            this.visibleItem
          );
        }
        this.allowInfinite = true;
      }, 1000);
    },

    //       const self = this;
    //   if (!self.allowInfinite) return;
    //   self.allowInfinite = false;

    //   setTimeout(() => {
    //     if (self.visibleItem >= 100) {
    //       self.showPreloader = false;
    //       return;
    //     }
    //     self.visibleItem += 25;
    //     // for (var i = 1; i <= 25; i++) {
    //     console.log("item 1: " + self.visibleItem);
    //     if (self.$ff7route.params.accounttype === "primaryTransactionList") {
    //       self.$store.dispatch(
    //         "transaction/loadPrimaryTransactionList",
    //         self.visibleItem
    //       );
    //     } else {
    //       self.$store.dispatch(
    //         "transaction/loadBusinessTransactionList",
    //         self.visibleItem
    //       );
    //     }
    //     console.log("item 2: " + self.visibleItem);
    //     // }
    //     self.allowInfinite = true;
    //   }, 1000);
    // },
  },

  computed: {
    ...mapState("transaction", ["primaryTransactionList", "businessTransactionList"]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "getusererror",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("transaction", ["grossexchangerate2"]),

    transactionListType() {
      return this.f7route.params.accounttype === "primaryTransactionList"
        ? this.primaryTransactionList
        : this.businessTransactionList;
    },

    transactionList() {
      const transactionListTypeA = this.transactionListType;
      return _.chain(transactionListTypeA.slice())
        .orderBy(["date"], ["desc"])
        .map((transaction) =>
          _.extend(
            {
              transactionYearMonth: transaction.date.substr(0, 7),
            },
            transaction
          )
        )
        .groupBy("transactionYearMonth")
        .map((objs, key, items) => ({
          transactionYearMonth: key,
          subTotalReceived: _.sumBy(objs, (item) => {
            return parseFloat(item.amount) > 0 ? item.amount : 0;
          }),
          subTotalPaid: _.sumBy(objs, (item) => {
            return parseFloat(item.amount) < 0 ? item.amount : 0;
          }),
          subTotalMonth: _.sumBy(objs, "amount"),
          items: objs,
        }))
        .value();
    },

    transactionType() {
      return this.accountTypeList === "primaryTransactionList" ? "Primary" : "Business";
    },
    accountTypeLink() {
      return this.accountTypeList === "primaryTransactionList"
        ? "primaryTransactionList"
        : "businessTransactionList";
    },
  },
};
</script>
