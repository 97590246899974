import { f7, theme } from "framework7-vue";
import { utils } from "framework7";
import _ from "lodash";
export const tontine = {
  mounted() {
    const self = this;

    if (this.tontine) {
      this.share();
    }
  },
  methods: {
    //Tontine List
    async share() {
      const self = this;

      const files = [];
      const base64Files = [];
      if (this.tontine && this.tontine.tontineImageList.length > 0) {
        for (const tontineImageItem of this.tontine.tontineImageList) {
          const blob = await (await fetch(tontineImageItem.url)).blob();
          // if (f7.device.cordova == true) {
          const termination = blob.type.split("/")[1];
          const file = new File(
            [blob],
            `${tontineImageItem.name}.${termination}`,
            { type: blob.type }
          );
          files.push(file);
          base64Files.push(tontineImageItem.url);
          if (this.fileToSave) {
            this.fileToSave.push(file);
          }
        }
      }

      this.sharedContent = {
        message: this.tontine.tag,
        subject: this.tontine.name,
        files,
        base64Files,
        link: `${f7.views.main.router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        imgUrl: "https://avocash.com/34x34.png",
      };

      // self.$store.dispatch("auth/createDynamicLink", this.sharedContent);
      console.log(this.sharedContent);
    },

    pageInit() {
      const self = this;
      // if (this.tontineList) {
      //   return;
      // }
      self.$store.dispatch("tontine/getUserTontinesByuserId", {
        maxNumberOfItem: this.visibleItem,
      });
      if (this.tontine) {
        this.share();
      }
    },
    async addRound() {
      const self = this;

      if (f7.input.validateInputs("#addRound")) {
        await self.$store.dispatch("tontine/createRound", {
          participantId: this.roundTaker,
          tontineId: this.tontine.id,
          round: {
            roundNumber: parseFloat(this.tontine.tontineRoundList.length) + 1,
            tontineRoundAmount: this.tontineRoundAmount,
          },
        });
        f7.popup.close(".addNewRound");
      }
    },
    async addParticipant(searchedUser) {
      const self = this;
      await self.$store.dispatch("tontine/addParticipant", {
        userId: searchedUser.userId,
        tontineId: this.tontine.id,
      });
      f7.popup.close(".addNewParticipant");
    },
    async selectRound(round) {
      const self = this;
      f7.tab.show(`#tab-${round.roundNumber}`, true);
      this.currentRound = round;
    },
    async removeParticipant(participant) {
      const self = this;

      await self.$store.dispatch("tontine/removeParticipant", {
        userId: participant.userId,
        tontineId: this.tontine.id,
      });
    },
    async grantAdminRole(participant) {
      const self = this;

      console.log(participant);
      await self.$store.dispatch("tontine/grantAdminRole", {
        tontineId: this.tontine.id,
        userId: participant.userId,
      });
    },
    async withdrawAdminRole(participant) {
      const self = this;

      await self.$store.dispatch("tontine/withdrawAdminRole", {
        tontineId: this.tontine.id,
        userId: participant.userId,
      });
    },

    //Tontine détail
  },
};
