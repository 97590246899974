import axios from "axios";
// import base_url from "../base_url.js";
import _ from "lodash";
const path = import.meta.env.VITE_APP_URL + "remit";
// const remit={
export default (app) => {
  return {
    namespaced: true,
    state: {
      userrecipientList: "",
      recipients: "",
      allrecipients: "",
      id: "",
      recipient: "",
      accountnumber: "",
      pickuptransaction: "",
      topuptransaction: "",
      pickupTransfer: "",
      pickupTransferamount: "",
      cashOut: "",
      cashOutError: "",
      pickupqueryerror: "",
      pickupamountqueryerror: "",
      recipientTransactionList: "",
      remitQRCode: "",
    },
    getters: {
      recipientsList: (state) => _.groupBy(state.allrecipients, "countryName"),
      userrecipientList: (state) => state.userrecipientList,
      topuptransaction: (state) => state.topuptransaction,
      pickuptransaction: (state) => state.pickuptransaction,
      pickupTransfer: (state) => state.pickupTransfer,
      pickupTransferamount: (state) => state.pickupTransferamount,
      pickupqueryerror: (state) => state.pickupqueryerror,
      pickupamountqueryerror: (state) => state.pickupamountqueryerror,
    },
    mutations: {
      GET_RECIPIENTBYID(state, recipient) {
        state.recipient = recipient;
      },
      GET_RECIPIENTS(state, recipients) {
        state.recipients = recipients;
      },
      GET_ALLRECIPIENTS(state, allrecipients) {
        state.allrecipients = allrecipients;
      },
      RECIPIENT_DELETE(state, payload) {
        state.id = payload;
      },
      RECIPIENT_EDIT(state, payload) {
        state.id = payload;
      },
      RECIPIENT_ADD(state, recipient) {
        state.recipient = recipient;
      },
      GET_USERRECIPIENTLIST(state, userrecipientList) {
        state.userrecipientList = userrecipientList;
      },
      TOP_UP(state, topuptransaction) {
        state.topuptransaction = topuptransaction;
      },
      PICK_UP(state, pickuptransaction) {
        state.pickuptransaction = pickuptransaction;
      },
      PICKUP_TRANSFER(state, pickupTransfer) {
        state.pickupTransfer = pickupTransfer;
      },
      PICKUP_TRANSFERAMOUNT(state, pickupTransferamount) {
        state.pickupTransferamount = pickupTransferamount;
      },
      CASH_OUT(state, cashOut) {
        state.cashOut = cashOut;
      },

      CASH_OUT_ERROR(state, cashOutError) {
        state.cashOutError = cashOutError;
      },

      PICKUP_QUERYERROR(state, pickupqueryerror) {
        state.pickupqueryerror = pickupqueryerror;
      },
      PICKUP_AMOUNTQUERYERROR(state, pickupamounqueryerror) {
        state.pickupamounqueryerror = pickupamounqueryerror;
      },
      GET_RECIPIENTTRANSACTIONLIST(state, recipientTransactionList) {
        state.recipientTransactionList = recipientTransactionList;
      },
      RESET_CASHOUT(state) {
        state.cashOut = "";
        state.pickupTransfer = "";
        state.pickupTransferamount = "";
      },
      DECODE_QRCODE(state, remitQRCode) {
        state.remitQRCode = remitQRCode;
      },
    },

    actions: {
      getRecipientById({ commit, dispatch }, payload) {
        axios({
          url: `${path}/recipient/getRecipientById/${payload}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((recipient) => {
            commit("GET_RECIPIENTBYID", recipient);
            setTimeout(() => {
              if (recipient.userRecipientId) {
                dispatch(
                  "account/loadUser",
                  { userId: recipient.userRecipientId, type: "userId" },
                  {
                    root: true,
                  }
                );
              }
            }, 500);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      loadRecipients({ commit, rootState }) {
        axios({
          url: `${path}/recipient/currentUserRecipients`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          useCache: true,
        })
          .then((r) => r.data)
          .then((allrecipients) => {
            commit("GET_ALLRECIPIENTS", allrecipients);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      loadRecipientsByCountry({ commit }, payload) {
        axios({
          url: `${path}/recipient/byCountry/byPickupMethod`,
          method: "GET",
          params: {
            countryId: payload.countryId,
            pickupMethod: payload.pickupMethod,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          useCache: true,
        })
          .then((r) => r.data)
          .then((recipients) => {
            commit("GET_RECIPIENTS", recipients);
          })
          .catch((err) => {
            console.log(err.response.data);
            console.log(err);
          });
      },

      recipientDelete({ commit }, payload) {
        axios({
          url: `${path}/recipient/delete/${payload}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((resp) => {
            commit("RECIPIENT_DELETE", payload);
          })
          .catch((err) => {
            console.log(err.response.data);
            console.log(err);
          });
      },

      recipientEdit({ commit, dispatch }, payload) {
        axios({
          url: `${path}/recipient/edit/${payload.id}`,
          data: payload,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((recipient) => {
            commit("RECIPIENT_EDIT", recipient);
            dispatch("getRecipientById", recipient.id);
          })
          .catch((err) => {
            console.log(err.response.data);
            console.log(err);
          });
      },

      recipientAdd({ commit, rootState }, payload) {
        axios({
          url: `${path}/recipient/add`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((recipient) => {
            commit("RECIPIENT_ADD", recipient);
          })
          .catch((err) => {
            console.log(err.response.data);
            console.log(err);
          });
      },

      getUserRecipientList({ commit }, payload) {
        axios({
          url: `${path}/recipient/users/public/enabled`,
          method: "GET",
          params: {
            countryCode: payload,
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          useCache: true,
        })
          .then((r) => r.data)
          .then((userrecipientList) => {
            commit("GET_USERRECIPIENTLIST", userrecipientList);
          })
          .catch((err) => {
            console.log(err.response.data);
            console.log(err);
          });
      },
      getTopupFromNewCards({ commit, rootState }, payload) {
        axios({
          url: `${path}/transactions/topupuseraccountfromnewcards`,
          method: "POST",
          params: {
            recipientId: payload.recipientId,
            id: payload.id,
            recipientAccountNumber: payload.recipientAccountNumber,
            recipientAccountType: payload.recipientAccountType,
            userId: app.config.globalProperties.$keycloak.subject,
            validatorRecipientId: payload.validatorRecipientId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topuptransaction) => {
            commit("TOP_UP", topuptransaction);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getTopupFromBalance({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/transactions/topupuseraccountfrombalance`,
          method: "POST",
          data: payload.forms,
          params: {
            recipientId: payload.recipientId,
            paymentCurrency: payload.paymentCurrency,
            amount: payload.amount,
            senderComment: payload.senderComment,
            paymentRef: payload.paymentRef,
            senderAccountNumber: payload.senderAccountNumber,
            senderAccountType: payload.senderAccountType,
            recipientAccountNumber: payload.recipientAccountNumber,
            recipientAccountType: payload.recipientAccountType,
            userId: app.config.globalProperties.$keycloak.subject,
            validatorRecipientId: payload.validatorRecipientId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topuptransaction) => {
            commit("TOP_UP", topuptransaction);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      validateTopUp({ commit, rootState }, payload) {
        axios({
          url: `${path}/topup/validate`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topuptransaction) => {
            commit("TOP_UP", topuptransaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getPickupFromNewCards({ commit, rootState }, payload) {
        axios({
          url: `${path}/transactions/pickupfromnewcards`,
          method: "POST",
          params: {
            recipientId: payload.recipientId,
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuptransaction) => {
            commit("PICK_UP", pickuptransaction);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      getPickupFromBalance({ commit, rootState }, payload) {
        axios({
          url: `${path}/transactions/pickupfrombalance`,
          method: "POST",
          params: {
            recipientId: payload.recipientId,
            amount: payload.amount,
            senderAccountNumber: payload.senderAccountNumber,
            senderAccountType: payload.senderAccountType,
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuptransaction) => {
            commit("PICK_UP", pickuptransaction);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      getPickupTransfer({ commit, rootState }, payload) {
        axios({
          url: `${path}/cashOut/check/pickupTransaction`,
          method: "GET",
          params: {
            transferCode: payload.transferCode,
            userId: app.config.globalProperties.$keycloak.subject,
            geolocation: `${rootState.location.latitude}-${rootState.location.longitude}`,
            ipAddress: rootState.location.ipAddress,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickupTransfer) => {
            commit("PICKUP_TRANSFER", pickupTransfer);
            commit("PICKUP_QUERYERROR", "");
          })
          .catch((err) => {
            commit("PICKUP_TRANSFER", "");
            commit("PICKUP_QUERYERROR", err.response.data);
            console.log(err);
            console.log(err.response.data);
          });
      },

      getPickupTransferWithAmount({ commit, rootState }, payload) {
        axios({
          url: `${path}/cashOut/check/pickupTransactionWithAmount`,
          method: "GET",
          params: {
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
            userId: app.config.globalProperties.$keycloak.subject,
            geolocation: `${rootState.location.latitude}-${rootState.location.longitude}`,
            ipAddress: rootState.location.ipAddress,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickupTransferamount) => {
            commit("PICKUP_TRANSFERAMOUNT", pickupTransferamount);
            commit("PICKUP_AMOUNTQUERYERROR", "");
          })
          .catch((err) => {
            commit("PICKUP_AMOUNTQUERYERROR", err.response.data);
            commit("PICKUP_TRANSFERAMOUNT", "");
            console.log(err);
            console.log(err.response.data);
          });
      },

      cashOutPickup({ commit, rootState }, payload) {
        axios({
          url: `${path}/pickup/cashOut`,
          method: "POST",
          params: {
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
            pickupTransactionId: payload.pickupTransactionId,
            amount: payload.amount,
            userId: app.config.globalProperties.$keycloak.subject,
            geolocation: `${rootState.location.latitude}-${rootState.location.longitude}`,
            ipAddress: rootState.location.ipAddress,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((cashOut) => {
            commit("CASH_OUT", cashOut);
            commit("CASH_OUT_ERROR", "");
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
            commit("CASH_OUT_ERROR", err.response.data);
            commit("CASH_OUT", "");
          });
      },
      decodeQRCode({ commit, dispatch }, payload) {
        axios({
          url: `${path}/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((remitQRCode) => {
            commit("DECODE_QRCODE", remitQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      loadRecipientTransactionList({ commit }, payload) {
        axios({
          url: `${path}/transactions/recipientTransactionList`,
          method: "GET",
          params: {
            recipientId: payload.recipientId,
            userId: app.config.globalProperties.$keycloak.subject,
            maxNumberOfItem: payload.visibleItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((recipientTransactionList) => {
            commit("GET_RECIPIENTTRANSACTIONLIST", recipientTransactionList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      resetCashout({ commit }) {
        commit("RESET_CASHOUT");
      },
    },
  };
};
// export default remit;
