<template>
  <f7-page hide-bars-on-scroll :page-content="false">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-html="this.title"></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top>
      <f7-link tab-link="#cashOutWithavocashNumber" tab-link-active :text="$t('avocashout.useAccount')"
        icon-aurora="f7:keyboard_fill" icon-ios="f7:keyboard_fill" icon-md="material:dialpad"
        style="text-transform: lowercase"></f7-link>
      <f7-link tab-link="#cashOutWithNewQRCode" :text="$t('avocashout.createQrCode')" icon-ios="f7:qrcode"
        icon-aurora="f7:qrcode" icon-md="f7:qrcode" style="text-transform: lowercase"></f7-link>
    </f7-toolbar>
    <f7-tabs swipeable animated>
      <f7-tab id="cashOutWithavocashNumber" tab-active>
        <accountNumberToCashOut-page />
      </f7-tab>
      <f7-tab id="cashOutWithNewQRCode">
        <cashOutWithNewQRCode-page />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script>
import AccountNumberToCashOut from "./avocashoutWithAccountNumber.vue";
import CashOutWithNewQRCode from "./avocashoutWithNewQRCode.vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import {
  theme,
  f7,
} from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    "cashOutWithNewQRCode-page": CashOutWithNewQRCode,
    "accountNumberToCashOut-page": AccountNumberToCashOut,
    navbarright: Navbarright,
  },
  data() {
    return {
      theme,
      f7,
      title: "<span class='avocash'>avo¢ash</span> out",
      time: 5,
      isRunning: false,
      interval: null,
    };
  },
  mounted() {
    const self = this;

    if (this.isTablet == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
  },
  methods: {
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
        console.log("timer stops");
      } else {
        this.interval = setInterval(this.decrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    decrementTime() {
      const self = this;

      if (this.time > 0) {
        this.time = parseInt(this.time) - 1;
      } else {
        this.time = 0;
        console.log("timer stops");
        // f7.dialog.alert('QR Code expired!');
        clearInterval(this.interval);
        const self = this;
        if (!self.toastTop) {
          self.toastTop = f7.toast.create({
            text: "QR code is expired! Issue new code to pay!",
            cssClass: "checkCardToast",
            position: "center",
            closeTimeout: 2000,
          });
        }
        self.toastTop.open();
      }
    },
  },

  computed: {
    ...mapState("auth", ["isDeskTop", "isTablet"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
</script>
