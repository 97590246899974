<template>
  <f7-page hide-bars-on-scroll no-toolbar v-if="this.shop">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false">
        <span class="padding-left-half"><img v-if="this.shop.logo !== null"
            :src="`data:image/png;base64,${this.shop.logo}`" width="40" height="40" />
          <f7-badge v-else slot="media" color="auto"
            style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">{{
              $filtersGetInitial.getInitial(shop.name) }}</f7-badge>
        </span>
      </f7-nav-left>
      <f7-nav-title> {{ this.shop.name }}</f7-nav-title>
      <f7-nav-right v-if="this.profile.sub === this.shop.ownerId || this.currentUserIsAdmin">
        <f7-link icon-color="red" icon-aurora="f7:text_badge_plus" icon-ios="f7:text_badge_plus"
          icon-md="material:playlist_add" href="#" @click="listNewProduct()"></f7-link>
        <f7-link icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"
          :href="'/shop/edit/' + this.shop.name"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card expandable swipe-to-close v-if="this.shop" hide-navbar-on-open class="demo-facebook-card">
          <f7-card-content :padding="false">
            <f7-card-header :padding="false" class="no-margin">
              <div class="demo-facebook-avatar padding-right">
                <img v-if="this.shop.logo !== null" :src="`data:image/png;base64,${this.shop.logo}`" width="40"
                  height="40" />
                <f7-badge v-else slot="media" color="auto" style="height: 40px; width: 40px; font-size: 16px">{{
                  $filtersGetInitial.getInitial(shop.name) }}</f7-badge>
              </div>
              <div class="demo-facebook-name">{{ this.shop.name }}</div>
              <div class="demo-facebook-date" v-html="this.shop.tag"></div>
            </f7-card-header>

            <div v-if="this.shop.shopImagesList &&
              this.shop.shopImagesList.length > 0 &&
              this.heroImage
              " :style="{
    background:
      'url(' +
      `data:image/png;base64,${this.heroImage.image}` +
      ') no-repeat center bottom',
    'background-size': 'cover',
    height: '300px',
  }"></div>
            <div v-else>
              <img :src="`data:image/png;base64,${this.shop.qrImage}`"
                style="display: block; margin: auto; height: auto" />
            </div>
            <f7-link card-close color="inherit" class="card-opened-fade-in"
              :style="{ position: 'absolute', right: '15px', top: '15px' }" icon-f7="multiply_square_fill"></f7-link>
            <div class="card-content-padding">
              <p v-html="this.shop.tag"></p>
              <p v-html="this.shop.about"></p>
              <p v-html="this.shop.policies"></p>
            </div>
          </f7-card-content>
        </f7-card>
        <div v-if="this.shop && this.currentUserIsAdmin">
          <f7-card-header>
            <div v-if="this.shop.shopAdminList && this.shop.shopAdminList.length > 1">
              {{ $t("shopdetail.shopAdmins") }}
            </div>
            <div v-else>{{ $t("shopdetail.addShopAdmin") }}</div>
            <div></div>
            <f7-badge v-if="currentUserIsAdmin" bg-color="red" :style="{
              position: 'absolute',
              right: '16px',
              top: '8px',
              'border-radius': '50px',
              height: '32px',
              width: '32px',
            }" class="elevation-3">
              <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white" href="#"
                popup-open=".addNewParticipant"></f7-link>
            </f7-badge>
          </f7-card-header>
          <f7-list dividers-ios strong-ios media-list v-if="this.shop.shopAdminList &&
            this.shop.shopAdminList.length > 1 &&
            this.currentUserIsAdmin
            ">
            <f7-list-item swipeout v-for="(shopAdmin, index) in this.shop.shopAdminList" :key="index"
              :title="shopAdmin.name">
              <f7-swipeout-actions right v-if="shopAdmin.userId !== profile.sub">
                <f7-swipeout-button @click="withdrawAdminRole(shopAdmin)" color="red" overswipe>{{
                  $t("common.withdrawAdminRights") }}</f7-swipeout-button>
              </f7-swipeout-actions>
              <template #after>
                <f7-badge color="auto" v-if="shopAdmin.userId !== profile.sub">{{
                  $t("common.admin")
                }}</f7-badge>
                <f7-badge color="auto" v-else>{{ $t("shopdetail.shopOwner") }}</f7-badge>
              </template>
              <template #media>
                <f7-badge color="auto" style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">
                  {{ $filtersGetInitial.getInitial(shopAdmin.name) }}</f7-badge>
              </template>
            </f7-list-item>
          </f7-list>
        </div>
        <f7-list dividers-ios strong-ios media-list v-if="currentUserIsAdmin">
          <f7-list-item :title="$t('shopdetail.addVideoOr')" link="#" @click="addVideoUrls(shop, type)"></f7-list-item>
        </f7-list>
        <swiper-container v-if="this.shop.shopImagesList && this.shop.shopImagesList.length > 0" :params="{
          slidesPerView: this.isTablet ? 1 : 1,
          spaceBetween: 0,
          disableOnInteraction: true,
          pagination: this.isTablet ? false : true,
        }">
          <f7-photo-browser :photos="this.shopPhotos" ref="popup"></f7-photo-browser>
          <swiper-slide v-for="(image, index) in this.shopPhotos" :key="index" style="height: 400px">
            <a link="#" @click="$refs.popup.open()">
              <img :src="image.url" width="auto" max-height="100%" class="margin-horizontal-half" />
            </a>
            <f7-link v-if="currentUserIsAdmin" raised :style="{
              position: 'absolute',
              right: '-8px',
              top: '-2px',
              color: 'red',
            }" icon-f7="multiply_square_fill" @click="deleteImage(image.id, shop, 'shop')"></f7-link>
          </swiper-slide>
        </swiper-container>

        <f7-card v-if="this.shop.shopVideoList && this.shop.shopVideoList.length > 0"
          v-for="(video, index) in this.shop.shopVideoList" :key="index"
          class="videoContainer grid grid-cols-1 medium-grid-cols-2 large-grid-cols-3" :style="{
            position: 'relative',
            overflow: 'hidden',
            'padding-top': '56.25%',
          }">
          <span :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }" v-if="video.url !== null && video.url.includes('iframe')" v-html="video.url"></span>
          <iframe class="videoIframe" :style="{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }" :src="video.url" :title="video.name" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <f7-link v-if="currentUserIsAdmin" raised :style="{
            position: 'absolute',
            right: '0px',
            top: '0px',
            color: 'red',
          }" icon-f7="multiply_square_fill" @click="deleteVideo(video.id, shop, 'shop')"></f7-link>
          <f7-card-footer>{{ video.name }}</f7-card-footer>
        </f7-card>

        <f7-block-title medium class="margin-top-half" v-if="this.productParentByShopId">{{ $t("shopdetail.shopProducts")
        }}</f7-block-title>
        <f7-list dividers-ios strong-ios media-list v-if="this.productParentByShopId"
          style="padding-bottom: 50px !important">
          <div width="50" medium="20" v-for="(productParent, index) in this.productParentByShopId" :key="index"
            class="itemImageContainer grid grid-cols-2 medium-grid-cols-4">
            <a class="text-color-black" :href="itemUrl(productParent.id)">
              <swiper-container>
                <!-- <f7-photo-browser :photos="this.shopPhotos" type="popup" ref="popup"></f7-photo-browser> -->
                <swiper-slide v-for="(productImage, index) in this.productParent.productImagesList" :key="index"
                  style="width: 150px; height: 155px">
                  <!-- <a link="#" @click="$refs.popup.open()"> -->
                  <img :src="`data:image/png;base64,${productImage.image}`" width="150" height="150" max-height="100%"
                    class="margin-horizontal-half" />
                  <!-- </a> -->
                </swiper-slide>
              </swiper-container>
              <!-- <img
              :src="`data:image/png;base64,${productParent.productImagesList[0].image}`"
              class="itemIamge no-margin-vertical"
            />-->

              <div class="no-margin-vertical padding-horizontal-half" style="font-size: 90%">
                <p class="no-margin-vertical itemTags">
                  <b>{{ productParent.name }}</b>
                </p>
                <p class="no-margin-vertical" v-if="productParent">
                  {{
                    $t("shopdetail.productVariant", {
                      myproduct: productParent.productList.length,
                    })
                  }}
                </p>
              </div>
            </a>
          </div>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>

    <f7-popup class="addNewParticipant" style="min-height: 100%; --f7-sheet-bg-color: #fff" :tablet-fullscreen="true"
      :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false">
      <f7-page>
        <f7-navbar no-shadow>
          <div class="left"></div>
          <div class="title">{{ $t("shopdetail.addNewShopAdmin") }}</div>
          <div class="right">
            <f7-link popup-close icon-f7="xmark_circle"></f7-link>
          </div>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title large>{{ $t("shopdetail.addNewShopAdmin") }}</f7-block-title>
            <userslist> </userslist>
            <f7-toolbar tabbar bottom style="position: fixed; bottom: 0" bg-color="red" v-if="this.searchedUser &&
              this.searchedUser.userId !== this.profile.sub &&
              !shop.shopAdminList.find((x) => x.userId === this.searchedUser.userId)
              ">
              <f7-link raised fill large class="link buttons" text-color="white" strong
                :text="'Add ' + this.searchedUser.name + ' as admin'" @click="grantAdminRole(searchedUser)"></f7-link>
            </f7-toolbar>
            <f7-chip class="margin" outline style="display: flex; justify-content: center" v-if="this.searchedUser &&
              shop.shopAdminList.find((x) => x.userId === this.searchedUser.userId)
              " color="red">{{
    $t("shopdetail.isAlreadyAnAdmin", {
      isAdmin: this.searchedUser.name,
    })
  }}</f7-chip>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-popup>
    <template #fixed>
      <f7-fab position="center-bottom" :text="$t('shopdetail.listAProduct')" color="red" popup-close
        @click="listNewProduct()">
        <f7-icon ios="f7:text_badge_plus" aurora="f7:text_badge_plus" md="material:playlist_add"></f7-icon>
      </f7-fab>
      <f7-fab position="left-bottom" color="auto" @click="goToMessageGroup()">
        <f7-icon ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="material:chat"></f7-icon>
      </f7-fab>
      <f7-fab position="right-bottom" class="shareButton" color="auto" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.itemImageContainer {
  border-left: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.itemImageContainer img {
  height: "auto";
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.demo-facebook-card .card-header {
  display: block;
  padding: 10px;
}

.demo-facebook-card .demo-facebook-avatar {
  float: left;
}

.demo-facebook-card .demo-facebook-name {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 500;
}

.demo-facebook-card .demo-facebook-date {
  margin-top: 8px;
  font-size: 12px;
  color: #8e8e93;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7Badge,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Card,
  f7CardContent,
  f7CardHeader,
  f7List,
  f7ListItem,
  f7SwipeoutActions,
  f7SwipeoutButton,
  f7PhotoBrowser,
  f7BlockTitle,
  f7Popup,
  f7PageContent,
  f7Toolbar,
  f7Chip,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { shop } from "../js/mixins/shop.js";
import UsersPage from "./users.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    userslist: UsersPage,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7Badge,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Card,
    f7CardContent,
    f7CardHeader,
    f7List,
    f7ListItem,
    f7SwipeoutActions,
    f7SwipeoutButton,
    f7PhotoBrowser,
    f7BlockTitle,
    f7Popup,
    f7PageContent,
    f7Toolbar,
    f7Chip,
    f7Fab,
    f7Icon,
  },
  mixins: [attachDocs, socialShare, shop],
  data() {
    return {
      theme,
      f7,
      productCategory: "",
      paymentCurrency: "",
      type: "shop",
      sharedContent: "",
      selectedMessages: "",
      isRecording: false,
    };
  },
  mounted() {
    const self = this;
    f7.toolbar.hide(".mainToolbar", true);
    if (!this.shop) {
      self.$store.dispatch("shop/getActiveShopById", this.f7route.params.shopId);
    }
    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.shop) {
      self.$store.dispatch("product/getProductParentByShopId", this.shop.id);
    }
    console.log(this.shop);
    self.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
  },

  methods: {
    async grantAdminRole(searchedUser) {
      const self = this;
      await self.$store.dispatch("shop/grantAdminRole", {
        shopId: this.shop.id,
        userId: searchedUser.userId,
      });
    },
    async withdrawAdminRole(shopAdmin) {
      const self = this;

      await self.$store.dispatch("shop/withdrawAdminRole", {
        shopId: this.shop.id,
        userId: shopAdmin.userId,
      });
    },

    listNewProduct() {
      const self = this;
      f7.preloader.show("multi");
      setTimeout(() => {
        this.f7router.navigate(`/product/new/${this.shop.id}`);
        f7.preloader.hide();
      }, 300);
    },

    async goToMessageGroup() {
      const self = this;
      const chatId = this.shop.chatGroupId === "" ? this.shop.id : this.shop.chatGroupId;
      f7.preloader.show("multi");

      if (this.profile.sub === this.shop.shopOwnerId || this.currentUserIsAdmin) {
        setTimeout(() => {
          this.f7router.navigate(`/message/${chatId}/GROUP`, {
            reloadAll: true,
          });
          f7.preloader.hide();
        }, 300);
      } else {
        setTimeout(() => {
          this.f7router.navigate(`/message/${this.shop.chatUserId}/PERSONAL`, {
            reloadAll: true,
          });
          f7.preloader.hide();
        }, 300);
      }
    },

    itemUrl(id) {
      return `/shopProductDetail/${id}`;
    },
  },

  computed: {
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "videosUrl",
      "videoService",
      "isDeskTop",
    ]),
    ...mapState("shop", ["categorylist", "categories", "shop", "shopList"]),
    ...mapState("product", [
      "product",
      "images",
      "optionNames",
      "shopImages",
      "allProduct",
      "productParent",
      "productByListOwner",
      "productByShopId",
      "productByShopNumber",
      "productByCategory",
      "productBySubCategory",
      "allProductParent",
      "productParentByList",
      "productParentByListOwner",
      "productParentByShopId",
      "productParentByShopNumber",
      "productParentByCategory",
      "productParentBySubCategory",
      "productParentGroupBuyList",
      "negoPrice",
      "groupBuy",
      "userGroupBuyList",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "videoService",
      "videosUrl",
      "dynamicLink",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    category() {
      return _.groupBy(this.categories, "categoryName");
    },

    heroImage() {
      return this.shop.shopImagesList && this.shop.shopImagesList.length > 0
        ? _.find(this.shop.shopImagesList, ["name", "hero"])
        : _.find(this.shop.shopImagesList, ["imageRank", "Hero"]);
    },
    shopPhotos() {
      const shopImages = this.shop.shopImagesList;
      if (shopImages && shopImages.length > 0) {
        return _.forEach(shopImages.slice(), (item) => {
          _.assign(item, {
            url: `data:image/png;base64,${item.image}`,
          });
        });
      }
    },
    currentUserIsAdmin() {
      if (this.profile.sub) {
        return _.find(this.shop.shopAdminList, ["userId", this.profile.sub]);
      }
    },
  },
};
</script>
