<template>
  <f7-page @page:init="onPageInit" hide-bars-on-scroll>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-if="this.pickupMethod === 'pick-up'">{{
        $t("sendcash.transferTo", { myrecipient: this.recipient.name })
      }}</f7-nav-title>
      <f7-nav-title sliding v-else>{{
        $t("sendcash.topup", { myrecipient: this.recipient.name })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios media-list form>
          <f7-list-group>
            <f7-list-item v-if="this.pickupMethod === 'top-up'" smart-select
              class="recipientAccountNumber align-content-right item-label" :smart-select-params="{
                closeOnSelect: true,
                openIn: this.f7.device.desktop == true ? 'popover' : 'sheet',
                sheetPush: true,
                sheetSwipeToClose: true,
              }" :title="$t('sendcash.selectRecipientAccount')">
              <select name="account-type" v-model="recipientAccountNumber" @change="selectRecipientAccount()">
                <option :value="this.userDetail.primaryAccount.accountNumber" selected>
                  {{ this.userDetail.primaryAccount.accountType }}:
                  {{ this.userDetail.primaryAccount.accountNumber }}
                </option>
                <option v-if="this.userDetail.businessAccount.active === true"
                  :value="this.userDetail.businessAccount.accountNumber">
                  {{ this.userDetail.businessAccount.accountType }}:
                  {{ this.userDetail.businessAccount.accountNumber }}
                </option>
              </select>
            </f7-list-item>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.recipient.firstName }} {{ this.recipient.lastName }}
                  </div>
                  <div v-if="this.recipient.userRecipientId !== null &&
                    this.pickupMethod === 'top-up'
                    " class="item-title" style="font-weight: bold">
                    {{ this.recipientAccountNumber }}
                  </div>
                  <div v-else class="item-title" style="font-weight: bold">
                    +{{ recipient.phoneCode }}{{ recipient.phone }}
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 75%; color: gray">
                  <div class="item-title">{{ this.recipient.countryName }}</div>
                  <div class="item-title">{{ this.recipient.cityName }}</div>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.destinationCountry") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.pickupMode") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.recipient.countryName }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    <span class="avocash">avo¢ash</span>
                    {{ this.pickupMethod }}
                  </div>
                </div>
              </div>
            </li>
            <f7-list-item :title="$t('sendcash.enterTheAmount')" divider></f7-list-item>
            <f7-row no-gap>
              <f7-col width="45" medium="45">
                <li class="item-content item-input">
                  <div class="item-media" v-if="this.recipientCountry">
                    {{ this.recipientCountry.fromCurrencyCode }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-label">
                      {{ $t("sendcash.youSend") }}
                    </div>
                    <div class="item-input-wrap">
                      <input type="number" pattern="[0-9]*" min="0" required validate
                        data-error-message="Only numbers please!" name="amountToSend" :value="this.amountToSend"
                        id="amountToSend" @input="amountToSend = $event.target.value" clear-button
                        @change="calculateTransfer()" @keyup="calculateTransfer()" @blur="calculateTransfer()" />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
              </f7-col>
              <f7-col width="5" medium="5">
                <br />
                <f7-link icon-aurora="f7:arrow_right_circle_fill" icon-ios="f7:arrow_right_circle_fill"
                  icon-md="material:arrow_forward"></f7-link>
              </f7-col>
              <f7-col width="45" medium="45">
                <li class="item-content item-input">
                  <div class="item-media" v-if="this.recipientCountry">
                    {{
                      this.recipientCountry.toCurrencyCode ||
                      transferDetail.toCurrencyCode
                    }}
                  </div>
                  <div class="item-inner">
                    <div class="item-title item-label">
                      {{ $t("sendcash.recipientGets") }}
                    </div>
                    <div class="item-input-wrap">
                      <input type="number" pattern="[0-9]*" required validate name="receivedAmount" :value="parseFloat(
                        this.transferDetail.receivedAmount || this.receivedAmount
                      )
                        " @input="receivedAmount = $event.target.value" id="receivedAmount"
                        @change="calculateAmountToSend()" @keypress="calculateAmountToSend()"
                        @blur="calculateAmountToSend()" />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
              </f7-col>
            </f7-row>
            <f7-block class="no-margin-vertical" v-if="this.amounterror !== null || this.senderAccountNumber === null">
              <p style="font-size: 10px; font-style: italic; color: red">
                {{ this.amounterror }}
              </p>
            </f7-block>

            <li class="item-content" v-if="transferDetail.receivedAmount">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">
                    {{ $t("sendcash.fee") }}
                  </div>
                  <div class="item-title">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.transferFeeAmount,
                      transferDetail.fromCurrencySymbol,
                      transferDetail.fromCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">
                    {{ $t("sendcash.xrate") }}
                  </div>
                  <div class="item-title float-left">
                    1{{ transferDetail.fromCurrencyCode }}=
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.netExchangeRate,
                      transferDetail.toCurrencySymbol,
                      transferDetail.toCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
          </f7-list-group>

          <!-- Top-up from balance -->
        </f7-list>
        <payDebitCard></payDebitCard>
        <f7-toolbar tabbar bottom bg-color="red">
          <f7-link @click="goToCheckTransfer()" v-if="this.pickupMethod === 'top-up'" raised large fill
            class="link buttons" strong text-color="white">{{ $t("sendcash.topupNow") }}</f7-link>
          <!-- Pick-up from balance -->
          <f7-link @click="goToCheckTransfer()" v-else raised large fill class="link buttons" strong text-color="white">{{
            $t("sendcash.transferNow") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Block,
  f7Toolbar,
  f7Link,
  theme,
  f7,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import PayDebitCard from "./payDebitCard.vue";
import { mapGetters, mapState } from "vuex";
import { scanCard, checkCard } from "../js/mixins/card.js";
import { selectedRecipient, recipient } from "../js/mixins/recipient.js";
import { calculateTransferPayment } from "../js/mixins/transaction.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    pickupmode: Object,
    recipientId: Object,
  },
  mixins: [scanCard, checkCard, selectedRecipient, recipient, calculateTransferPayment],
  components: {
    navbarright: Navbarright,
    payDebitCard: PayDebitCard,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7List,
    f7ListGroup,
    f7ListItem,

    f7Block,
    f7Toolbar,
    f7Link,
  },
  name: "frombalance",
  data() {
    return {
      theme,
      f7,
      pickupMethod: "",
      currRecipientId: "",
      pickupcity: "",
      pickupplace: "",
      tocurrencycode: "",
      senderAccountNumber: "",
      senderAccountType: "",
      recipientAccountNumber: "",
      senderAccountBalance: "",
      amounterror: "",
      grossXrate: "",
      recipientAcctnumber: "",
      recipientAccountType: "",
      amountToSend: "",
      amountToReceive: "",
      receivedAmount: "",
    };
  },

  mounted() {
    const self = this;
    if (this.f7route) {
      this.pickupMethod = this.f7route.params.pickupmode;
      this.currRecipientId = this.f7route.params.id;
    } else {
      this.pickupMethod = this.pickupmode;
      this.currRecipientId = this.recipientId;
    }

    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
    if (this.recipientCountry) {
      self.$store.dispatch("transaction/getCurrencyRate", {
        fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
        toCurrencyCode: this.recipientCountry.toCurrencyCode,
      });
    }
    if (this.userDetail !== "") {
      this.recipientAccountType = this.userDetail.primaryAccount.accountType;
      this.recipientAccountNumber = this.userDetail.primaryAccount.accountNumber;
    } else {
      this.recipientAccountType = this.extraTransferDetail.recipientAccountType;
      this.recipientAccountNumber = this.extraTransferDetail.recipientAccountNumber;
    }
    f7.$(".recipientAccountNumber .item-after").html(
      this.recipientAccountType + ": " + this.recipientAccountNumber
    );

    if (this.extraTransferDetail) {
      this.amountToSend = parseFloat(this.extraTransferDetail.amount);
      this.calculateTransfer();
    } else if (this.transferDetail) {
      this.amountToSend = parseFloat(this.transferDetail.grossTransferAmount);

      this.calculateTransfer();
    }
  },
  methods: {
    async selectSenderAccount() {
      const self = this;

      if (this.senderAccountnumber == this.primaryAccount.accountNumber) {
        this.senderAccountType = await this.primaryAccount.accountType;
      } else {
        this.senderAccountType = await this.businessAccount.accountType;
      }
    },
    async selectRecipientAccount() {
      const self = this;

      if (this.recipientAccountNumber == this.userDetail.primaryAccount.accountNumber) {
        this.recipientAccountType = await this.userDetail.primaryAccount.accountType;
      } else {
        this.recipientAccountType = await this.userDetail.businessAccount.accountType;
      }
    },

    choosePickupCity() {
      const self = this;

      self.$store.dispatch("location/loadDestinationCity", this.recipient.countryCode2);
    },

    async onPageInit() {
      const self = this;

      if (this.recipient) {
        await self.$store.dispatch(
          "location/loadDestinationCity",
          this.recipient.countryCode2
        );
      }

      await self.$store.dispatch("remit/getRecipientById", this.currRecipientId);
      if (this.recipientCountry) {
        await self.$store.dispatch("transaction/getCurrencyRate", {
          fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
          toCurrencyCode: this.recipientCountry.toCurrencyCode,
        });
      }
      setTimeout(() => {
        if (this.grossexchangerate2 && this.amountToSend > 0) {
          this.calculateTransfer();
        }
      }, 1000);
    },
  },

  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userrecipientList",
      "recipient",
    ]),

    ...mapState("account", ["userDetail", "userAccount"]),
    ...mapState("card", ["carditemlist", "queryerror"]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "grossexchangerate",
      "transferDetail",
      "extraTransferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
    ]),

    ...mapGetters("transaction", ["grossexchangerate2"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCty() {
      return _(this.destinationCity).groupBy("countryId").pick([this.destinationctry]);
    },
    recipientCountry() {
      if (this.recipient && this.destinationCountry) {
        return _.find(this.destinationCountry, ["toCountryId", this.recipient.countryId]);
      }
    },
  },
};
</script>
