import { f7, theme } from "framework7-vue";
import { utils } from "framework7";
import _ from "lodash";
export const shopping = {
  props: {
    f7route: Object,
    f7router: Object,
  },
  methods: {
    async addToCart(selectedItem, qty) {
      const self = this;
      if (this.cart.length === 0) {
        const now = utils.now();
        window.localStorage.setItem("cartDuration", now);
      }
      console.log(selectedItem, qty);
      const quant = selectedItem.quantity ? 1 : selectedItem.quantity;
      if (!selectedItem.quantity) {
        await self.$store.dispatch("shoppingCart/addToCart", {
          item: _.assign(selectedItem, {
            quantity: parseInt(qty),
          }),
        });
        console.log("selectedItem1", selectedItem, qty);
        return;
      }
      selectedItem.quantity = parseInt(qty);
      await self.$store.dispatch("shoppingCart/addToCart", {
        item: selectedItem,
      });
      console.log("selectedItem2", selectedItem, qty);
      // await this.f7router.refreshPage();
    },
    async removeFromCart(selectedItem) {
      const self = this;
      await self.$store.dispatch("shoppingCart/removeFromCart", selectedItem);
      if (this.cart.length > 0) {
        await this.f7router.refreshPage();
      } else {
        await this.f7router.back();
      }
    },
    async deleteItemFromCart(selectedItem) {
      const self = this;
      await self.$store.dispatch(
        "shoppingCart/deleteItemFromCart",
        selectedItem
      );
      if (this.cart.length > 0) {
        await this.f7router.refreshPage();
      } else {
        await this.f7router.back();
      }
    },
    async deleteCart() {
      const self = this;
      await self.$store.dispatch("shoppingCart/deleteCart");
      await this.f7router.back();
    },
    async buyNow(selectedItem, qty) {
      const self = this;
      await self.$store.dispatch("shoppingCart/addToCart", {
        item: _.assign(selectedItem, {
          quantity: qty ? parseInt(qty) : this.quantity,
        }),
      });
      await this.f7router.navigate("/check-out");
    },
    async share() {
      const self = this;
      const files = [];
      const base64Files = [];
      //   if (this.staticQRCode && this.staticQRCode.qrCode !== "") {
      //     const base64Url = `data:image/png;base64,${this.staticQRCode.qrCode}`;
      //     const blob = await (
      //       await fetch(`data:image/png;base64,${this.staticQRCode.qrCode}`)
      //     ).blob();
      //     // const blob = await (await fetch(base64Url)).blob();
      //     const termination = blob.type.split("/")[1];
      //     file = new File(
      //       [blob],
      //       `${this.staticQRCode.name}-${this.staticQRCode.qrNumber}.${termination}`,
      //       { type: blob.type }
      //     );
      //     this.fileToSave.push(file);
      //     files.push(file);
      //     base64Files.push(`data:image/png;base64,${this.staticQRCode.qrCode}`);
      //   }

      this.sharedContent = {
        message: "", // `${this.staticQRCode.name} n°${this.staticQRCode.qrNumber}`,
        subject: "", //this.staticQRCode.name,
        base64Files,
        files,
        link: `${f7.views.main.router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        imgUrl: "https://avocash.com/34x34.png",
      };

      // self.$store.dispatch("auth/createDynamicLink", this.sharedContent);
      console.log(this.sharedContent);
    },
  },
  computed: {
    recipient() {
      const self = this;

      const id = this.f7route.params.id || this.recipientId;
      for (let i = 0; i < this.allrecipients.length; i += 1) {
        if (this.allrecipients[i].id == id) {
          return this.allrecipients[i];
        }
      }
    },
  },
};
