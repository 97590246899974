<template>
  <f7-popup v-if="this.savings.battleList !== null" class="addNewCampaign" style="--f7-sheet-bg-color: #fff"
    swipe-to-close :tablet-fullscreen="true" :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }">
    <f7-page>
      <f7-navbar>
        <div class="left"></div>
        <div class="title">
          {{ $t("savingsdetail.addNewBattle") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="xmark_circle"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-list dividers-ios strong-ios media-list form id="addNewCampaign">
            <f7-list-group>
              <f7-list-input required validate :placeholder="$t('common.name')" type="text" name="campaign" floating-label
                :label="$t('common.name')" :error-message="$t('common.nameInfo')" :info="$t('common.nameInfo')"
                :value="name" @input="name = $event.target.value"></f7-list-input>
              <f7-list-input required validate :placeholder="$t('savingsdetail.tag')" type="text" name="tag"
                floating-label :label="$t('common.tag')" :error-message="$t('common.tagInfo')"
                :info="$t('common.tagInfo')" :value="tag" @input="tag = $event.target.value"></f7-list-input>
              <f7-block-title class="item-header">{{
                $t("common.description")
              }}</f7-block-title>
              <f7-text-editor :value="description" name="description" :placeholder="$t('common.description')"
                @texteditor:change="(v) => (description = v)" resizable required validate type="texteditor" :mode="f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'
                  " :style="f7.device.cordova || !this.isTablet
    ? '--f7-text-editor-height: 150px'
    : ''
    " />

              <f7-list-input :label="$t('associationdetail.selectMyCampaignDateRange')" type="datepicker" floating-la
                :placeholder="$t('associationdetail.selectMyCampaignDateRange')" readonly :value="campaignPeriod"
                @calendar:change="(value) => (campaignPeriod = value)" :calendar-params="{
                  rangePicker: true,
                  closeOnSelect: true,
                  openIn: 'auto',
                  minDate: new Date(),
                  header: true,
                  footer: true,
                }" :error-message="$t('associationdetail.selectMyCampaignDateRange')" :info="this.campaignDays"
                clear-button></f7-list-input>

              <f7-list-input :label="$t('savingsdetail.savingsTarget')" floating-label clear-button type="number"
                name="savingsTarget" :placeholder="$t('savingsdetail.savingsTarget')" min="0" :value="savingsTarget"
                @input="savingsTarget = $event.target.value" pattern="[0-9]*">
                <template #media> {{ this.savings.currencyCode }} </template>
              </f7-list-input>

              <f7-list-input v-if="this.savings.savingsGroupUserList &&
                this.savings.savingsGroupUserList.length > 2
                " :label="$t('savingsdetail.selectBattleManager')" type="select"
                :placeholder="$t('savingsdetail.selectBattleManager')" @input="battleManager = $event.target.value">
                <option v-for="participant in this.savings.savingsGroupUserList" :key="participant.id"
                  :value="participant.userId">
                  {{ participant.name }}
                </option>
              </f7-list-input>
            </f7-list-group>
          </f7-list>
          <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
            <f7-link raised fill large text-color="white" class="link buttons" strong @click="addCampaign()">{{
              $t("savingsdetail.addNewBattle") }}</f7-link>
          </f7-toolbar>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
  <f7-popup class="addNewMember" style="min-height: 50%; --f7-popup-bg-color: #fff" :tablet-fullscreen="true"
    :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false">
    <f7-page>
      <f7-navbar no-shadow>
        <div class="left"></div>
        <div class="title">
          {{ $t("savingsdetail.addNewParticipant") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="xmark_circle"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block-title large class="padding-left">{{
            $t("savingsdetail.addNewParticipant")
          }}</f7-block-title>
          <userslist> </userslist>
          <f7-block-title large class="padding-left">
            {{ $t("savingsdetail.participants") }}
          </f7-block-title>
          <f7-list dividers-ios strong-ios media-list accordion-list class="no-margin-vertical"
            :no-chevron="!currentParticipantIsAdmin">
            <f7-list-item v-for="participant in this.savings.savingsGroupUserList" :key="participant.id" accordion-item
              :title="participant.name" :subtitle="participant.phoneNumber" :text="participant.email" :badge="savings.savingsGroupAdminList.find((x) => x.userId === participant.userId)
                ? $t('common.admin')
                : ''
                " badge-color="primary">
              <f7-accordion-content v-if="currentParticipantIsAdmin &&
                pariticipant.userId !== savings.groupOwnerUserId
                ">
                <f7-button raised fill small large ripple color="red"
                  class="margin-horizontal margin-vertical-half buttons" :text="$t('common.removeAsParticipant')"
                  @click="removeMember(pariticipant)">
                </f7-button>

                <f7-button raised fill small large ripple color="green"
                  class="margin-horizontal margin-vertical-half buttons" @click="grantAdminRole(pariticipant)"
                  :text="$t('common.grantAdminRights')" v-if="!savings.savingsGroupUserList.find(
                    (x) => x.userId === participant.userId
                  )
                    ">
                </f7-button>
                <f7-button v-if="savings.savingsGroupAdminList.find(
                  (x) => x.userId === participant.userId
                )
                  " raised fill small large ripple color="orange"
                  class="margin-horizontal margin-vertical-half buttons" @click="withdrawAdminRole(participant)"
                  :text="$t('common.withdrawAdminRights')">
                </f7-button>
              </f7-accordion-content>
              <template #media>
                <img v-if="participant.avatar" :src="`data:image/png;base64,${participant.avatar}`" width="40"
                  alt='user avatar' style="height: 48px; width: 48px; border-radius: 25px" />

                <f7-badge color="auto" v-else style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">
                  {{ $filtersGetInitial.getInitial(participant.name) }}</f7-badge>
              </template>
            </f7-list-item>
          </f7-list>
          <f7-chip class="margin" outline style="display: flex; justify-content: center" v-if="this.searchedUser &&
            this.savings.savingsGroupUserList.find(
              (x) => x.userId === this.searchedUser.userId
            )
            " color="red">{{
    $t("savingsdetail.isAlreadyAParticipant", {
      isParticipant: this.searchedUser.name,
    })
  }}</f7-chip>

          <f7-toolbar tabbar bottom labels :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }" bg-color="red"
            ripple class="link" v-if="this.searchedUser &&
                this.searchedUser.userId !== this.profile.sub &&
                !this.savings.savingsGroupUserList.find(
                  (x) => x.userId === this.searchedUser.userId
                )
                ">
            <f7-link raised fill large ripple text-color="white" strong class="link buttons"
              @click="addMember(searchedUser)">{{
                $t("savingsdetail.addAsParticipant", {
                  addParticipant: this.searchedUser.name,
                })
              }}</f7-link>
          </f7-toolbar>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
import {
  f7Popover,
  f7Block,
  f7Button,
  f7Link,
  f7Sheet,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapState } from "vuex";
import { saving } from "../js/mixins/savings.js";
import UsersPage from "../pages/users.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    savings: Object,
  },
  mixins: [saving],
  components: {
    userslist: UsersPage,
    f7Popover,
    f7Block,

    f7Button,
    f7Link,
    f7Sheet,
    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
      description: "",
      savingsTarget: "",
      campaignAmount: "",
      campaignPeriod: [],
      battleManager: "",
      hiddenFrom: [],
      campaignBeneficiary: "",
      name: "",
      tag: "",
    };
  },

  methods: {
    async addCampaign() {
      const self = this;

      if (f7.input.validateInputs("#addNewCampaign")) {
        await self.$store.dispatch("savings/createBattle", {
          participantId: this.battleManager,
          savingsGroupId: this.savings.id,
          battle: {
            BattleNumber: parseFloat(this.savings.battleList.length) + 1,
            campaignAmount: this.campaignAmount,
            startDate: this.campaignPeriod[0],
            endDate: this.campaignPeriod[1],
            savingsTarget: this.savingsTarget,
            description: this.description,
            name: this.name,
            tag: this.tag,
            battleManager: this.battleManager,
          },
        });
        f7.sheet.close(".addNewCampaign");
      }
    },
    async addMember(searchedUser) {
      const self = this;

      await self.$store.dispatch("savings/addParticipant", {
        savingsGroupId: this.savings.id,
        userId: searchedUser.userId,
      });
      f7.popup.close(".addNewMember");
    },
  },

  computed: {
    ...mapState("savings", [
      "savings",
      "savingsList",
      "battle",
      "participant",
      "participantList",
      "admin",
      "adminList",
      "savingsTransaction",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", ["video", "isDeskTop", "isTablet", "device", "profile"]),
  },
};
</script>
