// import base_url from "../base_url.json";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "card";
import _ from "lodash";
// const card = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      carditemlist: "",
      cardlist: "",
      country: "",
      cardItemId: "",
      carditem: "",
      card: "",
      newCard: "",
      cardTopuValidateStatus: "",
      cardQRCode: "",
      queryerror: "",
      scannedCard: "",
      QRtext: "",
    },
    getters: {
      totalCardbasket: (state) => {
        return _.reduce(
          state.carditemlist,
          (amount, carditem) => amount + parseFloat(carditem.cardAmount || 0)
        );
      },
    },
    mutations: {
      GET_CARDITEMLIST(state, carditemlist) {
        state.carditemlist = carditemlist;
      },
      GET_SOLD_CARD_LIST(state, cardlist) {
        state.cardlist = cardlist;
      },
      REMOVE_CARDITEM(state, payload) {
        state.cardItemId = payload;
      },
      UPDATE_CARDITEM(state, payload) {
        state.carditem = payload;
      },
      CHECK_CARD(state, payload) {
        state.card = payload;
      },
      CREATE_CARD_QRCODE(state, payload) {
        state.cardQRCode = payload;
      },
      CREATE_NEW_CARD(state, newCard) {
        state.newCard = newCard;
      },
      READ_QRCODE(state, QRtext) {
        state.QRtext = QRtext;
      },
      VALIDATE_NEW_CARD(state, cardTopuValidateStatus) {
        state.cardTopuValidateStatus = cardTopuValidateStatus;
        state.newCard = "";
      },
      CANCEL_NEW_CARD(state, cardTopuValidateStatus) {
        state.cardTopuValidateStatus = cardTopuValidateStatus;
        state.newCard = "";
        state.scannedCard = "";
      },
      NEW_CARDMESSAGE(state, cardTopuValidateStatus) {
        state.cardTopuValidateStatus = cardTopuValidateStatus;
      },
      REMOVE_ALL_CARDITEMS(state) {
        state.carditemlist = carditemlist;
      },
      QUERY_ERROR(state, queryerror) {
        state.queryerror = queryerror;
      },
      SET_SCANNEDCARD(state, scannedCard) {
        state.scannedCard = scannedCard;
      },
    },
    actions: {
      setScannedCard({ commit }, payload) {
        commit("SET_SCANNEDCARD", payload);
      },
      checkCard({ commit, dispatch }, payload) {
        axios({
          url: `${path}/check`,
          method: "POST",
          params: {
            cardNumber: payload.cardNumber,
            cardPIN: payload.cardPIN,
          },
          headers: {
            Authorization: Bearer,
          },
        })
          .then((r) => r.data)
          .then((carditem) => {
            commit("CHECK_CARD", carditem);
            dispatch("loadCarditemlist");
            commit("QUERY_ERROR", "");
          })
          .catch((err) => {
            commit("QUERY_ERROR", err.response.data);
            console.log(err.response.data);
          });
      },

      removeCarditem({ commit, dispatch }, payload) {
        axios({
          url: `${path}/remove/${payload}`,
          method: "DELETE",
          headers: {
            Authorization: Bearer,
          },
        }).then(() => {
          console.log(payload);
          commit("REMOVE_CARDITEM", payload);
          dispatch("loadCarditemlist");
        });
      },

      removeAllCardItems({ commit }) {
        axios({
          url: `${path}/cardItemList/removeAll`,
          method: "DELETE",
          headers: {
            Authorization: Bearer,
          },
        }).then(() => {
          commit("REMOVE_ALL_CARDITEMS");
        });
      },

      //Update cardItem amount
      updateCarditem({ commit, dispatch }, payload) {
        axios({
          url: `${path}/update/cardItem`,
          method: "PUT",
          data: {
            id: payload.cardItemId,
            cardAmount: payload.cardAmount,
          },
          headers: {
            Authorization: Bearer,
          },
        })
          .then((r) => r.data)
          .then((carditem) => {
            commit("UPDATE_CARDITEM", carditem);
            dispatch("loadCarditemlist");
            console.log(payload);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      loadCarditemlist({ commit }) {
        if (app.config.globalProperties.$keycloak.token) {
          axios({
            url: `${path}/cardItemList`,
            method: "GET",
            params: {
              userId: app.config.globalProperties.$keycloak.subject,
            },
            headers: {
              Authorization: Bearer,
            },
          })
            .then((r) => r.data)
            .then((carditemlist) => {
              commit("GET_CARDITEMLIST", carditemlist);
              console.log(carditemlist);
            })
            .catch((err) => {
              console.log(err.response.data);
            });
        }
      },

      loadSoldCard({ commit }) {
        axios({
          url: `${path}/soldCards`,
          method: "GET",
          headers: {
            Authorization: Bearer,
          },
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
          },
        })
          .then((r) => r.data)
          .then((cardlist) => {
            commit("GET_SOLD_CARD_LIST", cardlist);
            console.log(cardlist);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      createNewCardLiveScan({ commit }, payload) {
        axios({
          url: `${path}/issue/newCard/liveScan`,
          method: "POST",
          params: {
            cardAmount: parseFloat(payload),
          },
          headers: {
            Authorization: Bearer,
          },
        })
          .then((r) => r.data)
          .then((newCard) => {
            commit("CREATE_NEW_CARD", newCard);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      validateNewCard({ commit, dispatch }, payload) {
        axios({
          url: `${path}/validate/newCard`,
          method: "POST",
          params: {
            cardId: payload.cardId,
            recipientId: payload.recipientId,
          },
          headers: {
            Authorization: Bearer,
          },
        })
          // .then(r => r.data)
          .then((cardTopuValidateStatus) => {
            commit("VALIDATE_NEW_CARD", cardTopuValidateStatus);
            console.log(cardTopuValidateStatus);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      cancelNewCard({ commit }, payload) {
        axios({
          url: `${path}/cancel/newCard`,
          method: "PUT",
          params: {
            cardId: payload,
          },
          headers: {
            Authorization: Bearer,
          },
        })
          // .then(r => r.data)
          .then((cardTopuValidateStatus) => {
            commit("CANCEL_NEW_CARD", cardTopuValidateStatus);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      createNewCardLaterScan({ commit }, payload) {
        axios({
          url: `${path}issue/newCard/laterScan`,
          method: "POST",
          params: {
            cardAmount: parseFloat(payload),
          },
          headers: {
            Authorization: Bearer,
          },
        })
          .then((r) => r.data)
          .then((newCard) => {
            console.log(newCard);
            const message = `New card issued for ${newCard.currenceSymbol}${newCard.initAmount} ${newCard.currencyCode}`;
            commit("CREATE_NEW_CARD", newCard);
            commit("NEW_CARDMESSAGE", message);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      createCardQRcode({ commit }, payload) {
        axios({
          url: `${path}card/createQrCode`,
          data: payload,
          method: "POST",
          headers: {
            Authorization: Bearer,
          },
          responseType: "arraybuffer",
        })
          .then((res) => {
            `data:${res.headers["content-type"]};base64,${Buffer.from(
              String.fromCharCode(...new Uint8Array(res.data)),
              "binary"
            ).toString("base64")}`;
            commit(
              "CREATE_CARD_QRCODE",
              `data:${res.headers["content-type"]};base64,${Buffer.from(
                String.fromCharCode(...new Uint8Array(res.data)),
                "binary"
              ).toString("base64")}`
            );
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      // Read card QR Code
      decodeCardQRCode({ commit, dispatch }, payload) {
        axios({
          url: `${path}card/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
          headers: {
            Authorization: Bearer,
          },
        })
          .then((r) => r.data)
          .then((QRtext) => {
            commit("READ_QRCODE", QRtext);
            console.log(QRtext);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
    },
  };
};
// export default card;
