<template>
  <!-- <f7-page> -->
  <f7-list dividers-ios strong-ios media-list form-store-data class="no-margin-bottom no-padding-bottom">
    <f7-list-group>
      <h5 class="text-align-center margin marging-vertical-half" style="font-weight: normal">
        {{ $t("addnewcards.scan") }}<span class="avocash">avo¢ash</span>{{ $t("addnewcards.qrCodeToTopup") }}
      </h5>

      <div class="text-align-center margin">
        <f7-link icon-size="50" icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder"
          icon-md="f7:qrcode_viewfinder" class="text-align-left" @click="scanQRCode()"></f7-link>
      </div>
      <h5 class="text-align-center margin marging-vertical-half" style="font-weight: normal">
        <span class="avocash">avo¢ash</span>{{ $t("addnewcards.cardCodeAndPin") }}
      </h5>
      <!-- xsmall-grid-cols-1 grid grid-cols-2 -->
      <div class="grid cols75_25" form>
        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">
              <span class="avocash">avo¢ash</span>{{ $t("addnewcards.code") }}
            </div>

            <div class="item-input-wrap">
              <input type="text" required inputmode="numeric" id="cardNumber" validate placeholder="0000 0000 0000 0000"
                value="0000 0000 0000 0000" @input="cardNumber = $event.target.value" @keyup.enter="checkCard()"
                @change="inputFocus()" @blur="inputFocus()" @focus="inputFocus()" maxlength="19" size="19"
                :data-error-message="$t('addnewcards.coderequired')" data-validate-on-blur="true" />

              <span v-if="this.inputFocused" class="input-clear-button padding-right" @click="clearCardButton()"></span>
            </div>
          </div>
          <f7-link v-if="!this.inputFocused" class="item-media" @click="scanQRCode()" icon-ios="f7:qrcode_viewfinder"
            icon-aurora="f7:qrcode_viewfinder" icon-md="f7:qrcode_viewfinder">
          </f7-link>
        </li>

        <li class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">
              {{ $t("common.pin") }}
            </div>
            <span></span>
            <div class="item-input-wrap">
              <input type="text" inputmode="numeric" id="cardPIN" required placeholder="0000" value="°°°°" validate
                @input="cardPIN = $event.target.value" @keyup.enter="checkCard()" maxlength="4" size="4"
                autocomplete="off" :data-error-message="$t('addnewcards.noPin')" />
              <span @click="clearPINButton()" class="input-clear-button"></span>
            </div>
          </div>
        </li>
      </div>
      <f7-block class="no-margin-vertical" v-if="this.queryerror !== null || this.nocardNumberOrPINError !== null">
        <p style="font-size: 80%; font-style: italic; color: red">
          {{ this.queryerror || this.nocardNumberOrPINError }}
        </p>
      </f7-block>
      <f7-button @click="checkCard()" large raised fill ripple class="buttons" color="red" id="checkCard">{{
        $t("addnewcards.checkMyCardCredit") }}</f7-button>
      <f7-block-header id="carditems" v-if="this.carditemlist && this.carditemlist.length > 0">{{
        $t("addnewcards.updateAmountSwipe") }}</f7-block-header>
      <span v-if="this.carditemlist && this.carditemlist.length > 0">
        <li class="item-content item-input swipeout" v-for="carditem in carditemlist" :key="carditem.id"
          @swipeout:delete="removeCardItem(carditem)">
          <div class="item-media">
            {{ carditem.currencyCode }}
          </div>
          <div class="item-inner swipeout-content" v-if="!!carditem.card">
            <div class="item-title item-label">
              {{ $t("addnewcards.card") }} {{ carditem.card.cardUID }}
              <span v-html="$filtersToCurrency.toCurrency(
                carditem.card.availableBalance,
                carditem.currencySymbolNative,
                carditem.currencyCode
              )
                "></span>
            </div>
            <div class="item-input-wrap">
              <input type="number" min="0" :max="carditem.cardAmount" :id="carditem.id" :name="carditem.id"
                :value="carditem.cardAmount || cardAmount" @input="carditem.cardAmount = $event.target.value"
                :key="carditem.id" @blur="updateCarditem(carditem)" @keyup="updateCarditem(carditem)"
                @change="updateCarditem(carditem)" />
              <span class="input-clear-button"></span>
            </div>
          </div>
          <f7-swipeout-actions left>
            <f7-swipeout-button delete overswipe :confirm-text="$t('addnewcards.areYouSure')">{{ $t("common.delete") }}
            </f7-swipeout-button>
          </f7-swipeout-actions>
        </li>
      </span>
      <!-- <f7-block-header v-if="carditemlist && carditemlist.length > 0"
        >{{ $t('addnewcards.updateTheAmount') }}</f7-block-header
      > -->
      <li v-if="carditemlist && carditemlist.length > 0" class="item-content item-input swipeout"
        @swipeout:delete="removeCarditemlist()" swipeout>
        <div class="item-media">
          {{ this.carditemlist[0].currencyCode }}
        </div>
        <div class="item-inner swipeout-content">
          <div class="item-title item-label">
            {{ $t("addnewcards.totalAmount") }}
          </div>
          <div class="item-input-wrap">
            <input type="number" name="totalCardbasket" required disabled validate :max="this.totalCardbasket"
              :value="parseFloat(this.totalCardbasket || this.amountToSend).toFixed(2)"
              @input="totalCardbasket = $event.target.value" />
            <span class="input-clear-button"></span>
          </div>
        </div>
        <f7-swipeout-actions left>
          <f7-swipeout-button delete overswipe :confirm-text="$t('addnewcards.areYouSure')">{{ $t("common.delete") }}
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </li>
    </f7-list-group>
  </f7-list>
  <!-- </f7-page> -->
</template>
<style scoped>
.cols80_20 {
  display: grid;
  grid-template-columns: 80% 20%;
}
</style>
<style>
.qRCodeInput {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition-duration: 100ms;
  position: absolute;
  /* top: 50%; */
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  z-index: 1;
  cursor: pointer;
  background: none;
  width: var(--f7-input-clear-button-size);
  height: var(--f7-input-clear-button-size);
  color: "auto";
  right: 0;
}
</style>
<script>
import { mapGetters, mapState } from "vuex";
import { scanCard, checkCard } from "../js/mixins/card.js";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";
import {
  f7List,
  f7ListGroup,
  f7BlockHeader,
  f7ListInput,
  f7Link,
  f7Block,
  f7Button,
  f7SwipeoutActions,
  f7SwipeoutButton,
  theme,
  f7,
} from "framework7-vue";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [scanCard, checkCard, calculateTransferPayment, scanQRCodeGeneral, keyPadMixin],
  components: {
    f7List,
    f7ListGroup,
    f7BlockHeader,
    f7ListInput,

    f7Link,
    f7Block,
    f7Button,
    f7SwipeoutActions,
    f7SwipeoutButton,
  },
  data() {
    return {
      theme,
      f7,
      cardNumber: "",
      cardPIN: "",
      nocardNumberOrPINError: "",
      amountToSend: "",
      amountToPay: "",
      cardAmount: "",
      inputVal: "",
      inputFocused: false,
    };
  },
  mounted() {
    const self = this;
    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry", this.profile.countryCode);
    }
    self.$store.state.card.queryerror === "";
  },
  methods: {
    inputFocus() {
      const self = this;
      this.inputFocused = true;
    },
  },

  computed: {
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("transaction", [
      "QRtext",
      "destinationCountry",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "qrcodeDecoded",
      "QRtext",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),
    ...mapState("card", ["carditemlist", "queryerror", "QRtext", "cardBasketAmount"]),
    ...mapState("auth", ["profile"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    totalCardbasket() {
      return _.reduce(
        this.carditemlist,
        (amount, carditem) => amount + parseFloat(carditem.cardAmount || 0),
        0
      );
    },
  },
};
</script>
