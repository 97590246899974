<template>
    <f7-card-content hide-on-scroll>
        <swiper-container orientation="horizontal" :pagination="true" :space-between="true" :slides-per-view="1"
            :centered-slides="true" class="transactions_swiper">
            <swiper-slide v-if="this.barChartSeries.length > 0" id="barChartSwiper">
                <apexchart type="bar" :options="this.barChartOptions" :series="this.barChartSeries">
                </apexchart>
            </swiper-slide>
            <swiper-slide v-if="this.areaChartSeries.length > 0" id="areaChartSwiper">
                <apexchart type="area" :options="this.areaChartOptions" :series="this.areaChartSeries">
                </apexchart>
            </swiper-slide>
            <swiper-slide v-if="this.pieChartSeries.length > 0" id="pieChartSwiper">
                <apexchart width="500" min-height="200" type="donut" :options="this.pieChartOptions"
                    :series="this.pieChartSeries">
                </apexchart>
            </swiper-slide>
        </swiper-container>
    </f7-card-content>
</template>
<style scoped></style>
<script>
import {
    f7List,
    f7ListGroup,
    f7BlockTitle,
    f7Accordion,
    f7BlockHeader,
    f7ListItem,
    f7ListInput,
    f7Button,
    theme,
    f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { avocash_charts } from "../../js/mixins/charts.js";

export default {
    props: {
        f7route: Object,
        f7router: Object,
    },
    mixins: [avocash_charts],

    components: {
        f7List,
        f7ListGroup,
        f7BlockTitle,
        f7Accordion,
        f7BlockHeader,
        f7ListItem,
        f7ListInput,
        f7Button,
    },
    data() {
        return {
            theme,
            f7,
            dataValue: [],
            axisData: [],
            area_name: "",
            bar_name: "",
            pieData: [],
            pieLabel: [],
            barData: [],
            barLabel: [],

            locales: [],
            barChartOptions: {},
            pieChartOptions: {},
            areaChartOptions: {},
            areaChartSeries: [],
            pieChartSeries: [],
            barChartSeries: [],
            colors: [],
        };
    },
    mounted() {
        this.colors = [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
            "#3F51B5",
            "#03A9F4",
            "#4CAF50",
            "#F9CE1D",
            "#FF9800",
            "#33B2DF",
            "#546E7A",
            "#D4526E",
            "#13D8AA",
            "#A5978B",
            "#4ECDC4",
            "#C7F464",
            "#81D4FA",
            "#546E7A",
            "#FD6A6A",
            "#2B908F",
            "#F9A3A4",
            "#90EE7E",
            "#FA4443",
            "#69D2E7",
            "#449DD1",
            "#F86624",
            "#EA3546",
            "#662E9B",
            "#C5D86D",
            "#D7263D",
            "#1B998B",
            "#2E294E",
            "#F46036",
            "#E2C044",
            "#662E9B",
            "#F86624",
            "#F9C80E",
            "#EA3546",
            "#43BCCD",
            "#5C4742",
            "#A5978B",
            "#8D5B4C",
            "#5A2A27",
            "#C4BBAF",
            "#A300D6",
            "#7D02EB",
            "#5653FE",
            "#2983FF",
            "#00B1F2",
        ];
        this.locales = [
            {
                name: this.locale,
                options: {
                    months: this.$dayjs.months(),
                    shortMonths: this.$dayjs.monthsShort(),
                    days: this.$dayjs.weekdays(),
                    shortDays: this.$dayjs.weekdays(),
                    toolbar: {
                        exportToSVG: this.$t("common.downloadSVG"), //'Download SVG',
                        exportToPNG: this.$t("common.downloadPNG"), //'Download PNG',
                        exportToSCV: this.$t("common.downloadCSV"), //'Download CSV',
                        menu: this.$t("common.menu"), //Menu
                        selection: this.$t("common.selection"), // 'Selection',
                        selectionZoom: this.$t("common.selectionZoom"), //'Selection Zoom',
                        zoomIn: this.$t("common.zoomIn"), // 'Zoom In',
                        zoomOut: this.$t("common.zoomOut"), // 'Zoom Out',
                        pan: this.$t("common.panning"), // 'Panning',
                        reset: this.$t("common.resetZoom"), // 'Reset Zoom',
                    },
                },
            },
        ];
        if (this.userAccount === null && this.$keycloak.authenticated) {
            this.$store.dispatch("account/loadAccount", this.profile.sub);
        }
        // if (this.structuredTransactionList && this.structuredTransactionList.length > 0) {
        this.initiateCharts();
    },

    methods: {
        initiateCharts() {
            this.areaChartOptions = {
                chart: {
                    type: "area",
                    locales: this.locales,
                    defaultLocale: this.locale,
                    id: "areaChart",
                },

                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    fixed: {
                        enabled: false,
                        position: "topRight",
                    },
                },
                title: {
                    text: undefined,
                    align: "bottom",
                },
                subtitle: {
                    text: undefined,
                    align: "bottom",
                },
                theme: {
                    mode: "light",
                    palette: "palette1",
                    monochrome: {
                        enabled: false,
                        color: "#255aee",
                        shadeTo: "light",
                        shadeIntensity: 0.65,
                    },
                },
                animations: {
                    enabled: true,
                    easing: "easeinout",
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150,
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350,
                    },
                },
                stroke: {
                    curve: "smooth",
                },
                xaxis: {
                    categories: this.structuredTransactionList
                        ? this.structuredTransactionList.find((item) => item.type === "area")
                            .categories
                        : [],
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    },
                },
                noData: {
                    text: f7.preloader.show("multi"),
                },
            };
            this.areaChartSeries = [
                {
                    name: this.structuredTransactionList
                        ? this.structuredTransactionList.find((item) => item.type === "area").name
                        : [],
                    data: this.structuredTransactionList
                        ? this.structuredTransactionList.find((item) => item.type === "area").data
                        : [],
                },
            ];

            this.pieChartOptions = {
                chart: {
                    width: 500,
                    type: "donut",
                    defaultLocale: this.locale,
                    locales: this.locales,
                    id: "pieChart",
                },
                title: {
                    text: undefined,
                    align: "bottom",
                },
                subtitle: {
                    text: undefined,
                    align: "bottom",
                },
                legend: {
                    show: this.isTablet ? true : false,
                },
                theme: {
                    mode: "light",
                    palette: "palette1",
                    monochrome: {
                        enabled: false,
                        color: "#255aee",
                        shadeTo: "light",
                        shadeIntensity: 0.65,
                    },
                },
                animations: {
                    enabled: true,
                    easing: "easeinout",
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150,
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350,
                    },
                },
                colors: this.colors,
                labels: this.structuredTransactionList
                    ? this.structuredTransactionList.find((item) => item.type === "pie").labels
                    : [],

                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            };
            this.pieChartSeries = this.structuredTransactionList
                ? this.structuredTransactionList.find((item) => item.type === "pie").series
                : [];

            this.barChartOptions = {
                chart: {
                    type: "bar",
                    height: 350,
                    defaultLocale: this.locale,
                    locales: this.locales,
                    id: "barChart",
                },
                title: {
                    text: undefined,
                    align: "bottom",
                },
                subtitle: {
                    text: undefined,
                    align: "bottom",
                },
                theme: {
                    mode: "light",
                    palette: "palette1",
                    monochrome: {
                        enabled: false,
                        color: "#255aee",
                        shadeTo: "light",
                        shadeIntensity: 0.65,
                    },
                },
                animations: {
                    enabled: true,
                    easing: "easeinout",
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150,
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350,
                    },
                },
                toolbar: {
                    show: true,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true,
                    },
                    export: {
                        csv: {
                            filename: `${this.apiWallet.tag} Transactions.csv`,
                            columnDelimiter: ";",
                        },
                        svg: { filename: `${this.apiWallet.tag} Transactions.svg` },
                        png: { filename: `${this.apiWallet.tag} Transactions.png` },
                    },
                },
                // legend: {
                //     show: this.isTablet ? true : false,
                // },
                colors: this.colors,
                plotOptions: {
                    bar: {
                        columnWidth: "45%",
                        distributed: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    categories: this.structuredTransactionList
                        ? this.structuredTransactionList.find((item) => item.type === "bar")
                            .categories
                        : [],
                },

                labels: {
                    style: {
                        colors: this.colors,
                        fontSize: "12px",
                    },
                },
            };

            this.barChartSeries = [
                {
                    data: this.structuredTransactionList
                        ? this.structuredTransactionList.find((item) => item.type === "bar").data
                        : [],
                },
            ];

            f7.preloader.hide();
        },
    },
    computed: {
        ...mapState("apimoney", [
            "bankAccount",
            "bankAccountList",
            "standard",
            "business",
            "accountList",
            "transactionList",
            "apiWallet",
            "structuredTransactionList",
        ]),
        ...mapState("auth", [
            "profile",
            "isDeskTop",
            "isTablet",
            "documentsUrl",
            "document",
            "locale",
        ]),
        ...mapState("account", ["userAccount"]),

        ...mapGetters("auth", [
            "getProfile",
            "isAuthenticated",
            "isProfileLoaded",
            "getusererror",
        ]),
    },
};
</script>
