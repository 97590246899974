<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("shopdetail.updateMyShop") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title large>{{ this.shop.name }}</f7-block-title>
        <f7-list dividers-ios strong-ios form id="createNewShop" class="padding-bottom">
          <f7-list-group>
            <f7-list-input :label="$t('common.phoneNumber')" type="tel" input-id="phoneNumber" name="phoneNumber"
              :value="this.telephone" @input="telephone = $event.target.value"
              :error-message="$t('common.providePhoneNumber')" required validate></f7-list-input>

            <f7-list-input :label="$t('common.email')" floating-label type="email" name="email"
              :placeholder="$t('common.email')" :value="this.email" @input="email = $event.target.value"
              :error-message="$t('common.provideEmail')" required validate></f7-list-input>
            <!-- :info="$t('shopedit.myShopEmailInfo')" -->

            <f7-list-input :label="$t('common.address')" floating-label type="textarea" name="address" resizable
              :placeholder="$t('common.address')" :value="this.address" @input="address = $event.target.value"
              :error-message="$t('common.provideAddress')" required validate>
            </f7-list-input>
            <f7-list-input :label="$t('common.tag')" :placeholder="$t('common.tag')" :value="this.tag" name="shopTag"
              @texteditor:change="(v) => (tag = v)" resizable type="textarea"></f7-list-input>
            <f7-text-editor :value="this.description" name="description" @texteditor:change="(v) => (description = v)"
              resizable v-if="this.shop.description" type="texteditor" :style="f7.device.cordova || !this.isTablet
                ? '--f7-text-editor-height: 150px'
                : ''
                " />
            <f7-block-title class="margin-top-half">{{
              $t("shopdetail.aboutShop", { myshop: this.shop.name })
            }}</f7-block-title>
            <f7-text-editor :value="this.about" name="aboutShop" @texteditor:change="(v) => (about = v)" resizable
              type="texteditor" :style="f7.device.cordova || !this.isTablet
                ? '--f7-text-editor-height: 150px'
                : ''
                " />
            <f7-block-title class="margin-top-half">{{
              $t("shopdetail.myShopPolicies")
            }}</f7-block-title>
            <f7-text-editor :label="$t('shopdetail.myShopPolicies')" :value="this.policies" name="ShopPolicies"
              @texteditor:change="(v) => (policies = v)" resizable type="texteditor" :style="f7.device.cordova || !this.isTablet
                ? '--f7-text-editor-height: 150px'
                : ''
                " />
            <myDocs :type="this.type"></myDocs>
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'absolute' : 'fixed' }">
          <f7-link raised fill large ripple class="link buttons" @click="updateMyShop()" strong text-color="white">{{
            $t("shopdetail.updateMyShop") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7ListInput,
  f7Input,
  f7BlockTitle,
  f7TextEditor,
  f7Toolbar,
  f7Link,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import { telephoneUtils } from "../js/mixins/telephone.js";
import { attachDocs } from "../js/mixins/attachDocs.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7ListInput,
    f7Input,

    f7BlockTitle,
    f7TextEditor,

    f7Toolbar,
    f7Link,
  },
  mixins: [telephoneUtils, attachDocs],
  data() {
    return {
      theme,
      f7,
      tag: "",
      description: "",
      address: "",
      email: "",
      telephone: "",
      about: "",
      policies: "",
      shopImages: [],
      files: "",
      forms: "",
      type: "shop",
      selectedCountry: "",
    };
  },
  mounted() {
    if (!this.shop) {
      this.$store.dispatch("shop/getActiveShopById", this.f7route.params.shopId);
    }
    this.selectedCountry = this.shop.countryCode;
    this.tag = this.shop.tag;
    this.description = this.shop.description;
    this.address = this.shop.address;
    this.email = this.shop.email;
    this.telephone = this.shop.telephone;
    this.tag = this.shop.tag;
    this.policies = this.shop.policies;
    this.about = this.shop.about;
  },
  methods: {
    async updateMyShop() {
      if (!f7.input.validateInputs("#createNewShop")) {
        return;
      }
      f7.preloader.show("multi");
      const newShop = {
        id: this.shop.id,
        about: this.about,
        tag: this.tag,
        description: this.description,
        policies: this.policies,
        address: this.address,
        email: this.email,
        telephone: this.telephone,
      };

      this.forms = new FormData();
      await this.forms.append("shop", JSON.stringify(newShop));
      for (const element of this.documentsUrl) {
        this.forms.append("files", element.file);
      }
      await this.$store.dispatch("shop/editShop", this.forms);
      await this.$store.dispatch("auth/clearDocumentsUrl");
      setTimeout(() => {
        this.f7router.navigate(`/shop/${this.shop.id}`);
        f7.preloader.hide();
      }, 300);
    },
  },

  computed: {
    ...mapState("shop", ["categorylist", "categories", "shop"]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    category() {
      return _.groupBy(this.categories, "categoryName");
    },
    uniqCategories() {
      return _.uniqBy(this.categories, "categoryId");
    },
    currentCategory() {
      return _.find(this.categories, ["id", this.shop.categoryId]);
    },
    shopPhotos() {
      const shopImages = this.shop.shopImagesList;
      if (shopImages & (shopImages.length > 1)) {
        return _.forEach(shopImages.slice(), (item) => {
          _.assign(item, {
            url: `data:image/png;base64,${item.image}`,
          });
        });
      }
    },
    currentDocuments() {
      const shopPhotoImage = this.shopPhotos;
      const logo = {
        id: this.shop.id,
        name: "logo",
        filetype: "image/png",
        image: this.shop.logo,
        url: `data:image/png;base64,${this.shop.logo}`,
      };
      if (!!shopPhotoImage && !!this.shop.logo) {
        shopPhotoImage.push(logo);
      }
      const qrImage = {
        id: this.shop.id,
        name: "QRImage",
        filetype: "image/png",
        image: this.shop.qrImage,
        url: `data:image/png;base64,${this.shop.qrImage}`,
      };
      if (!!shopPhotoImage && !!this.shop.qrImage) {
        shopPhotoImage.push(qrImage);
      }
      console.log(shopPhotoImage);
      return shopPhotoImage;
    },
  },
};
</script>
