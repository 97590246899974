<template>
  <f7-page no-toolbar>
    <f7-navbar no-shadow :title="$t('issuenewcardqr.mySoldCards')" :back-link="$t('common.backButton')"
      :back-link-show-text="false">
      <f7-nav-right>
        <f7-link href="/card/new">
          <f7-icon ios="f7:creditcard_fill" aurora="f7:creditcard_fill" md="material:credit_card">
            <f7-badge color="red">+</f7-badge>
          </f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-row>
      <f7-col width="100" medium="10" large="20" xlarge="30"></f7-col>
      <f7-col width="100" medium="80" large="60" xlarge="40">
        <f7-list dividers-ios strong-ios v-if="!this.cardListFinal" media-list
          class="skeleton-text skeleton-effect-blink">
          <li v-for="n in 10" :key="n">
            <div class="item-content">
              <div class="item-media">
                <div class="skeleton-block" style="width: 40px; height: 40px; border-radius: 50%"></div>
              </div>
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 80%">
                  <div class="item-title">
                    {{ $t("common.cardNumber") }}
                  </div>
                  <div class="item-title">
                    {{ $t("common.amount") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    FR 8585 7626 3246 7622
                  </div>
                  <div class="item-title" style="font-weight: bold">25 EUR</div>
                </div>
              </div>
            </div>
          </li>
        </f7-list>
        <f7-list dividers-ios strong-ios cards-list v-else class="cards-list searchbar-found" media-list
          v-for="(cards, yearMonth, index) in this.cardListFinal" :key="index">
          <f7-card :padding="false">
            <f7-list-item :title="yearMonth" group-title style="font-size: 18px; color: #007aff"></f7-list-item>

            <li v-for="cardItem in cards" :key="cardItem.id">
              <div class="swipeout-content item-content">
                <div class="item-inner">
                  <div class="item-title-row" style="font-size: 80%">
                    <div class="item-title">
                      {{ $t("common.cardNumber") }}
                    </div>
                    <div class="item-title">
                      {{ $t("common.amount") }}
                    </div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title" style="font-weight: bold">
                      {{ cardItem.cardUID }}
                    </div>
                    <div class="item-title" style="font-weight: bold">
                      <span v-html="$filtersToCurrency.toCurrency(
                        cardItem.initAmount,
                        '',
                        cardItem.currencyCode
                      )
                        "></span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </f7-card>

          <li v-if="this.cardlist && this.cardlist.length > 0" class="margin-bottom">
            <div class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="font-size: 80%">
                    {{ $t("common.totalAmount") }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      this.totalSoldCard,
                      '',
                      this.cardlist[0].currencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <f7-list-item></f7-list-item>
        </f7-list>
        <template #fixed>
          <f7-fab position="center-bottom" :text="$t('issuenewcardqr.issueNewCard')" color="red" href="/card/new">
            <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
          </f7-fab>
        </template>
      </f7-col>
      <f7-col width="100" medium="10" large="20" xlarge="30"></f7-col>
    </f7-row>
  </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { theme, f7 } from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return { theme, f7 };
  },

  mounted() {
    const self = this;
    self.$store.dispatch("card/loadSoldCard");
  },
  methods: {
    onIndexSelect(itemContent) {
      const self = this;
      console.log(itemContent);
    },
  },

  computed: {
    ...mapState("card", ["cardlist"]),

    ...mapState("auth", ["profile", "destinationCity", "isDeskTop", "isTablet"]),

    cardListFinal() {
      const currentCardList = this.cardlist;
      if (currentCardList) {
        return _.chain(currentCardList.slice())
          .orderBy(["issueDate"], ["desc"])
          .map((card) => _.extend({ yearMonth: card.issueDate.substr(0, 7) }, card))
          .groupBy("yearMonth")
          .value();
      }
    },

    totalSoldCard() {
      return _.reduce(
        this.cardlist,
        (amount, cardItem) => amount + parseFloat(cardItem.initAmount || 0),
        0
      );
    },
  },
};
</script>
