<template>
  <f7-page
    @page:beforeout="beforeOut()"
    class="tamtamMessagePage"
    infinite
    infinite-top
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
  >
    <f7-navbar
      :back-link="f7.width < 768 ? true : false"
      sliding
      no-shadow
      class="tamtamNav"
      v-if="this.f7route.params.type === 'undefined' && this.counterPartUser"
    >
      <f7-nav-left class="navBarLeftImg" @click="openContactDetailPanel()">
        <img
          class="margin-left"
          v-if="this.counterPartUser && this.counterPartUser.avatar !== null"
          :src="`data:image/png;base64,${this.counterPartUser.avatar}`"
          style="height: 32px; width: 32px; border-radius: 25px"
        />
        <f7-badge
          v-else
          color="auto"
          class="tamtamBadge margin-left"
          style="height: 32px; width: 32px; font-size: 16px"
          >{{ $filtersGetInitial.getInitial(counterPartUser.name) }}
        </f7-badge>
      </f7-nav-left>
      <f7-nav-title>{{ this.counterPartUser.name }} </f7-nav-title>
    </f7-navbar>
    <f7-navbar
      :back-link="f7.width < 768 ? true : false"
      sliding
      no-shadow
      class="tamtamNav"
      v-if="this.f7route.params.type !== 'undefined'"
    >
      <f7-nav-left
        class="navBarLeftImg"
        @click="openContactDetailPanel()"
        href="#"
        v-if="this.f7route.params.type !== 'PERSONAL' && this.group"
      >
        <img
          class="margin-left-half"
          v-if="this.group && this.group.avatar !== null"
          :src="`data:image/png;base64,${this.group.avatar}`"
          style="height: 32px; width: 32px; border-radius: 25px"
        />
        <f7-badge
          v-else
          color="auto"
          class="tamtamBadge margin-left-half"
          style="height: 32px; width: 32px; font-size: 16px"
          >{{ $filtersGetInitial.getInitial(group.name) }}</f7-badge
        >
      </f7-nav-left>
      <f7-nav-left
        class="navBarLeftImg"
        @click="openContactDetailPanel()"
        href="#"
        v-if="this.f7route.params.type === 'PERSONAL' && !!this.counterPartUser"
      >
        <img
          class="margin-left-half"
          v-if="this.counterPartUser && this.counterPartUser.avatar !== null"
          :src="`data:image/png;base64,${this.counterPartUser.avatar}`"
          style="height: 32px; width: 32px; border-radius: 25px"
        />
        <f7-badge
          v-else
          color="auto"
          class="tamtamBadge margin-left-half"
          style="height: 32px; width: 32px; font-size: 16px"
          >{{ $filtersGetInitial.getInitial(counterPartUser.name) }}
        </f7-badge>
      </f7-nav-left>
      <f7-nav-title class="title">
        <span v-if="this.f7route.params.type === 'PERSONAL' && !!this.counterPartUser"
          >{{
            this.counterPartUser.username ||
            this.counterPartUser.tag ||
            this.counterPartUser.phoneNumber
          }}
          <br />
          <small
            style="font-size: 75%; color: auto"
            v-if="
              this.counterPartUser.online == true &&
              (this.typingMessage === '' || !this.typingMessage.typing)
            "
            >{{ $t("tamtammessage.online") }}</small
          >
          <small
            v-else-if="
              this.counterPartUser.online == false &&
              (this.typingMessage === '' || !this.typingMessage.typing)
            "
            style="font-size: small"
            >{{ $t("tamtammessage.offlinesince") }}
            {{ $filtersToDate.toDateTime(this.counterPartUser.timeOffline) }}</small
          >
          <small
            style="font-size: small; color: var(--f7-theme-color)"
            v-else-if="this.typingMessage.typing"
            >{{
              $t("tamtam.isTyping", {
                isTyping: `${typingMessage.name}`,
              })
            }}</small
          >
        </span>
        <span v-if="this.f7route.params.type === 'GROUP' && this.group !== null">
          {{ this.group.name }} <br />
          <small
            style="font-size: small"
            v-if="this.typingMessage === '' || !this.typingMessage.typing"
            >{{ this.chatGroupUserList }}</small
          >
          <small
            style="font-size: small; color: var(--f7-theme-color)"
            v-if="this.typingMessage.typing"
            >{{
              $t("tamtam.isTyping", {
                isTyping: `${typingMessage.name}`,
              })
            }}</small
          >
        </span>
        <span v-if="this.f7route.params.type === 'SUBGROUP' && this.group !== null">
          {{ this.group.name }} <br />
          <small
            style="font-size: small"
            v-if="this.typingMessage === '' || !this.typingMessage.typing"
            >{{ this.chatGroupUserList }}</small
          >
          <small
            style="font-size: small; color: var(--f7-theme-color)"
            v-if="this.typingMessage.typing"
            >{{
              $t("tamtam.isTyping", {
                isTyping: `${typingMessage.name}`,
              })
            }}</small
          >
        </span>
      </f7-nav-title>

      <f7-nav-right>
        <!-- <f7-link
          :class="!this.isDeskTop ? 'no-margin-left' : ''"
          icon-aurora="f7:phone_fill_badge_plus"
          icon-ios="f7:phone_fill_badge_plus"
          icon-md="material:call"
        ></f7-link>
        <f7-link
          :class="!this.isDeskTop ? 'no-margin-left' : ''"
          icon-aurora="f7:videocam_fill"
          icon-ios="f7:videocam_fill"
          icon-md="material:video_call"
        ></f7-link> -->

        <f7-link
          v-if="this.isTablet"
          class="searchbar-enable"
          data-searchbar=".searchbar-message"
          icon-ios="f7:search_strong"
          icon-md="material:search"
        ></f7-link>
        <f7-link
          :class="!this.isTablet ? 'no-margin-left' : ''"
          popover-open=".otherActions"
          icon-ios="f7:ellipsis_vertical"
          icon-md="material:more_vert"
        ></f7-link>
      </f7-nav-right>

      <f7-searchbar
        class="searchbar-message"
        expandable
        search-container=".messages-list"
        search-item=".messages.message"
        search-in=".message-text, .message-name, .message-footer, .message-title, message-text-footer"
        :clear-button="true"
        :backdrop="false"
      ></f7-searchbar>
    </f7-navbar>

    <f7-navbar
      :style="this.messageIsSelected ? 'display: block' : 'display: none'"
      v-if="!this.isTablet"
      top
      no-shadow
      :class="'messageOptionsNavBar' + messageInScope.id"
    >
      <f7-nav-left v-if="this.selectedMessages.length > 1 || this.messageIsSelected">
        <f7-link @click="clearSelection()" icon-ios="f7:xmark" icon-md="material:clear">
        </f7-link>
        <a v-if="this.selectedMessages.length > 1">
          {{ this.selectedMessages.length }}
        </a>
      </f7-nav-left>
      <f7-nav-right v-if="this.selectedMessages.length > 1 || this.messageIsSelected">
        <f7-link
          v-if="this.selectedMessages.length > 1"
          icon-ios="f7:trash"
          icon-md="material:delete"
          icon-color="red"
          @click="deleteSelectedMsg()"
        ></f7-link>
        <f7-link
          v-if="this.selectedMessages.length > 1"
          :disabled="this.selectedMessages && this.selectedMessages.length < 3"
          icon-ios="f7:arrowshape_turn_up_right"
          icon-md="material:reply"
          :style="theme.md ? 'transform:  scaleX(-1)' : ''"
          icon-color="gray"
          @click="forwardAllMsg()"
        ></f7-link>
      </f7-nav-right>
      <f7-link
        v-if="
          messageInScope.groupType !== 'PERSONAL' && this.selectedMessages.length <= 1
        "
        icon-ios="f7:arrowshape_turn_up_left_2"
        icon-md="material:reply_all"
        @click="replyMsgGroup(messageInScope)"
      ></f7-link>
      <f7-link
        v-if="
          this.messageInScope.groupType === 'PERSONAL' &&
          this.selectedMessages.length <= 1
        "
        icon-ios="f7:arrowshape_turn_up_left"
        icon-md="material:reply"
        icon-color="lightblue"
        @click="replyMsgPersonal(messageInScope)"
      ></f7-link>
      <f7-link
        v-if="
          this.messageInScope.groupType !== 'PERSONAL' &&
          this.messageInScope.senderId !== profile.sub &&
          this.selectedMessages.length <= 1
        "
        icon-ios="f7:arrowshape_turn_up_left"
        icon-md="material:reply"
        icon-color="teal"
        @click="replyMsgPrivately(messageInScope)"
      ></f7-link>

      <f7-link
        v-if="this.selectedMessages.length <= 1"
        icon-ios="f7:trash"
        icon-md="material:delete"
        icon-color="orange"
        @click="deleteForMe(messageInScope)"
      ></f7-link>
      <f7-link
        v-if="
          this.messageInScope.senderId === profile.sub &&
          this.selectedMessages.length <= 1
        "
        icon-color="red"
        icon-ios="f7:trash_slash"
        icon-md="material:delete_forever"
        @click="deleteForAll(messageInScope)"
      ></f7-link>
      <f7-link
        v-if="this.selectedMessages.length <= 1"
        icon-color="deeppurple"
        icon-ios="f7:square_list"
        icon-md="material:playlist_add_check"
        @click="selectMsg(messageInScope)"
      ></f7-link>
      <f7-link
        v-if="this.selectedMessages.length <= 1"
        icon-color="lime"
        icon-ios="f7:square_on_square"
        icon-md="material:content_copy"
        class="margin-right-half"
        @click="copyMsg(messageInScope)"
      ></f7-link>
      <f7-link
        v-if="this.selectedMessages.length <= 1"
        icon-ios="f7:arrowshape_turn_up_right"
        icon-md="material:reply"
        :style="theme.md ? 'transform:  scaleX(-1)' : ''"
        icon-color="gray"
        @click="forwardMsg(messageInScope)"
      >
      </f7-link>
    </f7-navbar>
    <f7-messagebar
      :style="
        this.selectedMessages.length >= 1 && this.isTablet
          ? 'display:none'
          : 'display:block'
      "
      :placeholder="placeholder"
      ref="messagebar"
      :attachments-visible="attachmentsVisible"
      :sheet-visible="sheetVisible"
      :value="messageText"
      @input="messageText = $event.target.value"
      @keypress.enter="sendMessage()"
      @messagebar:focus="startTyping()"
      @messagebar:change="startTyping()"
      @keydown="startTyping()"
      @keyup="stopTyping()"
      @messagebar:blur="stopTyping()"
      @keypress.native="startTyping()"
      @keydown.native="startTyping()"
      @keyup.native="stopTyping()"
      @paste.native="onPaste"
      @paste="onPaste(event)"
    >
      <template #inner-start>
        <f7-link
          v-if="isTablet && !isRecording"
          icon-ios="f7:paperclip"
          icon-size="24"
          icon-md="material:attachment"
          class="attachFileButton"
          @click="attachFileOpenActions()"
        ></f7-link>
        <f7-link
          icon-ios="f7:smiley"
          icon-md="material:insert_emoticon"
          @click="toogleDialogEmoji()"
        ></f7-link>
      </template>
      <template #inner-end>
        <f7-link
          v-if="!isRecording && attachments.length == 0 && !isTablet"
          icon-ios="f7:paperclip"
          icon-size="24"
          icon-md="material:attachment"
          class="attachFileButton"
          @click="attachFileOpenActions()"
        >
        </f7-link>
        <f7-link
          v-if="!isRecording && messageText === '' && attachments.length == 0"
          icon-ios="f7:mic_circle_fill"
          icon-md="material:mic"
          @click="startRecording()"
        ></f7-link>
        <f7-link
          v-if="isRecording"
          icon-ios="f7:mic_circle_fill"
          icon-md="material:mic"
          icon-color="white"
          class="recordBtn stop"
          @click="stopRecording()"
          ref="stopRecord"
        >
        </f7-link>

        <f7-link
          v-if="messageText !== '' || (attachments && attachments.length > 0)"
          icon-ios="f7:arrow_up_circle_fill"
          icon-md="material:send"
          @click="sendMessage"
        ></f7-link>
      </template>

      <template #before-area>
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          style="display: none"
          @change="onChange"
          ref="file"
          accept="*"
        />
        <span
          style="width: 100%; max-height: 88px"
          v-if="this.replyMessage !== ''"
          class="no-margin no-padding"
          @click="scrollToMessage(replyMessage.id)"
          href="#"
        >
          <!-- <template #text> -->
          <span
            style="display: flex; flex-direction: row; justify-content: center"
            id="replyToMessage"
          >
            <span style="width: 100%">
              <span class="message-text" v-if="this.replyMessage.msgType === 'text'">
                <span class="message-name" style="display: inline-block">{{
                  this.replyMessage.senderName
                }}</span>
                <br />
                <span
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: inline-block;
                  "
                  >{{ this.replyMessage.text }}</span
                >
              </span>

              <span v-else>
                <span class="message-name" style="display: inline-block">{{
                  this.replyMessage.senderName
                }}</span>
                <br />
                <span
                  v-if="this.replyMessage.text"
                  class="message-text"
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: inline-block;
                  "
                  >{{ this.replyMessage.text }}</span
                >
                <br />
                <span
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    display: inline-block;
                  "
                  :class="
                    this.replyMessage.text
                      ? 'block-footer no-margin no-padding'
                      : 'no-margin no-padding'
                  "
                  >{{ this.replyMessage.fileName }}</span
                >
              </span>
            </span>

            <span
              style="width: 88px"
              v-if="this.replyMessage && this.replyMessage.attachment !== null"
            >
              <span style="width: 88px">
                <span
                  :style="{
                    'background-image': `url(data:image/png;base64,${this.replyMessage.attachment}`,
                    width: '100px',
                    height: '100px',
                    position: 'absolute',
                    right: '0px',
                  }"
                ></span>
              </span>
            </span>
          </span>
          <f7-link
            @click="removeReplyMessage()"
            style="position: absolute; right: 8px; top: 8px; width: 0px; height: 0px"
            icon-ios="f7:xmark_circle"
            icon-md="material:clear"
            icon-size="24"
            icon-color="red"
            class="removeMsgIcon"
          >
          </f7-link>
        </span>
      </template>

      <f7-messagebar-attachments
        class="dropzone-container"
        :style="this.isDragging && 'border-color: green;'"
      >
        <f7-messagebar-attachment
          @attachment:delete="deleteAttachment(attachment)"
          :style="{
            'max-height': theme.md ? '72px' : '155px',
            width: theme.md ? '72px' : '155px',
          }"
          v-for="(attachment, index) in attachments"
          :key="index"
        >
          <f7-photo-browser
            ref="attachPopup"
            :photos="attachments"
            :thumbs="attachments"
            type="popup"
          />
          <a
            href="#"
            @click="$refs.attachPopup.open()"
            v-if="attachment.fileType === 'image'"
          >
            <img :src="attachment.url" v-if="attachment.fileType === 'image'"
          /></a>
          <audio controls v-if="attachment.fileType === 'audio'" class="attachmentFormat">
            <source :src="attachment.url" :type="attachment.fileType" />
            {{ $t("tamtammessage.yourBrowserDoesntsupportaudio") }}
          </audio>
          <video v-if="attachment.fileType === 'video'" controls>
            <source
              :src="attachment.url"
              :type="attachment.fileType"
              preload="metadata"
            />
            {{ $t("tamtammessage.yourBrowserDoesntsupportvideo") }}
          </video>
          <f7-link
            v-if="attachment.fileType === 'application'"
            class="file link"
            @click="viewOtherFiles(attachment)"
          >
            <!-- :src="`http://docs.google.com/viewer?url=${attachment.url}&embedded=true`" -->
            <iframe
              v-if="attachment.fileType === 'application'"
              :src="`${attachment.url}`"
              frameborder="0"
              :title="attachment.name"
              style="width: 100%"
            ></iframe>
          </f7-link>

          <f7-link
            v-if="attachment.fileType === 'image'"
            raised
            :style="{
              position: 'absolute',
              right: '-16px',
              top: '-10px',
            }"
            icon-f7=" pencil_circle"
            @click="editImage(attachment.id)"
          ></f7-link>
          <f7-link
            v-if="attachment.fileType === 'application'"
            raised
            :style="{
              position: 'absolute',
              right: '-16px',
              top: '-10px',
            }"
            icon-f7="arrow_up_right_square"
            @click="viewOtherFiles(attachment)"
          ></f7-link>
          <!-- <br />
          <f7-block-footer class="no-margin-vertical">{{ attachment.name }}
          </f7-block-footer> -->
        </f7-messagebar-attachment>
      </f7-messagebar-attachments>
      <f7-messagebar-sheet> </f7-messagebar-sheet>
      <template #before-inner>
        <span>
          <Picker
            @select="onSelectEmoji"
            :data="emojiIndex"
            :set="getSet"
            :emojiSize="emojisize"
            v-show="showDialog"
            :showPreview="false"
            color="var(--f7-theme-color)"
            :i18n="i18n_locale"
          /> </span
      ></template>
    </f7-messagebar>
    <f7-messages
      ref="messages"
      class="searchbar-found messages-list"
      v-if="this.f7route.params.type === 'undefined' && this.infoMessage.length > 0"
    >
      <f7-message
        v-for="(message, index) in infoMessage"
        :key="index"
        :type="message.id === null ? 'received' : 'sent'"
        :image="message.image"
        :avatar="
          message.senderName === 'avo¢hat' && message.type === 'received'
            ? '/icon-new.ico'
            : chatUser.avatar !== null
            ? `data:image/png;base64,${chatUser.avatar}`
            : null
        "
        :name="message.senderId === chatUser.userId ? '' : message.senderName"
        :first="isFirstMessage(message, index)"
        :last="isLastMessage(message, index)"
        :tail="isTailMessage(message, index)"
        @click:header="acceptInvitation()"
        @click:footer="rejectInvitation()"
      >
        <template #text>
          <span v-if="message.text" v-html="message.text"></span>
        </template>
        <template #text-footer>
          <span>
            {{ $filtersToTime.toMsgTime(message.sentAt) }}
          </span>
        </template>
      </f7-message>
      <f7-message
        v-if="typingMessage"
        type="received"
        :typing="true"
        :first="true"
        :last="true"
        :tail="true"
        :header="$t('tamtam.isTyping', { isTyping: `${typingMessage.name}` })"
        :avatar="typingMessage.avatar"
      >
      </f7-message>
    </f7-messages>
    <f7-messages
      ref="messages"
      class="searchbar-found messages-list mainMessages"
      v-if="messagesData"
    >

      <span
        v-for="(dayMessages, messageDate, index) in messagesData"
        :key="index"
        class="messages"
      >
        <f7-messages-title style="position: -webkit-sticky; position: sticky; top: 20px"
          ><b>{{ messageDate }}</b>
        </f7-messages-title>
        <span
          v-for="(message, index) in dayMessages"
          :key="index"
          @taphold="messageActions(message)"
          @click="messageActions(message)"
          href="#"
          :class="
            'messages margin-top-half tapHoldRemoveLinks selectedMsg selectedMsgClass' +
            message.id
          "
        >
          <f7-messages-title v-if="message.type === 'title'"
            ><b>{{ $filtersToDate.toDateTime(message.sentAt) }} {{ message.text }}</b>
          </f7-messages-title>
          <f7-message
            v-if="
              message.userMessageStatus &&
              message.userMessageStatus.length > 0 &&
              message.userMessageStatus.find((x) => x.status === 'deleted') &&
              message.userMessageStatus.find((x) => x.userId === profile.sub) &&
              message.userMessageStatus.find((x) => x.userId === profile.sub).status ===
                'deleted' &&
              message.type !== 'title'
            "
            :avatar="
              message.senderId !== chatUser.userId && counterPartUser.avatar !== null
                ? `data:image/png;base64,${counterPartUser.avatar}`
                : null
            "
            :id="message.id"
            :type="message.senderId === chatUser.userId ? 'sent' : 'received'"
            :name="
              message.senderId === chatUser.userId || message.groupType === 'PERSONAL'
                ? ''
                : message.senderName
            "
            :first="isFirstMessage(dayMessages, message, index)"
            :last="isLastMessage(dayMessages, message, index)"
            :tail="isTailMessage(dayMessages, message, index)"
          >
            <template #text>
              <span
                style="font-style: italic"
                v-if="message.userMessageStatus.find((x) => x.deletedBy === profile.sub)"
                >{{ $t("tamtammessage.youDeletedThisMessage") }}</span
              >
              <span style="font-style: italic" v-else>{{
                $t("tamtammessage.messageDeleted")
              }}</span></template
            >
            <template #text-footer>
              <span>
                {{ $filtersToTime.toMsgTime(message.sentAt) }}
              </span></template
            >
          </f7-message>
          <label
            class="checkbox selectableMsg"
            v-if="
              message.type !== 'title' &&
              (message.status !== 'deleted' ||
                (message.userMessageStatus.length > 0 &&
                  !message.userMessageStatus.find((x) => x.status === 'deleted')))
            "
            :style="{
              'padding-left': message.type === 'sent' ? '16px' : '32px',
              position: 'absolute',
              left: !f7.support.touch == false ? '40px' : '',
              top: '50%',
              '-ms-transform': 'translateY(-50%)',
              transform: 'translateY(-50%)',
              display:
                f7.support.touch == false && messageIsSelected == false
                  ? 'none'
                  : 'block',
            }"
          >
            <input
              @change="selectMessage(message)"
              type="checkbox"
              :value="message.id"
              v-if="selectedMessages && selectedMessages.length > 0"
              :checked="selectedMessages.findIndex((x) => x.id === message.id) >= 0"
              :name="'checkbox' + message.id"
            />
            <i class="icon-checkbox" v-if="f7.support.touch == false"></i>
          </label>
          <f7-message
            v-if="
              !message.userMessageStatus.find((x) => x.status === 'deleted') &&
              message.type !== 'title'
            "
            :avatar="
              message.senderId !== chatUser.userId && counterPartUser.avatar !== null
                ? `data:image/png;base64,${counterPartUser.avatar}`
                : null
            "
            :id="message.id"
            @taphold="messageBarVisible(message)"
            @click="messagePhotoBrowser(message)"
            :type="message.senderId === chatUser.userId ? 'sent' : 'received'"
            class="tapHoldRemoveLinks"
            :name="
              message.senderId === chatUser.userId || message.groupType === 'PERSONAL'
                ? ''
                : message.senderName
            "
            :image="message.msgType === 'image' ? message.url : ''"
            :first="isFirstMessage(dayMessages, message, index)"
            :last="isLastMessage(dayMessages, message, index)"
            :tail="isTailMessage(dayMessages, message, index)"
          >
            <template #media>
              <span v-if="message.msgType === 'audio'">
                <audio controls>
                  <source :src="message.url" :type="message.mimetype" />
                  {{ $t("tamtammessage.yourBrowserDoesntsupportaudio") }}</audio
                ><br />
                <f7-block-footer class="no-margin-vertical message-text-footer">{{
                  message.fileName
                }}</f7-block-footer>
              </span>

              <span v-if="message.msgType === 'video'">
                <video
                  controls
                  style="display: block; max-width: 100%; height: auto; width: auto"
                >
                  <source
                    :src="message.url"
                    :type="message.mimetype"
                    preload="metadata"
                  />
                  {{ $t("tamtammessage.yourBrowserDoesntsupportvideo") }}</video
                ><br />
                <f7-block-footer class="no-margin-vertical message-text-footer">{{
                  message.fileName
                }}</f7-block-footer>
              </span>
              <span v-if="message.msgType === 'file'">
                <a :href="message.url" class="link color-white">
                  <i class="f7- if-not-md">doc</i>
                  <i class="material- md-only"
                    ><span v-if="message.mimetype === 'application/pdf'"
                      >picture_as_pdf</span
                    ><span v-else>description</span>
                  </i>
                  <span class="filename message-text-footer">
                    {{ message.fileName || message.url }}
                  </span>
                </a>
              </span>
            </template>
            <template #text>
              <span v-if="message.text" v-html="message.text"> </span>
            </template>
            <template #text-footer>
              <span style="font-size: 100%">
                {{ $filtersToTime.toMsgTime(message.sentAt) }}
                <span
                  v-if="
                    message.senderId === chatUser.userId &&
                    message.userMessageStatus &&
                    message.userMessageStatus.length > 0
                  "
                >
                  <f7-icon
                    ios="material:done"
                    md="material:done"
                    v-if="
                      message.userMessageStatus.find((x) => x.userId === profile.sub)
                        .status === 'sent'
                    "
                    color="gray"
                    bg-color="white"
                    style="
                      border-radius: 50%;
                      font-size: 100%;
                      opacity: 1;
                      background-color: var(--f7-message-sent-text-color);
                    "
                  ></f7-icon>
                  <f7-icon
                    ios="material:done_all"
                    md="material:done_all"
                    v-if="
                      message.groupType === 'PERSONAL' &&
                      message.userMessageStatus.find((x) => x.userId === profile.sub)
                        .status === 'read'
                    "
                    style="
                      border-radius: 50%;
                      font-size: 100%;
                      opacity: 1;
                      background-color: var(--f7-message-sent-text-color);
                    "
                    color="auto"
                  ></f7-icon>
                </span>
              </span>
            </template>
            <template #end>
              <span
                v-if="isTablet && f7.support.touch"
                :class="'targetIcon' + message.id"
              ></span>
              <span
                class="popoverActionIcon"
                v-if="isTablet && !f7.support.touch"
                :class="'targetIcon' + message.id"
              >
                <f7-link
                  class="message-menu-icon"
                  @click="openMessageOpenActions(message)"
                  icon-ios="f7:chevron_down"
                  icon-md="material:expand_more"
                ></f7-link>
              </span>
            </template>
          </f7-message>
        </span>
      </span>
      <f7-message
        v-if="typingMessage"
        type="received"
        :typing="true"
        :first="true"
        :last="true"
        :tail="true"
        :header="$t('tamtam.isTyping', { isTyping: `${typingMessage.name}` })"
        :avatar="typingMessage.avatar"
      >
      </f7-message>
    </f7-messages>
    <!-- <f7-messages v-else>
      <div class="text-align-center" style="top: 50%; transform: translateY(-50%)">
        <f7-preloader color="multi" />
      </div>
    </f7-messages> -->
    <f7-toolbar
      v-if="this.isTablet"
      :style="this.messageIsSelected ? 'display: block' : 'display: none'"
      bottom
      no-shadow
      class="multiMessagesSelect"
    >
      <f7-link
        icon-ios="f7:xmark"
        icon-md="material:clear"
        class="link"
        :text="this.selectedMessages.length + ' ' + $t('tamtammessage.selectedMessages')"
        style="text-transform: lowercase"
        text-color="gray"
        @click="clearSelection()"
      ></f7-link>
      <f7-link
        :disabled="this.selectedMessages.length == 0"
        icon-ios="f7:trash"
        icon-md="material:delete"
        class="link"
        icon-color="red"
        @click="deleteSelectedMsg()"
      ></f7-link>
      <f7-link
        :disabled="selectedMessages && selectedMessages.length < 3"
        icon-ios="f7:arrowshape_turn_up_right"
        icon-md="material:reply"
        class="link"
        :style="theme.md ? 'transform:  scaleX(-1)' : ''"
        @click="forwardAllMsg()"
      ></f7-link>
    </f7-toolbar>

    <contactDetail
      :groupType="this.f7route.params.type"
      :chatGroupAdminList="this.chatGroupAdminList"
      :isGroupAdmin="this.isGroupAdmin"
      :commonGroups="this.commonGroups"
      :chatGroupParticipantList="this.chatGroupParticipantList"
    ></contactDetail>

    <!-- <addDocs :docToView="this.docToView"></addDocs> -->
    <messageActions
      :groupType="this.f7route.params.type"
      :currentMessage="this.currentMessage"
    ></messageActions>
    <transferMsg></transferMsg>
    <addParticipants
      :chatGroupParticipantList="this.chatGroupParticipantList"
    ></addParticipants>

    <mydocs type="message" />
  </f7-page>
</template>
<style scoped>
.tamtamNav:hover,
#replyToMessage:hover {
  cursor: pointer;
}

.attachmentFormat {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.fab-label-button .fab-label {
  display: none;
}

.fab-button a {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.fab-buttons a {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.mainMessages .message-footer {
  margin: 0 auto;
}

.message-menu-icon {
  display: none;
}

.messagebar-area .message {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0px 0px;
}

.messagebar-area .message .message-content {
  /* min-width: 100%; */
  width: 100%;
  /* max-width: 100%;
  margin: 0px 0px; */
}

.messagebar-area .message .message-text .message-name {
  display: inline-block;
}

.tamtamMessagePage .message:hover .popoverActionIcon .message-menu-icon {
  display: inline-block;
  color: gray;
}

@media screen and (min-width: 1024px) {
  .view-master-detail .navbar-master-detail .navbar-inner a.link.back,
  .tamtamNav a.link.icon-only.back {
    display: none;
  }

  .navBarLeftImg {
    margin-left: 16px;
  }
}

.editInput {
  position: absolute;
  right: 0px;
  top: 8px;
  color: var(--f7-input-clear-button-color);
}

.recordBtn {
  position: absolute;
  right: 1rem;
  bottom: 0.25rem;
  background-color: #1d1d1d;
  border-radius: 50%;
}

.start {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: var(--f7-theme-color);
  box-shadow: none;
}

.recordBtn.stop {
  width: 2rem;
  height: 2rem;
  background-color: red;
  box-shadow: none;
}

.voiceMeter {
  position: absolute;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 3px 3px 10px #111;
}

.voiceMeterContainer {
  position: absolute;
  height: 2rem;
  width: 2rem;
  bottom: 0.25rem;
  right: 1rem;
}

.messagebar-attachment audio {
  padding-top: 40px;
}

.messagebar-attachment video {
  display: block;
  width: 80%;
  height: 100%;
  border-radius: var(--f7-messagebar-attachment-border-radius);
}

.messagebar-attachment a .file {
  padding-top: 40px;
  display: block;
  width: 80%;
  border-radius: var(--f7-messagebar-attachment-border-radius);
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Badge,
  f7Link,
  f7Searchbar,
  f7Messagebar,
  f7Message,
  f7Messages,
  f7MessagebarSheetImage,
  f7MessagebarSheet,
  f7MessagesTitle,
  f7MessagebarAttachments,
  f7MessagebarAttachment,
  f7Actions,
  f7ActionsButton,
  f7ActionsGroup,
  f7Subnavbar,
  f7BlockTitle,
  f7BlockFooter,
  f7List,
  f7ListItem,
  f7Popover,
  f7Sheet,
  f7ListGroup,
  f7ListInput,
  f7Button,
  f7AccordionContent,
  f7Accordion,
  f7AccordionItem,
  f7Icon,
  f7Panel,
  f7Block,
  f7Chip,
  f7Toolbar,
  f7Popup,
  f7Fab,
  f7FabButtons,
  f7FabButton,
  theme,
  f7ready,
  f7,
} from "framework7-vue";
import $ from "dom7";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import contactDetail from "../components/tamtam/contactDetail.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import messageActions from "../components/tamtam/messageActions.vue";
import transferMsg from "../components/tamtam/transferMsg.vue";
import addParticipants from "../components/tamtam/addParticipants.vue";
import { messageSocket } from "../js/websocket/websocketConnect.js";
import {
  messageOptionsMixin,
  // mediaMessage,
  messageGroupMixin,
} from "../js/mixins/messageMixins.js";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { cordovaCamera } from "../js/mixins/cordovaCamera.js";
// import { messageConnect } from "../js/websocket/messageWebsocket.js";
// @ts-ignore
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
// @ts-ignore
import { Picker, Emoji, EmojiIndex } from "emoji-mart-vue-fast/src";
const CUSTOM_EMOJIS = [
  {
    name: "avocash",
    short_names: ["avocash"],
    keywords: ["avocash"],
    text: "",
    emot: [],
    imageUrl: "/24x24.png",
  },
];
const emojiIndex = new EmojiIndex(data, { custom: CUSTOM_EMOJIS });

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    contactDetail,
    messageActions,
    transferMsg,
    addParticipants,
    Picker,
    Emoji,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Badge,
    f7Link,
    f7Searchbar,
    f7Messagebar,
    f7Message,
    f7Messages,
    f7MessagebarSheetImage,
    f7MessagebarSheet,
    f7MessagesTitle,
    f7MessagebarAttachments,
    f7MessagebarAttachment,
    f7BlockFooter,
    f7Actions,
    f7ActionsButton,
    f7ActionsGroup,
    f7Subnavbar,
    f7BlockTitle,
    f7List,
    f7ListItem,
    f7Popover,
    f7Sheet,
    f7ListGroup,
    f7ListInput,
    f7Button,
    f7AccordionContent,
    f7Accordion,
    f7AccordionItem,
    f7Icon,
    f7Panel,
    f7Block,
    f7Chip,
    f7Toolbar,
    f7Popup,
    f7Fab,
    f7FabButtons,
    f7FabButton,
  },
  mixins: [
    messageOptionsMixin,
    attachDocs,
    cordovaCamera,
    messageSocket,
    // messageConnect,
    // mediaMessage,
    messageGroupMixin,
  ],
  data() {
    return {
      theme,
      f7,
      searchPorst: "",
      displayedMsg: 10,
      allowInfinite: true,
      showPreloader: true,
      // attachments: [],
      imGattachments: [],
      imageCapture: "",
      docToView: {},
      //image, video, audio, pdf, doc,
      sheetVisible: false,
      selectAttachVisible: false,
      // selectedMsg: [],
      // messageBarVisible: false,
      messageInScope: "",
      messageText: "",
      groupName: "",
      groupNameDisabled: true,
      description: "",
      descDisabled: true,
      currentMessage: {},
      inviteReply: "",
      messagesToSend: "",
      timer: "",
      timeoutVal: 1000,
      isTypingMsg: "",
      newGroupParticipants: [],
      participantsToAdd: [],
      showDialog: false,
      emojisize: 30,
      msgBarWidth: "",
      msgBarHeight: "",
      getSet: "native",
      photoLibrary: [],
      emojiIndex,
      responseInProgress: false,
      toastIcon: "",
      isRecording: false,
      isDragging: false,
      // messageSocketClient: "",
      i18n_locale: {
        search: this.$t("emoji.search"),
        notfound: this.$t("emoji.notfound"),
        categories: {
          search: this.$t("emoji.searchResults"),
          recent: this.$t("emoji.recent"),
          smileys: this.$t("emoji.smileys"),
          people: this.$t("emoji.people"),
          nature: this.$t("emoji.nature"),
          foods: this.$t("emoji.foods"),
          activity: this.$t("emoji.activity"),
          places: this.$t("emoji.places"),
          objects: this.$t("emoji.objects"),
          symbols: this.$t("emoji.symbols"),
          flags: this.$t("emoji.flags"),
          custom: this.$t("emoji.custom"),
        },
      },
    };
  },

  mounted() {
    f7ready(() => {
      this.messagebar = f7.messagebar.get(this.$refs.messagebar.$el);

      console.log("this.msgCltConnected", this.msgCltConnected);
      if (!this.msgCltConnected) {
        this.connectMessageSocket();
      }

      // this.connectMsgdService();
      console.log(this.attachments);

      f7.panel.close(".contactDetailPanel", true);

      if (this.country === "" || !this.country) {
        this.$store.dispatch("location/getCountry");
      }
      this.countryId = this.currentUserCountry.countryId;

      this.msgBarWidth = f7.$(".messagebar .messagebar-area").width();

      f7.$("#replyToMessage").css({
        width: `${this.msgBarWidth + 16}px`,
      });
      if (f7.$("#replyToMessage")) {
        f7.$(".mainMessages").css({
          "margin-bottom": `${f7.$("#replyToMessage").height}px`,
        });
      }
      if (theme.md || f7.device.firefox || f7.device.android || f7.device.androidChrome) {
        this.getSet = "google";
      } else if (
        theme.ios ||
        theme.aurora ||
        f7.device.macos ||
        f7.device.iphone ||
        f7.device.ipad ||
        f7.device.ipod ||
        f7.device.ios
      ) {
        this.getSet = "apple" || "native";
      } else {
        this.getSet =
          "native" || "google" || "twitter" || "emojione" || "messenger" || "facebook";
      }

      this.i18n_locale = {
        search: this.$t("emoji.search"),
        notfound: this.$t("emoji.notfound"),
        categories: {
          search: this.$t("emoji.searchResults"),
          recent: this.$t("emoji.recent"),
          smileys: this.$t("emoji.smileys"),
          people: this.$t("emoji.people"),
          nature: this.$t("emoji.nature"),
          foods: this.$t("emoji.foods"),
          activity: this.$t("emoji.activity"),
          places: this.$t("emoji.places"),
          objects: this.$t("emoji.objects"),
          symbols: this.$t("emoji.symbols"),
          flags: this.$t("emoji.flags"),
          custom: this.$t("emoji.custom"),
        },
      };

      if (this.f7route.params.type != undefined && this.$keycloak.authenticated) {
        // f7.preloader.show("multi");
        this.$store.state.message.infoMessage = [];
        this.$store
          .dispatch("message/getGroupDetail", this.f7route.params.id)
          .then((result) => {
            if (this.messageList) {
              this.$store.state.message.messageList = "";
              this.$store.state.message.messagesData = [];
            }
            setTimeout(() => {
              this.$store.dispatch(
                "message/getGroupParticipantList",
                this.f7route.params.id
              );

              this.$store.dispatch("message/getUserGroupMessages", {
                requestId: this.f7route.params.id,
                displayedMsg: this.displayedMsg,
              });
              // }, 2000);

              if (
                this.f7route.params.type === "PERSONAL" &&
                this.group.groupParticipantList &&
                this.group.groupParticipantList.length > 1
              ) {
                const counterUserId = this.group.groupParticipantList.find(
                  (x) => x.userId !== this.profile.sub
                ).userId;
                // setTimeout(() => {
                this.$store.dispatch("message/getCounterPartUser", counterUserId);
              } else if (this.f7route.params.type !== "PERSONAL") {
                this.$store.state.message.counterPartUser = "";
              }
            }, 1500);
            // f7.preloader.hide();
          })
          .catch((err) => {
            // f7.preloader.hide();
            console.log(err);
          });
      }

      if (this.f7route.params.type == undefined && this.messageList.length >= 1) {
        // self.$store.dispatch("message/getCounterPartUser", this.f7route.params.id);
        this.$store.dispatch("message/getUserGroupMessages", "");
        this.$store.state.message.messageList = [];
        this.$store.state.message.messagesData = [];
      }
      const root = document.documentElement;

      if (f7.width >= 768) {
        // var height1 = f7.height / 2 + "px";
        root.style.setProperty("--f7-panel-width", "418px");
        root.style.setProperty("--f7-panel-shadow", "none");
      } else {
        root.style.setProperty("--f7-panel-width", "100%");
        f7.$(".icon-checkbox").css("display", "none");
      }
      root.style.setProperty(
        "--f7-message-name-text-color",
        `#${Math.floor(Math.random() * 16_777_215).toString(16)}`
      );

      root.style.setProperty("--f7-messagebar-attachment-height", "auto");
      if (this.replyMessage) {
      }

      if (this.counterPartUser.avatar === null) {
        f7.$(".message-avatar").css("display", "none");
      }

      /*   if (f7.device.cordova) {
        cordova.plugins.photoLibrary.getLibrary(
          function (result) {
            self.photoLibrary = result.library;
            var isLastChunk = result.isLastChunk;
          },
          function (err) {},
          {
            itemsInChunk: 100, // Loading large library takes time, so output can be chunked so that result callback will be called on
            chunkTimeSec: 0.5, // each X items, or after Y secons passes. You can start displaying photos immediately.
            useOriginalFileNames: false, // default, true will be much slower on iOS
            maxItems: 200, // limit the number of items to return
          }
        );
      } */
    });
  },
  methods: {
    // onPageBeforeIn() {
    // const self = this;
    // if (this.f7route.params.type !== "undefined") {
    //   self.$store.dispatch("message/getGroupDetail", this.f7route.params.id);
    //   self.$store.dispatch(
    //     "message/getGroupParticipantList",
    //     this.f7route.params.id
    //   );
    //   self.$store.dispatch(
    //     "message/getUserGroupMessages",
    //     this.f7route.params.id
    //   );
    // }
    // if (
    //   this.f7route.params.type === "PERSONAL" &&
    //   this.group.groupParticipantList &&
    //   this.group.groupParticipantList.length > 1
    // ) {
    //   const counterUserId = this.group.groupParticipantList.find(
    //     (x) => x.userId !== this.profile.sub
    //   ).userId;
    //   setTimeout(() => {
    //     self.$store.dispatch("message/getCounterPartUser", counterUserId);
    //   }, 2000);
    // }
    // console.log(this.messageList);
    // console.log(this.messagesData);
    // console.log(this.counterPartUser);
    // },

    toogleDialogEmoji() {
      console.log(this.showDialog);
      this.showDialog = !this.showDialog;
      if (this.showDialog == true) {
        const msgBarToolWidth = f7.$(".messagebar .toolbar-inner").width();
        f7.$(".emoji-mart").css({
          "max-width": `${msgBarToolWidth}px`,
          "min-width": `${msgBarToolWidth}px`,
          width: `${msgBarToolWidth}px`,
          "margin-right": "16px",
        });
      }
    },
    onSelectEmoji(emoji) {
      console.log(emoji.native);
      // this.messageText += this.getSet === "native" ? emoji.native : emoji;
      this.messageText += emoji.native;
    },

    scrollToMessage(messageId) {
      const msg = document.getElementById(`${messageId}`);
      if (msg) {
        msg.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },

    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      setTimeout(() => {
        if (self.messageList.length < self.displayedMsg) {
          self.showPreloader = false;
          return;
        }
        self.displayedMsg += 10;
        self.$store.dispatch("message/getUserGroupMessages", {
          requestId: self.f7route.params.id,
          displayedMsg: self.displayedMsg,
        });
        self.allowInfinite = true;
      }, 1000);
    },

    messagePhotoBrowser(message) {
      if (
        !(
          message.msgType === "image" &&
          message.url.split(",")[1].length > 10 &&
          this.messageIsSelected == false
        )
      ) {
        return;
      }
      f7.preloader.show("multi");
      // var mesgImage = new Array(message.url);
      const photoBrowser = f7.photoBrowser.create({
        photos: [message.url],
      });

      setTimeout(() => {
        if (photoBrowser) {
          photoBrowser.open();
        }
        f7.preloader.hide();
      }, 100);
    },
    attachFileOpenFab() {
      f7.fab.toggle(".attachFileForDeskTop");
    },

    isFirstMessage(dayMessages, message, index) {
      const previousMessage = dayMessages[index - 1];
      if (message.isTitle) return false;
      if (
        !previousMessage ||
        previousMessage.type !== message.type ||
        previousMessage.senderName !== message.senderName
      )
        return true;
      return false;
    },
    isLastMessage(dayMessages, message, index) {
      const nextMessage = dayMessages[index + 1];
      if (message.isTitle) return false;
      if (
        !nextMessage ||
        nextMessage.type !== message.type ||
        nextMessage.senderName !== message.senderName
      )
        return true;
      return false;
    },
    isTailMessage(dayMessages, message, index) {
      const nextMessage = dayMessages[index + 1];
      if (message.isTitle) return false;
      if (
        !nextMessage ||
        nextMessage.type !== message.type ||
        nextMessage.senderName !== message.senderName
      )
        return true;
      return false;
    },
    deleteAttachment(image) {
      const index = this.attachments.indexOf(image);
      this.attachments.splice(index, 1)[0]; // eslint-disable-line
      this.$store.dispatch("auth/setDocumentsUrl", this.attachments);
      // self.attachments = self.documentsUrl;
      const index0 = this.imGattachments.indexOf(image);
      this.imGattachments.splice(index0, 1)[0]; // eslint-disable-line
    },
    handleAttachment(e) {
      const index = $(e.target).parents("label.checkbox").index();
      const image = this.photoLibrary[index];
      if (e.target.checked) {
        // Add to attachments
        this.imGattachments.unshift(image);

        /*  cordova.plugins.photoLibrary.getPhoto(
          image, // or libraryItem.id
          function (blob) {
            self.setAttachment({ blob });
          },
          function (err) {
            console.log("Error occured");
          }
        ); */
        console.log(this.imGattachments);
      } else {
        // Remove from attachments
        this.imGattachments.splice(this.imGattachments.indexOf(image), 1);
        this.deleteAttachment(image);
      }
    },
    startTyping() {
      const type = this.f7route.params.type;
      this.clearSelection();
      if (this.responseInProgress) return;
      this.responseInProgress = true;
      clearTimeout(this.timer);
      this.isTypingMsg =
        type !== "PERSONAL" || type !== "undefined"
          ? {
              receiverId: this.group.id,
              groupType: this.group.groupType || type,
              senderId: this.chatUser.userId,
              groupId: this.group.id,
              name:
                this.chatUser.name.split(" ")[0] ||
                this.chatUser.username ||
                this.chatUser.phoneNumber,
              typing: true,
            }
          : {
              receiverId: this.counterPartUser.userId,
              groupType: "PERSONAL",
              name:
                this.chatUser.name.split(" ")[0] ||
                this.chatUser.username ||
                this.chatUser.phoneNumber,
              senderId: this.chatUser.userId,
              groupId: this.group.id,
              typing: true,
            };
      console.log(this.messageSocketClient);
      if (!this.msgCltConnected) {
        this.connectMessageSocket();
      }
      // setTimeout(() => {
      this.messageSocketClient.send(
        "/app/message.typing",
        JSON.stringify(this.isTypingMsg)
      );
      // }, 500);
      console.log(JSON.stringify(this.isTypingMsg));
      // console.log(this.typingMessage);
    },
    stopTyping() {
      const type = this.f7route.params.type;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isTypingMsg =
          type !== "PERSONAL" && type !== "undefined"
            ? {
                receiverId: this.group.id,
                groupType: this.group.groupType || type,
                groupId: this.group.id,
                name:
                  this.chatUser.name.split(" ")[0] ||
                  this.chatUser.username ||
                  this.chatUser.phoneNumber,
                senderId: this.chatUser.userId,
                typing: false,
              }
            : {
                receiverId: this.counterPartUser.userId,
                groupType: "PERSONAL",
                name:
                  this.chatUser.name.split(" ")[0] ||
                  this.chatUser.username ||
                  this.chatUser.phoneNumber,
                senderId: this.chatUser.userId,
                groupId: this.group.id,
                typing: false,
              };
        setTimeout(() => {
          this.messageSocketClient.send(
            "/app/message.typing",
            JSON.stringify(this.isTypingMsg)
          );
        }, 500);
        console.log(JSON.stringify(this.isTypingMsg));

        this.responseInProgress = false;
      }, this.timeoutVal);
    },
    sendMessage() {
      const self = this;
      const type = this.f7route.params.type;
      const text = self.messageText.replace(/\n/g, "<br>").trim();
      const dt = new Date();
      // const messagesToSend = {};
      // self.attachments.forEach((attachment) => {
      //   messagesToSend.push({
      //     image: attachment,
      //   });
      // });
      if (text.length || this.attachments.length > 0) {
        this.messagesToSend = {
          text,
          senderId: this.chatUser.userId,
          senderName:
            this.chatUser.name.split(" ")[0] ||
            this.chatUser.username ||
            this.chatUser.phoneNumber,
          sentAt: dt.toISOString(),
          readAt: "",
          attachment: "",
          replyingTo: this.replyMessage.id || "",
        };
      }
      if (this.messagesToSend.length === 0 && this.attachments.length > 0) {
        return;
      }
      // Reset attachments
      // self.attachments = [];
      // Hide sheet
      self.sheetVisible = false;
      self.showDialog = false;
      // Clear area
      self.messageText = "";
      self.messageText.replace(/\n/g, "<br>").trim();
      // Focus area
      if (text.length) f7.messagebar.focus();

      console.log(this.group);

      // Send message
      // if (this.infoMessage && this.infoMessage.length > 0) {
      //   self.$store.state.message.infoMessage.push(...messagesToSend);
      // }

      // self.messagesData.push(...messagesToSend);

      // Mock response
      // if (self.responseInProgress) return;
      // self.responseInProgress = true;
      // setTimeout(() => {
      //   const answer = self.answers[Math.floor(Math.random() * self.answers.length)];
      //   const person = self.people[Math.floor(Math.random() * self.people.length)];
      //   self.typingMessage = {
      //     name: self.profile.name,
      //     avatar: person.avatar,
      //   };
      //   setTimeout(() => {
      //     self.messagesData.push({
      //       text: answer,
      //       type: "received",
      //       name: person.name,
      //       avatar: person.avatar,
      //     });
      //     self.typingMessage = null;
      //     self.responseInProgress = false;
      //   }, 4000);
      // }, 1000);
      if (
        this.group.groupType === "GROUP" ||
        this.group.groupType === "SUBGROUP" ||
        type === "GROUP" ||
        type === "SUBGROUP"
      ) {
        const messagesToSend0 = {
          receiverId: this.group.id,
          receiverName: this.group.name,
          groupType: this.group.groupType || type,
          type: "sent",
          status: "sent",
        };
        f7.utils.extend(this.messagesToSend, messagesToSend0);
        this.sendChatMessage(this.messagesToSend);
      } else if (this.group.groupType === "PERSONAL" || type === "PERSONAL") {
        const messagesToSend1 = {
          receiverId: this.counterPartUser.userId,
          receiverName: this.counterPartUser.name,
          groupType: this.group.groupType || "PERSONAL",
          type: "sent",
          status: "sent",
          chatGroup: { id: this.group.id },
        };
        f7.utils.extend(this.messagesToSend, messagesToSend1);
        console.log(this.messagesToSend);
        this.sendChatMessage(this.messagesToSend);
      } else if (this.infoMessage[0].status === "invite") {
        const messagesToSend2 = {
          receiverId: this.counterPartUser.userId,
          receiverName: this.counterPartUser.name,
          groupType: "PERSONAL",
          type: "sent",
          status: "replyInvite",
          msgOrigin: "invitation",
          chatGroup: { id: this.counterPartUser.userId },
        };
        f7.utils.extend(this.messagesToSend, messagesToSend2);
        this.sendChatMessage(this.messagesToSend);
      } else if (this.infoMessage[0].status === "replyInvite") {
        this.inviteReply = e.target.dataset["name"];
        console.log(e.target.dataset["name"]);
        if (this.replyInvite === "inviteYes") {
          messagesToSend3 = {
            receiverId: this.counterPartUser.userId,
            receiverName: this.counterPartUser.name,
            groupType: "PERSONAL",
            type: "sent",
            status: "inviteYes",
            msgOrigin: "invitation",
            chatGroup: { id: this.group.id },
          };
          f7.utils.extend(this.messagesToSend, messagesToSend3);
          this.sendChatMessage(this.messagesToSend);
        } else if (this.replyInvite === "inviteNo") {
          messagesToSend4 = {
            receiverId: this.counterPartUser.userId,
            receiverName: this.counterPartUser.name,
            groupType: "PERSONAL",
            type: "sent",
            status: "inviteNo",
            msgOrigin: "invitation",
            chatGroup: { id: this.group.id },
          };
          f7.utils.extend(this.messagesToSend, messagesToSend4);
          this.sendChatMessage(this.messagesToSend);
        }
      }
      this.messagesToSend = "";
      f7.messagebar.clear();
    },

    /*   sendInvite() {
      const self = this;

      console.log(this.messagesToSend);
      const msg = {
        receiverId: this.counterPartUser.userId,
        receiverName: this.counterPartUser.name,
        groupType: "PERSONAL",
        type: "sent",
        status: "replyInvite",
        // chatUser: { id: this.chatUser.id },
      };
      f7.utils.extend(this.messagesToSend, msg);
      self.$store.state.message.infoMessage.push(this.messagesToSend);
      this.sendChatMessage();
    },
    acceptInvite() {
      const self = this;

      const msg = {
        receiverId: this.counterPartUser.userId,
        receiverName: this.counterPartUser.name,
        groupType: "PERSONAL",
        type: "sent",
        status: "inviteYes",
        // chatUser: { id: this.chatUser.id },
      };
      f7.utils.extend(this.messagesToSend, msg);
      this.sendChatMessage();
    },
    rejectInvite() {
      const self = this;
      this.messagesToSend.push({
        receiverId: this.counterPartUser.userId,
        receiverName: this.counterPartUser.name,
        groupType: "PERSONAL",
        type: "sent",
        status: "inviteNo",
        chatUser: { id: this.chatUser.id },
      });
      this.sendChatMessage();
    },
    sendRegularMessage() {
      const self = this;
     messagesToSend.push({
        receiverId: this.currentGroup.userId,
        receiverName: this.currentGroup.name,
        groupType: this.currentGroup.type,
        type: "sent",
        status: "sent",
        chatUser: { id: this.chatUser.id },
      });
      if (this.currentGroup.type === "SUBGROUP") {
        this.messagesToSend.push({
          chatGroup: {
            id: "",
          },
          subGroup: {
            id: this.currentGroup.id,
          },
        });
      } else {
        this.messagesToSend.push({
          chatGroup: {
            id: this.currentGroup.id,
          },
          subGroup: {
            id: "",
          },
        });
      }

      this.sendChatMessage();
    }, */

    beforeOut() {
      const self = this;
      f7.$(".mainToolbar").css("display", "block");
    },

    viewDocs(item) {
      const self = this;
      this.docToView = item;
      f7.popup.open(".viewDocs");
    },
  },

  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "infoMessage",
      "messagesData",
      "groupUnReadMsg",
      "allGroupsUnReadMsg",
      "replyMessage",
      "messageIsSelected",
      "selectedMessages",
      "msgCltConnected",
      "messageSocketClient",
      "typingMessage",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),

    ...mapState("account", ["userAccount", "searchedUser", "users"]),
    ...mapState("auth", [
      "profile",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
      "documentsUrl",
    ]),

    /*  user() {
      const self = this;

      const id = this.f7route.params.id;
      for (var i = 0; i < this.users.length; i += 1) {
        if (this.users[i].userId == id) {
          return this.users[i];
        }
      }
    }, */
    attachments() {
      const self = this;
      return this.documentsUrl;
    },
    currentGroup() {
      const self = this;

      return this.group;
      // const id = this.f7route.params.id;
      // const type = this.f7route.params.type;
      // if (type !== "SUBGROUP") {
      //   for (var i = 0; i < this.groupList.length; i += 1) {
      //     if (this.groupList[i].id == id && this.groupList[i].type == type) {
      //       if (type !== "PERSONAL") {
      //         return this.groupList[i];
      //       } else {
      //         return this.groupList[i].participantList.find(
      //           (x) => x.userId !== this.profile.sub
      //         );
      //       }
      //     }
      //   }
      // } else if (type === "SUBGROUP") {
      //   // const groupsWithSubGroup = this.groupList.find((x) => x.type == "GROUP");
      //   return _.filter(this.groupList, {
      //     subGroupList: [{ id: id, type: "SUBGROUP" }],
      //   });
      // }
    },
    chatGroupParticipantList() {
      const type = this.f7route.params.type;
      if (
        type === "GROUP" &&
        this.group.groupParticipantList &&
        this.group.groupParticipantList.length > 0
      ) {
        return this.group.groupParticipantList;
      } else if (
        type === "SUBGROUP" &&
        this.group.subGroupParticipantList &&
        this.group.subGroupParticipantList.length > 0
      ) {
        return this.group.subGroupParticipantList;
      } else if (
        this.group.groupParticipantList &&
        this.group.groupParticipantList.length > 0 &&
        type === "PERSONAL"
      ) {
        return this.group.groupParticipantList.find((x) => x.userId !== this.profile.sub);
      }
    },
    chatGroupUserList() {
      const type = this.f7route.params.type;
      if (
        type !== "PERSONAL" &&
        this.chatGroupParticipantList &&
        this.chatGroupParticipantList.length > 0
      ) {
        return this.chatGroupParticipantList
          .map((a) => a.name.split(" ")[0] || a.username || a.tag)
          .join(", ");
      } else if (
        type === "PERSONAL" &&
        this.group.groupParticipantList &&
        this.group.groupParticipantList.length > 0
      ) {
        return this.group.groupParticipantList.find((x) => x.userId !== this.profile.sub);
      }
    },

    chatGroupAdminList() {
      const type = this.f7route.params.type;
      if (
        type === "GROUP" &&
        this.group.groupAdminList &&
        this.group.groupAdminList.length > 0
      ) {
        return this.group.groupAdminList;
      } else if (
        type === "SUBGROUP" &&
        this.group.subGroupAdminList &&
        this.group.subGroupAdminList.length > 0
      ) {
        return this.group.subGroupAdminList;
      }
    },
    isGroupAdmin() {
      if (this.chatGroupAdminList && this.chatGroupAdminList.length > 0) {
        return _.find(this.chatGroupAdminList, ["userId", this.profile.sub]);
      }
    },
    commonGroups() {
      return _.intersectionBy(
        this.counterPartUser.userGroupList,
        this.chatUser.userGroupList,
        "id"
      );
    },
    // messagesData() {
    //   const self = this;
    //   return this.messageList[0];
    // },

    attachmentsVisible() {
      // if (this.isTablet == false) {
      //   const msgBarToolWidth = f7.$(".messagebar .toolbar-inner").width();
      //   f7.$(".messagebar-attachments").css({
      //     "max-width": msgBarToolWidth + "px",
      //     "min-width": msgBarToolWidth + "px",
      //     width: msgBarToolWidth + "px",
      //     height: "auto",
      //   });
      // }
      return this.attachments.length > 0 || this.imGattachments.length > 0;
    },
    placeholder() {
      if (!this.infoMessage || (this.infoMessage && this.infoMessage.length == 0)) {
        return this.attachments.length > 0 || this.imGattachments.length > 0
          ? this.$t("tamtammessage.addCommentOrSend")
          : this.$t("tamtammessage.typeamessage");
      } else {
        return this.infoMessage[0].status === "invite"
          ? this.$t("tamtammessage.writeaMessageToInvite", {
              myreceiver: this.infoMessage[0].receiverName,
            })
          : this.$t("tamtammessage.writeaMessageToReplyToInvite", {
              myreceiver: this.infoMessage[0].receiverName,
            });
      }
    },
  },
};
</script>
