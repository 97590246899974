<template>
  <f7-page :page-content="false" hide-bars-on-scroll>
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{
        $t("businessaccountactivate.updateBusinessAccountDetails")
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-tabs id="validateBusinnessAcct">
          <f7-tab id="addCompanyDetails" class="page-content" tab-active>
            <f7-block-title class="margin-left">{{
              $t("businessaccountactivate.updateBusinessAccountDetails")
            }}</f7-block-title>
            <f7-list dividers-ios strong-ios form-store-data>
              <f7-list-group>
                <f7-list-item :header="$t('common.accountNumber')" :title="this.businessAccount.accountNumber" :after="$filtersToCurrency.toCurrency(
                  this.businessAccount.accountBalance,
                  this.businessAccount.currencySymbol,
                  this.businessAccount.currencyCode
                )
                  "></f7-list-item>
                <f7-list-item header="$t('businessaccountactivate.companyLegalName')"
                  :title="this.businessAccount.companyName"></f7-list-item>
                <f7-list-item :header="$t('businessaccountactivate.companyNumber')"
                  :title="this.businessAccount.companyNumber"></f7-list-item>
                <f7-list-input :label="$t('businessaccountactivate.companyActivitydescription')" floating-label
                  type="textarea" resizable name="descriptionOfBusiness"
                  :placeholder="$t('businessaccountactivate.companyActivity')"
                  :value="this.businessAccount.descriptionOfBusiness" @input="descriptionOfBusiness = $event.target.value"
                  :error-message="$t('businessaccountactivate.enterCompanyActivity')" required validate>
                </f7-list-input>
                <!-- <f7-list-input :label="$t('businessaccountactivate.myRoleIn')" floating-label type="text"
                  name="legalRepresentativeRole" :placeholder="$t('businessaccountactivate.myRoleIn')"
                  :value="this.businessAccount.legalRepresentativeRole"
                  @input="legalRepresentativeRole = $event.target.value"
                  :error-message="$t('common.enterMyRoleIn')" required validate clear-button>
                </f7-list-input> -->
              </f7-list-group>
            </f7-list>
            <f7-button raised fill ripple large color="red" class="margin-top margin-horizontal buttons"
              @click="saveCompanyDetails()">{{ $t("businessaccountactivate.saveCompanyDetails") }}</f7-button>
          </f7-tab>
          <f7-tab id="addContact" class="page-content">
            <f7-block-title class="margin-left">{{ $t("common.update") }}</f7-block-title>
            <f7-list>
              <f7-list-group>
                <span class="cols25_75">
                  <li class="item-content item-input">
                    <f7-link class="item-media">
                      <img class="coutryMap no-margin-bottom no-padding-bottom" :src="'https://flagcdn.com/' +
                        this.businessAccount.countryCode.toLowerCase() +
                        '.svg'
                        " width="24" />
                    </f7-link>
                    <div class="item-inner no-padding-right no-chevron">
                      <div class="item-title item-label no-padding-right no-chevron">
                        {{ $t("businessaccountactivate.phoneCode") }}
                      </div>

                      <div class="item-input-wrap input-dropdown-wrap no-chevron">
                        <select readonly disabled>
                          <option :value="this.businessAccount.phoneCode">
                            +{{ this.businessAccount.phoneCode }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </li>

                  <li class="item-content item-input">
                    <div class="item-inner">
                      <div class="item-title item-floating-label">
                        {{ $t("businessaccountactivate.companyPhoneNumber") }}
                      </div>
                      <div class="item-input-wrap">
                        <input type="tel" required validate :value="this.businessAccount.businessPhoneNumber"
                          @input="businessPhoneNumber = $event.target.value" :data-error-message="$t('businessaccountactivate.enterCompanyPhoneNumber')
                            " />
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </li>
                </span>
                <f7-list-input :label="$t('businessaccountactivate.companyEmail')" floating-label type="text"
                  name="businessEmail" :placeholder="$t('businessaccountactivate.myCompanyEmail')"
                  :value="this.businessAccount.businessEmail" @input="businessEmail = $event.target.value"
                  :error-message="$t('businessaccountactivate.enterMyCompanyEmail')" required validate clear-button>
                </f7-list-input>

                <f7-list-input :label="$t('businessaccountactivate.street')" floating-label type="text"
                  name="street_address" :placeholder="$t('businessaccountactivate.companyHouseNumber')"
                  :value="this.businessAccount.businessAddress.street_address"
                  @input="street_address = $event.target.value" :error-message="$t('common.registrationNumber')" required
                  validate clear-button></f7-list-input>
                <span class="cols25_75">
                  <f7-list-input :label="$t('common.postCode')" floating-label type="text" name="postal_code"
                    :placeholder="$t('common.postCode')" :value="this.businessAccount.businessAddress.postal_code"
                    @input="postal_code = $event.target.value" :error-message="$t('common.enterPostCode')" required
                    validate clear-button>
                  </f7-list-input>

                  <f7-list-input :label="$t('common.citylocality')" floating-label type="text" name="locality"
                    :placeholder="$t('common.citylocality')" :value="this.businessAccount.businessAddress.locality"
                    @input="locality = $event.target.value" error-message="$t('common.enterCityOrLocality')" required
                    validate clear-button>
                  </f7-list-input>
                </span>
              </f7-list-group>
            </f7-list>
            <f7-button raised fill ripple large color="red" class="margin-top margin-horizontal buttons"
              @click="saveContactDetails()">{{ $t("businessaccountactivate.saveCompanyContact") }}</f7-button>
          </f7-tab>
          <f7-tab id="uploadDocs" class="page-content">
            <f7-block-title class="margin-left margin-bottom">{{
              $t("common.update")
            }}</f7-block-title>
            <f7-list>
              <f7-list-group>
                <form enctype="multipart/form-data" novalidate>
                  <f7-list-input type="file" class="documents" capture="environment" style="display: none"
                    accept=".jpg, .jpeg, .png, .pdf, image/*, .doc, .docx, .xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    :value="documents" @input="documents = $event.target.value" multiple
                    @change="onFileChange($event)"></f7-list-input>
                </form>

                <f7-link class="margin-left margin-vertical" @click="uploadedFile()" icon-size="50"
                  icon-ios="f7:photo_on_rectangle" icon-aurora="f7:photo_on_rectangle" icon-md="material:add_to_photos"
                  :text="$t('businessaccountactivate.addCompanyDocuments')"></f7-link>
                <span class="cols90_10" v-for="(docItem, index) in this.documentsUrl ||
                  this.businessAccount.accountImages" :key="index">
                  <f7-link :text="docItem.name" href="#" popup-open=".viewDocs" class="margin-left">
                  </f7-link>
                  <f7-link raised color="red" icon-f7="multiply_circle_fill" @click="removeDoc(index)"></f7-link>
                </span>
              </f7-list-group>
            </f7-list>
            <f7-button raised fill ripple large color="red" class="margin-top margin-horizontal buttons"
              @click="saveCompanyInfo()">
              {{ $t("businessaccountactivate.submitCompanyDetails") }}</f7-button>
          </f7-tab>
        </f7-tabs>
      </div>
      <div class="right_layout"></div>
    </div>
    <f7-popup class="viewDocs" swipe-to-close :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }">
      <f7-page>
        <f7-navbar no-shadow no-hairline>
          <f7-nav-right>
            <f7-link popup-close icon-f7="xmark_circle"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block v-if="this.documentsUrl && this.documentsUrl.length > 0" style="height: 100%">
              <iframe v-for="(docItem, index) in this.documentsUrl" :src="docItem.url" :key="index" :title="docItem.name"
                style="width: 100%; height: 500px" frameborder="0"></iframe>
            </f7-block>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
.input-dropdown-wrap:before,
.input-dropdown:before {
  content: "";
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Tabs,
  f7Tab,
  f7BlockTitle,
  f7List,
  f7ListGroup,
  f7ListInput,
  f7Button,
  f7Popup,
  f7PageContent,
  f7Block,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Tabs,
    f7Tab,
    f7BlockTitle,
    f7List,
    f7ListGroup,
    f7ListInput,
    f7Button,
    attachDoc,
    myDocs,
    f7Popup,
    f7PageContent,
    f7Block,
  },
  mixins: [attachDocs],
  data() {
    return {
      theme,
      f7,
      companyName: "",
      companyNumber: "",
      businessEmail: "",
      businessPhoneNumber: "",
      legalRepresentativeRole: "",
      descriptionOfBusiness: "",
      phoneCode: "",
      countryCode: "",
      street_address: "",
      locality: "",
      postal_code: "",
      documents: "",
      documentsUrl: [],
      docIndex: "",
      files: "",
      forms: "",
      destinationCountryCode: "",
      countryCode: "",
      addressType: this.$t("businessaccountactivate.newAddress"),
      newAddress: true,
      newBusinessAccount: "",
    };
  },

  mounted() {
    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      (this.primaryAccount === "" || this.businessAccount === "") &&
        this.$keycloak.authenticated;
    }
    this.paymentCurrency = this.profile.currencyCode;

    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    this.paymentCurrency = this.profile.currencyCode;

    if (this.isTablet == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
  },
  methods: {
    addressTypeChange() {
      var toggle = f7.toggle.get(".addressType");
      if (toggle.checked) {
        this.addressType = this.$t("businessaccountactivate.newAddress");
        this.newAddress = true;
        this.street_address = "";
        this.postal_code = "";
        this.locality = "";
        return;
      }
      this.addressType = this.$t("businessaccountactivate.sameAsPersoAddres");
      this.newAddress = false;
      this.street_address = this.profile.address.street_address;
      this.postal_code = this.profile.address.postal_code;
      this.locality = this.profile.address.locality;
    },
    saveCompanyDetails() {
      if (f7.input.validateInputs("#addCompanyDetails")) {
        f7.tab.show("#addContact", true);
      } else {
        const valid = f7.$("#addCompanyDetails .input-invalid").length === 0;
      }
    },

    saveContactDetails() {
      if (f7.input.validateInputs("#addContact")) {
        f7.tab.show("#uploadDocs", true);
      } else {
        const valid = f7.$("#addContact .input-invalid").length === 0;
      }
    },

    async saveCompanyInfo() {
      if (f7.input.validateInputs("#validateBusinnessAcct")) {
        this.newBusinessAccount = await {
          id: this.businessAccount.id,
          companyName: this.newBusinessAccount.companyName,
          companyNumber: this.newBusinessAccount.companyNumber,
          phoneCode: this.newBusinessAccount.phoneCode,
          businessPhoneNumber: this.businessPhoneNumber,
          legalRepresentativeRole: this.legalRepresentativeRole,
          descriptionOfBusiness: this.descriptionOfBusiness,
          businessEmail: this.businessEmail,
          businessAddress: {
            street_address: this.street_address,
            locality: this.locality,
            postal_code: this.postal_code,
            region: "",
            country: this.newBusinessAccount.businessAddress.country,
          },
        };
        console.log(this.newBusinessAccount);
        this.forms = new FormData();
        await this.forms.append(
          "businessAccount",
          JSON.stringify(this.newBusinessAccount)
        );

        for (content of this.documentsUrl) {
          await this.forms.append("files", content.file);
        }
        console.log(this.forms);
        await this.$store
          .dispatch("account/activateBusinessAccount", this.forms)
          .then((result) => {
            this.$store.dispatch("auth/clearDocumentsUrl");
            this.f7router.navigate("/user/profile");
          })
          .catch((err) => { });
      } else {
      }
    },
    async selectUserCountryCode() {
      if (this.country === "" || !this.country) {
        this.$store.dispatch("location/getCountry");
      }
      f7.popup.open(".selectUserCountryCode");
      console.log(this.countryCode);
    },
    async selectDestinationCountry(destination) {
      this.countryCode = await destination.countryCode2;
      console.log(this.countryCode);
      f7.popup.close(".selectUserCountryCode");
    },
  },

  computed: {
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
    campaignDays() {
      if (this.campaignPeriod.length > 1) {
        return (
          (this.campaignPeriod[1].getTime() - this.campaignPeriod[0].getTime()) /
          (1000 * 3600 * 24) +
          " days"
        );
      }
    },
    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
    beneficiaryWallet() {
      if (this.beneficiaryWalletId !== " ") {
        return _.find(this.publicWalletList, ["id", this.beneficiaryWalletId]);
      }
    },
  },
};
</script>
