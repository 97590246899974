<template>
  <f7-page @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove" hide-bars-on-scroll>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-if="this.pickupMethod === 'pick-up'">{{
        $t("sendcash.transferTo", { myrecipient: this.recipient.name })
      }}</f7-nav-title>
      <f7-nav-title sliding v-else>{{
        $t("sendcash.topup", { myrecipient: this.recipient.name })
      }}</f7-nav-title>

      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios media-list form>
          <f7-list-group>
            <f7-row no-gap v-if="this.pickupMethod === 'top-up' && this.recipient.userRecipientId !== null
                ">
              <f7-col width="50" medium="50">
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title" style="font-weight: bold">
                        {{ this.recipient.firstName }}
                        {{ this.recipient.lastName }}
                      </div>
                      <!-- <div class="item-title" style="font-weight:bold">{{this.recipient.userrecipient.primaryAccount.accountNumber}}</div> -->
                    </div>
                    <div class="item-title-row" style="font-size: 75%; color: gray">
                      <div class="item-title">
                        {{ this.recipient.cityName }}
                        {{ this.recipient.countryName }}
                      </div>
                      <!-- <div class="item-title">{{this.recipient.cityName}}</div> -->
                    </div>
                  </div>
                </li>
              </f7-col>
              <f7-col width="50" medium="50 " v-if="this.userDetail">
                <f7-list-item class="recipientAccountNumber item-label" smart-select :smart-select-params="{
                  closeOnSelect: true,
                  openIn: this.isTablet == true ? 'popover' : 'sheet',
                  sheetPush: true,
                  sheetSwipeToClose: true,
                }" :title="$t('sendcash.selectRecipientAccount')">
                  <select type="select" name="account-type" v-model="recipientAccountNumber"
                    @change="selectRecipientAccount()">
                    <option :value="this.userDetail.primaryAccount.accountNumber" selected>
                      {{ this.userDetail.primaryAccount.accountType }}:
                      {{ this.userDetail.primaryAccount.accountNumber }}
                    </option>
                    <option v-if="this.userDetail.businessAccount.active === true"
                      :value="this.userDetail.businessAccount.accountNumber">
                      {{ this.userDetail.businessAccount.accountType }}:
                      {{ this.userDetail.businessAccount.accountNumber }}
                    </option>
                  </select>
                </f7-list-item>
              </f7-col>
            </f7-row>
            <li v-else class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.recipient.name }}
                  </div>
                  <div v-if="this.recipient.userRecipientId !== null &&
                    this.pickupMethod === 'top-up'
                    " class="item-title" style="font-weight: bold">
                    {{ this.recipientAccountNumber }}
                  </div>
                  <div v-else class="item-title" style="font-weight: bold">
                    +{{ recipient.phoneCode }}{{ recipient.phone }}
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 75%; color: gray">
                  <div class="item-title">{{ this.recipient.countryName }}</div>
                  <div class="item-title">{{ this.recipient.cityName }}</div>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.destinationCountry") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("sendcash.pickupMode") }}
                  </div>
                </div>
                <div class="item-title-row" v-if="this.recipient">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.recipient.countryName }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    <span class="avocash">avo¢ash</span>
                    {{ this.pickupMethod }}
                  </div>
                </div>
              </div>
            </li>
          </f7-list-group>
        </f7-list>
        <addnewcards @getCardBAsketAmount="retrieveCardBasketAmount"></addnewcards>
        <f7-list dividers-ios strong-ios media-list form>
          <f7-list-group>
            <li class="item-content item-input" v-if="carditemlist && carditemlist.length > 0">
              <div class="item-media" v-if="this.recipientCountry">
                {{
                  this.recipientCountry.toCurrencyCode ||
                  this.transferDetail.toCurrencyCode
                }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("sendcash.recipientsGets") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" name="receivedAmount" pattern="[0-9]*" required validate data-error-message=""
                    min="0" :value="parseFloat(
                      this.transferDetail.receivedAmount || this.receivedAmount
                    )
                      " @input="receivedAmount = $event.target.value" @change="calculateAmountToSend()"
                    @blur="calculateAmountToSend()" @keydown="calculateAmountToSend()" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
            <li class="item-content" v-if="transferDetail.receivedAmount != null &&
              carditemlist &&
              carditemlist.length > 0
              ">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.fee") }}</div>
                  <div class="item-title">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.transferFeeAmount,
                      transferDetail.fromCurrencySymbol,
                      transferDetail.fromCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">
                    {{ $t("sendcash.xrate") }}
                  </div>
                  <div class="item-title float-left">
                    1{{ transferDetail.fromCurrencyCode }}=
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.netExchangeRate,
                      transferDetail.toCurrencySymbol,
                      transferDetail.toCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
          </f7-list-group>
        </f7-list>
        <f7-toolbar tabbar bottom bg-color="red" v-if="this.pickupMethod && this.carditemlist">
          <f7-link @click="goToCheckTransfer()" v-if="this.pickupMethod === 'top-up'" raised large fill
            class="link buttons" strong text-color="white">{{ $t("sendcash.topupNow") }}</f7-link>
          <!-- Pick-up from balance -->
          <f7-link @click="goToCheckTransfer()" v-if="this.pickupMethod === 'pick-up'" raised large fill
            class="link buttons" strong text-color="white">{{ $t("sendcash.transferNow") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Block,
  f7Toolbar,
  f7Link,
  theme,
  f7,
} from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import AddNewCards from "../components/addnewcards.vue";
import { mapState, mapGetters } from "vuex";
import { scanCard, checkCard } from "../js/mixins/card.js";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { selectedRecipient, recipient } from "../js/mixins/recipient.js";
import { calculateTransferPayment } from "../js/mixins/transaction.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    pickupmode: Object,
    recipientId: Object,
  },
  mixins: [
    scanCard,
    checkCard,
    selectedRecipient,
    recipient,
    scanQRCodeGeneral,
    calculateTransferPayment,
  ],
  components: {
    navbarright: Navbarright,
    addnewcards: AddNewCards,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7List,
    f7ListGroup,
    f7ListItem,

    f7Block,
    f7Toolbar,
    f7Link,
  },
  data() {
    return {
      theme,
      f7,
      pickupMethod: "",
      currRecipientId: "",
      cardNumber: "",
      cardPIN: "",
      pickupplace: "",
      pickupcity: "",
      transferRateId: "",
      recipientAccountNumber: "",
      tocurrencycode: "",
      grossXrate: "",
      recipientAcctNumber: "",
      recipientAccountType: "",
      userRecipient: "",
      receivedAmount: "",
      amountToReceive: "",
      amountToSend: "",
      nocardNumberOrPINError: "",
    };
  },

  mounted() {
    const self = this;
    if (this.f7route) {
      this.pickupMethod = this.f7route.params.pickupmode;
      this.currRecipientId = this.f7route.params.id;
    } else {
      this.pickupMethod = this.pickupmode;
      this.currRecipientId = this.recipientId;
    }
    self.$store.dispatch("remit/getRecipientById", this.currRecipientId);
    if (this.recipient !== "" && this.recipient.userRecipientId !== null) {
      self.$store.dispatch("account/loadUser", {
        userId: this.recipient.userRecipientId,
        type: "userId",
      });
    }
    self.$store.dispatch("card/loadCarditemlist");
    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }
    if (this.recipientCountry) {
      self.$store.dispatch("transaction/getCurrencyRate", {
        fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
        toCurrencyCode: this.recipientCountry.toCurrencyCode,
      });
    }
    this.amountToSend = parseFloat(this.totalCardbasket);
    if (this.extraTransferDetail.recipientAccountNumber !== null) {
      this.recipientAccountType = this.extraTransferDetail.recipientAccountType;
      this.recipientAccountNumber = this.extraTransferDetail.recipientAccountNumber;
    } else {
      this.recipientAccountType = this.userDetail.primaryAccount.accountType;
      this.recipientAccountNumber = this.userDetail.primaryAccount.accountNumber;
    }
    this.senderAccountNumber = this.primaryAccount.accountNumber;
    this.senderAccountType = this.primaryAccount.accountType;

    f7.$(".recipientAccountNumber .item-after").html(
      this.recipientAccountType + ": " + this.recipientAccountNumber
    );
  },
  methods: {
    async onPageInit() {
      const self = this;

      await self.$store.dispatch(
        "location/loadDestinationCity",
        this.recipient.countryCode2
      );
      await self.$store.dispatch("account/loadUser", {
        userId: this.recipient.userRecipientId,
        type: "userId",
      });
      await self.$store.dispatch("recipient/getRecipientById", this.currRecipientId);
      await self.$store.dispatch("transaction/getCurrencyRate", {
        fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
        toCurrencyCode: this.recipientCountry.toCurrencyCode,
      });
      this.amountToSend = await parseFloat(this.totalCardbasket);
    },

    async retrieveCardBasketAmount(cardBasketTotalAmount) {
      const self = this;

      this.amountToSend = parseFloat(cardBasketTotalAmount);
      if (this.amountToSend > 0) {
        await this.calculateTransfer();
      }
    },

    onPageBeforeRemove() {
      const self = this;
    },

    choosePickupCity() {
      const self = this;

      self.$store.dispatch("location/loadDestinationCity", this.recipient.countryCode2);
    },

    selectRecipientAccount() {
      const self = this;

      if (this.recipientAccountNumber == this.userDetail.primaryAccount.accountNumber) {
        this.recipientAccountType = this.userDetail.primaryAccount.accountType;
      } else {
        this.recipientAccountType = this.userDetail.businessAccount.accountType;
      }
      console.log(this.recipientAccountType);
    },
  },

  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "allrecipients",
      "userrecipientList",
      "recipient",
    ]),

    ...mapState("account", ["userDetail", "userAccount"]),
    ...mapState("card", ["carditemlist", "queryerror"]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "grossexchangerate",
      "transferDetail",
      "extraTransferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
    ]),

    ...mapGetters("transaction", ["grossexchangerate2"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },

    destinationCty() {
      return _(this.destinationCity).groupBy("countryId").pick([this.destinationctry]);
    },
    recipientCountry() {
      if (this.recipient) {
        return _.find(this.destinationCountry, ["toCountryId", this.recipient.countryId]);
      }
    },
    // totalCardbasket: function() {
    //   return this.carditemlist.reduce((amount, carditem) => {
    //     return amount + parseFloat(carditem.cardAmount || 0);
    //   }, 0);
    // }
  },
};
</script>
