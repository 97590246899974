<template>
  <f7-panel
    class="addParticipantGroupRightPanel"
    right
    cover
    resizable
    @panel:opened="reducePageWidth"
    @panel:close="resetPageWidth"
    @panel:open="addCurrentParticipants"
    :backdrop="false"
    :style="{
      'min-width': f7.width >= 768 ? '418px' : '100%',
      'min-width': f7.width >= 768 ? '418px' : '100%',
      'border-left':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
    }"
  >
    <f7-page page-with-subnavbar>
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link panel-close>
            <f7-icon ios="f7:multiply" aurora="f7:multiply" md="material:close"></f7-icon>
          </f7-link>
        </f7-nav-left>

        <div class="title">
          {{ $t("tamtammessage.addANewParticipant") }}
        </div>
        <f7-subnavbar :inner="false" no-shadow class="no-shadow">
          <f7-searchbar
            search-container=".search-UserList"
            search-in=".item-title, item-after, item-footer"
            :disable-button="!theme.aurora"
            :backdrop="false"
          ></f7-searchbar>
        </f7-subnavbar>
      </f7-navbar>

      <f7-list dividers-ios strong-ios class="searchbar-not-found">
        <f7-list-item :title="$t('common.nothingFound')"></f7-list-item>
      </f7-list>
      <f7-block
        strong
        v-show="this.newGroupParticipants && this.newGroupParticipants.length > 0"
      >
        <f7-chip
          outline
          v-for="(grpParticipant, index) in newGroupParticipants"
          :key="index"
          :text="grpParticipant.name"
          deleteable
          @click="deleteParticipantChip(grpParticipant)"
          ><template #media>
            <img
              v-if="!!grpParticipant.logo"
              :src="`data:image/png;base64,${grpParticipant.logo}`"
              style="
                width: var(--f7-chip-height);
                height: var(--f7-chip-height);
                border-radius: 50%;
              "
            />
            <img
              v-else-if="!!grpParticipant.avatar"
              :src="`data:image/png;base64,${grpParticipant.avatar}`"
              style="
                width: var(--f7-chip-height);
                height: var(--f7-chip-height);
                border-radius: 50%;
              "
            />
            <f7-badge
              v-else
              color="auto"
              style="
                width: var(--f7-chip-height);
                height: var(--f7-chip-height);
                border-radius: 50%;
              "
              >{{ $filtersGetInitial.getInitial(grpParticipant.name) }}</f7-badge
            >
          </template>
        </f7-chip>
      </f7-block>
      <f7-list
        dividers-ios
        strong-ios
        media-list
        no-chevron
        class="search-UserList searchbar-found"
      >
        <!-- <f7-list-group > -->
        <f7-list-item
          v-for="(user, index) in this.users"
          :key="index"
          checkbox
          :title="user.name"
          @click="selectParticipant(user)"
          :after="'+' + user.phoneCode + '' + user.phoneNumber"
          :footer="user.tag"
          :disabled="
            chatGroupParticipantList &&
            chatGroupParticipantList.length > 0 &&
            chatGroupParticipantList.findIndex((x) => x.userId === user.userId)
              ? true
              : false
          "
          :name="'participant' + user.userId"
          :checked="newGroupParticipants.findIndex((x) => x.userId === user.userId) >= 0"
          :value="user.userId"
          @change="selectParticipant(user)"
          :class="'newGroupParticipant' + user.userId"
        >
          <template #media>
            <img
              v-if="!!user.logo"
              :src="`data:image/png;base64,${user.logo}`"
              width="40"
              style="height: 48px; width: 48px; border-radius: 25px"
              badge="+"
              badge-color="auto"
            />

            <f7-badge
              color="auto"
              badge="+"
              badge-color="auto"
              v-else-if="user.logo === null && user.name !== null"
              style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
              >{{ $filtersGetInitial.getInitial(user.name) }}</f7-badge
            >
          </template>
        </f7-list-item>
        <!-- </f7-list-group> -->
      </f7-list>

      <f7-toolbar
        tabbar
        bottom
        bg-color="red"
        :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        v-if="this.newGroupParticipants && this.newGroupParticipants.length >= 1"
      >
        <f7-link
          raised
          fill
          large
          ripple
          class="link"
          text-color="white"
          icon-ios="f7:arrow_up_circle_fill"
          icon-aurora="f7:arrow_up_circle_fill"
          icon-md="material:send"
          strong
          @click="addNewParticipants()"
        >
          {{ $t("tamtammessage.addParticipants") }}
        </f7-link>
      </f7-toolbar>
    </f7-page>
  </f7-panel>
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import {
  messageOptionsMixin,
  messageGroupMixin,
  getusersMixin,
} from "../../js/mixins/messageMixins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    chatGroupParticipantList: Object,
  },
  mixins: [messageOptionsMixin, messageGroupMixin, getusersMixin],

  data() {
    return {
      theme,
      f7,
      newGroupParticipants: [],
      participantsToAdd: [],
    };
  },

  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "infoMessage",
      "messagesData",
      "groupUnReadMsg",
      "allGroupsUnReadMsg",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
      "users",
    ]),
    ...mapState("auth", ["video", "isDeskTop", "isTablet", "device", "profile"]),
  },
};
</script>
