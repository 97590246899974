<template>
  <f7-page>
  </f7-page>
</template>
<script>

export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
};
</script>
