import { mapGetters, mapState } from "vuex";
import { f7, theme } from "framework7-vue";
import $dayjs from "../../js/day.js";
import _ from "lodash";
export const avocash_charts = {
  data() {
    return {
      theme,
      f7,
    };
  },
  mounted() {},
  methods: {
    selectSortingCriteria(value) {
      f7.popover.close(".sortingPopup");
      this.orderBy = value;
      this.isDesc = !this.isDesc;
      this.oderingDir = this.isDesc ? "desc" : "asc";
      this.setTransactionList(this.groupBy, false);
    },
    transactionStatus(status) {
      const statusMap = {
        COMPLETE: { status: this.$t("common.confirmed"), color: "green" },
        SUCCESSFUL: { status: this.$t("common.confirmed"), color: "green" },
        Successful: { status: this.$t("common.confirmed"), color: "green" },
        CONFIRMED: { status: this.$t("common.confirmed"), color: "green" },
        SUCCEEDED: { status: this.$t("common.confirmed"), color: "green" },
        Succeeded: { status: this.$t("common.confirmed"), color: "green" },
        INITIATED: { status: this.$t("common.initiated"), color: "orange" },
        AUTHORIZED: {
          status: this.$t("common.authorized"),
          color: "deeporange",
        },
        CANCELLED: { status: this.$t("common.cancelled"), color: "red" },
        Canceled: { status: this.$t("common.cancelled"), color: "red" },
        CANCELED: { status: this.$t("common.cancelled"), color: "red" },
        TO_VALIDATE: { status: this.$t("common.to_validate"), color: "orange" },
        PENDING: { status: this.$t("common.to_validate"), color: "orange" },
        Pending: { status: this.$t("common.to_validate"), color: "orange" },
        INPROGRESS: { status: this.$t("common.to_validate"), color: "orange" },
        PROCESSING: { status: this.$t("common.to_validate"), color: "orange" },
        REJECTED: { status: this.$t("common.rejected"), color: "red" },
        FAILED: { status: this.$t("common.failed"), color: "red" },
        Failed: { status: this.$t("common.failed"), color: "red" },
        requires_payment_method: {
          status: this.$t("common.failed"),
          color: "red",
        },
        REFUNDED: { status: this.$t("common.refunded"), color: "teal" },
        TIMED_OUT_3DS: {
          status: this.$t("common.timed_out_3DS", { threeDS: "3DS" }),
          color: "red",
        },
        TIMED_OUT: { status: this.$t("common.timed_out"), color: "red" },
        EXPIRED: { status: this.$t("common.timed_out"), color: "red" },
      };
      return statusMap[status];
    },

    getCurrentUser(userId) {
      return _.find(this.users, ["userId", userId]);
    },
    currentTransactionList(transactionList, value) {
      return _.chain(transactionList)
        .orderBy([this.orderBy], [this.oderingDir])
        .map((transaction) => ({
          ...transaction,
          transactionYearQuarter: `${$dayjs(
            transaction.creation_date
          ).quarter()}-${$dayjs(transaction.creation_date).year()}`,
          transactionYearMonth: $dayjs(transaction.creation_date).format(
            "MM-YYYY"
          ),
          transactionYearMonthWeek: `${$dayjs(
            transaction.creation_date
          ).week()}-${$dayjs(transaction.creation_date).year()}`,
          transactionYearMonthDay: $dayjs(transaction.creation_date).format(
            "DD-MM-YYYY"
          ),
        }))
        .groupBy(value)
        .map((objs, key) => ({
          groupBy: key,
          currency: objs[0].currency,
          subTotalReceived: _.sumBy(objs, (item) =>
            parseFloat(item.amount) > 0 ? item.amount : 0
          ),
          subTotalPaid: _.sumBy(objs, (item) =>
            parseFloat(item.amount) < 0 ? item.amount : 0
          ),
          subTotal: _.sumBy(objs, "amount"),
          items: objs,
        }))
        .value();
    },
    setTransactionList(value, init_status) {
      this.groupBy = value;
      f7.popover.close(".sortingPopup");
      f7.preloader.show("multi");
      const aera_transaction_list = [
        "transactionYearQuarter",
        "transactionYearMonth",
        "transactionYearMonthWeek",
        "transactionYearMonthDay",
      ].includes(value)
        ? this.currentTransactionList(this.transactionList, value)
        : this.currentTransactionList(
            this.transactionList,
            "transactionYearMonth"
          );
      this.dataValue = aera_transaction_list.map((item) => item.subTotal);
      this.axisData = aera_transaction_list.map((item) => item.groupBy);
      this.area_name = [
        "transactionYearQuarter",
        "transactionYearMonth",
        "transactionYearMonthWeek",
        "transactionYearMonthDay",
      ].includes(value)
        ? _.find(this.groupingCriteria, ["value", value]).name
        : _.find(this.groupingCriteria, ["value", "transactionYearMonth"]).name;

      let bar_pie_transaction_list;
      if (
        ["transactionYearQuarter", "senderName", "status", "currency"].includes(
          value
        )
      ) {
        bar_pie_transaction_list = this.currentTransactionList(
          this.transactionList,
          value
        );
        this.pieData = bar_pie_transaction_list.map((item) => item.subTotal);
        this.barData = this.pieData;
        this.pieLabel = bar_pie_transaction_list.map((item) =>
          value === "status"
            ? this.transactionStatus(item.groupBy).status
            : item.groupBy
        );
        this.barLabel = this.pieLabel;
      }
      this.bar_name = _.find(this.groupingCriteria, ["value", value]).name;
      const chart_data = [
        {
          type: "bar",
          data: this.barData,
          categories: this.barLabel,
          name: this.bar_name,
        },
        {
          type: "area",
          data: this.dataValue,
          categories: this.axisData,
          name: this.area_name,
        },
        {
          type: "pie",
          series: this.pieData,
          labels: this.pieLabel,
          name: this.bar_name,
        },
      ];
      this.$store.dispatch("apimoney/setTransactionList", chart_data);
      if (!init_status && chart_data.length > 0) {
        console.log(" chartData", chart_data);
        this.updateChart();
        this.updateTheme();
      }
      f7.preloader.hide();
    },
    updateChart() {
      this.areaChartSeries = [
        {
          name: this.structuredTransactionList
            ? this.structuredTransactionList.find(
                (item) => item.type === "area"
              ).name
            : [],
          data: this.structuredTransactionList
            ? this.structuredTransactionList.find(
                (item) => item.type === "area"
              ).data
            : [],
        },
      ];
      this.barChartSeries = [
        {
          data: this.structuredTransactionList
            ? this.structuredTransactionList.find((item) => item.type === "bar")
                .data
            : [],
        },
      ];

      this.pieChartSeries = this.structuredTransactionList
        ? this.structuredTransactionList.find((item) => item.type === "pie")
            .series
        : [];
    },
    updateTheme(e) {
      this.areaChartOptions = {
        xaxis: {
          categories: this.structuredTransactionList
            ? this.structuredTransactionList.find(
                (item) => item.type === "area"
              ).categories
            : [],
        },
      };
      this.barChartOptions = {
        xaxis: {
          categories: this.structuredTransactionList
            ? this.structuredTransactionList.find((item) => item.type === "bar")
                .categories
            : [],
        },
      };
      this.pieChartOptions = {
        labels: this.structuredTransactionList
          ? this.structuredTransactionList.find((item) => item.type === "pie")
              .labels
          : [],
      };
    },
  },
  computed: {
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "standard",
      "business",
      "accountList",
      "transactionList",
      "apiWallet",
      "structuredTransactionList",
    ]),
    ...mapState("auth", [
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
      "locale",
    ]),
    ...mapState("account", ["userAccount"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
