<template>
  <f7-page hide-bars-on-scroll no-toolbar @page:beforeremove="onPageBeforeRemove">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{ this.title }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <!-- <f7-row no-gap>
      <f7-col width="0" medium="25"></f7-col>
      <f7-col width="100" medium="50"> -->
    <span v-if="(this.paymentTransaction === '' ||
      this.paymentTransaction.isValidated === true) &&
      this.QRCodeToCashOut === ''
      ">
      <f7-block-title medium style="white-space: normal; word-wrap: break-word">{{
        $t("avocashoutwithnewqrcode.selectCurrencyAnd")
      }}</f7-block-title>

      <f7-list dividers-ios strong-ios media-list id="cashOutWithQR">
        <f7-list-group>
          <f7-list-item v-if="this.profile" class="no-padding-right paymentCountry item-input-wrap item-label"
            smart-select smart-select-init :smart-select-params="{
              closeOnSelect: true,
              openIn: 'popup',
              popupSwipeToClose: true,
              valueEl: this.paymentCurrency,
              setValueText: true,
              searchbar: true,
              searchbarPlaceholder: 'Search payment currency',
            }" :title="$t('avocashoutwithnewqrcode.paymentCurrency')">
            <f7-link class="input-icon4">
              <img v-if="this.countryCode" class="coutryMap no-margin-bottom no-padding-bottom"
                :src="'https://flagcdn.com/' + this.countryCode.toLowerCase() + '.svg'" width="24" />
            </f7-link>
            <select style="font-size: 12px" name="currency" class="item-input-wrap" id="paymentCountry"
              v-model="paymentCountry" @change="changePaymentCurrency()">
              <optgroup v-for="(continent, continentName, index) in destinationCtry" :key="index" :label="continentName">
                <option v-for="(country, index) in continent" :value="country.toCountryId" :key="index"
                  :data-option-image="'https://flagcdn.com/' + country.toCountryCode2.toLowerCase() + '.svg'
                    " :data-totransferid="country.toCountryId + '' + country.toCurrencyCode"
                  :data-tocurrencycodetoCountryName="country.toCurrencyCode + '-' + country.toCurrencyName
                    ">
                  {{ country.toCurrencyCode }}:
                  {{ country.toCurrencyName }}
                  {{ country.toCountryCode2 }}
                </option>
              </optgroup>
            </select>
          </f7-list-item>

          <li class="item-content item-input">
            <div class="item-media" v-if="this.paymentCurrency !== ''">
              {{ this.paymentCurrency }}
            </div>
            <div class="item-inner">
              <div v-if="!this.paymentCurrency" class="item-title item-floating-label">
                {{ $t("avocashoutwithnewqrcode.amountToCashout") }}
              </div>
              <div v-else class="item-title item-label">
                {{ $t("avocashoutwithnewqrcode.amountToCashout") }}
              </div>
              <div class="item-input-wrap">
                <input type="number" required validate data-error-message="Please enter amount to issue QR code!"
                  name="amountToPay" id="amountToPay" placeholder-ios="amount to pay" :value="amountToPay.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                    " @input="amountToPay = $event.target.value" @change="changeAmountToPay()"
                  @keyup="changeAmountToPay()" @blur="changeAmountToPay()" @keyup.enter="issueQRCodeToToCashOut()" />
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>

          <f7-block class="no-margin" v-if="this.paymentCurrency !== '' && this.amountToPay != ''">
            <p style="font-size: 10px; font-style: italic; color: red">
              {{ this.issueQRToCashOutErrorMessage }}
            </p>
          </f7-block>

          <f7-block style="font-size: 14px" class="no-margin" v-if="this.paymentReverseDetail.grossTransferAmount != '' &&
            this.paymentCurrency !== this.primaryAccount.currencyCode &&
            this.amountToPay != ''
            ">
            <p>
              {{
                $t("avocashoutwithnewqrcode.iCashout", {
                  "1": $filtersToCurrency.toCurrency(
                    this.paymentReverseDetail.grossTransferAmount,
                    this.paymentReverseDetail.fromCurrencySymbolNative,
                    this.paymentReverseDetail.fromCurrencyCode
                  ),
                })
              }}
            </p>
          </f7-block>
          <f7-list-input :label="$t('avocashoutwithnewqrcode.description')" floating-label type="textarea" resizable
            name="description" :placeholder="$t('avocashoutwithnewqrcode.description')" :value="description"
            @input="description = $event.target.value"></f7-list-input>
        </f7-list-group>
      </f7-list>
      <!--If no amount is entered, alert to confirm that amount taken from main account account and to be confirmed with PIN-->
      <f7-button raised fill large class="margin" color="red" @click="issueQRCodeToToCashOut()">{{
        $t("avocashoutwithnewqrcode.issueQrCode") }}</f7-button>
    </span>
    <span v-if="(this.paymentTransaction === '' ||
      this.paymentTransaction.isValidated === true) &&
      this.QRCodeToCashOut
      ">
      <f7-list>
        <h5 class="margin-horizontal">
          {{ $t("avocashoutwithnewqrcode.cashierCanScan") }}
        </h5>
        <h5 class="margin-horizontal" v-if="this.paymentCurrency !== this.primaryAccount.currencyCode">
          {{
            $t("avocashoutwithnewqrcode.amountToCashout", {
              "1": $filtersToCurrency.toCurrency(
                this.paymentReverseDetail.receivedAmount,
                this.paymentReverseDetail.toCurrencySymbolNative,
                this.paymentReverseDetail.toCurrencyCode
              ),
            })
          }}
        </h5>
        <h5 class="margin-horizontal" v-else>
          {{
            $t("avocashoutwithnewqrcode.amountCashout", {
              "1": $filtersToCurrency.toCurrency(
                this.paymentDetail.grossTransferAmount,
                this.paymentDetail.fromCurrencySymbolNative,
                this.paymentDetail.fromCurrencyCode
              ),
            })
          }}
        </h5>
        {{ this.QRCodeToCashOut }}
        <img :src="this.QRCodeToCashOut" class="QRCode" alt="QRCode" style="display: block; margin: auto" />
      </f7-list>

      <f7-button class="margin" outline raised ripple large color="red" @click="deleteQRCode()">{{
        $t("avocashoutwithnewqrcode.deleteQrCode") }}</f7-button>
    </span>
    <f7-list strong-ios dividers-ios v-if="this.paymentTransaction.isValidated === false && this.type === 'avo¢ash out'"
      media-list>
      <f7-list-group>
        <f7-block-title color="green" class="text-align-center">{{
          $t("avocashoutwithnewqrcode.transferTo", {
            "1": this.paymentTransaction.payeeName,
          })
        }}</f7-block-title>
        <f7-list-item :title="$t('avocashoutwithnewqrcode.youPaid')" :after="$filtersToCurrency.toCurrency(
          this.paymentTransaction.grossPaymentAmount,
          this.paymentTransaction.fromCurrencySymbol,
          this.paymentTransaction.fromCurrencyCode
        )
          "></f7-list-item>
        <f7-block-title>{{
          $t("avocashoutwithnewqrcode.validateCashout")
        }}</f7-block-title>
        <li class="item-content">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title" style="color: gray; font-size: 75%">
                {{ $t("avocashoutwithnewqrcode.transferCode") }}
              </div>
              <div class="item-title" style="color: gray; font-size: 75%">
                {{ $t("avocashoutwithnewqrcode.transferPin") }}
              </div>
            </div>
            <div class="item-title-row">
              <div class="item-title" style="font-weight: bold">
                {{ this.paymentTransaction.transferCode }}
              </div>
              <div class="item-title" style="font-weight: bold">
                {{ this.paymentTransaction.transferPIN }}
              </div>
            </div>
          </div>
        </li>
      </f7-list-group></f7-list>
    <f7-button v-if="this.paymentTransaction.isValidated === false" raised fill ripple large class="margin" color="red"
      strong @click="validateDebitTransaction(paymentTransaction)">{{ $t("avocashoutwithnewqrcode.validateCashout")
      }}</f7-button>
    <!-- </f7-col>
      <f7-col width="0" medium="25"></f7-col>
    </f7-row> -->
  </f7-page>
</template>
<script>
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";
import { paymentConnect } from "../js/websocket/paymentWebsocket.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [calculateTransferPayment, transferAgain, paymentConnect],
  data() {
    return {
      theme,
      f7,
      title: "create QRcode to cash-out",
      amountToPay: "",
      paymentCountry: "",
      paymentCurrency: "",
      countryCode: "",
      issueQRToCashOutErrorMessage: "",
      time: 5,
      isRunning: false,
      interval: null,
      description: "",
      fromAccount: "",
      toastText: "",
      description: "",
      connected: false,
      service: "",
      type: "",
      path: "",
      socket: "",
      stompClient: "",
    };
  },

  mounted: function () {
    const self = this;

    if (this.profile) {
      if (
        (this.primaryAccount === "" || this.businessAccount === "") &&
        this.$keycloak.authenticated
      ) {
        self.$store.dispatch("account/loadPrimaryAccount");
        self.$store.dispatch("account/loadBusinessAccount");
      }
      if (this.destinationCountry === "" && this.$keycloak.authenticated) {
        self.$store.dispatch("transaction/loadDestinationCtry");
      }
      this.paymentCurrency = this.primaryAccount.currencyCode;
      this.paymentCountry = this.currentUserCountry.countryId;
      this.countryCode = this.currentUserCountry.countryCode2;
      f7.$(".smart-select .item-after").html(
        this.primaryAccount.currencyCode +
        ": " +
        this.primaryAccount.currencyName +
        " " +
        this.countryCode
      );
    }
    this.paymentCurrency = this.payerAccount.currencyCode;
    this.fromAccount = "Primary";
    setInterval(() => {
      if (this.qrCodeData && this.qrCodeData.expireAt !== "") {
        const date = new Date();
        if (date > parseInt(this.qrCodeData.expireAt)) {
          this.deleteQRCode();
        }
      }
    }, 30000);
  },
  methods: {
    changePaymentCurrency() {
      const self = this;

      // if (this.recipientCountry) {
      this.paymentCurrency = this.recipientCountry.toCurrencyCode;
      this.countryCode = this.recipientCountry.toCountryCode2;
      this.changeAmountToPay();
    },

    changeAmountToPay() {
      const self = this;

      if (this.recipientCountry) {
        //check if recipient country exits to calculate transfer
        self.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              if (parseFloat(this.amountToPay) > 0) {
                //check if amount to pay is >0 and proceed with calculation
                if (
                  this.paymentCurrency === this.payerAccount.currencyCode &&
                  this.paymentCurrency !== null
                ) {
                  if (
                    parseFloat(this.amountToPay) >
                    parseFloat(this.payerAccount.accountBalance)
                  ) {
                    this.amountToPay = parseFloat(this.payerAccount.accountBalance);
                    this.calculatePayment();
                  } else {
                    this.calculatePayment();
                  }
                } else if (
                  this.paymentCurrency !== this.payerAccount.currencyCode &&
                  this.paymentCurrency !== null
                ) {
                  if (
                    parseFloat(this.amountToPay) / parseFloat(this.grossexchangerate2) >
                    parseFloat(this.payerAccount.accountBalance)
                  ) {
                    this.amountToPay =
                      parseFloat(this.payerAccount.accountBalance) *
                      parseFloat(this.grossexchangerate2);
                    this.calculatePaymentReverse();
                  } else {
                    this.calculatePaymentReverse();
                  }
                } else {
                  this.isssueQRCashOutErrorMessage = "Please select cash-out currency";
                }
              }
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },

    issueQRCodeToToCashOut() {
      const self = this;

      if (f7.input.validateInputs("#cashOutWithQR")) {
        self.$store.dispatch("payment/createQRcode", {
          type: "avo¢ash ATM",
          amount: this.amountToPay,
          paymentCurrency: this.paymentCurrency,
          payerAccountNumber: this.payerAccount.accountNumber,
          payerGeolocation: this.latitude + "-" + this.longitude,
          payerIpAddress: this.ipAddress,

          payeeAccountNumber: "",
          payeeGeolocation: "",
          payeeIpAddress: "",
          description: this.description,
        });

        this.connectPayService();
      } else {
        var valid = f7.$("#cashOutWithQR .input-invalid").length === 0;
      }
    },
    deleteQRCode() {
      const self = this;

      this.amountToPay = "";
      self.$store.state.payment.QRCodeToCashOut = "";
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
      self.$store.dispatch("payment/deleteQRCode", this.qrCodeData.id);
      this.disconnectPaymentService();
    },
    onPageBeforeRemove() {
      const self = this;

      this.deleteQRCode();
    },
  },

  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCodeToCashOut",
      "paymentError",
      "qrCodeData",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("remit", ["recipients", "allrecipients", "userDetail"]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userByAccountNumber"]),
    ...mapState("auth", ["profile"]),
    ...mapState("location", [
      "country",
      "destinationCity",
      "currentUserCountry",
      "latitude",
      "longitude",
      "ipAddress",
    ]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    recipientCountry() {
      if (!this.userByAccountNumber) {
        return false;
      } else {
        return _.find(this.destinationCountry, [
          "toCountryId",
          this.userByAccountNumber.country.countryId,
        ]);
      }
    },
    recipientCountry() {
      return _.find(this.destinationCountry, ["toCountryId", this.paymentCountry]);
    },
    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    payerAccount() {
      if (this.fromAccount === "Primary") {
        return this.primaryAccount;
      } else {
        return this.businessAccount;
      }
    },
  },
};
</script>
