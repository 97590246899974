<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar :sliding="false" :title="this.product.name" no-shadow :back-link="$t('common.backButton')"
      :back-link-show-text="false">
      <f7-nav-right>
        <f7-link icon-aurora="f7:text_badge_plus" icon-ios="f7:text_badge_plus" icon-md="material:playlist_add"
          href="/product/new"></f7-link>
        <f7-link icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"
          :href="'/product/edit/' + this.product.id"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-photo-browser :photos="this.product.images" ref="popup"></f7-photo-browser>
        <f7-list>
          <f7-list-group>
            <swiper-container pagination :params="{
              loop: true,
              slidesPerView: 1,
              spaceBetween: 10,
            }">
              <swiper-slide v-for="(item, index) in this.product.images" :key="index" style="width: 400px; height: 400px">
                <a link="#" @click="$refs.popup.open()">
                  <img :src="item.url" width="400" height="400" max-height="100%" />

                  <!-- </a> -->
                </a>
              </swiper-slide>
            </swiper-container>
            <f7-list-item :title="$t('shopproductdetail.productId')" :after="this.product.id">
              <!-- <f7-list-item-row>
            <f7-list-item-cell>{{ $t('shopproductdetail.productId') }}</f7-list-item-cell>
            <f7-list-item-cell>
              {{ this.product.id }}
            </f7-list-item-cell>
          </f7-list-item-row>-->
            </f7-list-item>
            <f7-list-item :title="$t('shopproductdetail.productCategory')"
              :after="this.currentCategory.subCategoryName"></f7-list-item>
            <f7-list-item header="Delevery area" :title="this.deliveryCityList"></f7-list-item>
            <f7-list-item :title="$t('shopproductdetail.productName')" :after="this.product.name"></f7-list-item>
            <f7-list-item :title="$t('shopproductdetail.productPrice')"
              :after="this.product.currencyCode + ' ' + this.product.productPrice"></f7-list-item>
            <f7-list-item v-if="this.product.negotiable" :title="$t('shopproductdetail.poductPriceIsNegotiable')"
              after="Yes"></f7-list-item>
            <f7-list-item v-if="this.product.negotiable" :title="$t('shopproductdetail.productMinimumPrice')"
              :after="this.product.currencyCode + ' ' + this.product.minimumPrice"></f7-list-item>
            <f7-block-title class="margin-top-half">{{
              $t("shopproductdetail.description")
            }}</f7-block-title>
            <f7-block class="align-items-center">
              <p class="padding-left-half" style="border: 1px solid lightgray" v-html="this.product.description"></p>
            </f7-block>
          </f7-list-group>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
    <f7-fab position="center-bottom" slot="fixed" :text="$t('shopproductdetail.listNewProduct')" color="red" popup-close
      href="/product/new">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>
    <f7-fab position="right-bottom" slot="fixed" color="auto" popup-close>
      <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<style scoped>
.itemImageContainer img {
  height: "auto";
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<script>
import categories from "../js/data/categories.json";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return {
      theme,
      f7,
      product: JSON.parse(window.localStorage.getItem("product")),
      categories: categories,
      curCity: [],
    };
  },
  mounted() {
    const self = this;

    self.$store.dispatch("location/getCityList");
  },

  methods: {
    getStateInfo() {
      const self = this;
    },

    saveNewProduct() {
      const self = this;

      self.$store
        .dispatch("remit/recipientAdd", {
          firstName: this.firstName,
          lastName: this.lastName,
          name: this.firstName + " " + this.lastName,
          email: this.email,
          phoneCode: this.destinationCity.phoneCode,
          phone: this.phone,
          address: this.address,
          userrecipient: null,
          cityId: this.recipientCity,
          cityName: this.cityName,
          stateId: this.stateId,
          stateName: this.stateName,
          countryId: this.recipientCountry,
          countryCode2: this.destinationCity.countryCode2,
          countryName: this.destinationCity.countryName,
        })
        .then((resp) => {
          console.log(this.recipient);
          f7.preloader.show("multi");
          setTimeout(() => {
            this.f7router.navigate("/product/" + this.product.productName, {
              ignoreCache: true,
              reloadCurrent: true,
            });
            f7.preloader.hide();
          }, 500);
          console.log(this.recipient);
        })
        .catch((e) => {
          console.log(e.message);
        });
    },
  },
  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "recipient",
      "userrecipientList",
    ]),
    ...mapState("account", ["userByAccountNumber", "primaryAccount"]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "cityList",
      "cities",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber", "primaryAccount"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    currentCategory() {
      return _.find(this.categories, ["subCategoryId", this.product.category]);
    },

    deliveryCityList() {
      const self = this;

      if (this.cityList) {
        _.forEach(this.product.city, (cityId) => {
          this.curCity.push(_.find(this.cityList, ["cityId", cityId]).cityName);
        });
        return this.curCity.join(", ");
      }
    },
  },
};
</script>
