<template>
  <f7-page :page-content="false" hide-bars-on-scroll id="topupmyaccount">
    <f7-navbar no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">{{ $t("topupmyaccount.topupMy")
    }}<f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top no-shadow if-not-aurora>
      <f7-link tab-link="#topupWithAvocash" v-if="$keycloak.authenticated" class="avocash-icon">
        <i class="icon" style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)">
          <img src="/icon-new.ico" width="24" slot="media" height="24" class="elevation-6" alt="avocash logo" /></i>
        <span class="tabbar-label avocash">avo¢ash</span></f7-link>
      <f7-link tab-link="#topupWithDebitCard" icon-aurora="f7:creditcard" icon-ios="f7:creditcard"
        icon-md="material:payment" :text="$t('topupmyaccount.bankCard')"></f7-link>
      <f7-link tab-link="#topupWithBankOrMobile" icon-ios="f7:device_phone_portrait"
        icon-aurora="f7:device_phone_portrait" icon-md="material:smartphone"
        :text="$t('topupmyaccount.bankmobile')"></f7-link>
    </f7-toolbar>

    <f7-tabs id="topupMyAccount" swipeable animated>
      <f7-tab id="topupWithAvocash" class="page-content" tab-active @tab:show="initiateCardNumberKeyPad()">
        <f7-block-title style="white-space: normal; word-wrap: break-word">{{ $t("topupmyaccount.topupWith")
        }}<span class="avocash">avo¢ash</span></f7-block-title>
        <addnewcards></addnewcards>
        <f7-list dividers-ios strong-ios media-list form-store-data>
          <f7-list-group v-if="carditemlist && carditemlist.length > 0">
            <f7-list-item class="item-label" smart-select :smart-select-params="{
              closeOnSelect: true,
              openIn: isTablet == true ? 'popover' : 'sheet',
              sheetSwipeToClose: true,
              sheetPush: true,
            }" :title="$t('topupmyaccount.selectAccountToTopup')">
              <select type="select" name="account-type" v-model="accountNumber">
                <option v-if="userAccount.standard !== null" :value="st.accountNumber" selected>
                  {{ primaryAccount.accountType }}:
                  {{ primaryAccount.accountNumber }}
                </option>
                <option v-if="businessAccount.accountNumber !== null &&
                  businessAccount.active === true
                  " :value="businessAccount.accountNumber">
                  {{ businessAccount.accountType }}:
                  {{ businessAccount.accountNumber }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <f7-button v-if="carditemlist && carditemlist.length > 0" raised fill large ripple class="margin buttons"
          @click="topupmyaccount()" color="red">{{ $t("topupmyaccount.topUpMyAccount") }}</f7-button>
      </f7-tab>
      <f7-tab id="topupWithDebitCard" class="page-content" @tab:show="initiateDebitCardNumberKeyPad()">
        <f7-block-title style="white-space: normal; word-wrap: break-word">{{
          $t("topupmyaccount.enterAmountAnd")
        }}</f7-block-title>
        <f7-list dividers-ios strong-ios media-list>
          <f7-list-group>
            <li class="item-content item-input">
              <div class="item-media">
                {{ profile.currencyCode || primaryAccount.currencyCode }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("topupmyaccount.topupAmount") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" :placeholder="$t('topupmyaccount.topupAmount')" name="amountToTopup"
                    pattern="[0-9]*" required validate min="0" :data-error-message="$t('topupmyaccount.enterAmount')"
                    :value="amountToTopup" @input="amountToTopup = $event.target.value" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>

            <f7-list-item smart-select :smart-select-params="{
              closeOnSelect: true,

              openIn: isTablet == true ? 'popover' : 'sheet',
              sheetSwipeToClose: true,
              sheetPush: true,
            }" class="item-label" :title="$t('topupmyaccount.selectAccountToTopup')">
              <select type="select" name="account-type" v-model="accountNumber">
                <option v-if="primaryAccount.accountNumber !== null" :value="primaryAccount.accountNumber" selected>
                  {{ primaryAccount.accountType }}:
                  {{ primaryAccount.accountNumber }}
                </option>
                <option v-if="businessAccount.accountNumber !== null &&
                  businessAccount.active === true
                  " :value="businessAccount.accountNumber">
                  {{ businessAccount.accountType }}:
                  {{ businessAccount.accountNumber }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>
        <payDebitCard />
        <f7-button raised fill large ripple class="margin buttons" @click="topupmyWithBankCard()" color="red">{{
          $t("topupmyaccount.topUpMyAccount") }}</f7-button>
      </f7-tab>
      <f7-tab id="topupWithBankOrMobile" class="page-content">
        <f7-block-title style="white-space: normal; word-wrap: break-word">{{ $t("topupmyaccount.otherWaysTo") }}<span
            class="avocash">avo¢ash</span>{{ $t("topupmyaccount.account") }}</f7-block-title>
        <f7-card><f7-card-header>{{ $t("topupmyaccount.fromMyBankAccount") }}</f7-card-header>
          <f7-card-content>{{ $t("topupmyaccount.transferTo") }}<span class="avocash">avo¢ash</span>{{
            $t("topupmyaccount.bankAccount") }}<br />
            <b>{{ localAvocashBank }}</b><br />{{ $t("topupmyaccount.withReferenceYour")
            }}<span class="avocash">avo¢ash</span>{{ $t("topupmyaccount.account")
}}<br /><b>{{ primaryAccount.accountNumber }}</b>
            or
            <b>{{ businessAccount.accountNumber }}</b></f7-card-content></f7-card>
        <f7-card><f7-card-header>{{ $t("topupmyaccount.fromMyMobile") }}</f7-card-header>
          <f7-card-content>{{ $t("topupmyaccount.transferTo") }}<span class="avocash">avo¢ash</span>{{
            $t("topupmyaccount.mobileMoneyAccount") }}<br />
            <b>{{ localAvocashMobileMoney }}</b><br />{{ $t("topupmyaccount.withReferenceYour")
            }}<span class="avocash">avo¢ash</span>{{ $t("topupmyaccount.account")
}}<br /><b>{{ primaryAccount.accountNumber }}</b>
            or
            <b>{{ businessAccount.accountNumber }}</b></f7-card-content></f7-card>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Toolbar,
  f7Link,
  f7Tabs,
  f7Tab,
  f7BlockTitle,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Button,
  f7CardContent,
  f7Card,
  f7CardHeader,
  theme,
  f7,
} from "framework7-vue";
import PayDebitCard from "./payDebitCard.vue";
import Navbarright from "../components/navbarright.vue";
import { scanCard, checkCard } from "../js/mixins/card.js";
import AddNewCards from "../components/addnewcards.vue";
import { mapGetters, mapState } from "vuex";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    addnewcards: AddNewCards,
    payDebitCard: PayDebitCard,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Toolbar,
    f7Link,
    f7Tabs,
    f7Tab,
    f7BlockTitle,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7Button,
    f7CardContent,
    f7Card,
    f7CardHeader,
  },
  mixins: [scanCard, checkCard],
  data() {
    return {
      theme,
      f7,
      // cardNumber: '',
      // cardPIN: '',:
      amountToTopup: "",
      accountNumber: "",
      accountType: "",
      localAvocashBank: "NL0236 3732 3735 2727, bank: ABN AMRO ",
      localAvocashMobileMoney: "+31 6 44 88 07 01 ",
    };
  },

  mounted: function () {
    const self = this;
    if (this.$keycloak.authenticated) {
      this.accountNumber = this.userAccount.standard.id;
      f7.$(".smart-select .item-after").html(
        this.userAccount.standard.type + ": " + this.userAccount.standard.id
      );
    }
    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
  },
  methods: {
    initiateCardNumberKeyPad() {
      if (this.debitCardNumberKeypad) {
        this.debitCardNumberKeypad.destroy();
      }
    },
    initiateDebitCardNumberKeyPad() {
      if (this.cardNumberKeypad) {
        this.cardNumberKeypad.destroy();
      }
      if (this.cardPINKeypad) {
        this.cardPINKeypad.destroy();
      }
    },
    async topupmyaccount() {
      const self = this;

      if (this.accountNumber == this.primaryAccount.accountNumber) {
        this.accountType = this.primaryAccount.accountType;
      } else if (this.accountNumber == this.businessAccount.accountNumber) {
        this.accountType = this.businessAccount.accountType;
      }
      if (this.accountNumber !== "" && this.accountType !== "") {
        await self.$store.dispatch("transaction/topupMyAccount", {
          accountnumber: this.accountNumber,
          accountType: this.accountType,
        });

        await this.validatedDialog();
        await this.f7router.navigate("/dashboard/", {
          ignoreCache: true,
          reloadAll: true,
        });
      } else {
        if (!self.toastTop) {
          self.toastTop = f7.toast.create({
            text: "Please select account to top up!",
            cssClass: "checkCardToast",
            position: "top",
            closeTimeout: 2000,
          });
        }
        self.toastTop.open();
        return false;
      }
    },
    topupmyWithBankCard() { },
    validatedDialog() {
      const self = this;

      if (!self.toastIcon) {
        self.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-  text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-  text-color-green border-color-gray color-green" style="font-size:150px">check_circle_outline</i>',
          text: "Top-up successfull!",
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 5000,
        });
      }
      self.toastIcon.open();
    },
  },

  computed: {
    totalCardbasket() {
      return _.reduce(
        this.carditemlist,
        (amount, carditem) => {
          return amount + parseFloat(carditem.cardAmount || 0);
        },
        0
      );
    },

    ...mapState("card", ["carditemlist", "cardbasket", "country", "queryerror"]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "qrcodeDecoded",
      "QRtext",
    ]),
    ...mapState("payment", ["cardInfo"]),
    ...mapState("account", ["userAccount"]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
  },
};
</script>
