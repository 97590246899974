<template>
  <f7-page hide-bars-on-scroll no-toolbar>
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{
        $t("shopproductedit.update", { theproduct: this.product.productName })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios media-list form-store-data id="productAdd">
          <f7-list-group>
            <f7-list-item class="productCategory item-label" smart-select :smart-select-params="{
                  openIn: 'popup',
                  popupSwipeToClose: true,
                  closeOnSelect: true,
                  searchbar: true,
                  searchbarPlaceholder: $t('shopproductaddnew.findProductCategory'),
                }" :title="$t('shopproductaddnew.productCategory')">
              <select name="category" v-model="productCategory">
                <optgroup v-for="(subCategory, categoryName, index) in this.category"
                  :data-stateid="subCategory.categoryId" :label="categoryName" :key="index">
                  <option smartselect v-for="(subCategoryItem, index) in subCategory"
                    :value="subCategoryItem.subCategoryId" :key="index">
                    {{ subCategoryItem.subCategoryName }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>

            <f7-list-input :label="$t('common.name')" floating-label type="textarea" resizable name="name"
              :placeholder="$t('common.name')" :value="this.name" @input="productName = $event.target.value"
              :info="$t('common.nameInfo') + (80 - this.productName.length)" required validate maxlength="80"
              minlength="15" clear-button></f7-list-input>
            <f7-list-item checkbox :title="$t('shopproductaddnew.makeThePriceNegotiable')" name="negotiable"
              :checked="this.product.negotiable" @change="negotiable = $event.target.checked"
              :text="$t('shopproductaddnew.selectPriceNegotiable')"></f7-list-item>
            <!-- <f7-row no-gap>
            <f7-col width="60" medium="75">-->
            <li class="item-content item-input">
              <div class="item-media">
                {{ this.product.currencyCode }}
              </div>
              <div class="item-inner">
                <div v-if="this.negotiable === true" class="item-title item-label">
                  {{ $t("shopproductaddnew.displayedPrice") }}
                </div>
                <div v-else class="item-title item-label">
                  {{ $t("shopproductaddnew.productPrice") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" name="productPrice" min="0" :value="this.productPrice"
                    @input="productPrice = $event.target.value" pattern="[0-9]*" required validate />
                  <div v-if="!this.productPrice && this.negotiable === false" class="item-input-info">
                    {{ $t("shopproductaddnew.pleaseEnterThe") }}
                  </div>
                  <div v-if="this.negotiable === true" class="item-input-info">
                    {{ $t("shopproductaddnew.pleaseEnterThe") }}
                  </div>
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
            <li class="item-content item-input" v-if="this.negotiable === true">
              <div class="item-media">
                {{ this.product.currencyCode }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("shopproductaddnew.minimumPrice") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" name="minimumPrice" min="0" :max="this.productPrice" :value="this.minimumPrice"
                    @input="minimumPrice = $event.target.value" pattern="[0-9]*" required validate />
                  <div v-if="this.negotiable === true" class="item-input-info">
                    {{ $t("shopproductaddnew.pleaseEnterThe") }}
                  </div>
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
            <!-- </f7-col>
              <f7-col width="40" medium="25">

                <f7-list-input
                  :label="$t('shopproductedit.perUnit')"
                  type="text"
                  min="0"
                  name="unit"
                  :placeholder="$t('shopproductedit.kglunitm')"
                  :value="unit"
                  @input="unit = $event.target.value"
                  error-message="Enter unit"
                  required
                  validate
                  clear-button
                ></f7-list-input>
              </f7-col>
            </f7-row>-->
            <f7-list-input :label="$t('shopproductaddnew.quantityInStock')" floating-label type="number" min="1"
              pattern="[0-9]*" name="inventory" :placeholder="$t('shopproductaddnew.quantityInStock')"
              :value="this.inventory" @input="inventory = $event.target.value" required validate
              clear-button></f7-list-input>

            <f7-block-title class="margin-top-half">{{
              $t("common.description")
            }}</f7-block-title>
            <f7-text-editor :label="$t('common.description')" :placeholder="$t('common.descriptionInfo')"
              :value="this.description" name="description" @texteditor:change="(v) => (description = v)" resizable :style="f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
                " />
            <f7-block class="align-items-center">
              <f7-list-input type="file" class="productImages"
                accept=".jpg, .jpeg, .png, .pdf, image/*, .doc, .docx, .xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                capture style="display: none" :value="productImages" @input="productImages = $event.target.value"
                @change="onFileChange($event)" multiple></f7-list-input>
              <swiper-container v-if="this.imageUrlList.length > 0" :params="{
                slidesPerView: 'auto',
                spaceBetween: 5,
                disableOnInteraction: true,
              }">
                <swiper-slide v-for="(item, index) in this.imageUrlList" :key="index" style="width: 150px; height: 155px">
                  <img :src="item.url" width="150" height="150" max-height="100%" class="margin-horizontal-half" />
                  <f7-link raised :style="{
                    position: 'absolute',
                    right: '-8px',
                    top: '-2px',
                    color: 'inherit',
                  }" icon-f7="multiply_circle_fill" @click="removeImage(index)"></f7-link>
                </swiper-slide>
                <swiper-slide style="width: 150px; height: 150px">
                  <div href="#" class="dropbox margin-left-half" @click="uploadedFile()">
                    <f7-link icon-size="33" class="margin-top" icon-ios="f7:photo_on_rectangle"
                      icon-aurora="f7:photo_on_rectangle" icon-md="material:add_to_photos"
                      :text="$t('shopproductaddnew.addPhotos')"></f7-link>
                  </div>
                </swiper-slide>
              </swiper-container>
              <div v-else href="#" class="dropbox" width="auto" outline novalidate @click="uploadedFile()">
                <f7-link icon-size="50" icon-ios="f7:photo_on_rectangle" icon-aurora="f7:photo_on_rectangle"
                  icon-md="material:add_to_photos" :text="$t('shopproductaddnew.addPhotos')"></f7-link>
              </div>
            </f7-block>
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom bg-color="red">
          <f7-link raised fill large ripple class="link buttons" @click="saveNewProduct()" strong text-color="white">{{
            $t("common.update") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import categories from "../js/data/categories.json";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  data() {
    return {
      theme,
      f7,
      categories,
      productCategory: JSON.parse(window.localStorage.getItem("product")).category,
      negotiable: true,
      productName: "",
      productPrice: "",
      minimumPrice: "",
      unit: "",
      productImages: [],
      inventory: "",
      description: "",
      userrecipient: "",
      recipientCountry: "",
      deliveryCities: [],
      cityId: "",
      cityName: "",
      stateId: "",
      stateName: "",
      countryId: "",
      countryName: "",
      phonecode: "",
      paymentCurrency: "",
      product: JSON.parse(window.localStorage.getItem("product")),
      state: "",
      city: JSON.parse(window.localStorage.getItem("product")).city,
      url: "",
      imageUrlList: JSON.parse(window.localStorage.getItem("product")).images,
      imageList: [],
    };
  },
  mounted() {
    const self = this;

    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }
    this.product = JSON.parse(window.localStorage.getItem("product"));
    self.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
  },

  methods: {
    uploadedFile() {
      const self = this;

      setTimeout(() => {
        f7.$(".productImages input").off("click").click();
      }, 0);
    },

    onFileChange(event) {
      const self = this;
      const uploadedFiles = event.target.files || event.dataTransfer.files;
      console.log(uploadedFiles);
      if (!uploadedFiles.length) return;
      this.createImage(uploadedFiles);
    },
    createImage(file) {
      const self = this;

      for (const element of file) {
        const reader = new FileReader();
        console.log(element.name);
        reader.onload = (event) => {
          this.imageUrlList.push({
            id: f7.utils.id("xxxx-xxxx-xxxx-xxxx"),
            name: element.name,
            url: event.target.result,
            sizekb: element.size / 1000 + "kb",
            link: URL.createObjectURL(element),
          });
        };
        reader.readAsDataURL(element);
      }
      window.localStorage.setItem("productImages", JSON.stringify(this.imageUrlList));
      console.log(this.imageUrlList);
    },

    removeImage(index) {
      const self = this;

      this.imageUrlList.splice(index, 1);
    },

    getStateInfo() {
      const self = this;
    },

    async saveNewProduct() {
      const self = this;
      window.localStorage.setItem(
        "product",
        JSON.stringify({
          id: this.product.id,
          images: this.imageUrlList,
          description: this.description,
          category: this.productCategory,
          city: this.deliveryCities,
          inventory: this.inventory,
          minimumPrice: this.minimumPrice,
          productPrice: this.productPrice,
          productName: this.productName,
          negotiable: this.negotiable,
          currencyCode: this.product.currencyCode,
        })
      );
      this.f7router.navigate(`/product/${this.product.productName}`);
    },
  },

  computed: {
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "recipient",
      "userrecipientList",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    category() {
      return _.groupBy(this.categories, "categoryName");
    },
    currentCategory() {
      return _.findBy(this.categories, "subCategoryId");
    },
  },
};
</script>
