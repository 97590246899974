<template>
  <f7-page
    :infinite-preloader="showPreloader"
    infinite
    :infinite-distance="50"
    @infinite="loadMore"
  >
    <f7-navbar
      sliding
      no-shadow
      :back-link="$t('common.backButton')"
      :back-link-show-text="false"
    >
      <f7-nav-title>{{ $t("walletdetail.myCampaigns") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link
          class="searchbar-enable"
          data-searchbar=".searchbar-demo"
          icon-ios="f7:search"
          icon-aurora="f7:search"
          icon-md="material:search"
        ></f7-link>
        <f7-link
          popover-open=".sortingPopup"
          icon-ios="f7:sort_up"
          icon-aurora="f7:sort_up"
          icon-md="material:filter_list"
        ></f7-link>
      </f7-nav-right>
      <f7-searchbar
        class="searchbar-demo"
        :backdrop="false"
        :placeholder="$t('common.search')"
        expandable
        search-container=".search-list"
        search-item=".campaignTitleCard"
        search-in=".campaignTitle"
        search-group-title=".list-group-title"
        hide-groups:false
        :disable-button="!theme.aurora"
        clear-button
      >
      </f7-searchbar>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card
          outline
          header-divider
          class="demo-facebook-card campaignTitleCard skeleton-text skeleton-effect-blink"
          style="padding-bottom: 100px !important"
          v-for="n in 6"
          :key="n"
          v-if="!this.myWallets"
        >
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar padding-right">
              <f7-badge
                slot="media"
                style="
                  height: 40px;
                  width: 40px;
                  font-size: 16px;
                  border-radius: 50%;
                  background-color: light-gray;
                "
                >RS</f7-badge
              >
            </div>
            <div class="campaignTitle demo-facebook-name">$t("common.name")</div>
            <div class="demo-facebook-date">$t("common.tag")</div>
          </f7-card-header>
          <f7-card-content :padding="false" style="width: auto; height: 400px">
            <span color="light-gray" style="height: auto; width: 400px">RS</span>
          </f7-card-content>
          <f7-list dividers-ios strong-ios accordion-list media-list>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray">
                    {{ $t("common.collected") }}
                  </div>
                  <div class="item-title" style="font-weight: bold; color: gray">
                    500 EUR
                    <f7-badge color="gray">20</f7-badge>
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="color: gray">
                    {{ $t("common.balance") }}
                  </div>
                  <div class="item-title" style="font-weight: bold; color: gray">
                    2500 EUR
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="color: gray">
                    {{ $t("common.target") }}
                  </div>
                  <div class="item-title" style="font-weight: bold; color: gray">
                    2000 EUR
                  </div>
                </div>
              </div>
            </li>
          </f7-list>
          <f7-button
            raised
            fill
            large
            style="border-radius: 0px"
            class="no-margin-horiztontal buttons"
            color="gray"
            >{{ $t("common.seeDetail") }}</f7-button
          >
        </f7-card>

        <div v-else-if="this.myWallets === '' || this.myWallets.length < 1">
          <span class="padding-left" style="display: flex; justify-content: center">{{
            $t("common.nothingFound")
          }}</span>
          <a
            href="#"
            @click="createNew()"
            style="display: flex; justify-content: center"
            class="createCampaign"
          >
            <f7-chip
              color="red"
              outline
              :text="$t('walletcreatenew.createMyCampaign')"
              href="#"
            >
              <template #media>
                <f7-icon
                  color="red"
                  ios="f7:plus_circle_fill"
                  aurora="f7:plus_circle_fill"
                  md="material:add_circle"
                >
                </f7-icon>
              </template>
            </f7-chip>
          </a>
        </div>
        <f7-list dividers-ios class="searchbar-not-found">
          <f7-list-item
            :title="$t('common.nothingFound')"
            style="font-size: 18px; color: red"
          ></f7-list-item>
        </f7-list>
        <f7-list
          dividers-ios
          class="search-list searchbar-found"
          accordion-list
          media-list
          v-for="(walletGroupList, walletGroup, index) in this.myWallets"
          :key="index"
          v-if="!!this.myWallets || this.myWallets.length >= 1"
        >
          <f7-list-item
            v-if="walletGroup !== true || walletGroup !== false"
            :title="walletGroup"
            group-title
            text-color="primary"
            style="font-size: 18px"
          ></f7-list-item>

          <f7-card
            class="demo-facebook-card campaignTitleCard"
            v-for="(wallet, index) in walletGroupList"
            :key="index"
          >
            <f7-card-header class="no-border">
              <div class="demo-facebook-avatar padding-right">
                <img
                  v-if="!!wallet.logo && wallet.logo !== null"
                  :src="`data:image/png;base64,${wallet.logo}`"
                  width="40"
                  height="40"
                  style="border-radius: 50%"
                  alt="campaign logo"
                />
                <f7-badge
                  v-else
                  slot="media"
                  color="auto"
                  style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%"
                  >{{ $filtersGetInitial.getInitial(wallet.name) }}</f7-badge
                >
              </div>
              <div
                class="campaignTitle demo-facebook-name"
                style="vertical-align: middle"
              >
                {{ wallet.name }}
              </div>
              <div class="demo-facebook-date campaignTitle">
                {{ wallet.tag }}
              </div>
            </f7-card-header>

            <f7-card-content :padding="false">
              <swiper-container
                :pagination="isTablet ? false : true"
                :slidesPerView="1"
                :spaceBetween="0"
                :centeredSlides="true"
              >
                <f7-photo-browser
                  :photos="wallet.walletImageList"
                  ref="popup"
                ></f7-photo-browser>
                <swiper-slide
                  v-for="(imageUrl, index) in wallet.walletImageList"
                  :key="index"
                  :style="{
                    background:
                      'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
                    width: '100%',
                    height: '400px',
                  }"
                  class="align-content-center"
                  v-if="imageUrl !== null"
                >
                  <a
                    link="#"
                    @click="$refs.popup.open()"
                    style="display: flex; align-items: center; justify-content: center"
                  >
                    <img
                      :src="imageUrl.url"
                      :style="{
                        display: 'block',
                        margin: 'auto',
                        height: 'auto',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        width: imageUrl.name === 'QRImage' ? '' : '100%',
                      }"
                      :alt="imageUrl.name"
                    />
                  </a>
                </swiper-slide>
              </swiper-container>
            </f7-card-content>
            <f7-list-item
              :title="$t('walletdetail.fundraiser')"
              :after="wallet.type === 'charities' ? wallet.name : wallet.ownerName"
            ></f7-list-item>
            <f7-list-item
              :title="$t('walletdetail.collected')"
              :after="
                $filtersToCurrency.toCurrency(
                  (wallet.collectedAmount || 0).toFixed(2),
                  null,
                  wallet.currencyCode
                )
              "
            ></f7-list-item>
            <f7-list-item
              accordion-item
              :title="$t('common.description')"
              :padding="false"
            >
              <f7-accordion-content>
                <f7-block>
                  <p v-html="wallet.description"></p>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
            <f7-button
              fill
              large
              class="card_button buttons"
              color="red"
              @click="viewDetail(wallet)"
              >{{ $t("common.seeDetail") }}</f7-button
            >
          </f7-card>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
    <div style="padding-bottom: 100px !important"></div>
    <f7-popover
      class="sortingPopup"
      :backdrop="false"
      :params="{
        backdrop: false,
        closeOnEscape: true,
      }"
    >
      <f7-list dividers-ios strong-ios media-list>
        <f7-list-group>
          <f7-block-title medium>{{
            $t("walletdetail.groupCampaignsBy")
          }}</f7-block-title>

          <f7-list-item
            v-for="criteria in this.groupingCriteria"
            :key="criteria.index"
            radio
            href="#"
            :value="criteria.value"
            :title="criteria.name"
            @change="
              (e) => {
                if (e.target.checked) groupCriteria = criteria.value;
              }
            "
            name="groupCriteria"
          ></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-popover>
    <f7-fab
      position="center-bottom createCampaign"
      slot="fixed"
      :text="$t('walletcreatenew.createMyCampaign')"
      color="red"
      href="#"
      @click="createNew()"
    >
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<style>
.demo-facebook-card .card-header {
  display: block;
  padding: 10px;
}

.demo-facebook-card .demo-facebook-avatar {
  float: left;
}

.demo-facebook-card .demo-facebook-name {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 500;
}

.demo-facebook-date {
  margin-top: 8px;
  font-size: medium;
  color: #8e8e93;
}

.demo-facebook-card .card-content img {
  display: block;
}

.demo-facebook-card .card-content-padding {
  padding: 15px 10px;
}

.demo-facebook-card .card-content-padding .likes {
  color: #8e8e93;
}
</style>
<style scoped>
.walletLink {
  color: inherit;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7NavRight,
  f7Link,
  f7Searchbar,
  f7Chip,
  f7List,
  f7ListItem,
  f7ListGroup,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7AccordionContent,
  f7Block,
  f7Button,
  f7Popover,
  f7BlockTitle,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";

import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { walletMixin } from "../js/mixins/walletMixins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7NavRight,
    f7Link,
    f7Searchbar,
    f7Chip,
    f7List,
    f7ListItem,
    f7ListGroup,

    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,

    f7AccordionContent,
    f7Block,
    f7Button,
    f7Popover,
    f7BlockTitle,
    f7Fab,
    f7Icon,
  },
  mixins: [calculateTransferPayment, walletMixin],
  data() {
    return {
      theme,
      f7,
      theme,
      searchWallet: "",
      visibleItem: 6,
      allowInfinite: true,
      showPreloader: false,
      groupCriteria: "categoryName",
      groupingCriteria: [
        { value: "categoryName", name: this.$t("walletdetail.categoryName") },
        {
          value: "countryName",
          name: this.$t("walletdetail.country"),
        },
        { value: "thirdPartyCampaign", name: this.$t("walletdetail.thirdPartyCampaign") },
        { value: "currencyCode", name: this.$t("walletdetail.currency") },
      ],
    };
  },
  mounted() {
    const self = this;
    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    // if (f7.views.main.router.currentRoute.path.search(`/private/`) >= 0) {
    self.$store.dispatch("wallet/getPrivateWalletList", this.visibleItem);
    // } else {
    //   self.$store.dispatch("wallet/getPublicWalletList", this.visibleItem);
    // }
    if (this.country === "" || !this.country) {
      self.$store.dispatch("location/getCountry");
    }
    this.countryId = this.currentUserCountry.countryId;

    // if (this.isTablet == true) {
    //   f7.$("div.page-content").attr("style", "--f7-page-navbar-offset:0px");
    // }
  },
  computed: {
    ...mapState("wallet", [
      "wallet",
      "projectCategories",
      "walletOwner",
      "walletList",
      "privateWalletList",
      "publicWalletList",
      "walletTransaction",
      "thirdPartyAllowedWalletList",
      "privateWalletTransactionList",
      "publicWalletTransactionList",
      "walletTransactionList",
      "walletImages",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "searchedUser"]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    myWallets() {
      const currentWalletList = this.privateWalletList;
      // f7.views.main.router.currentRoute.path.search(`/private/`) >= 0
      // ? this.privateWalletList
      // : this.publicWalletList;
      return (
        _.chain(currentWalletList.slice())
          .map((wallet) =>
            _.assign(
              wallet,
              {
                categoryName: _.find(this.projectCategories, [
                  "id",
                  parseInt(wallet.categoryId),
                ]).categoryName,
                // daysLeft: (
                //   (new Date(wallet.endDate) - currentDate) /
                //   (1000 * 3600 * 24)
                // ).toFixed(0),
                countryName: _.find(this.country, ["countryCode2", wallet.countryCode])
                  .countryName,
              },
              wallet
            )
          )
          .forEach((wallet) =>
            _.forEach(
              wallet.walletImageList,
              (item) => {
                _.assign(item, {
                  url: `data:image/png;base64,${item.image}`,
                });
              },
              wallet
            )
          )
          // .orderBy([this.sortCriteria], ["desc"])
          .groupBy(this.groupCriteria)
          .value()
      );
    },

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
