<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title>{{ $t("shopdetail.createMyShop") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title large class="margin-left">{{
          $t("shopdetail.fillInYour")
        }}</f7-block-title>
        <f7-list dividers-ios strong-ios media-list form-store-data id="createNewShop">
          <f7-list-group>
            <f7-list-item
              class="shopCategory"
              smart-select
              :smart-select-params="{
                openIn: 'popup',
                popupSwipeToClose: true,
                closeOnSelect: true,
                searchbar: true,
                searchbarPlaceholder: $t('shopdetail.selectCategory'),
              }"
              :title="$t('shopdetail.myShopCategory')"
            >
              <select name="Category" v-model="shopCategory" required validate>
                <option
                  smartselect
                  v-for="(category, index) in this.categories"
                  :value="category.id"
                  :key="index"
                >
                  {{ category.name }}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="$t('common.name')"
              floating-label
              type="text"
              name="name"
              :placeholder="$t('common.name')"
              :value="name"
              @input="name = $event.target.value"
              :error-message="$t('common.nameInfo')"
              :info="
                $t('shopdetail.enterShopNameCount', {
                  charLeft: 20 - name.length,
                })
              "
              required
              validate
              maxlength="20"
              minlength="6"
              clear-button
            ></f7-list-input>

            <f7-list-input
              :label="$t('common.description')"
              floating-label
              type="textarea"
              :value="tag"
              name="tag"
              @input="tag = $event.target.value"
              resizable
              required
              validate
              max="80"
              :error-message="$t('common.descriptionInfo')"
            />

            <f7-list-input
              :label="$t('common.phoneNumber')"
              type="tel"
              input-id="phoneNumber"
              name="phoneNumber"
              :value="telephone"
              @input="telephone = $event.target.value"
              :error-message="$t('common.providePhoneNumber')"
              required
              validate
            ></f7-list-input>
            <f7-list-item
              :title="$t('common.selectCountry')"
              class="selectCountry"
              smart-select
              :smart-select-params="{
                openIn: 'page',
                searchbar: true,
                searchbarPlaceholder: $t('common.findCountry'),
                closeOnSelect: true,
              }"
              style="display: none"
            >
              <select name="country" v-model="selectedCountry">
                <optgroup
                  v-for="(countryList, continentName, index) in this.countries"
                  :label="continentName"
                  :key="index"
                >
                  <option
                    v-for="country in countryList"
                    :key="country.id"
                    :value="country.countryCode2"
                    :data-option-image="
                      'https://flagcdn.com/' + country.countryCode2.toLowerCase() + '.svg'
                    "
                    width="24"
                  >
                    {{ country.name }} {{ country.countryCode2 }} +{{ country.dialCode }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>

            <f7-list-input
              :label="$t('common.email')"
              floating-label
              type="email"
              name="email"
              :placeholder="$t('common.email')"
              :value="email"
              @input="email = $event.target.value"
              :error-message="$t('common.provideEmail')"
              required
              validate
              clear-button
            ></f7-list-input>

            <f7-list-input
              :label="$t('common.address')"
              floating-label
              type="textarea"
              name="address"
              resizable
              :placeholder="$t('common.address')"
              :value="address"
              @input="address = $event.target.value"
              :error-message="$t('common.provideAddress')"
              required
              validate
              clear-button
            ></f7-list-input>

            <f7-block-title medium class="margin-left">{{
              $t("shopdetail.addShopOther")
            }}</f7-block-title>
            <f7-list-input
              :value="about"
              name="about"
              floating-label
              @texteditor:change="(v) => (about = v)"
              resizable
              required
              validate
              :label="$t('shopdetail.aboutShop', { myshop: this.name })"
              type="texteditor"
              :mode="f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'"
              :style="
                f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
              "
            />
            <f7-list-input
              :label="$t('shopdetail.myShopPolicies')"
              floating-label
              :value="policies"
              name="ShopPolicies"
              @texteditor:change="(v) => (policies = v)"
              resizable
              required
              validate
              type="texteditor"
              :mode="f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'"
              :style="
                f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
              "
            />

            <myDocs :type="this.getType"></myDocs>
          </f7-list-group>
        </f7-list>

        <f7-toolbar
          tabbar
          bottom
          no-hairline
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        >
          <f7-link
            raised
            fill
            large
            ripple
            class="link button buttons"
            @click="createMyShop()"
            strong
            text-color="white"
            >{{ $t("shopdetail.createMyShop") }}</f7-link
          >
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Tabs,
  f7Tab,
  f7BlockTitle,
  f7List,
  f7ListItem,
  f7ListGroup,
  f7ListInput,
  f7Button,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import { cordovaCamera } from "../js/mixins/cordovaCamera.js";
import { telephoneUtils } from "../js/mixins/telephone.js";
import { attachDocs } from "../js/mixins/attachDocs.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Tabs,
    f7Tab,
    f7BlockTitle,
    f7List,
    f7ListItem,
    f7ListGroup,
    f7ListInput,
    f7Button,
  },
  mixins: [cordovaCamera, telephoneUtils, attachDocs],
  data() {
    return {
      theme,
      f7,
      theme,
      shopCategory: "",
      name: "",
      tag: "",
      address: "",
      email: "",
      telephone: "",
      about: `<p class="" style="text-align: left;"><span class=""><sub style=""><b>${this.$t(
        "shopdetail.shopStory"
      )} &nbsp;&nbsp;</b></sub></span></p><p class="" style="text-align: left;"><sup><b style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); background-color: var(--f7-text-editor-bg-color); font-family: var(--f7-font-family);" class="">${this.$t(
        "shopdetail.shopOwner"
      )}</b></sup></p><p class="" style="text-align: left;"><sup><b style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-family: var(--f7-font-family); background-color: var(--f7-text-editor-bg-color);">${this.$t(
        "shopdetail.shopTeam"
      )}</b><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family); background-color: var(--f7-text-editor-bg-color);">&nbsp;&nbsp;</span></sup></p><p class="" style="text-align: left;"><b><span class=""><sup>${this.$t(
        "common.phoneNumber"
      )}&nbsp; &nbsp;</sup></span></b></p><p class="" style="text-align: left;"><b><span class=""><sup>${this.$t(
        "common.email"
      )}&nbsp; &nbsp;</sup></span></b></p><p class="" style="text-align: left;"><b><span class=""><sup>${this.$t(
        "shopdetail.shopSocialMedia"
      )}&nbsp; &nbsp;</sup></span></b></p><p class="" style="text-align: left;"><ul><li><sup><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-family: var(--f7-font-family);">Facebook:</span><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family);">&nbsp;&nbsp;</span></sup></li><li><sup><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family);">Youtube:&nbsp;&nbsp;</span></sup></li><li><sup><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family);">Instagram:</span></sup></li><li><sup><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family);">WhatsApp:</span></sup></li><li><sup><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family);">Tweeter:</span></sup></li><li><sup><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family);">${this.$t(
        "shopdetail.other"
      )}</span></sup></li></ul></p><p class="" style="text-align: left;"><b><span class=""><sup>${this.$t(
        "common.address"
      )}</sup></span><sup class="">:&nbsp;&nbsp;</sup></b>&nbsp; </p><p class="" style="text-align: left;"> <sup><b style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-family: var(--f7-font-family); background-color: var(--f7-text-editor-bg-color); text-align: center;" class="">${this.$t(
        "shopdetail.other"
      )}</b><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family); background-color: var(--f7-text-editor-bg-color); text-align: center;" class="">&nbsp;</span></sup><span style="color: var(--f7-text-editor-text-color); font-size: var(--f7-text-editor-font-size); font-weight: var(--f7-text-editor-font-weight); font-family: var(--f7-font-family); vertical-align: sub; background-color: var(--f7-text-editor-bg-color); text-align: center;" class="">&nbsp;</span></p><ul> </ul> <ul class="tabs-content"></ul>`,
      policies: `<p class="" style="text-align: left;"><span class=""><b style=""><sub>${this.$t(
        "shopdetail.shopShipmentPolicies"
      )}</sub></b></span></p><p class="" style="text-align: left;"><span class=""><b style=""><sub>${this.$t(
        "shopdetail.shopReturnPolicies"
      )}</sub></b></span></p><p class="" style="text-align: left;"><span class=""><b style=""><sub>${this.$t(
        "shopdetail.faqs"
      )}</sub></b></span></p><p style="text-align: left;" class=""><b><sub class="">${this.$t(
        "shopdetail.other"
      )}</sub></b></p><ul> </ul> <ul class="tabs-content"></ul>`,
      fileInput: "",
      shopImages: "",
      files: "",
      forms: "",
      selectedCountry: "",
      getType: "shop",
    };
  },

  mounted() {
    this.selectedCountry = this.$keycloak.authenticated
      ? this.profile.countryCode
      : this.currentUserCountry.countryCode2;
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.profile) {
      this.address =
        this.profile.address.street_address +
        ", " +
        this.profile.address.postal_code +
        " " +
        this.profile.address.locality +
        ", " +
        this.profile.address.region +
        " " +
        this.profile.address.country;
      this.email = this.profile.email;
      this.telephone = this.profile.phoneNumber;
    }

    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    this.paymentCurrency = this.profile.currencyCode;
    f7.$(".shopCategory .smart-select .item-after").html(
      this.$t("shopdetail.selectCategory")
    );
    if (this.isTablet == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
  },
  methods: {
    // saveShopDetails() {
    //   const self = this;
    //   console.log(f7.input.validateInputs("#checkShopdetails"));
    //   if (f7.input.validateInputs("#checkShopdetails")) {
    //     f7.tab.show("#addShopContact", true);
    //   }
    // },

    // saveContactDetails() {
    //   const self = this;
    //   if (f7.input.validateInputs("#checkShopContacts")) {
    //     f7.tab.show("#uploadDocs", true);
    //   }
    // },
    async createMyShop() {
      if (f7.input.validateInputs("#createNewShop")) {
        f7.preloader.show("multi");
        const newShop = {
          name: this.name,
          ownerId: this.profile.sub,
          ownerName: this.profile.name,
          categoryId: this.shopCategory,
          number: "",
          about: this.about,
          tag: this.tag,
          policies: this.policies,
          currencyCode: this.shopCountry.currencyCode,
          walletId: this.walletId,
          address: this.address,
          email: this.email,
          telephone: this.telephone,
          // created: "",
          // modified: "",
          isActive: true,
          shopImagesList: [],
        };

        this.forms = await new FormData();
        await this.forms.append("shop", JSON.stringify(newShop));
        for (const element of this.documentsUrl) {
          await this.forms.append("files", element.file);
        }
        await this.$store.dispatch("shop/createShop", this.forms);
        await this.$store.dispatch("auth/clearDocumentsUrl");
        setTimeout(() => {
          this.f7router.navigate(`/shop/${this.shop.id}`);
          f7.preloader.hide();
        }, 300);
      }
    },
  },

  computed: {
    ...mapState("shop", ["categorylist", "categories", "shop"]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "document",
      "documentsUrl",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    category() {
      return _.groupBy(this.categories, "categoryName");
    },
    uniqCategories() {
      return _.uniqBy(this.categories, "categoryId");
    },
    countries() {
      return _.groupBy(this.country, "continentName");
    },
    shopCountry() {
      return _.find(this.country, ["countryCode2", this.selectedCountry]);
    },
  },
};
</script>
