<template>
  <f7-page class="accountWallet_page">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false">
      </f7-nav-left>
      <f7-nav-title v-if="this.apiWallet">{{ this.apiWallet.tag }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <templatePreloader v-if="!this.apiWallet"></templatePreloader>
        <f7-card outline header-divider v-if="this.apiWallet">
          <f7-card-header class="grid grid-rows-1">
            <span>{{ this.apiWallet.tag }}
              <f7-link v-if="!this.apiWallet.shared" icon-ios="f7:pencil" icon-md="material:edit" href="#"
                @click="editApiWallet(apiWallet)" style="position: absolute; right: 8px; top: 8px"></f7-link> </span><span
              style="font-size: small">№: {{ apiWallet.id }}</span>
          </f7-card-header>
          <f7-card-content class="grid grid-rows-2">
            <span>
              {{ $t("common.balance")
              }}<span style="font-size: x-large; font-weight: 500; margin-left: 8px">
                {{
                  $filtersToCurrency.toCurrency(
                    this.apiWallet.balance,
                    null,
                    this.apiWallet.currency
                  )
                }}</span></span>
            <span>
              {{ $t("common.availaible_balance")
              }}<span style="font-size: large; font-weight: 300; margin-left: 8px">
                {{
                  $filtersToCurrency.toCurrency(
                    this.apiWallet.balance_available,
                    null,
                    this.apiWallet.currency
                  )
                }}</span></span>
          </f7-card-content>

          <transactionCharts v-if="this.structuredTransactionList.length > 0"></transactionCharts>
          <span :class="!this.apiWallet.shared && this.apiWallet.balance > 0
              ? 'grid  grid-cols-2'
              : ''
            ">
            <f7-button raised large fill @click="transferFrom(this.apiWallet)" style="border-bottom-left-radius: 16px"
              class="no-margin-horizontal" color="primary" v-if="!this.apiWallet.shared && this.apiWallet.balance > 0">{{
                $t("common.tranfer") }}</f7-button>
            <f7-button raised large fill @click="selectProjectToContribute(apiWallet)" :class="this.apiWallet.shared || this.apiWallet.balance == 0
                ? 'card_button'
                : 'no-margin-horizontal'
              " color="red" style="border-bottom-right-radius: 16px"><span v-if="!this.apiWallet.shared">{{
    $t("common.topUp") }}</span><span v-else>{{ $t("common.contribute") }}</span></f7-button></span>
        </f7-card>
        <div class="not_sticky_with_content">
          <getTransactions v-if="this.transactionList.length > 0"></getTransactions>
        </div>
      </div>
      <div class="right_layout" style="max-width: auto">
        <div class="sticky with_content">
          <getTransactions v-if="this.transactionList.length > 0"></getTransactions>
        </div>
      </div>
    </div>
  </f7-page>
</template>
<style scoped>
.mainToolbar {
  display: none !important;
}
</style>
<script>
import {
  f7List,
  f7ListGroup,
  f7BlockTitle,
  f7Accordion,
  f7BlockHeader,
  f7ListItem,
  f7ListInput,
  f7Button,
  theme,
  f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { account } from "../js/mixins/account.js";
import getTransactions from "../components/modules/transactions.vue";
import transactionCharts from "../components/modules/charts.vue";
import templatePreloader from "../components/template_preloader.vue";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [account],
  components: {
    f7List,
    f7ListGroup,
    f7BlockTitle,
    f7Accordion,
    f7BlockHeader,
    f7ListItem,
    f7ListInput,
    f7Button,
    getTransactions,
    transactionCharts,
    navbarright: Navbarright,
    templatePreloader,
  },
  data() {
    return {
      theme,
      f7,
    };
  },
  mounted() {
    if (this.userAccount === null && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.$keycloak.authenticated) {
      this.$store.dispatch("apimoney/getApiWalletTransactions", {
        walletId: this.f7route.params.wallet_id,
        start_date: null,
        end_date: null,
      });
      this.$store.dispatch("apimoney/getApiWallet", {
        walletId: this.f7route.params.wallet_id,
      });
    }
    if ((this.apiWallet !== "") & (this.apiWallet.groupId !== "")) {
    }

    console.log("transactionList", this.transactionList);
  },

  methods: {},
  computed: {
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "standard",
      "business",
      "accountList",
      "transactionList",
      "apiWallet",
      "structuredTransactionList",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "documentsUrl", "document"]),
    ...mapState("account", ["userAccount"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    selectedWallet() {
      return;
    },
  },
};
</script>
