<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar
      title
      no-shadow
      :back-link="$t('common.backButton')"
      :back-link-show-text="false"
      >{{
        $t("common.edit") + " " + profile.given_name + " " + profile.family_name
      }}</f7-navbar
    >
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list list media-list form strong-ios dividers-ios id="userform1">
          <f7-list-group>
            <f7-list-item :title="$t('profile.myPersonalInfo')" divider></f7-list-item>
            <!-- <f7-list-group> -->
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 75%; color: gray">
                  <div class="item-title">
                    {{ $t("common.firstName") }}
                  </div>
                  <div class="item-title">
                    {{ $t("common.lastName") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title">{{ profile.given_name }}</div>
                  <div class="item-title">{{ profile.family_name }}</div>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 75%; color: gray">
                  <div class="item-title">
                    {{ $t("common.dateOfBirth") }}
                  </div>
                  <div class="item-title">
                    {{ $t("profile.myAvocashAccountStatus") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title">{{ profile.dateOfBirth }}</div>
                  <div v-if="profile.publicStatus == true" class="item-title">
                    {{ $t("profile.accountIsPublic")
                    }}<f7-toggle
                      class="togglePublic"
                      checked
                      value="0"
                      color="green"
                      @Click="toggle()"
                    ></f7-toggle>
                  </div>
                  <div v-if="profile.publicStatus == false" class="item-title">
                    {{ $t("profile.accountIsPublic")
                    }}<f7-toggle
                      class="togglePublic"
                      checked
                      value="1"
                      color="red"
                      @Click="toggle()"
                    ></f7-toggle>
                  </div>
                </div>
              </div>
            </li>
            <f7-list-input
              :label="$t('common.phoneNumber')"
              type="tel"
              name="phone"
              input-id="phoneNumber"
              :value="profile.upn"
              readonly
            ></f7-list-input>
            <f7-list-input
              :label="$t('common.email')"
              type="email"
              name="email"
              :placeholder="$t('common.email')"
              :value="profile.email"
              @input="email = $event.target.value"
              required
              readonly
            ></f7-list-input>
            <f7-list-input
              :label="$t('common.country')"
              type="text"
              name="country"
              :value="profile.address.country"
              @input="country = $event.target.value"
              readonly
            ></f7-list-input>
            <f7-list-item
              smart-select
              class="userCity item-label"
              :smart-select-params="{
                closeOnSelect: true,
                openIn: 'popup',
                popupSwipeToClose: true,
                popupPush: true,
                searchbar: true,
                searchbarPlaceholder: $t('common.locality'),
              }"
              :title="$t('common.locality')"
            >
              <select
                id="stateid"
                name="city"
                v-model="usercity"
                @change="getStateInfo()"
              >
                <optgroup
                  v-for="(states, stateName, index) in destinationCity.stateList"
                  :data-stateid="states.stateId"
                  :label="states.stateName"
                  :key="index"
                >
                  <option
                    smartselect
                    v-for="citylist in states.cityList"
                    :value="citylist.cityName"
                    :data-cityname="citylist.cityName"
                    :key="citylist.cityId"
                  >
                    {{ citylist.cityName }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>
            <f7-list-input
              :label="$t('common.postCode')"
              floating-label
              type="text"
              name="postal_code"
              :placeholder="$t('common.postCode')"
              :value="profile.address.postal_code"
              @input="postal_code = $event.target.value"
              required
              validate
              clear-button
            ></f7-list-input>
            <f7-list-input
              :label="$t('common.addressLine') + ' ' + '1'"
              floating-label
              type="text"
              name="street_address"
              :placeholder="$t('common.address')"
              :value="profile.address.street_address"
              @input="street_address = $event.target.value"
              :error-message="$t('common.provideAddress')"
              required
              validate
              clear-button
            ></f7-list-input>
            <f7-list-input
              :label="$t('common.addressLine') + ' ' + '2'"
              floating-label
              type="text"
              name="addressLine2"
              :placeholder="$t('common.address')"
              :value="profile.address.addressLine2"
              @input="addressLine2 = $event.target.value"
              :error-message="$t('common.provideAddress')"
              validate
              clear-button
            ></f7-list-input>
          </f7-list-group>
        </f7-list>
      </div>
      <f7-toolbar
        tabbar
        bottom
        :style="{ bottom: 0, position: this.isTablet ? 'initial' : 'fixed' }"
      >
        <f7-link
          raised
          fill
          large
          ripple
          bg-color="red"
          class="link button button-fill buttons"
          @click="saveMyChanges()"
          strong
          text-color="white"
          >{{ $t("common.update") }}</f7-link
        >
      </f7-toolbar>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Toggle,
  f7ListInput,
  f7Toolbar,
  f7Link,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { telephoneUtils } from "../js/mixins/telephone.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7Toggle,
    f7ListInput,
    f7Toolbar,
    f7Link,
  },
  mixins: [telephoneUtils],

  data() {
    return {
      theme,
      f7,
      title: "",
      firstName: "",
      lastName: "",
      username: "",
      dateOfBirth: "",
      nationality: "",
      phone: "",
      backupPhoneNumber: "",
      messageApp: "",
      email: "",
      password: "",
      locality: "",
      street: "",
      region: "",
      postal_code: "",
      usercity: "",
      type: "",
      incomes: "",
      fiscal_out_france: false,
      citizen_us: false,
      fiscal_us: false,
      profession_category: "",
      selectedCountry: "",
      telephone: "",
    };
  },
  mounted() {
    this.selectedCountry = this.profile.countryCode;
    this.telephone = this.profile.upn;
    this.usercity = this.profile.address.locality;
    this.$store.dispatch("auth/getAdminToken");
    if (this.adminToken) {
      this.$store.dispatch("auth/getUserFromAdmin");
    }
    this.$store.dispatch("location/getCountryStates");
    this.userCity = this.profile.address.cityId;
    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    f7.$(".userCity .item-after").html(this.profile.address.locality);
    console.log("destinationCity", this.destinationCity);
  },

  computed: {
    ...mapState("auth", ["userregistrationdetail", "profile", "adminToken", "adminUser"]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),

    ...mapGetters("auth", [
      "userregistrationdetail",
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
    ]),
    currentUserCtry() {
      return this.destinationCity.find((country) => {
        country.countryCode2 == this.profile.countryCode;
      });
    },
  },
  methods: {
    toggle() {
      f7.toggle.create({
        el: ".togglePublic",
        on: {
          change() {
            f7.dialog.alert("Status is Public");
          },
        },
      });
    },

    getStateInfo() {
      this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
      const city = document.querySelector("#stateid");
      this.city = city.value;
      if (!this.city) {
        return;
      }
      this.stateId = parseFloat(
        city.options[city.selectedIndex].parentNode.dataset.stateid
      );
      this.stateName = city.options[city.selectedIndex].parentNode.label;
      this.cityName = city.options[city.selectedIndex].dataset.cityname;
    },
    saveMyChanges() {
      const adminUser = {
        id: "4b21e4eb-eb15-45a9-a151-f3efda80c0a3",
        createdTimestamp: 1581886495168,
        username: "+33644880701",
        enabled: true,
        totp: true,
        emailVerified: true,
        firstName: "Kalebe",
        lastName: "Kpan",
        email: "kalebe.kpan@gmail.com",
        attributes: {
          incomes: ["GREATER_THAN_4000_EUROS"],
          country: ["France"],
          messageApp: ["WhatsApp"],
          locality: ["Thiais"],
          fiscal_out_france: ["false"],
          dateOfBirth: ["1979-12-22"],
          locale: ["en"],
          profession_category: ["EXECUTIVE"],
          phoneNumber: ["0644880701"],
          currencyName: ["Euro"],
          nationality: ["FRA"],
          PIN: ["6042"],
          api_key: ["3LPYkEbAXx9bfsQy0O5TCsUY68H5S6HC59ysJnWzFFyHG98YCp"],
          citizen_us: ["false"],
          countryCode: ["FR"],
          street: ["70 Boulevard de Stalingrad"],
          backupPhoneNumber: [""],
          phoneCode: ["33"],
          phoneNumberVerified: ["true"],
          fiscal_us: ["false"],
          region: ["Ile de France"],
          postal_code: ["94320"],
          currencyCode: ["EUR"],
        },
        disableableCredentialTypes: [],
        requiredActions: ["update_user_locale"],
        notBefore: 0,
        access: {
          manageGroupMembership: true,
          view: true,
          mapRoles: true,
          impersonate: true,
          manage: true,
        },
      };

      this.$store
        .dispatch("auth/UpdateUserFromAdmin", {
          userId: this.profile.sub,
          username: this.username,
          phoneCode: this.phoneCode,
          phone_number: this.phone,
          email: this.email,
          // type: this.type,
          // type: 0,
          address: {
            street_address: this.street_address,
            addressLine2: this.addressLine2,
            postal_code: this.postal_code,
            locality: this.locality,
            region: this.region,
            countryId: this.countryId,
            country: this.country,
          },
        })
        .then((response) => {
          this.f7router.navigate("/dashboard/");
        });
    },
  },
};
</script>
