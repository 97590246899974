import axios from "axios";
// import base_url from "../base_url.json";
const path = import.meta.env.VITE_APP_URL + "payment";
// const payment = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      QRCode: "",
      QRCodeToClaim: "",
      QRCodeToCashOut: "",
      decodedQRCode: "",
      paymentTransaction: "",
      paymentTransactionList: "",
      paymentError: "",
      cardInfo: "",
      // Vendor Qr code creation and payment
      staticQRCode: "",
      vendorQRCodeList: "",
      qrCodeUser: "",
      qrTransaction: "",
      allQrTransactions: "",
      qrUser: "",
      qrCodeData: "",
    },
    getters: {},
    mutations: {
      GET_CARDINFO(state, cardInfo) {
        state.cardInfo = cardInfo;
      },
      MAKE_PAYMENT(state, paymentTransaction) {
        state.paymentTransaction = paymentTransaction;
      },
      GET_PAYMENT_ERROR(state, paymentError) {
        state.paymentError = paymentError;
      },
      VALIDATE_PAYMENT(state, paymentTransaction) {
        state.paymentTransaction = paymentTransaction;
      },
      GET_PAYMENT_TRANSACTION(state, paymentTransactionList) {
        state.paymentTransactionList = paymentTransactionList;
      },
      CREATE_QRCODE(state, QRCode) {
        state.QRCode = QRCode;
      },
      CREATE_QRCODETOCLAIM(state, QRCodeToClaim) {
        state.QRCodeToClaim = QRCodeToClaim;
      },
      CREATE_QRCODETOCASHOUT(state, QRCodeToCashOut) {
        state.QRCodeToCashOut = QRCodeToCashOut;
      },
      DECODE_QRCODE(state, decodedQRCode) {
        state.decodedQRCode = decodedQRCode;
      },
      // Vendor Qr code creation and payment
      GET_VENDOR_QRCODE(state, staticQRCode) {
        state.staticQRCode = staticQRCode;
      },
      GET_VENDOR_QRCODELIST(state, vendorQRCodeList) {
        state.vendorQRCodeList = vendorQRCodeList;
      },
      GET_QRCODE_TRANSACTIONLIST(state, allQrTransactions) {
        state.allQrTransactions = allQrTransactions;
      },
      SET_QRCODEDQTA(state, qrCodeData) {
        state.qrCodeData = qrCodeData;
      },
    },

    actions: {
      getCardInfo({ commit }, cardInfo) {
        commit("GET_CARDINFO", cardInfo);
      },

      setDecodedQRCode({ commit }, payload) {
        commit("DECODE_QRCODE", payload);
      },

      makePayment({ commit, dispatch }, payload) {
        axios({
          url: `${path}/make`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((paymentTransaction) => {
            commit("MAKE_PAYMENT", paymentTransaction);
            console.log(paymentTransaction);
            console.log(payload);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
            commit("GET_PAYMENT_ERROR", err.response.data);
            dispatch("account/loadPrimaryAccount", {
              root: true,
            });
            dispatch("account/loadBusinessAccount", {
              root: true,
            });
          });
      },

      validatePayment({ commit, dispatch }, payload) {
        axios({
          url: `${path}/validatePayment`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((paymentTransaction) => {
            console.log(paymentTransaction);
            commit("VALIDATE_PAYMENT", paymentTransaction);
            dispatch("transaction/loadPrimaryTransactionList", 25, {
              root: true,
            });
            dispatch("transaction/loadBusinessTransactionList", 25, {
              root: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      cancelPayment({ commit, dispatch }, payload) {
        axios({
          url: `${path}/cancel`,
          method: "PUT",
          params: {
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((paymentTransaction) => {
            console.log(paymentTransaction);
            commit("VALIDATE_PAYMENT", paymentTransaction);
            dispatch("transaction/loadPrimaryTransactionList", 25, {
              root: true,
            });
            dispatch("transaction/loadBusinessTransactionList", 25, {
              root: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getPayeePaymentTransactions({ commit }, payload) {
        axios({
          url: `${path}/get/payee/paymentTransactions`,
          method: "GET",
          params: {
            payeeUserId: payload,
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((paymentTransactionList) => {
            commit("GET_PAYMENT_TRANSACTION", paymentTransactionList);
            console.log(paymentTransactionList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getPayerPaymentTransactions({ commit }, payload) {
        axios({
          url: `${path}/get/payer/paymentTransactions`,
          method: "GET",
          params: {
            payerUserId: payload,
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((paymentTransactionList) => {
            commit("GET_PAYMENT_TRANSACTION", paymentTransactionList);
            console.log(paymentTransactionList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createQRcode({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/createQrCode`,
          data: payload,
          method: "POST",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((res) => res.data)
          .then((qrCodeData) => {
            console.log(qrCodeData);
            commit("SET_QRCODEDQTA", qrCodeData);

            if (payload.type === "avo¢ash pay") {
              commit(
                "CREATE_QRCODE",
                `data:image/png;base64,${qrCodeData.qrCode}`
              );
            } else if (payload.type === "avo¢ash claim") {
              commit(
                "CREATE_QRCODETOCLAIM",
                `data:image/png;base64,${qrCodeData.qrCode}`
              );
            } else if (payload.type === "avo¢ash out" || "avo¢ash ATM") {
              commit(
                "CREATE_QRCODETOCASHOUT",
                `data:image/png;base64,${qrCodeData.qrCode}`
              );
            }
            // else if (payload.type === 'avocash-payout') {
            //   commit('CREATE_QRCODETOCASHOUT', `data:${res.headers['content-type']};base64,${Buffer.from(String.fromCharCode(...new Uint8Array(res.data)), 'binary')
            //     .toString('base64')}`);
            // }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      decodeQRCode({ commit, rootState }, payload) {
        console.log(
          `geolocation: ${rootState.location.latitude}-${rootState.location.longitude}`
        );
        axios({
          url: `${path}/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
            geolocation: `${rootState.location.latitude}-${rootState.location.longitude}`,
            ipAddress: rootState.location.ipAddress,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((decodedQRCode) => {
            console.log(decodedQRCode);
            console.log(
              `transaction type ${decodedQRCode.type}transaction amount: ${decodedQRCode.amount}`
            );
            commit("DECODE_QRCODE", decodedQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      decodeVendorQRCode({ commit, rootState }, payload) {
        axios({
          url: `${path}/decode_vendor_QrCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((decodedQRCode) => {
            console.log(decodedQRCode);
            commit("DECODE_QRCODE", decodedQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      deleteQRCode({ commit }, payload) {
        axios({
          url: `${path}/deleteQRCode`,
          params: {
            paymentDataId: payload,
          },
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((qrCodeData) => {
            commit("SET_QRCODEDQTA", "");
          })
          .catch((err) => {
            console.log(err);
          });
      },
      createVendorQRCode({ commit }, payload) {
        axios({
          url: `${path}/create_vendor_QrCode`,
          data: payload,
          method: "POST",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((staticQRCode) => {
            commit("GET_VENDOR_QRCODE", staticQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      getStaticQrCode({ commit }, payload) {
        axios({
          url: `${path}/get/qrCode`,
          params: {
            staticQRCodeId: payload.id,
          },
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((staticQRCode) => {
            console.log(staticQRCode);
            commit("GET_VENDOR_QRCODE", staticQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      addQRCodeAmount({ commit }, payload) {
        axios({
          url: `${path}/add/QrCode_amount`,
          data: payload,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((vendorQRCodeList) => {
            commit("GET_VENDOR_QRCODELIST", vendorQRCodeList);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      editAmountDescription({ commit }, payload) {
        axios({
          url: `${path}/edit/QrCode_amount`,
          params: { staticQrCodeId: payload },
          method: "PUT",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((vendorQRCodeList) => {
            commit("GET_VENDOR_QRCODELIST", vendorQRCodeList);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      cancelQRAmount({ commit }, payload) {
        axios({
          url: `${path}/cancel/QrCode_amount`,
          params: { staticQrCodeId: payload },
          method: "PUT",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((vendorQRCodeList) => {
            commit("GET_VENDOR_QRCODELIST", vendorQRCodeList);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      getVendorQRCodeList({ commit }, payload) {
        axios({
          url: `${path}/userQrCodeList`,
          // params: { userId: app.config.globalProperties.$keycloak.subject },
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((vendorQRCodeList) => {
            console.log(vendorQRCodeList);
            commit("GET_VENDOR_QRCODELIST", vendorQRCodeList);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      getQrTransctionList({ commit }, payload) {
        axios({
          url: `${path}/staticQrCode/transactions`,
          params: {
            staticQrCodId: payload.id,
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((allQrTransactions) => {
            commit("GET_QRCODE_TRANSACTIONLIST", allQrTransactions);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      addQrCodeUser({ commit }, payload) {
        axios({
          url: `${path}/add/QrCodeUser`,
          params: {
            staticQRCodeId: payload.id,
            qrCodeUserId: payload.userId,
          },
          method: "POST",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((staticQRCode) => {
            commit("GET_VENDOR_QRCODE", staticQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      removeQrCodeUser({ commit }, payload) {
        axios({
          url: `${path}/remove/QrCodeUser`,
          params: {
            staticQRCodeId: payload.id,
            qrCodeUserId: payload.userId,
          },
          method: "PUT",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((staticQRCode) => {
            commit("GET_VENDOR_QRCODE", staticQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
    },
  };
};
// export default payment;
