<template>
  <f7-page hide-bars-on-scroll no-toolbar @page:beforeremove="onPageBeforeRemove" @page:afterin="onPageBeforeIn">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{ $t("paywithqrcodescan.scanQrCode") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios strong-ios media-list v-if="((this.paymentTransaction === '' ||
          this.paymentTransaction.isValidated === true) &&
          parseFloat(this.decodedQRCode.payeeAccountNumber) === '') ||
          !this.decodedQRCode
          ">
          <div bg-color="auto" class="margin-vertical-half">
            <f7-block-title medium class="text-align-center" style="white-space: normal; word-wrap: break-word">{{
              $t("paywithqrcodescan.scanQrCode") }}</f7-block-title>
            <f7-row class="justify-content-center margin">
              <f7-link icon-color="auto" icon-size="50" icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder"
                icon-md="f7:qrcode_viewfinder" class="text-align-left" @click="scanQRCode()"></f7-link>
            </f7-row>
          </div>
        </f7-list>

        <span v-if="(this.paymentTransaction === '' ||
          this.paymentTransaction.isValidated === true) &&
          this.decodedQRCode
          ">
          <f7-list dividers-ios strong-ios id="payWithAcctNbr">
            <h5 class="margin" style="font-weight: normal" v-if="this.paymentDetail || this.paymentReverseDetail">
              <span v-if="this.decodedQRCode.paymentCurrency == this.primaryAccount.currencyCode
                ">{{ $t("common.pay")
  }}<b>
                  <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentDetail.receivedAmount ||
                      this.decodedQRCode.amountToPay,
                      null,
                      this.paymentDetail.toCurrencyCode
                    )
                    "></span>
                </b>
                to
                <b>
                  {{ this.userByAccountNumber.name }}
                </b>
              </span>
              <span v-else>{{ $t("common.pay")
              }}<b>
                  <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentReverseDetail.receivedAmount,
                      null,
                      this.paymentReverseDetail.toCurrencyCode
                    )
                    "></span>
                </b>
                to
                <b>
                  {{ this.userByAccountNumber.name }}
                </b>
              </span>
            </h5>
            <h5 v-else class="margin" style="font-weight: normal">
              {{ $t("paywithqrcodescan.enterTheAmount")
              }}<b>
                {{ this.userByAccountNumber.name }}
              </b>
            </h5>

            <f7-list-item v-if="parseFloat(this.decodedQRCode.amount) > 0" :title="$t('paywithqrcodescan.amountToPay')"
              :after="$filtersToCurrency.toCurrency(
                this.decodedQRCode.amount,
                '',
                this.decodedQRCode.paymentCurrency
              )
                "></f7-list-item>

            <li class="item-content item-input" v-else>
              <div class="item-media">
                {{ this.decodedQRCode.paymentCurrency }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("common.amount") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" min="0" required validate
                    :data-error-message="$t('paywithqrcodescan.enterTheAmount')" name="amountToPay" id="amountToPay"
                    :value="amountToPay" @input="amountToPay = $event.target.value" @change="changeAmountToPay()"
                    @keyup="changeAmountToPay()" @blur="changeAmountToPay()" @keyup.enter="payNow()" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
            <!-- </f7-col>
        </f7-row> -->
            <f7-block style="font-size: 12px" class="no-margin" v-if="parseFloat(this.amountToPay) != '' &&
              this.decodedQRCode.paymentCurrency !== this.primaryAccount.currencyCode
              ">
              <p v-if="this.paymentReverseDetail !== null">
                {{ $t("paywithqrcodescan.youPay") }}
                <span v-html="$filtersToCurrency.toCurrency(
                  this.paymentReverseDetail.grossTransferAmount,
                  null,
                  this.paymentReverseDetail.fromCurrencyCode
                )
                  "></span>
              </p>
            </f7-block>
            <f7-block class="no-margin" v-if="this.userByAccountNumber && this.userByAccountNumber.length > 0">
              <p style="font-size: 10px; font-style: italic; color: red">
                {{ this.getusererror }}
              </p>
            </f7-block>
            <f7-list-input :label="$t('common.description')" floating-label type="textarea" resizable name="description"
              :placeholder="$t('common.description')" :value="description || this.decodedQRCode.description"
              @input="description = $event.target.value">
            </f7-list-input>

            <!--Ask if PIN required to confirm and release payment for remote payment mainly-->
            <!-- <f7-button raised fill color="red" style="margin-left:16px; margin-right:16px;" v-if="this.recipientuser !=null"  @click="paynow()" :disabled="this.decodedQRCode.amountToPay>this.getProfile.primaryAccount.availableBalance">{{ $t('paywithqrcodescan.payNow') }}</f7-button> -->
          </f7-list>
          <f7-button v-if="parseFloat(this.amountToPay) != null" raised fill large ripple color="red" class="margin"
            @click="payNow()">{{ $t("paywithqrcodescan.payNow") }}</f7-button>

          <f7-button v-show="(this.paymentDetail.grossTransferAmount != null ||
            this.paymentReverseDetail.grossTransferAmount != null) &&
            parseFloat(this.amountToPay) != null
            " class="margin-horizontal" ripple outline raised color="red" @click="cancelScan()">{{
    $t("common.cancelPayment") }}</f7-button>
        </span>
        <f7-list dividers-ios strong-ios v-if="this.paymentTransaction.isValidated === false" media-list form
          class="margin-vertical-half">
          <f7-list-group>
            <f7-block-title color="green" class="text-align-center">{{
              $t("paywithqrcodescan.paymentTo", {
                payeeName: this.paymentTransaction.payeeName,
              })
            }}</f7-block-title>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("paywithqrcodescan.youPaid") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%" v-if="this.paymentTransaction.fromCurrencyCode !==
                    this.paymentTransaction.toCurrencyCode
                    ">
                    {{ $t("paywithqrcodescan.recipientGets") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentTransaction.grossPaymentAmount,
                      null,
                      this.paymentTransaction.fromCurrencyCode
                    )
                      "></span>
                  </div>
                  <div class="item-title" style="font-weight: bold" v-if="this.paymentTransaction.fromCurrencyCode !==
                    this.paymentTransaction.toCurrencyCode
                    ">
                    <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentTransaction.receivedPaymentAmount,
                      null,
                      this.paymentTransaction.toCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
            <!-- </f7-list-group>
          <f7-list-group > -->
            <f7-block-title>{{ $t("common.validatePayment") }}</f7-block-title>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferCode") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferPin") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.paymentTransaction.transferCode }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ this.paymentTransaction.transferPIN }}
                  </div>
                </div>
              </div>
            </li>
          </f7-list-group>
        </f7-list>
        <f7-button v-if="this.paymentTransaction.isValidated === false" raised fill ripple large class="margin"
          color="red" strong @click="validateDebitTransaction(paymentTransaction)">{{ $t("common.validatePayment") }}
        </f7-button>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<script>
import { theme, f7 } from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import userByAccountNumber from "../js/mixins/userByAccountNumber.js";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [userByAccountNumber, scanQRCodeGeneral],
  data() {
    return {
      theme,
      f7,
      title: this.$t("paywithqrcodescan.title"),
      payeeAccountNumber: "",
      amountToPay: "",
      paymentCurrency: "",
      recipientuser: "",
      toastText: "",
      description: "",
    };
  },
  mounted: function () {
    const self = this;

    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
    if (this.decodedQRCode) {
      this.amountToPay = parseFloat(this.decodedQRCode.amount);
    }
    this.paymentCurrency = this.payerAccount.currencyCode;
    this.fromAccount = "Standard";
  },
  methods: {
    scanQRCodeToPay() {
      const self = this;

      this.scanQRCode();
      setTimeout(() => {
        if (
          parseFloat(this.decodedQRCode.payeeAccountNumber) !== 0 &&
          this.recipientCountry
        ) {
          this.amountToPay = parseFloat(this.decodedQRCode.amount);
          this.changeAmountToPay();
        } else if (parseFloat(this.decodedQRCode.payeeAccountNumber) == 0) {
          this.toastText = this.$t("paywithqrcodescan.notPayQRCode");
          return false;
        } else if (!this.recipientCountry) {
          this.toastText = this.$t("paywithqrcodescan.notPaymentCntry", {
            notPaymentCntry: this.userByAccountNumber.country.countryName,
          });

          return false;
        }
      }, 500);
    },

    changeAmountToPay() {
      const self = this;

      if (this.recipientCountry) {
        self.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then(() => {
            setTimeout(() => {
              if (parseFloat(this.amountToPay) > 0) {
                if (
                  this.decodedQRCode.paymentCurrency === this.payerAccount.currencyCode
                ) {
                  if (
                    parseFloat(this.amountToPay) >
                    parseFloat(this.payerAccount.accountBalance)
                  ) {
                    this.amountToPay = parseFloat(this.payerAccount.accountBalance);
                    self.$store.dispatch("transaction/calculatePayment", {
                      grossXrate: parseFloat(this.grossexchangerate2),
                      fromCountryCode: this.recipientCountry.fromCountryCode2,
                      toCountryCode: this.recipientCountry.toCountryCode2,
                      pickupMethod: "top-up",
                      amount: parseFloat(this.amountToPay),
                    });
                  } else {
                    self.$store.dispatch("transaction/calculatePayment", {
                      grossXrate: parseFloat(this.grossexchangerate2),
                      fromCountryCode: this.recipientCountry.fromCountryCode2,
                      toCountryCode: this.recipientCountry.toCountryCode2,
                      pickupMethod: "top-up",
                      amount: parseFloat(this.amountToPay),
                    });
                  }
                } else if (
                  this.decodedQRCode.paymentCurrency !== this.payerAccount.currencyCode
                ) {
                  if (
                    parseFloat(this.amountToPay) / parseFloat(this.grossexchangerate2) >
                    parseFloat(this.payerAccount.accountBalance)
                  ) {
                    this.amountToPay =
                      parseFloat(this.payerAccount.accountBalance) *
                      parseFloat(this.grossexchangerate2);
                    self.$store.dispatch("transaction/calculatePaymentReverse", {
                      grossXrate: parseFloat(this.grossexchangerate2),
                      fromCountryCode: this.recipientCountry.fromCountryCode2,
                      toCountryCode: this.recipientCountry.toCountryCode2,
                      pickupMethod: "top-up",
                      amount: parseFloat(this.amountToPay),
                    });
                  } else {
                    self.$store.dispatch("transaction/calculatePaymentReverse", {
                      grossXrate: parseFloat(this.grossexchangerate2),
                      fromCountryCode: this.recipientCountry.fromCountryCode2,
                      toCountryCode: this.recipientCountry.toCountryCode2,
                      pickupMethod: "top-up",
                      amount: parseFloat(this.amountToPay),
                    });
                  }
                } else {
                  return false;
                }
              }
            }, 500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },

    onPageBeforeIn() {
      const self = this;

      this.changeAmountToPay();
    },

    payNow() {
      const self = this;

      if (f7.input.validateInputs("#payWithAcctNbr")) {
        f7.preloader.show("multi");
        self.$store
          .dispatch("payment/makePayment", {
            type: "avo¢ash pay",
            amount: this.amountToPay,
            paymentCurrency: this.paymentCurrency,
            payerAccountNumber: this.payerAccount.accountNumber,
            payerGeolocation: this.latitude + "-" + this.longitude,
            payerIpAddress: this.ipAddress,

            payeeAccountNumber: this.decodedQRCode.payeeAccountNumber,
            payeeGeolocation: this.decodedQRCode.payeeGeolocation,
            payeeIpAddress: this.decodedQRCode.payeeIpAddress,
            description: this.description || this.decodedQRCode.description,
          })
          .then((result) => {
            console.log(result);
            setTimeout(() => {
              self.$store.dispatch("account/loadAccount", this.profile.sub);
            }, 500);
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
        f7router.refreshPage();
      } else {
        var valid = f7.$("#payWithAcctNbr .input-invalid").length === 0;
      }
    },

    cancelScan() {
      const self = this;

      // f7.dialog.confirm("Do you want to cancel the payment?", () => {
      this.amountToPay = "";
      this.payeeAccountNumber = "";
      self.$store.dispatch("account/resetUserByAccountNumber");
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
      self.$store.dispatch("transaction/resetDecodedQRCode");
      // });
    },
    onPageBeforeRemove() {
      const self = this;

      this.cancelScan();
    },

    //  Issuing QR Code for the counterpart to scan and claim payment
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("account", ["userAccount", "userByAccountNumber", "getusererror"]),
    ...mapState("location", ["latitude", "longitude", "ipAddress"]),
    ...mapState("auth", ["profile"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    recipientCountry() {
      if (this.userByAccountNumber) {
        return _.find(this.destinationCountry, [
          "toCountryId",
          this.userByAccountNumber.country.countryId,
        ]);
      } else {
        return false;
      }
    },
    userAccountNumber() {
      const self = this;

      if (parseFloat(this.decodedQRCode.payeeAccountNumber) !== 0) {
        return this.decodedQRCode.payeeAccountNumber;
      } else {
        return this.decodedQRCode.senderAccountNumber;
      }
    },
    payerAccount() {
      return this.userAccount.standard;
    },

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
  },
};
</script>
