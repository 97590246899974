<template>
  <f7-panel
    left
    resizable
    :backdrop="false"
    class="sendMessageLeftPanel"
    :style="{
      width: this.f7.width >= 768 ? '418px' : '100%',
      'min-width': f7.width >= 768 ? '418px' : '100%',
      'border-right':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
    }"
  >
    <f7-page>
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link panel-close="left">
            <f7-icon
              ios="f7:multiply"
              aurora="f7:multiply"
              md="material:close"
            ></f7-icon></f7-link
        ></f7-nav-left>
        <f7-nav-title :title="$t('tamtam.newChat')"></f7-nav-title>
        <div class="subnavbar">
          <form class="searchbar" id="searchbar-autocomplete">
            <div class="searchbar-inner">
              <div class="searchbar-input-wrap">
                <input
                  @blur="clearSearch()"
                  @keydown="updateList()"
                  type="search"
                  :placeholder="$t('tamtam.searchAvoashUser')"
                  id="autocomplete-dropdown-ajax-typeahead"
                />
                <i class="searchbar-icon"></i>
                <span class="input-clear-button" @click="clearSearch()"></span>
              </div>
              <span class="searchbar-disable-button" @click="clearSearch()">{{
                $t("common.cancel")
              }}</span>
            </div>
          </form>
        </div>
      </f7-navbar>
      <f7-list dividers-ios strong-ios media-list class="searchbar-hide-on-search">
        <f7-list-item
          link="#"
          :title="$t('tamtam.newGroup')"
          @click="openCreateGroupeLeftPanel()"
          ><template #media>
            <f7-badge
              color="auto"
              style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
            >
              <f7-icon
                aurora="f7:person_3"
                ios="f7:person_3"
                md="material:groups"
              ></f7-icon>
            </f7-badge>
          </template>
        </f7-list-item>
      </f7-list>
      <div id="autocompleteList"></div>
      <span id="userRecipientlist" v-if="this.searchedUser === ''">
        <f7-block-title medium class="margin-left">{{
          $t("tamtam.recentlyUsed")
        }}</f7-block-title>
        <f7-list dividers-ios strong-ios media-list no-chevron>
          <f7-list-group
            v-for="chatGroup in this.groupList.slice(0, 10)"
            :key="chatGroup.id"
          >
            <li
              v-if="chatGroup && chatGroup.length > 0 && chatGroup.groupType === 'GROUP'"
            >
              <a
                :href="'/message/' + chatGroup.id + '/' + chatGroup.groupType"
                class="item-link item-content panel-close"
                :reload-detail="true"
              >
                <div class="item-media">
                  <img
                    v-if="chatGroup.avatar !== null"
                    :src="`data:image/png;base64,${chatGroup.avatar}`"
                    width="40"
                    style="height: 48px; width: 48px; border-radius: 25px"
                  />
                  <f7-badge
                    v-else
                    color="auto"
                    style="
                      height: 48px;
                      width: 48px;
                      font-size: 16px;
                      border-radius: 25px;
                    "
                    >{{ $filtersGetInitial.getInitial(chatGroup.name) }}</f7-badge
                  >
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title">{{ chatGroup.name }}</div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-footer text-container">
                      {{ chatGroup.description }}
                    </div>
                  </div>
                </div>
              </a>

              <ul v-if="chatGroup.subGroupList && chatGroup.subGroupList.length > 0">
                <li
                  v-if="
                    chatGroup.groupType === 'PERSONAL' &&
                    chatGroup.groupParticipantList.find(
                      (x) => x.userId !== profile.sub
                    ) !== null
                  "
                >
                  <a
                    v-if="!!chatGroup"
                    :href="'/message/' + chatGroup.id + '/' + chatGroup.groupType"
                    class="item-link item-content panel-close"
                    :reload-detail="true"
                  >
                    <div
                      class="item-media"
                      v-if="
                        chatGroup.groupParticipantList &&
                        chatGroup.groupParticipantList.length > 0
                      "
                    >
                      <img
                        v-if="
                          chatGroup.groupParticipantList.find(
                            (x) => x.userId !== profile.sub
                          ) !== null &&
                          chatGroup.groupParticipantList.find(
                            (x) => x.userId !== profile.sub
                          ).avatar !== null
                        "
                        :src="`data:image/png;base64,${
                          chatGroup.groupParticipantList.find(
                            (x) => x.userId !== profile.sub
                          ).avatar
                        }`"
                        width="40"
                        style="height: 48px; width: 48px; border-radius: 25px"
                      />
                      <f7-badge
                        v-if="
                          chatGroup.groupParticipantList.find(
                            (x) => x.userId !== profile.sub
                          ).avatar === null &&
                          chatGroup.groupParticipantList.find(
                            (x) => x.userId !== profile.sub
                          ).name !== null
                        "
                        color="auto"
                        style="
                          height: 48px;
                          width: 48px;
                          font-size: 16px;
                          border-radius: 25px;
                        "
                        >{{
                          $filtersGetInitial.getInitial(
                            chatGroup.groupParticipantList.find(
                              (x) => x.userId !== profile.sub
                            ).name
                          )
                        }}</f7-badge
                      >
                    </div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">
                          {{
                            chatGroup.groupParticipantList.find(
                              (x) => x.userId !== profile.sub
                            ).username
                          }}
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li v-for="chatSubGroup in chatGroup.subGroupList" :key="chatSubGroup.id">
                  <a
                    v-if="!!chatSubGroup"
                    :href="'/message/' + chatSubGroup.id + '/' + chatSubGroup.groupType"
                    class="item-link item-content panel-close"
                    :reload-detail="true"
                  >
                    <div class="item-media">
                      <img
                        v-if="chatSubGroup.avatar !== null"
                        :src="`data:image/png;base64,${chatSubGroup.avatar}`"
                        style="height: 36px; width: 36px; border-radius: 25px"
                      />
                      <f7-badge
                        v-else
                        color="auto"
                        style="
                          height: 36px;
                          width: 36px;
                          font-size: 12px;
                          border-radius: 25px;
                        "
                        >{{ $filtersGetInitial.getInitial(chatSubGroup.name) }}</f7-badge
                      >
                    </div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">
                          {{ chatSubGroup.name }}
                        </div>
                      </div>
                      <div class="item-title-row">
                        <div class="item-footer text-container">
                          {{ chatSubGroup.description }}
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </f7-list-group>
          <f7-list-group v-for="user in this.counterPartUserList" :key="user.id">
            <f7-list-item
              v-if="!!user"
              :link="'/message/' + user.userId + '/' + 'PERSONAL'"
              :title="user.name"
              :after="'+' + user.phoneNumber"
              :footer="user.tag"
              ><template #media>
                <img
                  v-if="user.avatar !== null"
                  :src="`data:image/png;base64,${user.avatar}`"
                  width="40"
                  style="height: 48px; width: 48px; border-radius: 25px"
                />

                <f7-badge
                  color="auto"
                  v-if="user.avatar === null && user.name !== null"
                  style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
                  >{{ $filtersGetInitial.getInitial(user.name) }}</f7-badge
                >
              </template></f7-list-item
            >
          </f7-list-group>
        </f7-list>
      </span>
    </f7-page>
  </f7-panel>
  <!-- <createGroupPanel :selectedGroup="null"></createGroupPanel> -->
  <f7-panel
    left
    resizable
    :backdrop="false"
    class="createGroupeLeftPanel"
    :style="{
      'min-width': f7.width >= 768 ? '418px' : '100%',
      width: this.f7.width >= 768 ? '418px' : '100%',
      'border-right':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
    }"
  >
    <f7-page page-with-subnavbar>
      <f7-navbar no-shadow>
        <div class="left panel-close">
          <a href="#" class="link icon-only">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <div class="title" v-if="this.selectedGroup && this.selectedGroup.length <= 0">
          {{ $t("tamtam.newGroup") }}
        </div>
        <div class="title" v-if="this.selectedGroup">
          {{ $t("tamtam.newSubgroup") }}<br />
          <span style="font-size: 75%"> {{ this.selectedGroup.name }}</span>
        </div>
        <div class="right">
          <f7-link panel-close></f7-link>
        </div>

        <f7-subnavbar :inner="false" no-shadow class="no-shadow">
          <f7-searchbar
            search-container=".search-list"
            search-in=".item-title, item-after, item-footer"
            :disable-button="!theme.aurora"
            :backdrop="false"
          ></f7-searchbar>
        </f7-subnavbar>
      </f7-navbar>
      <f7-block strong v-show="this.chatUsers && this.chatUsers.length > 0">
        <f7-chip
          outline
          v-for="(chatUser, index) in this.chatUsers"
          :key="index"
          :text="chatUser.name"
          deleteable
          @click="deleteChip(chatUser)"
          ><template #media>
            <img
              v-if="chatUser.logo !== null"
              :src="`data:image/png;base64,${chatUser.logo}`"
              style="
                width: var(--f7-chip-height);
                height: var(--f7-chip-height);
                border-radius: 25px;
              "
            />
            <f7-badge
              v-else-if="chatUser.logo === null && chatUser.name !== null"
              color="auto"
              style="
                width: var(--f7-chip-height);
                height: var(--f7-chip-height);
                border-radius: 25px;
              "
              >{{ $filtersGetInitial.getInitial(chatUser.name) }}</f7-badge
            >
          </template>
        </f7-chip>
      </f7-block>
      <f7-list dividers-ios strong-ios class="searchbar-not-found">
        <f7-list-item :title="$t('common.nothingFound')"></f7-list-item>
      </f7-list>

      <f7-list
        dividers-ios
        strong-ios
        v-if="this.selectedGroup && this.selectedGroup.length <= 0"
        media-list
        class="search-list searchbar-found"
        id="userRecipientlist"
      >
        <li v-for="user in this.users" :key="user.id">
          <a
            class="item-checkbox item-content"
            :data-value="user.userId"
            @click="selectUser(user)"
          >
            <div class="item-media">
              <img
                v-if="user.logo !== null"
                :src="`data:image/png;base64,${user.logo}`"
                width="40"
                style="height: 48px; width: 48px; border-radius: 25px"
              />

              <f7-badge
                color="auto"
                v-if="user.logo === null && user.name !== null"
                style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
                >{{ $filtersGetInitial.getInitial(user.name) }}
              </f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title">{{ user.name }}</div>
              <div class="item-after">
                {{ user.phoneNumber }}
              </div>
              <div class="item-footer">
                {{ user.tag }}
              </div>
            </div>
          </a>
        </li>
      </f7-list>
      <f7-list
        dividers-ios
        strong-ios
        v-if="this.selectedGroup"
        media-list
        class="search-list searchbar-found"
        id="userRecipientlist"
      >
        <li v-for="user in this.selectedGroup.groupParticipantList" :key="user.id">
          <a
            class="item-checkbox item-content"
            :data-value="user.userId"
            @click="selectChatUser(user)"
          >
            <div class="item-media">
              <img
                v-if="user.avatar !== null"
                :src="`data:image/png;base64,${user.avatar}`"
                width="40"
                style="height: 48px; width: 48px; border-radius: 25px"
              />

              <f7-badge
                color="auto"
                v-if="user.avatar === null && user.name !== null"
                style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
                >{{ $filtersGetInitial.getInitial(user.name) }}
              </f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title">{{ user.name }}</div>
              <div class="item-after">
                +
                {{ user.phoneNumber }}
              </div>
              <div class="item-footer">
                {{ user.tag }}
              </div>
            </div>
          </a>
        </li>
      </f7-list>

      <f7-toolbar
        tabbar
        bottom
        bg-color="red"
        v-if="this.chatUsers && this.chatUsers.length > 1"
      >
        <f7-link
          v-if="this.selectedGroup && this.selectedGroup.length <= 0"
          raised
          fill
          large
          class="link"
          text-color="white"
          strong
          panel-open="saveGroupeLeftPanel"
          >{{ $t("tamtam.addGroupName") }}</f7-link
        >
        <f7-link
          v-if="this.selectedGroup"
          raised
          fill
          large
          class="link"
          text-color="white"
          strong
          panel-open="saveGroupeLeftPanel"
          >{{ $t("tamtam.addSubgroupName") }}</f7-link
        >
      </f7-toolbar>
    </f7-page>
  </f7-panel>

  <f7-panel
    left
    resizable
    :backdrop="false"
    class="saveGroupeLeftPanel"
    :style="{
      width: this.f7.width >= 768 ? '418px' : '100%',
      'min-width': f7.width >= 768 ? '418px' : '100%',
      'border-right':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
    }"
  >
    <f7-page page-with-subnavbar>
      <f7-navbar no-shadow>
        <div class="left panel-close">
          <a href="#" class="link icon-only">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <div class="title" v-if="this.selectedGroup && this.selectedGroup.length <= 0">
          {{ $t("tamtam.newGroup") }}
        </div>
        <div class="title" v-if="this.selectedGroup">
          {{ $t("tamtam.newSubgroup") }}<br />
          <span style="font-size: 75%"> {{ this.selectedGroup.name }}</span>
        </div>
        <div class="right">
          <f7-link panel-close></f7-link>
        </div>
      </f7-navbar>
      <f7-block strong v-if="this.chatUsers && this.chatUsers.length > 0">
        <f7-chip
          outline
          v-for="(chatUser, index) in this.chatUsers"
          :key="index"
          :text="chatUser.name || chatUser.username"
          deleteable
          @click="deleteChip(chatUser)"
          ><template #media>
            <img
              v-if="chatUser.logo !== null || chatUser.avatar !== null"
              :src="`data:image/png;base64,${chatUser.logo || chatUser.avatar}`"
              style="
                width: var(--f7-chip-height);
                height: var(--f7-chip-height);
                border-radius: 50%;
              "
            />
            <f7-badge
              v-if="
                chatUser.logo === null && chatUser.avatar === null && chatUser.name !== ''
              "
              color="auto"
              style="
                width: var(--f7-chip-height);
                height: var(--f7-chip-height);
                border-radius: 50%;
              "
              >{{ $filtersGetInitial.getInitial(chatUser.name) }}</f7-badge
            >
          </template>
        </f7-chip>
      </f7-block>
      <form id="createGroupForm">
        <f7-list dividers-ios strong-ios media-list>
          <f7-list-input
            :label="$t('common.name')"
            floating-label
            type="text"
            name="groupName"
            :placeholder="$t('common.name')"
            :value="this.groupName"
            @input="groupName = $event.target.value"
            :error-message="$t('common.name')"
            required
            validate
            clear-button
          ></f7-list-input>

          <f7-list-input
            :label="$t('common.description')"
            floating-label
            type="text"
            name="description"
            :placeholder="$t('common.description')"
            :value="this.description"
            @input="description = $event.target.value"
            :error-message="$t('common.description')"
            clear-button
          ></f7-list-input>

          <f7-list-item
            v-if="
              (this.selectedGroup && this.selectedGroup.avatar !== null) ||
              !this.documentsUrl
            "
            link="#"
            :title="$t('tamtam.uploadNewProfilePicture')"
            @click="selectDocument('img')"
            ><template #media>
              <img
                v-if="
                  this.selectedGroup &&
                  this.selectedGroup.avatar &&
                  this.selectedGroup.avatar !== undefined &&
                  this.selectedGroup.avatar !== null
                "
                :src="`data:image/png;base64,${this.selectedGroup.avatar}`"
                style="
                  width: 48px;
                  height: 48px;
                  background-size: cover;
                  display: block;
                  border-radius: 50%;
                "
                alt="avatar"
            /></template>
          </f7-list-item>
          <f7-link
            v-else
            class="margin-left margin-top"
            @click="selectDocument('img')"
            icon-size="50"
            icon-ios="f7:photo_on_rectangle"
            icon-aurora="f7:photo_on_rectangle"
            icon-md="material:add_to_photos"
            :text="$t('tamtam.addGroupAvatar')"
          ></f7-link>
          <f7-list-input
            type="file"
            class="chatAvatarInput"
            input-id="selectPhoto"
            style="display: none"
            accept="image/*"
            capture="user"
          ></f7-list-input>
          <f7-list-group>
            <span v-for="(docItem, index) in this.documentsUrl" :key="index">
              <f7-list-item class="attachmentName">{{ docItem.name }} </f7-list-item>
              <f7-link
                class="removeAttachement"
                @click="removeDoc(index)"
                raised
                color="red"
                icon-f7="multiply_circle_fill"
              ></f7-link>
            </span>
            <span
              v-if="this.documentsUrl && this.documentsUrl.length > 0"
              class="logoCropper"
            >
              <imageCropper :imageSrc="this.documentsUrl[0]" :isToSave="false" />
            </span>
          </f7-list-group>
        </f7-list>
      </form>
      <f7-toolbar
        tabbar
        bottom
        bg-color="red"
        :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        v-if="this.chatUsers && this.chatUsers.length > 1 && this.groupName !== ' '"
      >
        <f7-link
          v-if="this.selectedGroup && this.selectedGroup.length <= 0"
          raised
          fill
          large
          ripple
          class="link"
          text-color="white"
          :disabled="this.groupName === ''"
          strong
          @click="createGroup()"
          >{{ $t("tamtam.createGroup") }}</f7-link
        >
        <f7-link
          v-if="this.selectedGroup"
          raised
          fill
          large
          ripple
          :disabled="this.groupName === ''"
          class="link"
          text-color="white"
          strong
          @click="createSubGroup(selectedGroup)"
          >{{ $t("tamtam.createSubgroup") }}</f7-link
        >
      </f7-toolbar>
    </f7-page>
  </f7-panel>
  <createGroupPanel :selectedGroup="selectedGroup" />
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import createGroupPanel from "./createGpPanel.vue";

import {
  messageOptionsMixin,
  messageGroupMixin,
  getusersMixin,
} from "../../js/mixins/messageMixins.js";
import _ from "lodash";
import imageCropper from "../imageCropper.vue";
import { cordovaCamera } from "../../js/mixins/cordovaCamera.js";
import { attachDocs } from "../../js/mixins/attachDocs.js";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    selectedGroup: Object,
  },
  mixins: [
    messageOptionsMixin,
    messageGroupMixin,
    getusersMixin,
    cordovaCamera,
    attachDocs,
  ],

  components: {
    createGroupPanel,
    imageCropper,
  },
  data() {
    return {
      theme,
      f7,
      chatUsers: [],
      chatUsersToPost: [],
      groupName: "",
      description: "",
      image: "",
      title: "",
      documents: [],
      parameters: {
        stencil_props: {
          handlers: {},
          movable: false,
          resizable: false,
        },
        stencil_component: "circle-stencil",
        stencil_size: {
          width: 280,
          height: 280,
        },
        image_restriction: "stencil",
      },
    };
  },
  mounted() {
    if (this.documentsUrl && this.documentsUrl.length > 0) {
      this.$store.dispatch("auth/clearDocumentsUrl");
    }
  },
  methods: {
    // addSubgroup(currentGroupDetail) {
    //   const self = this;
    //   self.$store.dispatch("message/getGroupParticipantList", currentGroupDetail.id);
    //   // this.selectedGroup = currentGroupDetail;
    //   f7.panel.open(".createGroupeLeftPanel");
    // },
  },

  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "infoMessage",
      "messagesData",
      "groupUnReadMsg",
      "allGroupsUnReadMsg",
      "messageIsSelected",
      "selectedMessages",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
      "users",
    ]),
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "profile",
      "documentsUrl",
    ]),
  },
};
</script>
