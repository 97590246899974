<template>
  <f7-page hide-bars-on-scroll @page:beforeremove="onPageBeforeRemove">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title v-html="this.title" sliding></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <!-- <f7-row no-gap>
      <f7-col width="0" medium="25"></f7-col>
      <f7-col width="100" medium="50"> -->
    <f7-block-title medium style="white-space: normal; word-wrap: break-word" v-if="this.amountToPay == ''">{{
      $t("avocashatmwithaccountnumber.enterRecipientAccount")
    }}</f7-block-title>

    <f7-list dividers-ios strong-ios v-if="this.paymentTransaction === '' ||
        this.paymentTransaction.isValidated === true
        ">
      <f7-list-group>
        <h5 class="margin" v-if="this.amountToPay !== ''">
          <span v-if="this.paymentCurrency != this.primaryAccount.currencyCode">{{
            $t("avocashatmwithaccountnumber.payout", {
              "1": $filtersToCurrency.toCurrency(
                this.paymentDetail.receivedAmount,
                this.paymentDetail.toCurrencySymbol,
                this.paymentDetail.toCurrencyCode
              ),
            })
          }}<b>{{ this.userByAccountNumber.firstName }}
              {{ this.userByAccountNumber.lastName }}</b>
          </span>
          <span v-else>{{ $t("avocashatmwithaccountnumber.payout")
          }}<b>{{
  $filtersToCurrency.toCurrency(
    this.amountToPay,
    this.primaryAccount.currencySymbol,
    this.primaryAccount.currencyCode
  )
}}</b>
            to
            <b>{{ this.userByAccountNumber.firstName }}
              {{ this.userByAccountNumber.lastName }}</b>
          </span>
        </h5>

        <f7-list-input :label="$t('avocashatmwithaccountnumber.avoashNumber')" floating-label type="number" required
          validate data-error-message="Please enter the account number!" class="inputNumber" maxlength="10"
          padding-right-half clear-button name="userAccountNumber" :value="userAccountNumber"
          @input="userAccountNumber = $event.target.value" @blur="getUserByAccountNber()"></f7-list-input>
        <f7-block v-if="this.userAccountNumber && (this.toastText || this.getusererror)" class="no-margin">
          <p style="font-size: 10px; font-style: italic; color: red" v-html="this.getusererror || this.toastText"></p>
        </f7-block>

        <f7-row no-gap>
          <f7-col width="25">
            <li class="item-content item-input no-padding-right">
              <div class="item-inner no-padding-right">
                <div v-if="!paymentCurrency" class="item-title item-floating-label">
                  {{ $t("avocashatmwithaccountnumber.currency") }}
                </div>
                <div v-else class="item-title item-label">
                  {{ $t("avocashatmwithaccountnumber.currency") }}
                </div>
                <div class="item-input-wrap input-dropdown-wrap">
                  <select v-model="paymentCurrency" @change="changePaymentCurrency()" :disabled="!this.recipientCountry">
                    <option v-if="this.profile.sub != null" :value="this.primaryAccount.currencyCode" selected>
                      {{ this.primaryAccount.currencyCode }}
                    </option>
                    <option v-if="this.userByAccountNumber !== ''" :value="this.userByAccountNumber.primaryAccount.currencyCode
                      ">
                      {{ this.userByAccountNumber.primaryAccount.currencyCode }}
                    </option>
                  </select>
                </div>
              </div>
            </li>
          </f7-col>
          <f7-col width="75">
            <li class="item-content item-input padding-left-half">
              <div class="item-inner padding-left-half">
                <div class="item-title item-floating-label">
                  {{ $t("avocashatmwithaccountnumber.amountToPayout") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" min="0" required validate data-error-message="Please enter the amount to pay-out!"
                    :disabled="!this.recipientCountry" name="amountToPay" class="inputNumber" :value="amountToPay"
                    @input="amountToPay = $event.target.value" @change="changeAmountToPay()" @keyup="changeAmountToPay()"
                    @blur="changeAmountToPay()" @keyup.enter="PayOutNow()" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
          </f7-col>
        </f7-row>
        <f7-block style="font-size: 12px" class="no-margin" v-if="this.amountToPay != '' &&
          this.paymentCurrency != primaryAccount.currencyCode
          ">
          <p v-if="this.paymentDetail !== null">
            {{
              $t("avocashatmwithaccountnumber.youGetAs", {
                "1": $filtersToCurrency.toCurrency(
                  this.paymentDetail.transferFeeAmount,
                  this.paymentDetail.toCurrencySymbol,
                  this.paymentDetail.toCurrencyCode
                ),
              })
            }}
          </p>
        </f7-block>
        <f7-list-input :label="$t('avocashatmwithaccountnumber.description')" floating-label type="textarea" resizable
          name="description" :placeholder="$t('avocashatmwithaccountnumber.description')" :value="description"
          @input="description = $event.target.value"></f7-list-input>
      </f7-list-group>
    </f7-list>

    <!--Ask if PIN required to confirm and release payment for remote payment mainly-->
    <!-- <f7-button raised fill color="red" style="margin-left:16px; margin-right:16px;" v-if="this.recipientuser !=null"  @click="paynow()" :disabled="this.scanQR.QRamountToPay>this.getProfile.primaryAccount.availableBalance">{{ $t('avocashatmwithaccountnumber.payNow') }}</f7-button> -->
    <span v-if="this.paymentTransaction === '' ||
      this.paymentTransaction.isValidated === true
      ">
      <f7-button raised fill ripple large color="red" class="margin" @click="payOutNow()">{{
        $t("avocashatmwithaccountnumber.payoutNow") }}</f7-button>

      <f7-button v-if="(this.paymentDetail.grossTransferAmount ||
        this.paymentReverseDetail.grossTransferAmount) &&
        this.amountToPay > 0
        " class="margin" ripple outline raised color="red" @click="cancelScan()">{{
    $t("avocashatmwithaccountnumber.cancelPayout") }}</f7-button>
    </span>
    <f7-list dividers-ios strong-ios v-if="this.paymentTransaction.isValidated === false" media-list form
      class="margin-vertical-half">
      <f7-list-group>
        <f7-block-title color="green" class="text-align-center">{{
          $t("avocashatmwithaccountnumber.transferFrom", {
            "1": this.paymentTransaction.payerName,
          })
        }}</f7-block-title>
        <f7-list-item :title="$t('avocashatmwithaccountnumber.amountToCashout')" :after="$filtersToCurrency.toCurrency(
          this.paymentTransaction.receivedPaymentAmount,
          this.paymentTransaction.toCurrencySymbol,
          this.paymentTransaction.toCurrencyCode
        )
          "></f7-list-item>
        <f7-block-title>{{
          $t("avocashatmwithaccountnumber.validateCashout")
        }}</f7-block-title>
        <f7-row>
          <f7-col width="65" medium="80">
            <f7-list-item header="Transfer code" :title="this.paymentTransaction.transferCode"></f7-list-item>
          </f7-col>
          <f7-col width="25" medium="15">
            <f7-list-input type="password" floating-label :label="$t('avocashatmwithaccountnumber.pin')"
              :placeholder="$t('avocashatmwithaccountnumber.149')" inputmode="numeric" :value="transferPIN"
              @input="transferPIN = $event.target.value" @keyup.enter="validateCreditTransaction(
                paymentTransaction.transactionType,
                paymentTransaction.transferCode
              )
                " pattern="[0-9]*" maxlength="4" size="4" required></f7-list-input>
          </f7-col>
        </f7-row> </f7-list-group></f7-list>
    <f7-button v-if="this.paymentTransaction.isValidated === false" raised fill ripple large class="margin" color="red"
      strong @click="validateCreditTransaction(
        paymentTransaction.transactionType,
        paymentTransaction.transferCode
      )
        ">{{ $t("avocashatmwithaccountnumber.validateCashout") }}</f7-button>
    <!-- </f7-col>
      <f7-col width="0" medium="25"></f7-col>
    </f7-row> -->
  </f7-page>
</template>
<script>
import { f7, theme } from "framework7-vue";
import userByAccountNumber from "../js/mixins/userByAccountNumber.js";
import {
  calculateTransferPayment,
  transferAgain,
} from "../js/mixins/transaction.js";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [userByAccountNumber, calculateTransferPayment, transferAgain],

  data() {
    return {
      theme,
      f7,
      title: "pay-out with account number",
      amountToPay: "",
      accountNumber: "",
      userAccountNumber: "",
      paymentCurrency: "",
      amountToHigh: "",
      toAccount: "",
      toastText: "",
      transferPIN: "",
      description: "",
    };
  },
  mounted() {
    const self = this;

    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }
    this.paymentCurrency = this.payeeAccount.currencyCode;
    this.toAccount = "Primary";
    if (!this.recipientCountry && this.userByAccountNumber) {
      this.toastText =
        "You cannot cash-out to" +
        " " +
        this.userByAccountNumber.country.countryName;
    }
    if (this.isTablet == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
  },

  methods: {
    changeAmountToPay() {
      const self = this;

      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        self.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              if (
                this.paymentCurrency === this.payerAccount.currencyCode &&
                this.paymentCurrency !== null
              ) {
                if (
                  parseFloat(this.amountToPay) >
                  parseFloat(this.payerAccount.accountBalance)
                ) {
                  this.amountToPay = parseFloat(
                    this.payerAccount.accountBalance
                  );
                }
                this.calculatePayment();
              } else if (
                this.paymentCurrency !== this.payerAccount.currencyCode &&
                this.paymentCurrency !== null
              ) {
                if (
                  parseFloat(this.amountToPay) /
                  parseFloat(this.grossexchangerate2) >
                  parseFloat(this.payerAccount.accountBalance)
                ) {
                  this.amountToPay =
                    parseFloat(this.payerAccount.accountBalance) /
                    parseFloat(this.grossexchangerate2);
                }
                this.calculatePaymentReverse();
              } else {
                return false;
              }
            }, 500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },

    changePaymentCurrency() {
      const self = this;

      if (this.paymentDetail || this.paymentReverseDetail) {
        self.$store.dispatch("transaction/resetPaymentDetail");
        self.$store.dispatch("transaction/resetPaymentReverseDetail");
      }
      this.changeAmountToPay();
    },
    payOutNow() {
      const self = this;

      if (f7.input.validateInputs("#payOutWithAcctNbr")) {
        f7.preloader.show("multi");
        self.$store
          .dispatch("payment/makePayment", {
            type: "avo¢ash pay",
            amount: this.amountToPay,
            paymentCurrency: this.paymentCurrency,
            payerAccountNumber: this.userByAccountNumber.accountNumber,
            payerGeolocation: "",
            payerIpAddress: "",

            payeeAccountNumber: this.payeeAccount.accountNumber,
            payeeGeolocation: this.latitude + "-" + this.longitude,
            payeeIpAddress: this.ipAddress,
            description: this.description,
          })
          .then((result) => {
            console.log(result);
            setTimeout(() => {
              if (this.toAccount === "Primary") {
                self.$store.dispatch("account/loadPrimaryAccount");
              } else {
                self.$store.dispatch("account/loadBusinessAccount");
              }
            }, 500);
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
      } else {
        var valid = f7.$("#payOutWithAcctNbr .input-invalid").length === 0;
      }
    },
    cancelScan() {
      const self = this;

      this.amountToPay = "";
      this.paymentCurrency = "";
      this.userAccountNumber = "";
      self.$store.dispatch("account/resetUserByAccountNumber");
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
    },
    onPageBeforeRemove() {
      const self = this;

      this.cancelScan();
    },
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "primaryTransactionList",
      "businessTransactionList",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userDetail",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userByAccountNumber",
      "getusererror",
    ]),
    ...mapState("location", ["latitude", "longitude", "ipAddress"]),

    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    recipientCountry() {
      if (!this.userByAccountNumber) {
        return false;
      } else {
        return _.find(this.originCountry, [
          "fromCountryId",
          this.userByAccountNumber.country.countryId,
        ]);
      }
    },
    payeeAccount() {
      return this.primaryAccount;
    },
    payerAccount() {
      return this.userByAccountNumber.primaryAccount;
    },
  },
};
</script>
