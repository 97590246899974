<template>
  <f7-page @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("deliveryAccount.createNew") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block strong>{{ $t("tontinecreatenew.createATontine") }} <br />{{
          $t("tontinecreatenew.youCanAdd")
        }}</f7-block>
        <f7-list dividers-ios strong-ios media-list form id="createNewTontine">
          <f7-list-group>
            <f7-list-input :label="$t('deliveryAccount.companyName')" floating-label type="text" name="companyName"
              :placeholder="$t('deliveryAccount.companyName')" :value="companyName"
              @input="companyName = $event.target.value" :error-message="$t('companyName.enterTontineTitleErr')" :info="$t('companyName.enterTontineTitleInfo', {
                1: 80 - tontineTitle.length,
              })
                " required validate maxlength="80" minlength="5" clear-button></f7-list-input>
            <f7-list-input :label="$t('recipientaddnew.ownerName')" floating-label type="text" name="ownerName"
              :placeholder="$t('recipientaddnew.ownerName')" :value="this.ownerName"
              @input="ownerName = $event.target.value" error-message="Please enter recipient email" required validate
              clear-button></f7-list-input>
            <span cols25_75>
              <li class="item-content item-input no-padding-right" @click="selectUserPhoneCountry()"
                popup-open=".selectUserPhoneCode">
                <f7-link class="item-media">
                  <img v-if="this.countryCode" class="coutryMap no-margin-bottom no-padding-bottom" :src="'https://flagcdn.com/' + this.countryCode.toLowerCase() + '.svg'
                    " width="24" />
                </f7-link>
                <div class="item-inner">
                  <div class="item-title item-label no-padding-right">
                    {{ $t("recipientaddnew.country") }}
                  </div>
                  <div class="item-input-wrap input-dropdown-wrap">
                    <input @change="selectUserPhoneCountry()" :value="'+' + this.countryPhoneCode" readonly type="text"
                      name="countryPhoneCode" />
                  </div>
                </div>
              </li>

              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">
                    {{ $t("recipientaddnew.phoneNumber") }}
                  </div>
                  <div class="item-input-wrap">
                    <input type="tel" required validate min="6" :value="this.phoneNumber"
                      @input="phoneNumber = $event.target.value" @blur="changePhoneNumber()"
                      data-error-message=" Please provide a phone number" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </span>

            <f7-block class="no-margin-vertical" v-if="this.phone && this.phone.length > 0">
              <p v-if="this.phoneNumberDetail !== null" style="font-size: 10px; font-style: italic; color: red">
                {{ this.phoneNumberDetail.carrier }}
                {{ this.phoneNumberDetail.geocoder }}
              </p>
              <p v-if="this.mobilePhoneError !== null" style="font-size: 10px; font-style: italic; color: red">
                {{ this.mobilePhoneError }}
              </p>
            </f7-block>
            <f7-list-input :label="$t('recipientaddnew.email')" floating-label type="email" name="email"
              :placeholder="$t('recipientaddnew.email')" :value="this.email" @input="email = $event.target.value"
              error-message="Please enter recipient email" required validate clear-button></f7-list-input>
            <f7-list-input :label="$t('recipientaddnew.address')" floating-label type="text" name="address"
              :value="this.accountAddress" :placeholder="$t('recipientaddnew.recipientAddress')"
              @input="accountAddress = $event.target.value" error-message="Please enter recipient address" required
              validate clear-button></f7-list-input>
            <f7-list-item class="deleveryCities item-label" smart-select :smart-select-params="{
              openIn: 'popup',
              popupSwipeToClose: true,
              popupPush: true,
              searchbar: true,
              searchbarPlaceholder: $t('shopproductaddnew.findProductDeliveryCities'),
            }" :title="$t('shopproductaddnew.productDeliveryCities')">
              <select id="stateid" name="city" v-model="deliveryCities" multiple>
                <optgroup v-for="(states, stateName, index) in destinationCity.stateList" :data-stateid="states.stateId"
                  :label="states.stateName" :key="index">
                  <option smartselect v-for="(citylist, index) in states.cityList" :value="citylist.cityId"
                    :data-cityname="citylist.cityName" :key="index">
                    {{ citylist.cityName }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>

            <f7-block-title class="item-header">{{
              $t("common.description")
            }}</f7-block-title>
            <f7-text-editor :value="deliveryAccountInfo" name="description" :placeholder="$t('common.description')"
              floating-label @texteditor:change="(v) => (deliveryAccountInfo = v)" resizable required validate :style="f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
                " />
            <myDocs :type="this.type"></myDocs>
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom no-hairline bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
          <f7-link raised fill large class="link" ripple @click="createDeliveryAccount()" strong text-color="white">{{
            $t("common.save") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Block,
  f7Card,
  f7CardContent,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7ListInput,
  f7Link,
  f7BlockTitle,
  f7TextEditor,
  f7Toolbar,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Block,
    f7Card,
    f7CardContent,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7ListInput,

    f7Link,
    f7BlockTitle,
    f7TextEditor,
    f7Toolbar,
  },
  mixins: [attachDocs],
  data() {
    return {
      theme,
      f7,
      companyName: "",
      ownerName: "",
      email: "",
      phoneNumber: "",
      selectedCountry: "",
      deliverAccountInfo: "",
      forms: "",
      deliveryCities: [],
      type: "delivery",
    };
  },
  mounted() {
    this.selectedCountry = this.$keycloak.authenticated
      ? this.profile.countryCode
      : this.currentUserCountry.countryCode2;

    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    this.paymentCurrency = this.profile.currencyCode;
  },
  methods: {
    createDeliveryAccount() {
      f7.preloader.show("multi");
      if (f7.input.validateInputs("#createDeliveryAccount")) {
        const deliveryAccount = {
          companyName: this.companyName,
          ownerName: this.profile.name,
          accountAddress: this.accountAddress,
          deliverAccountInfo: this.deliverAccountInfo,
          email: this.email,
          phoneNumber: this.phoneNumber,
          phoneCode: this.profile.phoneCode,
          primaryAccount: this.primaryAccount.accountNumber,
          businessAccount: this.businessAccount.accountNumber,
          deliveryCities: this.deliveryCities,
          currencyCode: this.profile.currencyCode,
          countryCode: this.profile.countryCode,
          countryName: this.profile.countryName,
        };
        this.forms = new FormData();
        this.forms.append("tontine", JSON.stringify(deliveryAccount));

        for (const element of this.documentsUrl) {
          this.forms.append("files", element.file);
        }
        this.$store
          .dispatch("delivery/createDeliveryAccount", this.forms)
          .then((result) => {
            setTimeout(() => {
              this.f7router.navigate(`/deliveryAccount/${this.deliveryAccount.id}`);
              this.$store.dispatch("auth/clearDocumentsUrl");
              f7.preloader.hide();
            }, 1000);
          })
          .then((err) => {
            console.log(err);
            f7.preloader.hide();
          });
      }
    },
    beforeOut() {
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("delivery", [
      "deliveryAccount",
      "delivery",
      "deliverer",
      "pickuPointAccount",
      "pickuPoint",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
