import { f7 } from "framework7-vue";
import _ from "lodash";
import { mapState } from "vuex";
export const recipient = {
  mounted() {
    if (this.$keycloak.authenticated && this.allrecipients === "") {
      this.$store.dispatch("remit/loadRecipients");
    }
  },
  methods: {
    recipientNew() {
      f7.views.main.router.navigate("/recipient/create/new/");
    },
    async toUrlEdit(recipient) {
      f7.preloader.show("multi");
      await this.$store.dispatch("remit/getRecipientById", recipient.id);
      // setTimeout(() => {
      f7.views.main.router.navigate(`/recipient/edit/${recipient.id}`);
      f7.preloader.hide();
      // }, 300);
    },
    async toUrlDetail(recipient) {
      f7.preloader.show("multi");
      console.log(this.$store.state.remit.recipientTransactionList);
      if (recipient.id !== this.$store.state.remit.recipient.id) {
        (await this.$store.state.remit.recipientTransactionList) == {};
        (await this.$store.state.remit.recipient) == {};
        await this.$store.dispatch("remit/getRecipientById", recipient.id);
      }
      setTimeout(() => {
        f7.views.main.router.navigate(`/recipient/${recipient.id}`);
        f7.preloader.hide();
      }, 1000);
    },
    getRecipientCountry(countryCode) {
      return this.country.find((cntry) => cntry.countryCode2 === countryCode);
    },
    selectRecipient(recipient) {
      this.selectedRecipient = recipient;
      f7.actions.open("#recipientActions", true);
    },
    getRecipient(recipient) {
      this.selectedRecipient = recipient;
    },

    async transferPickup(recipient) {
      f7.preloader.show("multi");
      await this.$store.dispatch("transaction/getExtraTransferDetail", {});
      await this.$store.dispatch("remit/getRecipientById", recipient.id);
      setTimeout(() => {
        this.f7router.f7.views.main.router.navigate(
          `/pickup/with-recipient/${recipient.id}/pick-up`
        );
        f7.preloader.hide();
      }, 300);
    },

    async transferTopup(recipient) {
      f7.preloader.show("multi");
      await this.$store.dispatch("transaction/getExtraTransferDetail", {});
      await this.$store.dispatch("remit/getRecipientById", recipient.id);
      await this.$store.dispatch("account/loadUser", {
        userId: recipient.userRecipientId,
        type: "userId",
      });
      setTimeout(() => {
        f7.views.main.router.navigate(
          `/topup/with-recipient/${recipient.id}/top-up`
        );
        f7.preloader.hide();
      }, 300);
    },

    async onPageBeforeInit() {
      // if (this.transferDetail === "" || this.extraTransferDetail === "") {
      //     await f7.tab.show("#withnewcards");
      // } else {
      //     await f7.tab.show("#frombalance");
      // }
    },

    async deleteRecipient(recipient) {
      f7.dialog.confirm(
        this.$t("recipients.deleteMessage", {
          therecipient: `${recipient.name}`,
        }),
        () => {
          f7.preloader.show("multi");
          setTimeout(() => {
            this.$store.dispatch("remit/recipientDelete", recipient.id);
          }, 1000);
          f7.preloader.hide();
        }
      );
    },
    onIndexSelect(itemContent) {
      console.log(itemContent);
    },

    loadMore(done) {
      setTimeout(() => {
        this.$store.dispatch("remit/loadRecipients");
        done();
      }, 1000);
    },

    openPreloader() {
      f7.preloader.show("multi");
      setTimeout(() => {
        f7.preloader.hide();
      }, 1000);
    },

    getCountry(countryName) {
      return this.country.find((x) => x.countryName === countryName)
        ? this.country.find((x) => x.countryName === countryName).name
        : countryName;
    },
    //recipientsPickup.vue

    async gotochecktransfer(recipient) {
      f7.preloader.show("multi");
      await this.$store.dispatch("remit/getRecipientById", recipient.id);
      this.extraTransferDetail.destinationctry = await recipient.countryName;
      this.extraTransferDetail.recipientId = await recipient.id;
      this.extraTransferDetail.name = await recipient.name;
      this.extraTransferDetail.countryName = await recipient.countryName;
      this.extraTransferDetail.email = await recipient.email;
      this.extraTransferDetail.phone = await recipient.phone;
      setTimeout(() => {
        f7.views.main.router.navigate(`/transfer/validate${recipient.id}`);
        f7.preloader.hide();
      }, 300);
    },

    // recipientsTopup

    async getUserRecipientAccountDetails(recipient) {
      await this.$store.dispatch("account/loadUser", {
        userId: recipient.userRecipientId,
        type: "userId",
      });
    },

    async goToCheckTransferToAccount(recipient, accountType) {
      f7.preloader.show("multi");
      await this.$store.dispatch("remit/getRecipientById", recipient.id);
      await this.$store.dispatch("account/loadUser", {
        userId: recipient.userRecipientId,
        type: "userId",
      });

      setTimeout(() => {
        if (
          accountType === "Primary" &&
          this.userDetail.userId === recipient.userRecipientId
        ) {
          this.chosenAccountNumber =
            this.userDetail.primaryAccount.accountNumber;
        } else if (
          accountType === "Business" &&
          this.userDetail.userId === recipient.userRecipientId
        ) {
          this.chosenAccountNumber =
            this.userDetail.businessAccount.accountNumber;
        }
        this.$store.dispatch("transaction/getExtraTransferDetail", {
          pickupMethod: this.extraTransferDetail.pickupMethod || "top-up",
          amount: this.extraTransferDetail.amount || 0,
          destinationctry:
            this.extraTransferDetail.destinationctry || recipient.countryName,
          destinationcty:
            this.extraTransferDetail.destinationcty || recipient.cityName,
          pickupplace: this.extraTransferDetail.pickupplace,
          name: recipient.name,
          accountNumber: this.chosenAccountNumber,
          recipientAccountType: accountType,
          recipientId: recipient.id,
          countryName: recipient.countryName,
          email: recipient.email,
          phone: recipient.phone,
          recipientAccountNumber: this.chosenAccountNumber,
        });
        if (f7.views.main.router.currentRoute.path === "/recipients/topup") {
          f7.views.main.router.navigate(`/transfer/validate${recipient.id}`);
        } else if (
          f7.views.main.router.currentRoute.path === "/recipients/topup-all" ||
          "/recipients/"
        ) {
          f7.views.main.router.navigate(
            `/topup/with-recipient/${recipient.id}/top-up`
          );
        }
        f7.preloader.hide();
      }, 300);
    },

    async goToCheckTransferPickUp(recipient) {
      f7.preloader.show("multi");
      await this.$store.dispatch("remit/getRecipientById", recipient.id);
      setTimeout(() => {
        this.extraTransferDetail.destinationctry = recipient.countryName;
        this.extraTransferDetail.recipientId = recipient.id;
        this.extraTransferDetail.name = recipient.name;
        this.extraTransferDetail.countryName = recipient.countryName;
        this.extraTransferDetail.statName = recipient.stateName;
        this.extraTransferDetail.cityName = recipient.cityName;
        this.extraTransferDetail.email = recipient.email;
        this.extraTransferDetail.phone = recipient.phone;
        f7.views.main.router.navigate(`/transfer/validate${recipient.id}`);
      }, 300);
    },
  },
  computed: {
    recipient() {
      const id = this.f7route.params.id || this.recipientId;
      for (let i = 0; i < this.allrecipients.length; i += 1) {
        if (this.allrecipients[i].id == id) {
          return this.allrecipients[i];
        }
      }
    },
  },
};

export const selectedRecipient = {
  computed: {
    ...mapState("auth", [
      "profile",
      "destinationCity",
      "isDeskTop",
      "isTablet",
    ]),
    recipient() {
      const id = this.f7route.params.id || this.recipientId;
      for (const recipient of this.allrecipients) {
        if (recipient.id == id) {
          return recipient;
        }
      }
    },

    recipientCountry() {
      if (this.recipient) {
        return _.find(this.destinationCountry, [
          "toCountryCode2",
          this.recipient.countryCode || this.selectedCountry,
        ]);
      }
    },
  },
};
