<template>
  <f7-page
    hide-navbar-on-scroll
    infinite
    :infinite-distance="10"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
    @page:init="pageInit()"
  >
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title>{{ $t("tontineslist.myTontines") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card
          class="skeleton-text skeleton-effect-blink"
          v-if="!this.tontineList"
          v-for="n in 6"
          :key="n"
        >
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar padding-right">
              <f7-badge
                slot="media"
                color="gray"
                style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%"
                >GT</f7-badge
              >
            </div>
            <div class="name demo-facebook-name">tontine title</div>
            <div class="demo-facebook-date name">Tontine tag</div>
          </f7-card-header>
          <f7-card-content style="width: auto; height: 400px">
            <ul ref="carousel" class="circle-container align-items-center">
              <li
                class="elevation-5"
                v-for="x in 10"
                :key="x"
                color="white"
                href="#"
                style="background-color: white"
              >
                <div class="item-inner">
                  <div class="item-media">
                    <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
                  </div>
                  <div class="item-after round">
                    {{ $t("tontinedetail.rd") }}{{ n + 1 }}
                  </div>
                </div>
              </li>
            </ul>
          </f7-card-content>
          <f7-list dividers-ios accordion-list media-list>
            <f7-list-item accordion-item :title="$t('common.description')">
              <f7-accordion-content>
                <f7-block> </f7-block>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-button raised fill ripple large class="card_button buttons" color="gray">{{
            $t("common.seeDetail")
          }}</f7-button>
        </f7-card>

        <div v-else-if="this.tontineList === '' || this.tontineList.length < 1">
          <span class="padding-left" style="text-align: center">{{
            $t("common.nothingFound")
          }}</span>
          <a href="#" style="display: flex; justify-content: center">
            <f7-chip
              color="red"
              outline
              :text="$t('tontinedetail.addNewTontine')"
              href="/tontine/new"
            >
              <template #media>
                <f7-icon
                  color="red"
                  ios="f7:plus_circle_fill"
                  aurora="f7:plus_circle_fill"
                  md="material:add_circle"
                >
                </f7-icon>
              </template>
            </f7-chip>
          </a>
        </div>

        <f7-card
          class="demo-facebook-card"
          v-else-if="!!this.tontineList && this.tontineList.length > 1"
          v-for="(tontine, index) in this.tontineList"
          :key="index"
        >
          <a href="#" @click="viewDetail(tontine)" class="tontineLink">
            <f7-card-header class="no-border">
              <div class="demo-facebook-avatar padding-right">
                <img
                  v-if="tontine.logo !== null"
                  :src="`data:image/png;base64,${tontine.logo}`"
                  width="40"
                  height="40"
                  style="border-radius: 50%"
                />
                <f7-badge
                  v-else
                  slot="media"
                  color="auto"
                  style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%"
                >
                  {{ $filtersGetInitial.getInitial(tontine.name) }}</f7-badge
                >
              </div>
              <div class="name demo-facebook-name">
                {{ tontine.name }}
              </div>
              <div class="demo-facebook-date name" v-html="tontine.tag"></div>
            </f7-card-header>

            <f7-card-content :padding="false" v-if="tontineList">
              <swiper-container
                pagination
                :slidesPerView="1"
                :disableOnInteraction="true"
              >
                <swiper-slide
                  :style="{
                    background:
                      'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',

                    height: '400px',
                  }"
                >
                  <ul
                    ref="carousel"
                    class="circle-container align-items-center"
                    v-if="tontine.tontineRoundList && tontine.tontineRoundList.length > 0"
                  >
                    <li
                      class="elevation-5"
                      v-for="(round, index) in tontine.tontineRoundList"
                      :key="index"
                    >
                      <a
                        href="#"
                        class="item-link item-content"
                        @click="viewDetail(tontine)"
                      >
                        <div class="item-inner">
                          <div
                            class="item-title participantName"
                            v-if="round.roundTaker !== null"
                          >
                            {{
                              tontine.tontineParticipantList.find(
                                (x) => x.id === round.roundTaker
                              ).name
                            }}
                          </div>
                          <div class="item-after round">rd{{ round.roundNumber }}</div>
                        </div>
                      </a>
                    </li>
                    <li
                      class="elevation-5"
                      v-for="(n, key) in 10 - tontine.tontineRoundList.length"
                      :key="key + tontine.tontineRoundList.length"
                      color="white"
                      href="#"
                      style="background-color: white"
                    >
                      <div class="item-inner">
                        <div
                          :class="
                            'item-media' + theme.ios
                              ? 'roundAddIconIOS'
                              : 'roundAddIconMD'
                          "
                        >
                          <f7-icon ios="f7:plus" md="material:add"></f7-icon>
                        </div>
                        <div class="item-after round">
                          {{ $t("tontinedetail.rd")
                          }}{{ n + tontine.tontineRoundList.length }}
                        </div>
                      </div>
                    </li>
                    <li class="center align-content-center">
                      <p style="font-size: 13px" class="align-content-center">
                        {{ $t("tontinedetail.collected")
                        }}<span
                          style="font-weight: bold; font-size: 16px"
                          v-html="
                            $filtersToCurrency.toCurrency(
                              (tontine.collectedAmount || 0).toFixed(2),
                              null,
                              tontine.currencyCode
                            )
                          "
                        >
                        </span>
                        <br />{{ $t("tontinedetail.balance")
                        }}<span
                          style="font-weight: bold; font-size: 16px"
                          v-html="
                            $filtersToCurrency.toCurrency(
                              (tontine.accountBalance || 0).toFixed(2),
                              null,
                              tontine.currencyCode
                            )
                          "
                        ></span>
                        <br />{{ $t("tontinedetail.round") }}
                        <span
                          v-html="
                            $filtersToCurrency.toCurrency(
                              tontine.tontineRoundAmount,
                              null,
                              tontine.currencyCode
                            )
                          "
                        ></span
                        ><br />{{
                          $t("tontinedetail.frequency", {
                            frequency: tontine.frequency + " " + tontine.frequencyType,
                          })
                        }}
                      </p>
                    </li>
                  </ul>
                </swiper-slide>

                <swiper-slide
                  v-for="(imageUrl, index) in tontine.tontineImageList"
                  :key="index"
                  style="height: 400px"
                >
                  <span
                    style="display: flex; align-items: center; justify-content: center"
                  >
                    <img :src="`data:image/png;base64,${imageUrl.image}`"
                  /></span>
                </swiper-slide>
              </swiper-container>
            </f7-card-content>
          </a>

          <f7-list dividers-ios accordion-list media-list>
            <f7-list-item accordion-item :title="$t('common.description')">
              <f7-accordion-content>
                <f7-block>
                  <p v-html="tontine.description"></p>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-button
            raised
            fill
            ripple
            large
            class="card_button buttons"
            color="red"
            @click="viewDetail(tontine)"
            >{{ $t("common.seeDetail") }}</f7-button
          >
        </f7-card>
      </div>
      <div class="right_layout"></div>
    </div>
    <div style="padding-bottom: 100px !important"></div>
    <template #fixed>
      <f7-fab
        position="center-bottom"
        :text="$t('tontinedetail.addNewTontine')"
        color="red"
        href="/tontine/new"
      >
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.circle-container li i.icon.f7- {
  color: blue;
}

.circle-container li i.icon.material- {
  color: blue;
}

.tontineLink {
  color: inherit;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7NavRight,
  f7Chip,
  f7Icon,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7List,
  f7ListItem,
  f7AccordionContent,
  f7Block,
  f7Button,
  f7Fab,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { tontine } from "../js/mixins/tontine.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7NavRight,
    f7Chip,
    f7Icon,

    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,

    f7List,
    f7ListItem,
    f7AccordionContent,
    f7Block,
    f7Button,
    f7Fab,
  },
  mixins: [calculateTransferPayment, tontine],
  data() {
    return {
      theme,
      f7,
      visibleItem: 6,
      allowInfinite: true,
      showPreloader: false,
    };
  },

  mounted() {
    const self = this;
    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === "" && !this.country) {
      self.$store.dispatch("location/getCountry");
    }
  },
  methods: {
    async viewDetail(tontine) {
      const self = this;
      f7.preloader.show("multi");
      await self.$store.dispatch("tontine/getTontineById", tontine.id);
      setTimeout(() => {
        this.f7router.navigate(`/tontine/${tontine.id}`);
        f7.preloader.hide();
      }, 200);
    },
    loadMore() {
      const self = this;
      console.log(self.tontineList.length + " " + this.visibleItem);
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      /*  if (self.tontineList && self.tontineList.length < this.visibleItem) {
              self.showPreloader = false;
              return;
            } */
      setTimeout(() => {
        this.visibleItem += 6;
        self.$store.dispatch("tontine/getUserTontinesByuserId", {
          maxNumberOfItem: this.visibleItem,
        });
        self.allowInfinite = true;
      }, 1000);
      self.showPreloader = false;
    },
  },

  computed: {
    ...mapState("tontine", [
      "tontine",
      "tontineOwner",
      "tontineList",
      "participantList",
      "tontineTransaction",
      "tontineImages",
      "tontineQRImage",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
