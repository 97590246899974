import { createI18n } from "vue-i18n";
// import messages from "@intlify/vite-plugin-vue-i18n/messages";
import messages from "@intlify/unplugin-vue-i18n/messages";
// On change of language, switch the /locals/_language_.json file

// import fr from "../locales/fr.json";
// import en from "../locales/en.json";
// import de from "../locales/de.json";
// import nl from "../locales/nl.json";
// import pt from "../locales/pt.json";
// import es from "../locales/es.json";
// import it from "../locales/it.json";

// const messages = { de, en, es, fr, it, nl, pt };
console.log(messages);

function checkDefaultLanguage() {
  let matched = null;
  const languages = Object.getOwnPropertyNames(messages);
  languages.forEach((lang) => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach((lang) => {
      const languagePartials = navigator.language.split("-")[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  if (!matched) {
    languages.forEach((lang) => {
      const languagePartials = navigator.language.split("-")[0];
      if (lang.split("-")[0] === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}

function t(...args) {
  return i18n.t(...args);
}

export const languages = Object.getOwnPropertyNames(messages);

export const selectedLocale =
  checkDefaultLanguage() || import.meta.env.VITE_APP_I18N_LOCALE;

// console.log(messages);
export default createI18n({
  // compositionOnly: false,
  // globalInjection: true,
  legacy: true,
  locale: selectedLocale || localStorage.getItem("lang"),
  /* ||
                                                                        detectLanguage() || en",*/
  messages,
  // t,
  silentTranslationWarn: true,
});
