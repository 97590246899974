<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar
      sliding
      no-shadow
      :back-link="$t('common.backButton')"
      :back-link-show-text="false"
    >
      <f7-nav-title sliding>{{ this.title }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <!-- <div class="stickySection"> -->
    <!-- <div class="layout_content2">
        <div class="left_layout"></div>
        <div
          class="main_layout"
          style="
            position: sticky;
            top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
            position: -webkit-sticky;
          "
        >
          <f7-block-title
            medium
            inset
            strong
            style="white-space: normal; word-wrap: break-word"
          >
            {{ $t("mobiletopup.enterTheMobile") }}
          </f7-block-title>
          <f7-list dividers-ios strong-ios media-list id="mobileTtopUpForm">
            <f7-list-group>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">
                    {{ $t("common.phoneNumber") }}
                  </div>

                  <div class="item-input-wrap">
                    <input
                      type="tel"
                      required
                      id="phoneNumber"
                      validate
                      :value="this.telephone"
                      @input="telephone = $event.target.value"
                      @blur="checkPhoneNumber()"
                      autocomplete="tel"
                      aria-describedby="mobileNumber-hint"
                      :data-error-message="$t('mobiletopup.pleaseEnterphoneNumber')"
                    />
                  </div>
                </div>
                <div class="item-media">
                  <f7-link
                    v-if="f7.cordova || this.contactsIsSupported"
                    class="input-icon2 padding-right-half elevation-2"
                    @click="selectContact()"
                    icon-color="auto"
                    icon-ios="f7:person_2_square_stack"
                    icon-md="material:contacts"
                  ></f7-link>
                  <f7-link
                    v-if="
                      $keycloak.authenticated && !(f7.cordova || this.contactsIsSupported)
                    "
                    class="input-icon2 padding-right-half elevation-2"
                    @click="popupRecipientOpen()"
                    icon-color="auto"
                    icon-ios="f7:person_2_square_stack"
                    icon-md="material:contacts"
                  ></f7-link>
                </div>
              </li>
              <f7-list-item
                :title="$t('shopcreatenew.selectCountry')"
                class="selectCountry item-label"
                smart-select
                :smart-select-params="{
                  openIn: !isTablet || isCordova ? 'popup' : 'page',
                  searchbar: true,
                  searchbarPlaceholder: $t('shopcreatenew.findCountry'),
                  closeOnSelect: true,
                }"
                style="display: none"
              >
                <select
                  name="country"
                  v-model="selectedCountry"
                  v-if="this.airtimeCountries !== ''"
                >
                  <option
                    v-for="airtimecountry in this.airtimeCountries"
                    :key="airtimecountry.CountryIso"
                    :value="airtimecountry.CountryIso"
                    :data-option-image="
                      'https://flagcdn.com/' +
                      airtimecountry.CountryIso.toLowerCase() +
                      '.svg'
                    "
                    width="24"
                  >
                    {{
                      getCountry(airtimecountry) !== undefined
                        ? getCountry(airtimecountry).name
                        : airtimecountry.CountryName
                    }}
                    {{ airtimecountry.CountryIso }}
                    {{
                      airtimecountry.InternationalDialingInformation[0] !== undefined
                        ? "+" + airtimecountry.InternationalDialingInformation[0].Prefix
                        : ""
                    }}
                  </option>
                </select>
              </f7-list-item>

              <f7-list-item
                :title="$t('mobiletopup.searchContact')"
                class="selectRecipient"
                smart-select
                :smart-select-params="{
                  openIn: !isTablet || isCordova ? 'popup' : 'page',
                  searchbar: true,
                  searchbarPlaceholder: $t('recipients.searchARecipient'),
                  closeOnSelect: true,
                }"
                style="display: none"
              >
                <select name="recipient" v-model="this.newRecipient">
                  <optgroup
                    v-for="(allRecipient, countryName, index) in this.recipientsList"
                    :key="index"
                    :label="countryName"
                  >
                    <option
                      v-for="recipient in allRecipient"
                      :key="recipient.id"
                      :value="recipient.id"
                    >
                      {{ recipient.name }}
                      {{ recipient.phone }}
                      {{ recipient.countryName }}
                    </option>
                  </optgroup>
                </select>
              </f7-list-item>
              <f7-block class="no-margin" v-if="this.telephone !== null">
                <span
                  v-if="
                    this.mobilePhoneError === 'NotAvalidMobileNumber' ||
                    (this.itiPhoneNumber !== '' && !this.itiPhoneNumber.isValidNumber())
                  "
                  style="font-size: 12px; font-style: italic; color: red"
                >
                  {{ $t("common.NotAValidPhoneNumber") }}
                </span>
              </f7-block>
            </f7-list-group>
          </f7-list>
        </div>
        <div class="right_layout"></div>
      </div>
    </div> -->
    <div class="layout_content2">
      <div class="left_layout">
        <div
          class="sticky with_content"
          v-if="this.selectedAirtimeProviders && this.selectedAirtimeProviders.length > 0"
        >
          <!-- <f7-page-content> -->
          <span class="stickyLeft">
            <f7-block-title large> {{ $t("common.providers") }}</f7-block-title
            ><f7-block-title
              medium
              inset
              strong
              style="white-space: normal; word-wrap: break-word"
            >
              {{ $t("mobiletopup.selectProvider") }}
            </f7-block-title></span
          >
          <f7-card
            v-if="this.selectedProvider !== ''"
            outline
            header-divider
            :class="'providerCard  provider' + this.selectedProvider.ProviderCode"
            style="height: 150px; width: 150px; display: table; margin: auto"
          >
            <f7-card-header style="font-size: small; height: 50px"
              >{{ selectedProvider.Name }}
              <f7-link
                icon-aurora="f7:xmark_circle"
                icon-ios="f7:xmark_circle"
                icon-md="material:highlight_off"
                color="red"
                href="#"
                style="position: absolute; right: 1px; top: 1px"
                @click="resetProvider()"
              ></f7-link
            ></f7-card-header>
            <f7-card-content style="height: 100px"
              ><img
                v-if="this.selectedProvider !== ''"
                :src="selectedProvider.LogoUrl"
                alt="provider logo"
                style="
                  width: 75px;
                  object-fit: cover;
                  position: absolute;
                  display: block;
                "
            /></f7-card-content>
          </f7-card>
          <div
            class="grid grid-cols-2"
            v-if="
              this.selectedAirtimeProviders && this.selectedAirtimeProviders.length > 0
            "
          >
            <span
              v-for="(item, index) in this.selectedAirtimeProviders"
              :key="index"
              v-if="this.selectedProvider === ''"
              ><a @click="selectProvider(item)" style="color: inherit">
                <f7-card style="height: 150px; min-width: 120px" outline header-divider>
                  <f7-card-header style="font-size: small; height: 50px"
                    >{{ item.Name }}
                    <img
                      :src="
                        'https://flagcdn.com/' +
                        getProductProvider(item.ProviderCode).CountryIso.toLowerCase() +
                        '.svg'
                      "
                      width="20"
                      height="20"
                      style="
                        float: right;
                        float: top;
                        right: 1px;
                        top: 1px;
                        position: absolute;
                        border-radius: 50%;
                      "
                  /></f7-card-header>
                  <f7-card-content style="height: 100px"
                    ><img
                      v-if="item !== null && item.LogoUrl !== null"
                      :src="item.LogoUrl"
                      alt="provider logo"
                      style="
                        width: 75px;
                        object-fit: cover;
                        display: block;
                        margin: auto;
                      "
                  /></f7-card-content>
                  <f7-button
                    raised
                    fill
                    small
                    ripple
                    color="green"
                    @click="selectProvider(item)"
                    >{{ $t("common.select") }}</f7-button
                  >
                </f7-card>
              </a>
            </span>
          </div>
          <!-- </f7-page-content> -->
        </div>
      </div>
      <div class="main_layout">
        <div
          class="country_phoneForm"
          style="
            position: sticky !important;
            top: 0;
            position: -webkit-sticky !important;
            z-index: 5000;
            margin-bottom: 32px;
          "
        >
          <f7-block-title
            medium
            inset
            strong
            style="white-space: normal; word-wrap: break-word"
          >
            {{ $t("mobiletopup.enterTheMobile") }}
          </f7-block-title>
          <f7-list dividers-ios strong-ios media-list id="mobileTtopUpForm">
            <f7-list-group>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">
                    {{ $t("common.phoneNumber") }}
                  </div>

                  <div class="item-input-wrap">
                    <input
                      type="tel"
                      required
                      id="phoneNumber"
                      validate
                      :value="this.telephone"
                      @input="telephone = $event.target.value"
                      @blur="checkPhoneNumber()"
                      autocomplete="tel"
                      aria-describedby="mobileNumber-hint"
                      :data-error-message="$t('mobiletopup.pleaseEnterphoneNumber')"
                    />
                  </div>
                </div>
                <div class="item-media">
                  <f7-link
                    v-if="f7.cordova || this.contactsIsSupported"
                    class="input-icon2 padding-right-half elevation-2"
                    @click="selectContact()"
                    icon-color="auto"
                    icon-ios="f7:person_2_square_stack"
                    icon-md="material:contacts"
                  ></f7-link>
                  <f7-link
                    v-if="
                      $keycloak.authenticated && !(f7.cordova || this.contactsIsSupported)
                    "
                    class="input-icon2 padding-right-half elevation-2"
                    @click="popupRecipientOpen()"
                    icon-color="auto"
                    icon-ios="f7:person_2_square_stack"
                    icon-md="material:contacts"
                  ></f7-link>
                </div>
              </li>
              <f7-list-item
                :title="$t('shopcreatenew.selectCountry')"
                class="selectCountry item-label"
                smart-select
                :smart-select-params="{
                  openIn: !isTablet || isCordova ? 'popup' : 'page',
                  searchbar: true,
                  searchbarPlaceholder: $t('shopcreatenew.findCountry'),
                  closeOnSelect: true,
                }"
                style="display: none"
              >
                <select
                  name="country"
                  v-model="selectedCountry"
                  v-if="this.airtimeCountries !== ''"
                >
                  <option
                    v-for="airtimecountry in this.airtimeCountries"
                    :key="airtimecountry.CountryIso"
                    :value="airtimecountry.CountryIso"
                    :selected="selectedCountry === airtimecountry.CountryIso"
                    :data-option-image="
                      'https://flagcdn.com/' +
                      airtimecountry.CountryIso.toLowerCase() +
                      '.svg'
                    "
                    width="24"
                  >
                    {{
                      getCountry(airtimecountry) !== undefined
                        ? getCountry(airtimecountry).name
                        : airtimecountry.CountryName
                    }}
                    {{ airtimecountry.CountryIso }}
                    {{
                      airtimecountry.InternationalDialingInformation[0] !== undefined
                        ? "+" + airtimecountry.InternationalDialingInformation[0].Prefix
                        : ""
                    }}
                  </option>
                </select>
              </f7-list-item>

              <f7-list-item
                :title="$t('mobiletopup.searchContact')"
                class="selectRecipient"
                smart-select
                :smart-select-params="{
                  openIn: !isTablet || isCordova ? 'popup' : 'page',
                  searchbar: true,
                  searchbarPlaceholder: $t('recipients.searchARecipient'),
                  closeOnSelect: true,
                }"
                style="display: none"
              >
                <select name="recipient" v-model="this.newRecipient">
                  <optgroup
                    v-for="(allRecipient, countryName, index) in this.recipientsList"
                    :key="index"
                    :label="countryName"
                  >
                    <option
                      v-for="recipient in allRecipient"
                      :key="recipient.id"
                      :value="recipient.id"
                    >
                      {{ recipient.name }}
                      {{ recipient.phone }}
                      {{ recipient.countryName }}
                    </option>
                  </optgroup>
                </select>
              </f7-list-item>
              <f7-block class="no-margin" v-if="this.telephone !== null">
                <!-- <span v-if="this.phoneNumberDetail !== null" style="font-size: 10px; font-style: italic; color: red">
            {{ this.phoneNumberDetail.carrier }}
            {{ this.phoneNumberDetail.geocoder }}
          </span> -->
                <span
                  v-if="
                    this.mobilePhoneError === 'NotAvalidMobileNumber' ||
                    (this.itiPhoneNumber !== '' && !this.itiPhoneNumber.isValidNumber())
                  "
                  style="font-size: 12px; font-style: italic; color: red"
                >
                  {{ $t("common.NotAValidPhoneNumber") }}
                </span>
              </f7-block>
            </f7-list-group>
          </f7-list>
        </div>
        <div class="selectedItems margin-vertical">
          <f7-card
            v-if="
              this.selectedProvider !== '' &&
              this.selectedProduct === '' &&
              !this.isTablet
            "
            outline
            header-divider
            :class="'providerCard  provider' + this.selectedProvider.ProviderCode"
            style="height: 150px; width: 150px; display: table; margin: auto"
          >
            <f7-card-header style="font-size: small; height: 50px"
              >{{ selectedProvider.Name }}
              <f7-link
                icon-aurora="f7:xmark_circle"
                icon-ios="f7:xmark_circle"
                icon-md="material:highlight_off"
                color="red"
                href="#"
                style="position: absolute; right: 1px; top: 1px"
                @click="resetProvider()"
              ></f7-link
            ></f7-card-header>
            <f7-card-content style="height: 100px"
              ><img
                v-if="this.selectedProvider !== ''"
                :src="selectedProvider.LogoUrl"
                alt="provider logo"
                style="
                  width: 75px;
                  object-fit: cover;
                  position: absolute;
                  display: block;
                "
            /></f7-card-content>
          </f7-card>
          <f7-card
            v-if="this.selectedProduct !== '' && this.selectedProduct !== ''"
            style="height: 400px; width: 300px; display: table; margin: auto"
            outline
            header-divider
            :class="'productCard  product' + this.selectedProduct.SkuCode"
          >
            <f7-card-header class="grid grid-cols-1" style="height: 150px">
              <span class="margin-bottom"
                ><f7-chip
                  :bg-color="theme.ios ? 'white' : ''"
                  :text-color="theme.ios ? 'primary' : ''"
                  media-bg-color="pink"
                  class="prodChip"
                  :text="$t('common.youSend')"
                  ><template #media>
                    <f7-icon
                      ios="f7:paperplane"
                      md="material:send"
                    /> </template></f7-chip
              ></span>
              <span v-if="!this.valueIsvariable" class="sendValue margin-top">{{
                $filtersToCurrency.toCurrency(
                  this.selectedProduct.Maximum.SendValue,
                  null,
                  this.selectedProduct.Maximum.SendCurrencyIso
                )
              }}</span>
              <div
                v-else
                :class="this.valueIsvariable ? 'grid grid-rows-2' : 'grid grid-rows-1'"
              >
                <span class="sendValue"
                  ><span style="margin-right: 8px; font-size: large">Max</span
                  >{{
                    $filtersToCurrency.toCurrency(
                      this.selectedProduct.Maximum.SendValue,
                      null,
                      this.selectedProduct.Maximum.SendCurrencyIso
                    )
                  }}</span
                >
                <span class="sendValue">
                  <span style="margin-right: 8px; font-size: large">Min</span
                  >{{
                    $filtersToCurrency.toCurrency(
                      this.selectedProduct.Minimum.SendValue,
                      null,
                      this.selectedProduct.Minimum.SendCurrencyIso
                    )
                  }}</span
                >
              </div>

              <f7-link
                icon-aurora="f7:xmark_circle"
                icon-ios="f7:xmark_circle"
                icon-md="material:highlight_off"
                color="red"
                href="#"
                style="position: absolute; right: 1px; top: 1px"
                @click="resetProduct()"
              ></f7-link>
            </f7-card-header>
            <f7-card-content class="grid grid-cols-1" style="height: 150px">
              <span class="margin-bottom"
                ><f7-chip
                  media-bg-color="primary"
                  class="prodChip"
                  :text="$t('common.theyGet')"
                  ><template #media>
                    <f7-icon
                      ios="f7:plus_circle"
                      md="material:add_circle"
                    /> </template></f7-chip
              ></span>

              <div
                v-if="
                  this.selectedProduct.LocalizationKey &&
                  getProductLocale(selectedProduct.LocalizationKey) !== '' &&
                  !this.valueIsvariable
                "
              >
                <span class="receiveValue">{{
                  getProductLocale(this.selectedProduct.LocalizationKey).DisplayText
                }}</span
                ><br /><span style="font-size: 80%">{{
                  getProductLocale(this.selectedProduct.LocalizationKey)
                    .DescriptionMarkdown
                }}</span>
              </div>
              <span class="receiveValue" v-else>{{
                this.selectedProduct.DefaultDisplayText
              }}</span>

              <f7-link
                href="#"
                @click="showDetail(selectedProduct)"
                style="font-size: small; font-style: italic"
                v-if="
                  selectedProduct.LocalizationKey &&
                  getProductLocale(selectedProduct.LocalizationKey)
                "
                >{{ $t("common.learnMore") }}</f7-link
              >
            </f7-card-content>
            <f7-card-footer
              style="display: flex; bottom: -8px; position: absolute"
              v-if="
                this.selectedAirtimeProviders !== '' &&
                this.selectedProduct !== '' &&
                getProductProvider(this.selectedProduct.ProviderCode)
              "
            >
              <img
                v-if="
                  this.selectedProduct !== '' &&
                  getProductProvider(this.selectedProduct.ProviderCode) &&
                  getProductProvider(this.selectedProduct.ProviderCode).LogoUrl !== ''
                "
                :src="getProductProvider(this.selectedProduct.ProviderCode).LogoUrl"
                alt="provider logo"
                style="object-fit: cover; max-height: 25px"
              />
              <div class="provider_footer_name">
                {{
                  getProductProvider(this.selectedProduct.ProviderCode).Name +
                  "-" +
                  getProductProvider(this.selectedProduct.ProviderCode).CountryIso
                }}
              </div>
            </f7-card-footer>
          </f7-card>
        </div>
        <div
          class="grid grid-rows-1 grid-gap not_sticky_with_content"
          style="marging-bottom: 64px"
        >
          <f7-link
            large
            href="#"
            v-if="
              this.selectedProvider === '' &&
              this.selectedProduct === '' &&
              this.selectedPromo === '' &&
              this.airtimeProviders &&
              this.airtimeProviders.length > 0
            "
            class="left not_sticky_with_content"
            style="position: absolute; left: 8px"
            popup-open=".providerPopup"
            ><f7-icon
              ios="f7:antenna_radiowaves_left_right"
              md="material:cell_tower"
              size="32px"
              color="green"
            ></f7-icon
          ></f7-link>

          <f7-link
            v-if="
              this.selectedProvider === '' &&
              this.selectedProduct === '' &&
              this.selectedPromo === '' &&
              this.airtimePromotions &&
              this.airtimePromotions.length > 0
            "
            class="right_layout not_sticky_with_content"
            icon-f7="speaker_zzz"
            icon-material="campaign"
            style="position: absolute; right: 8px"
            popup-open=".promoPopup"
            ><f7-icon
              ios="f7:speaker_zzz"
              md="material:campaign"
              size="32px"
              color="orange"
            ></f7-icon
          ></f7-link>
        </div>
        <f7-block-title
          class="padding-left"
          large
          v-if="
            this.airtimeProducts &&
            this.airtimeProducts.length > 0 &&
            this.selectedProduct === ''
          "
          :style="{
            'margin-left': !this.isTablet ? '25%' : 'auto',
          }"
        >
          {{ $t("common.products") }}
        </f7-block-title>

        <f7-block-title
          medium
          v-if="
            this.airtimeProducts &&
            this.airtimeProducts.length > 0 &&
            this.selectedProduct === ''
          "
          inset
          strong
          style="white-space: normal; word-wrap: break-word"
        >
          {{ $t("mobiletopup.selectProduct") }}
        </f7-block-title>

        <f7-toolbar
          icons
          v-show="
            this.airtimeProducts &&
            this.airtimeProducts.length > 0 &&
            this.selectedProduct === ''
          "
        >
          <f7-link
            v-for="(productsGroupList, productsGroup, index) in this
              .groupedAirtimeProducts"
            :key="index"
            :tab-link="`#tab-${index}`"
            :tab-link-active="index === 0"
            :text="getProductTabHtml(productsGroup).localized"
            ><span v-html="getProductTabHtml(productsGroup).icon"></span
          ></f7-link>
        </f7-toolbar>
        <f7-tabs v-show="this.airtimeProducts && this.selectedProduct === ''">
          <f7-tab
            v-for="(productsGroupList, productsGroup, index) in this
              .groupedAirtimeProducts"
            :key="productsGroup"
            :id="`tab-${index}`"
            :tab-active="index === 0"
            class="page-content"
          >
            <div class="grid xsmall-grid-cols-1 small-grid-cols-2">
              <f7-card
                grid
                outline
                header-divider
                style="height: 450px"
                class="productCard"
                v-for="(item, index) in productsGroupList"
                :key="index"
              >
                <f7-card-header class="grid grid-cols-1" style="height: 150px">
                  <span class="margin-bottom"
                    ><f7-chip
                      :bg-color="theme.ios ? 'white' : ''"
                      :text-color="theme.ios ? 'primary' : ''"
                      media-bg-color="pink"
                      class="prodChip"
                      :text="$t('common.youSend')"
                      ><template #media>
                        <f7-icon
                          ios="f7:paperplane"
                          md="material:send"
                        /> </template></f7-chip
                  ></span>
                  <div
                    :class="
                      item.Minimum.SendValue !== item.Maximum.SendValue
                        ? 'grid grid-rows-2'
                        : 'grid grid-rows-1'
                    "
                  >
                    <span class="sendValue"
                      ><span
                        style="margin-right: 8px; font-size: large"
                        v-if="item.Minimum.SendValue !== item.Maximum.SendValue"
                        >Max</span
                      >{{
                        $filtersToCurrency.toCurrency(
                          item.Maximum.SendValue,
                          null,
                          item.Maximum.SendCurrencyIso
                        )
                      }}</span
                    >

                    <div v-if="item.Maximum.SendValue !== item.Minimum.SendValue">
                      <span class="sendValue"
                        ><span style="margin-right: 8px; font-size: large">Min</span
                        >{{
                          $filtersToCurrency.toCurrency(
                            item.Minimum.SendValue,
                            null,
                            item.Minimum.SendCurrencyIso
                          )
                        }}</span
                      >
                    </div>
                  </div>
                  <img
                    v-if="
                      getProductProvider(item.ProviderCode) &&
                      getProductProvider(item.ProviderCode) !== ''
                    "
                    :src="
                      'https://flagcdn.com/' +
                      getProductProvider(item.ProviderCode).CountryIso.toLowerCase() +
                      '.svg'
                    "
                    width="28"
                    height="28"
                    style="
                      float: right;
                      float: top;
                      right: 1px;
                      top: 1px;
                      position: absolute;
                      border-radius: 50%;
                    "
                  />
                </f7-card-header>
                <f7-card-content class="grid grid-cols-1" style="height: 200px">
                  <span class="margin-bottom"
                    ><f7-chip
                      media-bg-color="primary"
                      class="prodChip"
                      :text="$t('common.theyGet')"
                      ><template #media>
                        <f7-icon
                          ios="f7:plus_circle"
                          md="material:add_circle"
                        /> </template></f7-chip
                  ></span>

                  <div
                    v-if="
                      item.LocalizationKey &&
                      getProductLocale(item.LocalizationKey) !== ''
                    "
                  >
                    <span class="receiveValue">{{
                      getProductLocale(item.LocalizationKey).DisplayText
                    }}</span
                    ><br /><span style="font-size: 80%">{{
                      getProductLocale(item.LocalizationKey).DescriptionMarkdown
                    }}</span>
                  </div>
                  <span class="receiveValue" v-else>{{ item.DefaultDisplayText }}</span>
                  <f7-link
                    href="#"
                    @click="showDetail(item)"
                    style="font-size: small; font-style: italic"
                    v-if="item.LocalizationKey && getProductLocale(item.LocalizationKey)"
                    >{{ $t("common.learnMore") }}</f7-link
                  >
                  <div
                    class="margin-left"
                    style="display: flex; bottom: -16px; position: absolute"
                    v-if="selectedAirtimeProviders !== null"
                  >
                    <img
                      v-if="
                        item &&
                        getProductProvider(item.ProviderCode) &&
                        getProductProvider(item.ProviderCode).LogoUrl !== ''
                      "
                      :src="getProductProvider(item.ProviderCode).LogoUrl"
                      alt="provider logo"
                      style="object-fit: cover; max-height: 25px"
                    />
                    <!-- </div> -->
                    <div
                      class="provider_footer_name"
                      v-if="item && getProductProvider(item.ProviderCode)"
                    >
                      {{
                        getProductProvider(item.ProviderCode).Name +
                        "-" +
                        getProductProvider(item.ProviderCode).CountryIso
                      }}
                    </div>
                  </div>
                </f7-card-content>

                <f7-button
                  raised
                  fill
                  large
                  ripple
                  color="red"
                  class="buttons"
                  @click="selectProduct(item)"
                  >{{ $t("common.select") }}</f7-button
                >
              </f7-card>
            </div>
          </f7-tab>
        </f7-tabs>

        <f7-list strong-ios dividers-ios>
          <f7-list-group v-if="this.selectedProduct && this.valueIsvariable">
            <f7-block-title medium>{{
              $t("mobiletopup.pleaseEnterAmount")
            }}</f7-block-title>
            <span class="grid grid-cols-2 medium-grid-cols-2">
              <f7-list-input
                type="number"
                :label="$t('common.youSend')"
                name="youSend"
                v-model:value="sendValue"
                @change="changeSendValue('chgeSendVal')"
                @keyup="changeSendValue('chgeSendVal')"
                @blur="changeSendValue('chgeSendVal')"
                pattern="[0-9]*"
                required
              >
                <template #media>
                  {{ this.selectedProduct.Minimum.SendCurrencyIso }}
                </template>
              </f7-list-input>
              <f7-list-input
                type="number"
                :label="$t('common.theyGet')"
                name="theyGet"
                v-model:value="receiveValue"
                @change="changeSendValue('chgeReceiveVal')"
                @keyup="changeSendValue('chgeReceiveVal')"
                @blur="changeSendValue('chgeReceiveVal')"
                pattern="[0-9]*"
                required
              >
                <template #media>
                  {{ this.selectedProduct.Minimum.ReceiveCurrencyIso }}
                </template>
              </f7-list-input>
            </span>
          </f7-list-group>
          <f7-list-group
            v-if="
              this.selectedProduct !== '' &&
              this.selectedProduct.SettingDefinitions.length > 0
            "
          >
            <f7-block-title medium>{{
              $t("mobiletopup.pleaseEnterExtraDetails")
            }}</f7-block-title>
            <f7-list-input
              v-for="setting in this.selectedProduct.SettingDefinitions"
              type="text"
              :label="setting.Description"
              :placeholder="setting.Description"
              :input-id="setting.ClientId"
              :name="setting.ClientId"
              v-model:value="setting.ClientId"
              :required="setting.IsMandatory"
              :validate="setting.IsMandatory"
              clear-button
            >
            </f7-list-input>
          </f7-list-group>
        </f7-list>

        <!-- <f7-button raised fill large ripple color="red" class="margin buttons" @click="topUpNow()"
          v-if="this.accountLookUp !== '' && this.selectedProduct !== ''">{{ $t("mobiletopup.topupMobileNow")
          }}</f7-button>

        <f7-button v-if="this.amountToPay != ''" class="margin buttons" ripple outline raised color="red"
          @click="cancelTopUp()">{{ $t("mobiletopup.cancelTopup") }}</f7-button> -->
        <f7-toolbar
          v-if="this.accountLookUp !== '' && this.selectedProduct !== ''"
          tabbar
          bottom
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        >
          <f7-link
            raised
            fill
            large
            class="link buttons"
            ripple
            @click="topUpNow()"
            strong
            text-color="white"
            >{{ $t("mobiletopup.topupMobileNow") }}</f7-link
          >
        </f7-toolbar>
      </div>
      <div class="right_layout">
        <div
          class="sticky with_content"
          v-if="
            this.groupedAirtimePromos &&
            this.groupedAirtimePromos.length > 0 &&
            this.selectedProduct === ''
          "
        >
          <f7-block-title large>{{ $t("common.promotions") }}</f7-block-title
          ><f7-block-title
            medium
            inset
            strong
            style="white-space: normal; word-wrap: break-word"
          >
            {{ $t("mobiletopup.selectPromotion") }}
          </f7-block-title>
          <div
            class="grid grid-cols-1"
            v-if="
              this.groupedAirtimePromos &&
              this.groupedAirtimePromos.length > 0 &&
              this.selectedProduct === ''
            "
          >
            <f7-card
              v-for="(item, index) in this.groupedAirtimePromos"
              :key="index"
              outline
              header-divider
              style="height: 400px"
            >
              <f7-card-header
                :paddng="false"
                v-if="item && getProductProvider(item.ProviderCode)"
                class="grid grid-cols-1"
                style="height: 150px"
              >
                <div class="grid grid-gap cols20_80">
                  <img
                    v-if="
                      item !== null &&
                      getProductProvider(item.ProviderCode) &&
                      getProductProvider(item.ProviderCode).LogoUrl !== null
                    "
                    :src="getProductProvider(item.ProviderCode).LogoUrl"
                    alt="provider logo"
                    style="object-fit: cover; margin: auto"
                  />
                  <div
                    style="font-size: medium"
                    v-if="item !== null && getProductProvider(item.ProviderCode)"
                  >
                    {{ getProductProvider(item.ProviderCode).Name }} <br />
                    {{
                      airtimeCountries.find(
                        (ctry) =>
                          ctry.CountryIso ===
                          getProductProvider(item.ProviderCode).CountryIso
                      ).CountryName
                    }}
                  </div>
                </div>
                <img
                  :src="
                    'https://flagcdn.com/' +
                    getProductProvider(item.ProviderCode).CountryIso.toLowerCase() +
                    '.svg'
                  "
                  width="28"
                  height="28"
                  style="
                    float: right;
                    float: top;
                    right: 1px;
                    top: 1px;
                    position: absolute;
                    border-radius: 50%;
                  "
                />
              </f7-card-header>

              <f7-card-content class="grid grid-cols-1" style="height: 150px">
                <div
                  class="col-100"
                  v-if="item.LocalizationKey && getPromoLocale(item.LocalizationKey)"
                >
                  <span class="receiveValue">{{
                    getPromoLocale(item.LocalizationKey).Headline
                  }}</span
                  ><br /><span>{{
                    getPromoLocale(item.LocalizationKey).BonusValidity
                  }}</span>
                </div>
                <span v-else>
                  {{
                    $filtersToCurrency.toCurrency(
                      item.MinimumSendAmount,
                      null,
                      item.CurrencyIso
                    )
                  }}</span
                >
                <span style="font-size: small; word-wrap: break-word"
                  >{{ $t("common.offerIsvalidUntil")
                  }}{{ $filtersToDate.toDateTime(item.EndUtc) }}</span
                >
                <f7-link
                  href="#"
                  @click="showDetail(item)"
                  style="font-size: small; font-style: italic"
                  v-if="item.LocalizationKey && getPromoLocale(item.LocalizationKey)"
                  >{{ $t("common.learnMore") }}</f7-link
                >
              </f7-card-content>
              <f7-button
                raised
                fill
                large
                ripple
                color="orange"
                class="buttons"
                @click="selectPromo(item)"
                >{{ $t("common.select") }}</f7-button
              >
            </f7-card>
          </div>
        </div>
      </div>
    </div>
    <f7-popup class="showDetail" swipe-to-close close-on-escape>
      <f7-page>
        <f7-navbar
          :title="
            this.detaiItem.Headline ? this.detaiItem.Headline : this.detaiItem.DisplayText
          "
        >
          <f7-nav-right>
            <f7-link popup-close=".showDetail">
              <f7-icon ios="f7:multiply" md="material:close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title medium style="word-wrap: break-word"
          ><span v-if="this.detaiItem.Headline">{{ this.detaiItem.Headline }}</span>
          <span v-if="this.detaiItem.DisplayText">{{
            this.detaiItem.DisplayText
          }}</span></f7-block-title
        >
        <f7-block strong-ios outline-ios v-if="this.detaiItem.Headline">
          <p v-html="this.detaiItem.TermsAndConditionsMarkDown"></p>
          <!-- {{ this.detaiItem.TermsAndConditionsMarkDown }} -->
        </f7-block>
        <f7-block strong-ios outline-ios v-if="this.detaiItem.DescriptionMarkdown">
          <p v-html="this.detaiItem.DescriptionMarkdown"></p>
          <br />
          <p v-html="this.detaiItem.ReadMoreMarkdown"></p>
        </f7-block>
      </f7-page>
    </f7-popup>
    <selectContact :type="'telephone'"></selectContact>
    <f7-popup class="providerPopup">
      <f7-page>
        <f7-navbar :title="$t('common.providers')">
          <f7-nav-right>
            <f7-link popup-close=".providerPopup">
              <f7-icon ios="f7:multiply" md="material:close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title
          large
          v-if="
            this.selectedAirtimeProviders &&
            this.selectedAirtimeProviders.length > 0 &&
            this.selectedProduct === ''
          "
        >
          {{ $t("common.providers") }}</f7-block-title
        ><f7-block-title
          v-if="
            this.selectedAirtimeProviders &&
            this.selectedAirtimeProviders.length > 0 &&
            this.selectedProduct === ''
          "
          medium
          inset
          strong
          style="white-space: normal; word-wrap: break-word"
        >
          {{ $t("mobiletopup.selectProvider") }}
        </f7-block-title>
        <div
          class="grid grid-cols-2"
          v-if="
            this.selectedAirtimeProviders &&
            this.selectedAirtimeProviders.length > 0 &&
            this.selectedPromo === '' &&
            this.selectedProduct === ''
          "
        >
          <span
            v-for="(item, index) in this.selectedAirtimeProviders"
            :key="index"
            v-if="this.selectedProvider === ''"
            ><a
              @click="selectProvider(item)"
              style="color: inherit"
              popup-close=".providerPopup"
            >
              <f7-card style="height: 150px; min-width: 120px" outline header-divider>
                <f7-card-header style="font-size: small; height: 50px"
                  >{{ item.Name }}
                  <img
                    :src="
                      'https://flagcdn.com/' +
                      getProductProvider(item.ProviderCode).CountryIso.toLowerCase() +
                      '.svg'
                    "
                    width="20"
                    height="20"
                    style="
                      float: right;
                      float: top;
                      right: 1px;
                      top: 1px;
                      position: absolute;
                      border-radius: 50%;
                    "
                /></f7-card-header>
                <f7-card-content style="height: 100px"
                  ><img
                    v-if="item !== null && item.LogoUrl !== null"
                    :src="item.LogoUrl"
                    alt="provider logo"
                    style="width: 75px; object-fit: cover; display: block; margin: auto"
                /></f7-card-content>
                <f7-button
                  raised
                  fill
                  small
                  ripple
                  color="green"
                  popup-close=".providerPopup"
                  @click="selectProvider(item)"
                  >{{ $t("common.select") }}</f7-button
                >
              </f7-card>
            </a>
          </span>
        </div>
      </f7-page>
    </f7-popup>

    <f7-popup class="promoPopup">
      <f7-page>
        <f7-navbar :title="$t('common.promotions')">
          <f7-nav-right>
            <f7-link popup-close=".promoPopup">
              <f7-icon ios="f7:multiply" md="material:close"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block-title large>{{ $t("common.promotions") }}</f7-block-title
        ><f7-block-title
          medium
          inset
          strong
          style="white-space: normal; word-wrap: break-word"
        >
          {{ $t("mobiletopup.selectPromotion") }}
        </f7-block-title>
        <div
          class="grid grid-cols-1"
          v-if="
            this.groupedAirtimePromos &&
            this.groupedAirtimePromos.length > 0 &&
            this.selectedProduct === ''
          "
        >
          <f7-card
            v-for="(item, index) in this.groupedAirtimePromos"
            :key="index"
            outline
            header-divider
            style="height: 400px"
          >
            <f7-card-header
              :paddng="false"
              v-if="item && getProductProvider(item.ProviderCode)"
              class="grid grid-cols-1"
              style="height: 150px"
            >
              <div class="grid grid-gap cols20_80">
                <img
                  v-if="
                    item !== null &&
                    getProductProvider(item.ProviderCode) &&
                    getProductProvider(item.ProviderCode).LogoUrl !== null
                  "
                  :src="getProductProvider(item.ProviderCode).LogoUrl"
                  alt="provider logo"
                  style="object-fit: cover; margin: auto"
                />
                <!-- </div> -->
                <div
                  style="font-size: medium"
                  v-if="item !== null && getProductProvider(item.ProviderCode)"
                >
                  {{ getProductProvider(item.ProviderCode).Name }} <br />
                  {{
                    airtimeCountries.find(
                      (ctry) =>
                        ctry.CountryIso ===
                        getProductProvider(item.ProviderCode).CountryIso
                    ).CountryName
                  }}
                </div>
              </div>
              <img
                :src="
                  'https://flagcdn.com/' +
                  getProductProvider(item.ProviderCode).CountryIso.toLowerCase() +
                  '.svg'
                "
                width="28"
                height="28"
                style="
                  float: right;
                  float: top;
                  right: 1px;
                  top: 1px;
                  position: absolute;
                  border-radius: 50%;
                "
              />
            </f7-card-header>

            <f7-card-content class="grid grid-cols-1" style="height: 150px">
              <div
                class="col-100"
                v-if="item.LocalizationKey && getPromoLocale(item.LocalizationKey)"
              >
                <span class="receiveValue">{{
                  getPromoLocale(item.LocalizationKey).Headline
                }}</span
                ><br /><span>{{
                  getPromoLocale(item.LocalizationKey).BonusValidity
                }}</span>
              </div>
              <span v-else>
                {{
                  $filtersToCurrency.toCurrency(
                    item.MinimumSendAmount,
                    null,
                    item.CurrencyIso
                  )
                }}</span
              >
              <span style="font-size: small; word-wrap: break-word"
                >{{ $t("common.offerIsvalidUntil")
                }}{{ $dayjs(item.EndUtc).format(LLLL) }}</span
              >
              <f7-link
                href="#"
                @click="showDetail(item)"
                style="font-size: small; font-style: italic"
                v-if="item.LocalizationKey && getPromoLocale(item.LocalizationKey)"
                >{{ $t("common.learnMore") }}</f7-link
              >
            </f7-card-content>
            <f7-button
              raised
              fill
              large
              ripple
              color="orange"
              popup-close=".promoPopup"
              class="buttons"
              @click="selectPromo(item)"
              >{{ $t("common.select") }}</f7-button
            >
          </f7-card>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
/* .sticky.with_content {
  position: sticky;
  top: 180px;
  position: -webkit-sticky;
} */

.page-content {
  z-index: 3;
}

.toolbar-top {
  top: 0px;
}

.toolbar {
  background-color: #fff;
}

.tabs .tab.page-content {
  padding-top: 20px;
}

.card {
  overflow: hidden;
  /* height: 300px; */
  background: var(--f7-card-expandable-bg-color);
  position: relative;
  transform-origin: center center;
  transition-property: transform, border-radius;
  border-radius: var(--f7-card-expandable-border-radius);
  z-index: 2;
  transition-duration: 0.2s;
  margin-left: calc(
    var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-left)
  );
  margin-right: calc(
    var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-right)
  );
  margin-top: var(--f7-card-expandable-margin-vertical);
  margin-bottom: var(--f7-card-expandable-margin-vertical);
  /* box-shadow: var(--f7-card-expandable-box-shadow); */
  font-size: var(--f7-card-expandable-font-size);
}

.productCard .card-header {
  background-color: var(--f7-theme-color);
  color: white;
  font-style: bold;
}

.card .button {
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  width: -moz-available;
}

.sendValue {
  font-size: xx-large;
  font-weight: 500;
  /* position: absolute; */
  /* top: 30px; */
}

.receiveValue {
  font-size: x-large;
  font-weight: 500;
  color: grey;
}

.provider_footer {
  position: relative;
  width: -webkit-fill-available;
  width: -moz-available;
  height: 20px;
}

.provider_footer_img {
  float: left;
  bottom: 0px;
  position: absolute;
  width: 15px;
}

.provider_footer_name {
  margin-left: 16px;
  font-size: 12px;
  color: var(--f7-card-footer-text-color);
  /* bottom: 0px;
  position: absolute; */
}

.prodChip {
  top: 0px;
  position: absolute;
  left: 0px;
}

div.chip-label {
  color: gray;
  font-weight: 500;
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavTitle,
  f7NavRight,
  f7BlockTitle,
  f7List,
  f7ListGroup,
  f7Link,
  f7ListItem,
  f7Block,
  f7FabButton,
  f7Popup,
  f7PageContent,
  f7Searchbar,
  f7Card,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { telephoneUtils } from "../js/mixins/telephone.js";
import { cordovaPlugin } from "../js/mixins/cordovaPlugins.js";
import Navbarright from "../components/navbarright.vue";
import selectContact from "../components/selectContact.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    selectContact,
    f7Page,
    f7Navbar,
    f7NavTitle,
    f7NavRight,
    f7BlockTitle,
    f7List,
    f7ListGroup,
    f7Link,
    f7ListItem,
    f7Block,
    f7FabButton,
    f7Popup,
    f7PageContent,
    f7Searchbar,
    f7Card,
    f7Icon,
  },
  mixins: [calculateTransferPayment, telephoneUtils, cordovaPlugin],
  data() {
    return {
      theme,
      f7,
      title: this.$t("common.mobiletopup"),
      amountToPay: "",
      telephone: "",
      paymentCurrency: "",
      countryPhoneCode: "",
      transferRateId: "",
      countryId: "",
      selectedCountry: "",
      itiPhoneNumber: "",
      newRecipient: "",
      theme,
      skuCodes: "",
      selectedProduct: "",
      selectedProvider: "",
      selectedPromo: "",
      providerCodes: [],
      benefits: "",
      regionCodes: "",
      accountNumber: "",
      localized: "",
      icon: "",
      contactsIsSupported: false,
      sendValue: "",
      receiveValue: "",
      valueIsvariable: false,
      redemptionMechanism: "Immediate",
      detaiItem: "",
      providerPanel: "",
    };
  },

  mounted() {
    const self = this;
    // f7.$(".mainToolbar").css("display", "none");
    f7.toolbar.hide(".mainToolbar");
    console.log("currentUserCountry", this.currentUserCountry);
    this.contactsIsSupported = "contacts" in navigator && "ContactsManager" in window;
    if (this.$keycloak.authenticated) {
      this.itiPhoneNumber.setNumber(this.profile.preferred_username.replace("+", ""));
      this.paymentCurrency = this.profile.currencyCode;
      this.selectedCountry = this.profile.countryCode;
    } else {
      this.selectedCountry = this.currentUserCountry.countryCode2;
    }
    console.log("this.selectedCountry base", this.selectedCountry);
    // this.itiPhoneNumber.setCountry(this.selectedCountry);

    if (this.$keycloak.authenticated && this.allrecipients === "") {
      self.$store.dispatch("remit/loadRecipients");
    }

    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.destinationCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadDestinationCtry");
    }
    setTimeout(() => {
      // if (this.selectedCountry !== "" && this.selectedCountry != undefined) {
      // this.itiPhoneNumber.setCountry(this.selectedCountry);
      // }
      if (this.airtimeCountries.length == 0) {
        self.$store.dispatch("airtime/getCountries");
        console.log("this.airtimeCountries", this.airtimeCountries);
      }
      if (this.airtimeCurrencies.length == 0) {
        self.$store.dispatch("airtime/getCurrencies");
      }
      if (
        this.selectedCountry !== "" &&
        this.selectedCountry != undefined &&
        this.airtimeProducts.length == 0
      ) {
        this.accountNumber =
          this.itiPhoneNumber !== ""
            ? this.itiPhoneNumber.getNumber().replace("+", "")
            : "";
        self.$store.dispatch("airtime/getProducts", {
          countryIsos: this.selectedCountry,
          skuCodes: this.skuCodes,
          providerCodes: this.providerCodes,
          benefits: this.benefits,
          regionCodes: this.selectedCountry,
          accountNumber: this.accountNumber,
        });
        // setTimeout(() => {
        //   this.getProductDescriptions();
        // }, 500);
      }
      if (
        this.selectedCountry !== "" &&
        this.selectedCountry != undefined &&
        this.selectedAirtimeProviders.length == 0
      ) {
        self.$store.dispatch("airtime/getProviders", {
          countryIsos: this.selectedCountry,
          accountNumber: this.accountNumber,
        });
      }
      if (
        this.selectedCountry !== "" &&
        this.selectedCountry != undefined &&
        this.airtimePromotions.length == 0
      ) {
        self.$store.dispatch("airtime/getPromotions", {
          countryIsos: this.selectedCountry,
        });
      }

      if (this.airtimePromoDesc.length == 0) {
        self.$store.dispatch("airtime/getPromotionDescriptions", {
          languageCodes: this.locale || navigator.language.split("-")[0],
        });
      }
    }, 1000);
    this.changePhoneNumber();

    const smartSelect = f7.smartSelect.get(".selectCountry .smart-select");
    f7.$(".iti__flag-container").click(function () {
      smartSelect.open();
      if (self.telephone !== "") {
        self.telephone = "";
      }
      smartSelect.setValue(this.selectedCountry);
    });
    f7.$(".selectCountry .smart-select").on("smartselect:open", () => {
      if (this.airtimeCountries.length == 0) {
        self.$store.dispatch("airtime/getCountries");
      }
      self.telephone = "";
    });
    f7.$(".selectCountry .smart-select").on("smartselect:close", () => {
      self.selectedCountry = smartSelect.getValue();
      console.log("self.selectedCountry ", self.selectedCountry);
      self.itiPhoneNumber.setCountry(self.selectedCountry);
      console.log(self.itiPhoneNumber.getSelectedCountryData());
      self.changePhoneNumber();
      this.updateCountry();
    });
    const smartSelectGetRecipient = f7.smartSelect.get(".selectRecipient .smart-select");
    f7.$(".selectRecipient .smart-select").on("smartselect:close", () => {
      self.newRecipient = smartSelectGetRecipient.getValue();
      const recipient = self.allrecipients.find((x) => x.id === self.newRecipient);
      console.log("self.recipient ", recipient);
      if (recipient === null) {
        return;
      }
      console.log(recipient.countryCode);
      self.itiPhoneNumber.setCountry(recipient.countryCode);
      self.itiPhoneNumber.setNumber(+"+" + recipient.phone);
      self.selectedCountry = recipient.countryCode;
      if (this.itiPhoneNumber.isValidNumber()) {
        console.log(self.itiPhoneNumber.getSelectedCountryData());
        console.log("getNumber", self.itiPhoneNumber.getNumber());
        this.telephone = self.itiPhoneNumber.getNumber(
          intlTelInputUtils.numberFormat.NATIONAL
        );
        console.log(
          "getNumber National",
          self.itiPhoneNumber.getNumber(intlTelInputUtils.numberFormat.NATIONAL)
        );
      }
      self.checkPhoneNumber();
      self.changePhoneNumber();
    });
  },
  methods: {
    getCountry(ctry) {
      return _.find(this.country, ["countryCode2", ctry.CountryIso])
        ? _.find(this.country, ["countryCode2", ctry.CountryIso])
        : undefined;
    },
    updateCountry() {
      if (this.selectedCountry === "" || this.selectedCountry == undefined) {
        return;
      }
      this.$store.dispatch("airtime/getProducts", {
        countryIsos: this.selectedCountry,
      });
      this.$store.dispatch("airtime/getProviders", {
        countryIsos: this.selectedCountry,
      });
      this.$store.dispatch("airtime/getPromotions", {
        countryIsos: this.selectedCountry,
      });
    },
    // getProductDescriptions() {
    //   const self = this;
    //   const skuCodesList = [];

    //   if (
    //     this.airtimeProducts.length != 0 &&
    //     this.airtimeProductDesc !== "" &&
    //     !_.find(this.airtimeProductDesc, [
    //       "LocalizationKey",
    //       this.airtimeProducts[0].LocalizationKey,
    //     ])
    //   ) {
    //     this.airtimeProducts.forEach((item) => {
    //       skuCodesList.push(item.SkuCode);
    //     });
    //     console.log("this.skuCodesList", skuCodesList);
    //   }
    //   setTimeout(() => {
    //     self.$store.dispatch("airtime/getProductDescriptions", {
    //       languageCodes:
    //         this.locale === "" ? navigator.language.split("-")[0] : this.locale,
    //       skuCodes: skuCodesList,
    //     });
    //   }, 500);
    // },
    getProductLocale(LocalizationKey) {
      return _.find(this.airtimeProductDesc, ["LocalizationKey", LocalizationKey]) !=
        undefined
        ? _.find(this.airtimeProductDesc, ["LocalizationKey", LocalizationKey])
        : "";
    },
    getPromoLocale(LocalizationKey) {
      return _.find(this.airtimePromoDesc, ["LocalizationKey", LocalizationKey]) !=
        undefined
        ? _.find(this.airtimePromoDesc, ["LocalizationKey", LocalizationKey])
        : "";
    },
    showDetail(item) {
      this.detaiItem = this.getProductLocale(item.LocalizationKey)
        ? this.getProductLocale(item.LocalizationKey)
        : this.getPromoLocale(item.LocalizationKey);
      f7.popup.open(".showDetail");
    },

    selectProduct(item) {
      this.selectedProduct = item;
      console.log("selectedProduct", this.selectedProduct);
      this.redemptionMechanism = this.selectedProduct.RedemptionMechanism;
      if (
        !(
          this.selectedProduct !== "" &&
          this.selectedProduct.Minimum.SendValue !==
            this.selectedProduct.Maximum.SendValue
        )
      ) {
        return;
      }
      this.sendValue = this.selectedProduct.Maximum.SendValue;
      this.receiveValue = this.selectedProduct.Maximum.ReceiveValue;
      this.valueIsvariable = true;
    },
    resetProduct() {
      // console.log("this.selectedProvider", `.product${this.selectedProduct.SkuCode}`);
      f7.$(`.product${this.selectedProduct.SkuCode}`).hide();
      this.selectedProduct = "";
      this.valueIsvariable = false;
      this.redemptionMechanism = "Immediate";
      if (this.selectedProvider !== "") {
        this.resetProvider();
      }
    },
    selectProvider(item) {
      this.selectedProvider = item;
      console.log(this.selectedProvider);
    },
    resetProvider() {
      // f7.$(`.provider${this.selectedProvider.ProviderCode}`).hide();
      this.selectedProvider = "";
      if (this.selectedProduct !== "") {
        this.resetProduct();
      }
    },
    selectPromo(item) {
      this.selectedPromo = item;
      console.log(this.selectedPromo);
    },
    resetPromo(item) {
      // f7.$(`.provider${this.selectedProvider.ProviderCode}`).hide();
      this.selectedPromo = "";
    },
    getProductProvider(providerCode) {
      return _.find(this.selectedAirtimeProviders, ["ProviderCode", providerCode]);
    },

    getProductTabHtml(productsGroup) {
      if (productsGroup === "Mobile,Minutes") {
        return {
          localized: this.$t("mobiletopup.topUp"),
          icon: `
            <i class="f7-icons  if-not-md">phone_circle</i>
            <i class="material-icons  md-only">call</i>
            `,
        };
      } else if (productsGroup === "Mobile,Minutes,Data") {
        return {
          localized: this.$t("mobiletopup.MobileMinData"),
          icon: `
            <i class="f7-icons  if-not-md">wifi</i>
            <i class="material-icons  md-only">cell_wifi</i>
            `,
        };
      } else if (productsGroup === "Mobile,Data") {
        return {
          localized: this.$t("mobiletopup.MobileData"),
          icon: `
            <i class="f7-icons  if-not-md">device_phone_portrait</i>
            <i class="material-icons  md-only">wifi</i>
            `,
        };
      } else if (
        productsGroup === "Utility,Electricity,TV" ||
        productsGroup === "Electricity,TV" ||
        productsGroup === "Utility,TV" ||
        productsGroup === "Utility,Electricity" ||
        productsGroup.includes("Internet") ||
        productsGroup.includes("Utility")
      ) {
        return {
          localized: this.$t("mobiletopup.utility"),
          icon: `
            <i class="f7-icons  if-not-md">house</i>
            <i class="material-icons  md-only">home</i>
            `,
        };
      } else if (productsGroup === "LongDistance") {
        return {
          localized: this.$t("mobiletopup.calls"),
          icon: `
            <i class="f7-icons  if-not-md">globe</i>
            <i class="material-icons  md-only">phone_arrow_up_right</i>
            `,
        };
      } else if (productsGroup === "Balance") {
        return {
          localized: this.$t("mobiletopup.balance"),
          icon: `
            <i class="f7-icons  if-not-md">square_arrow_up_on_square</i>
            <i class="material-icons  md-only">phonelink_ring</i>
            `,
        };
      } else {
        return {
          localized: this.$t("mobiletopup.other"),
          icon: `
            <i class="f7-icons if-not-md">square_arrow_up_on_square</i>
            <i class="material-icons  md-only">phonelink_ring</i>
            `,
        };
      }
    },

    checkPhoneNumber() {
      this.changePhoneNumber();
      if (this.itiPhoneNumber !== "" && this.itiPhoneNumber.isValidNumber()) {
        this.accountNumber = this.itiPhoneNumber.getNumber().replace("+", "");
        this.$store.dispatch("airtime/getAccountLookUp", {
          accountNumber: this.accountNumber,
        });
      }
      setTimeout(() => {
        const providerCode =
          this.accountLookUp.length > 0 && this.accountLookUp.ProviderCode
            ? this.accountLookUp.ProviderCode
            : "";
        const RegionCode =
          this.accountLookUp.length > 0 && this.accountLookUp.RegionCode
            ? this.accountLookUp.RegionCode
            : "";
        const countryIso =
          this.itiPhoneNumber !== "" && this.itiPhoneNumber.getSelectedCountryData().iso2
            ? this.itiPhoneNumber.getSelectedCountryData().iso2
            : this.selectedCountry;
        this.accountNumber =
          this.itiPhoneNumber !== "" && this.itiPhoneNumber.isValidNumber()
            ? this.itiPhoneNumber.getNumber().replace("+", "")
            : "";
        if (
          phoneNumber !== "" ||
          countryIso !== "" ||
          RegionCode !== "" ||
          providerCode !== ""
        ) {
          this.$store.dispatch("airtime/getProviders", {
            accountNumber: this.accountNumber,
            providerCodes: providerCode,
            regionCodes: RegionCode,
            countryIsos: countryIso,
          });
          this.$store.dispatch("airtime/getProducts", {
            accountNumber: this.accountNumber,
            countryIsos: countryIso,
            providerCodes: providerCode,
            regionCodes: RegionCode,
          });
        }
        if (phoneNumber !== "" || countryIso !== "" || providerCode !== "") {
          this.$store.dispatch("airtime/getPromotions", {
            accountNumber: this.accountNumber,
            countryIsos: countryIso,
            providerCodes: providerCode,
          });
        }
      }, 300);
    },
    async popupRecipientOpen() {
      console.log("allrecipients", this.allrecipients);
      if (this.allrecipients === null) {
        await this.$store.dispatch("remit/loadRecipients");
      }
      if (this.country === "" || !this.country) {
        this.$store.dispatch("location/getCountry");
      }
      const selectGetRecipient = f7.smartSelect.get(".selectRecipient .smart-select");
      this.telephone = "";
      this.countryPhoneCode = "";
      this.newRecipient = "";
      this.itiPhoneNumber.setNumber("");
      this.itiPhoneNumber.setCountry("");
      selectGetRecipient.open();
    },

    async changeSendValue(chgeType) {
      if (
        chgeType === "chgeSendVal" &&
        this.sendValue < this.selectedProduct.Minimum.SendValue
      ) {
        setTimeout(() => {
          this.sendValue = this.selectedProduct.Minimum.SendValue;
        }, 500);
      }
      if (
        chgeType === "chgeReceiveVal" &&
        this.receiveValue > this.selectedProduct.Maximum.ReceiveValue
      ) {
        setTimeout(() => {
          this.receiveValue = this.selectedProduct.Maximum.ReceiveValue;
        }, 500);
      }

      await this.$store.dispatch("airtime/getEstimate", [
        {
          SendValue: chgeType === "chgeSendVal" ? this.sendValue : 0,
          ReceiveValue: chgeType === "chgeReceiveVal" ? this.receiveValue : 0,
          SendCurrencyIso:
            chgeType === "chgeSendVal"
              ? this.selectedProduct.Minimum.SendCurrencyIso
              : "",
          SkuCode: this.selectedProduct.SkuCode,
          BatchItemRef: "1",
        },
      ]);
      setTimeout(() => {
        if (this.airtimeEstimatedPrice !== "" && this.airtimeEstimatedPrice.length > 0) {
          if (chgeType === "chgeSendVal") {
            this.receiveValue = this.airtimeEstimatedPrice[0].Price.ReceiveValue;
          }
          if (chgeType === "chgeReceiveVal") {
            this.sendValue = this.airtimeEstimatedPrice[0].Price.SendValue;
          }
        }
      }, 700);
    },

    topUpNow() {
      const settings = [];
      const partner_ref = this.crypto.randomUUID();
      const amount =
        this.valueIsvariable && this.sendValue !== ""
          ? this.sendValue
          : this.selectedProduct.SendValue;
      for (const setting of this.selectedProduct.SettingDefinitions) {
        settings.push({
          Name: setting.ClientId,
          Value: f7.$(`#${setting.ClientId}`).val(),
        });
      }
      const airTimeTransfer = {
        SkuCode: this.selectedProduct.SkuCode,
        SendValue: amount,
        SendCurrencyIso: this.selectedProduct.SendCurrencyIso,
        AccountNumber: this.accountNumber,
        ValidateOnly: false,
        DistributorRef: partner_ref,
        Settings: settings,
      };
      // this.selectedProduct.LocalizationKey &&
      //           getProductLocale(selectedProduct.LocalizationKey) !== '' &&
      //           !this.valueIsvariable
      //         "
      //       >
      //         <span class="receiveValue">{{
      //           getProductLocale(this.selectedProduct.LocalizationKey).DisplayText
      //         }}</span
      //         ><br /><span style="font-size: 80%">{{
      //           getProductLocale(this.selectedProduct.LocalizationKey).DescriptionMarkdown
      //         }}</span>
      //       </div>
      //       <span class="receiveValue" v-else>{{
      //         this.selectedProduct.DefaultDisplayText
      const description =
        this.selectedProduct.LocalizationKey &&
        this.getProductLocale(selectedProduct.LocalizationKey) !== ""
          ? this.getProductLocale(this.selectedProduct.LocalizationKey).DisplayText
          : this.selectedProduct.DefaultDisplayText;
      const baseTransfer = {
        partner_ref,
        payer_account_id: this.userAccount.standard.id,
        sender_wallet_id: null,
        receiver_wallet_id: null,
        amount,
        description,
        tag: this.$t("common.mobiletopup"),
        lang: this.locale || navigator.language.split("-")[0],
        trxType: "AIRT",
        return_url: "",
        return_url_fail: "",
      };

      if (f7.input.validateInputs("#mobileTtopUpForm")) {
        f7.popup.open(".mobileTopUpSuccess");
        setTimeout(() => {
          this.amountToPay = "";
          this.$store.dispatch("transaction/resetPaymentDetail");
          f7.popup.close(".mobileTopUpSuccess");
        }, 10000);
      } else {
        var valid = f7.$("#mobileTtopUpForm .input-invalid").length === 0;
      }
    },

    cancelTopUp() {
      f7.dialog.confirm("Do you want to cancel the top-up?", () => {
        this.amountToPay = "";
        this.$store.dispatch("transaction/resetPaymentDetail");
      });
    },
  },
  computed: {
    ...mapState("auth", ["profile", "isTablet", "isCordova", "locale"]),
    ...mapState("account", ["userByAccountNumber", "userAccount", "userDetail"]),
    ...mapState("airtime", [
      "accountLookUp",
      "airtimeCountries",
      "airtimeCurrencies",
      "airtimeProducts",
      "airtimeProductDesc",
      "airtimePromotions",
      "airtimePromoDesc",
      "airtimeProviders",
      "airtimeProviderStatus",
      "airtimeRegions",
      "airtimeBalance",
      "airtimeEstimatedPrice",
      "airtimeTransactionList",
      "errorDescriptions",
    ]),
    ...mapState("remit", ["recipients", "allrecipients"]),
    ...mapState("location", [
      "country",
      "destinationCity",
      "currentUserCountry",
      "phoneNumberDetail",
      "mobilePhoneError",
    ]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", ["grossexchangerate2"]),

    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    recipientCountry() {
      return _.find(this.destinationCountry, ["toCountryCode2", this.selectedCountry]);
    },

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },

    countries() {
      return _.groupBy(this.country, "continentName");
    },
    selectedAirtimeProviders() {
      return this.selectedProduct === ""
        ? this.airtimeProviders
        : this.airtimeProviders.filter(
            (provider) => provider.ProviderCode === this.selectedProduct.ProviderCode
          );
    },
    airtimeProdMinutes() {
      return this.selectedProvider === ""
        ? _.filter(
            this.airtimeProducts,
            (prod) => prod.Benefits.includes("Mobile") && !prod.Benefits.includes("Data")
          )
        : _.filter(
            this.airtimeProducts,
            (prod) =>
              prod.Benefits.includes("Mobile") &&
              !prod.Benefits.includes("Data") &&
              prod.ProviderCode === this.selectedProvider.ProviderCode
          );
    },
    airtimeProdPlans() {
      return this.selectedProvider === ""
        ? _.filter(this.airtimeProducts, (prod) => prod.Benefits.includes("Data"))
        : _.filter(
            this.airtimeProducts,
            (prod) =>
              prod.Benefits.includes("Data") &&
              prod.ProviderCode === this.selectedProvider.ProviderCode
          );
    },
    airtimeProdUtility() {
      return this.selectedProvider === ""
        ? _.find(
            this.airtimeProducts,
            (prod) =>
              prod.Benefits.includes("Utility") ||
              prod.Benefits.includes("Electricity") ||
              prod.Benefits.includes("TV") ||
              prod.Benefits.includes("Internet")
          )
        : _.find(
            this.airtimeProducts,
            (prod) =>
              (prod.Benefits.includes("Utility") ||
                prod.Benefits.includes("Electricity") ||
                prod.Benefits.includes("TV") ||
                prod.Benefits.includes("Internet")) &&
              prod.ProviderCode === this.selectedProvider.ProviderCode
          );
    },
    airtimeProdLongDistance() {
      return this.selectedProvider === ""
        ? _.find(this.airtimeProducts, (prod) => prod.Benefits.includes("LongDistance"))
        : _.find(
            this.airtimeProducts,
            (prod) =>
              prod.Benefits.includes("LongDistance") &&
              prod.ProviderCode === this.selectedProvider.ProviderCode
          );
    },
    airtimeProdBalance() {
      return this.selectedProvider === ""
        ? _.find(this.airtimeProducts, (prod) => prod.Benefits.includes("Balance"))
        : _.find(
            this.airtimeProducts,
            (prod) =>
              prod.Benefits.includes("Balance") &&
              prod.ProviderCode === this.selectedProvider.ProviderCode
          );
    },
    groupedAirtimeProducts() {
      if (this.selectedProvider !== "" && this.selectedProvider.ProviderCode !== "") {
        console.log("this.selectProvider", this.selectedProvider);
        return _.chain(this.airtimeProducts.slice())
          .filter((prod) => prod.ProviderCode === this.selectedProvider.ProviderCode)
          .groupBy("Benefits")
          .value();
      } else {
        return _.chain(this.airtimeProducts.slice()).groupBy("Benefits").value();
      }
    },

    groupedAirtimePromos() {
      if (this.selectedProvider !== "" && this.selectedProvider.ProviderCode !== "") {
        return _.chain(this.airtimePromotions.slice())
          .filter((promo) => promo.ProviderCode === this.selectedProvider.ProviderCode)
          .value();
      } else if (
        this.selectedProduct !== "" &&
        this.selectedProduct.ProviderCode !== ""
      ) {
        return _.chain(this.airtimePromotions.slice())
          .filter((promo) => promo.ProviderCode === this.selectedProduct.ProviderCode)
          .value();
      } else {
        return this.airtimePromotions;
      }
    },
  },
};
</script>
