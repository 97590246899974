import axios from "axios";
// import base_url from "../base_url.json";
import _ from "lodash";
const delivery_path = import.meta.env.VITE_APP_URLITE_APP_URL + "delivery";
const pickup_path = import.meta.env.VITE_APP_URLITE_APP_URL + "pickup";
// const delivery = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      deliveryAccount: "",
      delivery: "",
      deliverer: "",
      pickuPointAccount: "",
      pickuPoint: "",
    },
    getters: {},
    mutations: {
      CREATE_DELIVERY_ACCOUNT(state, deliveryAccount) {
        state.deliveryAccount = deliveryAccount;
      },
      GET_DELIVERY_ACCOUNT(state, deliveryAccount) {
        state.deliveryAccount = deliveryAccount;
      },
      CREATE_DELIVERY(state, delivery) {
        state.delivery = delivery;
      },
      GET_DELIVERY(state, delivery) {
        state.delivery = delivery;
      },
      GET_DELIVERER(state, deliverer) {
        state.deliverer = deliverer;
      },
      GET_PICKUP_POINT_ACCOUNT(state, pickuPointAccount) {
        state.pickuPointAccount = pickuPointAccount;
      },
      GET_PICKUP_POINT(state, pickuPoint) {
        state.pickuPoint = pickuPoint;
      },
    },

    actions: {
      createDeliveryAccount({ commit }, payload) {
        axios({
          url: `${delivery_path}/create/account`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((deliveryAccount) => {
            commit("GET_DELIVERY_ACCOUNT", deliveryAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editDeliveryAccount({ commit }, payload) {
        axios({
          url: `${delivery_path}/edit/account`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((deliveryAccount) => {
            commit("GET_DELIVERY_ACCOUNT", deliveryAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getDeliveryAccount({ commit }) {
        axios({
          url: `${delivery_path}/account`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((deliveryAccount) => {
            commit("GET_DELIVERY_ACCOUNT", deliveryAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      addDeliverer({ commit }, payload) {
        axios({
          url: `${delivery_path}/add/deliverer`,
          method: "POST",
          data: payload.deliverer,
          params: {
            deliveryAccountId: payload.deliveryAccountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((deliveryAccount) => {
            commit("GET_DELIVERY_ACCOUNT", deliveryAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      removeDeliverer({ commit }, payload) {
        axios({
          url: `${delivery_path}/remove/deliverer`,
          method: "PUT",
          params: {
            deliveryAccountId: payload.deliveryAccountId,
            delivererId: payload.delivererId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((deliveryAccount) => {
            commit("GET_DELIVERY_ACCOUNT", deliveryAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createPickupPointAccount({ commit }, payload) {
        axios({
          url: `${pickup_path}/create/account`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuPointAccount) => {
            commit("GET_PICKUP_POINT_ACCOUNT", pickuPointAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      editPickupAccount({ commit }, payload) {
        axios({
          url: `${pickup_path}/edit/pickupAccount`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuPointAccount) => {
            commit("GET_PICKUP_POINT_ACCOUNT", pickuPointAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getPickupAccount({ commit }) {
        axios({
          url: `${pickup_path}/pickupAccount`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuPointAccount) => {
            commit("GET_PICKUP_POINT_ACCOUNT", pickuPointAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      addPickupPoint({ commit }, payload) {
        axios({
          url: `${pickup_path}/add/pickupPoint`,
          method: "POST",
          data: payload.pickupPoint,
          params: {
            pickupPointAccountId: payload.pickupPointAccountId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuPointAccount) => {
            commit("GET_PICKUP_POINT_ACCOUNT", pickuPointAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      editPickupPoint({ commit }, payload) {
        axios({
          url: `${pickup_path}/edit/pickupPoint`,
          method: "PUT",
          data: payload.pickupPoint,

          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuPointAccount) => {
            commit("GET_PICKUP_POINT_ACCOUNT", pickuPointAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      removePickupPoint({ commit }, payload) {
        axios({
          url: `${pickup_path}/remove/pickupPoint`,
          method: "PUT",
          params: {
            pickupPointAccountId: payload.pickupPointAccountId,
            pickupPointId: payload.pickupPointId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((pickuPointAccount) => {
            commit("GET_PICKUP_POINT_ACCOUNT", pickuPointAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createDelivery({ commit }, payload) {
        axios({
          url: `${delivery_path}/create/delivery`,
          method: "POST",
          data: payload.delivery,
          params: {
            shopOrderId: payload.shopOrderId,
            delivererAcctId: payload.delivererAcctId,
            delivererId: payload.delivererId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((delivery) => {
            commit("GET_DELIVERY", delivery);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editDelivery({ commit }, payload) {
        axios({
          url: `${delivery_path}/edit/delivery`,
          method: "PUT",
          params: {
            orderStatus: payload.orderStatus,
            deliveryId: payload.deliveryId,
            delivererId: payload.delivererId,
            deliveryAmt: payload.deliveryAmt,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((delivery) => {
            commit("GET_DELIVERY", delivery);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      updateDeliveryStatus({ commit }, payload) {
        axios({
          url: `${delivery_path}/update/status`,
          method: "PUT",
          params: {
            orderStatus: payload.orderStatus,
            deliveryId: payload.deliveryId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((delivery) => {
            commit("GET_DELIVERY", delivery);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
};
// export default delivery;
