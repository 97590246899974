<template>
  <f7-page :page-content="false" @page:init="onPageInit" hide-bars-on-scroll no-toolbar>
    <f7-navbar no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <!-- <f7-nav-title v-html="this.transactionTitle" style="white-space: nowrap; text-overflow: ellipsis"></f7-nav-title> -->
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <!-- :navigation="isTablet == true ? true : false" -->
    {{ this.transaction_status }}
    {{ this.result_type }}
    <div v-if="this.result_type !== ''" style="top: 20%">
      <stripeCheckoutResult></stripeCheckoutResult>
      <div v-if="this.transaction_status">{{ this.transaction_status }}</div>
      <f7-list media-list v-if="this.transaction">
        <f7-list-group>
          <f7-list-item accordion-item :title="transaction.senderName"
            :footer="$dayjs(transaction.creation_date).format('llll')" :text="transaction.tag" :badge="transactionStatus(transaction.status)
              ? transactionStatus(transaction.status).status
              : transaction.status
              " :badge-color="transactionStatus(transaction.status)
    ? transactionStatus(transaction.status).color
    : 'primary'
    " :class="'highlighted_horiz border-color-' +
    transactionStatus(transaction.status).color
    ">
            <template #subtitle style="font-weight: bold"><span v-if="transaction.status === 'CONFIRMED'" :style="'color:' + transaction.walletActivity &&
              transaction.walletActivity.type === 'CREDIT'
              ? 'primary'
              : 'red'
              ">
                <span v-html="transaction.walletActivity &&
                  transaction.walletActivity.type === 'CREDIT'
                  ? '+'
                  : '-'
                  "></span>
                {{
                  $filtersToCurrency.toCurrency(
                    (transaction.amount || 0).toFixed(2),
                    null,
                    transaction.currency
                  )
                }}</span><span v-else>
                {{
                  $filtersToCurrency.toCurrency(
                    (transaction.amount || 0).toFixed(2),
                    null,
                    transaction.currency
                  )
                }}</span>
            </template>
            <template #media>
              <img v-if="transaction.senderUserId !== null &&
                  getCurrentUser(transaction.senderUserId) &&
                  getCurrentUser(transaction.senderUserId).avatar !== ''
                  " :src="`data:image/png;base64,${getCurrentUser(transaction.senderUserId).avatar
      }`" width="24" />
              <f7-badge v-else-if="transaction.senderName" color="auto"
                style="height: 48px; width: 48px; font-size: 16px; border-radius: 50%">
                {{ $filtersGetInitial.getInitial(transaction.senderName) }}
              </f7-badge>
            </template>
            <f7-accordion-content>
              <f7-list dividers-ios strong-ios media-list>
                <f7-list-group v-if="transaction.status === 'AUTHORIZED'">
                  <span v-if="transaction.trxValidatorList.length > 0 &&
                    transaction.trxValidatorList.find(
                      (user) => user.useId === profile.sub
                    )
                    ">
                    <div class="grid cols70_30">
                      <f7-input type="text" :label="$t('common.transferCode')" disabled readonly class="padding-left-half"
                        :value="transaction.transferCode" />

                      <f7-input :label="$t('common.pin')" type="text" :placeholder="$t('common.pin')" inputmode="numeric"
                        input-id="transferPIN" :value="transaction.transferPIN" @input="transferPIN = $event.target.value"
                        @keyup.enter="validateTransaction(transaction)" pattern="[0-9]*" maxlength="4" size="4"
                        required></f7-input>
                    </div>
                    <f7-button raised fill ripple class="margin" color="red" strong
                      @click="validateTransaction(transaction)">{{ $t("common.validateTransfer") }}</f7-button>
                  </span>
                  <f7-list-item v-else>{{ $t("common.transferCode") }}:
                    {{ transaction.transferCode }}</f7-list-item>
                </f7-list-group>
                <f7-list-group v-if="transaction.trxValidatorList &&
                  transaction.trxValidatorList.length > 0
                  ">
                  <f7-list-item v-for="(approver, index) in transaction.trxValidatorList" :key="index"
                    :title="approver.name" :footer="approver.isValidated
                      ? $dayjs(approver.validation_date).format('llll	')
                      : ''
                      ">
                    <template #after>
                      <f7-chip outline :text="approver.isValidated
                        ? $t('common.confirmed')
                        : $t('common.pending')
                        " :color="approver.isValidated ? 'green' : 'deeporange'"></f7-chip></template>
                    <template #media>
                      <img v-if="approver.userId !== null &&
                        getCurrentUser(approver.userId) &&
                        getCurrentUser(approver.userId).avatar !== ''
                        " :src="`data:image/png;base64,${getCurrentUser(approver.userId).avatar
    }`" width="24" />
                      <f7-badge v-else-if="approver.name" color="auto" style="
                          height: 24px;
                          width: 24px;
                          font-size: 12px;
                          border-radius: 50%;
                        ">
                        {{ $filtersGetInitial.getInitial(approver.name) }}
                      </f7-badge>
                    </template>
                  </f7-list-item>
                </f7-list-group>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
    </div>
    <f7-tabs swipeable animated v-else>
      <!-- :tab-active="index === transactionIndex" -->
      <div class="page-content tab" v-for="(transaction, index) in this.transactionList" :id="`tab-${index}`" :key="index"
        @tab:show="selectedTransactionTabShow(index, transaction)" @tab:hide="selectedTransactionTabHide(index)">
        <f7-row>
          <f7-col width="0" medium="25" large="25" x-large="25"></f7-col>
          <f7-col width="100" medium="50" large="50" x-large="50">
            <f7-card expandable :class="`card-${index}`" swipe-to-close>
              <f7-card-content :padding="false">
                <f7-link card-close color="auto" class="card-opened-fade-in"
                  :style="{ position: 'absolute', right: '15px', top: '15px' }" icon-f7="xmark_circle_fill"></f7-link>

                <f7-card-header v-if="transaction.status === 'Paid' || transaction.status === 'Complete'
                  " class="bg-color-green display-block no-margin-top" text-color="white" :style="{
    height: '100px',
    'font-size': '100%',
    'white-space': 'normal',
    'word-wrap': 'break-word',
  }" v-html="transaction.description"></f7-card-header>

                <f7-card-header text-color="white" class="bg-color-orange display-block" v-html="transaction.description"
                  v-else-if="transaction.status === 'Pending'" :style="{
                    height: '100px',
                    'font-size': '100%',
                    'white-space': 'normal',
                    'word-wrap': 'break-word',
                  }"></f7-card-header>

                <f7-card-header text-color="white" class="bg-color-red display-block" v-html="transaction.description"
                  v-else-if="transaction.status === 'Cancelled'" :style="{
                    height: '100px',
                    'font-size': '100%',
                    'white-space': 'normal',
                    'word-wrap': 'break-word',
                  }"></f7-card-header>

                <f7-card-header text-color="white" :style="{
                  'font-size': '100%',
                  'white-space': 'normal',
                  'word-wrap': 'break-word',
                  background: 'rgb(131, 58, 180)',
                  background:
                    'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
                }">
                  <small :style="{
                    height: '200px',
                    opacity: 0.7,
                  }">{{ $t("recipienttransactiondetail.amount") }}
                    {{
                      $filtersToCurrency.toCurrency(
                        transaction.amount,

                        "",
                        transactions.currency
                      )
                    }}<br />
                    <!-- <span v-if="transaction.pickupTransaction !== null">{{ $t('mytransactiondetail.pickupAmount', { '1': transaction.pickupTransaction.receivedAmount
                      | currency(
                      transaction.pickupTransaction.toCurrencySymbol,
                      transaction.pickupTransaction.toCurrencyCode
                      ) }) }}</span>
                    <span v-if="transaction.topupTransaction !== null">{{ $t('mytransactiondetail.topupAmount', { '1': transaction.topupTransaction.receivedAmount
                      | currency(
                      transaction.topupTransaction.toCurrencySymbol,
                      transaction.topupTransaction.toCurrencyCode
                      ) }) }}</span>
                    <br />-->{{ $t("recipienttransactiondetail.transactionDate")
                    }}{{ $filtersToDate.toDateTime(transaction.date) }}<br />{{
  $t("recipienttransactiondetail.status")
}}
                    {{ transactionStatus(transaction)
                    }}<!-- <br />{{ $t('mytransactiondetail.transferCode', { '1': transaction.transferCode }) }}<br />
                    Transfer PIN:{{ transaction.transferPIN }}-->
                    <!-- </h5> -->
                  </small>
                </f7-card-header>
                <swiper-container :navigation="isTablet == true && !!transaction.qrCode ? true : false">
                  <swiper-slide>
                    <f7-list dividers-ios strong-ios medial-list>
                      <f7-list-item :title="$t('recipienttransactiondetail.amount')" :after="$filtersToCurrency.toCurrency(
                        transaction.amount,
                        '',
                        transactions.currency
                      )
                        "></f7-list-item>
                      <!-- <f7-list-item
                    v-if="transaction.pickupTransaction !== null"
                    :title="$t('mytransactiondetail.pickupAmount')"
                    :after="
                  transaction.pickupTransaction.receivedAmount
                    | currency(
                      transaction.pickupTransaction.toCurrencySymbol,
                      transaction.pickupTransaction.toCurrencyCode
                    )
                "
                  ></f7-list-item>
                  <f7-list-item
                    v-if="transaction.pickupTransaction !== null"
                    :title="$t('mytransactiondetail.transferCode')"
                    :after="transaction.pickupTransaction.transferCode"
                  ></f7-list-item>
                  <f7-list-item
                    v-if="transaction.pickupTransaction !== null"
                    :title="$t('mytransactiondetail.transferPin')"
                    :after="transaction.pickupTransaction.transferPIN"
                  ></f7-list-item>
                  <f7-list-item
                    v-if="transaction.topupTransaction !== null"
                    :title="$t('mytransactiondetail.topupAmount')"
                    :after="
                  transaction.topupTransaction.receivedAmount
                    | currency(
                      transaction.topupTransaction.toCurrencySymbol,
                      transaction.topupTransaction.toCurrencyCode
                    )
                "
                  ></f7-list-item>
                  <f7-list-item
                    v-if="transaction.topupTransaction !== null"
                    :title="$t('mytransactiondetail.transferCode')"
                    :after="transaction.topupTransaction.transferCode"
                  ></f7-list-item>
                  <f7-list-item
                    v-if="transaction.topupTransaction !== null"
                    :title="$t('mytransactiondetail.transferPin')"
                    :after="transaction.topupTransaction.transferPIN"
                  ></f7-list-item>-->
                      <f7-list-item v-if="transaction.date != null"
                        :title="$t('recipienttransactiondetail.transactionDate')"
                        :after="$filtersToDate.toDateTime(transaction.date)"></f7-list-item>
                      <!-- <f7-list-item v-else :title="$t('mytransactiondetail.date')" after></f7-list-item>
                  <f7-list-item
                    v-if="transaction.date != null"
                    :title="$t('mytransactiondetail.time')"
                    :after="transaction.date.substr(11, 8)"
                  ></f7-list-item>
                  <f7-list-item v-else :title="$t('mytransactiondetail.time')" after></f7-list-item> -->
                      <f7-list-item :title="$t('recipienttransactiondetail.type')"
                        :after="transaction.transactionType"></f7-list-item>
                      <f7-list-item>{{ $t("recipienttransactiondetail.status")
                      }}<f7-chip v-if="transaction.status === 'Paid' ||
  transaction.status === 'Complete'
  " :text="transactionStatus(transaction)" color="green"></f7-chip>
                        <f7-chip v-else-if="transaction.status === 'Pending'" :text="transactionStatus(transaction)"
                          color="orange"></f7-chip>
                        <f7-chip v-else-if="transaction.status === 'Cancelled'" :text="transactionStatus(transaction)"
                          color="red"></f7-chip>
                      </f7-list-item>
                    </f7-list>
                    <f7-card-footer>
                      <span v-html="transaction.description"></span>
                    </f7-card-footer>
                  </swiper-slide>
                  <swiper-slide v-if="!!transaction.qrCode">
                    <f7-block v-if="!!transaction.qrCode" class="margin">
                      <img v-if="!!transaction.qrCode" :src="`data:image/png;base64,${transaction.qrCode}`"
                        style="display: block; margin: auto" />
                    </f7-block>
                  </swiper-slide>
                </swiper-container>
              </f7-card-content>
            </f7-card>
          </f7-col>
          <f7-col width="0" medium="25" large="25" x-large="25"></f7-col>
        </f7-row>
        <!-- <template #fixed> -->
        <f7-fab position="center-bottom" :text="$t('recipientdetail.sendAgain')" color="red"
          @click="sendAgainFromAccount(transaction)" v-if="transaction.transactionType === 'avo¢ash pick-up' &&
            parseFloat(transaction.amount) < 0 &&
            transaction.debitCreditType === 'debit'
            ">
          <f7-icon aurora="f7:paperplane" ios="f7:paperplane" md="material:send"></f7-icon>
        </f7-fab>
        <f7-fab position="center-bottom" :text="$t('recipientdetail.topupAgain')" color="red"
          @click="topupAgainFromAccount(transaction)" v-if="transaction.transactionType === 'avo¢ash top-up' &&
            parseFloat(transaction.amount) < 0 &&
            transaction.debitCreditType === 'debit'
            ">
          <f7-icon aurora="f7:paperplane" ios="f7:paperplane" md="material:send"></f7-icon>
        </f7-fab>
        <f7-fab position="right-bottom" class="shareButton" color="auto" @click="socialShare(sharedContent)">
          <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
        </f7-fab>
        <!-- </template> -->
        <span :class="'msg' + transaction.id" style="display: none">{{ transaction.description }}
          <br />
          {{ $t("recipienttransactiondetail.transactionDate")
          }}{{ $filtersToDate.toDateTime(transaction.date) }} <br />{{
  $t("recipienttransactiondetail.amount")
}}
          <b>
            {{
              $filtersToCurrency.toCurrency(transaction.amount, "", transactions.currency)
            }}
          </b>
          <br />
          {{ transaction.counterPartName }}
          <br />{{ $t("common.transferCode") }}
          <b>{{ transaction.transferCode }}</b></span>
        <span :class="'subject' + transaction.id" style="display: none">
          {{ transaction.transactionType }} <br />
          {{ $t("recipienttransactiondetail.amount") }}
          <b>
            {{
              $filtersToCurrency.toCurrency(transaction.amount, "", transactions.currency)
            }}
          </b>
          <br />
          {{ $t("recipienttransactiondetail.status") }}
          {{ transactionStatus(transaction) }}</span>
      </div>
    </f7-tabs>
  </f7-page>
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Tabs,
  f7Card,
  f7CardContent,
  f7Link,
  f7CardHeader,
  f7List,
  f7ListItem,
  f7Chip,
  f7CardFooter,
  f7Block,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import stripeCheckoutResult from "../components/stripeCheckoutResult.vue";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { transferAgain } from "../js/mixins/transaction.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Tabs,
    stripeCheckoutResult,

    f7Card,
    f7CardContent,
    f7Link,
    f7CardHeader,

    f7List,
    f7ListItem,
    f7Chip,
    f7CardFooter,
    f7Block,
    f7Fab,
    f7Icon,
  },
  mixins: [transferAgain, socialShare],

  data() {
    return {
      theme,
      f7,
      showNavbar: "Show",
      sharedContent: "",
      transactionTitle: "",
      result_type: "",
      route_query: "",
      query_string: "",
      queryParams: "",
      transactionId: "",
      colors: [
        {
          color: "red",
          color_hex: "#ff3b30",
        },
        {
          color: "deeppurple",
          color_hex: "#673ab7",
        },
        {
          color: "deeporange",
          color_hex: "#ff6b22",
        },
        {
          color: "green",
          color_hex: "#4cd964",
        },
        {
          color: "pink",
          color_hex: "#ff2d55",
        },
        {
          color: "orange",
          color_hex: "#ff9500",
        },
        {
          color: "teal",
          color_hex: "#009688",
        },
        {
          color: "purple",
          color_hex: "#9c27b0",
        },
        {
          color: "lime",
          color_hex: "#cddc39",
        },
        {
          color: "blue",
          color_hex: "#2196f3",
        },
        {
          color: "lightblue",
          color_hex: "#5ac8fa",
        },
        {
          color: "yellow",
          color_hex: "#ffcc00",
        },
        {
          color: "#B5179E",
          color_hex: "#B5179E",
        },

        {
          color: "#086375",
          color_hex: "#086375",
        },
        {
          color: "#87677b",
          color_hex: "#87677b",
        },
      ],
    };
  },
  mounted() {
    console.log("this.f7route.query", this.f7route.query);
    this.result_type = this.f7route.params.result_type;
    this.route_query = this.f7route.query;
    // f7.preloader.show("multi");
    setTimeout(() => {
      if (
        this.result_type !== "" &&
        this.route_query !== "" &&
        !this.route_query.session_id &&
        this.$keycloak.authenticated
      ) {
        this.queryParams = this.f7route.query;

        const queryMapping = {
          partner_ref: "partner_ref",
          transactionId: "transactionId",
          bizao_mobile_order_id: "bizao_mobile_order_id",
          bizao_card_order_id: "bizao_card_order_id",
          wave_check_out_id: "wave_check_out_id",
          wave_pay_out_id: "wave_pay_out_id",
          bizao_batchNumber: "bizao_batchNumber",
        };

        this.query_string = {
          partner_ref: "",
          transactionId: "",
          bizao_mobile_order_id: "",
          bizao_card_order_id: "",
          wave_check_out_id: "",
          wave_pay_out_id: "",
          bizao_batchNumber: "",
        };

        for (const key in this.queryParams) {
          if (queryMapping[key]) {
            this.query_string[queryMapping[key]] = this.queryParams[key];
          }
        }
        this.$store.dispatch("apimoney/getTransactionStatus", this.query_string);
      }

      if (this.route_query.session_id && this.$keycloak.authenticated) {
        // this.$store.dispatch("stripe/getStripePublicKey");
        // setTimeout(() => {
        //   if (this.publishableKey) {
        //     this.stripe = loadStripe(this.publishableKey);
        //   }
        // }, 300);
        this.$store.dispatch(
          "stripe/getCheckOutSessionStatus",
          this.route_query.session_id
        );

        if (this.checkout_session.status == "open") {
          this.f7router.navigate("/check-out");
        } else if (this.checkout_session.status == "complete") {
          document.getElementById("success").classList.remove("hidden");
          document.getElementById("customer-email").textContent = session.customer_email;
        }
      }

      // if (
      //   this.primaryTransactionList === "" ||
      //   (this.businessTransactionList === "" && this.$keycloak.authenticated)
      // ) {
      //   this.$store.dispatch("transaction/loadPrimaryTransactionList");
      //   this.$store.dispatch("transaction/loadBusinessTransactionList");
      // }
      // if (
      //   (this.primaryAccount === "" || this.businessAccount === "") &&
      //   this.$keycloak.authenticated
      // ) {
      //   this.$store.dispatch("account/loadPrimaryAccount");
      //   this.$store.dispatch("account/loadBusinessAccount");
      // }

      const currentSwiper = f7.swiper.get(".tabs-animated-wrap.swiper");
      console.log("currentSwiper", currentSwiper);
      if (currentSwiper) {
        currentSwiper.mousewheel.enable();
        currentSwiper.keyboard.enable();
      }
      // f7.preloader.hide();
    }, 3500);
    this.transactionId =
      this.queryParams.transactionId ||
      this.queryParams.partner_ref ||
      this.queryParams.bizao_card_order_id ||
      this.queryParams.bizao_mobile_order_id ||
      this.queryParams.bizao_batchNumber ||
      this.queryParams.wave_check_out_id ||
      this.queryParams.wave_pay_out_id;
    if (this.transactionId) {
      this.$store.dispatch("apimoney/getTransaction", this.transactionId);
    } else if (this.queryParams.session_id) {
      this.$store.dispatch(
        "stripe/getCheckOutSessionStatus",
        this.queryParams.session_id
      );
    }
  },

  methods: {
    onPageInit() {
      f7.tab.show(`#tab-${this.transactionIndex}`);
      // this.share(this.currentTransaction);
      const currentSwiper = f7.swiper.get(".tabs-animated-wrap.swiper");
      console.log("currentSwiper", currentSwiper);
      if (currentSwiper) {
        currentSwiper.mousewheel.enable();
        currentSwiper.keyboard.enable();
      }
    },

    selectedTransactionTabShow(index, transaction) {
      this.transactionTitle = transaction.description;
      setTimeout(() => {
        f7.card.close(`.card-${index - 1}`);
        f7.card.close();
        this.share(transaction);
      }, 500);
    },
    selectedTransactionTabHide(index) {
      setTimeout(() => {
        f7.card.close(`.card-${index}`);
        f7.card.close();
      }, 500);
    },

    async share(myTransaction) {
      /*    const myTransaction = _.find(
        this.transactionListType,
        (transaction) => transaction.id == this.f7route.params.id
      ); */
      const files = [];
      const base64Files = [];
      if (myTransaction && myTransaction.qrCode && myTransaction.qrCode !== null) {
        const blob = await (
          await fetch(`data:image/png;base64,${myTransaction.qrCode}`)
        ).blob();
        const termination = blob.type.split("/")[1];
        const file = new File([blob], `${myTransaction.date}.${termination}`, {
          type: blob.type,
        });
        files.push(file);
        base64Files.push(`data:image/png;base64,${myTransaction.qrCode}`);
      }
      this.sharedContent = {
        message: f7.$(`.msg${myTransaction.id}`).text(),
        subject: f7.$(`.subject${myTransaction.id}`).text(),
        files,
        base64Files,
        // url: `https://www.avocash.com/#/my-transactions/detail/${this.f7route.params.transactiontype}/${myTransaction.id}`,
        link: `${this.f7router.currentRoute.path}`,
        short: false,
        linkBase: "group",
        imgUrl: "https://avocash.com/34x34.png",
      };

      this.$store.dispatch("auth/createDynamicLink", this.sharedContent);
      console.log(this.sharedContent);
    },

    transactionStatus(status) {
      const statusMap = {
        COMPLETE: { status: this.$t("common.confirmed"), color: "green" },
        SUCCESSFUL: { status: this.$t("common.confirmed"), color: "green" },
        Successful: { status: this.$t("common.confirmed"), color: "green" },
        CONFIRMED: { status: this.$t("common.confirmed"), color: "green" },
        SUCCEEDED: { status: this.$t("common.confirmed"), color: "green" },
        Succeeded: { status: this.$t("common.confirmed"), color: "green" },
        INITIATED: { status: this.$t("common.initiated"), color: "orange" },
        AUTHORIZED: {
          status: this.$t("common.authorized"),
          color: "deeporange",
        },
        CANCELLED: { status: this.$t("common.cancelled"), color: "red" },
        Canceled: { status: this.$t("common.cancelled"), color: "red" },
        CANCELED: { status: this.$t("common.cancelled"), color: "red" },
        TO_VALIDATE: { status: this.$t("common.to_validate"), color: "orange" },
        PENDING: { status: this.$t("common.to_validate"), color: "orange" },
        Pending: { status: this.$t("common.to_validate"), color: "orange" },
        INPROGRESS: { status: this.$t("common.to_validate"), color: "orange" },
        PROCESSING: { status: this.$t("common.to_validate"), color: "orange" },
        REJECTED: { status: this.$t("common.rejected"), color: "red" },
        FAILED: { status: this.$t("common.failed"), color: "red" },
        Failed: { status: this.$t("common.failed"), color: "red" },
        requires_payment_method: {
          status: this.$t("common.failed"),
          color: "red",
        },
        REFUNDED: { status: this.$t("common.refunded"), color: "teal" },
        TIMED_OUT_3DS: {
          status: this.$t("common.timed_out_3DS", { threeDS: "3DS" }),
          color: "red",
        },
        TIMED_OUT: { status: this.$t("common.timed_out"), color: "red" },
        EXPIRED: { status: this.$t("common.timed_out"), color: "red" },
      };
      return statusMap[status];
    },
  },

  computed: {
    ...mapState("transaction", ["primaryTransactionList", "businessTransactionList"]),
    ...mapState("remit", ["recipients", "allrecipients", "userDetail", "recipient"]),
    ...mapState("apimoney", [
      "paymentIntent",
      "transaction_status",
      "transaction",
      "standard",
      "business",
      "accountList",
      "transactionList",
      "apiWallet",
    ]),

    ...mapState("stripe", ["publishableKey", "checkout_session"]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userByAccountNumber",
      "getusererror",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "dynamicLink"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    currentTransaction() {
      const id = this.f7route.params.id;

      for (const element of this.transactionList) {
        if (element.id == id) {
          return element;
        }
      }
    },
    transactionIndex() {
      return _.findIndex(
        this.transactionList,
        (transaction) => transaction.id == this.f7route.params.id
      );
    },
    trx_status() {
      if (this.transaction_status) {
        if (this.transaction_status.body.body.status === "successful") {
          return "success";
        } else if (this.transaction_status.status === "pending") {
          return "warning";
        } else if (this.transaction_status.status === "failed") {
          return "danger";
        } else if (this.transaction_status.status === "cancelled") {
          return "danger";
        } else if (this.transaction_status.status === "processing") {
          return "warning";
        } else if (this.transaction_status.status === "requires_payment_method") {
          return "warning";
        } else if (this.transaction_status.status === "requires_confirmation") {
          return "warning";
        } else if (this.transaction_status.status === "requires_action") {
          return "warning";
        } else if (this.transaction_status.status === "processing") {
          return "warning";
        } else if (this.transaction_status.status === "requires_capture") {
          return "warning";
        } else if (this.transaction_status.status === "canceled") {
          return "danger";
        } else if (this.transaction_status.status === "requires_source") {
          return "warning";
        } else if (this.transaction_status.status === "requires_payment_method") {
        }
      }
    },
  },
};
</script>
