<template>
  <!-- <f7-page> -->
  <f7-list dividers-ios strong-ios media-list>
    <f7-block-header style="white-space: normal; word-wrap: break-word">{{
      $t("paydebitcard.enterPaymentInformation")
    }}</f7-block-header>
    <f7-list-group>
      <f7-list-input type="text" value="0000 0000 0000 0000" :label="$t('common.cardNumber')" required
        input-id="debitCardNumber" inputmode="numeric" validate @input="debitCardNumber = $event.target.value"
        @blur="sendCardInfo()" maxlength="19" size="19" :error-message="$t('common.cardNumberRequired')"
        clear-button></f7-list-input>
      <f7-row>
        <f7-col>
          <f7-list-input :label="$t('common.expiryDate')" type="datepicker"
            :placeholder="$t('paydebitcard.selectExpiryDate')" readonly @blur="sendCardInfo()" @change="sendCardInfo()"
            :value="expiryDate" @calendar:change="(value) => (expiryDate = value)" :calendar-params="{
              openIn: 'auto',
              closeOnSelect: true,
              headerPlaceholder: $t('common.selectExpiryDate'),
              header: true,
              footer: true,
              dateFormat: 'm/yyyy',
              minDate: new Date(),
            }"></f7-list-input>
        </f7-col>
        <f7-col>
          <f7-list-input type="text" placeholder="000" :label="$t('common.cvv')" inputmode="numeric" input-id="CVV"
            required value="000" validate @blur="sendCardInfo()" @change="sendCardInfo()"
            @input="CVV = $event.target.value" maxlength="3" size="3" autocomplete="off"
            :error-message="$t('common.noCvv')" clear-button></f7-list-input>
        </f7-col>
      </f7-row>
      <f7-list-input :label="$t('common.cardHolderName')" floating-label type="text" name="cardHolderName"
        :placeholder="$t('common.cardHolderName')" @blur="sendCardInfo()" @change="sendCardInfo()" :value="cardHolderName"
        @input="cardHolderName = $event.target.value" :error-message="$t('common.enterCardHolderName')" required validate
        clear-button></f7-list-input>
    </f7-list-group>
  </f7-list>
  <!-- </f7-page> -->
</template>
<style scoped></style>
<script>
import {
  f7List,
  f7ListGroup,
  f7BlockHeader,
  f7ListInput,


  theme,
  f7,
} from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [keyPadMixin],
  components: {
    f7List,
    f7ListGroup,
    f7BlockHeader,
    f7ListInput,


  },
  data() {
    return {
      theme,
      f7,
      debitCardNumber: "",
      CVV: "",
      cardHolderName: "",
      expiryDate: "",
    };
  },
  mounted() {
    const self = this;

    if (this.profile) {
      this.cardHolderName = this.profile.name;
    }
    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }
  },

  methods: {
    sendCardInfo() {
      const self = this;

      const d = new Date(this.expiryDate);
      const month = d.getMonth() + 1;
      const year = d.getFullYear();
      let mthStr = "";
      if (month < 10) {
        mthStr = "0" + month;
      } else {
        mthStr = month;
      }
      const actDate = mthStr + "/" + year;
      const cardInfo = {
        debitCardNumber: this.debitCardNumber,
        CVV: this.CVV,
        cardHolderName: this.cardHolderName,
        expiryDate: actDate,
      };
      if (
        this.debitCardNumber !== "" &&
        this.debitCardNumber.length >= 16 &&
        this.CVV &&
        this.CVV.length == 3 &&
        this.cardHolderName &&
        this.expiryDate !== ""
      ) {
        console.log(cardInfo);
        self.$store.dispatch("payment/getCardInfo", cardInfo);
        this.$emit("getCardInfo", cardInfo);
      }
    },
  },
  computed: {
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
    ...mapState("card", [
      "carditemlist",
      "cardbasket",
      "country",
      "queryerror",
    ]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
  },
};
</script>
