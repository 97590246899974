<template>
  <f7-page hide-bars-on-scroll @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove"
    @page:afterin="onPageBeforeIn">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{ title }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <f7-list dividers-ios strong-ios media-list v-if="((paymentTransaction === '' || paymentTransaction.isValidated === true) &&
      parseFloat(decodedQRCode.payerAccountNumber) === '') ||
      !decodedQRCode
      ">
      <div class="margin-vertical-half">
        <f7-block-title medium class="text-align-center" style="white-space: normal; word-wrap: break-word">{{
          $t("acceptcashwithqrcodescan.scanQrCode") }}</f7-block-title>
        <f7-row class="justify-content-center margin">
          <f7-link icon-size="50" icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder"
            icon-md="f7:qrcode_viewfinder" class="text-align-left" @click="scanQRCodeToClaim()"></f7-link>
        </f7-row>
      </div>
    </f7-list>
    <span v-if="(paymentTransaction === '' || paymentTransaction.isValidated === true) &&
      decodedQRCode
      ">
      <f7-list dividers-ios strong-ios id="acceptCashWithAcctNbr">
        <f7-list-group>
          <f7-block-header style="font-weight: normal" v-if="parseFloat(amountToPay) > 0 && paymentDetail !== ''">
            {{ $t("acceptcashwithqrcodescan.claim")
            }}<b>
              <span v-html="$filtersToCurrency.toCurrency(
                  paymentDetail.grossTransferAmount,
                  paymentDetail.fromCurrencySymbolNative,
                  paymentDetail.fromCurrencyCode
                )
                "></span></b>{{ $t("acceptcashwithqrcodescan.from")
  }}<b>{{ decodedQRCode.payer.name }} </b>
          </f7-block-header>

          <f7-list-item v-if="parseFloat(decodedQRCode.amount) > 0" :title="$t('acceptcashwithqrcodescan.amountToClaim')"
            :after="$filtersToCurrency.toCurrency(
              decodedQRCode.amount,
              '',
              decodedQRCode.paymentCurrency
            )
              "></f7-list-item>
        </f7-list-group>
        <f7-list-group v-if="parseFloat(decodedQRCode.amount) == 0">
          <f7-block-header style="font-weight: normal">{{ $t("acceptcashwithqrcodescan.enterTheAmount")
          }}<b>{{ decodedQRCode.payer.name }} </b>
          </f7-block-header>
          <li class="item-content item-input">
            <div class="item-media">
              {{ decodedQRCode.paymentCurrency }}
            </div>
            <div class="item-inner">
              <div class="item-title item-label">
                {{ $t("acceptcashwithqrcodescan.amountToClaim") }}
              </div>
              <div class="item-input-wrap">
                <input type="number" min="0" required validate data-error-message="Enter amount to claim!"
                  name="amountToPay" id="amountToPay" :value="amountToPay" @input="amountToPay = $event.target.value"
                  @change="changeAmountToPay()" @keyup="changeAmountToPay()" @blur="changeAmountToPay()" />
                <span class="input-clear-button"></span>
              </div>
            </div>
          </li>
        </f7-list-group>
        <f7-list-group>
          <f7-block-footer style="font-size: 12px" class="no-margin" v-if="parseFloat(amountToPay) > 0 &&
            decodedQRCode.paymentCurrency !== primaryAccount.currencyCode
            ">
            <p v-if="paymentDetail !== ''">
              {{ $t("acceptcashwithqrcodescan.youGet") }}
              <span v-html="$filtersToCurrency.toCurrency(
                paymentDetail.receivedAmount,
                paymentDetail.toCurrencySymbol,
                paymentDetail.toCurrencyCode
              )
                "></span>
            </p>
          </f7-block-footer>

          <f7-list-input :label="$t('common.description')" floating-label type="textarea" resizable name="description"
            :placeholder="$t('common.description')" :value="description || decodedQRCode.description"
            @input="description = $event.target.value"></f7-list-input>
        </f7-list-group>
      </f7-list>
      <f7-button raised fill ripple large color="red" class="margin" @click="claimNow()">{{
        $t("acceptcashwithqrcodescan.acceptPayment") }}</f7-button>
      <f7-button class="margin" ripple outline raised color="red" @click="cancelScan()">{{
        $t("acceptcashwithqrcodescan.rejectPayment")
      }}</f7-button>
    </span>
    <f7-list dividers-ios strong-ios v-if="paymentTransaction.isValidated === false" media-list form
      class="margin-vertical-half">
      <f7-list-group>
        <f7-block-title color="green" class="text-align-center">{{
          $t("acceptcashwithqrcodescan.paymentFrom", {
            payerName: paymentTransaction.payerName,
          })
        }}</f7-block-title>
        <f7-list-item :title="$t('acceptcashwithqrcodescan.youReceived')" :after="$filtersToCurrency.toCurrency(
          paymentTransaction.receivedPaymentAmount,
          paymentTransaction.toCurrencySymbol,
          paymentTransaction.toCurrencyCode
        )
          "></f7-list-item>
        <f7-block-title>{{ $t("common.validatePayment") }}</f7-block-title>
        <f7-row>
          <f7-col width="65" medium="80">
            <f7-list-item header="Transfer code" :title="paymentTransaction.transferCode"></f7-list-item>
          </f7-col>
          <f7-col width="25" medium="15">
            <f7-list-input type="password" floating-label :label="$t('common.pin')" :placeholder="$t('common.pin')"
              inputmode="numeric" :value="transferPIN" @input="transferPIN = $event.target.value" @keyup.enter="
                validateCreditTransaction(
                  paymentTransaction.transactionType,
                  paymentTransaction.transferCode
                )
                " pattern="[0-9]*" maxlength="4" size="4" required></f7-list-input>
          </f7-col>
        </f7-row>
      </f7-list-group>
    </f7-list>
    <f7-button v-if="paymentTransaction.isValidated === false" raised fill ripple large class="margin" color="red" strong
      @click="
        validateCreditTransaction(
          paymentTransaction.transactionType,
          paymentTransaction.transferCode
        )
        ">{{ $t("common.validateTransfer") }}</f7-button>
  </f7-page>
</template>
<script>
import Navbarright from "../components/navbarright.vue";
import userByAccountNumber from "../js/mixins/userByAccountNumber.js";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";
import { f7, theme } from "framework7-vue";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    paymentData: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [
    userByAccountNumber,
    scanQRCodeGeneral,
    calculateTransferPayment,
    transferAgain,
  ],
  data() {
    return {
      theme,
      f7,
      title: this.$t("acceptcashwithqrcodescan.title"),
      payerAccountNumber: "",
      amountToPay: "",
      recipientuser: "",
      toastText: "",
      transferPIN: "",
      description: "",
      toAccount: "",
    };
  },
  mounted: function () {
    const self = this;

    if (
      (primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }
    if (this.originCountry === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("transaction/loadOriginCountry");
    }
    this.toAccount = "Primary";

    this.connectPayService();
    if (this.decodedQRCode.amount) {
      this.amountToPay = parseFloat(this.decodedQRCode.amount);
      this.paymentCurrency = this.decodedQRCode.paymentCurrency;
      this.changeAmountToPay();
    }
  },
  methods: {
    async scanQRCodeToClaim() {
      const self = this;

      await this.scanQRCode();
      if (
        parseFloat(this.decodedQRCode.payerAccountNumber) !== 0 &&
        this.recipientCountry
      ) {
        this.amountToPay = parseFloat(this.decodedQRCode.amount);
        await this.changeAmountToPay();
      } else if (this.decodedQRCode.payerAccountNumber === "") {
        this.toastText = this.$t("acceptcashwithqrcodescan.notAClaimQRCode");
        return false;
      } else if (!this.recipientCountry) {
        this.toastText = this.$t("acceptcashwithqrcodescan.youCannotClaimFrom", {
          notAClaimCountry: this.userByAccountNumber.country.countryName,
        });
        return false;
      }
    },

    changeAmountToPay() {
      const self = this;

      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        self.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              this.calculatePayment();
            }, 200);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },

    onPageBeforeIn() {
      const self = this;

      this.changeAmountToPay();
    },
    onPageInit() {
      const self = this;

      setTimeout(() => {
        if (this.decodedQRCode.amount) {
          this.amountToPay = this.decodedQRCode.amount;
          this.paymentCurrency = this.decodedQRCode.paymentCurrency;
          self.$store.dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          });
        }
      }, 4000);
      if (this.grossexchangerate2) {
        this.changeAmountToPay();
      }
      console.log("Payment detail " + this.paymentDetail.grossTransferAmount);
    },

    claimNow() {
      const self = this;

      if (f7.input.validateInputs("#acceptCashWithAcctNbr")) {
        f7.preloader.show("multi");
        self.$store
          .dispatch("payment/makePayment", {
            type: "avo¢ash pay",
            amount: this.amountToPay,
            paymentCurrency: this.paymentCurrency,
            payerAccountNumber: this.decodedQRCode.payerAccountNumber,
            payerGeolocation: this.decodedQRCode.payerGeolocation,
            payerIpAddress: this.decodedQRCode.payeeIpAddress,

            payeeAccountNumber: this.payeeAccount.accountNumber,
            payeeGeolocation: this.latitude + "-" + this.longitude,
            payeeIpAddress: this.ipAddress,
            description: this.description,
          })
          .then((result) => {
            console.log(result);
            setTimeout(() => {
              if (this.toAccount === "Primary") {
                self.$store.dispatch("account/loadPrimaryAccount");
              } else {
                self.$store.dispatch("account/loadBusinessAccount");
              }
            }, 500);
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
      } else {
        var valid = f7.$("#acceptCashWithAcctNbr .input-invalid").length === 0;
      }
    },
    cancelScan() {
      const self = this;

      this.amountToPay = "";
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
      self.$store.dispatch("payment/setDecodedQRCode", "");
    },
    onPageBeforeRemove() {
      const self = this;

      this.cancelScan();
    },

    //  Issuing QR Code for the counterpart to scan and claim payment
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userByAccountNumber",
      "getusererror",
    ]),
    ...mapState("location", ["latitude", "longitude", "ipAddress"]),

    ...mapState("auth", ["profile"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    recipientCountry() {
      if (this.decodedQRCode) {
        return _.find(this.originCountry, [
          "fromCountryId",
          this.decodedQRCode.payer.country.countryId,
        ]);
      }
    },

    payeeAccount() {
      // if (this.toAccount === "Primary") {
      return this.primaryAccount;
      // } else {
      //   return this.businessAccount;
      // }
    },
    payerAccount() {
      // if (this.decodedQRCode.accountType === "Primary") {
      return this.decodedQRCode.payer.primaryAccount;
      // } else {
      //   return this.decodedQRCode.payer.businessAccount;
      // }
    },
  },
};
</script>
