import { f7, theme } from "framework7-vue";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
export const paymentConnect = {
  data() {
    return {
      theme,
      f7,
      received_messages: [],
      path: "",
      socket: "",
    };
  },
  mounted() {
    const self = this;
  },
  methods: {
    connectPayService() {
      const self = this;
      // console.log(this.$keycloak.token);
      // const path = `${import.meta.env.VITE_APP_URL_WEBSOKET}ws`;
      // ?access_token=' + auth.access_token
      // const path = `${import.meta.env.VITE_APP_URL}ws`;
      // const socket = new SockJS(path);
      // socket.binaryType = "blob";
      // this.messageSocketClient = Stomp.over(socket);
      // self.$store.dispatch("auth/getCsrfToken");
      // const headers = {};
      // setTimeout(() => {
      //   headers[self.csrfToken.headerName] = self.csrfToken.token;
      //   headers["Authorization"] = `Bearer ${this.$keycloak.token}`;
      //   console.log(" headers", headers);

      //   this.messageSocketClient.connect(
      //     // headers,
      //     {
      //       Authorization: `Bearer ${this.$keycloak.token}`,
      //       "X-CSRF-TOKEN": self.csrfToken.token,
      //     },
      //     (frame) => {
      //       this.connected = true;
      //       console.log(frame);
      this.messageSocketClient.subscribe("/topic/payment", (data) => {
        setTimeout(() => {
          if (data.body !== null) {
            f7.$(".buttonIssue").hide();
          }
          f7.dialog.alert(data.body);
        }, 2000);
      });

      this.messageSocketClient.subscribe(
        "/user/queue/payment-validate",
        (data) => {
          if (data.body === null) {
            return;
          }
          const payData = JSON.parse(data.body);
          console.log(data.body);
          self.$store.dispatch("payment/setDecodedQRCode", payData);
          f7.dialog
            .create({
              text: `Pay <b>${payData.amount} ${payData.paymentCurrency}</b> to <b>${payData.payee.name}</b>`,
              buttons: [
                {
                  text: "Pay",
                  color: "auto",
                  bold: true,
                  onClick() {
                    self.payNow();
                  },
                },
                {
                  text: "Cancel",
                  color: "red",
                  onClick() {
                    self.deleteQRCode();
                  },
                },
                {
                  text: "Close",
                  color: "gray",
                  close: true,
                },
              ],
              verticalButtons: true,
            })
            .open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/payment-inform",
        (data) => {
          if (data.body === null) {
            return;
          }
          console.log(data.body);
          const payData = JSON.parse(data.body);
          self.$store.dispatch("payment/setDecodedQRCode", payData);
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${payData.status}</b>`,
            closeButton: true,
          });
          notificationpayIsScanned.open();
        }
      );
      this.messageSocketClient.subscribe(
        "/user/queue/payment-code-expired",
        (data) => {
          if (data.body === null) {
            return;
          }
          console.log(data.body);
          const paymentCodeNotValid = f7.toast.create({
            text: data.body,
            cssClass: "checkCardToast",
            position: "center",
            closeButton: true,
          });

          paymentCodeNotValid.open();
        }
      );

      this.messageSocketClient.subscribe("/user/queue/payIsScanned", (data) => {
        if (data.body.payId === null) {
          return;
        }
        const payData = JSON.parse(data.body);
        self.$store.dispatch("pay/setScannedpay", JSON.parse(data.body));
        if (self.profile.sub === payData.payIssuerId) {
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
            text: `pay status: <b>${payData.status}</b>. Please get the top-up amount from customer before validating.<br/>Click to validate top-up or cancel it!`,
            closeOnClick: true,
            on: {
              close() {
                f7.dialog
                  .create({
                    text: "Validate to confirm top-up!",
                    buttons: [
                      {
                        text: "Validate top-up",
                        color: "auto",
                        bold: true,
                        onClick() {
                          self.validateTopUp();
                        },
                      },
                      {
                        text: "Cancel top-up",
                        color: "red",
                        onClick() {
                          self.deleteQRCode();
                        },
                      },
                      {
                        text: "Close",
                        close: true,
                      },
                    ],
                    verticalButtons: true,
                  })
                  .open();
              },
            },
          });
          notificationpayIsScanned.open();
        } else if (self.profile.sub === payData.recipientUserId) {
          const notificationpayIsScanned0 = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
            text: `pay status: <b>${payData.status}</b>. Please wait validation for your account to be topped-up!`,
            closeButton: true,
          });
          notificationpayIsScanned0.open();
        }
      });
      this.messageSocketClient.subscribe("/user/queue/payIsLocked", (data) => {
        if (data.body.id !== null) {
          console.log(data.body);
          const payData = JSON.parse(data.body);
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${payData.status}</b>`,
            closeButton: true,
          });
          notificationpayIsScanned.open();
        }
      });

      this.messageSocketClient.subscribe(
        "/user/queue/topUpValidated",
        (data) => {
          if (data.body.payId === null) {
            return;
          }
          const payData = JSON.parse(data.body);
          self.$store.dispatch("pay/setScannedpay", JSON.parse(data.body));
          if (self.profile.sub === payData.userId) {
            const notificationpayIsScanned = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
              text: `pay status: <b>${payData.status}</b>`,
              closeButton: true,
            });
            notificationpayIsScanned.open();
          } else if (self.profile.sub === payData.recipientId) {
            const notificationpayIsScanned1 = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
              text: `pay status: <b>${payData.status}</b><br/> Go to my account!`,
              closeOnClick: true,
              on: {
                close() {
                  // this.f7router.navigate("/dashboard/");
                  self.goToPrimaryAccount();
                },
              },
            });
            notificationpayIsScanned1.open();
          }
        }
      );

      this.messageSocketClient.subscribe("/user/queue/payNotActive", (data) => {
        if (data.body.id === null) {
          return;
        }
        console.log(data.body);
        const payData = JSON.parse(data.body);
        const notificationpayIsScanned = f7.notification.create({
          titleRightText: new Date().toLocaleString(),
          text: `<b>${payData.status}</b>`,
          closeButton: true,
        });
        notificationpayIsScanned.open();
      });
      //     },
      //     (error) => {
      //       console.log(error);
      //       this.connected = false;
      //     }
      //   );
      // }, 1000);
    },

    disconnectPaymentService() {
      // if (this.messageSocketClient) {
      //   this.messageSocketClient.disconnect();
      // }
      // this.connected = false;
      console.log("Disconnected!");
    },
    async goToPrimaryAccount() {
      f7.preloader.show("multi");
      await this.$store.dispatch("account/loadPrimaryAccount");
      f7.preloader.hide();
      await this.f7router.navigate(
        "/my-transactions/" +
          this.primaryAccount.accountNumber +
          "/primaryTransactionList"
      );
    },
  },
  computed: {
    ...mapState("auth", [
      "profile",
      "token",
      "user",
      "authenticated",
      "topath",
      "dynamicLink",
      "csrfToken",
    ]),
    ...mapState("message", ["messageSocketClient", "msgCltConnected"]),
  },
};
