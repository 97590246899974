<template>
  <f7-page class="fairia_page withMainToolbar" name="fairia">
    <f7-navbar sliding no-shadow v-if="!this.isTablet">
      <f7-nav-left>
        <navbarleft></navbarleft>
      </f7-nav-left>
      <f7-searchbar
        class="searchbar-post"
        :value="searchPost"
        @input="searchPost = $event.target.value"
        style="width: 90%"
        search-container=".search-list"
        search-item="div.itemImageContainer"
        search-in=".categoryTag"
        :clear-button="true"
      ></f7-searchbar>
      <f7-nav-right>
        <f7-link
          v-if="$keycloak.authenticated && !this.isTablet"
          raised
          data-panel=".dashboardPanel"
          class="panel-toggle"
        >
          <f7-badge
            v-if="!!this.profile && (!this.userAccount || !this.userAccount.avatar)"
            slot="media"
            color="primary"
            class="no-padding no-margin"
            :style="{
              height: theme.md ? '32px' : '28px',
              width: theme.md ? '32px' : '28px',
              'min-width': theme.md ? '32px' : '28px',
              'border-radius': '50%',
            }"
            >{{ $filtersGetInitial.getInitial(profile.name) }}</f7-badge
          >
          <img
            :src="`data:image/png;base64,${this.userAccount.avatar}`"
            v-if="this.userAccount && !!this.userAccount.avatar"
            :style="{
              height: theme.md ? '32px' : '28px',
              width: theme.md ? '32px' : '28px',
              'background-size': 'cover',
              'min-width': theme.md ? '32px' : '28px',
              display: 'block',
              'border-radius': '50%',
            }"
            slot="media"
            alt="user avatar"
          /> </f7-link
      ></f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout fairia_layout">
        <div class="sticky with_content" v-if="this.isTablet">
          <dashboardComponent></dashboardComponent>
        </div>
      </div>
      <div class="main_layout">
        <f7-card class="demo-facebook-card .search-list" v-if="isTablet">
          <f7-card-header class="no-border">
            <f7-searchbar
              class="searchbar-post"
              :value="searchPost"
              @input="searchPost = $event.target.value"
              search-container=".search-list"
              search-item="div.itemImageContainer"
              search-in=".categoryTag"
              :clear-button="true"
              :disable-button="!theme.aurora"
            ></f7-searchbar>
          </f7-card-header>
        </f7-card>
        <f7-card class="demo-facebook-card search-list">
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar">
              <img
                src="https://cdn.framework7.io/placeholder/people-68x68-1.jpg"
                width="34"
                height="34"
              />
            </div>
            <div class="demo-facebook-name">John Doe</div>
            <div class="demo-facebook-date">Monday at 3:47 PM</div>
          </f7-card-header>
          <f7-card-content :padding="false">
            <img
              src="https://cdn.framework7.io/placeholder/nature-1000x700-8.jpg"
              style="width: 100%"
            />
          </f7-card-content>
          <f7-card-footer class="no-border">
            <f7-link
              icon-f7="hand_thumbsup"
              icon-aurora="f7:hand_thumbsup"
              icon-md="material:thumb_up_off_alt"
              >Like</f7-link
            >

            <f7-link
              icon-f7="bubble_left"
              icon-aurora="f7:bubble_left"
              icon-md="material:chat_bubble_outline"
              >Comment</f7-link
            >
            <f7-link
              icon-f7="square_arrow_up"
              icon-aurora="f7:square_arrow_up"
              icon-md="material:share"
              >Share</f7-link
            >
          </f7-card-footer>
        </f7-card>
        <f7-card class="demo-facebook-card">
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar">
              <img
                src="https://cdn.framework7.io/placeholder/people-68x68-1.jpg"
                width="34"
                height="34"
              />
            </div>
            <div class="demo-facebook-name">John Doe</div>
            <div class="demo-facebook-date">Monday at 2:15 PM</div>
          </f7-card-header>
          <f7-card-content>
            <p>What a nice photo i took yesterday!</p>
            <img
              src="https://cdn.framework7.io/placeholder/nature-1000x700-8.jpg"
              style="width: 100%"
            />
            <p class="likes">Likes: 112 &nbsp;&nbsp; Comments: 43</p>
          </f7-card-content>
          <f7-card-footer class="no-border">
            <f7-link
              icon-f7="hand_thumbsup"
              icon-aurora="f7:hand_thumbsup"
              icon-md="material:thumb_up_off_alt"
              >Like</f7-link
            >

            <f7-link
              icon-f7="bubble_left"
              icon-aurora="f7:bubble_left"
              icon-md="material:chat_bubble_outline"
              >Comment</f7-link
            >
            <f7-link
              icon-f7="square_arrow_up"
              icon-aurora="f7:square_arrow_up"
              icon-md="material:share"
              >Share</f7-link
            >
          </f7-card-footer>
        </f7-card>
      </div>
      <div class="right_layout fairia_layout">
        <div class="sticky with_content" v-if="this.isTablet"></div>
      </div>
    </div>

    <f7-panel
      left
      :effect="f7.width >= 768 ? 'reveal' : 'cover'"
      resizable
      swipe-only-close
      swipe
      class="dashboardPanel"
      :data-visible-breakpoint="f7.width >= 768 && this.isOpened ? 1 : 0"
      v-model:opened="isOpened"
      :backdrop="false"
      container-el=".fairia_page"
      :style="{
        'border-right':
          'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
      }"
    >
      <f7-page>
        <f7-navbar v-if="!this.isTablet">
          <f7-nav-right>
            <f7-link panel-close icon-f7="xmark_circle_fill"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <dashboardComponent></dashboardComponent>
      </f7-page>
    </f7-panel>
  </f7-page>
</template>
<style scoped>
/* @media screen and (min-width: 768px) {
  .fairia_page {
    width: 65%;
    height: -webkit-fill-available;
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
} */
.fairia_layout {
  top: 0;
}
</style>

<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import Navbarleft from "../components/navbarleft.vue";
import DashboardComponent from "../components/dashboard_component.vue";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    navbarleft: Navbarleft,
    dashboardComponent: DashboardComponent,
  },
  mixins: [calculateTransferPayment],
  data() {
    return {
      theme,
      f7,
      theme,
      isOpened: false,
      searchPost: "",
      searchCriteria: "email",
      countryCode: "",
      phoneCode: "",
      amountToPay: "",
      paymentCurrency: "",

      memberSearchParams: [
        {
          value: "email",
          name: "email",
          type: "email",
        },
        { value: "phone", name: "phone number", type: "tel" },
        { value: "accountNumber", name: "account number", type: "number" },
        { value: "userName", name: "user name", type: "text" },
      ],
    };
  },

  mounted() {
    // this.isOpened = this.f7.width >= 768 ? true : false;

    if (!this.isTablet) {
      // f7.$(".page").addClass("page-with-subnavbar");
      f7.$(".fairia_page .page-content").addClass("hide-bars-on-scroll");
    }
    if (this.isTablet) {
      f7.$("div.fairia_page.page > .navbar").css(
        "margin-top",
        "calc(var(--f7-tabbar-labels-height) + var(--f7-safe-area-top))"
      );
    }
    f7.$(".mainToolbar").css("display", "block !important");
    f7.$("#view-fairia").on("tab:show", function () {
      // var $tabEl = $(this);
      // alert("hello from: " + $tabEl.attr("id"));
      // if ($tabEl.attr("id") === "view-fairia") {
      this.isOpened = true;
      // } else {
      //   this.isOpened = false;
      // }
    });
  },
  methods: {},

  computed: {
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),

    ...mapState("account", ["userAccount"]),
  },
};
</script>
