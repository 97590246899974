<template>
  <f7-page ptr :ptr-mousewheel="true" @ptr:refresh="loadMore" ptr-content ptr-bottom no-toolbar>
    <f7-navbar no-shadow :title="$t('recipients.myRecipients')" :back-link="$t('common.backButton')"
      :back-link-show-text="false">
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-recipients" icon-aurora="f7:search_strong"
          icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
        <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus" icon-ios="f7:person_crop_circle_fill_badge_plus"
          icon-md="material:person_add" icon-color="red" @click="recipientNew()"></f7-link>
      </f7-nav-right>
      <f7-searchbar class="searchbar-recipients" expandable :backdrop="false" search-container=".recipients-list"
        search-in=".item-title" :placeholder="$t('recipients.searchARecipient')"
        :disable-button="!theme.aurora"></f7-searchbar>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list dividers-ios strong-ios v-if="!this.myrecipients" media-list>
          <f7-list-item v-for="n in 10" :key="n" class="skeleton-text skeleton-effect-blink" title="Full Name"
            subtitle="+33 7858556322" text="France" footer="avocash top-up 28 Oct 2021">
            <f7-skeleton-block style="width: 40px; height: 40px; border-radius: 50%" slot="media"></f7-skeleton-block>
          </f7-list-item>
        </f7-list>
        <div v-if="!!this.myrecipients && this.myrecipients.length < 1">
          <span style="text-align: center">{{ $t("common.nothingFound") }}</span>
        </div>

        <f7-list dividers-ios strong-ios recipients-list class="searchbar-not-found" media-list
          v-if="!!this.myrecipients && this.myrecipients.length >= 1">
          <f7-list-item :title="$t('common.nothingFound')" style="font-size: 18px; color: red"></f7-list-item>
        </f7-list>

        <f7-list dividers-ios strong-ios recipients-list class="recipients-list searchbar-found no-margin" media-list
          v-for="(country, countryName, index) in this.myrecipients" :key="index">
          <f7-list-group>
            <f7-list-item group-title text-color="primary">
              {{ recipientsList[countryName][0].countryCode }}
              {{
                getRecipientCountry(recipientsList[countryName][0].countryCode).name
              }}</f7-list-item>
            <f7-card-content :padding="false" v-for="recipient in country" :key="recipient.id">
              <f7-link v-if="!isTablet" @click="selectRecipient(recipient)" :style="{
                position: 'absolute',
                right: '5px',
                top: '15px',
                'font-size': 'var(--f7-list-chevron-icon-font-size)',
              }" icon-aurora="f7:ellipsis_vertical" icon-ios="f7:ellipsis_vertical"
                icon-md="material:more_vert"></f7-link>
              <f7-link v-if="isTablet" @click="getRecipient(recipient)" :style="{
                position: 'absolute',
                right: '5px',
                top: '15px',
                'font-size': 'var(--f7-list-chevron-icon-font-size)',
              }" icon-aurora="f7:ellipsis_vertical" icon-ios="f7:ellipsis_vertical" icon-md="material:more_vert"
                popover-open=".recipientPopover"></f7-link>
              <li class="accordion-item no-padding-left">
                <a href="#" class="item-content item-link no-chevron tapHoldRemoveLinks"
                  @taphold="selectRecipient(recipient)" @click="toUrlDetail(recipient)">
                  <div class="item-media">
                    <f7-badge color="auto" style="
                        height: 48px;
                        width: 48px;
                        font-size: 16px;
                        border-radius: 25px;
                      ">{{ $filtersGetInitial.getInitial(recipient.name) }}</f7-badge>
                  </div>
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title">{{ recipient.name }}</div>
                    </div>
                    <div class="item-title-row no-chevron">
                      <div class="item-subtitle">
                        +{{ getRecipientCountry(recipient.countryCode).phoneCode }}
                        {{ recipient.phone }}
                      </div>
                    </div>
                    <div class="item-title-row no-chevron">
                      <div class="item-footer">{{ recipient.email }}</div>
                      <div class="item-footer">
                        {{ getRecipientCountry(recipient.countryCode).name }}
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </f7-card-content>
          </f7-list-group>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
    <f7-actions id="recipientActions" :convert-to-popover="true">
      <f7-actions-group>
        <f7-actions-label> {{ this.selectedRecipient.name }}</f7-actions-label>
        <f7-actions-button @click="toUrlDetail(selectedRecipient)" color="gray">{{
          $t("common.seeDetail")
        }}</f7-actions-button>
        <f7-actions-button @click="toUrlEdit(selectedRecipient)" v-if="this.selectedRecipient.userRecipientId === null"
          color="deeporange">{{ $t("recipients.edit") }}</f7-actions-button>
        <f7-actions-button @click="deleteRecipient(selectedRecipient)" color="red"
          v-if="this.selectedRecipient.userRecipientId === null">{{ $t("recipients.delete") }}</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-label>{{ $t("recipients.transfer") }}</f7-actions-label>
        <f7-actions-button bold color="auto" @click="transferPickup(selectedRecipient)">{{
          $t("recipients.pickup")
        }}</f7-actions-button>
        <f7-actions-button @click="transferTopup(selectedRecipient)" color="red" bold
          v-if="this.selectedRecipient.userRecipientId">{{ $t("recipients.topup") }}</f7-actions-button>
      </f7-actions-group>
      <f7-actions-group>
        <f7-actions-button color="deeporange">{{
          $t("recipients.cancel")
        }}</f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-popover class="recipientPopover">
      <f7-list>
        <f7-list-item link="#" divider :title="this.selectedRecipient.name"></f7-list-item>
        <f7-list-item link="#" divider :title="$t('recipients.transfer')"></f7-list-item>
        <f7-list-item link="#" popover-close @click="transferPickup(selectedRecipient)" :title="$t('recipients.pickup')"
          style="color: var(--f7-theme-color)"></f7-list-item>
        <f7-list-item link="#" popover-close @click="transferTopup(selectedRecipient)"
          v-if="this.selectedRecipient.userRecipientId" :title="$t('recipients.topup')" text-color="red"></f7-list-item>
        <f7-list-item link="#" popover-close @click="toUrlDetail(selectedRecipient)" :title="$t('common.seeDetail')"
          text-color="gray"></f7-list-item>
        <f7-list-item link="#" popover-close v-if="this.selectedRecipient.userRecipientId === null"
          @click="toUrlEdit(selectedRecipient)" :title="$t('recipients.edit')" text-color="deeporange"></f7-list-item>
        <f7-list-item link="#" v-if="this.selectedRecipient.userRecipientId === null" popover-close
          @click="deleteRecipient(selectedRecipient)" :title="$t('recipients.delete')" text-color="red"></f7-list-item>
      </f7-list>
    </f7-popover>

    <div class="ptr-preloader">
      <div class="preloader"></div>
      <div class="ptr-arrow"></div>
    </div>

    <f7-fab position="center-bottom" slot="fixed" :text="$t('recipients.addRecipient')" color="red"
      @click="recipientNew()" href="#">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<style scoped>
.list ul ul {
  padding-left: 0px !important;
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Searchbar,
  f7Button,
  f7List,
  f7ListItem,
  f7ListGroup,
  f7CardContent,
  f7Badge,
  f7Actions,
  f7ActionsButton,
  f7ActionsGroup,
  f7Popover,
  f7Fab,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { recipient, selectedRecipient } from "../js/mixins/recipient.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [recipient, selectedRecipient],
  components: {
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Searchbar,
    f7Button,
    f7List,
    f7ListItem,
    f7ListGroup,
    f7CardContent,
    f7Badge,
    f7Actions,
    f7ActionsButton,
    f7ActionsGroup,
    f7Popover,
    f7Fab,
  },
  data() {
    return {
      theme,
      f7,
      primaryAccountType: "Primary",
      chosenAccountNumber: "",
      selectedRecipient: "",
      theme,
    };
  },

  mounted() {
    const self = this;
  },
  methods: {},

  computed: {
    myrecipients() {
      return _.chain(this.allrecipients).groupBy("countryName").value();
    },
    ...mapState("transaction", [
      "destinationCountry",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "qrcodeDecoded",
      "QRtext",
      "extraTransferDetail",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),
    ...mapState("remit", ["recipients", "allrecipients", "recipient"]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", ["profile", "destinationCity", "isDeskTop", "isTablet"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
  },
};
</script>
