<template>
  <f7-page hide-bars-on-scroll @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{
        $t("tontineedit.update", { tontineTitle: this.tontine.name })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout">
        <f7-card outline header-divider class="sticky with_content">
          <f7-card-header>
            {{ $t("tontinedetail.participants") }}
          </f7-card-header>
          <f7-card-content>
            <membersComponent :entityId="this.tontine.id" :memberList="this.tontine.tontineParticipantList"
              :adminList="this.tontine.tontineAdminList" :entityType="'tontine'" :isAdmin="currentParticipantIsAdmin"
              :mainUserId="tontine.mainAdminUserId"></membersComponent>
          </f7-card-content>
        </f7-card>
      </div>
      <div class="main_layout">
        <f7-block-title large>{{
          $t("tontineedit.updateTontineDetails")
        }}</f7-block-title>
        <f7-list dividers-ios media-list accordion-list form id="createNewTontine">
          <f7-list-group>
            <f7-card outline header-divider>
              <f7-card-header>
                {{ $t("tontineedit.tontineSummary") }}
              </f7-card-header>
              <f7-card-content>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title" style="color: gray">
                        {{ $t("common.collected") }}
                      </div>
                      <div class="item-title" style="color: gray">
                        {{ $t("common.balance") }}
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title" style="font-weight: bold; color: green">
                        <span v-html="$filtersToCurrency.toCurrency(
                          (this.tontine.collectedAmount || 0).toFixed(2),
                          null,
                          this.tontine.currencyCode
                        )
                          "></span>
                      </div>
                      <div class="item-title" style="font-weight: bold; color: auto">
                        <span v-html="$filtersToCurrency.toCurrency(
                          (this.tontine.accountBalance || 0).toFixed(2),
                          null,
                          this.tontine.currencyCode
                        )
                          "></span>
                      </div>
                    </div>
                  </div>
                </li>
              </f7-card-content>
            </f7-card>

            <f7-list-input :label="$t('common.name')" floating-label type="text" name="tontineTitle"
              :placeholder="$t('common.name')" :value="name" @input="name = $event.target.value"
              :error-message="$t('tontinecreatenew.enterTontineTitleErr')" :info="$t('tontinecreatenew.enterTontineTitleInfo', {
                1: 80 - name.length,
              })
                " required validate maxlength="80" minlength="5" clear-button></f7-list-input>
            <f7-list-input :label="$t('common.tag')" floating-label :placeholder="$t('common.tagInfo')" :value="tag"
              name="tag" @input="tag = $event.target.value" required validate maxlength="80" type="text" />
            <li class="item-content item-input">
              <div class="item-media">
                {{ this.tontine.currencyCode }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("tontinecreatenew.tontineRoundAmount") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" name="tontineRoundAmount" min="0" :value="tontineRoundAmount"
                    @input="tontineRoundAmount = $event.target.value" pattern="[0-9]*" required validate
                    :data-error-message="$t('tontinecreatenew.tontineRoundAmountInfo')" />
                  <div class="item-input-info">
                    {{ $t("tontinecreatenew.tontineRoundAmountInfo") }}
                  </div>
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
            <div class="grid grid-cols-2">
              <f7-list-input :label="$t('tontinecreatenew.frequencyType')" type="select" required validate
                defaultValue="days" :placeholder="$t('tontinecreatenew.selectFrequencyType')"
                @input="frequencyType = $event.target.value">
                <option value="days" selected>
                  {{ $t("tontinecreatenew.inDay") }}
                </option>
                <option value="weeks">
                  {{ $t("tontinecreatenew.inWeek") }}
                </option>
                <option value="months">
                  {{ $t("tontinecreatenew.inMonth") }}
                </option>
              </f7-list-input>

              <f7-list-input :label="$t('tontinecreatenew.tontineFrequency')" type="number" floating-label
                :disabled="this.frequencyType === ''" :placeholder="$t('tontinecreatenew.tontineFrequencyPlaceHolder', {
                  frequency: this.frequencyType,
                })
                  " :value="frequency" @input="frequency = $event.target.value"
                :error-message="$t('tontinecreatenew.tontineFrequencyInfo')"
                :info="$t('tontinecreatenew.tontineFrequencyInfo')" required validate clear-button></f7-list-input>
            </div>
            <f7-block-title class="item-header">{{
              $t("common.description")
            }}</f7-block-title>
            <f7-text-editor :value="description" name="description" @texteditor:change="(v) => (description = v)"
              resizable required validate :style="f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
                " />
          </f7-list-group>
        </f7-list>
        <myDocs :type="this.type"></myDocs>
        <f7-myDocsr tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
          <f7-link raised fill large class="link buttons" ripple @click="editTontine()" strong text-color="white">{{
            $t("tontineedit.updateTontine") }}</f7-link>
        </f7-myDocsr>
      </div>
      <div class="right_layout">
        <span class="sticky with_content">
          <campaignRounds :entity="this.tontine" :entityType="'tontine'"></campaignRounds>
        </span>
      </div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7NavRight,
  f7List,
  f7ListGroup,
  f7BlockTitle,
  f7ListItem,
  f7AccordionContent,
  f7Badge,
  f7ListInput,
  f7Input,
  f7TextEditor,
  f7Toolbar,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import membersComponent from "../components/modules/members.vue";
import campaignRounds from "../components/modules/campaignRounds.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [attachDocs],
  components: {
    membersComponent,
    campaignRounds,
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7NavRight,
    f7List,
    f7ListGroup,
    f7BlockTitle,
    f7ListItem,
    f7AccordionContent,
    f7Badge,
    f7ListInput,
    f7Input,

    f7TextEditor,
    f7Toolbar,
  },
  data() {
    return {
      theme,
      f7,
      frequencyType: "",
      projectCategoryId: "",
      name: "",
      beneficiaryWalletId: "",
      tontineRoundAmount: "",
      frequency: "",
      paymentCurrency: "",
      tag: "",
      description: "",
      destinationCountryCode: "",
      countryCode: "",
      type: "tontine",
      forms: "",
      fileToSave: [],
    };
  },
  mounted() {
    const self = this;

    if (!this.tontine) {
      self.$store.dispatch("tontine/getTontineById", this.f7route.params.tontineId);
    }
    this.tag = this.tontine.tag;
    this.description = this.tontine.description;
    this.name = this.tontine.name;
    this.tontineRoundAmount = this.tontine.tontineRoundAmount;
    this.frequencyType = this.tontine.frequencyType;
    this.frequency = this.tontine.frequency;
    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.paymentCurrency = this.profile.currencyCode;

    self.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
  },
  methods: {
    async editTontine() {
      const self = this;

      f7.preloader.show("multi");
      if (!f7.input.validateInputs("#createNewTontine")) {
        return;
      }
      const newTontine = {
        name: this.name,
        frequencyType: this.frequencyType,
        frequency: this.frequency,
        description: this.description,
        tag: this.tag,
        tontineRoundAmount: this.tontineRoundAmount,
        id: this.tontine.id,
      };
      this.forms = new FormData();
      await this.forms.append("tontine", JSON.stringify(newTontine));
      for (const element of this.documentsUrl) {
        this.forms.append("files", element.file);
      }
      await self.$store.dispatch("tontine/editTontine", this.forms);
      self.$store.dispatch("auth/setDocumentsUrl", "");
      await self.$store.dispatch("tontine/getCreatedTontine", this.tontine.id);
      setTimeout(() => {
        this.f7router.navigate(`/tontine/${this.tontine.id}`);
        f7.preloader.hide();
      }, 300);
    },
    beforeOut() {
      const self = this;
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("tontine", ["tontine", "round", "participant", "payment", "admin"]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
    ]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    tontineEndDate() {
      const currentDate = new Date();
      if (this.frequencyType === "days") {
        return currentDate.setDate(currentDate.getDate() + this.frequency * 1);
      } else if (this.frequencyType === "weeks") {
        return currentDate.setDate(currentDate.getDate() + this.frequency * 7);
      } else if (this.frequencyType === "months") {
        return currentDate.setDate(currentDate.getDate() + this.frequency * 30);
      }
    },
    daysLeft() {
      return (
        (new Date(this.tontineEndDate).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
      ).toFixed(0);
    },
    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
    beneficiaryWallet() {
      if (this.beneficiaryWalletId !== " ") {
        return _.find(this.publicWalletList, ["id", this.beneficiaryWalletId]);
      }
    },
    currentDocuments() {
      const tontinePhotoImage = this.tontine.tontineImageList;
      const logo = {
        id: this.tontine.id,
        name: "logo",
        filetype: "image/png",
        image: this.tontine.logo,
        url: `data:image/png;base64,${this.tontine.logo}`,
      };
      if (!!tontinePhotoImage && !!this.tontine.logo) {
        tontinePhotoImage.push(logo);
      }
      console.log(tontinePhotoImage);
      return tontinePhotoImage;
    },
  },
};
</script>
