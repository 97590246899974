<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{ $t("forpurposetransfer.payTheBillTitle") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card outline style="white-space: normal; word-wrap: break-word">{{
          $t("forpurposetransfer.forPurposePaymentExplanation")
        }}</f7-card>
        <userslist> </userslist>
        <f7-list dividers-ios strong-ios media-list id="forPurposeTransfer">
          <f7-list-group>
            <f7-block-footer style="font-size: 12px" class="no-margin" v-if="this.searchedUser !== ''">{{
              $t("forpurposetransfer.vendorName") }}<b>{{ this.searchedUser.name }}</b>{{ $t("common.accountNumber")
  }}<b>{{ this.searchedUser.accountNumber }}</b>
            </f7-block-footer>
            <div class="grid cols20_80">
              <li class="item-content item-input no-padding-right">
                <div class="item-inner no-padding-right">
                  <div v-if="paymentCurrency === ''" class="item-title item-floating-label">
                    {{ $t("common.currency") }}
                  </div>
                  <div v-else class="item-title item-label">
                    {{ $t("common.currency") }}
                  </div>

                  <div class="item-input-wrap input-dropdown-wrap">
                    <select v-model="paymentCurrency" @change="changePaymentCurrency()">
                      <option v-if="this.profile.sub !== null" :selected="this.profile.currencyCode"
                        :value="this.profile.currencyCode">
                        {{ this.profile.currencyCode }}
                      </option>
                      <option v-if="this.searchedUser && this.recipientCountry"
                        :value="this.recipientCountry.toCurrencyCode">
                        {{ this.recipientCountry.toCurrencyCode }}
                      </option>
                    </select>
                  </div>
                </div>
              </li>

              <li class="item-content item-input padding-left-half">
                <div class="item-inner padding-left-half">
                  <div class="item-title item-floating-label">
                    {{ $t("common.amount") }}
                  </div>
                  <div class="item-input-wrap">
                    <input type="number" name="amountToPay" class="inputNumber" min="0" pattern="[0-9]*" required validate
                      :data-error-message="$t('common.amountInfo')" :disabled="!this.searchedUser"
                      :value="parseFloat(amountToPay)" @input="amountToPay = $event.target.value"
                      @change="changeAmountToPay()" @keyup="changeAmountToPay()" @blur="changeAmountToPay()" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </div>
            <f7-block-footer class="margin-bottom" v-if="this.amountToPay != '' &&
              this.searchedUser.countryCode !== this.primaryAccount.countryCode
              ">
              <p v-if="this.paymentReverseDetail">
                {{ $t("common.iPay")
                }}<b>
                  <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentReverseDetail.grossTransferAmount,
                      this.paymentReverseDetail.fromCurrencySymbol,
                      this.paymentReverseDetail.fromCurrencyCode
                    )
                    "></span>
                </b>
              </p>
              <p v-if="this.paymentDetail">
                {{ $t("forpurposetransfer.vendorReceives")
                }}<b>
                  <span v-html="$filtersToCurrency.toCurrency(
                      this.paymentDetail.receivedAmount,
                      this.paymentDetail.toCurrencySymbol,
                      this.paymentDetail.toCurrencyCode
                    )
                    "></span>
                </b>
              </p>
            </f7-block-footer>

            <div v-if="this.paymentDetail || this.paymentReverseDetail">
              <f7-list-item smart-select :smart-select-params="{
                closeOnSelect: true,
                openIn: 'popup',
                popupSwipeToClose: true,
                popupPush: true,
                searchbar: true,
                searchbarPlaceholder: $t('forpurposetransfer.findApprover'),
              }" class="selectPaymentApprover" :title="$t('forpurposetransfer.selectPaymentApprover')">
                <select name="recipient" v-model="validatorRecipientId">
                  <optgroup v-for="(allRecipient, countryName, index) in this.recipientsList" :key="index"
                    :label="countryName">
                    <option v-for="(recipient, index) in allRecipient" :value="recipient.id" :key="index">
                      {{ recipient.name }}
                      <br />
                      +{{ recipient.phoneCode + "" + recipient.phone }}
                    </option>
                  </optgroup>
                </select>
              </f7-list-item>
              <f7-block-title>{{ $t("forpurposetransfer.fillInBelow") }}</f7-block-title>

              <f7-list-input :label="$t('forpurposetransfer.paymentReference')" class="no-padding-left"
                :placeholder="$t('forpurposetransfer.paymentReference')" floating-label type="text" required validate
                :value="this.paymentRef" @input="paymentRef = $event.target.value"
                :data-error-message="$t('forpurposetransfer.pleaseEnterPaymentref')" clear-button></f7-list-input>
              <f7-block-title class="item-header">{{
                $t("common.description")
              }}</f7-block-title>
              <f7-text-editor :label="$t('common.description')" :value="senderComment" resizable required validate
                @texteditor:change="(v) => (senderComment = v)" type="texteditor" :style="f7.device.cordova || !this.isTablet
                    ? '--f7-text-editor-height: 150px'
                    : ''
                  " />

              <myDocs :type="this.type" />
            </div>
          </f7-list-group>
        </f7-list>
        <f7-button raised fill large ripple color="red" class="margin buttons" @click="payTheBill()">{{ $t("common.pay")
        }}</f7-button>
        <f7-button class="margin buttons" v-if="this.amountToPay !== ''" ripple raised color="red"
          @click="cancelpayment()">{{ $t("common.cancel") }}</f7-button>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavTitle,
  f7NavRight,
  f7NavLeft,
  f7List,
  f7ListGroup,
  f7BlockFooter,
  f7ListItem,
  f7BlockTitle,
  f7ListInput,
  f7TextEditor,
  f7Button,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { attachDocs } from "../js/mixins/attachDocs.js";
import UsersPage from "./users.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import Navbarright from "../components/navbarright.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    userslist: UsersPage,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavTitle,
    f7NavRight,
    f7NavLeft,
    f7List,
    f7ListGroup,
    f7BlockFooter,

    f7ListItem,
    f7BlockTitle,
    f7ListInput,
    f7TextEditor,
    f7Button,
  },
  mixins: [calculateTransferPayment, attachDocs],
  data() {
    return {
      theme,
      f7,
      title: this.$t("forpurposetransfer.payTheBillTitle"),
      amountToPay: "",
      accountNumber: "",
      recipientAccountNumber: "",
      recipientuser: "",
      paymentCurrency: "",
      transferRateId: "",
      recipientCountryId: "",
      recipientId: "",
      validatorRecipientId: "",
      countryCode: "",
      senderComment: "",
      invoiceFile: "",
      accountNumber: "",
      paymentRef: "",
      type: "transfer",
      forms: "",
    };
  },

  mounted() {
    if (this.$keycloak.authenticated && this.allrecipients === "") {
      this.$store.dispatch("remit/loadRecipients");
    }

    if (this.userAccount === null && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.destinationCountry === "") {
      this.$store.dispatch("transaction/loadDestinationCtry");
    }
    if (this.$keycloak.authenticated) {
      this.paymentCurrency = this.profile.currencyCode;
    }

    f7.$(".selectPaymentApprover .smart-select .item-after").html(
      this.$t("forpurposetransfer.selectPaymentApprover")
    );
  },
  methods: {
    async selectRecipientCountry() {
      if (this.allrecipients === "") {
        await this.$store.dispatch("remit/loadRecipients");
      }
    },

    async changePaymentCurrency() {
      if (this.paymentDetail || this.paymentReverseDetail) {
        await this.$store.dispatch("transaction/resetPaymentDetail");
        await this.$store.dispatch("transaction/resetPaymentReverseDetail");
      }
      await this.changeAmountToPay();
    },
    async changeAmountToPay() {
      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        await this.$store.dispatch("transaction/getCurrencyRate", {
          fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
          toCurrencyCode: this.recipientCountry.toCurrencyCode,
        });
        if (
          this.paymentCurrency === this.primaryAccount.currencyCode &&
          this.paymentCurrency !== null
        ) {
          if (
            parseFloat(this.amountToPay) > parseFloat(this.primaryAccount.accountBalance)
          ) {
            this.amountToPay = parseFloat(this.primaryAccount.accountBalance);
          }
          await this.calculatePayment();
        } else if (
          this.paymentCurrency !== this.primaryAccount.currencyCode &&
          this.paymentCurrency !== null
        ) {
          if (
            parseFloat(this.amountToPay) / parseFloat(this.grossexchangerate2) >
            parseFloat(this.primaryAccount.accountBalance)
          ) {
            this.amountToPay =
              parseFloat(this.primaryAccount.accountBalance) *
              parseFloat(this.grossexchangerate2);
          }
          await this.calculatePaymentReverse();
        } else {
          return false;
        }
      }
    },

    payTheBill() {
      if (f7.input.validateInputs("#forPurposeTransfer")) {
        f7.preloader.show("multi");
        this.forms = new FormData();
        for (const element of this.documentsUrl) {
          this.forms.append("files", element.file);
        }
        if (
          this.allrecipients.find((x) => x.userRecipientId === this.searchedUser.userId)
        ) {
          this.recipientId = this.allrecipients.find(
            (x) => x.userRecipientId === this.searchedUser.userId
          ).id;
        } else {
          setTimeout(() => {
            this.$store.dispatch("remit/recipientAdd", {
              firstName: this.searchedUser.name.split(" ")[0],
              lastName: this.searchedUser.name.split(" ")[1],
              name: this.searchedUser.name,
              userRecipientId: this.searchedUser.userId,
              email: this.searchedUser.email,
              phone: this.searchedUser.phoneNumber,
              countryCode2: this.searchedUser.countryCode,
            });
          }, 2000);
          this.recipientId = this.recipient.id;
        }
        this.$store
          .dispatch("remit/getTopupFromBalance", {
            recipientId: this.recipientId,
            amount: this.amountToPay,
            paymentCurrency: this.paymentCurrency,
            senderAccountNumber: this.primaryAccount.accountNumber,
            senderAccountType: this.primaryAccount.accountType,
            recipientAccountNumber: this.searchedUser.accountNumber,
            recipientAccountType: this.searchedUser.accountType,
            validatorRecipientId: this.validatorRecipientId,
            senderComment: this.senderComment,
            paymentRef: this.paymentRef,
            forms: this.forms,
          })
          .then((response) => {
            setTimeout(() => {
              this.f7router.navigate("/transfer/validated", {
                ignoreCache: true,
                reloadCurrent: true,
              });
              this.$store.dispatch("auth/clearDocumentsUrl");
              f7.preloader.hide();
            }, 1000);
          });
      } else {
        const valid = f7.$("#forPurposeTransfer .input-invalid").length === 0;
      }
    },

    cancelpayment() {
      f7.dialog.confirm(this.$t("forpurposetransfer.cancelPayment?"), () => {
        this.amountToPay = "";
        this.$store.dispatch("transaction/resetPaymentDetail");
        this.$store.dispatch("transaction/resetPaymentReverseDetail");
      });
    },
  },
  computed: {
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
    ]),
    ...mapState("account", [
      "userByAccountNumber",
      "userAccount",
      "userDetail",
      "getusererror",
      "searchedUser",
    ]),
    ...mapState("transaction", [
      "originCountry",
      "destinationCountry",
      "grossexchangerate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
      "extraTransferDetail",
    ]),
    ...mapState("remit", ["recipients", "allrecipients", "recipient"]),
    ...mapState("location", ["country", "destinationCity", "currentUserCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", ["grossexchangerate2"]),

    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    recipientCountry() {
      if (this.searchedUser) {
        return _.find(this.destinationCountry, [
          "toCountryCode2",
          this.searchedUser.countryCode,
        ]);
      }
    },
    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
  },
};
</script>
