<template>
  <span v-if="this.imageSrc.fileType === 'image'" class="logoCropper">
    <cropper ref="cropper" @change="onChange" class="logoAvatar-cropper cropper" :src="this.imageSrc.url"
      :auto-zoom="true" image-class="cropper__image" :stencil-component="this.stencil"
      :class="{ 'types-classic-cropper--small-height': smallHeight }" :default-boundaries="defaultBoundaries"
      :resize-image="resizeImage" :default-size="defaultSize" :default-visible-area="defaultVisibleArea"
      :image-restriction="imageRestriction" />
    <!-- :image-restriction="parameters.image_restriction"
      :stencil_props="parameters.stencil_props"
      :default-size="defaultSize" -->
    <div :class="this.isTablet
        ? 'vertical-buttons'
        : 'horizontal-buttons grid grid-cols-5 grid-gap margin-top'
      ">
      <f7-link @click="toggleStencil()" class="square-button"
        :icon-ios="this.stencil_component ? 'f7:circle' : 'f7:rectangle'" :icon-md="this.stencil_component ? 'material:radio_button_unchecked' : 'material:crop_5_4'
          "></f7-link>

      <f7-link @click="flip(true, false)" class="square-button" icon-ios="material:flip"
        icon-md="material:flip"></f7-link>
      <f7-link @click="flip(false, true)" class="square-button flipRotate" icon-ios="material:flip"
        icon-md="material:flip"></f7-link>
      <f7-link @click="rotate(90)" icon-aurora="f7:rotate_right" icon-ios="f7:rotate_right"
        icon-md="material:rotate_right" class="square-button"></f7-link>
      <f7-link class="square-button" @click="rotate(-90)" icon-aurora="f7:rotate_left" icon-ios="f7:rotate_left"
        icon-md="material:rotate_left"></f7-link>
      <f7-link class="square-button" @click="saveAvatar()" icon-aurora="material:save" icon-ios="material:save"
        icon-md="material:save"></f7-link>
    </div>
  </span>
</template>
<style scoped lang="scss">
.types-classic-cropper {
  max-height: 500px;
  background: black;

  &--small-height {
    max-height: 400px;
  }
}

.cropper-stencil {
  &__preview {

    &:after,
    &:before {
      content: "";
      opacity: 0;
      transition: opacity 0.25s;
      position: absolute;
      pointer-events: none;
      z-index: 1;
    }

    &:after {
      border-left: solid 1px white;
      border-right: solid 1px white;
      width: 33%;
      height: 100%;
      transform: translateX(-50%);
      left: 50%;
      top: 0;
    }

    &:before {
      border-top: solid 1px white;
      border-bottom: solid 1px white;
      height: 33%;
      width: 100%;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }

  &--dragging {
    .cropper-stencil__preview {

      &:after,
      &:before {
        opacity: 0.7;
      }
    }
  }
}

.cropper-line {
  border-color: rgba(white, 0.8);
}

.cropper-handler-wrapper {
  width: 24px;
  height: 24px;

  &--west-north {
    transform: translate(0, 0);
  }

  &--east-south {
    transform: translate(-100%, -100%);
  }

  &--west-south {
    transform: translate(0, -100%);
  }

  &--east-north {
    transform: translate(-100%, 0);
  }
}

.cropper-handler {
  display: block;
  position: relative;
  flex-shrink: 0;
  transition: opacity 0.5s;
  border: none;
  background: white;
  height: 4px;
  width: 4px;
  opacity: 0;
  top: auto;
  left: auto;

  &--west-north,
  &--east-south,
  &--west-south,
  &--east-north {
    display: block;
    height: 16px;
    width: 16px;
    background: none;
    opacity: 0.7;
  }

  &--west-north {
    border-left: solid 2px white;
    border-top: solid 2px white;
  }

  &--east-south {
    border-right: solid 2px white;
    border-bottom: solid 2px white;
  }

  &--west-south {
    border-left: solid 2px white;
    border-bottom: solid 2px white;
  }

  &--east-north {
    border-right: solid 2px white;
    border-top: solid 2px white;
  }

  &--hover {
    opacity: 1;
  }
}

// .cropper {
//   max-width: 90%;
//   max-height: calc(100vh - var(--f7-navbar-height));
//   max-width: 90%;
//   width: calc(100vw - var(--f7-navbar-height));
// }

.logoCropper {
  max-height: calc(100vh - var(--f7-navbar-height));
  max-width: 90%;
  width: calc(100vw - var(--f7-navbar-height));
}

.square-button {
  // background: rgba(black, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background 0.5s;
}

.flipRotate {
  transform: rotate(90deg);
}
</style>
<script>
import {
  f7Popup,
  f7Page,
  f7Link,
  f7Button,
  f7Toolbar,
  f7Icon,
  f7,
  theme,
} from "framework7-vue";
import { CircleStencil, RectangleStencil } from "vue-advanced-cropper";
// import { CircleStencil, Cropper } from "vue-advanced-cropper";
// // // import "vue-advanced-cropper/dist/theme.compact.css";
import "vue-advanced-cropper/dist/style.css";
import { markRaw } from "vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    isToSave: Boolean,
    imageSrc: Object,
    img: {
      type: String,
    },
    defaultBoundaries: {
      type: String,
      default: "fit",
    },
    // defaultSize: {
    //   type: Object,
    // },
    resizeImage: {
      type: Object,
    },
    defaultVisibleArea: {
      type: Object,
    },
    imageRestriction: {
      type: String,
      default: "fill-area",
    },
    smallHeight: {
      type: Boolean,
    },
  },
  components: {
    f7Popup,
    f7Page,
    f7Link,
    f7Button,
    f7Toolbar,
    f7Icon,
    // Cropper,
    CircleStencil: markRaw(CircleStencil),
    RectangleStencil: markRaw(RectangleStencil),
  },
  mixins: [attachDocs],

  data() {
    return {
      theme,
      f7,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      stencil: RectangleStencil,
      stencil_component: true,
      image: "",
      parameters: {
        stencil_props: {
          class: "cropper-stencil",
          previewClass: "cropper-stencil__preview",
          draggingClass: "cropper-stencil--dragging",
          handlersClasses: {
            default: "cropper-handler",
            eastNorth: "cropper-handler--east-north",
            westNorth: "cropper-handler--west-north",
            eastSouth: "cropper-handler--east-south",
            westSouth: "cropper-handler--west-south",
          },
          handlersWrappersClasses: {
            default: "cropper-handler-wrapper",
            eastNorth: "cropper-handler-wrapper--east-north",
            westNorth: "cropper-handler-wrapper--west-north",
            eastSouth: "cropper-handler-wrapper--east-south",
            westSouth: "cropper-handler-wrapper--west-south",
          },
        },
        stencil_component: "rectangle-stencil",
        stencil_size: { width: (f7.width * 90) / 100, height: (f7.height * 90) / 100 },
        image_restriction: "none",
        default_size: "defaultSize",
      },
    };
  },
  mounted() { },

  methods: {
    toggleStencil() {
      this.stencil = this.stencil == RectangleStencil ? CircleStencil : RectangleStencil;
      this.stencil_component = this.stencil == RectangleStencil;
    },
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    resize(width, height, left, top) {
      this.$refs.cropper.setCoordinates({
        width: width,
        height: height,
        left: left,
        top: top,
      });
    },
    onChange({ coordinates, canvas }) {
      this.coordinates = coordinates;
      this.image = canvas.toDataURL();
    },

    saveCroppedImage() {
      console.log("this.$refs.cropper", this.$refs.cropper);
      const result = this.$refs.cropper.getResult();
      console.log("this.$refs.cropper result", result);
      if (result) {
        result.canvas.toBlob((blob) => {
          const objectURL = URL.createObjectURL(blob);
          console.log("blob", blob);

          for (const doc of this.documentsUrl) {
            if (doc.id === this.document.id) {
              var reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = () => {
                var base64data = reader.result;
                if (base64data) {
                  _.assign(doc, {
                    url: base64data,
                    link: objectURL,
                    base64: base64data,
                    file: blob,
                    blob,
                  });
                }
              };
            }
          }
          this.$store.dispatch("auth/setDocumentsUrl", this.documentsUrl);
          this.$store.dispatch("auth/selectDocument", {});
        });
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("auth", ["profile", "documentsUrl", "document", "isTablet"]),
  },
};
</script>
