import AcceptcashPage from "../pages/acceptcash.vue";
import AcceptcashWithAccountNumberPage from "../pages/acceptcashWithAccountNumber.vue";
import AcceptcashWithNewQRCodePage from "../pages/acceptcashWithNewQRCode.vue";
import AcceptcashWithQRCodeScanPage from "../pages/acceptcashWithQRCodeScan.vue";
import AvocashATMPage from "../pages/avocashATM.vue";
import AvocashATMPickUpPage from "../pages/avocashATMPickUp.vue";
import DeliveryAccountCreatePage from "../pages/deliveryAccountCreate.vue";
import DeliveryAccountEditPage from "../pages/deliveryAccountEdit.vue";
import DeliveryAccountDetailPage from "../pages/deliveryAccountDetail.vue";
import PickupAccountCreatPage from "../pages/pickupAccountCreate.vue";
import PickupAccountEditPage from "../pages/pickupAccountEdit.vue";
import PickupAccountDetailPage from "../pages/pickupAccountDetail.vue";
import AccountNumberATMPage from "../pages/avocashATMWithAccountNumber.vue";
import AvocashATMQRCodeScanPage from "../pages/avocashATMWithQRCodeScan.vue";
import AvocashOutPage from "../pages/avocashout.vue";
import AvocashOutWithAccountNumberPage from "../pages/avocashoutWithAccountNumber.vue";
import AvocashOutWithNewQRCodPage from "../pages/avocashoutWithNewQRCode.vue";
import AvocashStoresPage from "../pages/avocashStores.vue";
import BusinessAccountActivatePage from "../pages/businessAccountActivate.vue";
import BusinessAcceptCashPage from "../pages/businessAcceptCash.vue";
import StaticQrCodeDetailPage from "../pages/staticQrCodeDetail.vue";
import businessAccountEditDetailsPage from "../pages/businessAccountEditDetails.vue";
import CheckOutPage from "../pages/checkOut.vue";
import DashboardPage from "../pages/dashboard.vue";
import FairiaPage from "../pages/fairia.vue";
import TamtamPage from "../pages/tamtam.vue";
import TamtamMessagePage from "../pages/tamtamMessage.vue";
import ForPurposeTransferPage from "../pages/forPurposeTransfer.vue";
import Home from "../pages/home.vue";
import HomePage from "../pages/homePage.vue";
// import onBoardingDetailsPage from "../pages/onboardingDetailsPage.vue";
import NewCardQRPage from "../pages/issueNewCardQR.vue";
import ItemDetailPage from "../pages/itemDetail.vue";
import MobileTopUpPage from "../pages/mobileTopUp.vue";
import soldCardsPage from "../pages/mySoldCards.vue";
import MytransactionDetailPage from "../pages/mytransactiondetail.vue";
import MytransactionsPage from "../pages/mytransactions.vue";
import NotFoundPage from "../pages/not-found.vue";
// import PanelLeftPage from "../pages/panel-left.vue";
import PanelRightPage from "../pages/panel-right.vue";
import PaycashPage from "../pages/paycash.vue";
import PayWithAccountNumberPage from "../pages/payWithAccountNumber.vue";
import PayWithNewQRCodePage from "../pages/payWithNewQRCode.vue";
import PayWithQRCodeScanPage from "../pages/payWithQRCodeScan.vue";
import PickupwithrecipientPage from "../pages/pickupwithrecipient.vue";
import RecipientDetailPage from "../pages/recipientdetail.vue";
import RecipientEditPage from "../pages/recipientedit.vue";
import RecipientNewPage from "../pages/recipientnew.vue";
import RecipientsPage from "../pages/recipients.vue";
import RecipientPickupPage from "../pages/recipientsPickup.vue";
import RecipientTopupPage from "../pages/recipientsTopup.vue";
import RecipientsTopupAllPage from "../pages/recipientsTopupAll.vue";
import RecipientTransactionDetailPage from "../pages/recipientTransactionDetail.vue";
import SendcashPage from "../pages/sendcash.vue";
import SendCashFrombalancePage from "../pages/sendCashFromBalance.vue";
import SendCashWithnewcardsPage from "../pages/sendCashWithNewCards.vue";
import SendCashWithDebitCardPage from "../pages/sendCashFromDebitCard.vue";
import ShopCreateNewPage from "../pages/shopCreateNew.vue";
import ShopDetailPage from "../pages/shopDetail.vue";
import ShopsListPage from "../pages/shopsList.vue";
import ShopEditPage from "../pages/shopEdit.vue";
import ShoppingCartPage from "../pages/shoppingCart.vue";
import ShopProductAddNewPage from "../pages/shopProductAddNew.vue";
import ShopProductDetailPage from "../pages/shopProductDetail.vue";
import ShopProductEditPage from "../pages/shopProductEdit.vue";
import StoreDetailPage from "../pages/storeDetail.vue";
import TontineCreateNewPage from "../pages/tontineCreateNew.vue";
import TontineDetailPage from "../pages/tontineDetail.vue";
import TontineEditPage from "../pages/tontineEdit.vue";
import TontineListPage from "../pages/tontinesList.vue";
import TopupBetweebAccountsPage from "../pages/topupbetweenaccounts.vue";
import TopupmyAcctPage from "../pages/topupmyaccount.vue";
import TopupwithrecipientPage from "../pages/topupwithrecipient.vue";
import TransfervalidatedPage from "../pages/transfervalidated.vue";
import UserProfilePage from "../pages/userprofile.vue";
import UserProfileEditPage from "../pages/userprofileedit.vue";
import ValidatetransferPage from "../pages/validatetransfer.vue";
import WalletCreateNewPage from "../pages/walletCreateNew.vue";
import WalletDetailPage from "../pages/walletDetail.vue";
import WalletEditPage from "../pages/walletEdit.vue";
import WalletsAllPublicPage from "../pages/walletsAllPublic.vue";
import WalletsUserPage from "../pages/walletsUser.vue";
import AssociationCreateNewPage from "../pages/associationCreateNew.vue";
import AssociationDetailPage from "../pages/associationDetail.vue";
import AssociationEditPage from "../pages/associationEdit.vue";
import AssociationsListPage from "../pages/associationsList.vue";

import SavingsCreateNewPage from "../pages/savingsCreateNew.vue";
import SavingsDetailPage from "../pages/savingsDetail.vue";
import SavingsEditPage from "../pages/savingsEdit.vue";
import SavingsListPage from "../pages/savingsList.vue";

import GroupBuyCreateNewPage from "../pages/groupBuyCreateNew.vue";
import GroupBuyDetailPage from "../pages/groupBuyDetail.vue";
import GroupBuyEditPage from "../pages/groupBuyEdit.vue";
import GroupBuyListPage from "../pages/groupBuyList.vue";
import UsersPage from "../pages/users.vue";
import BankAccountPage from "../pages/bankAccount.vue";
import BankCardPage from "../pages/bankCard.vue";
import BankWirePage from "../pages/bankWire.vue";
import AccountDetailsPage from "../pages/accountDetails.vue";
import AccountWalletPage from "../pages/accountWallet.vue";
import ContributePage from "../pages/contribute.vue";
import PaymentPage from "../pages/payment.vue";
import PaymentCardsPage from "../pages/paymentCards.vue";
import Navbarright from "../components/navbarright.vue";
import LoginPage from "../pages/login.vue";
// import Navbar from "framework7-vue/components/navbar";
// import { f7 } from "framework7-vue";
import store from "./oauth2.js";
// function getUser() {
//   return store.state.$keycloak.profile;
// }
function ifAuthenticated({ to, from, resolve, reject }) {
  const $keycloak = store.state.$keycloak;
  console.log($keycloak);
  // const profile = $keycloak.loadUserInfo();
  const router = this;
  console.log("router,", router, to, from);
  // const app = router.app;
  // console.log("user profile:", profile);
  router.app.preloader.show("multi");
  if ($keycloak.authenticated) {
    resolve();
  } else {
    const redirect = router.app.device.cordova
      ? `${import.meta.env.VITE_AVOCASH_MOBILE_URL_SCHEME}${to.path}`
      : `${window.location.origin}${to.path}`;
    console.log(redirect);
    $keycloak.login({
      redirectUri: redirect,
      locale: navigator.language.split("-")[0],
    });
  }
  router.app.preloader.hide();
}

const routes = [
  {
    // alias: "/home",
    path: "/",
    // redirect: "/home",
    alias: "/home",
    name: "home",
    component: Home,
    // tabs: window.cordova
    //   ? [
    //       {
    //         name: "home",
    //         alias: "/home",
    //         path: "/",
    //         id: "view-home",
    //         name: "home_page",
    //         component: HomePage,
    //       },
    //       // {
    //       //     path: "/services",
    //       //     id: "view-services",
    //       //     component: AvocashServicesPage,
    //       // },
    //       {
    //         path: "/fairia/",
    //         id: "view-fairia",
    //         name: "fairia",
    //         component: FairiaPage,
    //         beforeEnter: ifAuthenticated,
    //       },
    //       {
    //         path: "/stores/",
    //         id: "view-stores",
    //         name: "store",
    //         component: AvocashStoresPage,
    //       },
    //       {
    //         path: "/wallets/",
    //         id: "view-wallets",
    //         name: "public wallets",
    //         component: WalletsAllPublicPage,
    //       },
    //       {
    //         path: "/dashboard/",
    //         id: "view-dashboard",
    //         name: "dashboard",
    //         component: DashboardPage,
    //         beforeEnter: ifAuthenticated,
    //       },
    //       // {
    //       //   path: "/message/",
    //       //   id: "view-tamtam",
    //       //   component: TamtamPage,
    //       //   beforeEnter: ifAuthenticated,
    //       //   master: true,
    //       //   detailRoutes: [
    //       //     {
    //       //       path: "/message/:id/:type",
    //       //       component: TamtamMessagePage,
    //       //       beforeEnter: ifAuthenticated,
    //       //     },
    //       //   ],
    //       // },
    //     ]
    //   : [],
  },
  // {
  //   path: "/",
  //   component: Home,
  //   // alias: "/home",
  //   name: "home",
  //   tabs: [
  //     {
  //       name: "home",
  //       // alias: "/home",
  //       path: "//",
  //       id: "view-home",
  //       name: "home_page",
  //       component: HomePage,
  //     },
  //     // {
  //     //     path: "/services",
  //     //     id: "view-services",
  //     //     component: AvocashServicesPage,
  //     // },
  //     {
  //       path: "/fairia/",
  //       id: "view-fairia",
  //       name: "fairia",
  //       component: FairiaPage,
  //       beforeEnter: ifAuthenticated,
  //     },
  //     {
  //       path: "/stores/",
  //       id: "view-stores",
  //       name: "store",
  //       component: AvocashStoresPage,
  //     },
  //     {
  //       path: "/wallets/",
  //       id: "view-wallets",
  //       name: "public wallets",
  //       component: WalletsAllPublicPage,
  //     },
  //     {
  //       path: "/dashboard/",
  //       id: "view-dashboard",
  //       name: "dashboard",
  //       component: DashboardPage,
  //       beforeEnter: ifAuthenticated,
  //     },
  //     // {
  //     //   path: "/message/",
  //     //   id: "view-tamtam",
  //     //   component: TamtamPage,
  //     //   beforeEnter: ifAuthenticated,
  //     //   master: true,
  //     //   detailRoutes: [
  //     //     {
  //     //       path: "/message/:id/:type",
  //     //       component: TamtamMessagePage,
  //     //       beforeEnter: ifAuthenticated,
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  {
    path: "/fairia/",
    id: "view-fairia",
    name: "fairia",
    component: FairiaPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/message/",
    // alias: "/home/tamtam",
    name: "tamtam main page",
    component: TamtamPage,
    beforeEnter: ifAuthenticated,
    master: true,
    detailRoutes: [
      {
        // alias: "/message/",
        path: "/message/:id/:type",
        name: "tamtam detail page",
        component: TamtamMessagePage,
        beforeEnter: ifAuthenticated,
      },
    ],
  },
  {
    // alias: "/tamtam/message",
    path: "/message/:id/:type",
    name: "tamtam detail page",
    component: TamtamMessagePage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/card/new",
    name: "issue new card",
    component: NewCardQRPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/card/sold",
    name: "list of cards issued",
    component: soldCardsPage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/stores/",
    name: "stores",
    component: AvocashStoresPage,
  },
  {
    path: "/user/profile",
    name: "user detail page",
    component: UserProfilePage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/user/profile/edit",
    name: "edit user profile",
    component: UserProfileEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/recipients",
    name: "recipients list",
    component: RecipientsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/recipients/topup-all",
    name: "top-up recipients list",
    component: RecipientsTopupAllPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/transfer/validated",
    name: "transfer validated",
    component: TransfervalidatedPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/transfer/validate/:id",
    name: "validate transfer",
    component: ValidatetransferPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/recipient",
    path: "/recipient/:id",
    name: "recipient",
    component: RecipientDetailPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/recipient/edit/:id",
    name: "recipient edit",
    component: RecipientEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/recipient/transaction/detail/:id",
    name: "recipient trx detail",
    component: RecipientTransactionDetailPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/account/business/activate",
    name: "activate business acount",
    component: BusinessAccountActivatePage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/business-qr/",
    name: "business accept cash main page",
    component: BusinessAcceptCashPage,
    beforeEnter: ifAuthenticated,
    master: true,
    detailRoutes: [
      {
        path: "/business-qr/:account/:id",
        name: "business accept cash detail page",
        component: StaticQrCodeDetailPage,
        beforeEnter: ifAuthenticated,
      },
    ],
  },

  {
    path: "/account/business/edit",
    name: "activate business edit",
    component: businessAccountEditDetailsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/pickup/with-recipient/pickupmode",
    path: "/pickup/with-recipient/:id/:pickupmode",
    name: "pickup-with-recipient",
    component: PickupwithrecipientPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/topup/with-recipient/pickupmode",
    path: "/topup/with-recipient/:id/:pickupmode",
    name: "topup-with-recipient",
    component: TopupwithrecipientPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/send-cash/with-new-cards/pickupmode",
    path: "/send-cash/with-new-cards/:id/:pickupmode",
    name: "send cash with new cards",
    component: SendCashWithnewcardsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/send-cash/from-balance/pickupmode",
    path: "/send-cash/from-balance/:id/:pickupmode",
    name: "send cash from balance",
    component: SendCashFrombalancePage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "send-cash/with-debit-card/pickupmode",
    path: "send-cash/with-debit-card/:id/:pickupmode",
    name: "send cash with debit cards",
    component: SendCashWithDebitCardPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/recipient/create/new",
    name: "recipient create",
    component: RecipientNewPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/recipients/topup",
    name: "recipients topup list",
    component: RecipientTopupPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/recipients/pickup",
    name: "recipients pickup list",
    component: RecipientPickupPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/send-cash",
    name: "send cash",
    component: SendcashPage,
  },

  {
    path: "/pay-cash",
    name: "pay cash",
    component: PaycashPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/pay/with-new-QRCode",
    name: "pay_with-new-QRCode",
    component: PayWithNewQRCodePage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/pay/with-account-number",
    name: "pay_with-account-number",
    component: PayWithAccountNumberPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/pay/with-QRCode-scan",
    name: "pay_with-QRCode-scan",
    component: PayWithQRCodeScanPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/cash-out",
    name: "cash-out",
    component: AvocashOutPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/cash-out/with-account-number",
    name: "cash-out_with-account-number",
    component: AvocashOutWithAccountNumberPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/cash-out/with-new-QRCode",
    name: "cash-out_with-new-QRCode",
    component: AvocashOutWithNewQRCodPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/atm",
    name: "atm",
    component: AvocashATMPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/atm/with-QRCode-scan",
    name: "atm_with-QRCode-scan",
    component: AvocashATMQRCodeScanPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/atm/with-account-number",
    name: "atm_with-account-number",
    component: AccountNumberATMPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/atm/pickup",
    name: "atm-pickup",
    component: AvocashATMPickUpPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/accept-cash",
    name: "accept-cash",
    component: AcceptcashPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/accept-cash/with-QRCode_scan",
    name: "accept-cash_with-QRCode_scan",
    component: AcceptcashWithQRCodeScanPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/accept-cash/with-account-number",
    name: "accept-cash_with-account-number",
    component: AcceptcashWithAccountNumberPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/accept-cash/with-new-QRCode",
    name: "accept-cash_with-new-QRCode",
    component: AcceptcashWithNewQRCodePage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/topup/my-account/:cardUID/:cardNumber",
    name: "topup-my-account",
    component: TopupmyAcctPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/topup/my-account",
    name: "topup-my-account",
    component: TopupmyAcctPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/topup/between-accounts",
    name: "topup-between-accounts",
    component: TopupBetweebAccountsPage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/users",
    name: "users list",
    component: UsersPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/dashboard/",
    name: "dashboard",
    component: DashboardPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/my-transactions",
    path: "/my-transactions/:accountNumber/:accounttype",
    name: "transactions list",
    component: MytransactionsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/my-transactions/detail",
    path: "/my-transactions/detail/:transactiontype/:id",
    name: "transaction detail",
    component: MytransactionDetailPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/transaction/:result_type",
    name: "transaction detail",
    component: MytransactionDetailPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/mobile/topup",
    name: "mobiletopup",
    component: MobileTopUpPage,
    // beforeEnter: ifAuthenticated,
  },
  {
    path: "/transfer/for-purpose",
    name: "transfer-for-purpose",
    component: ForPurposeTransferPage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/storeDetail/:storeId",
    component: StoreDetailPage,
  },
  {
    path: "/itemDetail/:storeId/:itemId",
    component: ItemDetailPage,
  },
  // {
  //     path: "/categoryDetail",
  //     alias: "/categoryDetail/:categoryId",
  //     component: CategoryDetailPage,
  // },

  {
    path: "/cart/:cartId",
    name: "shopping-cart",
    component: ShoppingCartPage,
  },
  {
    path: "/cart/",
    name: "shopping_cart",
    component: ShoppingCartPage,
  },
  {
    path: "/check-out",
    name: "checkout",
    component: CheckOutPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/shop/new",
    name: "shop_create",
    component: ShopCreateNewPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/shop/edit",
    path: "/shop/edit/:shopId",
    name: "shop_edit",
    component: ShopEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/shops",
    name: "shops_list",
    component: ShopsListPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/shop",
    path: "/shop/:shopId",
    name: "shop_detail",
    component: ShopDetailPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // alias: "/product/new/",
    path: "/product/new/:shopId",
    name: "product_create",
    component: ShopProductAddNewPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/product/edit",
    path: "/product/edit/:productId",
    name: "product_edit",
    component: ShopProductEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/product/detail",
    path: "/product/:productId",
    name: "product_detail",
    component: ShopProductDetailPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/wallet/new/:ownerType",
    name: "wallet_create",
    component: WalletCreateNewPage,
    beforeEnter: ifAuthenticated,
  },
  {
    alias: "/wallet/:name",
    path: "/wallet/:entityId",
    name: "wallet_detail",
    component: WalletDetailPage,
  },
  // beforeEnter: ifAuthenticated
  // routes: [
  {
    alias: "/wallet/:entityId/contribute/:projectId",
    path: "/wallet/:entityId/contribute/:projectId",
    beforeEnter: ifAuthenticated,
    component: ContributePage,
    name: "wallet_contribute_to_campaign",
  },
  {
    path: "/wallet/:entityId/redeem/:projectId",
    component: ContributePage,
    name: "wallet_redeem_campaign",
    beforeEnter: ifAuthenticated,
  },
  //   ],
  // },
  // {
  //   // path: "/wallet/contribute",
  //   alias: "/wallet/contribute/:entityId/:projectId",
  //   path: "/:type/contribute/:entityId/:projectId",
  //   name: "wallet contribute",
  //   component: ContributePage,
  //   // beforeEnter: ifAuthenticated,
  // },
  // {
  //   // path: "/wallet/redeem",
  //   path: "/wallet/redeem/:walletId/:campaignId",
  //   name: "wallet redeem",
  //   component: WalletRedeemPage,
  //   beforeEnter: ifAuthenticated,
  // },
  {
    // path: "/wallet/edit",
    path: "/wallet/edit/:walletId",
    name: "wallet_edit",
    component: WalletEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/wallets/private",
    name: "private_wallets_list",
    component: WalletsUserPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/wallets/",
    name: "public_wallets_list",
    component: WalletsAllPublicPage,
  },

  //Tontine
  {
    path: "/tontine/new",
    name: "tontine_create",
    component: TontineCreateNewPage,
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/tontine",
    path: "/tontine/:entityId",
    name: "tontine_detail",
    component: TontineDetailPage,
    beforeEnter: ifAuthenticated,
  },
  // routes: [
  {
    alias: "/tontine/:entityId/contribute/:projectId",
    path: "/tontine/:entityId/contribute/:projectId",
    component: ContributePage,
    name: "tontine contribute to round",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/tontine/:entityId/redeem/:projectId",
    component: ContributePage,
    name: "tontine redeem round",
    beforeEnter: ifAuthenticated,
  },
  //   ],
  // },
  // {
  //   // path: "/tontine/contribute",
  //   alias: "/tontine/contribute/:entityId/:projectId",
  //   path: "/:type/contribute/:entityId/:projectId",
  //   name: "tontine contribute to round",
  //   component: ContributePage,
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   // path: "/tontine/redeem",
  //   path: "/tontine/redeem/:tontineId/:roundId",
  //   name: "tontine redeem round",
  //   component: TontineRedeemPage,
  //   beforeEnter: ifAuthenticated,
  // },
  {
    // path: "/tontine/edit",
    path: "/tontine/edit/:tontineId",
    component: TontineEditPage,
    name: "tontine_edit",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/tontines",
    component: TontineListPage,
    name: "tontines_list",
    beforeEnter: ifAuthenticated,
  },

  //Association and groups campaigns
  {
    path: "/association/new/:accountType",
    component: AssociationCreateNewPage,
    name: "association_create",
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/association/:associationTitle",
    path: "/association/:entityId",
    component: AssociationDetailPage,
    name: "association_detail",
    beforeEnter: ifAuthenticated,
  },
  {
    // path: "/association/contribute",
    alias: "/association/:entityId/contribute/:projectId",
    path: "/association/:entityId/contribute/:projectId",
    component: ContributePage,
    name: "association _ontribute_to_campaign",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/association/:entityId/redeem/:projectId",
    component: ContributePage,
    name: "association_redeem_campaign",
    beforeEnter: ifAuthenticated,
  },

  {
    // path: "/association/edit",
    path: "/association/edit/:associationId",
    name: "association_edit",
    component: AssociationEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/associations",
    name: "associations_list",
    component: AssociationsListPage,
    beforeEnter: ifAuthenticated,
  },

  //Savings groups and battles

  {
    path: "/saving/new",
    component: SavingsCreateNewPage,
    name: "savings group create",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/saving/:entityId",
    component: SavingsDetailPage,
    name: "savings group detail",
    beforeEnter: ifAuthenticated,
  },
  {
    alias: "/saving/:entityId/contribute/:projectId",
    path: "/contribute/:projectId",
    component: ContributePage,

    name: "savings contribute to battle",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/saving/:entityId/redeem/:projectId",
    component: ContributePage,
    name: "savings redeem battle",
    beforeEnter: ifAuthenticated,
  },
  //   ],
  // },
  // {
  //   alias: "/savings/contribute/:entityId/:projectId",
  //   path: "/:type/contribute/:entityId/:projectId",
  //   component: ContributePage,
  //   name: "savings contribute to battle",
  //   beforeEnter: ifAuthenticated,

  // },
  // {
  //   path: "/savings/redeem/:savingsGroupId/:battleId",
  //   component: SavingsRedeemPage,
  //   name: "savings redeem battle",
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: "/saving/edit/:savingsGroupId",
    name: "savings_group_edit",
    component: SavingsEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/savings",
    name: "savings_group_list",
    component: SavingsListPage,
    beforeEnter: ifAuthenticated,
  },
  //GroupBuy groups and project

  {
    path: "/groupBuy/new",
    component: GroupBuyCreateNewPage,
    name: "groupBuy_create",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/groupBuy/:entityId",
    component: GroupBuyDetailPage,
    name: "groupBuy_detail",
    beforeEnter: ifAuthenticated,
  },
  // routes: [
  {
    alias: "/groupBuy/:entityId/contribute/:projectId",
    path: "/contribute/:projectId",
    component: ContributePage,

    name: "groupBuy contribute to battle",
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/groupBuy/:entityId/redeem/:projectId",
    component: ContributePage,
    name: "groupBuy redeem battle",
    beforeEnter: ifAuthenticated,
  },
  // ],

  // {
  //   alias: "/groupBuy/contribute/:entityId/:projectId",
  //   path: "/:type/contribute/:entityId/:projectId",
  //   component: ContributePage,
  //   name: "groupBuy contribute to battle",
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: "/groupBuy/redeem/:groupBuyId/:projectId",
  //   component: GroupBuyRedeemPage,
  //   name: "groupBuy redeem battle",
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: "/groupBuy/edit/:groupBuyId",
    name: "groupBuy_edit",
    component: GroupBuyEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/groupBuy",
    name: "groupBuy_list",
    component: GroupBuyListPage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/fairia",
    component: FairiaPage,
    name: "fairia",
    beforeEnter: ifAuthenticated,
  },

  // {
  //     path: '/home/tamtam/',
  //     component: TamtamPage,
  //     beforeEnter: ifAuthenticated,
  //     master: true,
  //     detailRoutes: [{
  //         path: '/tamtamMessage/:id',
  //         component: TamtamMessagePage,
  //         beforeEnter: ifAuthenticated,
  //     }, ]
  // },

  {
    path: "/create/delivery/account",
    name: "delivery_account_create",
    component: DeliveryAccountCreatePage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/edit/delivery/account/:id",
    name: "delivery_account_edit",
    component: DeliveryAccountEditPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/detail/delivery/account/:id",
    name: "delivery_account",
    component: DeliveryAccountDetailPage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/create/pickup/account",
    name: "pick-up_ccount_create",
    component: PickupAccountCreatPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/edit/pickup/account/:id",
    component: PickupAccountEditPage,
    name: "pick-up_account_edit",
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/detail/pickup/account/:id",
    name: "pick-up_account",
    component: PickupAccountDetailPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/bankAccount",
    name: "bank_account",
    component: BankAccountPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/bankCard",
    name: "bank_card",
    component: BankCardPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/bankWire",
    name: "bank_wire",
    component: BankWirePage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/account/:type/:account_id",
    name: "account_details",
    component: AccountDetailsPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/account/:account_id/wallet/:wallet_id",
    name: "account_wallet",
    component: AccountWalletPage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/pay/",
    name: "paymentPage",
    component: PaymentPage,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/pay/cards",
    component: PaymentCardsPage,
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/panel-right",
    name: "panel-right",
    component: PanelRightPage,
  },
  {
    path: "/navbarright",
    name: "navbar-right",
    component: Navbarright,
  },

  // {
  //   path: "/onboard-detail/",
  //   name: "onboard detail",
  //   component: onBoardingDetailsPage,
  // },

  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

export default routes;
