<template>
  <f7-page @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove" hide-bars-on-scroll name="sendCash">
    <!-- <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
          <f7-nav-title sliding>{{ this.title }}</f7-nav-title>
          <f7-nav-right>
            <navbarright></navbarright>
          </f7-nav-right>
        </f7-navbar> -->
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <addnewcards @getCardBAsketAmount="retrieveCardBasketAmount"></addnewcards>
        <f7-list dividers-ios strong-ios media-list action form>
          <f7-list-group>
            <f7-list-item v-if="carditemlist && carditemlist.length > 0" :title="$t('sendcash.selectDestinationAnd')"
              divider></f7-list-item>
            <f7-row no-gap v-if="carditemlist && carditemlist.length > 0">
              <f7-col width="50" medium="50">
                <f7-list-item smart-select :smart-select-params="{
                  closeOnSelect: true,
                  openIn: 'popup',
                  searchbar: true,
                  searchbarPlaceholder: $t('sendcash.findCountry'),
                }" :title="$t('sendcash.recipientCountry')" class="selectRecipientCountry item-label">
                  <select name="country" v-model="destinationctryId" @change="updateCountry()">
                    <!-- <option value disabled>{{ $t('electRecipientCountry') }}</option> -->

                    <optgroup v-for="(continent, continentName, index) in destinationCtry" :key="index"
                      :label="continentName">
                      <option v-for="(country, index) in continent" :value="country.toCountryId" :key="index"
                        :data-option-image="'https://flagcdn.com/' +
                          country.toCountryCode2.toLowerCase() +
                          '.svg'
                          " data-option-class="countryFlag" :data-totransferid="country.toCountryId + '' + country.toCurrencyCode
    " :data-tocurrencycodetoCountryName="country.toCurrencyCode">
                        {{ country.toCountryName }}-{{ country.toCurrencyCode }}
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
              </f7-col>
              <f7-col width="50" medium="50">
                <f7-list-item smart-select :smart-select-params="{
                  closeOnSelect: true,
                  openIn: this.isTablet == true ? 'popover' : 'sheet',
                  sheetSwipeToClose: true,
                  sheetPush: true,
                }" class="selectPickupMode item-label" :title="$t('sendcash.pickupMode')">
                  <select name="pickupmode" v-model="pickupMethod" @change="updatePickUpMethod()">
                    <!-- <option value disabled>{{ $t('electPickupMode') }}</option> -->
                    <option value="pick-up" selected>
                      {{ $t("sendcash.avoashPickup") }}
                    </option>
                    <option value="top-up">{{ $t("sendcash.avoashTopup") }}</option>
                  </select>
                </f7-list-item>
              </f7-col>
            </f7-row>

            <f7-list-item v-if="this.pickupMethod &&
              this.carditemlist &&
              this.profile &&
              this.recipients &&
              this.recipients.length > 0
              " smart-select :smart-select-params="{
    closeOnSelect: true,
    openIn: 'popup',
    popupSwipeToClose: true,
    popupPush: true,
    searchbar: true,
    searchbarPlaceholder: $t('sendcash.findRecipient'),
  }" @smartselect:closed="selectRecipient" :title="$t('sendcash.avoashRecipient')" class="item-label">
              <select name="recipient" v-model="recipientId" @change="selectRecipient()">
                <option v-for="recipient in recipients" :key="recipient.name" :value="recipient.id">
                  {{ recipient.name }}-{{ recipient.phone }}
                </option>
              </select>
            </f7-list-item>
          </f7-list-group>

          <f7-list-group v-if="this.pickupMethod && this.destinationctryId">
            <f7-list-item :title="$t('sendcash.updateValueToReceive')" divider v-if="transferDetail.receivedAmount && carditemlist && carditemlist.length > 0
              "></f7-list-item>
            <li class="item-content item-input" v-if="transferDetail.receivedAmount && carditemlist && carditemlist.length > 0
              ">
              <div class="item-media">
                {{ transferDetail.toCurrencyCode }}
              </div>
              <div class="item-inner">
                <div class="item-title item-label">
                  {{ $t("sendcash.recipientsGets") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" name="receivedAmount" pattern="[0-9]*" required validate data-error-message=""
                    min="0" :value="parseFloat(transferDetail.receivedAmount)"
                    @input="receivedAmount = $event.target.value" @change="calculateAmountToSend()"
                    @blur="calculateAmountToSend()" @keypress="isNumber($event)" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>

            <li class="item-content" v-if="transferDetail.receivedAmount && carditemlist && carditemlist.length > 0
              ">
              <div class="item-inner">
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.fee") }}</div>
                  <div class="item-title">
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.transferFeeAmount,
                      transferDetail.fromCurrencySymbolNative,
                      transferDetail.fromCurrencyCode
                    )
                      "></span>
                  </div>
                </div>
                <div class="item-title-row" style="font-size: 60%; color: gray">
                  <div class="item-title">{{ $t("sendcash.xrate") }}</div>
                  <div class="item-title float-left">
                    1 {{ transferDetail.fromCurrencySymbolNative }} =
                    <span v-html="$filtersToCurrency.toCurrency(
                      transferDetail.netExchangeRate,
                      transferDetail.toCurrencySymbolNative,
                      transferDetail.toCurrencyName
                    )
                      "></span>
                  </div>
                </div>
              </div>
            </li>
            <f7-block class="no-margin" v-if="!this.$keycloak.authenticated && carditemlist && carditemlist.length > 0
              ">
              <p style="font-size: 10px; font-style: italic; color: red">
                {{ $t("sendcash.pleaseLogIn") }}
              </p>
            </f7-block>
            <f7-block class="margin-vertical" v-if="transferDetail.receivedAmount && carditemlist && carditemlist.length > 0
              "></f7-block>
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom bg-color="red" v-if="!this.pickupMethod &&
          !this.destinationctryId &&
          carditemlist &&
          carditemlist.length > 0
          ">
          <f7-link raised large fill class="link buttons" strong text-color="white" @click="selectAccountToTopup()">{{
            $t("sendcash.topMeUp") }}<br />
            <span style="font-size: 50%">{{
              $t("sendcash.selectDestinationCountry")
            }}</span>
          </f7-link>
        </f7-toolbar>
        <f7-toolbar tabbar bottom bg-color="red" v-if="this.pickupMethod &&
            this.destinationctryId &&
            carditemlist &&
            carditemlist.length > 0
            ">
          <f7-link v-if="this.pickupMethod === 'top-up' && this.recipientId.length == 0" raised large fill
            class="link buttons" strong text-color="white" @click="goToCheckTransferNoRecipient()">{{
              $t("sendcash.topupNow") }}</f7-link>
          <f7-link v-else-if="this.pickupMethod === 'top-up' && this.recipientId.length !== 0" raised large fill
            class="link buttons" strong text-color="white" @click="goToCheckTransfer()">{{ $t("sendcash.topupNow")
            }}</f7-link>
          <f7-link v-else-if="this.pickupMethod === 'pick-up' && this.recipientId.length !== 0" raised large fill
            class="link buttons" strong text-color="white" @click="goToCheckTransfer()">{{ $t("sendcash.transferNow")
            }}</f7-link>
          <f7-link v-else raised large fill class="link buttons" strong text-color="white"
            @click="goToCheckTransferNoRecipient()">
            {{ $t("sendcash.transferNow") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
    <!-- Selecting the account number to top-up my own account -->
    <f7-sheet class="SelectAccountToTopup" style="height: auto; --f7-sheet-bg-color: #fff" swipe-to-close backdrop
      v-if="this.$keycloak.authenticated">
      <f7-page>
        <f7-navbar>
          <div class="left"></div>
          <div class="title">{{ $t("sendcash.selectYourAccount") }}</div>
          <div class="right">
            <f7-link sheet-close icon-f7="xmark_circle"></f7-link>
          </div>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title>{{ $t("sendcash.selectYourAccount") }}</f7-block-title>
            <f7-block>
              <f7-list dividers-ios strong-ios media-list>
                <f7-list-item radio :value="this.primaryAccount.accountNumber" :title="this.primaryAccount.accountType +
                  ': ' +
                  this.primaryAccount.accountNumber
                  " @click="topupMyAccount()" @change="(e) => {
    if (e.target.checked)
      accountNumber = this.primaryAccount.accountNumber;
  }
    " name="account" checked></f7-list-item>
                <f7-list-item radio :value="this.businessAccount.accountNumber" :title="this.businessAccount.accountType +
                  ': ' +
                  this.businessAccount.accountNumber
                  " @click="topupMyAccount()" @change="(e) => {
    if (e.target.checked)
      accountNumber = this.businessAccount.accountNumber;
  }
    " name="account"></f7-list-item>
              </f7-list>
            </f7-block>
            <f7-toolbar tabbar bottom bg-color="red">
              <f7-link v-if="this.totalCardbasket" raised fill large class="link" text-color="white" strong
                @click="topupMyAccount">{{ $t("sendcash.topMeUp") }}</f7-link>
            </f7-toolbar>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-sheet>
  </f7-page>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavTitle,
  f7NavLeft,
  f7NavRight,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7Block,
  f7Toolbar,
  f7Link,
  f7Sheet,
  f7PageContent,
  f7BlockTitle,
  f7,
  theme,
} from "framework7-vue";
import { scanCard, checkCard } from "../js/mixins/card.js";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { selectedRecipient, recipient } from "../js/mixins/recipient.js";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { mapGetters, mapState } from "vuex";

import Navbarright from "../components/navbarright.vue";
import AddNewCards from "../components/addnewcards.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    addnewcards: AddNewCards,
    f7Page,
    f7Navbar,
    f7NavTitle,
    f7NavLeft,
    f7NavRight,
    f7List,
    f7ListGroup,
    f7ListItem,

    f7Block,
    f7Toolbar,
    f7Link,
    f7Sheet,
    f7PageContent,
    f7BlockTitle,
  },
  mixins: [
    scanCard,
    checkCard,
    selectedRecipient,
    recipient,
    scanQRCodeGeneral,
    calculateTransferPayment,
  ],
  data() {
    return {
      theme,
      f7,
      title: "Check my card",
      cardNumber: "",
      cardPIN: "",
      cardAmount: "",
      destinationctryId: "",
      pickupcity: "",
      pickupplace: "",
      pickupMethod: "",
      transferRateId: "",
      recipientId: "",
      tocurrencycode: "",
      query: "",
      grossXrate: "",
      recipientAccountNumber: "",
      recipientAccountType: "",
      accountNumber: "",
      nocardNumberOrPINError: "",
      amountToReceive: "",
      amountToSend: "",
      newVal: "",
    };
  },
  mounted: function () {
    const self = this;

    // f7.$(".mainToolbar").css("display", "none");
    if (this.destinationCountry === "" && this.userAddress) {
      self.$store.dispatch(
        "transaction/loadDestinationCtry",
        this.userAddress.address.country_code
      );
    }
    if (this.profile) {
      this.accountNumber == this.primaryAccount.accountNumber;
      this.amountToSend === parseFloat(this.totalCardbasket);
    }
    f7.$(".selectRecipientCountry .item-after").html(
      this.$t("sendcash.selectRecipientCountry")
    );
    f7.$(".selectPickupMode .item-after").html(this.$t("sendcash.selectPickupMode"));
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async retrieveCardBasketAmount(cardBasketTotalAmount) {
      const self = this;

      this.amountToSend = parseFloat(cardBasketTotalAmount);
      await this.calculateTransfer();
    },

    async updateCountry() {
      const self = this;
      this.amountToSend = parseFloat(this.totalCardbasket);
      await self.$store.dispatch(
        "transaction/loadDestinationCtry",
        self.$store.state.location.userAddress.address.country_code || this.profile.sub
      );
      await self.$store.dispatch("transaction/getCurrencyRate", {
        fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
        toCurrencyCode: this.recipientCountry.toCurrencyCode,
      });
      if (this.pickupMethod !== null && this.pickupMethod !== "") {
        await this.calculateTransfer();

        if (this.$keycloak.authenticated) {
          await self.$store.dispatch("remit/loadRecipientsByCountry", {
            countryId: this.destinationctryId,
            pickupMethod: this.pickupMethod,
          });
          if (this.recipient !== null && this.recipient.userRecipientId !== null) {
            await self.$store.dispatch(
              "account/loadUser",
              { userId: this.recipient.userRecipientId, type: "userId" }
            );
          }
        }
        this.pickupcity = ""; //Empty pick-up city list
        await self.$store.dispatch(
          "location/loadDestinationCity",
          this.recipientCountry.toCountryCode2
        ); //upadte pick-up city list
      } else {
        return false;
      }
    },

    async selectRecipient() {
      const self = this;
      if (parseFloat(this.recipient.userRecipientId) > 0) {
        await self.$store.dispatch("account/loadUser", { userId: this.recipient.userRecipientId, type: "userId" });
      }
      await self.$store.dispatch("remit/getRecipientById", this.recipientId);
      if (this.userDetail) {
        (await this.recipientAccountNumber) ===
          this.userDetail.primaryAccount.accountNumber;
        (await this.recipientAccountType) === this.userDetail.primaryAccount.accountType;
      } else {
        this.recipientAccountNumber = "";
        this.recipientAccountType = "";
      }
    },

    async updatePickUpMethod() {
      const self = this;
      await f7.preloader.show("multi");
      this.amountToSend = parseFloat(this.totalCardbasket);
      await self.$store.dispatch("transaction/getCurrencyRate", {
        fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
        toCurrencyCode: this.recipientCountry.toCurrencyCode,
      });

      if (this.destinationctryId != null) {
        await this.calculateTransfer(); //Calculate transfer in Transfer mixin
        if (this.$keycloak.authenticated) {
          await self.$store.dispatch("remit/loadRecipientsByCountry", {
            countryId: this.destinationctryId,
            pickupMethod: this.pickupMethod,
          });
        }

        await self.$store.dispatch(
          "location/loadDestinationCity",
          this.recipientCountry.toCountryCode2
        ); //upadte pick-up city list
      } else {
        await f7.dialog.alert(self.$t("sendcash.selectRecipientCountry"));
        return false;
      }
      await f7.preloader.hide();
    },

    onPageInit() {
      const self = this;
    },

    async goToCheckTransferNoRecipient() {
      const self = this;
      if (this.pickupMethod) {
        await self.$store.dispatch("transaction/getExtraTransferDetail", {
          pickupMethod: this.pickupMethod,
          amount: parseFloat(this.amountToSend || 0),
          toCountryId: this.recipientCountry.toCountryId,
          destinationctry: this.recipientCountry.toCountryName,
        });
        //Check if no recipients in the sselected country recorded for the user then redirect to new recipient add
        if (this.recipients != "") {
          if (this.pickupMethod === "top-up") {
            await this.f7router.navigate("/recipients/topup");
          } else {
            await this.f7router.navigate("/recipients/pickup");
          }
        } else {
          const self = this;

          await f7.dialog.confirm(
            self.$t("sendcash.noRecipient", {
              recCntry: this.recipientCountry.toCountryName,
            }),
            () => {
              this.f7router.navigate("/recipient/create/new");
            }
          );
        }
      } else {
        const self = this;
        if (!self.toastTop) {
          self.toastTop = f7.toast.create({
            text: self.$t("sendcash.selectPickupMode"),
            cssClass: "checkCardToast",
            position: "top",
            closeTimeout: 3000,
          });
        }
        await self.toastTop.open();
        return false;
      }
    },

    async selectAccountToTopup() {
      const self = this;

      if (this.$keycloak.authenticated) {
        await f7.sheet.open(".SelectAccountToTopup");
      } else {
        await setTimeout(() => {
          if (this.$keycloak.authenticated) {
            f7.sheet.open(".SelectAccountToTopup");
          }
        }, 300);
      }
    },

    async topupMyAccount() {
      const self = this;

      if (this.accountNumber == this.primaryAccount.accountNumber) {
        this.accountType = this.primaryAccount.accountType;
      } else if (this.accountNumber == this.businessAccount.accountNumber) {
        this.accountType = this.businessAccount.accountType;
      }
      if (
        this.accountNumber == this.primaryAccount.accountNumber ||
        this.accountNumber == this.businessAccount.accountNumber
      ) {
        await f7.sheet.close(".SelectAccountToTopup");
        await self.$store.dispatch("transaction/topupMyAccount", {
          accountnumber: this.accountNumber,
          accountType: this.accountType,
        });
        setTimeout(() => {
          this.f7router.navigate("/dashboard/", {
            reloadCurrent: true,
            ignoreCache: true,
          });
        }, 300);
      } else {
        const self = this;
        if (!self.toastTop) {
          self.toastTop = f7.toast.create({
            text: self.$t("sendcash.selectYourAccount"),
            cssClass: "checkCardToast",
            position: "top",
            closeTimeout: 2000,
          });
        }
        await self.toastTop.open();
        return false;
      }
    },

    onPageBeforeRemove() {
      var self = this;

      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("card", ["carditemlist", "queryerror", "cardBasketAmount"]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "userAddress",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "qrcodeDecoded",
      "QRtext",
    ]),
    ...mapState("remit", ["recipients", "allrecipients", "recipient"]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    ...mapGetters("card", ["totalCardBasket"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    destinationCty() {
      return _(this.destinationCity)
        .groupBy("countryName")
        .pick([this.recipientCountry.toCountryName]);
    },
    recipientCountry() {
      return _.find(this.destinationCountry, ["toCountryId", this.destinationctryId]);
    },
  },
};
</script>
