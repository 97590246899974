<template>
  <f7-page hide-bars-on-scroll @page:beforeremove="onPageBeforeRemove" no-toolbar>
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding v-html="title"></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <!-- <f7-row no-gap>
      <f7-col width="0" medium="25"></f7-col>
      <f7-col width="100" medium="50"> -->
    <f7-block-title medium style="white-space: normal; word-wrap: break-word">{{
      $t("acceptcashwithaccountnumber.enterThePayer")
    }}<span class="avocash">{{ $t("acceptcashwithaccountnumber.avoash") }}</span>{{
  $t("acceptcashwithaccountnumber.accountAndSend") }}</f7-block-title>
    <f7-list dividers-ios strong-ios media-list id="acceptCashWithAcctNbr"
      v-if="paymentTransaction === '' || paymentTransaction.isValidated === true">
      <f7-list-group>
        <f7-block-header v-if="amountToPay !== ''">
          <span v-if="paymentCurrency != userAccount.currencyCode">{{ $t("acceptcashwithaccountnumber.claim")
          }}<b>{{
  $filtersToCurrency.toCurrency(
    paymentDetail.grossTransferAmount,
    paymentDetail.fromCurrencySymbol,
    paymentDetail.fromCurrencyCode
  )
}}</b>{{ $t("acceptcashwithaccountnumber.from")
}}<b>{{ userByAccountNumber.name }} </b>
          </span>
          <span v-else>{{ $t("acceptcashwithaccountnumber.claim")
          }}<b>{{
  $filtersToCurrency.toCurrency(amountToPay, "", userAccount.currencyCode)
}}</b>{{ $t("acceptcashwithaccountnumber.from")
}}<b>{{ userByAccountNumber.name }} </b>
          </span>
        </f7-block-header>
        <f7-list-input :label="$t('acceptcashwithaccountnumber.avoashNumber')" floating-label type="number" min="0"
          pattern="[0-9]*" required validate padding-right-half class="inputNumber" id="userAccountNumber" clear-button
          error-message="Please enter the account number!" name="userAccountNumber" :value="userAccountNumber"
          @input="userAccountNumber = $event.target.value" @blur="getUserByAccountNber()"></f7-list-input>
        <f7-block-footer v-if="userAccountNumber && (toastText || getusererror)">
          <p style="font-size: 10px; font-style: italic; color: red" v-html="getusererror || toastText"></p>
        </f7-block-footer>

        <f7-row no-gap>
          <f7-col width="25">
            <li class="item-content item-input no-padding-right">
              <div class="item-inner no-padding-right">
                <div class="item-title item-label">
                  {{ $t("acceptcashwithaccountnumber.currency") }}
                </div>
                <div class="item-input-wrap input-dropdown-wrap">
                  <select v-model="paymentCurrency" @change="changePaymentCurrency()" :disabled="!recipientCountry">
                    <option v-if="profile.sub != null" :value="primaryAccount.currencyCode" selected>
                      {{ primaryAccount.currencyCode }}
                    </option>
                    <option v-if="userByAccountNumber !== ''" :value="userByAccountNumber.primaryAccount.currencyCode">
                      {{ userByAccountNumber.primaryAccount.currencyCode }}
                    </option>
                  </select>
                </div>
              </div>
            </li>
          </f7-col>
          <f7-col width="75">
            <li class="item-content item-input padding-left-half">
              <div class="item-inner padding-left-half">
                <div class="item-title item-floating-label">
                  {{ $t("acceptcashwithaccountnumber.amountToClaim") }}
                </div>
                <div class="item-input-wrap">
                  <input type="number" name="amountToPay" id="amountToPay" class="inputNumber" inputmode="numeric" min="0"
                    required validate data-error-message="Please enter amount to claim!" :value="amountToPay"
                    @input="amountToPay = $event.target.value" @change="changeAmountToPay()" @keyup="changeAmountToPay()"
                    @blur="changeAmountToPay()" @keyup.enter="claimNow()" />
                  <span class="input-clear-button"></span>
                </div>
              </div>
            </li>
          </f7-col>
        </f7-row>

        <f7-block-footer style="font-size: 12px" class="no-margin"
          v-if="amountToPay != '' && paymentCurrency != userAccount.currencyCode">
          <p v-if="paymentDetail !== null">
            {{
              $t("acceptcashwithaccountnumber.youGet", {
                "1": $filtersToCurrency.toCurrency(
                  paymentDetail.receivedAmount,
                  paymentDetail.toCurrencySymbol,
                  paymentDetail.toCurrencyCode
                ),
              })
            }}
          </p>
        </f7-block-footer>
        <f7-list-input :label="$t('acceptcashwithaccountnumber.paymentDescription')" floating-label type="textarea"
          resizable name="description" :placeholder="$t('acceptcashwithaccountnumber.paymentDescription')"
          :value="description" @input="description = $event.target.value"></f7-list-input>
      </f7-list-group>
    </f7-list>
    <!--Ask if PIN required to confirm and release payment for remote payment mainly-->
    <!-- <f7-button raised fill color="red" style="margin-left:16px; margin-right:16px;" v-if="recipientuser !=null"  @click="paynow()" :disabled="scanQR.QRamountToPay>getProfile.primaryAccount.availableBalance">{{ $t('acceptcashwithaccountnumber.payNow') }}</f7-button> -->

    <span v-if="paymentTransaction === '' || paymentTransaction.isValidated === true">
      <f7-button raised fill ripple class="margin" large color="red" @click="claimNow()">{{
        $t("acceptcashwithaccountnumber.sendPaymentRequest") }}</f7-button>

      <f7-button v-if="(paymentDetail.grossTransferAmount != null ||
        paymentReverseDetail.grossTransferAmount != null) &&
        amountToPay != ''
        " ripple outline class="margin" raised color="red" @click="cancelScan()">{{
    $t("acceptcashwithaccountnumber.cancelPayment") }}</f7-button>
    </span>
    <f7-list dividers-ios strong-ios v-if="paymentTransaction.isValidated === false" media-list form
      class="margin-vertical-half">
      <f7-list-group>
        <f7-block-title color="green" class="text-align-center">{{
          $t("acceptcashwithaccountnumber.paymentFrom", {
            "1": paymentTransaction.payerName,
          })
        }}</f7-block-title>
        <f7-list-item :title="$t('acceptcashwithaccountnumber.youReceived')" :after="$filtersToCurrency.toCurrency(
          paymentTransaction.receivedPaymentAmount,
          paymentTransaction.toCurrencySymbol,
          paymentTransaction.toCurrencyCode
        )
          "></f7-list-item>
        <f7-block-title>{{ $t("common.validatePayment") }}</f7-block-title>
        <f7-row>
          <f7-col width="65" medium="80">
            <f7-list-item header="Transfer code" :title="paymentTransaction.transferCode"></f7-list-item>
          </f7-col>
          <f7-col width="25" medium="15">
            <f7-list-input type="password" floating-label :label="$t('common.pin')" :placeholder="$t('common.pin')"
              inputmode="numeric" :value="transferPIN" @input="transferPIN = $event.target.value" @keyup.enter="
                validateCreditTransaction(
                  paymentTransaction.transactionType,
                  paymentTransaction.transferCode
                )
                " pattern="[0-9]*" maxlength="4" size="4" required></f7-list-input>
          </f7-col>
        </f7-row>
      </f7-list-group>
    </f7-list>
    <f7-button v-if="paymentTransaction.isValidated === false" raised fill ripple large class="margin" color="red" strong
      @click="
        validateCreditTransaction(
          paymentTransaction.transactionType,
          paymentTransaction.transferCode
        )
        ">{{ $t("common.validateTransfer") }}</f7-button>
    <!-- </f7-col>
      <f7-col width="0" medium="25"></f7-col>
    </f7-row> -->
  </f7-page>
</template>
<script>
import userByAccountNumber from "../js/mixins/userByAccountNumber.js";
import { calculateTransferPayment, transferAgain } from "../js/mixins/transaction.js";
import Navbarright from "../components/navbarright.vue";
import { f7 } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [userByAccountNumber, calculateTransferPayment, transferAgain],
  data() {
    return {
      theme,
      f7,
      title: "Get paid with <span class='avocash'>avo¢ash</span>  number",
      amountToPay: "",
      userAccountNumber: "",
      transferRateId: "",
      paymentCurrency: "",
      toAccount: "",
      toastText: "",
      transferPIN: "",
      description: "",
    };
  },

  mounted: function () {
    const self = this;

    if (this.userAccount === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.paymentCurrency = this.payeeAccount.currencyCode;
    this.toAccount = "Primary";
    if (!this.recipientCountry && this.userByAccountNumber) {
      this.toastText =
        "You cannot receive payment from" +
        " " +
        this.userByAccountNumber.country.countryName;
    }
  },
  methods: {
    changeAmountToPay() {
      const self = this;

      this.amountToPay = f7.$("#amountToPay").val();
      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        this.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              if (
                this.paymentCurrency === this.payerAccount.currencyCode &&
                this.paymentCurrency !== null
              ) {
                if (
                  parseFloat(this.amountToPay) >
                  parseFloat(this.payerAccount.accountBalance)
                ) {
                  this.amountToPay = parseFloat(this.payerAccount.accountBalance);
                }
                this.calculatePayment();
              } else if (
                this.paymentCurrency !== this.payerAccount.currencyCode &&
                this.paymentCurrency !== null
              ) {
                if (
                  parseFloat(this.amountToPay) / parseFloat(this.grossexchangerate2) >
                  parseFloat(this.payerAccount.accountBalance)
                ) {
                  this.amountToPay =
                    parseFloat(this.payerAccount.accountBalance) /
                    parseFloat(this.grossexchangerate2);
                }
                this.calculatePaymentReverse();
              } else {
                return false;
              }
            }, 500);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return false;
      }
    },

    changePaymentCurrency() {
      const self = this;
      if (this.paymentDetail || this.paymentReverseDetail) {
        this.$store.dispatch("transaction/resetPaymentDetail");
        this.$store.dispatch("transaction/resetPaymentReverseDetail");
      }
      this.changeAmountToPay();
    },

    claimNow() {
      const self = this;

      if (f7.input.validateInputs("#acceptCashWithAcctNbr")) {
        f7.preloader.show("multi");
        self.$store
          .dispatch("payment/makePayment", {
            type: "avo¢ash claim",
            amount: this.amountToPay,
            paymentCurrency: this.paymentCurrency,
            payerAccountNumber: this.userByAccountNumber.accountNumber,
            payerGeolocation: "",
            payerIpAddress: "",

            payeeAccountNumber: this.payeeAccount.accountNumber,
            payeeGeolocation: this.latitude + "-" + this.longitude,
            payeeIpAddress: this.ipAddress,
            description: this.description,
          })
          .then((result) => {
            console.log(result);
            setTimeout(() => {
              if (this.toAccount === "Primary") {
                self.$store.dispatch("account/loadPrimaryAccount");
              } else {
                self.$store.dispatch("account/loadBusinessAccount");
              }
            }, 500);
            f7.preloader.hide();
          })
          .catch((err) => {
            f7.preloader.hide();
          });
      } else {
        var valid = f7.$("#acceptCashWithAcctNbr .input-invalid").length === 0;
      }
    },
    cancelScan() {
      const self = this;

      this.amountToPay = "";
      this.paymentCurrency = "";
      this.userAccountNumber = "";
      self.$store.dispatch("account/resetUserByAccountNumber");
      self.$store.dispatch("transaction/resetPaymentDetail");
      self.$store.dispatch("transaction/resetPaymentReverseDetail");
    },
    onPageBeforeRemove() {
      const self = this;

      this.cancelScan();
    },
  },
  computed: {
    ...mapState("payment", [
      "paymentTransaction",
      "paymentTransactionList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("account", ["userAccount", "userByAccountNumber", "getusererror"]),
    ...mapState("location", ["latitude", "longitude", "ipAddress"]),

    ...mapState("auth", ["profile"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    recipientCountry() {
      if (!this.userByAccountNumber) {
        return false;
      } else {
        return _.find(this.originCountry, [
          "fromCountryId",
          this.userByAccountNumber.country.countryId,
        ]);
      }
    },
    payeeAccount() {
      // if (this.toAccount === "Primary") {
      return this.userAccount;
      // } else {
      //   return this.businessAccount;
      // }
    },
    payerAccount() {
      if (this.userByAccountNumber) {
        if (this.userByAccountNumber.accountType === "Standard") {
          return this.userByAccountNumber.primaryAccount;
        } else {
          return this.userByAccountNumber.businessAccount;
        }
      }
    },
  },
};
</script>
