<template>
  <f7-popover class="otherActions" :backdrop="false">
    <f7-list dividers-ios strong-ios media-list no-chevron>
      <f7-list-item link="#" class="searchbar-enable" data-searchbar=".searchbar-message" popover-close
        :title="$t('common.search')">
      </f7-list-item>

      <f7-list-item link="#" popover-close @click="openContactDetailPanel()">
        <div v-if="this.groupType === 'PERSONAL'">
          {{ $t("tamtammessage.contactInfo") }}
        </div>
        <div v-else-if="this.groupType === 'GROUP'">
          {{ $t("tamtammessage.groupInfo") }}
        </div>
        <div v-else-if="this.groupType === 'SUBGROUP'">
          {{ $t("tamtammessage.subgroupInfo") }}
        </div>
      </f7-list-item>
      <f7-list-item link="#" @click="makeSelectable()" popover-close
        :title="$t('tamtammessage.selectMessages')"></f7-list-item>
      <f7-list-item link="#" popover-close :title="$t('tamtammessage.clearMessages')"></f7-list-item>
      <f7-list-item link="#" popover-close :title="$t('tamtammessage.deleteChat')"
        @click="deleteChat(group)"></f7-list-item>
      <f7-list-item link="#" popover-close :title="$t('tamtammessage.muteNotifications')"></f7-list-item>
      <f7-list-item link="#" popover-close :title="$t('tamtammessage.exitGroup')" v-if="this.groupType !== 'PERSONAL'"
        @click="leaveGroup(group)"></f7-list-item>
    </f7-list>
  </f7-popover>

  <f7-sheet class="messageActionsSheet" style="height: auto; --f7-sheet-bg-color: #fff" swipe-to-close :backdrop="false">
    <f7-list dividers-ios strong-ios media-list no-chevron>
      <f7-list-item v-if="currentMessage.groupType !== 'PERSONAL'" link="#" sheet-close :title="$t('tamtammessage.reply')"
        @click="replyMsgGroup(currentMessage)"></f7-list-item>
      <f7-list-item v-if="currentMessage.groupType === 'PERSONAL'" link="#" sheet-close :title="$t('tamtammessage.reply')"
        @click="replyMsgPersonal(currentMessage)"></f7-list-item>
      <f7-list-item v-if="currentMessage.groupType !== 'PERSONAL' &&
        currentMessage.senderId !== profile.sub
        " link="#" sheet-close :title="$t('tamtammessage.replyPrivately')"
        @click="replyMsgPrivately(currentMessage)"></f7-list-item>
      <f7-list-item link="#" sheet-close :title="$t('tamtammessage.forwardMessage')"
        @click="forwardMsg(currentMessage)"></f7-list-item>
      <f7-list-item link="#" sheet-close :title="currentMessage.senderId === profile.sub
          ? $t('tamtammessage.deleteMessageForMe')
          : $t('tamtammessage.deleteMessage')
        " @click="deleteForMe(currentMessage)"></f7-list-item>
      <f7-list-item v-if="currentMessage.senderId === profile.sub" link="#" sheet-close
        :title="$t('tamtammessage.deleteMessageForAll')" @click="deleteForAll(currentMessage)"></f7-list-item>

      <f7-list-item link="#" sheet-close :title="$t('tamtammessage.selectMessage')"
        @click="selectMsg(currentMessage)"></f7-list-item>
      <f7-list-item link="#" sheet-close :title="$t('tamtammessage.transferMessage')"
        @click="forwardMsg(currentMessage)"></f7-list-item>

      <f7-list-item link="#" sheet-close :title="$t('tamtammessage.copyMessage')"
        @click="copyMsg(currentMessage)"></f7-list-item>
    </f7-list>
  </f7-sheet>

  <!-- <div class="popover messageActionsPopover" data-close-on-escape="true" data-backdrop="false">
    <div class="popover-inner" v-if="this.currentMessage && this.currentMessage !== ''">
      <f7-list dividers-ios strong-ios media-list no-chevron>
        <f7-list-item v-if="this.currentMessage.groupType !== 'PERSONAL'" link="#" popover-close text-color="primary"
          :title="$t('tamtammessage.reply')" @click="replyMsgGroup(currentMessage)"></f7-list-item>
        <f7-list-item v-if="this.currentMessage.groupType === 'PERSONAL'" link="#" popover-close text-color="primary"
          :title="$t('tamtammessage.reply')" @click="replyMsgPersonal(currentMessage)"></f7-list-item>
        <f7-list-item v-if="this.currentMessage.groupType !== 'PERSONAL' &&
          this.currentMessage.senderId !== profile.sub
          " link="#" popover-close text-color="teal" :title="$t('tamtammessage.replyPrivately')"
          @click="replyMsgPrivately(currentMessage)"></f7-list-item>
        <f7-list-item link="#" popover-close text-color="gray" :title="$t('tamtammessage.forwardMessage')"
          @click="forwardMsg(currentMessage)"></f7-list-item>
        <f7-list-item link="#" popover-close text-color="orange" :title="$t('tamtammessage.deleteMessageForMe')"
          @click="deleteForMe(currentMessage)"></f7-list-item>
        <f7-list-item v-if="this.currentMessage.senderId === profile.sub" link="#" popover-close text-color="red"
          :title="$t('tamtammessage.deleteMessageForAll')" @click="deleteForAll(currentMessage)"></f7-list-item>
        <f7-list-item link="#" popover-close text-color="deeppurple" :title="$t('tamtammessage.selectMessage')"
          @click="selectMsg(currentMessage)"></f7-list-item>
        <f7-list-item link="#" popover-close text-color="teal" :title="$t('tamtammessage.copyMessage')"
          @click="copyMsg(currentMessage)"></f7-list-item>
      </f7-list>
    </div>
  </div> -->
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { messageOptionsMixin, messageGroupMixin } from "../../js/mixins/messageMixins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    groupType: String,
    currentMessage: Object,
  },
  mixins: [messageOptionsMixin, messageGroupMixin],
  // components: {
  //   Cropper,
  // },
  data() {
    return {
      theme,
      f7,
      // actionGridOpened: false,
      // messageIsSelected: "",
      // selectedMsg: [],
    };
  },

  // methods: {

  // },

  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "messageIsSelected",
      "selectedMessages",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
      "documentsUrl",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
  },
};
</script>
