import { f7, theme } from "framework7-vue";
export const calculateTransferPayment = {
  methods: {
    async retrieveCardBasketAmount(cardBasketTotalAmount) {
      this.amountToSend = parseFloat(cardBasketTotalAmount);
      this.amountToPay = parseFloat(cardBasketTotalAmount);
      this.calculatePayment();
      this.calculateTransfer();
    },
    calculateTransfer() {
      if (
        this.recipientCountry &&
        parseFloat(this.amountToSend || this.amount) > 0
      ) {
        this.$store.dispatch("transaction/getCurrencyRate", {
          fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
          toCurrencyCode: this.recipientCountry.toCurrencyCode,
        });

        setTimeout(() => {
          this.$store.dispatch("transaction/calculateTransfer", {
            grossXrate: parseFloat(this.grossexchangerate2),
            fromCountryCode: this.recipientCountry.fromCountryCode2,
            toCountryCode: this.recipientCountry.toCountryCode2,
            pickupMethod: this.pickupMethod,
            amount: parseFloat(this.amountToSend || this.amount),
          });
        }, 500);
      }
    },
    async calculateTransferReverse() {
      if (this.recipientCountry && parseFloat(this.amountToReceive) > 0) {
        await this.$store.dispatch("transaction/getCurrencyRate", {
          fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
          toCurrencyCode: this.recipientCountry.toCurrencyCode,
        });
        await this.$store.dispatch("transaction/calculateTransferReverse", {
          grossXrate: parseFloat(this.grossexchangerate2),
          fromCountryCode: this.recipientCountry.fromCountryCode2,
          toCountryCode: this.recipientCountry.toCountryCode2,
          pickupMethod: this.pickupMethod,
          amount: parseFloat(this.amountToReceive),
        });
      }
    },
    async calculatePayment() {
      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        await this.$store.dispatch("transaction/getCurrencyRate", {
          fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
          toCurrencyCode: this.recipientCountry.toCurrencyCode,
        });
        await this.$store.dispatch("transaction/calculatePayment", {
          grossXrate: parseFloat(this.grossexchangerate2),
          fromCountryCode: this.recipientCountry.fromCountryCode2,
          toCountryCode: this.recipientCountry.toCountryCode2,
          pickupMethod: "top-up",
          amount: parseFloat(this.amountToPay),
        });
      }
    },
    async calculatePaymentReverse() {
      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        await this.$store.dispatch("transaction/getCurrencyRate", {
          fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
          toCurrencyCode: this.recipientCountry.toCurrencyCode,
        });
        await this.$store.dispatch("transaction/calculatePaymentReverse", {
          grossXrate: parseFloat(this.grossexchangerate2),
          fromCountryCode: this.recipientCountry.fromCountryCode2,
          toCountryCode: this.recipientCountry.toCountryCode2,
          pickupMethod: "top-up",
          amount: parseFloat(this.amountToPay),
        });
      }
    },

    async calculateAmountToSend() {
      if (this.senderAccountNumber == this.primaryAccount.accountNumber) {
        this.senderAccountBalance = parseFloat(
          this.primaryAccount.accountBalance
        );
        this.amounterror = "";
      } else if (
        this.senderAccountNumber == this.businessAccount.accountNumber
      ) {
        this.senderAccountBalance = parseFloat(
          this.businessAccount.accountBalance
        );
        this.amounterror = "";
      } else {
        this.amounterror = this.$t("common.selectYourAccount");
      }
      if (!(this.recipientCountry && parseFloat(this.amountToSend) > 0)) {
        return;
      }
      await this.$store.dispatch("transaction/getCurrencyRate", {
        fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
        toCurrencyCode: this.recipientCountry.toCurrencyCode,
      });
      this.amountToReceive =
        parseFloat(this.receivedAmount) / parseFloat(this.grossexchangerate2) <=
        parseFloat(this.senderAccountBalance || this.totalCardbasket)
          ? await this.receivedAmount
          : parseFloat(this.senderAccountBalance || this.totalCardbasket) *
            parseFloat(this.grossexchangerate2);
      await this.calculateTransferReverse();
      this.amountToSend = parseFloat(
        this.transferDetail.grossTransferAmount
      ).toFixed(2);
    },

    async goToCheckTransfer() {
      if (
        parseFloat(this.amountToSend || this.totalCardbasket) > 0 &&
        parseFloat(this.transferDetail.receivedAmount) > 0
      ) {
        await this.$store.dispatch("transaction/getExtraTransferDetail", {
          pickupMethod: this.pickupMethod,
          amount: parseFloat(this.amountToSend || this.totalCardbasket),
          name: this.recipient.name,
          phone: this.recipient.phone,
          phoneCode: this.recipient.phoneCode,
          accountNumber: this.recipientAccountNumber || "",
          recipientId: this.recipient.id,
          toCountryId: this.recipient.countryId,
          countryName: this.recipient.countryName,
          cityName: this.recipient.cityName,
          destinationctry: this.recipient.countryName, //to be change to desitination country if user were to change destination country vs default  recipient country
          senderAccountNumber: this.senderAccountNumber,
          senderAccountType: this.senderAccountType,
          recipientAccountNumber: this.recipientAccountNumber || "",
          recipientAccountType: this.recipientAccountType || "",
        });

        f7.views.main.router.navigate(
          `/transfer/validate/${this.recipient.id}`
        );
        return;
      }
      if (!this.toastTop) {
        this.toastTop = f7.toast.create({
          text: this.$t("common.enterRecipientAcctAndAmt"),
          cssClass: "checkCardToast",
          position: "top",
          closeTimeout: 2000,
        });
      }
      await this.toastTop.open();
      return false;
    },
  },
};

export const calcAmountToPay = {
  methods: {
    changeAmountToPay() {
      if (this.recipientCountry && parseFloat(this.amountToPay) > 0) {
        this.$store
          .dispatch("transaction/getCurrencyRate", {
            fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
            toCurrencyCode: this.recipientCountry.toCurrencyCode,
          })
          .then((result) => {
            setTimeout(() => {
              if (this.profile.sub !== null) {
                if (
                  this.paymentCurrency === this.primaryAccount.currencyCode &&
                  this.paymentCurrency !== null
                ) {
                  if (
                    parseFloat(this.amountToPay) >
                    parseFloat(this.primaryAccount.accountBalance)
                  ) {
                    this.amountToPay = parseFloat(
                      this.primaryAccount.accountBalance
                    );
                  }
                  this.calculatePayment();
                } else if (
                  this.paymentCurrency !== this.primaryAccount.currencyCode &&
                  this.paymentCurrency !== null
                ) {
                  if (
                    parseFloat(this.amountToPay) /
                      parseFloat(this.grossexchangerate2) >
                    parseFloat(this.primaryAccount.accountBalance)
                  ) {
                    this.amountToPay =
                      parseFloat(this.primaryAccount.accountBalance) *
                      parseFloat(this.grossexchangerate2);
                  }
                  this.calculatePaymentReverse();
                } else {
                  return false;
                }
              } else if (
                this.paymentCurrency === this.wallet.currencyCode &&
                this.paymentCurrency !== null
              ) {
                this.calculatePayment();
              } else if (
                this.paymentCurrency !== this.wallet.currencyCode &&
                this.paymentCurrency !== null
              ) {
                this.calculatePaymentReverse();
              } else {
                return false;
              }
            }, 500);
          })
          .catch((err) => {});
      } else {
        return false;
      }
    },
    async changePaymentCurrency() {
      if (this.paymentDetail || this.paymentReverseDetail) {
        await this.$store.dispatch("transaction/resetPaymentDetail");
        await this.$store.dispatch("transaction/resetPaymentReverseDetail");
      }
      this.changeAmountToPay();
    },

    async topUpPopupOpen() {
      await this.$store.dispatch("transaction/resetPaymentDetail");
      await this.$store.dispatch("transaction/resetPaymentReverseDetail");
      await this.$store.dispatch("card/loadCarditemlist");
      await this.amountToPayChange();
    },
    validatedDialog() {
      if (!this.toastIcon) {
        this.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-icons text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-icons text-color-green border-color-gray color-green" style="font-size:150px">check_circle_outline</i>',
          text: "",
          // this.scannedCard.status || this.QRtext.status,
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 3000,
        });
      }
      this.toastIcon.open();
    },

    // validatedDialog() {
    //     const self = this;
    //
    //     if (!self.toastIcon) {
    //         self.toastIcon = f7.toast.create({
    //             icon: theme.ios ?
    //                 '<i class="f7-icons text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>' : '<i class="material-icons text-color-green " style="font-size:150px">check_circle_outline</i>',
    //             text: i18n.t('common.transactionValidated'),
    //             cssClass: "toast_validate",
    //             position: "center",
    //             closeTimeout: 1500,
    //         });
    //     }
    //     self.toastIcon.open();
    // },
  },
};

export const transferAgain = {
  mounted() {},
  methods: {
    async sendAgain(transaction, recipient) {
      f7.preloader.show("multi");
      if (transaction.pickupTransaction) {
        this.reciptId = await recipient.id;
        this.amount = parseFloat(
          Math.abs(transaction.pickupTransaction.grossTransferAmount)
        );
      } else {
        this.reciptId = await recipient.id;
        this.amount = parseFloat(Math.abs(transaction.grossTransferAmount));
      }
      (await this.$store.state.transaction.transferDetail) === {};
      await this.$store.dispatch("transaction/getExtraTransferDetail", {
        amount: this.amount,
        recipientId: this.reciptId,
      });
      await this.calculateTransfer();
      setTimeout(() => {
        f7.popup.close(".transactionDetailCard");
        f7.views.main.router.navigate(
          `/send-cash/from-balance/${this.reciptId}/pick-up`
        );
        f7.preloader.hide();
      }, 300);
    },

    async topupAgain(transaction, recipient) {
      f7.preloader.show("multi");
      if (transaction.topupTransaction) {
        this.reciptId = await recipient.id;
        this.userRecipientId = await recipient.userRecipientId;
        this.amount = parseFloat(
          Math.abs(transaction.topupTransaction.grossTransferAmount)
        );
      } else {
        this.reciptId = await recipient.id;
        this.userRecipientId = await recipient.userRecipientId;
        this.amount = parseFloat(Math.abs(transaction.grossTransferAmount));
      }
      console.log(this.amount);
      await this.$store.dispatch("account/loadUser", {
        userId: this.userRecipientId,
        type: "userId",
      });
      await this.$store.dispatch("transaction/getExtraTransferDetail", {
        amount: this.amount,
        recipientId: this.reciptId,
      });
      await this.calculateTransfer();
      setTimeout(() => {
        f7.popup.close(".transactionDetailCard");
        f7.views.main.router.navigate(
          `/send-cash/from-balance/${this.reciptId}/top-up`
        );
        f7.preloader.hide();
      }, 300);
    },
    async sendAgainFromAccount(transaction) {
      f7.preloader.show("multi");
      this.reciptId = await transaction.counterPartId;
      this.amount = parseFloat(Math.abs(transaction.amount));
      await this.$store.dispatch("transaction/getExtraTransferDetail", {
        amount: this.amount,
        recipientId: this.reciptId,
      });
      await this.calculateTransfer();
      setTimeout(() => {
        f7.popup.close(".transactionDetailCard");
        f7.views.main.router.navigate(
          `/send-cash/from-balance/${this.reciptId}/pick-up`
        );
        f7.preloader.hide();
      }, 300);
    },

    async topupAgainFromAccount(transaction) {
      f7.preloader.show("multi");
      this.reciptId = await transaction.counterPartId;
      this.amount = parseFloat(Math.abs(transaction.amount));
      await this.$store.dispatch("remit/getRecipientById", this.reciptId);
      await this.$store.dispatch("transaction/getExtraTransferDetail", {
        amount: this.amount,
        recipientId: this.reciptId,
      });
      await this.calculateTransfer();
      setTimeout(() => {
        f7.popup.close(".transactionDetailCard");
        f7.views.main.router.navigate(
          `/send-cash/from-balance/${this.reciptId}/top-up`
        );
        f7.preloader.hide();
      }, 300);
    },

    async calculateTransfer() {
      if (
        this.recipientCountry &&
        parseFloat(this.amountToSend || this.amount) > 0
      ) {
        await this.$store.dispatch("transaction/getCurrencyRate", {
          fromCurrencyCode: this.recipientCountry.fromCurrencyCode,
          toCurrencyCode: this.recipientCountry.toCurrencyCode,
        });
        await this.$store.dispatch("transaction/calculateTransfer", {
          grossXrate: parseFloat(this.grossexchangerate2),
          fromCountryCode: this.recipientCountry.fromCountryCode2,
          toCountryCode: this.recipientCountry.toCountryCode2,
          pickupMethod: this.pickupMethod,
          amount: parseFloat(this.amountToSend || this.amount),
        });
      }
    },
    async validateTopUp(topuptransaction) {
      f7.preloader.show("multi");
      if (topuptransaction !== null) {
        console.log(topuptransaction);
        await this.$store.dispatch("remit/validateTopUp", {
          transferCode: topuptransaction.transferCode,
          transferPIN: topuptransaction.transferPIN,
        });
      }
      f7.preloader.hide();
      this.validatedDialog();
      await this.f7router.refreshPage();
    },
    // Making payment from your account validation, you receive the transferPIN on payment and you validate the transaction
    async validateDebitTransaction(transaction) {
      f7.preloader.show("multi");
      if (transaction.transactionType === "avo¢ash top-up") {
        await this.$store.dispatch("remit/validateTopUp", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      } else if (
        transaction.transactionType === "tontine top-up" ||
        transaction.transactionType === "tontine redeem"
      ) {
        await this.$store.dispatch("tontine/validateRoundRedeem", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      } else if (
        transaction.transactionType === "wallet top-up" ||
        transaction.transactionType === "wallet redeem"
      ) {
        await this.$store.dispatch("wallet/validateWalletRedeem", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      } else if (
        transaction.transactionType === "association top-up" ||
        transaction.transactionType === "association redeem"
      ) {
        await this.$store.dispatch("asso/validateCampaignRedeem", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      } else if (
        transaction.transactionType === "avo¢ash shop"
        // || transaction.transactionType === "wallet redeem"
      ) {
        await this.$store.dispatch("shopOrder/validateOrder", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      } else if (transaction.transactionType === "avo¢ash pay") {
        await this.$store.dispatch("payment/validatePayment", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      } else if (transaction.transactionType === "avo¢ash out") {
        await this.$store.dispatch("payment/validatePayment", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      }
      setTimeout(() => {
        this.$store.dispatch("transaction/loadPrimaryTransactionList", 25);
        this.$store.dispatch("transaction/loadBusinessTransactionList", 25);
        f7.preloader.hide();
        this.validatedDialog();
        this.f7router.refreshPage();
        this.f7router.back();
      }, 1500);
    },
    // Receiving payment in your account from someone else who has not validated the transaction, you receive the transferPIN from the payer and you validate the transaction
    async validateCreditTransaction(transactionType, transferCode) {
      f7.preloader.show("multi");
      if (transactionType === "avo¢ash top-up") {
        await this.$store.dispatch("remit/validateTopUp", {
          transferCode,
          transferPIN: this.transferPIN,
        });
      } else if (
        transactionType === "tontine top-up" ||
        transactionType === "tontine redeem"
      ) {
        await this.$store.dispatch("tontine/validateRoundRedeem", {
          transferCode,
          transferPIN: this.transferPIN,
        });
      } else if (
        transactionType === "wallet top-up" ||
        transactionType === "wallet redeem"
      ) {
        await this.$store.dispatch("wallet/validateWalletRedeem", {
          transferCode,
          transferPIN: this.transferPIN,
        });
      } else if (
        transactionType === "association top-up" ||
        transactionType === "association redeem"
      ) {
        await this.$store.dispatch("asso/validateCampaignRedeem", {
          transferCode,
          transferPIN: this.transferPIN,
        });
      } else if (
        transactionType === "avo¢ash shop"
        // || transaction.transactionType === "wallet redeem"
      ) {
        await this.$store.dispatch("shopOrder/validateOrder", {
          transferCode,
          transferPIN: this.transferPIN,
        });
      } else if (
        transactionType === "avo¢ash pay"
        // || transaction.transactionType === "wallet redeem"
      ) {
        await this.$store.dispatch("payment/validatePayment", {
          transferCode,
          transferPIN: this.transferPIN,
        });
      } else if (transactionType === "avo¢ash out") {
        await this.$store.dispatch("payment/validatePayment", {
          transferCode,
          transferPIN: this.transferPIN,
        });
      }
      this.validatedDialog();
      setTimeout(() => {
        this.$store.dispatch("transaction/loadPrimaryTransactionList", 25);
        this.$store.dispatch("transaction/loadBusinessTransactionList", 25);
        f7.preloader.hide();
        this.f7router.refreshPage();
        this.f7router.back();
      }, 1500);
    },
    validatedDialog() {
      if (!this.toastIcon) {
        this.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-icons text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-icons text-color-green border-color-gray color-green" style="font-size:150px">check_circle_outline</i>',
          text: "",
          // this.scannedCard.status || this.QRtext.status,
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 3000,
        });
      }
      this.toastIcon.open();
    },

    transactionStatus(transaction) {
      if (transaction.status === "Paid") {
        return this.$t("common.paid");
      }
      if (transaction.status === "Complete") {
        return this.$t("common.complete");
      }
      if (transaction.status === "Pending") {
        return this.$t("common.pending");
      }
      if (transaction.status === "Cancelled") {
        return this.$t("common.cancelled");
      }
    },

    async cancelTransaction(transaction) {
      await this.$store.dispatch("payment/cancelPayment", transaction);
    },
  },
};
