<template>
  <f7-page hide-bars-on-scroll @page:beforeout="beforeOut">
    <f7-navbar v-if="this.curRecipient && this.curRecipient.name !== ''"
      :title="$t('recipientnew.updateTheForDetails') + this.curRecipient.name" no-shadow
      :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title medium style="white-space: normal; word-wrap: break-word">{{
          $t("recipientnew.updateTheRecipientDetails")
        }}</f7-block-title>
        <f7-list dividers-ios strong-ioslist media-list form>
          <f7-list-group>
            <f7-list-input :label="$t('common.firstName')" floating-label type="text" name="firstName"
              :placeholder="$t('common.firstName')" :value="firstName" @input="firstName = $event.target.value"
              :error-message="$t('common.provideFirstName')" required validate clear-button></f7-list-input>
            <f7-list-input :label="$t('common.lastName')" floating-label type="text" name="lastname"
              :placeholder="$t('common.lastName')" v-model:value="lastName" :error-message="$t('common.provideLastName')"
              required validate clear-button></f7-list-input>
            <f7-list-input :label="$t('common.phoneNumber')" type="tel" name="telephone" input-id="phoneNumber"
              v-model:value="this.telephone" :error-message="$t('common.providePhoneNumber')" required
              validate></f7-list-input>
            <f7-list-item :title="$t('common.selectCountry')" class="selectCountry" smart-select :smart-select-params="{
              openIn: 'page',
              searchbar: true,
              searchbarPlaceholder: $t('common.findCountry'),
              closeOnSelect: true,
            }" style="display: none">
              <select name="country" v-model="this.selectedCountry">
                <optgroup v-for="(countryList, continentName, index) in this.countries" :label="continentName"
                  :key="index">
                  <option v-for="country in countryList" :key="country.id" :value="country.countryCode2"
                    :data-option-image="'https://flagcdn.com/' + country.countryCode2.toLowerCase() + '.svg'
                      " width="24">
                    {{ country.name }}
                    {{ country.countryCode2 }} +{{ country.dialCode }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>

            <f7-list-input :label="$t('common.email')" floating-label type="email" name="email"
              :placeholder="$t('common.email')" v-model:value="email" :error-message="$t('common.provideEmail')" required
              validate clear-button></f7-list-input>
            <f7-list-input :label="$t('common.country')" floating-label type="text" name="countryName"
              v-model:value="this.countryName" readonly></f7-list-input>

            <f7-list-input :label="$t('common.address')" floating-label type="text" name="address"
              :placeholder="$t('common.address')" v-model:value="address" :error-message="$t('common.provideAddress')"
              required validate clear-button></f7-list-input>
            <!-- <f7-button
          if-aurora
          class="margin"
          raised
          large
          ripple
          fill
          color="red"
          @click="saveRecipient(recipient.id)"
            >{{ $t('recipientedit.updateRecipient') }}</f7-button>-->
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
          <f7-link raised fill large ripple bg-color="red" class="link button button-fill buttons"
            @click="saveRecipient()" strong text-color="white">{{ $t("common.save") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7BlockTitle,
  f7List,
  f7ListGroup,
  f7ListInput,
  f7Toolbar,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { selectedRecipient } from "../js/mixins/recipient.js";
import { telephoneUtils } from "../js/mixins/telephone.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [selectedRecipient, telephoneUtils],
  components: {
    f7Page,
    f7Navbar,
    f7BlockTitle,
    f7List,
    f7ListGroup,
    f7ListInput,
    f7Toolbar,
  },
  data() {
    return {
      theme,
      f7,
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      address: "",
      countryName: "",
      selectedCountry: "",
      itiPhoneNumber: "",
    };
  },
  mounted() {
    console.log(this.recipient);
    this.telephone = this.curRecipient.phone;
    this.firstName = this.curRecipient.firstName;
    this.lastName = this.curRecipient.lastName;
    this.email = this.curRecipient.email;
    this.address = this.curRecipient.address;
    this.countryName = this.curRecipient.countryName;
    this.selectedCountry = this.curRecipient.countryCode;
  },
  computed: {
    ...mapState("remit", [
      "recipients",
      "recipient",
      "allrecipients",
      "userrecipientList",
      "recipientTransactionList",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),

    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("account", ["userByAccountNumber"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
    curRecipient() {
      const self = this;
      return this.recipient === ""
        ? _.find(this.allrecipients, ["id", this.f7route.params.id])
        : this.recipient;
    },
    recipientCountry() {
      return _.find(this.country, [
        "countryCode2",
        this.recipient.countryCode || this.selectedCountry,
      ]);
    },
  },
  methods: {
    async saveRecipient() {
      const self = this;
      await self.$store.dispatch("remit/recipientEdit", {
        id: this.recipient.id,
        firstName: this.firstName,
        lastName: this.lastName,
        name: `${this.firstName} ${this.lastName}`,
        email: this.email,
        phone: this.telephone,
        address: this.address,
        countryCode: this.recipientCountry.countryCode2,
        countryName: this.recipientCountry.name,
      });
      await this.f7router.navigate(`/recipient/${this.recipient.id}`);
    },
    beforeOut() {
      const self = this;
      f7.$(".mainToolbar").css("display", "block");
    },
  },
};
</script>
