<template>
  <f7-page hide-bars-on-scroll :page-content="false">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title v-html="this.title" sliding></f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar top>
      <f7-link tab-link="#avocashATMQRCodeScan" tab-link-active :text="$t('avocashatm.scanQrCode')"
        icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder" icon-md="f7:qrcode_viewfinder"
        style="text-transform: lowercase"></f7-link>
      <f7-link tab-link="#avocashATMWithNumber" :text="$t('avocashatm.useAccount')" icon-aurora="f7:keyboard_fill"
        icon-ios="f7:keyboard_fill" icon-md="material:dialpad" style="text-transform: lowercase"></f7-link>
      <f7-link tab-link="#avocashATMPickUp" :text="$t('avocashatm.pickup')" icon-ios="f7:qrcode" icon-aurora="f7:qrcode"
        icon-md="material:style" style="text-transform: lowercase"></f7-link>
    </f7-toolbar>
    <f7-tabs swipeable animated>
      <f7-tab id="avocashATMQRCodeScan" tab-active>
        <avocashATMQRCodeScan-page />
      </f7-tab>
      <f7-tab id="avocashATMWithNumber">
        <accountNumberATM-page />
      </f7-tab>
      <f7-tab id="avocashATMPickUp">
        <avocashATMPickUp-page />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script>
import AvocashATMQRCodeScan from "./avocashATMWithQRCodeScan.vue";
import AccountNumberATM from "./avocashATMWithAccountNumber.vue";
import AvocashATMPickUp from "./avocashATMPickUp.vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import {
  theme,
  f7,
} from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    "avocashATMQRCodeScan-page": AvocashATMQRCodeScan,
    "accountNumberATM-page": AccountNumberATM,
    "avocashATMPickUp-page": AvocashATMPickUp,
    navbarright: Navbarright,
  },
  data() {
    return { theme, f7, title: "<span class='avocash'>avo¢ash</span> ATM" };
  },
  mounted() {
    const self = this;

    if (this.isTablet == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
  },
  // methods: {},
  computed: { ...mapState("auth", ["isDeskTop", "isTablet"]) },
};
</script>
