<template>
  <!-- <div class="addDocument padding-bottom" v-if="this.type !== 'message'">
    <f7-block-title v-if="this.type === 'transfer'" style="white-space: normal; word-wrap: break-word; font-weight: 90%"
      v-html="$t('common.addInvoicesForPurpose', { theType: this.type })"></f7-block-title>
    <f7-block-title v-if="this.type === 'bankAccount'"
      style="white-space: normal; word-wrap: break-word; font-weight: 90%" v-html="$t('common.addProofOfIBAN')">
    </f7-block-title>
    <f7-block-title v-if="this.type === 'delivery' || this.type === 'pickupPoint'"
      style="white-space: normal; word-wrap: break-word; font-weight: 90%" v-html="$t('common.addDeliveryorpickup')">
    </f7-block-title>

    <f7-block-title v-else-if="this.type === 'product'"
      style="white-space: normal; word-wrap: break-word; font-weight: 90%"
      v-html="$t('common.addPhotos', { theType: this.type })"></f7-block-title>
    <f7-block-title v-else-if="this.type !== 'message' && this.type !== 'bankAccount'"
      style="white-space: normal; word-wrap: break-word; font-weight: 90%"
      v-html="$t('common.addOrUpdateImages', { theType: this.type })"></f7-block-title>
    <div :class="'padding-bottom-half' + f7.device.desktop && 'dropzone-container margin'" v-if="this.type !== 'message'"
      @dragover="dragover" @dragleave="dragleave" @drop="drop" :style="this.isDragging && 'border-color: primary;'">
      <input type="file" multiple name="file" :value="null" id="fileInput" style="display: none" @change="onChange"
        ref="file" accept="*" />
      <swiper-container v-if="this.docsLists.length > 0" virtual class="dropbox margin-horizontal padding-top"
        slidesPerView="auto" :spaceBetween="100" pagination="true" :navigation="isTablet ? true : false"
        :disableOnInteraction="true">
        <f7-photo-browser :photos="this.photoList()" ref="popup"></f7-photo-browser>
        <swiper-slide class="margin-vertical-half margin-horizontal" style="min-width: 180px; width: 190px; height: 200px"
          v-for="(imageItem, index) in this.docsLists" :key="index" :virtualIndex="index">
          <a link="#" @click="$refs.popup.open()" v-if="imageItem.fileType === 'image'">
            <img :src="imageItem.url" class="margin-half" style="
                width: auto;
                max-width: 180px;
                max-height: 160px;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
              " alt=" file" /></a>
          <video
            v-if="imageItem.fileType === 'video'"
            controls
            max-height="160"
            style="transform: translateY(25%)"
          >
            <source :src="imageItem.url" :type="imageItem.fileType" preload="metadata" />
            {{ $t("tamtammessage.yourBrowserDoesntsupportvideo") }}
          </video>
          <audio
            v-if="imageItem.fileType === 'audio'"
            :src="imageItem.url"
            :title="imageItem.name"
            max-height="160"
          ></audio>
          <iframe v-if="imageItem.type === 'application/pdf' ||
            imageItem.fileType === 'audio' ||
            imageItem.fileType === 'video'
            " :src="imageItem.url" :title="imageItem.name" frameborder="0" max-height="160"
            class="margin-top-half margin-horizontal" allowfullscreen style="min-width: 180px; width: 100%"></iframe>
          <f7-link v-else-if="imageItem.url !== ''" :href="imageItem.url" :text="imageItem.name" max-height="160"
            style="transform: translateY(50%)"></f7-link>

          <f7-link v-if="imageItem.url !== ''" raised :style="{
            position: 'absolute',
            left: '-4px',
            top: '-10px',
            color: 'red',
          }" icon-f7="multiply_circle_fill" @click="removeDoc(index)"></f7-link>
          <f7-link v-if="imageItem.fileType === 'image'" raised :style="{
            position: 'absolute',
            right: '-16px',
            top: '-10px',
          }" icon-f7=" pencil_circle" @click="editImage(imageItem.id)"></f7-link>
          <f7-link v-if="imageItem.type === 'application/pdf' ||
            imageItem.fileType === 'audio' ||
            imageItem.fileType === 'video'
            " raised :style="{
    position: 'absolute',
    right: '-16px',
    top: '-10px',
  }" icon-f7="arrow_up_right_square" @click="viewOtherFiles(imageItem)"></f7-link>
        </swiper-slide>
        <swiper-slide style="width: 150px; height: 150px">
          <a @click="attachFileOpenActions()" href="#" class="margin attach attachFileButton" width="auto"
            style="transform: translateY(50%)">
            <f7-link icon-size="33" class="margin-top" icon-ios="f7:photo_on_rectangle" icon-md="material:add_to_photos"
              :text="$t('attachdocs.addDocphotos')"></f7-link>
          </a>
        </swiper-slide>
      </swiper-container>

      <a v-else href="#" @click="attachFileOpenActions()" class="attachFileButton dropbox" outline novalidate>
        <f7-link icon-size="50" icon-ios="f7:photo_on_rectangle" icon-md="material:add_to_photos" class="attachFileButton"
          :text="$t('attachdocs.addDocphotos')"></f7-link>
      </a>
      <f7-block v-if="this.type === 'transfer'" class="no-margin-vertical"><span
          v-for="(docItem, index) in this.docsLists" :key="index">
          <f7-block-title href="#" @click="viewOtherFiles(docItem)" style="
              overflow: hidden;
              white-space: nowrap;
              max-width: calc(100% - 32px);
              text-overflow: ellipsis;
              display: inline-flex;
            ">{{ docItem.name }}
          </f7-block-title>
          <f7-link raised color="red" style="position: absolute; right: 16px; bottom: 40px" icon-f7="multiply_circle_fill"
            @click="removeDoc(index)"></f7-link>
        </span>
      </f7-block>
    </div>
  </div> -->

  <f7-popup
    class="recordVideoTakePicutre main"
    :backdrop="false"
    :tablet-fullscreen="true"
    swipe-to-close
    @popup:close="stopMediaRecording()"
    ><f7-view>
      <f7-page id="recordMediaPopup">
        <f7-link
          popup-close
          icon-ios="f7:xmark"
          icon-aurora="f7:xmark"
          icon-md="material:clear"
          style="
            position: absolute;
            right: 16px;
            top: 16px;
            color: white;
            margin-right: auto;
          "
        ></f7-link>
        <span style="height: calc(100% - 16px); width: 100%" class="display-flex">
          <video
            autoplay="autoplay"
            id="videoRecorder"
            ref="videoRecorder"
            :style="{
              'object-fit': 'cover',
              width: '100%',
              position: 'fixed',
              height: '100%',
            }"
          ></video>
        </span>
        <template #fixed>
          <f7-fab
            v-if="this.torchSupported == true"
            position="left-bottom"
            class="switch"
            color="auto"
            :style="{
              bottom: this.cameraSupport2Cameras.length >= 1 ? '0px' : '0px',
            }"
          >
            <f7-icon
              :ios="this.torchActive ? 'material: flash_on' : 'material:flash_off'"
              :aurora="this.torchActive ? 'material: flash_on' : 'material:flash_off'"
              :md="this.torchActive ? 'material: flash_on' : 'material:flash_off'"
              color="white"
            ></f7-icon>
            <!-- <f7-icon
            v-else
            ios="material:flash_off"
            aurora="material:flash_off"
            md="material:flash_off"
            color="white"
          ></f7-icon> -->
          </f7-fab>

          <f7-fab
            position="center-bottom"
            color="auto"
            :style="{
              bottom: this.cameraSupport2Cameras.length >= 1 ? '0px' : '0px',
            }"
            @click="capTurePhoto()"
            v-if="this.isMediaRecording == false && this.recordType === 'img'"
          >
            <f7-icon
              ios="f7:camera_fill"
              aurora="f7:camera_fill"
              md="material:photo_camera"
            ></f7-icon>
          </f7-fab>
          <f7-fab
            position="center-bottom"
            color="auto"
            :style="{
              bottom: this.cameraSupport2Cameras.length >= 1 ? '0px' : '0px',
            }"
            @click="recordVideo()"
            v-if="this.isMediaRecording == false && this.recordType === 'video'"
          >
            <f7-icon
              ios="f7:videocam_circle_fill"
              aurora="f7:videocam_circle_fill"
              md="material:videocam"
            ></f7-icon>
          </f7-fab>
          <f7-fab
            position="center-bottom"
            color="red"
            :style="{
              bottom: this.cameraSupport2Cameras.length >= 1 ? '0px' : '0px',
            }"
            @click="stopMediaRecording()"
            v-if="this.isMediaRecording == true && this.recordType === 'video'"
          >
            <f7-icon
              ios="f7:stop_fill"
              aurora="f7:stop_fill"
              md="material:stop"
            ></f7-icon>
          </f7-fab>
          <f7-fab
            position="right-bottom"
            color="primary"
            :style="{
              bottom: '0px',
            }"
            v-if="this.cameraSupport2Cameras.length >= 2"
            @click="switchCamera()"
          >
            <f7-icon
              ios="f7:camera_rotate_fill"
              aurora="f7:camera_rotate_fill"
              md="f7:camera_rotate_fill"
            ></f7-icon>
          </f7-fab>
        </template>
      </f7-page>
    </f7-view>
  </f7-popup>

  <f7-input
    type="file"
    input-id="selectPhoto"
    ref="selectPhoto"
    style="display: none"
    v-model:value="this.inputFileVal"
    accept="image/*"
    capture="environment"
    @change="onFileChange($event)"
    multiple
  ></f7-input>
  <f7-input
    type="file"
    @change="onFileChange($event)"
    input-id="selectVideo"
    ref="selectVideo"
    style="display: none"
    v-model:value="this.inputFileVal"
    accept="video/*"
    capture="environment"
    multiple
  ></f7-input>
  <f7-input
    type="file"
    @change="onFileChange($event)"
    input-id="selectAudio"
    ref="selectAudio"
    style="display: none"
    v-model:value="this.inputFileVal"
    accept="audio/*"
    capture
    multiple
  ></f7-input>
  <f7-input
    type="file"
    @change="onFileChange($event)"
    input-id="selectOtherFile"
    ref="selectOtherFile"
    v-model:value="this.inputFileVal"
    style="display: none"
    accept="*"
    multiple
  ></f7-input>

  <f7-popup
    class="viewDocs"
    swipe-to-close
    :tablet-fullscreen="true"
    :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }"
  >
    <f7-page-content>
      <f7-navbar class="no-shadow" :title="this.document.name">
        <f7-nav-right>
          <f7-link popup-close icon-ios="f7:xmark" icon-md="material:clear"></f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div v-if="this.document">
        <iframe
          v-if="
            this.document.type === 'application/pdf' ||
            document.fileType === 'video' ||
            document.fileType === 'audio'
          "
          :src="this.document.url"
          :title="this.document.name"
          style="width: 100%; height: calc(100vh - var(--f7-navbar-height))"
          frameborder="0"
        ></iframe>
        <!-- <video v-if="document.fileType === 'video'" controls style="transform: translateY(25%)">
          <source :src="document.url" :type="document.fileType" preload="metadata" />
          {{ $t("tamtammessage.yourBrowserDoesntsupportvideo") }}
        </video>
        <audio v-if="document.fileType === 'audio'" :src="document.url" :title="document.name" max-height="160"></audio> -->
        <div
          v-else
          :href="document.url"
          :text="document.name"
          style="transform: translateY(50%)"
        ></div>
      </div>
    </f7-page-content>
  </f7-popup>
</template>
<style scoped>
.dropzone-container {
  /* . . .  */
  border: 2px dashed;
  border-color: #9e9e9e;
}

.fab-label-button .fab-label {
  display: none;
}

.fab-button a {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.fab-buttons a {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
</style>
<script>
import {
  f7BlockTitle,
  f7List,
  f7PhotoBrowser,
  f7Link,
  f7BlockFooter,
  f7Input,
  f7Actions,
  f7ActionsButton,
  f7Icon,
  f7ActionsGroup,
  f7Fab,
  f7FabButtons,
  f7FabButton,
  f7Popup,
  f7Page,
  f7PageContent,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7Block,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { cordovaCamera } from "../js/mixins/cordovaCamera.js";
import { messageOptionsMixin, messageGroupMixin } from "../js/mixins/messageMixins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [attachDocs, cordovaCamera, messageOptionsMixin, messageGroupMixin],
  components: {
    // addDocs,
    f7BlockTitle,
    f7List,
    f7PhotoBrowser,
    f7Link,
    f7BlockFooter,
    f7Input,
    f7Actions,
    f7ActionsButton,
    f7Icon,
    f7ActionsGroup,
    f7Fab,
    f7FabButtons,
    f7FabButton,
    f7Popup,
    f7Page,
    f7PageContent,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7Block,
  },
  data() {
    return {
      // hasTouchSupport: true,
      // cameraSupport2Cameras: [],
      // isMediaRecording: false,

      // mediaStream: "",
      // hasTorch: "",
      // torchSupported: false,
      // torchActive: false,
      // recordType: "",
      // constraints: "",
      // front: false,
      // isRecording: false,
      // shouldStop: false,
      // stopped: false,
      videoElement: "",
      // attachments: [],
      imGattachments: [],
      imageCapture: "",
      attachment: "",
      content: "",
      msgType: "text",
      files: "",
      forms: "",
      documents: [],
      fileInput: "",
      documentFile: "",
      actionGridOpened: false,
      isRecording: false,
      shouldStop: false,
      stopped: false,
      videoElement: "",
      isMediaRecording: false,
      front: false,
      imageCapture: "",
      constraints: "",
      mediaStream: "",
      hasTorch: "",
      torchSupported: false,
      torchActive: false,
      cameraSupport2Cameras: [],
      recordType: "",
      cordovaApp: "",
      selectedDocument: "",
      // docToView: "",
      videoUrl: "",
      videoName: "",
      file: [],
      inputFileVal: null,
    };
  },
  mounted() {},

  methods: {
    // onChange() {
    //   this.files.push(...this.$refs.file.files);
    //   this.createFile(this.files);
    //   this.$refs.file.files = null;
    //   this.files = [];
    // },
    // dragover(e) {
    //   e.preventDefault();
    //   this.isDragging = true;
    // },
    // dragleave() {
    //   this.isDragging = false;
    // },
    // drop(e) {
    //   e.preventDefault();
    //   this.$refs.file.files = e.dataTransfer.files;
    //   this.onChange();
    //   this.isDragging = false;
    // },
  },

  computed: {
    ...mapState("account", ["primaryAccount", "businessAccount"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated"]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
    ]),
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
    ]),
  },
};
</script>
