import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
const path = import.meta.env.VITE_APP_URL;
export const transactAuth = {
  data() {
    return {
      theme,
      f7,
      FingerprintOptions: "",

      openloginScreen: "",
    };
  },
  mounted() {
    this.FingerprintOptions = {
      title: this.$t("common.biometricSignOnTitle"),
      description: this.$t("common.biometricSignOnDescription"),
      cancelButtonTitle: this.$t("common.cancel"),
      fallbackButtonTitle: this.$t("common.fallback"),
      clientId: this.profile.sub,
    };
    document.addEventListener("deviceready", this.onDeviceReady, false);
  },
  methods: {
    async onDeviceReady() {
      try {
        const isAvailable = await window.Fingerprint.isAvailable({
          allowBackup: true,
        });
        console.log("Fingerprint available", isAvailable);
        if (isAvailable === "OK") {
          await this.$store.dispatch("auth/isAuthAvailable", true);
        } else {
          await this.$store.dispatch("auth/isAuthAvailable", false);
        }
      } catch (error) {
        // Handle error
        console.error("Fingerprint not available", error);
        await this.$store.dispatch("auth/isAuthAvailable", false);
      }
    },

    checkAuth() {
      // if (this.isAuthAvailable) {
      //   Fingerprint.show(
      //     this.FingerprintOptions,
      //     this.successCallback,
      //     this.errorCallback
      //   );
      // } else

      // if (navigator.credentials?.create || navigator.credentials.get) {
      //   this.createPasskey();
      // } else if (
      //   PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
      // ) {
      //   this.verifyPasskey();
      // } else {
      this.openAuthloginScreen();
      // }
    },
    generateRandomChallenge() {
      const length = 32;
      const randomValues = new Uint8Array(length);
      window.crypto.getRandomValues(randomValues);
      return randomValues;
    },

    base64urlToUint8array(base64Bytes) {
      const padding = "====".substring(0, (4 - (base64Bytes.length % 4)) % 4);
      return this.Base64.toUint8Array(
        (base64Bytes + padding).replace(/\//g, "_").replace(/\+/g, "-")
      );
    },
    uint8arrayToBase64url(bytes) {
      return bytes instanceof Uint8Array
        ? this.Base64.fromUint8Array(bytes)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "")
        : uint8arrayToBase64url(new Uint8Array(bytes));
    },

    async createPasskey() {
      self = this;
      // if (PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()) {
      if (
        !navigator.credentials ||
        !navigator.credentials.create ||
        !navigator.credentials.get
      ) {
        f7.dialog.alert(
          "Your browser does not support the Web Authentication API"
        );
        this.openAuthloginScreen();
      }
      // const id = Uint8Array.from(window.atob(this.profile.sub), (c) =>
      //   c.charCodeAt(0)
      // );
      const userHandle = null;
      this.axios({
        url: `${path}webauthn/register`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        },
      })
        .then((r) => r.data)
        .then((handle) => {
          userHandle = handle.userHandle;
          console.log(handle);
        })
        .catch((err) => {
          console.log(err);
        });

      this.axios({
        url: `${path}webauthn/attestation/options`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        },
      })
        .then((r) => r.data)
        .then((credentialCreateJson) => ({
          publicKey: {
            ...credentialCreateJson,
            challenge: this.base64urlToUint8array(
              credentialCreateJson.challenge
            ),
            user: {
              name: this.$keycloak.subject,
              displayName: this.profile.name,
              id: this.base64urlToUint8array(userHandle),
            },
            excludeCredentials: credentialCreateJson.excludeCredentials.map(
              (credential) => ({
                ...credential,
                id: this.base64urlToUint8array(credential.id),
              })
            ),
            extensions: credentialCreateJson.extensions,
            authenticatorSelection: {
              requireResidentKey: true,
              userVerification: "preferred",
            },
          },
        }))
        .then((credentialCreateOptions) =>
          navigator.credentials.create(credentialCreateOptions)
        )
        .then((publicKeyCredential) => ({
          type: publicKeyCredential.type,
          id: publicKeyCredential.id,
          response: {
            attestationObject: this.uint8arrayToBase64url(
              publicKeyCredential.response.attestationObject
            ),
            clientDataJSON: this.uint8arrayToBase64url(
              publicKeyCredential.response.clientDataJSON
            ),
            transports:
              (publicKeyCredential.response.getTransports &&
                publicKeyCredential.response.getTransports()) ||
              [],
          },
          clientExtensionResults:
            publicKeyCredential.getClientExtensionResults(),
        }))
        .then((encodedResult) => {
          return this.axios({
            url: `${path}register`,
            method: "POST",
            params: {
              credential: JSON.stringify(encodedResult),
            },
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
          });
        })
        .then((response) => {
          // followRedirect(response);
          console.log("register/finish response", response);
          this.verifyPasskey();
        })
        .catch((error) => {
          console.log("error", error);
          // displayError(error);
        });

      // const credentials = await navigator.credentials.create({
      //   mediation: "conditional",
      //   publicKey: {
      //     challenge: this.generateRandomChallenge(),
      //     rp: { name: "avocash", id: window.location.hostname },
      //     user: {
      //       id: Uint8Array.from(this.profile.sub, (c) => c.charCodeAt(0)),
      //       name: `${this.profile.name} \n ${this.profile.upn}`,
      //       displayName: this.profile.name,
      //     },
      //     pubKeyCredParams: [
      //       { type: "public-key", alg: -7 },
      //       { type: "public-key", alg: -257 },
      //     ],
      //     timeout: 60000,
      //     authenticatorSelection: {
      //       residentKey: "required",
      //       requireResidentKey: false,
      //       userVerification: "preferred",
      //     },
      //     attestation: "none",
      //     extensions: { credProps: true },
      //   },
      // });
      // console.log(credentials);
      // window.currentPasskey = credentials;
      // // Save credentials to server
      // this.saveCredentialsToServer(credentials, this.profile.sub);
      // this.successCallback();
      // return credentials;
    },

    async verifyPasskey() {
      self = this;
      // Retrieve credentials from server
      this.axios({
        url: `${path}webauthn/attestation/options`,
        method: "GET",

        headers: {
          Authorization: `Bearer ${this.$keycloak.token}`,
        },
      })

        .then((r) => r.data)
        .then((credentialGetJson) => ({
          publicKey: {
            ...credentialGetJson.publicKey,
            allowCredentials:
              credentialGetJson.publicKey.allowCredentials &&
              credentialGetJson.publicKey.allowCredentials.map(
                (credential) => ({
                  ...credential,
                  id: this.base64urlToUint8array(credential.id),
                })
              ),
            challenge: this.base64urlToUint8array(
              credentialGetJson.publicKey.challenge
            ),
            extensions: credentialGetJson.publicKey.extensions,
          },
        }))
        .then((credentialGetOptions) =>
          navigator.credentials.get(credentialGetOptions)
        )
        .then((publicKeyCredential) => ({
          // type: publicKeyCredential.type,
          credentialId: publicKeyCredential.id,
          loginClientDataJSON: this.uint8arrayToBase64url(
            publicKeyCredential.response.clientDataJSON
          ),
          authenticatorData: this.uint8arrayToBase64url(
            publicKeyCredential.response.authenticatorData
          ),
          signature: this.uint8arrayToBase64url(
            publicKeyCredential.response.signature
          ),
          clientExtensionResults: JSON.stringify(
            publicKeyCredential.getClientExtensionResults()
          ),
          // response: {
          //   authenticatorData: this.uint8arrayToBase64url(
          //     publicKeyCredential.response.authenticatorData
          //   ),
          //   clientDataJSON: this.uint8arrayToBase64url(
          //     publicKeyCredential.response.clientDataJSON
          //   ),
          //   signature: this.uint8arrayToBase64url(
          //     publicKeyCredential.response.signature
          //   ),
          //   userHandle:
          //     publicKeyCredential.response.userHandle &&
          //     this.uint8arrayToBase64url(
          //       publicKeyCredential.response.userHandle
          //     ),
          // },
          // clientExtensionResults:
          //   publicKeyCredential.getClientExtensionResults(),
        }))
        .then((encodedResult) => {
          this.axios({
            url: `${path}webauthn/login`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$keycloak.token}`,
            },
            params: {
              credentialId: encodedResult.credentialId,
              authenticatorData: encodedResult.authenticatorData,
              signature: encodedResult.signature,
              clientExtensionResults: encodedResult.clientExtensionResults,
            },
          })
            .then((r) => r.data)
            .then((logingresponse) => {
              console.log("login/finish logingresponse", logingresponse);
              if (logingresponse.status == "success") {
                self.successCallback();
              } else {
                self.errorCallback();
              }
            })
            .catch((err) => {
              // console.log(err.response.data);
              console.log(err);
              self.errorCallback();
            });
        })
        .catch((error) => {
          console.log(error);
          self.errorCallback();
        });

      // const savedCredentials =
      //   /*this.getCredentialsFromServer(this.profile.sub)*/ window.currentPasskey;

      // if (!savedCredentials) {
      //   self.createPasskey();
      //   throw new Error("No credentials found for user");
      // }

      // const credentials = await navigator.credentials.get({
      //   publicKey: {
      //     challenge: this.generateRandomChallenge(),
      //     allowCredentials: [
      //       {
      //         type: "public-key",
      //         id: /*savedCredentials.rawId */ window.currentPasskey.rawId,
      //       },
      //     ],
      //   },
      // });

      // console.log(credentials);
      // f7.dialog.alert("Biometric authentication successful!");
      // this.successCallback();
    },

    openAuthloginScreen() {
      f7.popup.open(".authLoginScreen");
    },

    errorCallback(error) {
      console.log(`Authentication invalid ${error.message}`);
      if (this.isAuthAvailable && this.leftTrials < 4) {
        f7.dialog.alert(this.$t("common.autheticate with secretPIN"), () => {
          this.openAuthloginScreen();
        });
      }
    },

    async successCallback() {
      console.log("successCallback", this.userPaymentProvider);
      if (this.userPaymentProvider === "stripe") {
        await this.$refs.stripeComponent.stripeValidate();
      } else if (this.userPaymentProvider === "bizao") {
        if (this.baseTransfer.payment_method === "MOBILE_MONEY") {
          await this.$store.dispatch(
            "apimoney/authorizeCashInMobileMoney",
            this.baseTransfer
          );
        } else if (this.baseTransfer.payment_method === "CREDIT_CARD") {
          await this.$store.dispatch(
            "apimoney/initiateCreditCardCashIn",
            this.baseTransfer
          );
        } else if (this.selected_method === "avocash") {
          await this.$store.dispatch(
            "apimoney/createTransferAuthorization",
            this.baseTransfer
          );
        }
      }
    },

    validatedDialog() {
      if (!this.toastIcon) {
        this.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-icons text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-icons text-color-green border-color-gray color-green" style="font-size:150px">check_circle_outline</i>',
          text: "",
          // this.scannedCard.status || this.QRtext.status,
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 3000,
        });
      }
      this.toastIcon.open();
    },
  },
  computed: {
    ...mapState("auth", ["isTablet", "profile", "isAuthAvailable", "device"]),
  },
};
