<template>
  <f7-page hide-toolbar-on-scroll ptr :ptr-mousewheel="true" ptr-bottom @ptr:refresh="loadMore" v-if="this.recipient"
    @page:beforeremove="onPageBeforeRemove">
    <f7-navbar :sliding="false" no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false"
      navbar-transparent v-if="this.isTablet == true">
      <f7-nav-title sliding>
        {{ this.recipient.name }}
      </f7-nav-title>
      <f7-nav-right>
        <f7-link v-if="this.recipientTransactions" class="searchbar-enable" data-searchbar=".searchbar-transaction"
          icon-aurora="f7:search_strong" icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
        <f7-link v-if="this.recipient.userRecipientId === null" icon-aurora="f7:pencil" icon-ios="f7:pencil"
          icon-md="material:edit" :href="'/recipient/edit/' + this.recipient.id"></f7-link>
      </f7-nav-right>
      <f7-searchbar class="searchbar-transaction" expandable search-container=".transaction-list" search-in=".item-title"
        :placeholder="$t('recipientdetail.searchATransaction')" :disable-button="!theme.aurora"
        :value="this.searchbarContent" @input="searchbarContent = $event.target.value"></f7-searchbar>
    </f7-navbar>
    <f7-navbar :sliding="false" no-shadow large :back-link="$t('common.backButton')" :back-link-show-text="false"
      navbar-transparent v-else>
      <f7-nav-title sliding>
        {{ this.recipient.name }}
      </f7-nav-title>
      <f7-nav-title-large sliding>
        {{ this.recipient.name }}
      </f7-nav-title-large>
      <f7-nav-right>
        <f7-link v-if="this.recipientTransactions" class="searchbar-enable" data-searchbar=".searchbar-transaction"
          icon-aurora="f7:search_strong" icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
        <f7-link v-if="this.recipient.userRecipientId === null" icon-aurora="f7:pencil" icon-ios="f7:pencil"
          icon-md="material:edit" :href="'/recipient/edit/' + this.recipient.id"></f7-link>
      </f7-nav-right>
      <f7-searchbar class="searchbar-transaction" expandable search-container=".transaction-list" search-in=".item-title"
        :placeholder="$t('recipientdetail.searchATransaction')" :disable-button="!theme.aurora"
        :value="this.searchbarContent" @input="searchbarContent = $event.target.value"></f7-searchbar>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card v-if="!this.recipient" class="skeleton-text skeleton-effect-blink card-exp">
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar">
              <img v-if="this.recipient" src="" width="24" />
            </div>
            <div class="demo-facebook-name">First name</div>
            <div class="demo-facebook-date">Countryname</div>
          </f7-card-header>
          <f7-card-content :padding="false">
            <f7-list dividers-ios strong-ios list media-list form>
              <f7-list-group>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title">Tel:+33644880701</div>
                    <div class="item-title">Email: firstanme.name@gmail.com</div>
                  </div>
                </li>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-subtitle" style="white-space: normal; word-wrap: break-word">
                      25 rue de la ferrière 25021 Thialone
                    </div>
                  </div>
                </li>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ $t("recipientdetail.totalSent") }}
                      </div>
                      <div class="item-title">
                        <b> 45 EUR </b>
                      </div>
                    </div>
                    <div class="item-title-row" v-if="destinationCity">
                      <div class="item-title">
                        {{ $t("recipientdetail.totalReceived") }}
                      </div>
                      <div class="item-title">
                        <b> 25 000 XOF </b>
                      </div>
                    </div>
                  </div>
                </li>
              </f7-list-group>
            </f7-list>
          </f7-card-content>
        </f7-card>
        <f7-card :padding="false" v-if="searchbarContent === '' || this.recipient"
          class="card-exp ptr-watch-scrollable ptr-ignore">
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar">
              <img v-if="this.recipient" :src="'https://flagcdn.com/' +
                this.recipient.countryCode.toLowerCase() +
                '.svg'
                " width="24" />
            </div>
            <div class="demo-facebook-name">
              {{ this.recipient.name }}
            </div>
            <div class="demo-facebook-date">
              {{ this.recipient.countryName }}
            </div>
          </f7-card-header>
          <f7-card-content :padding="false">
            <f7-list dividers-ios strong-ios media-list>
              <f7-list-group>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title">
                      {{
                        $t("recipientdetail.tel", {
                          phoneCode: "",
                          phone: this.recipient.phone,
                        })
                      }}
                    </div>
                    <div class="item-title">
                      {{ $t("recipientdetail.email", { email: this.recipient.email }) }}
                    </div>
                    <!-- </div> -->
                  </div>
                </li>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-subtitle" style="white-space: normal; word-wrap: break-word">
                      {{ this.recipient.address }}
                    </div>
                  </div>
                </li>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ $t("recipientdetail.totalSent") }}
                      </div>
                      <div class="item-title">
                        <b>
                          {{
                            Intl.NumberFormat(
                              profile.countryCode +
                              "-" +
                              profile.countryCode.toLowerCase(),
                              {
                                style: "currency",
                                currency: primaryAccount.currencyCode,
                              }
                            ).format(this.totalSent)
                          }}
                        </b>
                      </div>
                    </div>
                    <div class="item-title-row" v-if="destinationCity">
                      <div class="item-title">
                        {{ $t("recipientdetail.totalReceived") }}
                      </div>
                      <div class="item-title">
                        <b>
                          {{
                            Intl.NumberFormat(
                              recipient.countryCode +
                              "-" +
                              recipient.countryCode.toLowerCase(),
                              {
                                style: "currency",
                                currency: destinationCity.currencyCode,
                              }
                            ).format(this.totalReceived)
                          }}
                        </b>
                      </div>
                    </div>
                  </div>
                </li>
              </f7-list-group>
            </f7-list>
          </f7-card-content>

          <!-- Check if existing transfer info and if pick-up or top-up then choose account of topup (Primary or Business account if user has them) -->
        </f7-card>
        <f7-list dividers-ios strong-ios v-if="!this.recipientTransactionList" media-list>
          <f7-list-item v-for="n in 10" :key="n" class="skeleton-text skeleton-effect-blink"
            title="Received: CFA125 000 XOF" subtitle="avocash top-up 21 Mar 2021" after="Sent: 230 EUR ">
            <f7-skeleton-block style="width: 40px; height: 40px; border-radius: 50%" slot="media"></f7-skeleton-block>
          </f7-list-item>
        </f7-list>
        <f7-list dividers-ios strong-ios transaction-list class="searchbar-not-found" media-list v-else-if="this.recipientTransactionList === '' &&
          this.recipientTransactionList.length >= 1
          ">
          <f7-list-item :title="$t('recipientdetail.noSuchTransaction')"
            style="font-size: 18px; color: red"></f7-list-item>
        </f7-list>

        <!-- <f7-list-index list-el=".list.transaction-list" :scroll-list="true"></f7-list-index> -->

        <f7-list dividers-ios strong-ios transaction-list class="transaction-list searchbar-found no-margin" media-list
          v-if="!!this.recipientTransactionList && this.recipientTransactionList.length >= 1
            " v-for="(transactions, index) in recipientTransactions" :key="index">
          <!-- <f7-list-group
      > -->
          <f7-list-item text-color="primary" group-title
            style="font-size: 18px; white-space: normal; word-wrap: break-word">
            {{ transactions.transactionYearMonth }} |
            <span v-if="profile && primaryAccount" style="font-size: 75%; font-style: italic">{{
              $t("recipientdetail.sent") }}
              <span v-html="$filtersToCurrency.toCurrency(
                transactions.subTotalSent,
                primaryAccount.currencySymbol,
                primaryAccount.currencyCode
              )
                "></span></span>

            <span v-if="destinationCity && recipient" style="font-size: 75%; font-style: italic">| {{
              $t("recipientdetail.received") }}
              <span v-html="$filtersToCurrency.toCurrency(
                transactions.subTotalReceived,
                transaction.toCurrencySymbol,
                transaction.toCurrencyCode
              )
                "></span></span>
          </f7-list-item>
          <f7-card-content :padding="false" v-for="(transaction, index) in transactions.items" :key="index">
            <!-- <f7-link
                @click="transactionActions(transaction)"
                :style="{position: 'absolute', right: '0px', top: '15px'}"
                icon-aurora="f7:ellipsis_vertical"
                icon-ios="f7:ellipsis_vertical"
                icon-md="material:more_vert"
                color="red"
                class="button-to-popover"
              ></f7-link>-->
            <li :padding="false" class="accordion-item" v-if="transaction.isValidated === false"
              style="border: 1px orange solid">
              <a href="#" class="item-content item-link">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-subtitle">
                      <span class="item-header">{{ $t("recipientdetail.sent") }}</span>
                      <span v-html="$filtersToCurrency.toCurrency(
                        transaction.grossTransferAmount,
                        transaction.fromCurrencySymbol,
                        transaction.fromCurrencyCode
                      )
                        "></span>
                    </div>
                    <div class="item-title">
                      <span class="item-header">{{
                        $t("recipientdetail.received")
                      }}</span>
                      <span v-html="$filtersToCurrency.toCurrency(
                            transaction.receivedAmount,
                            transaction.toCurrencySymbol,
                            transaction.toCurrencyCode
                          )
                          "></span>
                    </div>
                  </div>
                  <div class="item-title-row no-chevron">
                    <div class="item-footer">
                      {{ transaction.transactionType }}
                      {{ $filtersToDate.toDateTime(transaction.transactionDate) }}
                    </div>
                    <!-- <div class="item-footer"></div> -->
                    <div class="item-after">
                      <f7-badge v-if="transaction.status === 'Paid' ||
                        transaction.status === 'Complete'
                        " class="color-green" strong>{{ transactionStatus(transaction) }}</f7-badge>
                      <f7-badge v-else-if="transaction.status === 'Pending'" strong class="color-orange">{{
                        transactionStatus(transaction) }}</f7-badge>
                      <f7-badge v-else-if="transaction.status === 'Cancelled'" strong class="color-red">
                        {{ transactionStatus(transaction) }}
                      </f7-badge>
                    </div>
                  </div>
                </div>
              </a>
              <f7-accordion-content>
                <f7-list dividers-ios strong-ios media-list>
                  <f7-button :href="toUrlTransactionDetail(transaction)" color="auto" outline large small raised
                    class="margin-horizontal margin-vertical">{{ $t("recipientdetail.seeDetail") }}</f7-button>

                  <f7-button v-if="transaction.transactionType === 'avo¢ash pick-up'"
                    @click="sendAgain(transaction, recipient)" color="auto" raised fill small ripple
                    class="margin-horizontal margin-vertical">{{ $t("recipientdetail.sendAgain") }}</f7-button>
                  <f7-button v-if="transaction.transactionType === 'avo¢ash top-up'"
                    @click="topupAgain(transaction, recipient)" color="red" raised fill small ripple
                    class="margin-horizontal margin-vertical">{{ $t("recipientdetail.topupAgain") }}</f7-button>
                  <f7-row class="margin-horizontal-left no-margin-bottom">
                    <f7-col width="65" medium="80">
                      <f7-list-input type="text" :label="$t('common.transferCode')" disabled readonly
                        @input="transferCode = $event.target.value" :value="transaction.transferCode" />
                    </f7-col>
                    <f7-col width="10" medium="5" class="no-margin-bottom"></f7-col>
                    <f7-col width="25" medium="15">
                      <f7-list-input type="text" :label="$t('common.pin')" inputmode="numeric" input-id="transferPIN"
                        :value="transaction.transferPIN" @input="transferPIN = $event.target.value"
                        @keyup.enter="validateRedeem(transaction)" pattern="[0-9]*" maxlength="4" size="4" required>
                      </f7-list-input>
                    </f7-col>
                  </f7-row>
                  <f7-button v-if="transaction.transactionType === 'avo¢ash top-up'" raised fill ripple
                    class="margin-horizontal margin-bottom" color="red" strong
                    @click="validateDebitTransaction(transaction)">{{ $t("recipientdetail.validateTransfer")
                    }}</f7-button>
                </f7-list>
              </f7-accordion-content>
            </li>
            <!--  TODO Revisit the validateRedeem() function and
          validateDebitTransaction() and validateCreditTransaction -->
            <li :padding="false" class="accordion-item" v-if="transaction.isValidated === true ||
              transaction.transactionType === 'avo¢ash pick-up'
              ">
              <a href="#" class="item-content item-link">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title">
                      <span class="item-header">{{
                        $t("recipientdetail.received")
                      }}</span>
                      <span v-html="$filtersToCurrency.toCurrency(
                            transaction.receivedAmount,
                            transaction.toCurrencySymbol,
                            transaction.toCurrencyCode
                          )
                          "></span>
                    </div>
                    <div class="item-subtitle">
                      <span class="item-header">{{ $t("recipientdetail.sent") }}</span>
                      <span v-html="$filtersToCurrency.toCurrency(
                        transaction.grossTransferAmount,
                        transaction.fromCurrencySymbol,
                        transaction.fromCurrencyCode
                      )
                        "></span>
                    </div>
                  </div>
                  <div class="item-title-row no-chevron" v-if="transaction.transactionType === 'avo¢ash pick-up'">
                    <div class="item-subtitle">
                      <span class="item-header">{{
                        $t("recipientdetail.available")
                      }}</span>
                      <span v-html="$filtersToCurrency.toCurrency(
                            transaction.availableAmount,
                            transaction.toCurrencySymbol,
                            transaction.toCurrencyCode
                          )
                          "></span>
                    </div>
                  </div>
                  <div class="item-title-row no-chevron">
                    <div class="item-footer">
                      {{ transaction.transactionType }}
                      {{ $filtersToDate.toDateTime(transaction.transactionDate) }}
                    </div>
                    <div class="item-after">
                      <f7-badge v-if="transaction.status === 'Paid' ||
                        transaction.status === 'Complete'
                        " class="color-green" strong>{{ transactionStatus(transaction) }}</f7-badge>
                      <f7-badge v-else-if="transaction.status === 'Pending'" strong class="color-orange">{{
                        transactionStatus(transaction) }}</f7-badge>
                      <f7-badge v-else-if="transaction.status === 'Cancelled'" strong class="color-red">
                        {{ transactionStatus(transaction) }}
                      </f7-badge>
                    </div>
                  </div>
                </div>
              </a>
              <f7-accordion-content>
                <f7-list dividers-ios strong-ios media-list>
                  <f7-button :href="toUrlTransactionDetail(transaction)" color="auto" outline large text-color="auto"
                    small raised class="margin-horizontal">{{ $t("recipientdetail.seeDetail") }}</f7-button>

                  <f7-button v-if="transaction.transactionType === 'avo¢ash pick-up'"
                    @click="sendAgain(transaction, recipient)" color="auto" raised fill small ripple
                    class="margin-horizontal margin-vertical">{{ $t("recipientdetail.sendAgain") }}</f7-button>
                  <f7-button v-if="transaction.transactionType === 'avo¢ash top-up'"
                    @click="topupAgain(transaction, recipient)" color="red" raised fill small ripple
                    class="margin-horizontal margin-vertical">{{ $t("recipientdetail.topupAgain") }}</f7-button>
                  <f7-row class="margin-horizontal-left no-margin-bottom">
                    <f7-col width="65" medium="80">
                      <f7-list-item :header="$t('recipientdetail.transferCode')" :subtitle="transaction.transferCode" />
                    </f7-col>
                    <f7-col width="10" medium="5" class="no-margin-bottom"></f7-col>
                    <f7-col width="25" medium="15">
                      <f7-list-item header="PIN" :subtitle="transaction.transferPIN"></f7-list-item>
                    </f7-col>
                  </f7-row>
                </f7-list>
              </f7-accordion-content>
            </li>
          </f7-card-content>
          <!-- </f7-list-group> -->
        </f7-list>

        <f7-toolbar tabbar bottom style="position: absolute"
          v-if="this.extraTransferDetail && this.extraTransferDetail.length > 0">
          <f7-link v-if="this.extraTransferDetail.pickupMethod == 'pick-up'" raised large fill ripple class="link" strong
            bg-color="red" text-color="white" @click="transferNowWithAmount()">{{ $t("recipientdetail.transferNow") }}
          </f7-link>

          <f7-link v-if="this.recipient.userRecipientId !== null &&
            this.extraTransferDetail.pickupMethod == 'top-up'
            " raised large fill ripple class="buttonLink link" strong text-color="white"
            @click="topUpNowWithAmountPrimary(this.recipient)">{{ $t("recipientdetail.topupNowPrimary") }}</f7-link>

          <f7-link v-if="this.recipient.userRecipientId !== null &&
            this.extraTransferDetail.pickupMethod == 'top-up'
            " raised large fill ripple class="buttonLink link" strong text-color="white"
            @click="topUpNowWithAmountBusiness(this.recipient)">{{ $t("recipientdetail.topupNowBusiness") }}</f7-link>
        </f7-toolbar>
        <!-- Check if no transfer info and choose pick-up or top-up then choose account of topup (Primary or Business account if user has them) -->
        <f7-toolbar tabbar bottom v-else style="position: absolute">
          <f7-link v-if="this.recipient.userRecipientId !== null" raised large fill ripple class="link" strong
            bg-color="red" text-color="white" @click="transferNowWithoutAmount()">{{ $t("recipientdetail.transferNow") }}
          </f7-link>
          <f7-link v-if="this.recipient.userRecipientId !== null" raised large ripple strong class="buttonLink link"
            text-color="white" @click="topUpNowWithoutAmount()">{{ $t("recipientdetail.topupNow") }}</f7-link>
          <f7-link v-if="this.recipient.userRecipientId == null" raised large fill ripple class="buttonLink link" strong
            text-color="white" @click="transferNowWithoutAmount()">{{ $t("recipientdetail.transferNow") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>

    <div class="ptr-preloader">
      <div class="preloader"></div>
      <div class="ptr-arrow"></div>
    </div>
  </f7-page>
</template>
<style scoped>
.card-exp {
  overflow: hidden;
  height: auto;
  background: var(--f7-card-expandable-bg-color);
  position: relative;
  /*   transform-origin: center center;
  transition-property: transform, border-radius;
  border-radius: var(--f7-card-expandable-border-radius);
  z-index: 2;
  transition-duration: 200ms;
  margin-left: calc(
    var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-left)
  );
  margin-right: calc(
    var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-right)
  );
  margin-top: var(--f7-card-expandable-margin-vertical);
  margin-bottom: var(--f7-card-expandable-margin-vertical); */
  box-shadow: var(--f7-card-expandable-box-shadow);
  font-size: var(--f7-card-expandable-font-size);
}

.buttonLink {
  background-color: #0009ff;
}

.toolbar-inner {
  padding: 0px !important;
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7NavTitleLarge,
  f7Link,
  f7Searchbar,
  f7Card,
  f7CardHeader,
  f7CardContent,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7AccordionContent,
  f7Button,
  f7Badge,
  f7Toolbar,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { selectedRecipient } from "../js/mixins/recipient.js";
import { transferAgain } from "../js/mixins/transaction.js";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [selectedRecipient, transferAgain, keyPadMixin],
  components: {
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7NavTitleLarge,
    f7Link,
    f7Searchbar,
    f7Card,
    f7CardHeader,
    f7CardContent,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7AccordionContent,
    f7Button,

    f7Button,
    f7Badge,
    f7Toolbar,
  },
  data() {
    return {
      theme,
      f7,
      searchbarContent: "",
      visibleItem: 25,
    };
  },
  mounted: function () {
    const self = this;

    self.$store.dispatch("transaction/getExtraTransferDetail", {});
    this.transferDetail = {};
    if (this.$keycloak.authenticated && this.allrecipients === "") {
      self.$store.dispatch("remit/loadRecipients");
    }
    // if (!this.recipientTransactionList) {
    // }
    self.$store.dispatch("remit/getRecipientById", this.f7route.params.id);
    self.$store.dispatch("remit/loadRecipientTransactionList", {
      recipientId: this.f7route.params.id,
      visibleItem: this.visibleItem,
    });
    if (this.recipient) {
      self.$store.dispatch("location/loadDestinationCity", this.recipient.countryCode);
    }


    // if (this.isDeskTop == true) {
    //   f7.$("div.page-content").attr("style", "--f7-page-navbar-offset:0px");
    // }
  },
  methods: {
    openPopUp(transaction) {
      const self = this;

      f7.popup.open(".transactionDetailCard");
      // f7.tab.show(`#tab-${transaction.id}`);
    },

    toUrlEdit(recipient) {
      const self = this;

      f7.actions.close();
      return `/recipient/edit/${recipient.id}`;
    },

    toUrlTransactionDetail(transaction) {
      const self = this;

      f7.actions.close();
      return `/recipient/transaction/detail/${transaction.id}`;
    },

    loadMore(done) {
      const self = this;
      this.visibleItem += 25;
      setTimeout(() => {
        self.$store.dispatch("remit/loadRecipientTransactionList", {
          recipientId: this.recipient.id,
          visibleItem: this.visibleItem,
        });
        done();
      }, 1000);
    },

    transferNowWithAmount() {
      const self = this;

      if (this.extraTransferDetail === null) {
        return;
      }

      this.extraTransferDetail.destinationctry = this.recipient.countryName;
      this.extraTransferDetail.recipientId = this.recipient.id;
      this.extraTransferDetail.name = this.recipient.name;
      this.extraTransferDetail.countryName = this.recipient.countryName;
      this.extraTransferDetail.email = this.recipient.email;
      this.extraTransferDetail.phone = this.recipient.phone;
      this.f7router.navigate(`/transfer/validate${this.recipient.id}`, {
        ignoreCache: true,
        reloadCurrent: true,
      });
    },
    topUpNowWithAmountPrimary(recipient) {
      const self = this;

      self.$store
        .dispatch("account/loadUser", { userId: recipient.userRecipientId, type: "userId" })
        .then((response) => {
          self.$store
            .dispatch("transaction/getExtraTransferDetail", {
              pickupMethod: this.extraTransferDetail.pickupMethod,
              amount: this.extraTransferDetail.amount,
              toCountryId: this.extraTransferDetail.toCountryId,
              destinationctry: this.extraTransferDetail.destinationctry,
              destinationcty: this.extraTransferDetail.destinationcty,
              pickupplace: this.extraTransferDetail.pickupplace,
              name: recipient.name,
              accountNumber: userDetail.primaryAccount.accountNumber,
              recipientId: recipient.id,
              countryName: recipient.countryName,
              email: recipient.email,
              phone: recipient.phone,
              recipientAccountNumber: userDetail.primaryAccount.accountNumber,
            })
            .then((resp) => {
              const self = this;

              this.f7router.navigate(`/transfer/validate${recipient.id}`);
            });
        });
    },
    topUpNowWithAmountBusiness(recipient) {
      const self = this;

      self.$store
        .dispatch("account/loadUser", { userId: recipient.userRecipientId, type: "userId" })
        .then((response) => {
          self.$store
            .dispatch("transaction/getExtraTransferDetail", {
              pickupMethod: this.extraTransferDetail.pickupMethod,
              amount: this.extraTransferDetail.amount,
              toCountryId: this.extraTransferDetail.toCountryId,
              destinationctry: this.extraTransferDetail.destinationctry,
              destinationcty: this.extraTransferDetail.destinationcty,
              pickupplace: this.extraTransferDetail.pickupplace,
              name: recipient.name,
              accountNumber: this.userDetail.businessAccount.accountNumber,
              recipientId: recipient.id,
              countryName: recipient.countryName,
              email: recipient.email,
              phone: recipient.phone,
              recipientAccountNumber: this.userDetail.businessAccount.accountNumber,
            })
            .then((resp) => {
              const self = this;

              this.f7router.navigate(`/transfer/validate${recipient.id}`);
            });
        });
    },

    transferNowWithoutAmount() {
      const self = this;

      this.f7router.navigate(`/pickup/with-recipient/${this.recipient.id}/pick-up`, {
        ignoreCache: true,
        reloadCurrent: true,
      });
    },
    topUpNowWithoutAmount() {
      const self = this;

      this.f7router.navigate(`/topup/with-recipient/${this.recipient.id}/top-up`, {
        ignoreCache: true,
        reloadCurrent: true,
      });
    },

    async validateTopUp(transaction) {
      const self = this;

      f7.preloader.show("multi");
      if (transaction !== null) {
        console.log(transaction);
        await self.$store.dispatch("remit/validateTopUp", {
          transferCode: transaction.transferCode,
          transferPIN: transaction.transferPIN,
        });
      }
      f7.preloader.hide();
      this.validatedDialog();
      await this.f7router.refreshPage();
    },

    validatedDialog() {
      const self = this;

      if (!self.toastIcon) {
        self.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-  text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-  text-color-green " style="font-size:150px">check_circle_outline</i>',
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 1500,
        });
      }
      self.toastIcon.open();
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy popup when page removed
      if (self.popup) self.popup.destroy();
    },
  },
  computed: {
    recipientTransactions() {
      const currentTransactions = this.recipientTransactionList;
      if (currentTransactions && currentTransactions.length > 0) {
        return _.chain(currentTransactions.slice())
          .map((transaction) =>
            _.extend(
              {
                transactionYearMonth:
                  transaction.transactionDate && transaction.transactionDate.length > 0
                    ? transaction.transactionDate.substr(0, 7)
                    : null,
              },
              transaction
            )
          )
          .orderBy(["transactionDate"], ["desc"])
          .groupBy("transactionYearMonth")
          .map((objs, key, items) => ({
            transactionYearMonth: key,
            subTotalSent: _.sumBy(objs, "grossTransferAmount"),
            subTotalReceived: _.sumBy(objs, "receivedAmount"),
            items: objs,
          }))
          .value();
      }
    },
    totalSent() {
      if (this.recipientTransactions) {
        return _.reduce(
          this.recipientTransactions,
          (amount, monthlyTransaction) =>
            amount + parseFloat(monthlyTransaction.subTotalSent || 0),

          0
        );
      }
    },

    totalReceived() {
      if (this.recipientTransactions) {
        return _.reduce(
          this.recipientTransactions,
          (amount, monthlyTransaction) =>
            amount + parseFloat(monthlyTransaction.subTotalReceived || 0),
          0
        );
      }
    },
    recipient() {
      const self = this;

      const id = this.f7route.params.id || this.recipientId;
      for (const recipient of this.allrecipients) {
        if (recipient.id == id) {
          return recipient;
        }
      }
    },

    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userrecipientList",
      "recipient",
      "recipientTransactionList",
    ]),

    ...mapState("account", ["userDetail", "primaryAccount"]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
    ]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),
    ...mapState("transaction", ["extraTransferDetail"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
  },
};
</script>
