// import base_url from "../base_url.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "shop";
// const shop = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      shop: "",
      shopList: "",
      userShop: "",
      images: "",
      queryerror: "",
      categories: "",
      categorylist: "",
      img: [],
    },
    getters: {
      // totalCardbasket() {
      //     return _.reduce(carditemlist, function(amount, carditem) {
      //         return amount + parseFloat(carditem.cardAmount || 0);
      //     }, 0);
      // },
    },
    mutations: {
      CREATE_SHOP(state, shop) {
        state.shop = shop;
      },
      EDIT_SHOP(state, shop) {
        state.shop = shop;
      },
      DEACTIVATE_SHOP(state, shop) {
        state.shop = shop;
      },
      ADD_IMAGES(state, payload) {
        state.images = payload;
      },
      GET_SHOPBYID(state, shop) {
        state.shop = shop;
      },
      GET_USER_SHOPLIST(state, shopList) {
        state.shopList = shopList;
      },
      GET_SHOPBYNUMBER(state, shop) {
        state.shop = shop;
      },
      GET_SHOPBYSHOPOWNERID(state, userShop) {
        state.userShop = userShop;
      },
      CREATE_CATEGORY(state, categorylist) {
        state.categorylist = categorylist;
      },
      GET_CATEGORIES(state, categories) {
        state.categories = categories;
      },
    },
    actions: {
      createCategory({ commit }, payload) {
        axios({
          url: `${path}/create/category`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((categorylist) => {
            commit("CREATE_CATEGORY", categorylist);
            console.log(categorylist);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createSubCategory({ commit }, payload) {
        axios({
          url: `${path}/create/subCategory`,
          method: "POST",
          params: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((categorylist) => {
            commit("CREATE_CATEGORY", categorylist);
            console.log(payload);
            console.log(categorylist);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getCategory({ commit }) {
        axios({
          url: `${path}/info/categories`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((categories) => {
            commit("GET_CATEGORIES", categories);
            console.log(categories);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      createShop({ commit, dispatch }, payload) {
        console.log(payload);
        axios({
          url: `${path}/create`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("CREATE_SHOP", shop);
            setTimeout(() => {
              dispatch("getShopById", shop.id);
            }, 1000);
            console.log(payload);
            console.log(shop);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editShop({ commit }, payload) {
        axios({
          url: `${path}/edit`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("EDIT_SHOP", shop);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      addImages({ commit }, payload) {
        axios({
          url: `${path}/uploadShopImages/${payload.id}`,
          method: "POST",
          data: payload.formData,
          dataType: "application/json",
          contentType: "multipart/form-data",
          processData: true,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((data) => {
            console.log(payload);
            commit("GET_SHOPBYID", payload.id);
            console.log(payload);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      deleteImages({ dispatch }, payload) {
        axios({
          url: `${path}/deleteShopImages`,
          method: "PUT",
          params: {
            imageId: payload.imageId,
            entityId: payload.entityId,
            type: payload.type,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((data) => {
            if (payload.type === "shop") {
              dispatch("getShopById", payload.entityId);
            } else if (payload.type === "productParent") {
              dispatch(
                "product/getProductParent",
                { productParentId: payload.entityId },
                { root: true }
              );
            } else if (payload.type === "product") {
              dispatch(
                "product/getProductById",
                { productId: payload.entityId },
                { root: true }
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      uploadShopVideo({ dispatch }, payload) {
        axios({
          url: `${path}/uploadShopVideo`,
          method: "POST",
          data: payload.url,
          params: {
            id: payload.id,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((data) => {
            dispatch("getShopById", payload.id);
            console.log(payload);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      deleteVideo({ dispatch }, payload) {
        axios({
          url: `${path}/deleteShopVideo`,
          method: "PUT",
          params: {
            videoId: payload.videoId,
            entityId: payload.entityId,
            type: payload.type,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((data) => {
            if (payload.type === "shop") {
              dispatch("getShopById", payload.entityId);
            } else if (payload.type === "productParent") {
              dispatch(
                "product/getProductParent",
                { productParentId: payload.entityId },
                { root: true }
              );
            } else if (payload.type === "product") {
              dispatch(
                "product/getProductById",
                { productId: payload.entityId },
                { root: true }
              );
            }
            console.log(payload);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserShopList({ commit }, payload) {
        axios({
          url: `${path}/getUserShopList`,
          method: "GET",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            maxNumberOfItem: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shopList) => {
            commit("GET_USER_SHOPLIST", shopList);
            console.log(shopList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getActiveShopById({ commit }, payload) {
        axios({
          url: `${path}/getShopById`,
          method: "GET",
          params: {
            shopId: payload,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("GET_SHOPBYID", shop);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getShopById({ commit }, payload) {
        axios({
          url: `${path}/getShopById`,
          method: "GET",
          params: {
            shopId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("GET_SHOPBYID", shop);
            console.log(payload);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getShopByShopNumber({ commit }, payload) {
        axios({
          url: `${path}/info/getShopByShopNumber`,
          method: "GET",
          params: {
            shopNumber: payload,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("GET_SHOPBYNUMBER", shop);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getShopByShopOwnerId({ commit }) {
        axios({
          url: `${path}/info/getShopByShopOwnerId`,
          method: "GET",
          params: {
            shopByShopOwnerId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userShop) => {
            commit("GET_SHOPBYSHOPOWNERID", userShop);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      deactivateShop({ commit }, payload) {
        axios({
          url: `${path}/deactivateShop`,
          method: "PUT",
          params: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        }).then(() => {
          console.log(payload);
          commit("DEACTIVATE_SHOP", payload);
        });
      },
      grantAdminRole({ commit }, payload) {
        axios({
          url: `${path}/grantAdminRole`,
          method: "POST",
          params: {
            userId: payload.userId,
            shopId: payload.shopId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("GET_SHOPBYID", shop);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      withdrawAdminRole({ commit }, payload) {
        axios({
          url: `${path}/withdrawAdminRole`,
          method: "PUT",
          params: {
            userId: payload.userId,
            shopId: payload.shopId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("GET_SHOPBYID", shop);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getCategories({ commit }) {
        axios({
          url: `${path}/categories`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((categories) => {
            commit("GET_CATEGORIES", categories);
            console.log(categories);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      decodeShopQRCode({ commit }, payload) {
        axios({
          url: `${path}/info/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
        })
          .then((r) => r.data)
          .then((shop) => {
            commit("GET_SHOPBYID", shop);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      // decodeShopOrdersQRCode({
      //     commit,
      //     dispatch
      // }, payload) {
      //     axios({
      //             url: path + "shop/decodeQRCode",
      //             method: "GET",
      //             params: {
      //                 qrCodeText: payload,
      //             },
      //             headers: {
      //                 Authorization: `Bearer ${ app.config.globalProperties.$keycloak.token}`,
      //             },
      //         })
      //         .then((r) => r.data)
      //         .then((decodedQRCode) => {
      //             console.log(decodedQRCode);
      //             commit("DECODE_QRCODE", decodedQRCode);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //             console.log(err.response.data);
      //         });
      // },
    },
  };
};
// export default shop;
