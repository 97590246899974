import _ from "lodash";
const path =
    import.meta.env.VITE_APP_URL;
const cart = window.localStorage.getItem("cart");
// const shoppingCart={
export default (app) => {
    return {
        namespaced: true,
        state: {
            cart: cart ? JSON.parse(cart) : [],
            cartItemCount: 0,
        },
        getters: {
            totalCartAmount: (state) => {
                return _.reduce(
                    state.cart,
                    (amount, item) =>
                    amount + parseInt(item.item.quantity) * item.item["Variant Price"],
                    0
                );
            },
            totalItemCount: (state) => {
                return _.reduce(
                    state.cart,
                    (quantity, item) => parseInt(quantity) + parseInt(item.item.quantity),
                    0
                );
            },
        },
        mutations: {
            ADD_TO_CART(state, item) {
                const itemIncart = _.find(
                    state.cart,
                    (product) => product.item.Handle === item.item.Handle
                );
                console.log(item, itemIncart);
                if (itemIncart) {
                    console.log(item, itemIncart);
                    itemIncart.item.quantity += parseInt(item.item.quantity);
                    // state.cart.itemIncart = itemIncart;
                } else {
                    state.cart.push(item);
                }
                window.localStorage.setItem("cart", JSON.stringify(state.cart));
            },

            REMOVE_FROM_CART(state, item) {
                if (item.item.quantity > 1) {
                    item.item.quantity--;
                } else {
                    const itemIndex = _.findIndex(
                        state.cart,
                        (product) => product.item.Handle === item.item.Handle
                    );
                    state.cart.splice(itemIndex, 1);
                }
                window.localStorage.setItem("cart", JSON.stringify(state.cart));
            },

            DELETE_ITEM_FROM_CART(state, item) {
                const itemIndex = _.findIndex(
                    state.cart,
                    (product) => product.item.Handle === item.item.Handle
                );
                state.cart.splice(itemIndex, 1);
                window.localStorage.setItem("cart", JSON.stringify(state.cart));
            },

            DELETE_CART(state) {
                state.cart = [];
                window.localStorage.setItem("cart", []);
            },

            SAVE_CART(state) {
                window.localStorage.setItem("cart", JSON.stringify(state.cart));
            },
        },
        actions: {
            addToCart({ commit }, item) {
                commit("ADD_TO_CART", item);
                console.log(item);
            },

            removeFromCart({ commit }, item) {
                commit("REMOVE_FROM_CART", item);
            },

            deleteItemFromCart({ commit }, item) {
                commit("DELETE_ITEM_FROM_CART", item);
            },

            deleteCart({ commit }) {
                commit("DELETE_CART");
            },
        },
    };
};
// export default shoppingCart;